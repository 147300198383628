<!--
SharedMasters 2019 - Fast OnBoarding Application

__copyright__ = "Copyright (C) 2020 Shared Masters sp. z o.o."
__license__ = "Fast OnBoarding can not be copied and/or distributed without the express permission of Shared Masters sp. z o.o."
__version__ = "1.1.0"
__author__ = "Marcin Roszczyk, Jan Przychodniak, Tomasz Drzewiecki"
__maintainer__ = "Shared Masters"
__email__ = "it@sharedmasters.com"
__status__ = "PROD"

-->

<template>
	<div class="user_profile" v-bind:style="{ marginBottom: marginBottom }" :mode="mode">
		<!--
		  EDIT DIALOGS
		-->
		<!-- Phone Number -->
		<v-layout row justify-center>
			<v-dialog v-model="editDialogs.phoneNumber.visible" persistent max-width="290">
				<v-card>
					<v-card-title class="headline">{{ lview.edit }}</v-card-title>

					<v-card-text>
						<v-select
							:placeholder="lview.chooseCountry"
							:items="editDialogs.phoneNumber.countriesToChoose"
							v-model="editDialogs.phoneNumber.countryPhonePrefix"
						></v-select>
						<v-text-field ref="phoneEditDialogField"
									  :label="editDialogs.phoneNumber.label"
									  v-model="editDialogs.phoneNumber.value"
									  :prepend-icon="(editDialogs.phoneNumber.icon == null) ? '' : editDialogs.phoneNumber.icon"
									  type="tel"
									  color="rgba(4, 202, 90, 1)"
									  clearable
									  maxlength="15"
									  :error="editDialogs.phoneNumber.error"></v-text-field>
					</v-card-text>

					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="rgba(4, 202, 90, 1)"
							   text
							   @click="editDialogOnCancel(editDialogs.phoneNumber)">{{ lbuttons.cancel }}</v-btn>
						<v-btn color="rgba(4, 202, 90, 1)"
							   text
							   :disabled="editDialogs.phoneNumber.error || editDialogs.phoneNumber.countryPhonePrefix == null"
							   @click="editDialogOnInputAccept(editDialogs.phoneNumber)">{{ lbuttons.confirm }}</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-layout>

		<!-- Text -->
		<v-layout row justify-center>
			<v-dialog v-model="editDialogs.text.visible" persistent max-width="290">
				<v-card>
					<v-card-title class="headline">{{ lview.edit }}</v-card-title>

					<v-card-text>
						<v-text-field ref="textEditDialogField"
									  :label="editDialogs.text.label"
									  v-model="editDialogs.text.value"
									  :prepend-icon="(editDialogs.text.icon == null) ? '' : editDialogs.text.icon"
									  clearable
									  color="rgba(4, 202, 90, 1)"
									  :type="editDialogs.text.type"
									  :mask="editDialogs.text.mask"
									  :error="editDialogs.text.error"
									  @input="editDialogs.text.error = false"></v-text-field>
					</v-card-text>

					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="rgba(4, 202, 90, 1)"
							   text
							   @click="editDialogOnCancel(editDialogs.text)">{{ lbuttons.cancel }}</v-btn>
						<v-btn color="rgba(4, 202, 90, 1)"
							   text
							   @click="editDialogOnInputAccept(editDialogs.text)">{{ lbuttons.confirm }}</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-layout>

		<!-- Date Picker -->
		<v-layout row justify-center>
			<v-dialog v-model="editDialogs.datePicker.visible" persistent max-width="290">
				<v-card>
					<v-card-title class="headline">{{ lview.edit }}</v-card-title>

					<v-card-text>
						<v-dialog persistent ref="editDatePickerDialog"
								  v-model="editDialogs.datePicker.dialog"
								  persistant
								  width="290px">
							<template v-slot:activator="{ on }">
								<v-text-field v-model="editDialogs.datePicker.value"
											  :label="editDialogs.datePicker.label"
											  :prepend-icon="editDialogs.datePicker.icon == null ? '' : editDialogs.datePicker.icon"
											  readonly
											  v-on="on"
											  color="rgba(4, 202, 90, 1)"
											  clearable></v-text-field>
							</template>
							<v-date-picker ref="editDatePickerPicker"
										   scrollable
										   v-model="editDialogs.datePicker.value"
										   :max="editDialogs.datePicker.max"
										   :min="editDialogs.datePicker.min"
										   color="rgba(4, 202, 90, 1)"
										   @change="
												if(editDialogs.datePicker.closeDatePickerOnChange)
													$refs.editDatePickerDialog.save(editDialogs.datePicker.value)
											">
								<v-spacer></v-spacer>
								<v-btn text
									   color="rgba(4, 202, 90, 1)"
									   @click="editDialogs.datePicker.dialog = false">{{ lbuttons.cancel }}</v-btn>
								<v-btn v-if="!editDialogs.datePicker.closeDatePickerOnChange"
									   text
									   color="rgba(4, 202, 90, 1)"
									   @click="$refs.editDatePickerDialog.save(editDialogs.datePicker.value); editDialogs.datePicker.dialog = false">{{ lbuttons.confirm }}</v-btn>
							</v-date-picker>
						</v-dialog>
					</v-card-text>

					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="rgba(4, 202, 90, 1)"
							   text
							   @click="editDialogOnCancel(editDialogs.datePicker)">{{ lbuttons.cancel }}</v-btn>
						<v-btn color="rgba(4, 202, 90, 1)"
							   text
							   @click="editDialogOnInputAccept(editDialogs.datePicker)">{{ lbuttons.confirm }}</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-layout>

		<!-- Postal address -->
		<v-layout row justify-center>
			<v-dialog v-model="editDialogs.postalAddress.visible" persistent max-width="350">
				<v-card>
					<v-card-title class="headline">{{ lview.edit }}</v-card-title>

					<v-card-text>
						<v-layout justify-space-between row wrap>
							<!-- Street -->
							<v-flex xs12>
								<v-text-field :prepend-icon="(editDialogs.postalAddress.icon.street == null) ? '' : editDialogs.postalAddress.icon.street"
											  :label="editDialogs.postalAddress.label.street"
											  v-model="editDialogs.postalAddress.value.street"
											  :error="editDialogs.postalAddress.noStreetName"></v-text-field>
							</v-flex>

							<!-- Street number, property number -->
							<v-flex xs5 align-center>
								<v-text-field :prepend-icon="(editDialogs.postalAddress.icon.streetNumber == null) ? '' : editDialogs.postalAddress.icon.streetNumber"
											  :label="editDialogs.postalAddress.label.streetNumber"
											  v-model="editDialogs.postalAddress.value.streetNumber"
											  :error="editDialogs.postalAddress.noStreetNumber"></v-text-field>
							</v-flex>
							<v-flex xs1 style="line-height:68px; text-align:center; font-size: 18px;">/</v-flex>
							<v-flex xs5>
								<v-text-field :prepend-icon="(editDialogs.postalAddress.icon.propertyNumber == null) ? '' : editDialogs.postalAddress.icon.propertyNumber"
											  :label="editDialogs.postalAddress.label.propertyNumber"
											  v-model="editDialogs.postalAddress.value.propertyNumber"></v-text-field>
							</v-flex>

							<!-- zip code, city, country -->
							<v-flex xs5>
								<v-text-field type="tel"
											  mask="##-###"
											  :prepend-icon="(editDialogs.postalAddress.icon.zipCode == null) ? '' : editDialogs.postalAddress.icon.zipCode"
											  :label="editDialogs.postalAddress.label.zipCode"
											  v-model="editDialogs.postalAddress.value.zipCode"
											  :error="editDialogs.postalAddress.noZipCode"></v-text-field>
							</v-flex>
							<v-flex xs6>
								<v-text-field :prepend-icon="(editDialogs.postalAddress.icon.city == null) ? '' : editDialogs.postalAddress.icon.city"
											  :label="editDialogs.postalAddress.label.city"
											  v-model="editDialogs.postalAddress.value.city"
											  :error="editDialogs.postalAddress.noCity"></v-text-field>
							</v-flex>
							<v-flex xs12>
								<!--
								<v-text-field
								  :prepend-icon="(editDialogs.postalAddress.icon.country == null) ? '' : editDialogs.postalAddress.icon.country"
								  :label="editDialogs.postalAddress.label.country" :value="editDialogs.postalAddress.value.country">
								</v-text-field>
								-->
								<v-select required
										  v-model="editDialogs.postalAddress.value.country"
										  :items="countriesSelect"
										  :label="editDialogs.postalAddress.label.country"
										  item-value="key"
										  item-text="text"
										  :error="editDialogs.postalAddress.noCountry"></v-select>
							</v-flex>
						</v-layout>
					</v-card-text>

					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="rgba(4, 202, 90, 1)"
							   text
							   @click="editDialogOnCancel(editDialogs.postalAddress)">{{ lbuttons.cancel }}</v-btn>
						<v-btn color="rgba(4, 202, 90, 1)"
							   text
							   @click="editDialogOnInputAccept(editDialogs.postalAddress)">{{ lbuttons.confirm }}</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-layout>

		<postal-address-edit-dialog ref="postalAddressEditDialog"
			:title="editDialogs.postalAddress.title"
			:selectInput="editDialogs.postalAddress.mode == 'SELECT'"
			:manualInput="editDialogs.postalAddress.mode == 'DEFAULT'"
			:manualAndSelectInput="editDialogs.postalAddress.mode == 'MIXED'"
			:countriesDictionary="countriesSelect"
			:locationsDictionary="editDialogs.postalAddress.stayLocationsDictionary"
			:address="editDialogs.postalAddress.address"
			:selectAddress="editDialogs.postalAddress.selectAddress"
			@error="onError"
			@confirm="(feedback) => {
          	commonEditDialogOnAccept(editDialogs.postalAddress, feedback)
        }"></postal-address-edit-dialog>

		<yes-no-dialog ref="yesNoDialog"
					   :title="yesNoDialog.title"
					   :content="yesNoDialog.content"
					   @yes-click="yesNoDialog.yesClick"
					   @no-click="yesNoDialog.noClick"></yes-no-dialog>

		<!--
		  BOARDING DIALOGS
		-->
		<!--
			<change-boarding-plans-dialog ref="changeBoardingPlansDialog"
										v-bind:workers="boardingDialogs.changePlans.workers"
										v-bind:projectsDictionary="projectsDictionary"
										v-bind:defaultProject="boardingDialogs.changePlans.defaultProject"
										v-bind:date="boardingDialogs.changePlans.date"
										@request-sending-start="onRequestStart"
										@request-sending-error="onRequestError"
										@request-sending-success="onChangeBoardingPlansSuccess"
										@request-sending-end="onRequestEnd"></change-boarding-plans-dialog>

			<confirm-boarding-dialog ref="confirmBoardingDialog"
									v-bind:workers="boardingDialogs.confirmBoarding.workers"
									@request-sending-start="onRequestStart"
									@request-sending-success="onConfirmBoardingSuccess"
									@request-sending-end="onRequestEnd"
									@error="onError"></confirm-boarding-dialog>

			<resign-dialog ref="resignDialog"
						v-bind:workerIds="boardingDialogs.resign.workerIds"
						@request-sending-start="onRequestStart"
						@request-sending-error="onRequestError"
						@request-sending-success="onResignSuccess"
						@request-sending-end="onRequestEnd"
						@error="onError"></resign-dialog> -->

			<change-employee-project-dialog ref="changeEmployeeProjectDialog"
										v-bind:workers="employeeDialogs.changePlans.workers"
										v-bind:projectsDictionary="projectsDictionary"
										v-bind:activeBoardings="activeBoardings"
										v-bind:defaultProject="employeeDialogs.changePlans.defaultProject"
										@request-sending-start="onRequestStart"
										@request-sending-error="onRequestError"
										@request-sending-success="onChangeEmployeePlansSuccess"
										@request-sending-end="onRequestEnd"
										@error="onError"></change-employee-project-dialog>

		<!--<off-boarding-dialog ref="offBoardingDialog"
								v-bind:workers="employeeDialogs.offBoarding.workers"
								@request-sending-start="onRequestStart"
								@request-sending-error="onRequestError"
								@request-sending-success="onOffBoardingSuccess"
								@request-sending-end="onRequestEnd"
								@error="onError"></off-boarding-dialog>
		-->

		<!-- <change-employee-plans-dialog ref="changeEmployeeProjectDialog"
							:workers="employeeDialogs.changePlans.workers"
							v-bind:defaultProject="employeeDialogs.changePlans.defaultProject"
							v-bind:managersDictionary="managersDictionary"
							v-bind:defaultManager="profileInformation.managerId" 
							@request-sending-start="onRequestStart"
							@request-sending-error="onRequestError"
							@request-sending-success="onChangeEmployeePlansSuccess"
							@request-sending-end="onRequestEnd"
							@error="onError"></change-employee-plans-dialog> -->

		<!--
		  PAGE CONTENT
		-->
		
		<v-dialog 
			persistent
			v-model="editDialogs.extraInformation.visible"
			max-width="290"
		>	
			<v-card>	
				<v-card-title class="headline">{{editDialogs.extraInformation.title}}</v-card-title>
				
				<v-card-text v-if="editDialogs.extraInformation.canWrite" style="text-align:justify; height: 304px; padding-left: 5%; padding-right: 5%;">
					<v-textarea
						:value="editDialogs.extraInformation.value"
						v-model="editDialogs.extraInformation.value"
						solo
						rows="8"
						row-height="10"
						:counter="editDialogs.extraInformation.limitOfCharacters"
						class="user-profile__textarea"
						spellcheck="false"
						:placeholder="lview.noData"
						clearable
						:maxlength="editDialogs.extraInformation.limitOfCharacters"
					></v-textarea>
				</v-card-text>
				<v-card-text v-else>
					<div v-html="editDialogs.extraInformation.value"></div>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>

					<v-btn color="rgba(4, 202, 90, 1)" v-if="mode=='edit'"
					   text="text"
					   @click="editDialogs.extraInformation.visible = false">
						{{ lbuttons.cancel }}
					</v-btn>

					<v-btn color="rgba(4, 202, 90, 1)" v-if="mode=='edit'"
					   text="text"
					   @click="closeExtraInfoDialog()">
						{{ lbuttons.confirm }}
					</v-btn>

					<v-btn color="rgba(4, 202, 90, 1)" v-if="mode!='edit'"
					   text="text"
					   @click="editDialogs.extraInformation.visible = false">
						{{ lbuttons.ok }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Rating details dialog -->
		<v-dialog
			persistent
			v-model="visibleRatingDetails"
			max-width="290px"
		>
			<v-card>
				<v-card-title class="headline">{{ lview.ratingComment }}</v-card-title>

				<v-card-text v-if="profileInformation.ratingDetails != null && profileInformation.ratingDetails != ''" v-html="profileInformation.ratingDetailsDisplay">
				</v-card-text>

				<v-card-text v-else>
					{{ lmessages.noDetails }}
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="green darken-1"
						text
						@click="visibleRatingDetails = false"
					>
						{{ lbuttons.close }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>


		<!-- See more project dialog -->
		<v-dialog
			persistent
			v-model="visibleSeeMoreProjectsDialog"
			max-width="340"
		>
			<v-card style="text-align: center;">
				<v-card-title class="headline justify-center"> {{lview.projectsList}} </v-card-title>
				<v-list>
					<v-list-item
						v-for="(project,key) in projectsList"
						:key="key"
					>
						<v-flex xs12>
							<v-layout>
								<v-list-item-content>
									<v-list-item-title style="font-size: 15px;"> {{ project.name }}  </v-list-item-title>
									<v-list-item-subtitle v-if="project.to == null">
										({{ $t('views.userProfile.sinceDate', { since: project.since }) }})
									</v-list-item-subtitle>
									<v-list-item-subtitle v-else>
										({{ $t('views.userProfile.sinceDateToDate', { since: project.since, to: project.to }) }})
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-layout>
						</v-flex>
					</v-list-item>
				</v-list>
				<v-spacer></v-spacer>
				<v-btn text color="rgba(4, 202, 90, 1)" @click="visibleSeeMoreProjectsDialog = false">
					{{lbuttons.close}}
				</v-btn>
			</v-card>
		</v-dialog>

		<v-dialog
			persistent
			v-model="editDialogs.rating.visible"
			width="350px"
		>
			<v-card>	
				<v-card-title class="headline">{{ lview.editRating }}</v-card-title>

				<v-card-text>
					<nullable-rating
						v-model="editDialogs.rating.rating"
					></nullable-rating>

					<v-textarea
						v-model="editDialogs.rating.ratingDetails"
						:auto-grow="true"
						:rows="2"
						counter="400"
						:clearable="true"
						maxlength="400"
						:label="lview.ratingComment"
					></v-textarea>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="rgba(4, 202, 90, 1)"
					   text="text"
					   @click="editDialogs.rating.visible = false">
						{{ lbuttons.cancel }}
					</v-btn>

					<v-btn color="rgba(4, 202, 90, 1)"
					   text="text"
					   @click="editDialogs.rating.visible = false; ratingCallback(editDialogs.rating.rating, editDialogs.rating.ratingDetails)">
						{{ lbuttons.ok }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!--
			<v-btn v-if="mode!='edit'" large fab fixed class="user-profile__edit-button" @click="setMode('edit')">
			  <v-icon>edit</v-icon>
			</v-btn>
			<v-btn v-else large fab fixed class="user-profile__edit-button" @click="setMode('default')">
			  <v-icon>check</v-icon>
			</v-btn>
		-->

		<!--
			*
			*
			* START OF MAIN USER PROFILE FORM
			*
			*
		-->
		<v-layout column v-if="state == 'profileData'">
			<v-flex class="profile-header" style="margin-top: 40px;">
				<!-- Worker second and first NAME -->
				<h1 class="headline" style="text-align:center">{{ displayName }}</h1>

				<!-- Worker RATING stars and info -->
				<div v-if="permissions.includes('fob.worker_rating_view')" style="text-align: center; display:flex; justify-content:center" @click="onRatingClick()">
					<div style="display:inline-block;">
						<v-rating
							v-model="profileInformation.rating"
							:length="5"
							:empty-icon="(profileInformation.rating == null) ? 'star_outline' : 'star'"
							full-icon="star"
							half-icon="star_half"
							:half-increments="true"
							:hover="true"
							:readonly="true"
							color="orange"
							background-color="grey lighten-2"
							dense
							style="margin: 0 auto; text-align: center; height: 26.78px; padding-top:4.61px"
						></v-rating>
					</div>
					<div style="display:inline-block; margin-top:4.25px;">
						<v-btn v-if="mode != 'edit'" text icon small color="green darken-1" style="margin-right:0" @click="openRatingDetailsDialog()">
							<v-icon>info</v-icon>
						</v-btn>
						<v-icon v-else-if="permissions.includes('fob.worker_rating_change')" style="margin-right:0; color: #000">edit</v-icon>
					</div>
				</div>

				<!-- *** Worker ROLE - removal of not yet complete functionalities ***
				<h3 class="subheading" style="text-align:center">
					  CODE STABILIZATION -->
					<!-- {{ displayRole }} -->
					<!-- {{ $t('views.userProfile.inSince', { since: displaySince }) }}
				</h3>
				-->

				<!-- Worker PROJECT assignments -->
				<div v-if="projectsList.length <= 3">
					<h3 
						class="caption"
						v-for="(project, key) in projectsList"
						:key="key"
						style="text-align:center"
					>
						{{ project.name }} 
						<span v-if="project.to == null">({{ $t('views.userProfile.sinceDate', { since: project.since }) }})</span>
						<span v-else>({{ $t('views.userProfile.sinceDateToDate', { since: project.since, to: project.to }) }})</span>
					</h3>
				</div>
				<div v-else>
					<h3
						class="caption"
						v-for="key in 3"
						:key="key"
						style="text-align:center"
					>
						{{ projectsList[key - 1].name }} 
						<span v-if="projectsList[key - 1].to == null">({{ $t('views.userProfile.sinceDate', { since: projectsList[key - 1].since }) }})</span>
						<span v-else>({{ $t('views.userProfile.sinceDateToDate', { since: projectsList[key - 1].since, to: projectsList[key - 1].to }) }})</span>
					</h3>
					<v-spacer></v-spacer>
					<h3
						class="caption"
						style="color: rgba(4, 202, 90, 1); text-align:center; "
						@click="visibleSeeMoreProjectsDialog = true"
					>
						{{lview.seeMoreProjects}}
					</h3>
				</div>
			</v-flex>

			<!-- Worker PERSONAL DATA - all remaining information -->
			<div v-for="(item, index) in displaySections" v-bind:key="index">
				<v-flex class="profile-title">
					<h2 class="body-2">{{ item.title }}</h2>
				</v-flex>

				<v-flex class="file-list">
					<v-list two-line dense>
						<div v-for="(subItem, subIndex) in item.items" v-bind:key="subIndex">
							<v-list-item v-if="mode != 'edit' || subItem.onClick == null">
								
								<v-list-item-action v-if="(subItem.icon=='phone' || subItem.icon=='smartphone') && phoneNumberValidation(subItem.value)">
									<a :href="'tel:'+subItem.value">
										<v-icon style="width:100%; height:100%; margin-top:8px;">{{ subItem.icon }}</v-icon>
									</a>
								</v-list-item-action>

								<v-list-item-action v-else-if="(subItem.icon=='mail_outline' || subItem.icon=='email') && emailValidation(subItem.value)">
									<a :href="'mailto:'+subItem.value">
										<v-icon style="width:100%; height:100%; margin-top:8px;">{{ subItem.icon }}</v-icon>
									</a>
								</v-list-item-action>

								<v-list-item-action v-else-if="(subItem.icon=='contact_mail' || subItem.icon=='home') && subItem.value != lview.unassigned && subItem.value != lview.noData">
									<a :href="'http://maps.google.com/?q='+subItem.value" target="_blank">
										<v-icon style="width:100%; height:100%; margin-top:8px;">{{ subItem.icon }}</v-icon>
									</a>
								</v-list-item-action>

								<v-list-item-action v-else-if="subItem.icon != 'add'">
										<v-icon>{{ subItem.icon }}</v-icon>
								</v-list-item-action>


								<!-- making calls to worker via click -->
								<v-flex xs12 v-if="(subItem.icon=='phone' || subItem.icon=='smartphone') && phoneNumberValidation(subItem.value)">
									<a :href="'tel:'+subItem.value" style="text-decoration: none; width:100%; height:100%; margin-top:8px;">
										<v-list-item-title>{{ subItem.value }}</v-list-item-title>
										<v-list-item-subtitle>{{ subItem.description }}</v-list-item-subtitle>
									</a>
								</v-flex>

								<!-- writing emails to worker -->
								<v-flex xs12 v-else-if="(subItem.icon=='mail_outline' || subItem.icon=='email') && emailValidation(subItem.value)">
									<a :href="'mailto:'+subItem.value" style="text-decoration: none; width:100%; height:100%; margin-top:8px;">
										<v-list-item-title>{{ subItem.value }}</v-list-item-title>
										<v-list-item-subtitle>{{ subItem.description }}</v-list-item-subtitle>
									</a>
								</v-flex>

								<!-- opening address in map browser ex. Google Maps -->
								<v-flex xs12 v-else-if="(subItem.icon=='contact_mail' || subItem.icon=='home') && subItem.value != lview.unassigned && subItem.value != lview.noData">
									<a :href="'http://maps.google.com/?q='+subItem.value" target="_blank" style="text-decoration: none; width:100%; height:100%; margin-top:8px;">
										<v-list-item-title>{{ subItem.value }}</v-list-item-title>
										<v-list-item-subtitle>{{ subItem.description }}</v-list-item-subtitle>
									</a>
								</v-flex>
								
								<v-flex xs12 v-else-if="subItem.icon=='add'">
									<div :disabled="mode!='edit'" 
										@click="openExtraInfoDialog(subItem)" style="height:100%; width:100%; min-height: 64px;" 
										v-html="subItem.value">
									</div>
								</v-flex>
							
								<v-flex xs12 v-else-if="subItem.icon!='add'">
									<v-list-item-title>{{ subItem.value }}</v-list-item-title>
									<v-list-item-subtitle>{{ subItem.description }}</v-list-item-subtitle>
								</v-flex>

								<v-divider></v-divider>

							</v-list-item>

							<v-list-item v-else @click="permissions.includes(item.permissions.change)? subItem.onClick(): ''" ripple>
								<!-- row that is not text area -> additional info, contact address info -->
								<v-list-item-action v-if="subItem.icon != 'add'">
									<v-icon>{{ subItem.icon }}</v-icon>
								</v-list-item-action>

								<v-list-item-content v-if="subItem.icon != 'add'">
									<v-list-item-title>{{ subItem.value }}</v-list-item-title>
									<v-list-item-subtitle>{{ subItem.description }}</v-list-item-subtitle>
								</v-list-item-content>

								<v-list-item-action v-if="subItem.icon != 'add' && permissions.includes(item.permissions.change)">
									<v-icon style="color:#000">edit</v-icon>
								</v-list-item-action>

								<v-list-item-content v-else-if="subItem.icon=='add'">
									<div color="rgba(4, 202, 90, 1)" @click="openExtraInfoDialog(subItem)" style="height:100%; width:100%;" v-html="subItem.value"></div>
								</v-list-item-content>

							</v-list-item>
							
						</div>
					</v-list>
				</v-flex>
			</div>
		</v-layout>

		<v-snackbar
          v-model="offlineNotifier"
		  fixed
		  class="snackbar-offline-notifier"
      	>
			<v-container class="align-self-center" style="height: 30px; width: 100%; padding-top: 0px; padding-bottom: 0px;">
				<v-flex xs12 class="align-self-center" style="display: flex;">
					<v-flex xs6 class="align-self-center" style="-webkit-font-smoothing: antialiased; text-rendering: optimiseSpeed;">{{ lmessages.networkError }}</v-flex>
					<v-flex xs6 class="align-self-center">
						<v-btn
							text
							@click="retryConnect()"
							class="align-self-center"
							style="color: orange; font-size: 12px; -webkit-font-smoothing: antialiased; text-rendering: optimiseSpeed;"
						>
							{{ lbuttons.retry }}
						</v-btn>
					</v-flex>
				</v-flex>
			</v-container>
      	</v-snackbar>

		<v-btn v-bind:style="{ bottom: editButtonBottom }"
			   class="user-profile__edit-button"
			   v-if="mode != 'edit' && onlineStatus && editionAvailable"
			   fab
			   fixed
			   @click="setMode('edit')">
			<v-icon>edit</v-icon>
		</v-btn>
		<v-btn v-bind:style="{ bottom: editButtonBottom }"
			   class="user-profile__edit-button"
			   v-else-if="mode == 'edit' && onlineStatus"
			   fab
			   fixed
			   @click="setMode('default')">
			<v-icon>check</v-icon>
		</v-btn>
	</div>
</template>

<script>
	import axios from "axios";
	import store from "../store.js";
	import PhoneNumber from 'awesome-phonenumber';
	import isLoginValid from "../auth";

	export default {
		name: "user_profile",
		data() {
			return {
				permissions: [],
				workerProfileId: null,
				isMyProfile: false,
				onlineStatus: navigator.onLine,
				offlineNotifier: false,
				visibleSeeMoreProjectsDialog: false,
				visibleRatingDetails: false,

				// - default
				// - boarding
				// - employee
				actionsStatus: "default",

				projectsList: [
					/*
					{
						id: 1,
						name: "Project 1",
						since: "2019-06-03",
						to: "2019-09-03"
					},
					{
						id: 2,
						name: "Project 2",
						since: "2019-06-03",
						to: "2019-09-03"
					},
					*/
				],
				attributesList: [
					/*
					{
						assignmentId: Integer,
						attributeId: Integer,
						attributeIdentifier: Text (unique user-defined attribute identifier),
						attributeGettextName: Text (text prepared for translation in Django),
						assignmentStartDate: Date,
						assignmentEndDate: Date
					}
					*/
				],

				actionsBoarding: [
					{
						isIcon: true,
						value: "mdi-file-replace-outline",
						description: "Zmień plany",
						onClick: null
					},
					{
						isIcon: true,
						value: "attach_file",
						description: "Dokumenty",
						onClick: null
					},
					{
						isIcon: true,
						value: "assignment",
						description: "Projekty",
						onClick: null
					},
				],
				moreActionsBoarding: [
					/*
					{
					  name: "{ACTION}",
					  onClick: () => {},
					  isIcon: false,
					  value: (...) {icon}
					},
					{
						name: this.$t('views.documents.title'),
						onClick: null,
						isIcon: false,
						value: null
					},
					{
						name: this.$t('views.projects.title'),
						onClick: null,
						isIcon: false,
						value: null
					} */
				],
				actionsEmployee: [
					{
						isIcon: true,
						value: "mdi-file-replace-outline",
						description: "Zmień plany",
						onClick: null,
						disabled: null,
					},
					{
						isIcon: true,
						value: "attach_file",
						description: "Dokumenty pracownika",
						onClick: null,
						disabled: null,
					},
					{
						isIcon: true,
						value: "assignment",
						description: "Projekty",
						onClick: null,
						disabled: null,
					},
				],
				moreActionsEmployee: [
					/*
					{
					  name: "{ACTION}",
					  onClick: () => {},
					  isIcon: false,
					  value: (...) {icon}
					},
					{
						name: this.$t('views.documents.title'),
						onClick: null,
						isIcon: false,
						value: null
					},
					{
						name: this.$t('views.projects.title'),
						onClick: null,
						isIcon: false,
						value: null
					} */
				],
				myProfileActions: [
					{
						isIcon: true,
						value: "attach_file",
						description: "Dokumenty",
						onClick: null
					},
					{
						isIcon: true,
						value: "assignment",
						description: "Projekty",
						onClick: null
					},
				],

				displaying: null,
				marginBottom: "56px",
				editButtonBottom: "24px",

				managersDictionary: [
					/*
					{
					  managerId: {Number},
					  firstName: {String},
					  middleName: {String},
					  lastName: {String}
					}
					*/
				],

				// - profileData
				// - loading
				// - error
				state: "loading",
				// - edit
				// - default
				mode: "default",
				editionAvailable: true,
				networkErrorOccured: false,
				errorMessage: "Wystąpił błąd",

				//countries dictionary
				countriesSelect: this.$t('views.userProfile.countriesSelect'),

				addressesDictionary: [
					/*{
					  id: {Integer},
					  street: {String},
					  streetNumber: {String},
					  propertyNumber: {String},
					  zipCode: {String},
					  city: {String},
					  country: {Char[2]},
					  region: {String},
					  description: {String},
					  deleted: {Boolean}
					},*/
				],
				addressesPickDictionary: [
					/*{
					  id: {Integer},
					  street: {String},
					  streetNumber: {String},
					  propertyNumber: {String},
					  zipCode: {String},
					  city: {String},
					  country: {Char[2]},
					  region: {String},
					  description: {String},
					  deleted: {Boolean}
					},*/
				],

				// Component data format
				profileInformation: {
					profileId: null,

					rating: 0,
					ratingDetails: null,

					workerId: null,
					managerId: null,
					workerStatus: null,
					onBoardingDate: null,
					offBoardingDate: null,
					projectId: null,

					firstName: null,
					middleName: null,
					lastName: null,
					privatePhoneNumber: null,
					businessPhoneNumber: null,
					privateEmail: null,
					businessEmail: null,
					birthDate: null,
					primaryPostalAddress: {
						id: null,
						streetName: null,
						streetNumber: null,
						propertyNumber: null,
						zipCode: null,
						city: null,
						region: null,
						country: null
					},
					pesel: null,
					since: null,
					contractBeginDate: null,
					contractEndDate: null,
					secondaryPostalAddress: {
						id: null,
						streetName: null,
						streetNumber: null,
						propertyNumber: null,
						zipCode: null,
						city: null,
						region: null,
						country: null
					},
					secondaryPostalAddressId: null, // reference Key to Dictionary Addresses from the Profile
					drivingLicense: null,
					contactAddressInformation: null,
					additionalInformation: null
				},

				serverProfileRequest: {
					anyChanges: false,
					changes: {
						rating: {
							changed: false,
							requestId: "rating",
							newValue: null
						},
						ratingDetails: {
							changed: false,
							requestId: "rating_comment",
							newValue: null
						},
						privatePhoneNumber: {
							changed: false,
							requestId: "personal_mobile_number",
							newValue: null
						},
						businessPhoneNumber: {
							changed: false,
							requestId: "business_mobile_number",
							newValue: null
						},
						privateEmail: {
							changed: false,
							requestId: "personal_email",
							newValue: null
						},
						businessEmail: {
							changed: false,
							requestId: "secondary_email",
							newValue: null
						},
						birthDate: {
							changed: false,
							requestId: "birth_date",
							newValue: null
						},
						primaryPostalAddress: {
							changed: false,
							requestId: "primary_postal_address",
							newValue: null
						},
						secondaryPostalAddress: {
							changed: false,
							requestId: "contact_postal_address_id",
							newValue: null
						},
						pesel: {
							changed: false,
							requestId: "pesel",
							newValue: null
						},
						drivingLicense: {
							changed: false,
							requestId: "driving_license_id",
							newValue: null
						},
						extraInformation: {
							changed: false,
							requestId: "contact_address_information",
							newValue: null
						},
						additionalInformation: {
							changed: false,
							requestId: "additional_information",
							newValue: null
						},
						contractBeginDate: {
							changed: false,
							requestId: "contract_start_date",
							newValue: null
						},
						contractEndDate: {
							changed: false,
							requestId: "contract_end_date",
							newValue: null,
						}
					}
				},
				/*serverWorkerRequest: {
					anyCHanges: false,
					changes: {
						contractBeginDate: {
							changed: false,
							requestId: "contract_start_date",
							newValue: null
						},
						contractEndDate: {
							changed: false,
							requestId: "contract_end_date",
							newValue: null,
						}
					}
				},*/

				// 3 Properties displayed at the top
				displayName: "",
				displayRole: "Magazynier",
				displaySince: "cze 2019",

				// Config of edit dialogs
				editDialogs: {
					phoneNumber: {
						visible: false,
						cannotClose: false,
						icon: null,
						label: "Telefon prywatny",
						value: "+48721652205",
						error: false,
						countriesToChoose: [
							{ value: '+48', text: 'Poland (Polska)' },
							{ value: '+44', text: 'United Kingdom' },
							{ value: '+49', text: 'Germany (Deutschland)' },
							{ value: '+380', text: 'Ukraine (Україна)' },
						],
						countryPhonePrefix: null,
					},
					text: {
						visible: false,
						cannotClose: false,
						icon: null,
						label: null,
						value: null,
						mask: null,
						type: "text",
						error: false,
					},
					datePicker: {
						visible: false,
						cannotClose: false,
						icon: null,
						value: null,
						label: "Data urodzin",
						min: null,
						max: null,
						activePicker: null,
						closeDatePickerOnChange: false,

						dialog: false
					},
					postalAddress: {
						visible: false,
						cannotClose: false,

						noCity: false,
						noZipCode: false,
						noStreetNumber: false,
						noStreetName: false,
						noCountry: false,

						mode: "DEFAULT",
						title: null,
						address: {
							street: null,
							streetNumber: null,
							propertyNumber: null,
							zipCode: null,
							city: null,
							country: null
						},
						selectAddress: null,

						onInputAccept: null,

						value: {
							street: null,
							streetNumber: null,
							propertyNumber: null,
							zipCode: null,
							city: null,
							country: null
						},
						icon: {
							street: null,
							streetNumber: null,
							propertyNumber: null,
							zipCode: null,
							city: null,
							country: null
						},
						label: {
							street: "Ulica",
							streetNumber: "Numer",
							propertyNumber: "Mieszkanie",
							zipCode: "Kod pocztowy",
							city: "Miasto",
							country: "Kraj"
						}
					},
					extraInformation: {
						editedId: null,
						visible: false,
						value: null,
						limitOfCharacters: 0,
						title: null,
						canWrite: false,
					},
					rating: {
						visible: false,
						rating: null,
						ratingDetails: null
					}
				},
				boardingDialogs: {
					changePlans: {
						workers: [],
						defaultProject: null,
						date: null,
					},
					confirmBoarding: {
						workers: []
					},
					resign: {
						workerIds: []
					}
				},
				employeeDialogs: {
					changePlans: {
						workers: [],
						defaultProject: null
					},
					offBoarding: {
						workers: [],
					}
				},

				yesNoDialog: {
					title: "",
					content: "",

					yesClick: () => { },
					noClick: () => { },

					store: null,
				},

				projectsDictionary: [],
				activeBoardings: [],

				// This is the default template for this page
				// Its structure is static for now, but may change to be dynamic in the future
				displayList: [
					{
						title: "KONTAKT",
						permissions: {
							view: "fob.worker_contact_view",
							change: "fob.worker_contact_change"
						},
						items: [
							{
								icon: "phone",
								value: "",
								description: "Telefon prywatny",
								onClick: null
							},
							{
								icon: "smartphone",
								value: "",
								description: "Telefon firmowy",
								onClick: null
							},
							{
								icon: "mail_outline",
								value: "",
								description: "Mail prywatny",
								onClick: null
							},
							{
								icon: "email",
								value: "",
								description: "Mail firmowy",
								onClick: null
							}
						]
					},
					{
						title: "DANE OSOBOWE",
						permissions: {
							view: "fob.worker_data_view",
							change: "fob.worker_data_change"
						},
						items: [
							{
								icon: "today",
								value: "",
								description: "Data urodzenia",
								onClick: null
							},
							{
								icon: "contact_mail",
								value: "",
								description: "Adres zamieszkania",
								onClick: null
							},
							{
								icon: "info",
								value: "",
								description: "Pesel",
								onClick: null
							},
							{
								icon: "drive_eta",
								value: "",
								description: "Prawo jazdy",
								onClick: null
							}
						]
					},
					{
						title: "DANE UMOWY",
						permissions: {
							view: "fob.worker_contract_view",
							change: "fob.worker_contract_change"
						},
						items: [
							{
								icon: "event_available",
								value: "",
								description: "Data rozpoczęcia",
								onClick: null
							},
							{
								icon: "event_busy",
								value: "",
								description: "Data zakończenia",
								onClick: null
							}
						]
					},
					{
						title: "DANE POBYTU",
						permissions: {
							view: "fob.worker_residence_view",
							change: "fob.worker_residence_change"
						},
						items: [
							{
								icon: "home",
								value: "",
								description: "Adres pobytu",
								onClick: null
							},
							{
								id: "address_info",
								icon: "add",
								value: "",
								description: "Informacje adresu kontaktowego",
								onClick: null
							}
						]
					},
					{
						title: "Inne Dane",
						permissions: {
							view: "fob.worker_other_view",
							change: "fob.worker_other_change"
						},
						items: [
							{
								id: "additional_info",
								icon: "add",
								value: "",
								description: "Informacje dodatkowe",
								onClick: null
							}
						]
					},
					{
						title: "Atrybuty",
						permissions: {
							view: null,
							change: null
						},
						items: [ /* Assigned dynamically */]
					}
				],

				errorWhileFetchingData: false,
			};
		},
		props: {
            appConfig: {
                type: Object,
                required: true
            }
        },
		computed: {
			lview: {
				get: function () {
					return this.$t("views.userProfile");
				}
			},
			lbuttons: {
				get: function () {
					return this.$t("commons.buttons");
				}
			},
			lmessages: {
				get: function () {
					return this.$t('commons.messages');
				}
			},
			lerrors: { get: function() { return this.$t('errors'); } },

			isUserProfile() {
				//console.log(
				//	"###### Getting user profile " + this.$store.getters.userProfile
				//);
				return this.$store.getters.userProfile;
			},
			displaySections(){
				let result = []
				if(this.displayList){
					for(let item of this.displayList){
						if(this.permissions.includes(item.permissions.view) || !item.permissions.view){
							result.push(item)
						}
					}
				}
				return result
			}
		},
		beforeMount() {
			this.permissions = localStorage.getItem("user_permissions");
			this.displayList[0].items[0].onClick = this.onPrivatePhoneClick;
			this.displayList[0].items[1].onClick = this.onBusinessPhoneClick;
			this.displayList[0].items[2].onClick = this.onPrivateEmailClick;
			this.displayList[0].items[3].onClick = this.onBusinessEmailClick;
			this.displayList[1].items[0].onClick = this.onBirthDateClick;
			this.displayList[1].items[1].onClick = this.onPrimaryPostalAddressClick;
			this.displayList[1].items[2].onClick = this.onPeselClick;
			this.displayList[1].items[3].onClick = this.onDrivingLicenseClick;
			this.displayList[2].items[0].onClick = this.onBeginDateClick;
			this.displayList[2].items[1].onClick = this.onEndDateClick;
			this.displayList[3].items[0].onClick = this.onSecondaryPostalAddressClick;
			//this.displayList[3].items[1].onClick = this.onContactAddressInformationClick;
			//this.displayList[4].items[0].onClick = this.onAdditionalInformationClick;

			this.actionsBoarding[0].onClick = this.onBoardingEditClick;
			this.actionsBoarding[1].onClick = this.onEmployeeDocuments; // this.onBoardingConfirmClick;
			this.actionsBoarding[2].onClick = this.onEmployeeProjects;  // this.onBoardingResignClick;
			// this.moreActionsBoarding[0].onClick = this.onEmployeeDocuments;
			// this.moreActionsBoarding[1].onClick = this.onEmployeeProjects;

			this.actionsEmployee[0].onClick = this.onEmployeeEditClick;
			this.actionsEmployee[1].onClick = this.onEmployeeDocuments;
			this.actionsEmployee[2].onClick = this.onEmployeeProjects;

			// disabled conditions assignment for
			this.actionsEmployee[1].disabled = this.userNotHasWorkerDocumentViewPermission();

			// this.moreActionsEmployee[0].onClick = this.onEmployeeDocuments;
			// this.moreActionsEmployee[1].onClick = this.onEmployeeProjects;

			this.myProfileActions[0].onClick = this.onEmployeeDocuments;
			this.myProfileActions[1].onClick = this.onEmployeeProjects;

			this.countriesSelect.sort((a, b) => {
				if (a.text < b.text) return -1;
				else if (a.text > b.text) return 1;
				return 0;
			});

			window.addEventListener('online', ()=>{this.onlineStatus = true;} );
			window.addEventListener('offline', ()=>{this.onlineStatus = false} );
		},
		beforeDestroy(){
			if(this.onlineStatus){
				localStorage.setItem("UP_" + this.workerProfileId, JSON.stringify(this.profileInformation));
			}

			window.removeEventListener('online', ()=>{this.onlineStatus = true;});
			window.removeEventListener('offline', ()=>{this.onlineStatus = false;});
		},
		mounted() {
			if (!isLoginValid()) {
				this.$store.dispatch("logout").then(() => {
					this.$router.push("/login");
				});
			}

			var loadId = this.$route.params.profile_id;
			if (typeof loadId === "undefined") loadId = null;
			if (loadId == null) this.$emit("set-title", this.lview.myProfile);
			else this.$emit("set-title", this.lview.workerProfile);

			let userPermissions = localStorage.getItem("user_permissions");
			if ( !userPermissions.includes("fob.workers_view") ) {
				this.$emit('set-state', 'ERROR', this.lview.youDoNotHavePriviledgesToThisView);
				return;
			}

			if(!userPermissions.includes("fob.workers_change")||
			!(userPermissions.includes("fob.worker_rating_change") ||
			userPermissions.includes("fob.worker_contact_change") ||
			userPermissions.includes("fob.worker_data_change") ||
			userPermissions.includes("fob.worker_contract_change") ||
			userPermissions.includes("fob.worker_residence_change") ||
			userPermissions.includes("fob.worker_other_change"))){
				this.editionAvailable=false
			}

			var isEditMode = JSON.parse(localStorage.getItem('isEditMode'));
			if(isEditMode){
				this.setMode('edit');
			} else {
				this.setMode('default');
			}
			this.$nextTick(this.reloadData);
		},
		watch: {
			// Watch for changes in the parameters
			"$route.params": function () {
				this.reloadData();
			},
			// Watching for opening
			"editDialogs.datePicker.dialog": function (val) {
				val &&
					setTimeout(
						() =>
							(this.$refs.editDatePickerPicker.activePicker = this.editDialogs.datePicker.activePicker)
					);
			},
			"$i18n.locale": function () {
				this.reloadData();
			},
			"editDialogs.phoneNumber.value": function(val) {
				const countriesToCheckCodes = ['PL', 'GB', 'DE', 'UA'];
				const phoneNumberPrefixes = ['+48', '+44', '+49', '+380'];

				if (this.editDialogs.phoneNumber.countryPhonePrefix != null && val != null && typeof(val) == "string" && val != '') {
					var errorDetected = false;
					for(var i = 0; i < val.length; i++) {
						if ((val[i] < '0' || val[i] > '9') && val[i] != ' ' && val[i] != '+') {
							this.editDialogs.phoneNumber.error = true;
							errorDetected = true;
							break;
						}
					}

					if (!errorDetected) {
						let countryIdx = phoneNumberPrefixes.findIndex(x => { return x == this.editDialogs.phoneNumber.countryPhonePrefix; } );
						var pn = new PhoneNumber(val, countriesToCheckCodes[countryIdx]);
						if (pn.isValid()) {
							this.editDialogs.phoneNumber.error = false;
						}
						else {
							this.editDialogs.phoneNumber.error = true;
						}
					}
				}
				else{
					this.editDialogs.phoneNumber.error = false;
				}
			},
			"editDialogs.phoneNumber.countryPhonePrefix": function(val, oldValue) {
				const countriesToCheckCodes = ['PL', 'GB', 'DE', 'UA'];
				const phoneNumberPrefixes = ['+48', '+44', '+49', '+380'];

				if (this.editDialogs.phoneNumber.value != null) {
					this.editDialogs.phoneNumber.value = this.editDialogs.phoneNumber.value.replace(oldValue, val); 
				}

				if (val != null && this.editDialogs.phoneNumber.value != null && typeof(this.editDialogs.phoneNumber.value) == "string" && this.editDialogs.phoneNumber.value != '') {
					var errorDetected = false;
					for(var i = 0; i < this.editDialogs.phoneNumber.value.length; i++) {
						if ((this.editDialogs.phoneNumber.value[i] < '0' || this.editDialogs.phoneNumber.value[i] > '9') && this.editDialogs.phoneNumber.value[i] != ' ' && this.editDialogs.phoneNumber.value[i] != '+') {
							this.editDialogs.phoneNumber.error = true;
							errorDetected = true;
							break;
						}
					}

					if (!errorDetected) {
						let countryIdx = phoneNumberPrefixes.findIndex(x => { return x == val; } );
						var pn = new PhoneNumber(this.editDialogs.phoneNumber.value, countriesToCheckCodes[countryIdx]);
						if (pn.isValid()) {
							this.editDialogs.phoneNumber.error = false;
						}
						else {
							this.editDialogs.phoneNumber.error = true;
						}
					}
				}
				else{
					this.editDialogs.phoneNumber.error = false;
				}
			}
		},
		methods: {
			/**
			 * Checks whether logged user has permission to view worker's documents
			 */
			userNotHasWorkerDocumentViewPermission: function() {
				let userPermissions = localStorage.getItem("user_permissions");
				if (!userPermissions.includes("fob.worker_docs_view"))
					return true;
				return false;
			},

			loadLanguage() {
				// Page title
				var loadId = this.$route.params.profile_id;
				if (typeof loadId === "undefined") loadId = null;
				if (loadId == null) this.$emit("set-title", this.lview.myProfile);
				else this.$emit("set-title", this.lview.workerProfile);

				// Sections
				var sections = [
					{
						title: "contact",
						items: [
							"privatePhone",
							"businessPhone",
							"privateMail",
							"businessMail"
						]
					},
					{
						title: "personalDetails",
						items: ["birthDate", "personalAddress", "pesel", "drivingLicense"]
					},
					{
						title: "contractDetails",
						items: ["beginDate", "endDate"]
					},
					{
						title: "stayDetails",
						items: ["stayAddress", "contactAddressInformation"]
					},
					{
						title: "otherData",
						items: ["additionalInformation"]
					}
				];
				for (var i = 0; i < this.displayList.length && i < sections.length; i++) {
					this.displayList[i].title = this.lview[sections[i].title];
					for (
						var j = 0;
						j < this.displayList[i].items.length && j < sections[i].items.length;
						j++
					) {
						this.displayList[i].items[j].description = this.lview[
							sections[i].items[j]
						];
					}
				}
			},

			phoneNumberValidation(phoneNumber) {
				if (phoneNumber == "" || phoneNumber == null)
					return false;
				let reg = /^\+[1-9]{2}\d{9}$/i;
				if (reg.test(phoneNumber)) {
					return true;
				}
				return false;
			},

			emailValidation(email) {
				if (email == "" || email == null) {
					return false;
				}
				let reg = /^[a-z0-9\._%-]+@[a-z0-9\.-]+\.[a-z]{2,4}$/i;
				if (reg.test(email)) {
					return true;
				}
				return false;
			},

			

			escapeHtml(unsafe) {
				if(unsafe == null) return "";

				return unsafe
					.replace(/<br>/g, "\n")
			},



			//
			// Create JSON with changes to addresses & worker to be updated by API
			//
			createJSONToSend() {
				var jsonProfile = {
					// primary_postal_address: {}
				};
				//var jsonWorker = {};

				var changesProfile = this.serverProfileRequest.changes;
				var keysProfile = Object.keys(changesProfile);

				for (var i = 0; i < keysProfile.length; i++) {
					if (changesProfile[keysProfile[i]].changed) {
						jsonProfile[changesProfile[keysProfile[i]].requestId] = changesProfile[keysProfile[i]].newValue;
					}
				}

				/*var changesWorker = this.serverWorkerRequest.changes;
				var keysWorker = Object.keys(changesWorker);

				for (var i = 0; i < keysWorker.length; i++) {
					if (changesWorker[keysWorker[i]].changed) {
						jsonWorker[changesWorker[keysWorker[i]].requestId] = changesWorker[keysWorker[i]].newValue;
					}
				}*/
				return {
					profile: jsonProfile,
					//worker: jsonWorker
				};
			},
			/**
			 * Resets request data (sets all request fields to unchanged)
			 */
			resetRequestData() {
				this.serverProfileRequest.anyChanges = false;

				var changes = this.serverProfileRequest.changes;
				var keys = Object.keys(changes);
				for (var i = 0; i < keys.length; i++) {
					changes[keys[i]].changed = false;
					changes[keys[i]].newValue = null;
				}

				/*this.serverWorkerRequest.anyChanges = false;

				var changes = this.serverWorkerRequest.changes;
				var keys = Object.keys(changes);
				for (var i = 0; i < keys.length; i++) {
					changes[keys[i]].changed = false;
					changes[keys[i]].newValue = null;
				}*/
			},

			// SENDING REQUEST TO THE SERVER
			updateUserProfileData: async function () {
				if (!this.serverProfileRequest.anyChanges) {
					this.$emit("set-state", "default");
					return true;
				}

				//console.log(this.profileInformation.profileId);

				let json = this.createJSONToSend();
				this.$emit("set-state", "loading_overlayer", this.lmessages.savingChanges);

				//console.log("----> Updating User Profile at : " + localStorage.getItem("current_env"))
				//console.log("----> payload:" + user_profile_json)

				var profilePromise = null;
				//console.log(json);

				if (this.serverProfileRequest.anyChanges) {
					profilePromise = axios({
						method: "PATCH",
						url:
							localStorage.getItem("current_env") +
							"/api/v2/workers/" +
							this.profileInformation.profileId,
						headers: {
							"Content-Type": "application/json",
							Authorization: "Bearer " + localStorage.getItem("jwt")
						},
						data: {
							user_profile: json.profile
						}
					});
				}


				/*var workerPromise = null;

				if (this.serverWorkerRequest.anyChanges) {
					workerPromise = axios({
						method: "PATCH",
						url: localStorage.getItem('current_env') + '/api/worker/' + this.profileInformation.workerId,
						headers: {
							"Content-Type": "application/json",
							Authorization: "Bearer " + localStorage.getItem("jwt")
						},
						data: json.worker
					});
				}*/


				try {
					if (profilePromise != null)
						await profilePromise;
					//if (workerPromise != null)
						//await workerPromise;
					
					this.resetRequestData();
				} catch (err) {
					console.log(err);
					this.$emit("set-state", "default");

					if (err.response.data.user_profile.non_field_error != null && err.response.data.user_profile.non_field_error != undefined) {
						this.$emit('display-error', this.lerrors.user_profile.non_field_error.header, this.lerrors.user_profile.non_field_error.string, err.toString());
						return false;
					}

					if (err.response.data.user_profile.pesel != null && err.response.data.user_profile.pesel != undefined) {
						this.$emit('display-error', this.lerrors.user_profile.pesel_unique.header, this.lerrors.user_profile.pesel_unique.string, err.toString());
						return false;
					}
					
					if (err.response.data.user_profile.personal_email != null && err.response.data.user_profile.personal_email != undefined) {
						this.$emit('display-error', this.lerrors.user_profile.personal_email.header, this.lerrors.user_profile.personal_email.string, err.toString());
						return false;
					}

					if (err.response.data.user_profile.secondary_email != null && err.response.data.user_profile.secondary_email != undefined) {
						this.$emit('display-error', this.lerrors.user_profile.secondary_email.header, this.lerrors.user_profile.secondary_email.string, err.toString());
						return false;
					}

					if (err.response.data.user_profile.contract_end_date != null && err.response.data.user_profile.contract_end_date != undefined) {
						this.$emit('display-error', this.lerrors.user_profile.contract_end_date.header, this.lerrors.user_profile.contract_end_date.string, err.toString());
						return false;
					}

					if (err.response.data.user_profile.personal_mobile_number != null && err.response.data.user_profile.personal_mobile_number != undefined) {
						this.$emit('display-error', this.lerrors.user_profile.personal_mobile_number.header, this.lerrors.user_profile.personal_mobile_number.string, err.toString());
						return false;
					}
					var error_text = err.toString();
					if (err.request) {
						error_text += ': ' + err.request.responseText
					}

					if(err.message=='Network Error') {
						this.networkErrorOccured = true;
						this.$emit(
							"display-error",
							this.lmessages.networkErrorTitle,
							this.lmessages.networkError,
							error_text
						);
						return false;
					}
					else{
						this.$emit(
							"display-error",
							null,
							null,
							String(error_text)
						);
						//console.log(error_text);
						return false;
					}
				}

				this.$emit("set-state", "default");
				return true;
			},

			setMode: async function(mode) {
				if (mode == "default") {
					var success = await this.updateUserProfileData();
					if(!success){
						return;
					}
					this.resetRequestData();
				}
				this.mode = mode;
				var isEditMode = (mode == "edit")? true : false;
				localStorage.setItem("isEditMode", JSON.stringify(isEditMode));
			},
			reloadDisplay(){
				if(typeof(this.$route.params.profile_id) === 'undefined' || this.$route.params.profile_id == null)
					this.$emit("set-display", "default");
				else
					this.$emit("set-display", "back_window");
				
			},
			/**
			 * Realods user profile data on the page
			 * In case we change the page from /user-profile/ to /user-profile/:id or the other way around, it also changes toolbars properties
			 */
			reloadData: async function () {
				this.$emit("set-state", "loading");

				var loadId = this.$route.params.profile_id;
				if (typeof loadId === "undefined") loadId = null;
				// 'My Profile'
				if (loadId == null) {
					this.$emit("set-title", this.lview.myProfile);
					this.reloadDisplay();
					this.marginBottom = "166px";
					this.editButtonBottom = "24px";

					let workers = localStorage.getItem("workers");
					if (workers != null) {
						loadId = JSON.parse(localStorage.getItem('workers'))[0].id;
					}
					this.isMyProfile = true;
				}
				// Profile of some with given id (might be my id)
				else {
					this.reloadDisplay();
					this.marginBottom = "110px";
					this.editButtonBottom = "24px";
				}
				this.workerProfileId = loadId;

				/* 	getting basic info for offline mode
					firstName, middleName, lastName, privatePhoneNumber & projects of worker
					to be visible on screen
				*/

				var profileData = JSON.parse(localStorage.getItem(loadId + "_workerData"));
				if(profileData != null){
					this.profileInformation.firstName = profileData.firstName;
					this.profileInformation.middleName = profileData.middleName;
					this.profileInformation.lastName = profileData.lastName;
					this.profileInformation.privatePhoneNumber = profileData.phoneNumber;
					var jsonProjects = {
						project_assignments: profileData.worker_assignments
					}
					this.projectsList = this.adaptAssignedProjects(jsonProjects);
				}

				/*
					checking whether profile of concrete user was opened or not
					if it was, we're getting full pack of data (that is this.profileInformation) 
					saved in localStorage
				*/
				//var profileInfo = JSON.parse(localStorage.getItem("UP_" + loadId));
				//console.log(JSON.parse(localStorage.getItem("UP_" + loadId)));
				if( JSON.parse(localStorage.getItem("UP_" + loadId)) != null ){
					this.profileInformation = JSON.parse(localStorage.getItem("UP_" + loadId));
				}

				/*
				 	Finally we check whether connection is online, then we try to update app data
				 */
				this.loadLanguage();
				try {
					var response = await this.getPageData(loadId);
					if (!this.errorWhileFetchingData) {
						this.handlePageData(response);
						this.$emit('set-state', 'default');
					}
				} catch (error) {
					if(error.message == "Network Error"){
						if(localStorage.getItem("UP_" + loadId) == null){
							this.displayList.splice(1, this.displayList.length - 1);
						}
						this.offlineNotifier = true;
					}
					else{
						this.$emit('set-state', 'error');
						var error_text = error.toString()
						if (error.request) {
							error_text += ': ' + err.request.responseText
						}
						//console.log(error_text);
						this.$emit('display-error', null, null, String(error_text));
					}
				}
				
				if (!this.errorWhileFetchingData) {
					this.$emit('set-state', 'default');
					this.updatePage();
					this.state = "profileData";
				}
			},
			/**
			 * Creates user display name (lastName firstName [middleName])
			 *
			 * @param {object} adaptedData object adapted to this component
			 * @returns {String} display name
			 */
			getDisplayName(adaptedData) {
				if (adaptedData.middleName != null) {
					return (
						adaptedData.lastName +
						" " +
						adaptedData.firstName +
						" " +
						adaptedData.middleName
					);
				}
				return adaptedData.lastName + " " + adaptedData.firstName;
			},
			/**
			 * Converts date to mmm yyyy format (e.x cze 2018)
			 *
			 * @param {Date} date date to convert
			 * @returns {String} converted date
			 */
			dateToMonthYear(date) {
				return this.monthNumberToText(date.getMonth()) + " " + date.getFullYear();
			},
			/**
			 * Creates a display birthday (DD-MM-YYYY (age of ..))
			 *
			 * @param {Date} date date to use
			 * @return {String} date in format DD-MM-YYYY (age of ..)
			 */
			getDisplayBirthDate(date) {
				var today = new Date();
				if(date) {
					var birth_date = new Date(date); // convert date as it might come in JSON format when offline
					var age = today.getFullYear() - birth_date.getFullYear();
					if (
						!(
							today.getMonth() > birth_date.getMonth() ||
							(today.getMonth() == birth_date.getMonth() &&
								today.getDate >= birth_date.getDate())
						)
					) {
						age -= 1;
					}
					return (
						birth_date.getFullYear() +
						"-" +
						this.pad(birth_date.getMonth() + 1, 2) +
						"-" +
						this.pad(birth_date.getDate(), 2) +
						" (" +
						age +
						" " + this.lview.age + ")"
					);
				}
				return "";
			},
			/**
			 * Creates a display address (e.x Warszawska 1/1, 00-000 Warszawa)
			 *
			 * @param {object} address an address object of an object adapted to this component
			 * @returns {String} display address
			 */
			getDisplayAddress(address) {
				var result = "";
				if (address.streetName != null) {
					result += address.streetName;
					if (address.streetNumber != null) {
						result += " " + address.streetNumber;

						if (address.propertyNumber != null) {
							result += "/" + address.propertyNumber;
						}
					}
				}
				else{
					result += address.city;
					if (address.streetNumber != null) {
						result += " " + address.streetNumber;

						if (address.propertyNumber != null) {
							result += "/" + address.propertyNumber;
						}
					}
				}

				if (address.zipCode != null || address.city != null) {
					//if (address.streetName != null) result += ", ";
					result += ", ";
					if (address.zipCode != null) {
						result += address.zipCode + " ";
					}
					if (address.city != null && address.streetName != null) {
						result += address.city;
					}
				}

				if (result == "" || result == null) return this.lview.noData;
				return result;
			},
			dateToString(dateZone){
				// input date contains timezone shift, so to get the proper JSON text date we remove it
				var userTimezoneOffset = dateZone.getTimezoneOffset() * 60000;
				var dateOut = new Date(dateZone.getTime() - userTimezoneOffset);
				return dateOut.toJSON().substring(0,10).replace(/-/g, "."); // return 'yyyy-mm-dd' only
			},
			/**
			 * Updates page information based on provided data
			 *
			 * @param {object} adaptedData object adapted to the format accepted by this component
			 */
			updatePage() {
				var adaptedData = this.profileInformation;

				this.actionsStatus = "default";

				if (!this.isMyProfile) {
					if (adaptedData.workerStatus == 1 || adaptedData.workerStatus == 4)
						// OnBoarding | OffBoarding
						this.actionsStatus = "boarding";
					if (adaptedData.workerStatus == 2)
						// Active
						this.actionsStatus = "employee";
				}

				// Setting actions on top toolbar

				if(!this.isMyProfile){
					this.$emit("set-actions", this.actionsEmployee);
					this.$emit("set-more-actions", this.moreActionsEmployee);
				}
				else{
					this.$emit("set-actions", this.myProfileActions);
					this.$emit("set-more-actions", []);
				}

				this.displayName = this.getDisplayName(adaptedData);
				//this.displayRole = "Brak stanowiska";
				this.displaySince = "cze 2019"; //this.dateToMonthYear(adaptedData.since);

				this.projectsList.sort((a, b)=>{
					if(a.since < b.since) return -1;
					else if(a.since > b.since) return 1;
					else {
						if(a.name < b.name) return -1;
						else return 1;
					}
				});

				this.displayList[0].items[0].value =
					adaptedData.privatePhoneNumber != null
						? adaptedData.privatePhoneNumber
						: this.lview.unassigned;

				this.displayList[0].items[1].value =
					adaptedData.businessPhoneNumber != null
						? adaptedData.businessPhoneNumber
						: this.lview.unassigned;

				this.displayList[0].items[2].value =
					adaptedData.privateEmail != null
						? adaptedData.privateEmail
						: this.lview.unassigned;

				this.displayList[0].items[3].value =
					adaptedData.businessEmail != null
						? adaptedData.businessEmail
						: this.lview.unassigned;

				if(this.displayList.length > 1){
					this.displayList[1].items[0].value =
						adaptedData.birthDate != null
						? this.getDisplayBirthDate(adaptedData.birthDate)
						: this.lview.unassigned;

					this.displayList[1].items[1].value =
						adaptedData.primaryPostalAddress != null
							? this.getDisplayAddress(adaptedData.primaryPostalAddress)
							: this.lview.unassigned;

					this.displayList[1].items[2].value =
						adaptedData.pesel != null ? adaptedData.pesel : this.lview.unassigned;

					this.displayList[1].items[3].value =
						adaptedData.drivingLicense != null
							? adaptedData.drivingLicense
							: this.lview.unassigned;

					var start_date_string = this.lview.unassigned;
					if(adaptedData.contractBeginDate != null) {
						if(typeof(adaptedData.contractBeginDate) == 'string') { // when offline deserialization returns JSON Date string
							start_date_string = adaptedData.contractBeginDate
								.slice(0, 10)
								.replace("/-/g", "/");
						} else {
							start_date_string = adaptedData.contractBeginDate
								.toJSON()
								.slice(0, 10)
								.replace("/-/g", "/");
						}
					}
					this.displayList[2].items[0].value = start_date_string;

					var end_date_string = this.lview.permanentContract;
					if(adaptedData.contractEndDate != null) {
						if(typeof(adaptedData.contractEndDate) == 'string') { // when offline deserialization returns JSON Date string
							end_date_string = adaptedData.contractEndDate
								.slice(0, 10)
								.replace("/-/g", "/");
						} else {
							end_date_string = adaptedData.contractEndDate
								.toJSON()
								.slice(0, 10)
								.replace("/-/g", "/");
						}
					}
					this.displayList[2].items[1].value = end_date_string;
					
					this.displayList[3].items[0].value =
						adaptedData.secondaryPostalAddress != null
							? this.getDisplayAddress(adaptedData.secondaryPostalAddress)
							: this.lview.noData;
					this.displayList[3].items[1].value =
						adaptedData.contactAddressInformation != null
						? adaptedData.contactAddressInformation.replace(/\n(\r)?/g, "<br />")
						: this.lview.noData
					this.displayList[4].items[0].value = 
						adaptedData.additionalInformation != null
						? adaptedData.additionalInformation.replace(/\n(\r)?/g, "<br />")
						: this.lview.noData

					//
					// Attributes
					//
					this.displayList[5].items = [];
					for(var i = 0; i < this.attributesList.length; i++){
						var description = "";
						var attribute = this.attributesList[i];

						if(attribute.assignmentStartDate == null && attribute.assignmentEndDate == null){
							description = "Bezterminowo";
						}
						else{
							if(attribute.assignmentStartDate != null){
								description += (this.dateToString(attribute.assignmentStartDate));

								if(attribute.assignmentEndDate != null){
									description += " - ";
								}
							}
							if(attribute.assignmentEndDate != null){
								description += this.dateToString(attribute.assignmentEndDate);
							}
						}

						this.displayList[5].items.push({
							icon: "check",
							value: this.attributesList[i].attributeGettextName,
							description: description,
							onClick: null
						})
					}
				}
			},
			/**
			 * Adapter for projects dictionary response
			 *
			 * @param {Object} json response from server (JSON object)
			 * @returns {Array} object adapted to a format accepted by this component
			 */
			adaptProjects(json) {
				var result = [];

				for (var i = 0; i < json.length; i++) {
					result.push({
						id: json[i].id,
						name: json[i].name
					});
				}

				result.sort((a, b) => {
					if (a.name < b.name) return -1;
					else if (a.name == b.name) return 0;
					else return 1;
				});

				return result;
			},
			adaptAddressesDictionary(json) {
				var result = [];
				let address;

				result.push({
					id: null,
					street: null,
					streetNumber: null,
					propertyNumber: null,
					zipCode: null,
					city: null,
					country: null,
					region: null,
					description: this.lview.lackOfContactPostalAddress,
					deleted: false
				});
				for (var i = 0; i < json.addresses.length; i++) {
					address = json.addresses[i]
					result.push({
						id: address.id,
						street: address.street_name,
						streetNumber: address.street_number,
						propertyNumber: address.property_number,
						zipCode: address.zip_code,
						city: address.city,
						country: address.country,
						region: address.region,
						description: address.description,
						deleted: address.address_status == 0
					});
				}

				return result;
			},
			/**
			 * Adapts server answer to format accepted by this component
			 *
			 * @param {object} json Json Object, answer from the server
			 * @returns {object} object adapted to this component
			 */
			adaptUserProfile(json) {
				var primaryPostalAddress;
				var upjson = json.user_profile;
				if (
					typeof upjson["primary_postal_address"] !== "undefined" &&
					upjson["primary_postal_address"] != null
				) {
					primaryPostalAddress = {
						id: upjson["primary_postal_address"]["id"],
						streetName: upjson["primary_postal_address"]["street_name"],
						streetNumber: upjson["primary_postal_address"]["street_number"],
						propertyNumber: upjson["primary_postal_address"]["property_number"],
						zipCode: upjson["primary_postal_address"]["zip_code"],
						city: upjson["primary_postal_address"]["city"],
						region: upjson["primary_postal_address"]["region"],
						country: upjson["primary_postal_address"]["country"]
					};
				} else {
					primaryPostalAddress = null;
				}
				var secondaryPostalAddress;
				// console.log(">>>> UPJSON");
				// console.log(upjson);
				if (
					typeof upjson["contact_postal_address"] !== "undefined" &&
					upjson["contact_postal_address"] != null
				) {
					secondaryPostalAddress = {
						id: upjson["contact_postal_address"]["id"],
						streetName: upjson["contact_postal_address"]["street_name"],
						streetNumber: upjson["contact_postal_address"]["street_number"],
						propertyNumber: upjson["contact_postal_address"]["property_number"],
						zipCode: upjson["contact_postal_address"]["zip_code"],
						city: upjson["contact_postal_address"]["city"],
						region: upjson["contact_postal_address"]["region"],
						country: upjson["contact_postal_address"]["country"]
					};
				} else {
					secondaryPostalAddress = null;
				}

				//console.log(upjson["rating_comment"]);

				return {
					profileId: upjson["id"],

					rating: (upjson["rating"] == null) ? null : upjson["rating"] / 2,
					ratingDetails: upjson["rating_comment"],
					ratingDetailsDisplay: (upjson["rating_comment"] == null) ? null : upjson["rating_comment"].replace(/\n/g, "<br />").replace("\r", ""),

					workerId: json["id"],
					managerId: json["parent"],
					workerStatus: json["worker_status"],
					projectId: json["location"],
					onBoardingDate: new Date(json["on_boarding_date"]),
					offBoardingDate: new Date(json["off_boarding_date"]),

					firstName: upjson["first_name"],
					middleName: upjson["middle_name"],
					lastName: upjson["last_name"],
					privatePhoneNumber: upjson["personal_mobile_number"] == "" ? null : upjson["personal_mobile_number"],
					businessPhoneNumber: upjson["business_mobile_number"] == "" ? null : upjson["business_mobile_number"],
					privateEmail: upjson["personal_email"],
					businessEmail: upjson["secondary_email"],
					birthDate:
						upjson["birth_date"] == null ? null : new Date(upjson["birth_date"]),
					pesel: upjson["pesel"],
					contractBeginDate: upjson["contract_start_date"] == null ? null : new Date(upjson["contract_start_date"]),
					contractEndDate: upjson["contract_end_date"] == null ? null : new Date(upjson["contract_end_date"]),
					primaryPostalAddress: primaryPostalAddress,
					secondaryPostalAddress: secondaryPostalAddress,
					secondaryPostalAddressId: secondaryPostalAddress == null ? null : secondaryPostalAddress.id,
					drivingLicense: upjson["driving_license_id"]
				};
			},
			adaptUserProfileExtraInfo(json){
				this.profileInformation.contactAddressInformation = this.escapeHtml(json.contact_address_information);
				this.profileInformation.additionalInformation = this.escapeHtml(json.additional_information);
			},
			adaptManagers(json) {
				var managers = [];
				var worker;
				var sortName;

				for(var i = 0; i < json.workers.length; i++){
					worker = json.workers[i];

					if(worker.user_profile.middle_name != null){
						sortName = worker.user_profile.first_name + " " + worker.user_profile.middle_name + " " + worker.user_profile.last_name;
					}
					else{
						sortName = worker.user_profile.first_name + " " + worker.user_profile.last_name;
					}

					managers.push({
						managerId: worker.id,
						firstName: worker.user_profile.first_name,
						middleName: worker.user_profile.middle_name,
						lastName: worker.user_profile.last_name,
						sortName: sortName
					});
				}

				var myWorkers = JSON.parse(localStorage.getItem("workers"));
				worker = myWorkers[0];
				var firstName = localStorage.getItem("user_profile_first_name");
				var middleName = localStorage.getItem("user_profile_middle_name");
				var lastName = localStorage.getItem("user_profile_last_name");
				if(middleName != 'null'){
					sortName = firstName + " " + middleName + " " + lastName;
				}
				else{
					sortName = firstName + " " + lastName;
				}
				managers.push({
					managerId: worker.id,
					firstName: firstName,
					middleName: (middleName == 'null') ? null : middleName,
					lastName: lastName,
					sortName: sortName
				});

				managers.sort((a, b) => {
					if(a.sortName < b.sortName) return -1;
					else if(a.sortName == b.sortName) return 0;
					else return 1;
				});

				return managers;
			},
			adaptProjectsDictionary(json) {
				var projects = [];

				for(let item of json.projects){
					projects.push({
						id: item.id,
						name: item.name,
					})
				}
				projects.sort((a, b) => {
					if(a.name < b.name) return -1;
					else if(a.name == b.name) return 0;
					else return 1;
				});

				return projects;
			},
			adaptWorkerActiveBoardings(json){
				let result = []
				for(let item of json.boardings){
					result.push({
						id: item.id,
						onBoardingDate: item.on_boarding_date,
						projectId: item.project_assignment.project.id,
						projectName: item.project_assignment.project.name
					})
				}
				return result
			},
			adaptAssignedProjects(json){
				var result = [];

				var project;
				var assignment;
				
                // handle lack of project assignments
                if (json.project_assignments) {
                    for (var i = 0; i < json.project_assignments.length; i++) {
                        project = json.project_assignments[i].project;
						assignment = json.project_assignments[i];
                        if (assignment.assignment_status != 5 && assignment.assignment_status != 3) {
							result.push({
                                id: (project != null) ? project.id : -1,
                                name: (project != null) ? project.name : this.lview.unassigned,//"{L Unassigned}",
                                since: assignment.start_date,
                                to: assignment.finish_date
                            });
                        }
                    }
				}
				return result;
			},
			adaptAttributes(json){
				var result = [];

				var project;
				var assignment;

				if(json.attributes) {
					for(var i = 0; i < json.attributes.length; i++){
						var attribute = json.attributes[i].attribute;
						var assignment = json.attributes[i];

						result.push({
							assignmentId: assignment.id,
							attributeId: attribute.id,
							attributeIdentifier: attribute.identifier,
							attributeGettextName: attribute.gettext_name,
							assignmentStartDate: (assignment.assignment_start_date == null) ? null : new Date(assignment.assignment_start_date),
							assignmentEndDate: (assignment.assignment_end_date == null) ? null : new Date(assignment.assignment_end_date)
						});
					}
				}

				//console.log(result);

				return result;
			},
			adaptUserDetails(json) {
				let primaryPostalAddress = null;
				let secondaryPostalAddress = null;
				let userProfile = json.worker.user_profile;
				let worker = json.worker;

				// get primary address
				if (typeof(userProfile.primary_postal_address) !== 'undefined' && userProfile.primary_postal_address != null) {
					let primaryAddressObj = userProfile.primary_postal_address;
					primaryPostalAddress = {
						id: primaryAddressObj.id,
						streetName: primaryAddressObj.street_name,
						streetNumber: primaryAddressObj.street_number,
						propertyNumber: primaryAddressObj.property_number,
						zipCode: primaryAddressObj.zip_code,
						city: primaryAddressObj.city,
						region: primaryAddressObj.region,
						country: primaryAddressObj.country
					}
				}

				// get contact address
				if (typeof(userProfile.contact_postal_address) !== 'undefined' && userProfile.contact_postal_address != null) {
					let contactAddressObj = userProfile.contact_postal_address;
					secondaryPostalAddress = {
						id: contactAddressObj.id,
						streetName: contactAddressObj.street_name,
						streetNumber: contactAddressObj.street_number,
						propertyNumber: contactAddressObj.property_number,
						zipCode: contactAddressObj.zip_code,
						city: contactAddressObj.city,
						region: contactAddressObj.region,
						country: contactAddressObj.country
					}
				}
				
				// get project assignments
				let projects_result = []
				if (worker.project_assignments != null) {
					for (let i = 0; i < worker.project_assignments.length; i++) {
						let project = worker.project_assignments[i].project;
						let assignment = worker.project_assignments[i];
						if (assignment.assignment_status != 5 && assignment.assignment_status != 3) {
							projects_result.push({
								id: (project != null) ? project.id : -1,
								name: (project != null) ? project.name : this.lview.unassigned,
								since: assignment.start_date,
								to: assignment.finish_date
							});
						}
					}
				}

				// get attributes
				let attributes_result = []
				if(userProfile.attributes != null) {
					for(let i = 0; i < userProfile.attributes.length; i++){
						let attribute = userProfile.attributes[i].attribute;
						let assignment = userProfile.attributes[i];

						attributes_result.push({
							assignmentId: assignment.id,
							attributeId: attribute.id,
							attributeIdentifier: attribute.identifier,
							attributeGettextName: attribute.gettext_name,
							assignmentStartDate: (assignment.assignment_start_date == null) ? null : new Date(assignment.assignment_start_date),
							assignmentEndDate: (assignment.assignment_end_date == null) ? null : new Date(assignment.assignment_end_date)
						});
					}
				}

				return {
					// info about userProfile
					adaptedProfile: {
						profileId: userProfile.id,
						rating: userProfile.rating == null ? null : userProfile.rating / 2,
						ratingDetails: userProfile.rating_comment,
						ratingDetailsDisplay: (userProfile.rating_comment == null) ? null : userProfile.rating_comment.replace(/\n/g, "<br />").replace("\r", ""),
						workerId: worker.id,
						managerId: worker.parent,
						workerStatus: worker.worker_status,
						projectId: worker.location,
						onBoardingDate: worker.on_boarding_date == null ? null : Date(worker.on_boarding_date),
						offBoardingDate: worker.off_boarding_date == null ? null : new Date(worker.off_boarding_date),
						firstName: userProfile.first_name,
						middleName: userProfile.middle_name,
						lastName: userProfile.last_name,
						privatePhoneNumber: userProfile.personal_mobile_number == "" ? null : userProfile.personal_mobile_number,
						businessPhoneNumber: userProfile.business_mobile_number == "" ? null : userProfile.business_mobile_number,
						privateEmail: userProfile.personal_email,
						businessEmail: userProfile.secondary_email,
						birthDate: userProfile.birth_date == null ? null : new Date(userProfile.birth_date),
						pesel: userProfile.pesel,
						contractBeginDate: userProfile.contract_start_date == null ? null : new Date(userProfile.contract_start_date),
						contractEndDate: userProfile.contract_end_date == null ? null : new Date(userProfile.contract_end_date),
						primaryPostalAddress: primaryPostalAddress,
						secondaryPostalAddress: secondaryPostalAddress,
						secondaryPostalAddressId: secondaryPostalAddress == null ? null : secondaryPostalAddress.id,
						drivingLicense: userProfile.driving_license_id,
						contactAddressInformation: userProfile.contact_address_information,
						additionalInformation: userProfile.additional_information
					},
					projectsList: projects_result,
					attributesList: attributes_result,
				};

			},
			/**
			 * Callback for server request
			 *
			 * @param {object} json Json Object, answer from the server
			 */
			handlePageData(json) {
				this.$emit("set-state", "default");
				let workerDetails = this.adaptUserDetails(json.workerDetails)
				this.managersDictionary = this.adaptManagers(json.managersData);
				this.addressesDictionary = this.adaptAddressesDictionary(json.addressesDictionary);
				this.projectsDictionary = this.adaptProjectsDictionary(json.workerProjects);
				this.activeBoardings = this.adaptWorkerActiveBoardings(json.workerActiveBoardings)

				// Assigning non-deleted addresses
				this.addressesPickDictionary.splice(0, this.addressesPickDictionary.length);
				for(var i = 0; i < this.addressesDictionary.length; i++){
					if(!this.addressesDictionary[i].deleted){
						this.addressesPickDictionary.push(this.addressesDictionary[i]);
					}
				}	

				let adaptedProfile = workerDetails.adaptedProfile;
				this.projectsList = workerDetails.projectsList;
				this.attributesList = workerDetails.attributesList;
				
				//this.adaptUserProfileExtraInfo(json.profileDataExtraInfo);

				var propertiesNames = Object.keys(adaptedProfile);
				for (var i = 0; i < propertiesNames.length; i++) {
					this.profileInformation[propertiesNames[i]] = adaptedProfile[propertiesNames[i]];
				}

				// addresses
				this.profileInformation.contactAddressInformation = this.escapeHtml(adaptedProfile.contactAddressInformation);
				this.profileInformation.additionalInformation = this.escapeHtml(adaptedProfile.additionalInformation);

				this.updatePage();

				this.state = "profileData";
			},
			/**
			 * Loads local user from localStorage
			 * (another adapter)
			 */
			loadLocalUser() {
				var options = [
					"profileId",
					"firstName",
					"middleName",
					"lastName",
					"privatePhoneNumber",
					"businessPhoneNumber",
					"privateEmail",
					"businessEmail",
					"birthDate",
					"pesel",
					"primaryPostalAddress"
				];

				var adapted = {
					profileId: localStorage.getItem("user_profile_id"),
					workerId: null,
					workerStatus: null,
					firstName: localStorage.getItem("user_profile_first_name"),
					middleName: localStorage.getItem("user_profile_middle_name"),
					lastName: localStorage.getItem("user_profile_last_name"),
					privatePhoneNumber: localStorage.getItem(
						"user_profile_personal_mobile_number"
					),
					businessPhoneNumber: localStorage.getItem(
						"user_profile_business_mobile_number"
					),
					privateEmail: localStorage.getItem("user_profile_personal_email"),
					businessEmail: localStorage.getItem("user_profile_secondary_email"),
					birthDate:
						localStorage.getItem("user_profile_birth_date") == "null"
							? null
							: new Date(localStorage.getItem("user_profile_birth_date")),
					pesel: localStorage.getItem("user_profile_pesel"),
					primaryPostalAddress: {
						id: localStorage.getItem("user_profile_personal_primary_address_id"),
						streetName: localStorage.getItem(
							"user_profile_personal_primary_address_street_name"
						),
						streetNumber: localStorage.getItem(
							"user_profile_personal_primary_address_street_number"
						),
						propertyNumber: localStorage.getItem(
							"user_profile_personal_primary_address_property_number"
						),
						zipCode: localStorage.getItem(
							"user_profile_personal_primary_address_zip_code"
						),
						city: localStorage.getItem(
							"user_profile_personal_primary_address_city"
						),
						region: localStorage.getItem(
							"user_profile_personal_primary_address_region"
						),
						country: localStorage.getItem(
							"user_profile_personal_primary_address_country"
						)
					}
				};

				for (var i = 0; i < options.length; i++) {
					if (adapted[options[i]] == "null") adapted[options[i]] = null;

					this.profileInformation[options[i]] = adapted[options[i]];
				}
				if (adapted.primaryPostalAddress != null) {
					var keys = Object.getOwnPropertyNames(adapted.primaryPostalAddress);
					for (var i = 0; i < keys.length; i++) {
						if (adapted.primaryPostalAddress[keys[i]] == "null") {
							adapted.primaryPostalAddress[keys[i]] = null;
						}
					}
				}

				this.updatePage();

				this.state = "profileData";
			},
			getAddressesDictionary: async function () {
				return await axios({
					method: "GET",
					url: localStorage.getItem('current_env') + '/api/v2/workers/postal-address/',
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + localStorage.getItem("jwt")
					}
				});
			},
			/**
			 * Requests user profile from server, or loads local data (if currently logged user)
			 *
			 * @param {integer} userId id of user in the database
			 * @param {function(object):void} callback server answer will be redirected to this function (unless local user is loaded, in which case it won't be called)
			 */
			getWorkerDetails: async function (userId) {
				return axios({
					method: "GET",
					url: localStorage.getItem("current_env") + "/api/v2/workers/detail/" + userId + "?include_project_assignments=true",
					headers: {
						"Content-Type": "application/json",
						"Authorization": "Bearer " + localStorage.getItem("jwt")
					}
				});
			},
			/*getUserProfileExtraInfo: async function(userId){
				return axios.get(
					localStorage.getItem("current_env") + "/api/user-profile_extra_information/worker/" + userId,{
						headers: {
							"Content-Type": "application/json",
							Authorization: "Bearer " + localStorage.getItem("jwt")
						}
					}
				)
			},*/
			getManagers: async function (managerId) {
				var response = await axios({
					url: localStorage.getItem("current_env") + "/api/v2/workers/managers-list/" + managerId,
					method: "GET",
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + localStorage.getItem('jwt'),
					}
				});

				return response;
			},
			getWorkerProjects: async function (workerId) {
				var response = await axios({
					url: localStorage.getItem("current_env") + "/api/v2/workers/"+workerId+"/projects/",
					method: "GET",
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + localStorage.getItem('jwt'),
					}
				});
				return response;
			},
			getWorkerActiveBoardings: async function (workerId) {
				var response = await axios({
					url: localStorage.getItem("current_env") + "/api/v2/workers/"+workerId+"/active-boardings/",
					method: "GET",
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + localStorage.getItem('jwt'),
					}
				});
				return response;
			},
			/*getAssignedProjects: async function(workerId){
				var result = await axios({
					method: "GET",
					url: localStorage.getItem("current_env") + "/api/worker_with_projects_and_attributes/" + workerId + "?include_project_assignments=true",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + localStorage.getItem("jwt")
					}
				});

				return result.data;
			},*/
			/**
			 * Requests page data
			 *
			 * @param {Integer} userId id of user to load the page for
			 * @returns {Object} { profileData, projectsDictionary }
			 */
			getPageData: async function (userId) {
				var myWorkers = JSON.parse(localStorage.getItem("workers"));

				try {
					//var profilePromise = this.getUserProfile(userId);
					//var profileExtraInfoPromise = this.getUserProfileExtraInfo(userId);
					var workerDetailsPromise = this.getWorkerDetails(userId);
					var managersPromise = this.getManagers(myWorkers[0].id);
					var addressesDictionaryPromise = this.getAddressesDictionary();
					var workerProjectsPromise = this.getWorkerProjects(userId)
					var workerActiveBoardingsPromise = this.getWorkerActiveBoardings(userId)
					//var assignedProjectsAndAttributesPromise = this.getAssignedProjects(userId);

					//var profileData = await profilePromise;
					//var profileDataExtraInfo = await profileExtraInfoPromise;

					var managersData = await managersPromise;
					var addressesDictionary = await addressesDictionaryPromise;
					var workerDetailsData = await workerDetailsPromise;
					var workerProjectsData = await workerProjectsPromise;
					var workerActiveBoardingsData = await workerActiveBoardingsPromise
				} catch (error) {
					this.errorWhileFetchingData = true;
					if (error.isAxiosError) {
						switch(error.response.status){
							case 403:
								this.$emit("set-state", "ERROR", this.lview.youDoNotHavePriviledgesToThisView);
								break;
							default:
								// TODO
								break;
						}
					}
					return null;
				}
				this.errorWhileFetchingData = false;
				//var assignedProjectsAndAttributes = await assignedProjectsAndAttributesPromise;

				return {
					//profileData: profileData.data,
					//profileDataExtraInfo: profileDataExtraInfo.data,
					workerDetails: workerDetailsData.data,
					managersData: managersData.data,
					addressesDictionary: addressesDictionary.data,
					workerProjects: workerProjectsData.data,
					workerActiveBoardings: workerActiveBoardingsData.data
					//assignedProjectsAndAttributes: assignedProjectsAndAttributes
				};
			},
			/**
			 * Adds padding to the number
			 */
			pad(number, size) {
				var s = String(number);
				while (s.length < size) s = "0" + s;
				return s;
			},
			/**
			 * Converts month number to its text representation
			 *
			 * @param {integer} monthNumber number of month from 0 to 11
			 * @returns {string} text representation of the month
			 */
			monthNumberToText(monthNumber) {
				var months = [
					"sty",
					"lut",
					"mar",
					"kwi",
					"maj",
					"cze",
					"lip",
					"sie",
					"wrz",
					"paź",
					"lis",
					"gru"
				];

				return months[monthNumber];
			},

			//
			// DIALOG BOXES FOR EDITING
			//

			/**
			 * Default function for showing edit dialogs with just one edit field
			 *
			 * @param {Object} dialog Dialog to open
			 * @param {function(String):void} onInputAccept Function which receives input from editDialog after it was accepted
			 * @param {String} value Value to put into the input field
			 * @param {String} label Label of the input field
			 * @param {String} icon Name of an icon to prepend before the input field
			 */
			showEditDialog(
				dialog,
				onInputAccept = null,
				value = null,
				label = null,
				icon = null
			) {
				dialog.onInputAccept = onInputAccept;
				dialog.value = value;
				dialog.label = label;
				dialog.icon = icon;

				dialog.visible = true;
			},
			/**
			 * Shows text edit dialog
			 *
			 * @param {function(String):void} onInputAccept Function which receives input from editDialog after it was accepted
			 * @param {String} value Value to put into the input field
			 * @param {String} label Label of the input field
			 * @param {String} icon Name of an icon to prepend before the input field
			 */
			showTextEditDialog(
				onInputAccept = null,
				value = null,
				label = "Tekst",
				icon = null,
				type = "text",
				mask = null
			) {
				this.editDialogs.text.type = type;
				this.editDialogs.text.mask = mask;
				this.editDialogs.text.error = false;

				this.showEditDialog(
					this.editDialogs.text,
					onInputAccept,
					value,
					label,
					icon
				);

				if (this.$refs.textEditDialogField){
					this.$nextTick(this.$refs.textEditDialogField.focus);
				}
			},
			/**
			 * Shows phone number edit dialog
			 *
			 * @param {function(String):void} onInputAccept Function which receives input from editDialog after it was accepted
			 * @param {String} value Value to put into the input field
			 * @param {String} label Label of the input field
			 * @param {String} icon Name of an icon to prepend before the input field
			 */
			showPhoneNumberEditDialog(
				onInputAccept = null,
				value = null,
				label = null,
				icon = null,
				mask = "+## ### ### ###"
			) {
				this.editDialogs.phoneNumber.mask = mask;

				if (label == null) {
					label = this.lview.privatePhone;
				}

				if (value != '' && value != null) {
					const phoneNumberPrefixes = ['+48', '+44', '+49', '+380'];
					for(var i = 0; i < phoneNumberPrefixes.length; i++) {
						if (value.includes(phoneNumberPrefixes[i])){
							this.editDialogs.phoneNumber.countryPhonePrefix = phoneNumberPrefixes[i];
							break;
						}
					}
				}
				else {
					this.editDialogs.phoneNumber.countryPhonePrefix = null;
				}

				this.showEditDialog(
					this.editDialogs.phoneNumber,
					onInputAccept,
					value,
					label,
					icon
				);
				
				if (this.$refs.phoneEditDialogField){
					this.$nextTick(this.$refs.phoneEditDialogField.focus);
				}
			},
			/**
			 * Shows birth date edit dialog
			 *
			 * @param {function(String):void} onInputAccept Function which receives input from editDialog after it was accepted
			 * @param {String} value Date to put into the input field (format: YYYY-MM-DD)
			 * @param {String} label Label of the input field
			 * @param {String} icon Name of an icon to prepend before the input field
			 */
			showBirthDateEditDialog(
				onInputAccept = null,
				value = null,
				label = "Data urodzenia",
				icon = "event",
				min = "1920-01-01",
				max = null
			) {
				if (value == null) {
					value = new Date()
						.toJSON()
						.slice(0, 10)
						.replace("/-/g", "/");
				}
				this.editDialogs.datePicker.min = min;
				this.editDialogs.datePicker.max =
					max == null
						? new Date()
							.toJSON()
							.slice(0, 10)
							.replace("/-/g", "/")
						: max;
				this.editDialogs.datePicker.activePicker = "YEAR";
				this.editDialogs.datePicker.closeDatePickerOnChange = true;
				this.showEditDialog(
					this.editDialogs.datePicker,
					onInputAccept,
					value,
					label,
					icon
				);
			},
			showDatePickerDialog(
				onInputAccept = null,
				value = null,
				label = "Data",
				icon = "event",
				min = null,
				max = null,
				activePicker = "DATE"
			) {
				var date;
				if (value == null) {
					date = new Date();
				}
				else {
					date = new Date(value);
				}

				if (max != null || min != null) {
					var maxDate = max != null ? new Date(max) : null;
					var minDate = min != null ? new Date(min) : null;

					if (maxDate != null && date > maxDate) {
						date = maxDate;
					}
					else if (minDate != null && date < minDate) {
						date = minDate;
					}
				}

				value = date
					.toJSON()
					.slice(0, 10)
					.replace("/-/g", "/");

				this.editDialogs.datePicker.min = min;
				this.editDialogs.datePicker.max = max;
				this.editDialogs.datePicker.closeDatePickerOnChange = false;
				this.editDialogs.datePicker.activePicker = activePicker;
				this.showEditDialog(
					this.editDialogs.datePicker,
					onInputAccept,
					value,
					label,
					icon
				);
			},
			/**
			 * Shows postal address edit dialog
			 *
			 * @param {function(String):void} onInputAccept Function which receives input from editDialog after it was accepted
			 * @param {String} street Street name
			 * @param {Integer} streetNumber
			 * @param {Integer} propertyNumber
			 * @param {String} zipCode
			 * @param {String} city
			 * @param {String} country
			 */
			showPostalAddressEditDialog(
				onInputAccept = null,
				street = null,
				streetNumber = null,
				propertyNumber = null,
				zipCode = null,
				city = null,
				country = null
			) {
				var dialog = this.editDialogs.postalAddress;

				dialog.onInputAccept = onInputAccept;
				dialog.value.street = street;
				dialog.value.streetNumber = streetNumber;
				dialog.value.propertyNumber = propertyNumber;
				dialog.value.zipCode = zipCode;
				dialog.value.city = city;
				dialog.value.country = country;

				dialog.label.street = this.lview.location.streetName;
				dialog.label.streetNumber = this.lview.location.streetNumber;
				dialog.label.propertyNumber = this.lview.location.propertyNumber;
				dialog.label.zipCode = this.lview.location.zipCode;
				dialog.label.city = this.lview.location.city;
				dialog.label.country = this.lview.location.country;

				dialog.visible = true;
			},

			editDialogOnInputAccept(dialog) {
				if (dialog.onInputAccept != null) {
					var answer = dialog.onInputAccept(dialog.value);
				}
				if (typeof answer !== "undefined") dialog.visible = answer;
				else {
					dialog.visible = false;
				}
			},

			editDialogOnCancel(dialog) {
				dialog.visible = false;
			},

			commonEditDialogOnAccept(dialog, feedback) {
				if (dialog.onInputAccept != null) {
					dialog.onInputAccept(feedback);
				}
			},

			//
			// Field edit functions
			//

			// Private phone number
			onPrivatePhoneClick() {
				if (this.mode == "edit") {
					this.showPhoneNumberEditDialog(
						this.privatePhoneCallback,
						this.profileInformation.privatePhoneNumber,
						this.lview.privatePhone,
						"phone"
					);
				}
			},
			privatePhoneCallback(newValue) {
				var finalValue;
				let codePrefix = this.editDialogs.phoneNumber.countryPhonePrefix;
				if (newValue == '' || newValue == null){
					finalValue = null;
				}
				else {
					if (!newValue.includes(codePrefix)) {
						finalValue = codePrefix + newValue;
					}
					else {
						finalValue = newValue;
					}
				}

				var changed = (this.profileInformation.privatePhoneNumber != finalValue);
				this.profileInformation.privatePhoneNumber = finalValue;

				if (changed){
					this.displayList[0].items[0].value = this.profileInformation.privatePhoneNumber;
					this.serverProfileRequest.anyChanges = true;
					this.serverProfileRequest.changes.privatePhoneNumber.changed = true;
					this.serverProfileRequest.changes.privatePhoneNumber.newValue = finalValue;
				}
				this.updatePage();
			},

			// Business phone number
			onBusinessPhoneClick() {
				if (this.mode == "edit") {
					this.showPhoneNumberEditDialog(
						this.businessPhoneCallback,
						this.profileInformation.businessPhoneNumber,
						this.lview.businessPhone,
						"smartphone"
					);
				}
			},
			businessPhoneCallback(newValue) {
				var finalValue;

				let codePrefix = this.editDialogs.phoneNumber.countryPhonePrefix;
				if (newValue == '' || newValue == null){
					finalValue = null;
				}
				else {
					if (!newValue.includes(codePrefix)) {
						finalValue = codePrefix + newValue;
					}
					else {
						finalValue = newValue;
					}
				}

				var changed = (this.profileInformation.businessPhoneNumber != finalValue);
				this.profileInformation.businessPhoneNumber = finalValue;

				if (changed){
					this.serverProfileRequest.anyChanges = true;
					this.serverProfileRequest.changes.businessPhoneNumber.changed = true;
					this.serverProfileRequest.changes.businessPhoneNumber.newValue = finalValue;
				}
				this.updatePage();
			},

			emailValidate(mail) {
				var regex = /.*@.*\..*/g
				var match = mail.match(regex);
				if (match == null) {
					return false;
				}
				else {
					return true;
				}
			},
			// Private email
			onPrivateEmailClick() {
				if (this.mode == "edit") {
					this.showTextEditDialog(
						this.privateEmailCallback,
						this.profileInformation.privateEmail,
						this.lview.privateMail,
						"mail_outline"
					);
				}
			},
			privateEmailCallback(newValue) {
				if (newValue != '' && newValue != null && !this.emailValidate(newValue)) {
					this.editDialogs.text.error = true;
					return true;
				}

				var changed = (this.profileInformation.privateEmail != newValue);
				if (changed){
					this.profileInformation.privateEmail = newValue;

					this.serverProfileRequest.anyChanges = true;
					this.serverProfileRequest.changes.privateEmail.changed = true;
					if (newValue == null || newValue == "")
						this.serverProfileRequest.changes.privateEmail.newValue = null;
					else this.serverProfileRequest.changes.privateEmail.newValue = newValue;

					this.updatePage();
				}
			},

			// Business email
			onBusinessEmailClick() {
				if (this.mode == "edit") {
					this.showTextEditDialog(
						this.businessEmailCallback,
						this.profileInformation.businessEmail,
						this.lview.businessMail,
						"email"
					);
				}
			},
			businessEmailCallback(newValue) {
				if (newValue != '' && newValue != null && !this.emailValidate(newValue)) {
					this.editDialogs.text.error = true;
					return true;
				}

				var changed = (this.profileInformation.businessEmail != newValue);

				if (changed){
					this.profileInformation.businessEmail = newValue;

					this.serverProfileRequest.anyChanges = true;
					this.serverProfileRequest.changes.businessEmail.changed = true;
					if (newValue == null || newValue == "")
						this.serverProfileRequest.changes.businessEmail.newValue = null;
					else this.serverProfileRequest.changes.businessEmail.newValue = newValue;

					this.updatePage();
				}
			},

			// Birth date
			onBirthDateClick() {
				if (this.mode == "edit") {
					var date = this.profileInformation.birthDate;

					var passDate =
						date != null
							? date.getFullYear() +
							"-" +
							this.pad(date.getMonth() + 1, 2) +
							"-" +
							this.pad(date.getDate(), 2)
							: null;

					this.showBirthDateEditDialog(
						this.birthDateCallback,
						passDate,
						this.lview.birthDate,
						"event"
					);
				}
			},
			birthDateCallback(newValue) {
				var changed = (this.profileInformation.birthDate != new Date(newValue));
				if (changed){
					this.profileInformation.birthDate =
					newValue != null ? new Date(newValue) : null;

					this.serverProfileRequest.anyChanges = true;
					this.serverProfileRequest.changes.birthDate.changed = true;

					if (this.profileInformation.birthDate == null)
						this.serverProfileRequest.changes.birthDate.newValue = null;
					else this.serverProfileRequest.changes.birthDate.newValue = newValue;

					this.updatePage();
				}
			},

			// Primary postal address
			onPrimaryPostalAddressClick() {
				if (this.mode == "edit") {
					if (this.profileInformation.primaryPostalAddress == null) {
						this.profileInformation.primaryPostalAddress = {
							id: null,
							streetName: null,
							streetNumber: null,
							propertyNumber: null,
							zipCode: null,
							city: null,
							region: null,
							country: null
						};
					}

					var dialog = this.editDialogs.postalAddress;

					dialog.mode = "DEFAULT";
					dialog.onInputAccept = this.primaryPostalAddressCallback;
					dialog.address.street = this.profileInformation.primaryPostalAddress.streetName;
					dialog.address.streetNumber = this.profileInformation.primaryPostalAddress.streetNumber;
					dialog.address.propertyNumber = this.profileInformation.primaryPostalAddress.propertyNumber;
					dialog.address.zipCode = this.profileInformation.primaryPostalAddress.zipCode == null
						? null
						: this.profileInformation.primaryPostalAddress.zipCode.replace(
							"-",
							""
						),
						dialog.address.city = this.profileInformation.primaryPostalAddress.city;
					dialog.address.country = this.profileInformation.primaryPostalAddress.country == null
						? "PL"
						: this.profileInformation.primaryPostalAddress.country;

					this.$nextTick(this.$refs.postalAddressEditDialog.openDialog);
				}
			},

			getShortcutCountry(postalAddress) {
				return postalAddress["country"];
			},

			primaryPostalAddressCallback(newValues) {
				var address = this.profileInformation.primaryPostalAddress;

				let addressInfo = this.editDialogs.postalAddress;

				if (address == null) {
					address = {};
				}
				address.streetName = newValues.street == "" ? null : newValues.street;
				address.streetNumber =
					newValues.streetNumber == "" ? null : newValues.streetNumber;
				address.propertyNumber =
					newValues.propertyNumber == "" ? null : newValues.propertyNumber;
				address.zipCode =
					newValues.zipCode == null || newValues.zipCode == ""
						? null
						: newValues.zipCode;
				address.city = newValues.city == "" ? null : newValues.city;
				address.country = newValues.country == "" ? null : newValues.country;

				this.serverProfileRequest.anyChanges = true;
				this.serverProfileRequest.changes.primaryPostalAddress.changed = true;

				this.serverProfileRequest.changes.primaryPostalAddress.newValue = {
					street_name: newValues.street == "" ? null : newValues.street,
					street_number:
						newValues.streetNumber == "" ? null : newValues.streetNumber,
					property_number:
						newValues.propertyNumber == "" ? null : newValues.propertyNumber,
					zip_code:
						newValues.zipCode == null || newValues.zipCode == ""
							? null
							: newValues.zipCode,
					city: newValues.city == "" ? null : newValues.city,
					region: null,
					country:
						newValues.country == null ? null : this.getShortcutCountry(newValues)
				};

				this.updatePage();
			},

			// Begin date
			onBeginDateClick() {
				if (this.mode == "edit") {
					var date = this.profileInformation.contractBeginDate;
					var passDate;

					var maxDate = null;
					var passMaxDate;
					if (this.profileInformation.contractEndDate != null) {
						maxDate = new Date();
						maxDate.setDate(this.profileInformation.contractEndDate.getDate());
						passMaxDate = maxDate.toJSON().slice(0, 10);
					}

					passDate = date != null ? date.toJSON().slice(0, 10) : null;

					this.showDatePickerDialog(
						this.beginDateCallback,
						passDate,
						this.lview.beginDate,
						"event_available",
						null,
						passMaxDate
					);
				}
			},
			beginDateCallback(newValue) {
				this.profileInformation.contractBeginDate =
					newValue != null && newValue != "" ? new Date(newValue) : null;

				this.serverProfileRequest.anyChanges = true;
				this.serverProfileRequest.changes.contractBeginDate.changed = true;
				this.serverProfileRequest.changes.contractBeginDate.newValue = newValue;

				this.updatePage();
			},

			// End date
			onEndDateClick() {
				if (this.mode == "edit") {
					var date = (this.profileInformation.contractEndDate == null) ? new Date() : this.profileInformation.contractEndDate;

					var passDate =
						date != null
							? date.toJSON().slice(0, 10)
							: null;

					var minDate = passDate;

					if (date < this.profileInformation.contractBeginDate) {
						date.setDate(this.profileInformation.contractBeginDate.getDate());
						minDate = date.toJSON().slice(0, 10);
					}

					this.showDatePickerDialog(
						this.endDateCallback,
						passDate,
						this.lview.endDate,
						"event_busy",
						minDate
					);
				}
			},
			endDateCallback(newValue) {
				var d = this.yesNoDialog;

				var oldDate = this.profileInformation.contractEndDate;

				d.title = this.lview.contractEndDateQuestionTitle;
				d.content = this.$t('views.userProfile.contractEndDateQuestionContent', {
					newDate: newValue == null ? "\"" + this.lview.permanentContract + "\"" : newValue,
					oldDate: oldDate == null ? "\"" + this.lview.permanentContract + "\"" : oldDate.toJSON().slice(0, 10).replace('/-/g', '/')
				});
				d.yesClick = this.endDateOnYesClick;
				d.noClick = () => { };
				d.store = newValue;

				this.$nextTick(this.$refs.yesNoDialog.openDialog);

				return true;
			},
			endDateOnYesClick() {
				var d = this.yesNoDialog;

				this.profileInformation.contractEndDate =
					d.store != null && d.store != "" ? new Date(d.store) : null;

				this.serverProfileRequest.anyChanges = true;
				this.serverProfileRequest.changes.contractEndDate.changed = true;
				this.serverProfileRequest.changes.contractEndDate.newValue = d.store;

				this.editDialogs.datePicker.visible = false;

				this.updatePage();
			},

			// Secondary postal address
			onSecondaryPostalAddressClick() {
				if (this.mode == "edit") {
					if (this.profileInformation.secondaryPostalAddress == null) {
						this.profileInformation.secondaryPostalAddress = {
							id: null,
							streetName: null,
							streetNumber: null,
							propertyNumber: null,
							zipCode: null,
							city: null,
							region: null,
							country: null,
							description: null,
						};
					}

					var dialog = this.editDialogs.postalAddress;
					dialog.mode = "SELECT";
					dialog.title = null;
					dialog.stayLocationsDictionary = this.addressesPickDictionary;
					dialog.selectAddress = this.profileInformation.secondaryPostalAddressId;
					dialog.onInputAccept = this.secondaryPostalAddressCallback;

					this.$nextTick(this.$refs.postalAddressEditDialog.openDialog);
				}
			},
			secondaryPostalAddressCallback(newValues) {
				// if there is no change, then no need for any update
				if (this.profileInformation.secondaryPostalAddressId == newValues.addressId) {
					return;
				}

				// update data to populate on GUI
				
				if(newValues.addressId != null){
					var address = this.profileInformation.secondaryPostalAddress;
					if (address == null) {
						address = {};
					}
					address.streetName = newValues.street == "" ? null : newValues.street;
					address.streetNumber =
						newValues.streetNumber == "" ? null : newValues.streetNumber;
					address.propertyNumber =
						newValues.propertyNumber == "" ? null : newValues.propertyNumber;
					address.zipCode =
						newValues.zipCode == null || newValues.zipCode == ""
							? null
							: newValues.zipCode;
					address.city = newValues.city == "" ? null : newValues.city;
					address.country = newValues.country == "" ? null : newValues.country;
				}
				else{
					this.profileInformation.secondaryPostalAddress = null;
				}

				// update in UserProfile only reference ID to the new dictionary address or set NULL when cleared
				this.profileInformation.secondaryPostalAddressId = newValues.addressId;
				this.serverProfileRequest.anyChanges = true;
				this.serverProfileRequest.changes.secondaryPostalAddress.changed = true;

				this.serverProfileRequest.changes.secondaryPostalAddress.newValue = newValues.addressId;

				this.updatePage();
			},

			// Pesel
			onPeselClick() {
				if (this.mode == "edit") {
					this.showTextEditDialog(
						this.peselCallback,
						this.profileInformation.pesel,
						this.lview.pesel,
						null,
						"tel",
						"###########"
					);
				}
			},
			peselValidate(pesel) {
				if (typeof pesel !== 'string')
					return false;

				let weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
				let sum = 0;
				let controlNumber = parseInt(pesel.substring(10, 11));
				for (let i = 0; i < weight.length; i++) {
					sum += (parseInt(pesel.substring(i, i + 1)) * weight[i]);
				}
				sum = sum % 10;
				return 10 - sum === controlNumber;
			},
			peselCallback(newValue) {
				if (newValue != '' && newValue != null && !this.peselValidate(newValue)) {
					this.editDialogs.text.error = true;
					return true;
				}

				this.profileInformation.pesel =
					newValue == "" || newValue == null ? null : newValue;

				this.serverProfileRequest.anyChanges = true;
				this.serverProfileRequest.changes.pesel.changed = true;
				this.serverProfileRequest.changes.pesel.newValue =
					newValue == "" ? null : newValue;
				this.updatePage();
			},

			// Driving license
			onDrivingLicenseClick() {
				if (this.mode == "edit") {
					this.showTextEditDialog(
						this.drivingLicenseCallback,
						this.profileInformation.drivingLicense,
						this.lview.drivingLicense,
						"drive_eta"
					);
				}
			},
			drivingLicenseCallback(newValue) {
				this.profileInformation.drivingLicense = newValue;

				this.serverProfileRequest.anyChanges = true;
				this.serverProfileRequest.changes.drivingLicense.changed = true;
				this.serverProfileRequest.changes.drivingLicense.newValue = newValue;

				this.updatePage();
			},

			// Contact address information
			onContactAddressInformationClick(){
				if(this.mode == "edit"){
					this.showTextEditDialog(
						this.contactAddressInformationCallback,
						this.profileInformation.contactAddressInformation,
						this.lview.contactAddressInformation
					);
				}
			},

			contactAddressInformationCallback(newValue){
				this.profileInformation.contactAddressInformation = this.escapeHtml(newValue);

				this.serverProfileRequest.anyChanges = true;
				this.serverProfileRequest.changes.extraInformation.changed = true;
				this.serverProfileRequest.changes.extraInformation.newValue = newValue;

				this.updatePage();
			},

			// Additional information
			onAdditionalInformationClick(){
				if(this.mode == "edit"){
					this.showTextEditDialog(
						this.additionalInformationCallback,
						this.profileInformation.additionalInformation,
						this.lview.additionalInformation
					);
				}
			},
			additionalInformationCallback(newValue){
				this.profileInformation.additionalInformation = this.escapeHtml(newValue);
				//console.log(newValue);
				//console.log(this.profileInformation.additionalInformation);

				this.serverProfileRequest.anyChanges = true;
				this.serverProfileRequest.changes.additionalInformation.changed = true;
				this.serverProfileRequest.changes.additionalInformation.newValue = newValue;

				this.updatePage();
			},

			// Rating
			onRatingClick(){
				if(this.mode == "edit" && this.permissions.includes("fob.worker_rating_change")){
					this.editDialogs.rating.rating = this.profileInformation.rating;
					this.editDialogs.rating.ratingDetails = this.profileInformation.ratingDetails;
					this.editDialogs.rating.visible = true;
				}
			},
			ratingCallback(newRating, newRatingDetails){
				//console.log(newRating);
				this.profileInformation.rating = newRating;
				this.profileInformation.ratingDetails = newRatingDetails;
				this.profileInformation.ratingDetailsDisplay = (newRatingDetails == null) ? null : newRatingDetails.replace(/\n/g, "<br />").replace("\r", "");

				this.serverProfileRequest.anyChanges = true;
				this.serverProfileRequest.changes.rating.changed = true;
				this.serverProfileRequest.changes.rating.newValue = (newRating == null) ? null : newRating * 2;
				this.serverProfileRequest.changes.ratingDetails.changed = true;
				this.serverProfileRequest.changes.ratingDetails.newValue = newRatingDetails == "" ? null : newRatingDetails;

				//console.log(this.serverProfileRequest.changes);

				this.updatePage();
				
			},

			//
			//  Top toolbar actions handlers
			//
			onBoardingEditClick() {

				var worker = {
					workerId: this.profileInformation.workerId,
					status: this.profileInformation.workerStatus,
					untilDate: null,
					projectId: this.profileInformation.projectId,
					project: null
				};

				switch (this.profileInformation.workerStatus) {
					case 1:
						worker.untilDate = this.profileInformation.onBoardingDate.toJSON().slice(0, 10).replace('/-/g', '/');
						break;
					case 4:
						worker.untilDate = this.profileInformation.offBoardingDate.toJSON().slice(0, 10).replace('/-/g', '/');
						break;
					default:
						worker.untilDate = null;
				}

				this.boardingDialogs.changePlans.workers = [worker];
				this.boardingDialogs.changePlans.defaultProject = worker.projectId;
				this.boardingDialogs.changePlans.date = worker.untilDate;

				this.$nextTick(this.$refs.changeEmployeeProjectDialog.openDialog);
			},
			onChangeBoardingPlansSuccess(response) {
				var worker = response[0];
				this.profileInformation.projectId = worker.projectId;

				switch (this.profileInformation.workerStatus) {
					case 1:
						this.profileInformation.onBoardingDate = new Date(worker.untilDate);
						break;
					case 4:
						this.profileInformation.offBoardingDate = new Date(worker.untilDate);
						break;
				}
			},

			onBoardingConfirmClick() {
				this.boardingDialogs.confirmBoarding.workers = [{
					id: this.profileInformation.workerId,
					profileId: this.profileInformation.profileId
				}];

				this.$nextTick(this.$refs.confirmBoardingDialog.openDialog);
			},
			onConfirmBoardingSuccess(startDate) {
				this.profileInformation.workerStatus = 2;
				this.profileInformation.contractBeginDate = new Date(startDate);
				this.actionsStatus = 'employee';
				this.updatePage();
			},

			onBoardingResignClick() {
				this.boardingDialogs.resign.workerIds = [this.profileInformation.workerId];

				this.$nextTick(this.$refs.resignDialog.openDialog);
			},
			onResignSuccess() {
				this.profileInformation.workerStatus = 3;
				this.actionsStatus = 'default';
				this.updatePage();
			},

			onEmployeeEditClick() {
				this.employeeDialogs.changePlans.workers.splice(0, this.employeeDialogs.changePlans.workers.length);

				this.employeeDialogs.changePlans.workers.push({
					workerId: this.profileInformation.workerId,
					projectId: this.profileInformation.projectId,
					project: null
				});

				//console.log(this.employeeDialogs.changePlans.workers.length);

				this.employeeDialogs.changePlans.defaultProject = this.profileInformation.projectId;

				this.$nextTick(this.$refs.changeEmployeeProjectDialog.openDialog);
			},
			onChangeEmployeePlansSuccess(response) {
				this.profileInformation.projectId = response[0].projectId;
				this.profileInformation.managerId = response[0].managerId;
				this.reloadData()
			},

			onEmployeeResignClick() {
				this.employeeDialogs.offBoarding.workers = [{
					workerId: this.profileInformation.workerId,
					offBoardingDate: null,
				}];

				this.$nextTick(this.$refs.offBoardingDialog.openDialog);
			},

			onEmployeeDocuments() {
				let link = "/workers-documents";
				if (this.profileInformation.workerId != null) {
					link = link + "/" + this.profileInformation.workerId;
				}

				localStorage.setItem("Specific_worker_documents_search_value", this.profileInformation.lastName + " " + this.profileInformation.firstName);
				//console.log(link);
				//this.$router.push(link);
				this.$router.push(link);
			},

			onEmployeeProjects() {
				let link = "/projects";
				if (this.profileInformation.workerId != null) {
					link = link + "/" + this.profileInformation.workerId;
				}
				//console.log(link);
				this.$router.push(link);
			},

			onOffBoardingSuccess(response) {
				this.profileInformation.workerStatus = 4;
				this.profileInformation.offBoardingDate = new Date(response[0].offBoardingDate);
				this.actionsStatus = 'boarding';
				this.updatePage();
			},

			numberOfCharactersRule(item){
				//console.log(item.value);
				//console.log(item.description);
				//console.log(item.value.split('\n').length);
				if(item.description == this.lview.contactAddressInformation){
					return 400;
				}
				if(item.description == this.lview.additionalInformation){
					return 1000;
				}
			},

			openRatingDetailsDialog(){
				this.visibleRatingDetails = true;
			},

			openExtraInfoDialog(item){
				if(this.mode == 'edit'){
					this.editDialogs.extraInformation.canWrite = true;
				}
				else{
					this.editDialogs.extraInformation.canWrite = false;
				}

				if(item.id == "address_info"){
					this.editDialogs.extraInformation.limitOfCharacters = 400;
					this.editDialogs.extraInformation.value = this.profileInformation.contactAddressInformation;
				}
				else{
					this.editDialogs.extraInformation.limitOfCharacters = 1000;
					this.editDialogs.extraInformation.value = this.profileInformation.additionalInformation;
				}
				

				if(!this.editDialogs.extraInformation.canWrite && this.editDialogs.extraInformation.value != null){
					this.editDialogs.extraInformation.value = this.editDialogs.extraInformation.value.replace(/\n(\r)?/g, "<br />");
				}
				this.editDialogs.editedId = item.id;
				this.editDialogs.extraInformation.visible = true;
				this.editDialogs.extraInformation.title = item.description;
			},

			closeExtraInfoDialog(){
				if(this.editDialogs.editedId == "address_info"){
					//this.displayList[3].items[1].value = this.editDialogs.extraInformation.value;
					this.serverProfileRequest.anyChanges = true;
					this.profileInformation.contactAddressInformation = this.escapeHtml(this.editDialogs.extraInformation.value);
					this.serverProfileRequest.changes.extraInformation.changed = true;
					this.serverProfileRequest.changes.extraInformation.newValue = this.editDialogs.extraInformation.value;
				}
				else{
					this.serverProfileRequest.anyChanges = true;
					this.profileInformation.additionalInformation = this.escapeHtml(this.editDialogs.extraInformation.value);
					this.serverProfileRequest.changes.additionalInformation.changed = true;
					this.serverProfileRequest.changes.additionalInformation.newValue = this.editDialogs.extraInformation.value;
				}
				
				this.editDialogs.extraInformation.limitOfCharacters = 0;
				this.editDialogs.extraInformation.value = null;
				this.editDialogs.extraInformation.title = null;
				this.editDialogs.extraInformation.visible = false;

				this.updatePage();
			},

			// ------
			// EVENTS
			// ------
			onRequestStart() {
				this.$emit("set-state", "LOADING_OVERLAYER", this.lmessages.savingChanges);
			},
			onRequestError(error) {
				var error_text = error.toString()
				if (error.request) {
					error_text += ': ' + error.request.responseText
				}
				this.$emit("display-error", this.lmessages.errorOccuredTitle, this.errorOccured, error_text);
			},
			onRequestEnd() {
				this.$emit("set-state", "DEFAULT");
			},
			onError(error) {
				this.$emit(
					"display-error",
					error.title,
					error.message,
					error.errorCodeShort + " " + error.errorCodeLong + (error.details == null ? "" : "<br />" + error.details)
				);
			}
		}
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
	.mdl-chip__contact-name {
		width: auto !important;
		padding: 0 16px;
	}

	h1,
	h2 {
		font-weight: normal;
	}

	ul {
		list-style-type: none;
		padding: 0;
	}

	li {
		display: inline-block;
		margin: 0 10px;
	}

	a {
		color: #35495e;
	}

	.v-icon.v-icon--link.material-icons.theme--light.orange--text {
		position:relative;
		z-index: 1;
	}
	.v-icon.v-icon--link.material-icons.theme--light.orange--text::before {
		content: "\e838";
		color: #e0e0e0!important;
		position: absolute;
		z-index: -1;
	}

	.v-text-field--rounded > .v-input__control > .v-input__slot {
		padding: 10px !important;
	}

	.snackbar-offline-notifier{
		height: 30px !important;
		margin-bottom: 70px;
		top: calc(90% - 35px) !important;
		-webkit-font-smoothing: antialiased; 
		text-rendering: geometricPrecision;
		width: 80% !important;
		margin-left: 10% !important;
		margin-right: 10% !important;
		transition: 1s !important;
	}

	.v-sheet .v-snack--multi-line .v-snack__wrapper{
		height: 30px !important;
		min-height: 30px !important;
	}
</style>

<style scoped>

	.user_profile {
		margin-bottom: 56px;
	}

	.profile-header {
		padding: 20px;
		background-color: #fff;
	}

	.profile-title {
		padding: 10px;
	}

	.user-profile__middler {
		position: absolute;
		width: 100%;
		height: calc(100% - 56px);
	}

	.profile-editor__container {
		padding: 5px 10px;
		background-color: #fff;
	}

	.user-profile__edit-button {
		position: fixed;
		right: 24px;
		z-index: 4;
	}

	.user-profile__textarea{
		font-size: 14px;
		width:100%;
		height:100%;
		margin-top: 10px;
		margin-bottom: 10px;
		padding-top: 2px;
		resize: none;
		overflow: auto;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-rendering: optimizeSpeed;
	}

	a:link, a:visited, a:hover, a:active {
		color: rgba(4, 202, 90, 1);
		background-color: transparent;
		text-decoration: none;
	}
</style>
