import { render, staticRenderFns } from "./ConfirmBoardingDialog.vue?vue&type=template&id=3bb6c1d0&scoped=true&"
import script from "./ConfirmBoardingDialog.vue?vue&type=script&lang=js&"
export * from "./ConfirmBoardingDialog.vue?vue&type=script&lang=js&"
import style0 from "./ConfirmBoardingDialog.vue?vue&type=style&index=0&lang=css&"
import style1 from "./ConfirmBoardingDialog.vue?vue&type=style&index=1&id=3bb6c1d0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bb6c1d0",
  null
  
)

export default component.exports