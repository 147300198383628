<template>
	<v-layout style="max-width: 900px; margin: 0 auto; margin-top:10px">
		<v-flex xs12 class="top-distance">
			<div style="display: flex; flex-wrap: wrap;">
				<!-- role name field -->
				<v-text-field
					v-model="roleName"
					:label="lviews.roleName"
					style="calc(width:100% - 32px); max-width: 400px; padding: 0 16px"
					clearable
					maxlength="140"
					counter
					:error="isRoleError"
					:error-messages="roleErrors"
					@input="() => {this.checkRoleName(roleName);}"
					@click:clear="() => {this.showEmptyError();}"
				></v-text-field>
			</div>
		</v-flex>

		<v-list
			
		>
			<v-list-item
				style="background-color: #eeeeee; height: 54px;"
			>
				<div class="perm-wrapper">
					<div class="perm-wrapper-item perm-main-title">
						<div class="perm-title-wrapper">
							{{lviews.module}}
						</div>
					</div>
					<div class="perm-wrapper-item">
						<v-checkbox
							style="margin-left:8px; margin-top: 20px;"
							v-model="mainCheckbox"
							:indeterminate="mainCheckboxIndeterminate"
							@click="switchCheckAll()"
						></v-checkbox>
					</div>

					<div>
					</div>
				</div>
			</v-list-item>

			<v-divider></v-divider>

			<template
				v-for="moduleItem in dataTableItems"
			>
				<v-list-item
					:key="moduleItem.id"
					style="height: 58px;"
				>
					<div class="perm-wrapper">
						<div class="perm-wrapper-item perm-title">
							<div class="list-item-title-wrapper"  @click="moduleItem.subItemsOpen=!moduleItem.subItemsOpen" :style="{'cursor': moduleItem.subItems.length>0? 'pointer': 'auto'}">
								<div :style="{'visibility': moduleItem.subItems.length>0? 'visible': 'hidden'}">
									<v-icon v-if="moduleItem.subItemsOpen" class="mr-2">mdi-menu-up</v-icon>
									<v-icon v-else class="mr-2">mdi-menu-down</v-icon>
								</div>
								<div style="height: fit-content;">{{ moduleItem.module }}</div>
							</div>
						</div>

						<div class="perm-wrapper-item perm-checkbox">
							<v-checkbox
								style="margin-left:8px;"
								v-model="moduleItem.mainCheckbox"
								:indeterminate="moduleItem.mainCheckboxIndeterminate"
								@click="()=>{switchCheckAllInRow(moduleItem)}"
							></v-checkbox>
						</div>

						<!-- 
							Displayed as:
							1) Multi select (Desktop, screenWidth > 500px)
							2) Button to open a dialog with multiselect (Mobile)
						-->
						<div class="perm-wrapper-item perm-select">
							<div class="perm-select-wrapper">
								<!-- Desktop screen size -->
								<v-select
									v-if="windowWidth > 500"
									v-model="moduleItem.selectedItems"
									:items="moduleItem.displayItems"
									item-value="id"
									item-text="name"
									:menu-props="{ left: true, 'min-width': '270px', 'max-width': '350px' }"
									multiple
									style="height: 54px; max-height: 54px; overflow: hidden; margin-top: 0; padding-top: 12px"
									:placeholder="lviews.noPermissions"
									@change="onPermissionSelectionChange(moduleItem)"

									class="no-underline-select"
								>
									<!--<template v-slot:selection="{ item, index }">
										<span
											v-if="moduleItem.selectedItems.length < 2"
										>{{ item.name }}</span>
										<span
											v-else-if="index===0"
										>
											{{ lviews.numberPicked.replace("{0}", moduleItem.selectedItems.length) }}
										</span>
									</template>-->

									<template v-slot:item="{item, attrs, on}">
										<v-divider
											v-if="item.divider"
										></v-divider>

										<v-list-item
											v-else
											color="primary"
											v-on="on"
											v-bind="attrs"
											#default="{ active }"
										>
											<v-list-item-action style="margin-right: 12px">
												<v-checkbox
													:input-value="active"
													color="primary"
												></v-checkbox>
											</v-list-item-action>

											<v-list-item-content>
												<v-list-item-title style="white-space:normal;" v-text="item.name"></v-list-item-title>
											</v-list-item-content>
										</v-list-item>
									</template>
								</v-select>

								<!-- Mobile screen size -->
								<v-dialog
									v-else
									v-model="moduleItem.dialog"
									max-width="320"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											text
											fab
											ripple
											small
											v-bind="attrs"
											v-on="on"
										>
											<v-icon color="primary">
												mdi-dots-vertical
											</v-icon>
										</v-btn>
									</template>
									<v-card>
										<v-card-title class="text-h6" style="padding-left: 18px; padding-right: 18px">
											{{moduleItem.module}}
										</v-card-title>
										<v-divider></v-divider>
										<v-card-text style="padding-left:0;padding-right:0;padding-bottom:0">
											<!-- Using v-list for compatibility with multiselect -->
											<v-list style="padding: 0">
												<v-list-item-group
													v-model="moduleItem.selectedItems"
													@change="onPermissionSelectionChange(moduleItem)"
													multiple
												>
													<template v-for="(item, i) in moduleItem.displayItems">
														<v-divider
															v-if="item.divider"
															:key="`divider-${i}`"
														></v-divider>

														<v-list-item
															v-else
															color="primary"
															:key="`item-${i}`"
															:value="item.id"
														>
															<template v-slot:default="{ active }">
																<v-list-item-action style="margin-right: 12px">
																	<v-checkbox
																		:input-value="active"
																		color="primary"
																	></v-checkbox>
																</v-list-item-action>

																<v-list-item-content>
																	<v-list-item-title style="white-space:normal;" v-text="item.name"></v-list-item-title>
																</v-list-item-content>
															</template>
														</v-list-item>
													</template>
												</v-list-item-group>
											</v-list>
											<v-divider></v-divider>
										</v-card-text>
										<v-card-actions>
										<v-spacer></v-spacer>
										<v-btn
											color="green darken-1"
											text
											@click="moduleItem.dialog = false"
										>
											{{lviews.accept}}
										</v-btn>
										</v-card-actions>
									</v-card>
								</v-dialog>
							</div>
						</div>
					</div>
				</v-list-item>

				<div v-if="moduleItem.subItemsOpen" :key="'subItems'+moduleItem.id" class="ml-12">
					<template
						v-for="subItem in moduleItem.subItems"
					>
						<v-list-item
							:key="subItem.id"
							style="height: 48px;"
						>
							<div class="perm-wrapper">
								<div class="perm-wrapper-item perm-title">
									<div class="perm-title-wrapper">
										{{ subItem.module }}
									</div>
								</div>

								<div class="perm-wrapper-item perm-checkbox">
									<v-checkbox
										style="margin-left:8px;"
										v-model="subItem.mainCheckbox"
										:indeterminate="subItem.mainCheckboxIndeterminate"
										@click="()=>{switchCheckAllInRow(subItem)}"
									></v-checkbox>
								</div>

								<!-- 
									Displayed as:
									1) Multi select (Desktop, screenWidth > 500px)
									2) Button to open a dialog with multiselect (Mobile)
								-->
								<div class="perm-wrapper-item perm-select">
									<div class="perm-select-wrapper">
										<!-- Desktop screen size -->
										<v-select
											v-if="windowWidth > 500"
											v-model="subItem.selectedItems"
											:items="subItem.displayItems"
											item-value="id"
											item-text="name"
											:menu-props="{ left: true, 'min-width': '270px', 'max-width': '350px' }"
											multiple
											style="height: 54px; max-height: 54px; overflow: hidden; margin-top: 0; padding-top: 12px"
											:placeholder="lviews.noPermissions"
											@change="onPermissionSelectionChange(subItem)"

											class="no-underline-select"
										>
											<!--<template v-slot:selection="{ item, index }">
												<span
													v-if="subItem.selectedItems.length < 2"
												>{{ item.name }}</span>
												<span
													v-else-if="index===0"
												>
													{{ lviews.numberPicked.replace("{0}", subItem.selectedItems.length) }}
												</span>
											</template>-->

											<template v-slot:item="{item, attrs, on}">
												<v-divider
													v-if="item.divider"
												></v-divider>

												<v-list-item
													v-else
													color="primary"
													v-on="on"
													v-bind="attrs"
													#default="{ active }"
												>
													<v-list-item-action style="margin-right: 12px">
														<v-checkbox
															:input-value="active"
															color="primary"
														></v-checkbox>
													</v-list-item-action>

													<v-list-item-content>
														<v-list-item-title style="white-space:normal;" v-text="item.name"></v-list-item-title>
													</v-list-item-content>
												</v-list-item>
											</template>
										</v-select>

										<!-- Mobile screen size -->
										<v-dialog
											v-else
											v-model="subItem.dialog"
											max-width="320"
										>
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													text
													fab
													ripple
													small
													v-bind="attrs"
													v-on="on"
												>
													<v-icon color="primary">
														mdi-dots-vertical
													</v-icon>
												</v-btn>
											</template>
											<v-card>
												<v-card-title class="text-h6" style="padding-left: 18px; padding-right: 18px">
													{{subItem.module}}
												</v-card-title>
												<v-divider></v-divider>
												<v-card-text style="padding-left:0;padding-right:0;padding-bottom:0">
													<!-- Using v-list for compatibility with multiselect -->
													<v-list style="padding: 0">
														<v-list-item-group
															v-model="subItem.selectedItems"
															@change="onPermissionSelectionChange(subItem)"
															multiple
														>
															<template v-for="(item, i) in subItem.displayItems">
																<v-divider
																	v-if="item.divider"
																	:key="`divider-${i}`"
																></v-divider>

																<v-list-item
																	v-else
																	color="primary"
																	:key="`item-${i}`"
																	:value="item.id"
																>
																	<template v-slot:default="{ active }">
																		<v-list-item-action style="margin-right: 12px">
																			<v-checkbox
																				:input-value="active"
																				color="primary"
																			></v-checkbox>
																		</v-list-item-action>

																		<v-list-item-content>
																			<v-list-item-title style="white-space:normal;" v-text="item.name"></v-list-item-title>
																		</v-list-item-content>
																	</template>
																</v-list-item>
															</template>
														</v-list-item-group>
													</v-list>
													<v-divider></v-divider>
												</v-card-text>
												<v-card-actions>
												<v-spacer></v-spacer>
												<v-btn
													color="green darken-1"
													text
													@click="subItem.dialog = false"
												>
													{{lviews.accept}}
												</v-btn>
												</v-card-actions>
											</v-card>
										</v-dialog>
									</div>
								</div>
							</div>
						</v-list-item>

					</template>
				</div>

				<v-divider class="perm-divider"
				:key="'divider'+moduleItem.id"
					
				></v-divider>
			</template>
		</v-list>

		<!-- <v-list>
			<v-list-item
				:id="globalCheckboxes[moduleItem.id].module"
				v-for="moduleItem in dataTableItems"
				:key="moduleItem.module"
			>
				<v-list-item-content >
					<div style="display: inline-flex;">
						<div class = "col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3" style="text-align:center; margin:auto 0;">
							<div>{{moduleItem.module}}</div>
						</div>
						
						<div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2" style="text-align:center; margin:auto 0;">
							<v-checkbox
								:id="'checkbox'+globalCheckboxes[moduleItem.id].module"
								v-model="globalCheckboxes[moduleItem.id].check"
								:indeterminate="globalCheckboxes[moduleItem.id].indeterminate"
								@click="()=>{checkAllInRow(moduleItem)}"
							></v-checkbox>
						</div>

						<div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-5" style="padding-top:auto; padding-bottom:auto; item-align:center;">
							<v-select
								v-model="selectItems[moduleItem.id].selectedItems"
								:items="selectItems[moduleItem.id].items"
								multiple
								item-text="name"
							>
								<template v-slot:item="{item}">    
										<v-list-item @click="()=>{checkBox(moduleItem, item)}">
											<v-simple-checkbox
											v-model="moduleItem[item.value]"
											style="text-align: center;"
											@click="()=>{checkIndeterminate(moduleItem,item)}"
											></v-simple-checkbox>
											{{ item.name }}
										</v-list-item>
								</template>
							</v-select>  
						</div>
					</div>
				</v-list-item-content>
			</v-list-item>
		</v-list> -->
		<v-layout align-center justify-end class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-right">
			<v-btn
				primary
				color="white"
				@click="rejectRoleChanges()"
				style='vertical-align:top; margin-right:20px'
				class="smart-button-width"
			>
				{{lviews.cancel}}
			</v-btn>
			<v-btn
				primary
				color="primary"
				@click="saveRoleChanges()"
				style='vertical-align:top;'
				class="smart-button-width"
				:disabled="isRoleError||isSaveDisabled"
			>
				{{lviews.save}}
			</v-btn>
		</v-layout>
	</v-layout>
</template>
<script>
import axios from 'axios';
import fob_config from '../fob_config.js';
export default {
	name: 'ModuleConfiguration',
	data() {
		return{
			windowWidth: window.innerWidth,
			dialog: false,

			roleName: "",
			isRoleError: false,
			isSaveDisabled: false,
			roleErrors: [],

			mainCheckbox: false,
			mainCheckboxIndeterminate: false,

			initialPermissions: null,

			dataTableItems:[
			//    { 
			//			id: 0, 
			//			dialog: false, // For mobile
			//			mainCheckbox: <model>,
			//			mainCheckboxIndeterminate: <true/false>,
			//			module: "Dokumenty", 
			//			displayItems: [ ... ] // Same as items, but may contain a divider. Also enforces this order: VIEW, ADD, CHANGE, DELETE
			//			itemIds: [ <int>, <int>, ... ], // items[...].id
			//			items: [ // Permissions in the module
			//				{
			//					id: subItem.id,
			//					name: subItem.name,
			//					codename: subItem.codename,
			//					contentType: subItem.content_type
			//				}
			//			],
			//			selectedItems: [ <int>, <int>, ... ]
			//     },
			],
			globalCheckboxes:[
				// {
				//     module:"",
				//     check:false,
				//     indeterminate:false,
				// }
			],
			snackbar: {
				visible: false,
				color: "primary",
				message: ""
			},
		};
	},
	watch: {
		// windowWidth(newHeight, oldHeight) {

		// }
	},
	methods:{
		checkRoleName: function(roleName){
			this.roleErrors = [];
			if(roleName == "" || roleName == null){
				this.isRoleError = true;
				this.roleErrors.push(this.lviews.roleEmpty);
				return false;
			}
			
			this.isRoleError = false;
			this.isSaveDisabled = false;
			return true;
		},
		showEmptyError: function(){
			this.roleErrors = [];
			this.isRoleError = true;
			this.roleErrors.push(this.lviews.roleEmpty);
		},
		onResize(){
			this.windowWidth = window.innerWidth;
		},
		updateMainCheckbox(){
			if(this.dataTableItems.length > 0){
				var moduleItem;
				var first = this.dataTableItems[0].mainCheckbox;

				for(let i = 0; i < this.dataTableItems.length; i++){
					moduleItem = this.dataTableItems[i];
					if(moduleItem.mainCheckbox != first || moduleItem.mainCheckboxIndeterminate){
						this.mainCheckboxIndeterminate = true;
						this.mainCheckbox = false;
						return;
					}
				}
				this.mainCheckbox = first;
				this.mainCheckboxIndeterminate = false;
			}
		},
		onPermissionSelectionChange(moduleItem){
			if (moduleItem.items.length == moduleItem.selectedItems.length){
				moduleItem.mainCheckbox = true;
				moduleItem.mainCheckboxIndeterminate = false;
			}
			else if (moduleItem.selectedItems.length == 0){
				moduleItem.mainCheckbox = false;
				moduleItem.mainCheckboxIndeterminate = false;
			}
			else {
				moduleItem.mainCheckbox = false;
				moduleItem.mainCheckboxIndeterminate = true;
			}

			this.updateMainCheckbox();
		},

		expandRow: function(event){
			if(event.target.className != ""){
				event.stopPropagation();
			}

		},
		saveRoleChanges: async function (){
			this.$emit('set-state', "LOADING_OVERLAYER");
			var group_id = this.$route.params.group_id;
			let newPermissions = [];
			
			let lang = this.$cookies.get("language");
			if (lang == null){
				lang = "pl";
			}

			for(let i = 0; i < this.dataTableItems.length; i++){
				for(let j = 0; j < this.dataTableItems[i].selectedItems.length; j++){
					newPermissions.push(this.dataTableItems[i].selectedItems[j]);
				}
				if(this.dataTableItems[i].subItems.length>0){
					for(let j = 0; j < this.dataTableItems[i].subItems.length; j++){
						for(let k = 0; k < this.dataTableItems[i].subItems[j].selectedItems.length; k++){
							newPermissions.push(this.dataTableItems[i].subItems[j].selectedItems[k]);
						}
					}
				}
			}

			let responsePromise = null;
			if (group_id == null || group_id == undefined){
				let groupNewData = {
					permissions: newPermissions,
					name: this.roleName,
					perm_type: 0,
				}
				let apiGroupsPostLink = fob_config.api_url + "/api/v2/admin/groups/";
				responsePromise = axios({
					method: "POST",
					url: apiGroupsPostLink,
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + localStorage.getItem('jwt')
					},
					data:groupNewData
				});
			}
			else{
				let groupNewData = {
					permissions: newPermissions,
					name: this.roleName,
				}
				let apiGroupsLink = fob_config.api_url + "/api/v2/admin/groups/"+group_id;
				responsePromise = axios({
					method: "PATCH",
					url: apiGroupsLink,
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + localStorage.getItem('jwt')
					},
					data:groupNewData
				});
			}
			let response = null;
			try {
				response = await responsePromise;
			} catch (error) {
				switch(error.response.status){
					case 404:
						this.$emit('set-state', 'ERROR', this.lviews.pageNotFound);
					case 403:
						this.$router.push("/login");
					case 401:
						this.$router.push("/login");
					case 400:
						if(error.response.data.name[0].code == 'unique'){
							this.$emit('set-state', "ERROR", this.lviews.invalidRoleName);
						}
						else{
							this.$emit('set-state', "ERROR", this.lviews.incorrectRequest);
						}
						break;
					default:
						this.$emit('set-state', "ERROR");
						break;
				}
				return null;
			}
			this.nameDisabled = true;
			this.snackbar.color = "success";
			this.snackbar.message = this.lviews.changesUpdated;
			this.snackbar.visible = true;

			this.$router.go(-1);
		},
		switchCheckAllInRow(moduleItem){
			if(moduleItem.mainCheckbox){
				this.setCheckAllInRow(moduleItem, true);
			}
			else{
				this.setCheckAllInRow(moduleItem, false);
			}

			this.updateMainCheckbox();
		},
		setCheckAllInRow(moduleItem, value){
			if(value){
				moduleItem.selectedItems.splice(0, moduleItem.selectedItems.length);
				
				for(let i = 0; i < moduleItem.items.length; i++){
					moduleItem.selectedItems.push(moduleItem.items[i].id);
				}

				moduleItem.mainCheckbox = true;
				moduleItem.mainCheckboxIndeterminate = false;
			}
			else{
				moduleItem.selectedItems.splice(0, moduleItem.selectedItems.length);
				moduleItem.mainCheckbox = false;
				moduleItem.mainCheckboxIndeterminate = false;
			}
		},
		switchCheckAll() {
			if(this.mainCheckbox){
				for(let i = 0; i < this.dataTableItems.length; i++){
					this.setCheckAllInRow(this.dataTableItems[i], true);
				}
				this.mainCheckbox = true;
				this.mainCheckboxIndeterminate = false;
			}
			else{
				for(let i = 0; i < this.dataTableItems.length; i++){
					this.setCheckAllInRow(this.dataTableItems[i], false);
				}
				this.mainCheckbox = false;
				this.mainCheckboxIndeterminate = false;
			}
		},
		getPermissionsId: function(permissionSuffixes, modulePermissions){
			let newId = [];
			for (let i = 0; i < modulePermissions.length; i++) {
				for (let p = 0; p < permissionSuffixes.length; p++) {
					if ( modulePermissions[i].codename.includes(permissionSuffixes[p]) ) {
						newId.push(modulePermissions[i].id)
					}
				}
			}
			return newId;
		},
		rejectRoleChanges: function(){
			this.$router.go(-1);
		},
		getCookie:function (cname){
			let name = cname + "=";
			let decodedCookie = decodeURIComponent(document.cookie);
			let ca = decodedCookie.split(';');
			for(let i = 0; i <ca.length; i++) {
				let c = ca[i];
				while (c.charAt(0) == ' ') {
					c = c.substring(1);
				}
				if (c.indexOf(name) == 0) {
					return c.substring(name.length, c.length);
				}
			}
			return "";
		},

		adaptPermissionsData(requestResponseMdl){
			var item;
			var subItem;
			var topItems;
			var bottomItems;
			var adaptedItem;
			var adaptedSubItem;
			var result = [];

			for (let i = 0; i < requestResponseMdl.length; i++){
				item = requestResponseMdl[i];

				adaptedItem = {
					id: i,
					dialog: false,
					mainCheckbox: false,
					mainCheckboxIndeterminate: false,
					module: item.translation,
					itemIds: [],
					items: [],
					displayItems: [],
					selectedItems: [],
					subItems: [],
					subItemsOpen: true,
				};

				// Enforcing order: VIEW, ADD, CHANGE, DELETE
				topItems = [ null, null, null, null ];
				bottomItems = [ ];

				for (let j = 0; j < item.permissions.length; j++){
					// For displayItems so that we can add a divider for custom permissions
					

					subItem = item.permissions[j];
					adaptedSubItem = {
						id: subItem.id,
						name: subItem.name,
						codename: subItem.codename,
						contentType: subItem.content_type
					};
					adaptedItem.items.push(adaptedSubItem);
					adaptedItem.itemIds.push(adaptedSubItem.id);

					// Handling standard permissions

					// VIEW
					if (adaptedSubItem.codename.substring(adaptedSubItem.codename.length - 5) == "_view"){
						if(topItems[0] == null){
							adaptedSubItem.name = this.lviews.access;
							topItems[0] = adaptedSubItem;
						}
						else{
							bottomItems.push(adaptedSubItem);
						}
					}

					// ADD
					else if (adaptedSubItem.codename.substring(adaptedSubItem.codename.length - 4) == "_add"){
						if(topItems[1] == null){
							adaptedSubItem.name = this.lviews.creating;
							topItems[1] = adaptedSubItem;
						}
						else{
							bottomItems.push(adaptedSubItem);
						}
					}

					// CHANGE
					else if (adaptedSubItem.codename.substring(adaptedSubItem.codename.length - 7) == "_change") {
						if(topItems[2] == null){
							adaptedSubItem.name = this.lviews.edit;
							topItems[2] = adaptedSubItem;
						}
						else{
							bottomItems.push(adaptedSubItem);
						}
					}

					// DELETE
					else if (adaptedSubItem.codename.substring(adaptedSubItem.codename.length - 7) == "_delete") {
						if(topItems[3] == null){
							adaptedSubItem.name = this.lviews.delete;
							topItems[3] = adaptedSubItem;
						}
						else{
							bottomItems.push(adaptedSubItem);
						}
					}

					// CUSTOM
					else {
						bottomItems.push(adaptedSubItem);
					}
				}

				// Creating display list
				let adaptedDisplayItems = adaptedItem.displayItems;
				let count = 0;
				for (let k = 0; k < 4; k++){
					if(topItems[k] != null){
						count++;
						adaptedDisplayItems.push(topItems[k]);
					}
				}

				if(count > 0 && bottomItems.length > 0){
					// Add divider
					adaptedDisplayItems.push({divider: true});
				}

				for(let k = 0; k < bottomItems.length; k++){
					adaptedDisplayItems.push(bottomItems[k]);
				}

				if(item.dependencies.length>0){
					adaptedItem.subItems= this.adaptPermissionsData(item.dependencies)
				}

				result.push(adaptedItem);
			}
			return result;
		},
		fetchPermissionsData: async function(){
			let lang = this.$cookies.get("language");
			if (lang == null){
				lang = "pl";
			}
			let apiPermissionsLink = fob_config.api_url + "/api/v2/admin/permissions/?language="+lang+"&perm_type=0";
			let responsePromise = axios({
				method: "GET",
				url: apiPermissionsLink,
				headers: {
					'Authorization': 'Bearer ' + localStorage.getItem('jwt')
				}
			});
			let response = null;
			try {
				response = await responsePromise;
			} catch (error) {
				switch(error.response.status){
					case 403:
						this.$router.push("/login");
					case 401:
						this.$router.push("/login");
					case 400:
						this.$emit('set-state', "ERROR", this.lviews.incorrectRequest);
						return false;
					default:
						this.$emit('set-state', "ERROR");
						return false;
				}
				return false;
			}
			
			var adaptedData = this.adaptPermissionsData(response.data.mdl);
			this.dataTableItems = adaptedData;
			return true;

		},
		setSelectedPermissions(permissionIds){
			this.initialPermissions = permissionIds;
			let dtItem;
			let permItem;
			let currentPermId;

			// Clearing current selections
			for(let i = 0; i < this.dataTableItems.length; i++){
				this.dataTableItems[i].selectedItems.splice(0, this.dataTableItems[i].selectedItems.length);
			}

			// If this becomes too time consuming, rewrite it using binary search:
			// 1. Create a sorted table of references per permission id to proper module items
			// 2. Create a function for binary search on that table
			// 3. Update selectedItems via the defined reference
			//
			// Pretty complicated, which is why I'm not implmenting it for now, as it might not be needed
			for(let i = 0; i < permissionIds.length; i++){
				currentPermId = permissionIds[i];

				for(let j = 0; j < this.dataTableItems.length; j++){
					dtItem = this.dataTableItems[j];

					for(let k = 0; k < dtItem.items.length; k++){
						permItem = dtItem.items[k];

						if(permItem.id == currentPermId){
							dtItem.selectedItems.push(currentPermId);
						}
					}

					if(dtItem.subItems.length>0){
						for(let subItem of dtItem.subItems){
							for(let item of subItem.items){
								if(item.id==currentPermId){
									subItem.selectedItems.push(currentPermId)
								}
							}
						}
					}
				}
			}

			// Updating checkboxes
			for(let i = 0; i < this.dataTableItems.length; i++){
				this.onPermissionSelectionChange(this.dataTableItems[i]);
				if(this.dataTableItems[i].subItems.length>0){
					for(let subItem of this.dataTableItems[i].subItems){
						this.onPermissionSelectionChange(subItem)
					}
				}
			}
		},
		fetchRolePermissions: async function(roleId) {
			let promise = axios({
				method: "GET",
				url: fob_config.api_url + `/api/v2/admin/groups/${roleId}`,
				headers: {
					'Authorization': 'Bearer ' + localStorage.getItem('jwt')
				}
			});

			let response = null;
			try {
				response = await promise;
			} catch (error) {
				switch(error.response.status) {
					case 404:
						this.$emit('set-state', 'ERROR', this.lviews.pageNotFound);
						return false;
					case 403:
						this.$router.push("/login");
					case 401:
						this.$router.push("/login");
					case 400:
						this.$emit('set-state', "ERROR", this.lviews.incorrectRequest);
						return false;
					default:
						this.$emit('set-state', 'ERROR', this.lviews.unrecognizedError);
						return false;
				}
			}

			this.roleName = response.data.name;
			this.setSelectedPermissions(response.data.permissions);
			return true;
		},
		fetchPageData: async function(){
			this.$emit('set-state', 'LOADING', this.lviews.loading);

			try {
				let roleId = this.$route.params.group_id;

				var fetchPerm = await this.fetchPermissionsData();
				var fetchRole = true;
				if(typeof(roleId) !== 'undefined' && roleId != null){
					fetchRole = await this.fetchRolePermissions(roleId);
				}

				if(fetchPerm && fetchRole){
					this.$emit('set-state', 'DEFAULT');
				}
			} catch(error) {
				this.$emit('set-state', 'ERROR');
			}
		}
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		})

		this.$emit('set-title', this.lviews.mdlConfigTitle);
		this.$emit('set-display', 'BACK_WINDOW');
		let userPermissions = localStorage.getItem("user_permissions");
		if (!userPermissions.includes("fob.admin_view")) {
			this.$emit('set-state', 'ERROR', this.lviews.youDoNotHavePriviledgesToThisView);
			return;
		} 

		this.fetchPageData();
		var groupId = this.$route.params.group_id;
		if(groupId == null || groupId == undefined){
			this.isSaveDisabled = true;
		}
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.onResize); 
	},
	computed: {
		lviews: {
			get: function() {
				return this.$t('views.mdlConfig');
			}
		}
	},
	beforeMount() {
		this.screenWidth = window.innerWidth;

	},
}
</script>
<style>

.app-router-component {
	overflow-x: visible !important;
}

#dataTable table thead th {
	height: 32px;
	margin:0px;
	padding:0px;
}
#dataTable table thead tr {
	background: #53d16b;
}
#dataTable table thead th {
	font-weight: bold;
}
#dataTable table tbody tr td:nth-child(1) {
	background: lightgray;
	border-right: 1px solid rgb(84, 84, 84);
}
#dataTable table tbody tr td {
	border-bottom: 1px solid rgb(146, 141, 141);
}

.no-underline-select.v-text-field>.v-input__control>.v-input__slot:before {
		border-style: none;
}
.no-underline-select.v-text-field>.v-input__control>.v-input__slot:after {
		border-style: none;
}

.perm-wrapper {
	width: 100%;
	display: grid;
	grid-gap: 0;
	grid-template-columns: 250px 48px 1fr;
}
.perm-main-title {
	font-weight: bold;
	font-size: 16px;
}
.perm-title {
	padding-bottom: 0;
}
.perm-divider {
	margin-top: 0;
}
.perm-title-wrapper, .perm-select-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.list-item-title-wrapper{
	display: flex;
	align-items: center;
	height: 100%;
}

@media only screen and (max-width: 550px) {
	.perm-wrapper {
		grid-template-columns: 200px 48px 1fr;
	}
}

@media only screen and (max-width: 500px) {
	.perm-wrapper {
		grid-template-columns: 1fr 48px 48px;
	}
	.perm-title {
		display: flex;
		padding-bottom: 0;
	}
	.perm-divider {
		margin-top: 0;
	}
}


.top-distance {
	padding-top: 20px;
}

@media only screen and (max-width: 960px){
	.top-distance {
		padding-top: 10px;
	}
}
</style>