import Vue from 'vue'
import Router from 'vue-router'
//import TimesheetAdd from '@/components/TimesheetAdd'
//import TimesheetList from '@/components/TimesheetList'
//import TimesheetDetails from '@/components/TimesheetDetails'
import Login from '@/components/Login'
import UserProfile from '@/components/UserProfile'
import PasswordReset from '@/components/PasswordReset'
import Home from '@/components/Home'
import About from '@/components/About'
import OnBoardingPM from '@/components/OnBoardingPM'
import EmployeesPM from '@/components/EmployeesPM'
import FirstLogging from '@/components/FirstLogging'
import Documents from '@/components/Documents'
import Projects from '@/components/Projects'
// import Qr from '@/components/Qr' // add when component finished
import MyDocuments from '@/components/MyDocuments'
import DocumentViewer from '@/components/PDFDocumentsViewer'
import Settings from '@/components/Settings'
import ChangePassword from '@/components/ChangePassword' // this view is for logged user
import SettingPassword from '@/components/SettingPassword'
import ImportDocuments from '@/components/ImportDocuments'
import WorkersDocuments from '@/components/WorkersDocuments'
import AdminPanel from '@/components/AdminPanel'
import Configuration from '@/components/Configuration'
import UserList from '@/components/UserList'
import Preferences from '@/components/Preferences'
import ModuleConfiguration from '@/components/ModuleConfiguration'
import DataSharingConfiguration from '@/components/DataSharingConfiguration'
import Permissions from '@/components/Permissions'
import Company from '@/components/Company'
import AddressList from '@/components/AddressList'

import Dev from '@/components/Dev'

Vue.use(Router)

export default new Router({
    routes: [
        // simple redirection to the login page (can be improved - to handle the last logged-in page better)
        {
            path: '/',
            redirect: {
                name: 'login'
            },
        },
        {
            path: '/login',
            name: 'login',
            component: Login
        },
        {
            path: '/home',
            name: 'home',
            component: Home
        },
        {
            path: '/user-profile/:profile_id?',
            name: 'User Profile',
            component: UserProfile
        },
        {
            path: '/password-reset',
            name: 'Password Reset',
            component: PasswordReset
        },
        {
            path: '/about',
            name: 'about',
            component: About
        },
        {
            path: '/boarding',
            name: 'boarding',
            component: OnBoardingPM
        },
        {
            path: '/employees',
            name: 'employees',
            component: EmployeesPM
        },
        {
            path: '/auth/activate-account/:token',
            name: 'First Logging',
            component: FirstLogging
        },
        {
            path: '/documents/:profile_id?',
            name: 'Documents',
            component: Documents
        },
        {
            path: '/projects/:worker_id',
            name: 'Projects',
            component: Projects
        },
        {
            path: '/my-documents',
            name: 'MyDocuments',
            component: MyDocuments
        },
        {
            path: '/documents-viewer/:document_slug?',
            name: 'DocumentsViewer',
            component: DocumentViewer
        },
        {
            path: '/settings',
            name: 'Settings',
            component: Settings
        },
        {
            path: '/change-password',
            name: 'ChangePassword',
            component: ChangePassword
        },
        {
            path: '/set-new-password/:token',
            name: 'SettingPassword',
            component: SettingPassword
        },
        {
            path: '/import-documents',
            name: 'ImportDocuments',
            component: ImportDocuments
        },
        {
            path: '/workers-documents/:workerId?',
            name: 'WorkersDocuments',
            component: WorkersDocuments
        },

        {
            path: '/admin-panel',
            name: 'AdminPanel',
            component: AdminPanel
        },

        {
            path: '/config',
            name: 'Configuration',
            component: Configuration
        },

        {
            path: '/user-list',
            name: 'UserList',
            component: UserList
        },

        {
            path: '/preferences',
            name: 'Preferences',
            component: Preferences
        },

        {
            path: '/ds-config/:group_id?',
            name: 'DataSharingConfiguration',
            component: DataSharingConfiguration
        },

        {
            path: '/mdl-config/:group_id?',
            name: 'ModuleConfiguration',
            component: ModuleConfiguration
        },

        {
            path: '/permissions',
            name: 'Permissions',
            component: Permissions
        },

        {
            path: '/company/:company_id?',
            name: 'Company',
            component: Company
        },

        {
            path: "/addresses",
            name: 'AddressList',
            component: AddressList
        },

        // DEFAULT PAGE
        {
            path: '*',
            name: 'error404',
            component: OnBoardingPM
        }
    ]
})