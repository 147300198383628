<template>
    <v-layout style="margin-top: 10px;">
        <!-- DESKTOP search bar -->
        <v-layout class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding-top: 10px; padding-bottom: 0px; margin-bottom: 20px;">
            <v-text-field
                v-model="searchValue"
                prepend-icon="mdi-magnify"
                :label=lviews.documents.search
                single-line
                hide-details
                outlined
                @input="updateDocumentsTable"
                class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12"
                :class="screenWidth >= 600 ? 'align-self-center':''"
            ></v-text-field>
            <!-- DESKTOP header part -->
            <v-select
                v-model="sortingGrouper.grouping"
                @change="updateDocumentsCacheStore"
                :items="groupSelectItems"
                :label=lviews.documents.groupBy
                @input="groupDocumentsTableElements"
                class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 align-self-center"
                style="padding-top: 0px; padding-bottom: 0px;"
                hide-details
                v-if="screenWidth >= 600"
                xs4
            ></v-select>
            <!--<v-flex class="mx-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 flex-column d-flex justify-content-center" v-if="screenWidth >= 600">
                <v-icon style="color: green;">
                    mdi-download
                </v-icon>
                <span style="font-size: 15px; text-align: center;">
                    Pobierz wszystkie
                </span>
            </v-flex>-->
        </v-layout>

        <!-- MOBILE header -->
        <v-layout class="col-xl-6 col-lg-6 col-md-6 col-sm-8 col-xs-12" style="padding-top: 0px; padding-bottom: 0px;" v-if="screenWidth < 600">
            <v-select
                v-model="sortingGrouper.sorting"
                :items="orderSelectItems"
                :label=lviews.documents.sortBy
                @input="sortDocumentsTableElements"
                @change="updateDocumentsCacheStore"
                class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-5"
                style="padding-top: 0px; padding-bottom: 0px;"
                hide-details
            ></v-select>
            <v-select
                v-model="sortingGrouper.grouping"
                :items="groupSelectItems"
                :label=lviews.documents.groupBy
                @input="groupDocumentsTableElements"
                @change="updateDocumentsCacheStore"
                class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-5"
                style="padding-top: 0px; padding-bottom: 0px;"
                hide-details
            ></v-select>
        </v-layout>

        <!-- DESKTOP -->
        <v-list v-if="screenWidth >= 600">
            <!-- DESKTOP : listing only grouped documents without searchValue-->
            <div v-if="sortingGrouper.grouping != 0 && sortingGrouper.grouping != -1" style="padding-top: 0px; padding-bottom: 0px;">
                <v-list-item>
                    <v-flex xs1></v-flex>
                    <v-flex xs5 @click="() => { sortDocumentsByNameHandler(); }" style="border-right: 2px solid lightgray; padding-left: 15px;"> {{ tableHeaders[0].text }} <v-icon v-if="nameArrowDown">mdi-arrow-down</v-icon> <v-icon v-else>mdi-arrow-up</v-icon></v-flex>
                    <v-flex xs4 @click="() => { sortDocumentsByDateHandler(); }" style="border-right: 2px solid lightgray; padding-left: 15px;"> {{ tableHeaders[1].text }} <v-icon v-if="dateArrowDown">mdi-arrow-down</v-icon> <v-icon v-else>mdi-arrow-up</v-icon></v-flex>
                    <v-flex xs2 style="padding-left: 15px;"> {{ tableHeaders[2].text }} </v-flex>
                </v-list-item>
                <v-list-group
                    v-for="(group, index) in renderedDocuments"
                    :key="index">
                    <template v-slot:activator>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ group.groupName }} ({{group.items.length}})
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <v-list-item v-for="document in group.items" :key=document.id style="margin: 10px 10px; border-bottom: 2px solid lightgray;">
                        <v-flex xs1> 
                            <v-icon :style="document.iconStyle"> {{ document.icon }} </v-icon>
                        </v-flex>

                        <v-flex xs5 v-if="document.seen" @click="() => { showPDF(document); }" class="document-name-cut-when-too-long"> {{ document.name }} </v-flex>
                        <v-flex xs5 v-else @click="() => { showPDF(document); }" class="document-name-cut-when-too-long"> <strong>{{ document.name }}</strong> </v-flex>
                        <v-flex xs4 @click="() => { showPDF(document); }" style="padding-left: 15px;"> {{ document.dateToDisplay }} </v-flex>

                        <v-flex xs2>
                            <v-icon style="color: green; margin-left: 25px;" @click="() => { downloadDocument(document.slug, document.name); }">mdi-download</v-icon>
                        </v-flex>
                    </v-list-item>
                </v-list-group>
            </div>
            <!-- DESKTOP: listing documents without grouping or searching for some values -->
            <div v-else style="padding-top: 0px; padding-bottom: 0px;">
                <v-list-item>
                    <v-flex xs1></v-flex>
                    <v-flex xs5 @click="() => { sortDocumentsByNameHandler(); }" style="border-right: 2px solid lightgray; padding-left: 15px;"> {{ tableHeaders[0].text }} <v-icon v-if="nameArrowDown">mdi-arrow-down</v-icon> <v-icon v-else>mdi-arrow-up</v-icon></v-flex>
                    <v-flex xs4 @click="() => { sortDocumentsByDateHandler(); }" style="border-right: 2px solid lightgray; padding-left: 15px;"> {{ tableHeaders[1].text }} <v-icon v-if="dateArrowDown">mdi-arrow-down</v-icon> <v-icon v-else>mdi-arrow-up</v-icon></v-flex>
                    <v-flex xs2 style="padding-left: 15px;"> {{ tableHeaders[2].text }} </v-flex>
                </v-list-item>
                <div v-if="renderedDocuments.length > 0">
                    <v-list-item v-for="document in renderedDocuments" :key=document.id style="margin: 10px 10px; border-bottom: 2px solid lightgray;">
                        <v-flex xs1> 
                            <v-icon :style="document.iconStyle"> {{ document.icon }} </v-icon>
                        </v-flex>

                        <v-flex xs5 v-if="document.seen" class="document-name-cut-when-too-long" @click="() => { showPDF(document); }"> {{ document.name }} </v-flex>
                        <v-flex xs5 v-else @click="() => { showPDF(document); }" class="document-name-cut-when-too-long"> <strong>{{ document.name }}</strong> </v-flex>
                        <v-flex xs4 @click="() => { showPDF(document); }" style="padding-left: 15px;"> {{ document.dateToDisplay }} </v-flex>

                        <v-flex xs2>
                            <v-icon style="color: green; margin-left: 25px;" @click="() => { downloadDocument(document.slug, document.name); }" >mdi-download</v-icon>
                        </v-flex>
                    </v-list-item>
                </div>
                <v-layout v-else-if="documents.length == 0" class="content-middler" style="height: 50px;" column justify-center align-center>
                    {{ lviews.documents.noDocuments }}
                </v-layout>
                <v-layout v-else class="content-middler" style="height: 50px;" column justify-center align-center>
                    {{ lviews.documents.noMatchingDocumentsFound }}
                </v-layout>
            </div>
        </v-list>

        <!-- MOBILE -->
        <v-list v-else>
            <!-- listing documents with grouping if grouping condition was chosen -->
            <div v-if="sortingGrouper.grouping != 0 && sortingGrouper.grouping != -1" style="padding-top: 0px; padding-bottom: 0px;">
                <v-list-group
                    v-for="(group, index) in renderedDocuments"
                    :key="index">
                    <template v-slot:activator>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ group.groupName }} ({{group.items.length}})
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <v-list-item
                        v-for="document in group.items"
                        :key="document.id"
                        style="margin: 10px 10px; border-bottom: 2px solid lightgray;"
                    >
                        <v-flex xs1> 
                            <v-icon :style="document.iconStyle"> {{ document.icon }} </v-icon>
                        </v-flex>

                        <v-flex xs9 @click="() => { showPDF(document); }">
                            <v-list-item-content>
                                <v-list-item-title v-if="document.seen" class="document-name-cut-when-too-long"> {{ document.name }} </v-list-item-title>
                                <v-list-item-title v-else class="document-name-cut-when-too-long"> <strong> {{ document.name }} </strong> </v-list-item-title>
                                <v-list-item-subtitle> {{ document.dateToDisplay }} </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-flex>

                        <v-flex xs2>
                            <v-icon style="color: green;" @click="() => { downloadDocument(document.slug, document.name); }">mdi-download</v-icon>
                        </v-flex>
                    </v-list-item>
                </v-list-group>
            </div>
            <!-- listing documents without grouping -->
            <div v-else style="padding-top: 0px; padding-bottom: 0px;">
                <div v-if="renderedDocuments.length > 0">
                    <v-list-item v-for="document in renderedDocuments" :key=document.id style="margin: 10px 10px; border-bottom: 2px solid lightgray;">
                        <v-flex xs1> 
                            <v-icon :style="document.iconStyle"> {{ document.icon }} </v-icon>
                        </v-flex>

                        <v-flex xs9 @click="() => { showPDF(document); }">
                            <v-list-item-content>
                                <v-list-item-title v-if="document.seen" class="document-name-cut-when-too-long"> {{ document.name }} </v-list-item-title>
                                <v-list-item-title v-else class="document-name-cut-when-too-long"> <strong> {{ document.name }} </strong> </v-list-item-title>
                                <v-list-item-subtitle> {{ document.dateToDisplay }} </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-flex>

                        <v-flex xs2>
                            <v-icon style="color: green;" @click="() => { downloadDocument(document.slug, document.name); }">mdi-download</v-icon>
                        </v-flex>
                    </v-list-item>
                </div>
                <v-layout v-else-if="documents.length == 0" class="content-middler" style="height: 50px;" column justify-center align-center>
                    {{ lviews.documents.noDocuments }}
                </v-layout>
                <v-layout v-else class="content-middler" style="height: 50px;" column justify-center align-center>
                    {{ lviews.documents.noMatchingDocumentsFound }}
                </v-layout>
            </div>
        </v-list>

        <v-dialog persistent v-model="downloadConfirmationDialog.visible"
			  max-width="295">
            <v-card>
                <v-card-title style="font-size: 20px; font-weight: 550;">{{ downloadConfirmationDialog.title }}</v-card-title>

                <v-card-text style="text-align:justify">{{ downloadConfirmationDialog.content }}</v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="green darken-1" style="font-size: 12px;"
                        text
                        @click="downloadConfirmationDialog.onDownloadClick">
                        {{ downloadConfirmationDialog.downloadLabel }}
                    </v-btn>

                    <v-btn color="green darken-1" style="font-size: 12px;"
                        text
                        @click="downloadConfirmationDialog.onCancelClick">
                        {{ downloadConfirmationDialog.cancelLabel }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-layout>
</template>

<script>
import axios from 'axios';
import fob_config from '../fob_config.js';

export default {
    name: 'MyDocuments',
    data: function(){
        return {
            today: new Date(),
            searchValue: '',
            renderedDocuments: [
                /*
                {
                    id: {Number},
                    name: {String},
                    date: {Date},
                    icon: {String},
                    iconStyle: {Object},
                    type: {String},
                    mimetype: {String},
                    dateToDisplay: {String},
                    seen: {Bool}
                }
                */
            ], // the same structure as documents, but it is used to render
            documents: [
                /**
                    {
                        id: {int}
                        name: {string}
                        date: {date}
                        icon: {string}
                        iconStyle: {object},
                        mimetype: {String},
                        type: {String},
                        slug: {String},
                        seen: {Bool}
                    }                
                 */
            ],
            tableHeaders: [
                {
                    text: "",
                    align: 'start',
                    filterable: true,
                    value: 'name'
                },
                {
                    text: "",
                    filterable: true,
                    value: 'date'
                },
                {
                    text: "",
                    filterable: false,
                    value: 'download'
                }
            ],
            orderSelectItems: [
                {
                    text: '',
                    value: 0
                },
                {
                    text: '',
                    value: 1
                },
                {
                    text: '',
                    value: 2
                },
                {
                    text: '',
                    value: 3
                },
            ],
            groupSelectItems: [
                {
                    text: '',
                    value: 0,
                    /*groups: [{
                        groupName: "Wszystkie",
                        items: []
                    }]*/
                },
                {
                    text: '',
                    value: 1,
                    groups: [
                        /*{
                            groupName: "",
                            items: [],
                        }*/
                    ]
                },
                {
                    text: '',
                    value: 2,
                    groups: [
                        {
                            groupName: "",
                            items: [],
                            rangeDate: null
                        },
                        {
                            groupName: "",
                            items: [],
                            rangeDate: null
                        },
                        {
                            groupName: "",
                            items: [],
                            rangeDate: null,
                        },
                        {
                            groupName: "",
                            items: [],
                            rangeDate: null
                        }
                    ]
                },
            ],

            nameArrowDown: false,
            dateArrowDown: false,
            /**
             * 0 - sort by date (most recent)
             * 1 - sort by date (least recent)
             * 2 - sort by name (ascending)
             * 3 - sort by name (descending)
             */
            sortingGrouper: {
                grouping: -1,
                sorting: -1,
            },
            
            // it's necessary while searching documents by search value
            sortingGrouperBackup: {
                grouping: -1,
                sorting: -1
            },
            screenWidth: window.innerWidth,
            downloadConfirmationDialog: {
                visible: false,
                title: "",
                content: "",
                downloadLabel: "",
                cancelLabel: "",

                onDownloadClick: () => {},
                onCancelClick: () => {}
            }
        }
    },
    methods: {
        downloadDocument: async function(slug, documentName){
            this.$emit('set-state', "LOADING_OVERLAYER");
            var xhr = new XMLHttpRequest();
            var that = this;
            xhr.onload = function() {
                that.$emit('set-state', 'default');
                var reader = new FileReader();
                reader.onloadend = function() {
                    const linkSource = reader.result;
                    const downloadLink = document.createElement('a');

                    document.body.appendChild(downloadLink);
                    downloadLink.href = linkSource;
                    downloadLink.target = '_self';
                    downloadLink.download = documentName;
                    downloadLink.click();
                }
                reader.readAsDataURL(xhr.response);
            };
            xhr.onerror = function(){
                that.$emit('set-state', 'default');
                that.$emit('display-error', that.lmessages.errorOccurredTitle, that.lview.couldNotDownloadDocument);
            };
            xhr.open('GET', fob_config.api_url + "/api/v2/my-documents/" + slug + "/download");
            xhr.responseType = 'blob';
            xhr.setRequestHeader("Authorization", "Bearer " + localStorage.getItem('jwt'));
            xhr.send();
        },
        /**
         * This method updates rendered documents.
         * If any value to search was provided, then it finds all matching elements.
         * If there is no value to search, then 'renderedDocuments' are being changed by grouDocumentsTableElements method;
         */
        updateDocumentsTable: function(){
            if (this.searchValue.length > 0 && (this.sortingGrouper.grouping == 0 || this.sortingGrouper.grouping == -1)){
                this.renderedDocuments = [];
                let documentToRender;
                for(var i = 0; i < this.documents.length; i++){
                    if (this.documents[i].name.toUpperCase().includes(this.searchValue.toUpperCase())){
                        documentToRender = this.createRenderedDocumentObject(this.documents[i]);
                        this.renderedDocuments.push(documentToRender);
                    }
                }
            } else {
                this.groupDocumentsTableElements();
            }
            this.updateDocumentsCacheStore();
        },
        /**
         * This method sorts documents
         * If documents are grouped, then all groups are sorted according to predicates given by user
         * In other case, simple list of objects is sorted according to them.
         */
        sortDocumentsTableElements: function(){
            let sortingPredicate = (doc1, doc2) => {
                if (this.sortingGrouper.sorting == 0){
                    if(doc2.date.getTime() === doc1.date.getTime()){
                        return doc2.id - doc1.id;
                    }

                    return doc2.date - doc1.date;
                } else if (this.sortingGrouper.sorting == 1) {
                    if(doc2.date.getTime() === doc1.date.getTime()){
                        return doc1.id - doc2.id;
                    }

                    return doc1.date - doc2.date;
                } else if (this.sortingGrouper.sorting == 2) {
                    return doc1.name > doc2.name ? 1 : -1;
                } else if (this.sortingGrouper.sorting == 3) {
                    return doc1.name <= doc2.name ? 1 : -1;
                }
            };

            if (this.sortingGrouper.grouping != -1 && this.sortingGrouper.grouping != 0){
                for(var i = 0; i < this.renderedDocuments.length; i++){
                    this.renderedDocuments[i].items.sort(sortingPredicate);
                }
            } else {
                this.renderedDocuments.sort(sortingPredicate);
            }
        },
        sortDocumentsByNameHandler: function(){
            this.nameArrowDown = !this.nameArrowDown;
            if (this.nameArrowDown){
                this.sortingGrouper.sorting = 2;
            } else {
                this.sortingGrouper.sorting = 3;
            }
            this.sortDocumentsTableElements();
            this.updateDocumentsCacheStore();
        },
        sortDocumentsByDateHandler: function(){
            this.dateArrowDown = !this.dateArrowDown;
            if (this.dateArrowDown){
                this.sortingGrouper.sorting = 1;
            } else {
                this.sortingGrouper.sorting = 0;
            }
            this.sortDocumentsTableElements();
            this.updateDocumentsCacheStore();
        },

        /**
         * This method groups documents rendered on the screen,
         * If no grouping conditions (group by date, document type etc.) were provided, then it rewrites all documents to single list (normal listing for elements)
         * In other case, documents are grouped
         */
        groupDocumentsTableElements: function(){
            if (this.sortingGrouper.grouping == 0 || this.sortingGrouper.grouping == -1){
                this.renderedDocuments = [];
                let documentToRender;
                this.documents.forEach(document => {
                    documentToRender = this.createRenderedDocumentObject(document);
                    this.renderedDocuments.push(documentToRender);
                });
                if (this.searchValue.length > 0){
                    let filteredDocuments = [];
                    this.renderedDocuments.forEach(document => {
                        if(document.name.toUpperCase().includes(this.searchValue.toUpperCase())){
                            filteredDocuments.push(document);
                        }
                    });
                    this.renderedDocuments = filteredDocuments;
                }
            } else {
                this.renderedDocuments = JSON.parse(JSON.stringify(this.groupSelectItems[this.sortingGrouper.grouping].groups));
                let documentToRender;
                this.documents.forEach(document => {
                    let predicate = () => {};
                    switch(this.sortingGrouper.grouping){
                        case 1: // po typie
                            predicate = (group) => {return group.groupName.toUpperCase() == document.type_text.toUpperCase();};
                            break;
                        case 2: // po dacie
                            predicate = (group) => {
                                return new Date(group.rangeDate) < document.date;
                            };
                            break;
                        default:
                            predicate = () => {return false;};
                    }
                    let documentGroupIndex = this.findGroupIndex(this.renderedDocuments, predicate);
                    documentToRender = this.createRenderedDocumentObject(document);
                    if(documentGroupIndex != -1){
                        this.renderedDocuments[documentGroupIndex].items.push(documentToRender);
                    }
                });

                if(this.searchValue.length > 0){
                    let filteredGroupedDocuments = JSON.parse(JSON.stringify(this.groupSelectItems[this.sortingGrouper.grouping].groups));
                    for(var i = 0; i < this.renderedDocuments.length; i++){
                        this.renderedDocuments[i].items.forEach(document => {
                            if(document.name.toUpperCase().includes(this.searchValue.toUpperCase())){
                                filteredGroupedDocuments[i].items.push(document);
                            }
                        });
                    }
                    this.renderedDocuments = filteredGroupedDocuments;
                }
            }
            if (this.sortingGrouper.sorting != -1){
                this.sortDocumentsTableElements();
            }
            
            this.reduceGroups();
        },

        reduceGroups: function(){
            if (this.renderedDocuments.length > 0 && this.renderedDocuments[0].items == undefined){
                return;
            }

            let groupsToRemove = [];
            for(var i = 0; i < this.renderedDocuments.length; i++){
                if (this.renderedDocuments[i].items.length == 0){
                    groupsToRemove.push(this.renderedDocuments[i].groupName.toUpperCase());
                }
            }

            for(var i = 0; i < groupsToRemove.length; i++){
                let predicate = (group) => { return group.groupName.toUpperCase() == groupsToRemove[i]; };
                let index = this.findGroupIndex(this.renderedDocuments, predicate);
                this.renderedDocuments.splice(index, 1);
            }
        },

        createRenderedDocumentObject: function(document){
            let month = Number(document.date.getMonth()) + 1;
            if (month >= 10 && 12 >= month){
                month = String(month);
            } else if (1 <= month && month <= 9){
                month = '0' + String(month);
            } else {
                month = "";
            }
            return {
                id: document.id,
                name: document.name,
                date: document.date,
                icon: document.icon,
                iconStyle: document.iconStyle,
                type: document.type,
                mimetype: document.mimetype,
                dateToDisplay: document.date.getFullYear() + "." + month + "." + document.date.toDateString().substr(8, 2),
                seen: document.seen,
                slug: document.slug,
                type_text: document.type_text,
            }
        },
        findGroupIndex: function(groups, predicate){
            let testPassed;
            for(var i = 0; i < groups.length; i++){
                testPassed = predicate(groups[i]);
                if (testPassed){
                    return i;
                }
            }
            return -1;
        },
        showPDF: function(document){
            this.$emit('set-title', document.name);
            localStorage.setItem('CurrentlySeenDocument', JSON.stringify(document));
            this.$router.push("/documents-viewer/" + document.slug);
        },

        //EVENT HANDLERS
        downloadAllDocuments: function(){
            this.downloadConfirmationDialog.visible = false;
            //console.log("downloading all documents...");
            // TODO request to download all documents
        },
        adaptDocuments: function(responseData){
            //console.log(responseData);
            this.documents = [];
            let date, icon, iconStyle;
            let filesTypes = [];
            if (responseData != null){
                for(var i = 0; i < responseData.length; i++){
                    date = responseData[i].created_at.split("/");
                    if (responseData[i].file_mime_type == "application/pdf"){
                        icon = 'mdi-file-pdf';
                        iconStyle = {'color': 'red'};
                    } else if(responseData[i].file_mime_type.includes('image')) {
                        icon = 'mdi-file-image';
                        iconStyle = {'color': '#22c9dc'};
                    } else {
                        icon = 'mdi-file';
                        iconStyle = {'color': '#c8c8c8'};
                    }
                    
                    this.documents.push(
                        {
                            id: responseData[i].id,
                            name: responseData[i].file_name,
                            date: new Date(responseData[i].created_at),
                            icon: icon,
                            iconStyle: iconStyle,
                            mimetype: responseData[i].file_mime_type,
                            type: responseData[i].type,
                            slug: responseData[i].slug,
                            seen: responseData[i].acknowledged,
                            type_text: responseData[i].type_text,
                        }    
                    );
                    if(!filesTypes.find(x => {x.toUpperCase() == responseData[i].type_text.toUpperCase()})){
                        filesTypes.push(responseData[i].type_text);
                    }
                }

                // file types groups
                for(var i = 0; i < filesTypes.length; i++){
                    if (this.groupSelectItems[1].groups.find(x => {return x.groupName.toUpperCase() == filesTypes[i].toUpperCase();})){
                        continue;
                    }
                    this.groupSelectItems[1].groups.push( 
                        {
                            groupName: filesTypes[i],
                            items: []
                        }
                    );
                }
            }
        },
        downloadDocumentsData: async function(){
            this.$emit("set-state", "LOADING");
            let responsePromise = axios({
                method: "GET",
                url: fob_config.api_url + "/api/v2/my-documents", // + JSON.parse(localStorage.getItem('workers'))[0].id + "/documents/",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                },
            });
            
            var response = null;
            try {
                response = await responsePromise;
            } catch (error) {
                this.$emit('set-state', "DEFAULT");
                this.documents = [];

                if (error.isAxiosError) {
                    switch (error.response.status) {
                        case 403:
                            // forbidden
                            this.$emit("set-state", "ERROR", this.lmessages.forbidden);
                            break;
                        case 401:
                            // unauthorized
                            this.$emit("set-state", "ERROR", this.lmessages.forbidden);
                            break;
                        case 404:
                            // not found
                            this.$emit('set-state', "ERROR", this.lmessages.noWorkerAssigned);
                            break;
                        default:
                            this.$emit("set-state", "ERROR", this.lmessages.networkError);
                            break;
                    }
                    /*let errorString = String(error);
                    if (errorString.includes("Request failed with status code 403")){
                        // forbidden
                        this.$emit("set-state", "ERROR", this.lmessages.forbidden);
                    } else if (errorString.includes("Request failed with status code 401")){
                        // unauthorized
                        this.$emit("set-state", "ERROR", this.lmessages.forbidden);
                    }
                    this.$emit("set-state", "ERROR", this.lmessages.networkError);*/
                }
            }

            if (response != null) {
                this.$emit('set-state', "DEFAULT");
                this.adaptDocuments(response.data);
            }
            /*.then(response => {
                this.$emit("set-state", "DEFAULT");
                this.adaptDocuments(response.data);
            }).catch(error => {
                let errorString = String(error);
                if (errorString.includes("Request failed with status code 403")){
                    // forbidden
                    this.$emit("set-state", "ERROR", this.lmessages.forbidden);
                } else if (errorString.includes("Request failed with status code 401")){
                    // unauthorized
                    this.$emit("set-state", "ERROR", this.lmessages.forbidden);
                }
                this.$emit("set-state", "ERROR", this.lmessages.networkError);
            });*/
        },
        updateDocumentsCacheStore: function(){
            this.$store.commit('updateMyDocumentsCache', {
                searchValue: this.searchValue,
                sorting: this.sortingGrouper.sorting,
                grouping: this.sortingGrouper.grouping,
            });
        }
    },
    computed: {
        lapp: {
            get: function () {
                return this.$t("app");
            }
        },
        lviews: {
            get: function() {
                return this.$t("views");
            }
        },
        lmessages: {
            get: function() {
                return this.$t("commons.messages");
            }
        }
    },
    props: {
        version: {
            type: String,
            required: true,
        },
        appConfig: {
            type: Object,
            required: true
        }
    },
    mounted: function () {
        this.$emit('set-title', this.lviews.documents.myDocumentsTitle);
        let userPermissions = localStorage.getItem('user_permissions');
        if (!userPermissions.includes("fob.my_docs_view")) {
            this.$emit('set-state', "ERROR", this.lviews.documents.lackOfPermission);
        }
        else {
            /*if(this.appConfig.applicationMode != "WORKER"){
                this.$store.dispatch("logout").then(() => {
                    this.$router.push("/login");
                });
            }*/

            this.tableHeaders[0].text = this.lviews.documents.name;
            this.tableHeaders[1].text = this.lviews.documents.date;
            this.tableHeaders[2].text = this.lviews.documents.download;

            this.orderSelectItems[0].text = this.lviews.documents.fromMostRecent;
            this.orderSelectItems[1].text = this.lviews.documents.fromLeastRecent;
            this.orderSelectItems[2].text = this.lviews.documents.alphabeticalAscending;
            this.orderSelectItems[3].text = this.lviews.documents.alphabeticalDescending;
            
            this.groupSelectItems[0].text = this.lviews.documents.dontGroup;
            this.groupSelectItems[1].text = this.lviews.documents.byType;
            this.groupSelectItems[2].text = this.lviews.documents.byDate;
            this.groupSelectItems[2].groups[0].groupName = this.lviews.documents.lastWeek;
            this.groupSelectItems[2].groups[1].groupName = this.lviews.documents.lastMonth;
            this.groupSelectItems[2].groups[2].groupName = this.lviews.documents.lastYear;
            this.groupSelectItems[2].groups[3].groupName = this.lviews.documents.elder;

            this.downloadConfirmationDialog.title = this.lviews.documents.downloadingDocuments;
            this.downloadConfirmationDialog.content = this.lviews.documents.areYouSureYouWantDownloadAllDocuments;
            this.downloadConfirmationDialog.downloadLabel = this.lviews.documents.download;
            this.downloadConfirmationDialog.cancelLabel = this.lviews.documents.cancel;


            let cache = this.$store.getters.myDocumentsCache;
            if (cache) { // just for sure
                this.sortingGrouper.sorting = cache.sorting;
                this.sortingGrouper.grouping = cache.grouping;
                this.searchValue = cache.searchValue;
            }

            this.downloadConfirmationDialog.onDownloadClick = () => { this.downloadAllDocuments(); };
            this.downloadConfirmationDialog.onCancelClick = () => { this.downloadConfirmationDialog.visible = false; };

            this.screenWidth = window.innerWidth;
            if (this.screenWidth >= 600){
                this.$emit('set-actions', []);
            } else {
                this.$emit('set-actions', []);
                /*
                download all documents button :
                this.$emit('set-actions', [
                    {
                        isIcon: true,
                        value: 'mdi-download',
                        description: 'downloading files',
                        onClick: () => { this.downloadConfirmationDialog.visible = true; }
                    }
                ]);*/
            }

            this.downloadDocumentsData().then(() => {
                // preparing group date ranges for grouping by date
                // this.groupSelectItems[2].groups is always (apparently it should be) the same length as dates array is
                let today = new Date();
                let dates = [
                    new Date(today.getFullYear(), today.getMonth(), Number(today.toDateString().substr(8, 2)) - 7),     // last 7 days
                    new Date(today.getFullYear(), today.getMonth(), Number(today.toDateString().substr(8, 2)) - 30),    // last month
                    new Date(today.getFullYear(), today.getMonth(), Number(today.toDateString().substr(8, 2)) - 365),   // last year
                ];
                for(var i = 0; i < this.groupSelectItems[2].groups.length; i++){
                    this.groupSelectItems[2].groups[i].rangeDate = dates[i];
                }
                
                this.groupDocumentsTableElements();

                if(this.searchValue != ""){
                    this.updateDocumentsTable();
                }
            });
            //this.lapp.drawer.documents);

            window.addEventListener('resize', ()=>{
                this.screenWidth = window.innerWidth;
                if (this.screenWidth >= 600){
                    this.$emit('set-actions', []);
                } else {
                    /*
                    download all documents button :
                    this.$emit('set-actions', [
                        {
                            isIcon: true,
                            value: 'mdi-download',
                            description: 'downloading files',
                            onClick: () => { this.downloadConfirmationDialog.visible = true; }
                        }
                    ]);*/
                    this.$emit('set-actions', []);
                }
            });
        }
    },
    beforeDestroy: function(){
        this.$emit('set-actions', []);
    }
}
</script>

<style>
.documents-search-bar-container{
    width: 60% !important;
    border-radius: 10px !important;
}
.document-name-cut-when-too-long {
    max-width: 170px;
    overflow: hidden; 
    white-space: nowrap; 
    text-overflow: ellipsis;
}
</style>