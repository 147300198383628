<template>
	<div style="position:relative; height:100%; width:100%;">
		<v-layout class="navigation-drawer__header" style="margin-top:0">
			<div class="navigation-side-header">
				<div>
					<div class="user-names-label">
						{{ appConfig.user.firstName }}
						<span v-if="appConfig.user.middleName != null && appConfig.user.middleName != 'null' && appConfig.user.middleName.length > 0">{{ appConfig.user.middleName }}</span>
						{{ appConfig.user.lastName }}
					</div>
					<div class="user-name">
						<div v-if="appConfig.user.username != null && appConfig.user.username.length > 0">{{ appConfig.user.username }}</div>
						<div v-else>{{ lapp.drawer.emailUnassigned }}</div>
					</div>
				</div>
			</div>
		</v-layout>

		<v-divider></v-divider>

		<div class="navigation-side-content">
			<!-- 
				Navigation determined by user's permissions
			-->
			<v-list dense class="pt-0" style="background-color: white; z-index: 12; max-width: 255px; overflow-x: hidden;">
				<div class="navigation-drawer__link"
					v-for="(item, index) in navigationDrawerItems"
					v-bind:key="index"
				>
					<!-- simple option in drawer -->
					<v-list-item :key="item.title" @click="onNavigationLinkClick(item.onClick)" v-if="userCanViewNavigationItem(item.permission, item.additionalConditions) && item.type == 'OPTION'">
						<v-list-item-action>
							<v-icon>{{ item.leftIcon }}</v-icon>
						</v-list-item-action>

						<v-list-item-content>
							<v-list-item-title>{{ item.title }}</v-list-item-title>
						</v-list-item-content>

						<v-list-item-action>
							<v-icon>{{ item.rightIcon }}</v-icon>
						</v-list-item-action>
					</v-list-item>

					<!-- dropdown in navigation -->
					<v-layout flex-column :key="item.title" v-else-if="userCanViewNavigationItem(item.permission, item.additionalConditions) && item.type == 'DROPDOWN'">
						<v-list-item @click="onNavigationLinkClick(item.onClick)">
							<v-list-item-action>
								<v-icon>{{ item.leftIcon }}</v-icon>
							</v-list-item-action>

							<v-list-item-content style="width: 112px; font-size: 13px;">
								{{ item.title }}
							</v-list-item-content>

							<v-list-item-action>
								<v-icon> {{ item.opened ? 'mdi-chevron-up' : 'keyboard_arrow_right' }} </v-icon>
							</v-list-item-action>
						</v-list-item>

						<v-expand-transition>
							<v-flex v-if="item.opened">
								<v-divider></v-divider>
								<v-list-item v-for="(subItem, subItemKey) in item.dropdownItems" :key="subItemKey" @click="onNavigationLinkClick(subItem.onClick)">
									<v-list-item-action>
										<v-icon>{{ subItem.leftIcon }}</v-icon>
									</v-list-item-action>

									<v-list-item-content style="font-size: 13px;" :style="$route.path == subItem.link? 'color: green;':''"> 
										{{ subItem.title }} 
									</v-list-item-content>

									<v-list-item-action>
										<v-icon>{{ subItem.rightIcon }}</v-icon>
									</v-list-item-action>
								</v-list-item>
								<v-divider></v-divider>
							</v-flex>
						</v-expand-transition>
					</v-layout>
				</div>
			</v-list>
		</div>

		<div class="d-flex align-self-end navigation-drawer__footer" style="z-index:-1">
				<img class="navigation-drawer__footer-img" v-bind:src="logo"/>
			</div>
	</div>
</template>

<script>
	import fob_config from '../../fob_config.js';
	export default {
		name: 'navigationSide',
		data() {
			return {
				logo: fob_config.api_url + "/media/logo.png",
				defaultLink: null,
				navigationDrawerItems: [
					/**
					 * {
					 *  title       :  {String} - drawer item's title
					 *  leftIcon    :  {String} - name of icon that occurs before drawer item's title
					 *  rightIcon   :  {String} - name of icon that occurs after drawer item's title
					 *  linkTo      :  {String} - link to next page (only if we choose specific option)
					 *  onClick     :  {Function} - handler for 'click' event (can be unassigned - 'linkTo' attribute makes all work done)
					 *  permission  :  {String} - name of permissions that user needs to see specific drawer item (if it's null or undefined all users can see that option)
					 *  type        :  {String} - one value from list [ "OPTION", "DROPDOWN" ] OPTION (redirect to other page), DROPDOWN (just hidden list/simple dropdown)
					 * }
					 */
					{
						title: "", // My profile
						leftIcon: "person",
						rightIcon: "keyboard_arrow_right",
						linkTo: "/user-profile",
						onClick: () => void 0,
						permission: "fob.workers_view",
						type: "OPTION",
						additionalConditions: [
							() => { 
								if (localStorage.getItem('workers') == null) { 
									return false; 
								} 
								return true; 
							},
						]
					},
					{
						title: "",
						leftIcon: "check_circle",
						rightIcon: "keyboard_arrow_right",
						linkTo: "/boarding",
						onClick: () => void 0,
						permission: "fob.boardings_view",
						type: "OPTION",
						additionalConditions: [],
					},
					{
						title: "",
						leftIcon: "group",
						rightIcon: "keyboard_arrow_right",
						linkTo: "/employees",
						onClick: () => void 0,
						permission: "fob.boardings_view",
						type: "OPTION",
						additionalConditions: [],
					},
					{
						title: "",
						leftIcon: "mdi-file-document",
						rightIcon: "keyboard_arrow_right",
						linkTo: "/my-documents",
						onClick: () => void 0,
						permission: "my_docs_view",
						type: "OPTION",
						additionalConditions: [],
					},
					{
						title: "",
						leftIcon: "mdi-file-document",
						rightIcon: "keyboard_arrow_right",
						linkTo: "/workers-documents",
						onClick: () => void 0,
						permission: 'fob.worker_docs_view',
						type: "OPTION",
						additionalConditions: [],
					},
					{
						title: "",
						leftIcon: "mdi-cogs",
						rightIcon: "keyboard_arrow_right",
						linkTo: "/admin-panel",
						onClick: () => void 0,
						permission: "fob.admin_view", // currently with no permissions, later we'll add
						type: "DROPDOWN",
						dropdownItems: [
							{
								title: "",
								link: null,
								onClick: null,
								leftIcon: "",
								rightIcon: "",
							},
							{
								title: "",
								link: null,
								onClick: null,
								leftIcon: "",
								rightIcon: "",
							},
							{
								title: "",
								link: null,
								onClick: null,
								leftIcon: "",
								rightIcon: "",
							},
							{
								title: "",
								link: null,
								onClick: null,
								leftIcon: "",
								rightIcon: "",
							},
							{
								title: "",
								link: null,
								onClick: null,
								leftIcon: "",
								rightIcon: "",
							},
							/*{
								title: "",
								link: null,
								onClick: null,
								leftIcon: "",
								rightIcon: "",
							},
							{
								title: "",
								link: "/user-list",
								onClick: null,
								leftIcon: "",
								rightIcon: "",
							},*/
						],
						opened: false,
						additionalConditions: [],
					},
					{
						title: "", // About
						leftIcon: "info",
						rightIcon: "keyboard_arrow_right",
						linkTo: "/about",
						onClick: () => void 0,
						permission: null,
						type: "OPTION",
						additionalConditions: [],
					},
					{
						title: "",
						leftIcon: "mdi-settings",
						rightIcon: "",
						linkTo: "/settings",
						onClick: () => void 0,
						permission: null,
						type: "OPTION",
						additionalConditions: [],
					},
					{
						title: "", // Log out
						leftIcon: "mdi-exit-to-app",
						rightIcon: "",
						icon: "mdi-exit-to-app",
						linkTo: "/",
						onClick: () => void 0, // assigned in mounted
						permission: null,
						type: "OPTION",
						additionalConditions: [],
					}
				],
			}
		},
		mounted() {
			this.loadLanguage();
			this.loadClickHandlers();

			this.dropdownOnRedirect(this.$route.path)
		},
		computed: {
			lapp: {
				get: function () {
					return this.$t("app");
				}
			}
		},
		props: {
			appConfig: {
				type: Object,
				required: true
			}
		},
		watch: {
			"$i18n.locale": function () {
				this.loadLanguage();
			},
			"$route.path": function (newPath, oldPath) {
				this.redirectToDefaultIfNecessary(this.$route);
				this.dropdownOnRedirect(newPath);
			}
		},
		methods: {
			dropdownOnRedirect(newPath){
				// check whether we should open administration dropdown or not
				var administrationDropdownDirectories = ["/admin-panel", "/add-user", "/config", "/user-list", "/permissions", "/addresses", "/company", "/mdl-config/", "/ds-config/",]; // add next routes
				if (administrationDropdownDirectories.includes(newPath)) {
					this.navigationDrawerItems[5].opened = true;
				}
				else if(newPath.includes("/mdl-config/") || newPath.includes("/ds-config/")){
					this.navigationDrawerItems[5].opened = true;
				}
				else {
					this.navigationDrawerItems[5].opened = false;
				}
			},
			redirectToDefault: function() {
				// prioritized directions
				let redirections = [
					{ linkTo: "/boarding", permission: "fob.boardings_view" },
					{ linkTo: "/employees", permission: "fob.boardings_view" },
					{ linkTo: "/my-documents", permission: "fob.my_docs_view" },
					{ linkTo: "/workers-documents", permission: 'fob.worker_docs_view' },
					{ linkTo: "/user-profile", permission: "fob.workers_view" },
					{ linkTo: "/about", permission: null },
					{ linkTo: "/settings", permission: null },
				];

				let userPermissions = localStorage.getItem("user_permissions");
				for(var i = 0; i < redirections.length; i++) {
					if (redirections[i].permission == null || userPermissions.includes(redirections[i].permission)) {
						this.$router.push(redirections[i].linkTo);
						break;
					}
				}
			},

			redirectToDefaultIfNecessary: function(route) {
				if (route.name == "error404") {
					this.redirectToDefault();
				}
				/*else { // check permissions to redirected page
					let myPermissions = localStorage.getItem("user_permissions");

					switch (route.path){
						case "/boarding":
							if (!myPermissions.includes("fob.boardings_view")){
								this.redirectToDefault();
							}
							break;
						case "/employees":
							if (!myPermissions.includes("fob.boardings_view")){
								this.redirectToDefault();
							}
							break;
						case "/my-documents":
							if (!myPermissions.includes("fob.my_documents_view")){
								this.redirectToDefault();
							}
							break;
						case "/workers-documents":
							if (!myPermissions.includes("fob.worker_docs_view")){
								this.redirectToDefault();
							}
							break;
						case "/user-profile":
							if (!myPermissions.includes("fob.workers_view")){
								this.redirectToDefault();
							}
							break;
					}
				}*/
			},

			loadLanguage: function() {
				this.navigationDrawerItems[0].title = this.lapp.drawer.myProfile;
				this.navigationDrawerItems[1].title = this.lapp.drawer.boardings;
				this.navigationDrawerItems[2].title = this.lapp.drawer.employees;
				this.navigationDrawerItems[3].title = this.lapp.drawer.myDocuments;
				this.navigationDrawerItems[4].title = this.lapp.drawer.documents;
				this.navigationDrawerItems[5].title = this.lapp.drawer.administration;

				//this.navigationDrawerItems[5].dropdownItems[0].title = this.lapp.drawer.officeData;
				//this.navigationDrawerItems[5].dropdownItems[1].title = this.lapp.drawer.settlements;
				this.navigationDrawerItems[5].dropdownItems[0].title = this.lapp.drawer.dictionaries;
				//this.navigationDrawerItems[5].dropdownItems[3].title = this.lapp.drawer.permissions;
				this.navigationDrawerItems[5].dropdownItems[1].title = this.lapp.drawer.users;
				this.navigationDrawerItems[5].dropdownItems[2].title = this.lapp.drawer.permissions;
				this.navigationDrawerItems[5].dropdownItems[3].title = this.lapp.drawer.addresses;
				this.navigationDrawerItems[5].dropdownItems[4].title = this.lapp.drawer.officeData;

				this.navigationDrawerItems[6].title = this.lapp.drawer.about;
				this.navigationDrawerItems[7].title = this.lapp.drawer.settings;
				this.navigationDrawerItems[8].title = this.lapp.drawer.logout;
			},  

			loadClickHandlers: function() {
				this.navigationDrawerItems[0].onClick = () => { if (this.$route.path != '/user-profile') this.$router.push("/user-profile") };
				this.navigationDrawerItems[1].onClick = () => { if (this.$route.path != '/boarding') this.$router.push("/boarding") };
				this.navigationDrawerItems[2].onClick = () => { if (this.$route.path != '/employees') this.$router.push("/employees") };
				this.navigationDrawerItems[3].onClick = () => { if (this.$route.path != '/my-documents') this.$router.push("/my-documents") };
				this.navigationDrawerItems[4].onClick = () => { if (this.$route.path != '/workers-documents') this.$router.push("/workers-documents") };

				this.navigationDrawerItems[5].onClick = () => { 
					if (this.$route.path != this.navigationDrawerItems[5].linkTo) {
						this.$router.push(this.navigationDrawerItems[5].linkTo);
					}
				};
				//this.navigationDrawerItems[5].dropdownItems[0].onClick = () => {};
				//this.navigationDrawerItems[5].dropdownItems[1].onClick = () => {};
				this.navigationDrawerItems[5].dropdownItems[0].onClick = () => { if (this.$route.path != '/config') this.$router.push("/config"); };
				this.navigationDrawerItems[5].dropdownItems[1].onClick = () => { if (this.$route.path != '/user-list') this.$router.push("/user-list"); };
				this.navigationDrawerItems[5].dropdownItems[2].onClick = () => { if (this.$route.path != '/permissions') this.$router.push("/permissions"); };
				this.navigationDrawerItems[5].dropdownItems[3].onClick = () => { if (this.$route.path != '/addresses') this.$router.push("/addresses"); };
				this.navigationDrawerItems[5].dropdownItems[4].onClick = () => { if (this.$route.path != '/company') this.$router.push("/company"); };

				this.navigationDrawerItems[6].onClick = () => { if (this.$route.path != '/about') this.$router.push("/about"); };
				this.navigationDrawerItems[7].onClick = () => { if (this.$route.path != '/settings') this.$router.push("/settings"); };
				this.navigationDrawerItems[8].onClick = this.logout;
			},

			// currently it emits signal to parent component that some option was clicked and menu is hidden
			// if onClick handler is unassigned nothing happens
			onNavigationLinkClick(onClick = null){
				this.$emit("navigation-link-clicked", onClick);
			},

			logout: function () {
				this.$store.dispatch("logout").then(() => {
					//console.log("### logged out ###");
					this.$router.push("/login");
				});
			},

			/**
			 * Decides whether specific items in navigation drawer can be rendered or not
			 * @param permission - {String} permission name that has to be possessed
			 * @param additionalConditions - {List of functions} list of necessary conditions (all conditions must return true and given permission has to be possessed)
			 * @retuns boolean
			 */
			userCanViewNavigationItem: function(permission, additionalConditions) {
				let testValue = false, condition = ()=>{};
				for (var i = 0; i < additionalConditions.length; i++) {
					condition = additionalConditions[i];
					testValue = condition();
					if (!testValue) {
						return false;
					}
				}

				if (permission == undefined || permission == null)
					return true;
					
				let permissions = localStorage.getItem("user_permissions");
				if (permissions == null || permissions == undefined || permissions == '') {
					return false;
				}

				if (permissions.includes(permission)) {
					return true;
				}
				return false;
			}
		}
	}
</script>

<style scoped>
	.user-names-label{
		font-size: 18px;
	}

	.user-role{
		padding-top: 2px;
		font-size: 16px;
	}

	.user-name{
		padding-top: 2px;
		font-size: 13px;
		font-weight: 500;
	}

	.navigation-drawer__header {
		height: 100px;
	}

	.navigation-drawer__link {
		text-decoration: none;
		color: rgba(0, 0, 0, 0.87);
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-rendering: optimizeSpeed;
	}

	.navigation-drawer__footer {
		position: absolute;
		z-index: 10;
		bottom: 0;
		left: 0;
		width: 100%;
		background-color: transparent;
		text-align: center;
	}

	.navigation-drawer__footer-img {
		max-width: 256px;
		max-height: 102.4px;
		margin: 0 auto;
	}

	.bottom-nav__link {
		text-decoration: none;
	}

	.navigation-side-header {
		padding-left: 10px;
		height: 100%;
		width: 100%;

		display: flex;
		flex-direction: column;
		justify-content: center;
	}
</style>