<!--
SharedMasters 2019 - Fast OnBoarding Application

__copyright__ = "Copyright (C) 2019, Shared Masters sp. z o.o."
__license__ = "Fast OnBoarding can not be copied and/or distributed without the express permission of Shared Masters sp. z o.o."
__version__ = "0.0.1-pre-beta"
__author__ = "Jan Przychodniak, .."
__maintainer__ = "Jan Przychodniak"
__email__ = "jan@sharedmasters.com"
__status__ = "DEV"


USAGE
-------
<change-employee-project-dialog
  ref="yourReference"

  ...
></change-employee-plans-dialog>
$refs.yourReference.openDialog();

props:
 


events:
  - request-sending-start
  - request-sending-end
  - request-sending-success
  - error

errors:
  structure: {
	errorCodeShort,
	errorCodeLong,
	title,
	message,
	details
  }

  errorsId: CHNGEMP

-->

<template>
	<div>
		<v-dialog persistent v-model="visible" max-width="290">
			<v-card dense class="ChangeEmployeePlansDialog">
				<v-card-title style="text-align:center; padding-bottom:0">
					<span class="headline">{{ lview.title }}</span>
				</v-card-title>

				<v-card-text>
					<!-- Select active boarding to change -->
					<v-select
					  v-model="pickedChangingActiveBoarding"
					  :items="activeBoardings"
					  item-text="projectName"
					  item-value="id"
					  :label="lview.projectToChange"
					  style="margin-top: 20px;"
					></v-select>

					<!-- Select new project -->
					<v-select
					  ref="changePlansDialogLocationSelect"
					  v-model="pickedChangingLocation"
					  :items="projectsDictionary"
					  item-text="name"
					  item-value="id"
					  :label="lview.changeProject"
					  style="margin-top: 10px;"
					></v-select>

					<!-- Project change date -->
					<v-dialog
						ref="dialogOffboardingDate"
						v-model="projectChangeDatePicker.visible"
						:return-value.sync="projectChangeDatePicker.date"
						width="290px"
					>
						<template v-slot:activator="{ on }">
							<div
							style="width:100%; margin-bottom: 25px;"
							>
							<v-text-field
								style="width:100%"
								hide-details
								v-model="projectChangeDatePicker.date"
								:label="lview.projectChangeDate"
								append-icon="event"
								readonly
								v-on="on"
							></v-text-field>
							</div>
						</template>

						<v-date-picker 
							color="rgba(4, 202, 90, 1)" 
							v-model="projectChangeDatePicker.date" 
							scrollable
							:min="pickedBoardingOnboardingDate"
						>
							<v-spacer></v-spacer>
							<v-btn
							text
							color="rgba(4, 202, 90, 1)"
							@click="projectChangeDatePicker.visible = false"
							>{{ lbuttons.cancel }}</v-btn>
							<v-btn
							text
							color="rgba(4, 202, 90, 1)"
							@click="$refs.dialogOffboardingDate.save(projectChangeDatePicker.date); projectChangeDatePicker.visible = false"
							>{{ lbuttons.confirm }}</v-btn>
						</v-date-picker>
					</v-dialog>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text color="rgba(4, 202, 90, 1)" @click="closeDialog()">{{ lbuttons.cancel }}</v-btn>
					<v-btn :disabled="!pickedChangingLocation ||!pickedChangingActiveBoarding" text color="rgba(4, 202, 90, 1)" @click="onAcceptDialog()">{{ lbuttons.confirm }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import axios from 'axios'

	export default {
		name: 'changeEmployeeProjectDialog',
		mounted: function () {
			
		},
		data() {
			return {
				visible: false,

				changingLocationEnabled: false,
				pickedChangingLocation: null,

				pickedChangingActiveBoarding: {},

				projectChangeDatePicker: {
					date: new Date()
					.toJSON()
					.slice(0, 10)
					.replace("/-/g", "/"), //set default date as today's in format : YY-MM-DD
					visible: false
				},
				onBoardingDatePicker: {
					date: new Date()
					.toJSON()
					.slice(0, 10)
					.replace("/-/g", "/"), //set default date as today's in format : YY-MM-DD
					visible: false
				},

				tableProjects: [
					/*{
					  id: {Number},
					  name: {String},
					  status: (DEFAULT|REMOVED|ADDED)
					}*/
				]
			}
		},
		computed: {
			lview: { get: function () { return this.$t('views.commons.changeEmployeePlansDialog'); } },
			lbuttons: { get: function () { return this.$t("commons.buttons"); } },
			lmessages: { get: function () { return this.$t("commons.messages"); } },
			lerrors: { get: function() { return this.$t('errors'); } },
			pickedBoardingOnboardingDate() {
				let item = this.activeBoardings.find(i => i.id==this.pickedChangingActiveBoarding)
				if(item && item.onBoardingDate){
					let minDate = new Date(item.onBoardingDate)
					minDate.setDate(minDate.getDate()+1)
					return minDate.toISOString().substr(0, 10)
				}
				return null
			},
		},
		props: {
			workers: {
				type: Array,
				required: null,
			},

			projectsDictionary: {
			  type: Array,
			  required: null,
			  default: [],
			},
			assignedProjects: {
			  type: Array,
			  default: () => []
			},
			activeBoardings: {
				type: Array,
				required: null,
				default: [],
			}

		},
		watch: {
		},
		methods: {
			openDialog() {
				this.changingLocationEnabled = false;

				if(this.activeBoardings.length==1){
					this.pickedChangingActiveBoarding=this.activeBoardings[0].id
				}
				else this.pickedChangingActiveBoarding=null

				this.visible = true;
			},
			closeDialog() {
				this.visible = false;
			},

			onAcceptDialog: async function () {
				var errorOccured = false;
				var workers = this.workers;

				// off_boarding_date - 1 day and format to YY-MM-DD
				var off_boarding_date = new Date(this.projectChangeDatePicker.date);
				off_boarding_date.setDate(off_boarding_date.getDate() - 1);
				off_boarding_date = off_boarding_date.toJSON().slice(0, 10).replace("/-/g", "/");

				var data = {
					project_id: this.pickedChangingLocation,
					off_boarding_date: off_boarding_date,
					on_boarding_date: this.projectChangeDatePicker.date,
				};

				this.$emit('request-sending-start');
				
				try {
					await axios({
							method: "PATCH",
							url: localStorage.getItem("current_env") + "/api/v2/workers/change-project/"+this.pickedChangingActiveBoarding,
							headers: {
								'Content-Type': 'application/json',
								'Authorization': 'Bearer ' + localStorage.getItem('jwt')
							},
							data: data
						})
				} catch (ex) {
					errorOccured = true;
					this.$emit('request-sending-end');
					if (ex.isAxiosError) {
						if (ex.response.status == 403) {
							this.$emit('error', {
								title: this.lmessages.forbiddenOperation,
								message: this.lmessages.youDoNotHavePriviledgesToDoThatOperation,
								errorCodeShort: "CNFBRD#5",
								errorCodeLong: "REQUEST_FAILED",
								details: String(ex)
							});
							return;
						}
                        else if(ex.response.status ==400 && ex.response.data.project_assignment){
                            this.$emit('error', {
								title: this.lerrors.worker.project_assignment.header,
								message: this.lerrors.worker.project_assignment.string,
								errorCodeShort: this.lerrors.worker.project_assignment.code,
								errorCodeLong: this.lerrors.worker.project_assignment.code,
								details: String(ex)
							});
							return;
                        }
						else if (ex.response.data != null && ex.response.data != undefined) {
							if (ex.response.data.worker != null && ex.response.data.worker != undefined) {
								if(ex.response.data.worker.parent_id != null && ex.response.data.worker.parent_id != undefined) {
									for (var i=0; i < this.lerrors.worker.parent_id.length; i++) {
										if (this.lerrors.worker.parent_id[i].code == ex.response.data.worker.parent_id[0].code) {
											this.$emit('error', {
												title: this.lerrors.worker.parent_id[i].header,
												message: this.lerrors.worker.parent_id[i].string,
												errorCodeShort: this.lerrors.worker.parent_id.code,
												errorCodeLong: this.lerrors.worker.parent_id.code,
												details: String(ex)
											});
											return;									
										}
									}
								}
							}
						}
						else { // any other error
							this.$emit('error', {
								title: this.lmessages.errorOccuredTitle,
								errorCodeShort: ex.toString(),
								errorCodeLong: ex.toString(),
								message: this.lmessages.errorOccured,
								details: ex.toString()
							});
							return;
						}
					}
					else {
						this.$emit('error', {
							title: this.lmessages.errorOccuredTitle, 
							errorCodeShort: ex.toString(), 
							errorCodeLong: ex.toString(),
							message: this.lmessages.errorOccured,
							details: this.lmessages.errorOccured
						});
						return;
					}
						// TODO handle exception
						//console.log(ex);
				}
				
				this.$emit('request-sending-end');

				if (!errorOccured) {
					this.$emit('request-sending-success', workers);
					this.closeDialog();
				}
			},

			anyChanges() {
				if (this.changingManagerEnabled && this.defaultManager != this.pickedChangingManager) {
					return true;
				}

				/*for(var i = 0; i < this.tableProjects.length; i++){
				  if(this.tableProjects[i].status != "DEFAULT"){
					return true;
				  }
				}*/

				return false;
			},

			getProject(projectId) {
				for (var i = 0; i < this.tableProjects.length; i++) {
					if (this.tableProjects[i].id == projectId) {
						return this.tableProjects[i];
					}
				}
				return null;
			},
			removeProject(projectId) {
				var project = this.getProject(projectId);

				if (project != null) {
					if (project.status != "ADDED")
						project.status = "REMOVED";
					else
						this.tableProjects.splice(
							this.tableProjects.indexOf(project), 1
						);
				}
			},
			bringProjectBack(projectId) {
				var project = this.getProject(projectId);

				if (project != null) {
					project.status = "DEFAULT";
				}
			},

			/**
			 * Finds project name in projects dictionary
			 *
			 * @param {Integer} projectId Id to look for
			 * @returns {String} Project name if foound, null otherwise
			 */
			getProjectName(projectId) {
				for (var i = 0; i < this.projectsDictionary.length; i++) {
					if (this.projectsDictionary[i].id == projectId) {
						return this.projectsDictionary[i].name;
					}
				}
				return null;
			},
			// partialWorkerUpdate: async function (workerIds, requestData) {
			// 	var worker;
			// 	var axiosPromises = [];
			// 	var workerIdsCopy = [];
			// 	for (var i = 0; i < workerIds.length; i++) {
			// 		workerIdsCopy.push(workerIds[i]);

			// 		axiosPromises.push(
			// 			axios({
			// 				method: "PATCH",
			// 				url:
			// 					localStorage.getItem("current_env") +
			// 					"/api/worker/" +
			// 					workerIds[i],
			// 				headers: {
			// 					"Content-Type": "application/json",
			// 					Authorization: "Bearer " + localStorage.getItem("jwt")
			// 				},
			// 				data: requestData
			// 			})
			// 		);
			// 	}

			// 	var response;
			// 	var requestError = {
			// 		errors: [],
			// 		message: null
			// 	};
			// 	for (var i = 0; i < axiosPromises.length; i++) {
			// 		try {
			// 			response = await axiosPromises[i];
			// 		} catch (error) {
			// 			requestError.errors.push({
			// 				workerId: workerIdsCopy[i],
			// 				error: error
			// 			});
			// 		}
			// 	}

			// 	if (requestError.errors.length > 0) {
			// 		requestError.message = "Following errors occured: \n";

			// 		for (var i = 0; i < requestError.errors.length; i++) {
			// 			requestError.message +=
			// 				"\nWorker " +
			// 				requestError.errors[i].workerId +
			// 				": " +
			// 				requestError.errors[i].error;
			// 		}

			// 		throw requestError;
			// 	}
			// }
		}
	};
</script>

<style>
	div.ChangeEmployeePlansDialog .projects-table .v-subheader {
		padding: 0;
	}

	div.ChangeEmployeePlansDialog .projects-table .v-list__tile {
		padding: 0;
	}
</style>

<style scoped>
	.projects-table {
		padding: 0;
	}

	.projects-table-title {
		border-bottom: 1px solid rgba(0,0,0,0.12);
		padding: 5px;
	}
</style>
