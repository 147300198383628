<template>
    <v-layout>
        <v-card-actions style='background: #bdbdbd; margin: auto; height: 40px; max-width: 800px; width:90%; margin-right:auto; margin-left:auto; margin-bottom:20px; margin-top: 50px;'>
            <v-card-text color="black" style = 'font-weight:bold;' text>
                {{ lview.language }}
            </v-card-text>
        </v-card-actions>

            <div class="form-container">
                <v-select
                    @change="changeAppLanguage"
                    :items="languages"
                    item-text="name"
                    item-value="id"
                    v-model="languagesValue"
                    class="form-input"
                ></v-select>
            </div>

        <v-card-actions style='background: #bdbdbd; margin: auto; height: 40px; max-width: 800px; width:90%; margin-right:auto; margin-left:auto; margin-bottom:20px; margin-top: 50px;'>
            <v-card-text color="black" style = 'font-weight:bold;' text>
                {{ lview.notifications }}
            </v-card-text>
        </v-card-actions>

        <div class="form-container">
            <v-container>
                <!--<v-checkbox style="margin-bottom: -25px;"
                    v-model="appCheckboxValue"
                    :label="lview.notificationsAppCheckbox"
                ></v-checkbox>-->

                <v-checkbox
                    v-model="emailCheckboxValue"
                    :label="lview.notificationsEmailCheckbox"
                ></v-checkbox>
            </v-container>

            <v-container v-if="appCheckboxValue == true || emailCheckboxValue == true">
                <span> {{ lview.notificationsMessage }} </span>

                <v-select 
                    :items="preferences.frequency"
                    item-text="name"
                    item-value="id"
                    v-model="frequencyValue"
                    class="form-input"
                ></v-select>

                <!-- date -->
                <div v-if="preferences.configOptions[frequencyValue].weekday == true">
                    <v-select 
                        v-model="weekdayValue"
                        :items="preferences.weekday"
                        item-text="name"
                        item-value="id"
                        :label="lview.notificationsWeekday"
                        dense
                        class="form-input"
                    ></v-select>
                </div>

                <!-- hour -->
                <div v-if="preferences.configOptions[frequencyValue].hour == true">
                    <!--<v-menu
                        ref="menu"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="time"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="time"
                                :label="lview.notificationsHour"
                                append-icon="mdi-clock-outline"
                                class="form-input"
                                readonly
                                dense
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-time-picker
                            v-if="menu2"
                            v-model="time"
                            full-width
                            @click:minute="$refs.menu.save(time)"
                        ></v-time-picker>
                    </v-menu>-->
                    <v-dialog
                        ref="timePickerDialog"
                        v-model="timePicker.dialog"
                        :return-value.sync="timePicker.time"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="timePicker.time"
                            :label=lview.pickTime
                            append-icon="mdi-clock-outline"
                            class="form-input"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-time-picker
                            v-if="timePicker.dialog"
                            v-model="timePicker.time"
                            full-width
                            :allowedMinutes="v => v % 10 === 0"
                            format="24hr"
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            @click="timePicker.dialog=false"
                        >
                            {{ lview.cancel }}
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.timePickerDialog.save(timePicker.time)"
                        >
                            {{ lview.confirm }}
                        </v-btn>
                        </v-time-picker>
                    </v-dialog>
                </div>
            </v-container>

            <!-- firebase notifications checkbox-->
            <v-container>
                <div class="firebase-notifications-wrapper">
                    <v-checkbox
                        v-model="firebaseNotification.turnedOn"
                        :label="lview.turnOnNotifications"
                    ></v-checkbox>
                </div>
            </v-container>

            <!-- firebase notifications -->
            <v-container v-if="firebaseNotification.turnedOn">
                <span> {{ lview.notificationsMessage }} </span>

                <v-select 
                    :items="preferences.frequency"
                    item-text="name"
                    item-value="id"
                    v-model="firebaseNotification.frequency"
                    class="form-input"
                ></v-select>

                <!-- date -->
                <div v-if="preferences.configOptions[firebaseNotification.frequency].weekday == true">
                    <v-select 
                        v-model="firebaseNotification.weekdayValue"
                        :items="preferences.weekday"
                        item-text="name"
                        item-value="id"
                        :label="lview.notificationsWeekday"
                        dense
                        class="form-input"
                    ></v-select>
                </div>

                <!-- hour -->
                <div v-if="preferences.configOptions[firebaseNotification.frequency].hour == true">
                    <v-dialog
                        ref="firebaseTimePickerDialog"
                        v-model="firebaseNotificationTimePicker.dialog"
                        :return-value.sync="firebaseNotificationTimePicker.time"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="firebaseNotificationTimePicker.time"
                                :label=lview.pickTime
                                append-icon="mdi-clock-outline"
                                class="form-input"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>

                        <v-time-picker
                            v-if="firebaseNotificationTimePicker.dialog"
                            v-model="firebaseNotificationTimePicker.time"
                            full-width
                            :allowedMinutes="v => v % 10 === 0"
                            format="24hr"
                        >
                            <v-spacer></v-spacer>

                            <v-btn
                                text
                                @click="firebaseNotificationTimePicker.dialog=false"
                            >
                                {{ lview.cancel }}
                            </v-btn>
                            
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.firebaseTimePickerDialog.save(firebaseNotificationTimePicker.time)"
                            >
                                {{ lview.confirm }}
                            </v-btn>
                        </v-time-picker>
                    </v-dialog>
                </div>
            </v-container>
        </div>

        <v-divider style='margin:auto; margin-left: 5%; margin-right: 10%; margin-bottom: 2%;'></v-divider>

        <v-container>
            <v-row style='margin:auto;'
                justify='space-around'
            >
                <div>
                    <v-btn @click="redirectToSettings"> {{lview.buttonCancel}} </v-btn>
                    <v-btn
                        style="margin-left: 25px;"
                        @click="saveUserSettings"
                        color="success">
                        {{lview.buttonSave}}
                    </v-btn>
                </div>
            </v-row>
        </v-container>

        <v-snackbar
            v-model="snackbar.visible"
            :color="snackbar.color"
        >
            {{ snackbar.message }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar.visible = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </v-layout>
</template>

<script>
import axios from 'axios';
export default {
    data: function(){
        return {
            languages: [
                { id: "en", name: "English" },
                { id: "pl", name: "Polski" },
                { id: "de", name: "Deutsch" }
            ],
            languagesValue: null,
            appCheckboxValue: null,
            emailCheckboxValue: null,
            timeValue: null,
            frequencyValue: 0,
            weekdayValue: 0,
            // time: null,
            // menu2: false,
            // modal2: false,
            timePicker: {
                dialog: false,
                time: null
            },

            firebaseNotificationTimePicker: {
                dialog: false,
                time: null,
            },

            preferences: {
                frequency: [
                    { id: 0, name: ""}, // ASAP 
                    { id: 1, name: ""}, // Hourly
                    { id: 2, name: ""}, // Daily
                    { id: 3, name: ""} // Weekly
                ],

                configOptions: {
                    0: {
                        hour: false,
                        weekday: false,
                    },
                    1: {
                        hour: false,
                        weekday: false,
                    },
                    2: {
                        hour: true,
                        weekday: false,
                    },
                    3: {
                        hour: true,
                        weekday: true,
                    }
                },
                weekday: [
                    { id: 0, name: "" }, // monday
                    { id: 1, name: "" }, // tuesday
                    { id: 2, name: "" }, // wednesday
                    { id: 3, name: "" }, // thursday
                    { id: 4, name: "" }, // friday
                    { id: 5, name: "" }, // saturday
                    { id: 6, name: "" } // sunday
                ]
            },

            snackbar: {
                visible: false,
                color: "primary",
                message: ""
            },

            firebaseNotification: {
                turnedOn: false,
                weekdayValue: 0,
                frequency: 0,
            }
        }
    },

    methods: {
        redirectToSettings: function() {
            this.$router.push("/settings");
        },

        changeAppLanguage(newValue) {
            this.$cookies.set('language', newValue);
            window.location.reload();
        },

        saveUserSettings: async function(){
            try {
                let timeDiff = null;
                this.$emit("set-state", "LOADING_OVERLAYER", this.lview.savingChanges);
                if (this.timePicker.time != null && this.timePicker.time != undefined) {
                    let notificationTime = this.timePicker.time;
                    let notificationSplit = notificationTime.split(":");
                    let notificationHour = Number(notificationSplit[0]);
                    let notificationMinutes = Number(notificationSplit[1]);
                    var offset = new Date().getTimezoneOffset();

                    if (this.frequencyValue == 2){
                        timeDiff = (notificationHour * 60 + notificationMinutes + offset + 1440)%1440;
                    }
                    if (this.frequencyValue == 3){
                        timeDiff = (notificationHour * 60 + notificationMinutes + offset + this.weekdayValue * 1440 + 10080)%10080;
                        
                    }
                }

                let firebaseTimeDiff = null;
                if (this.firebaseNotification.turnedOn) {
                    let firebaseNotificationTime = this.firebaseNotificationTimePicker.time;
                    if (this.firebaseNotificationTimePicker.time != null) {
                        let firebaseNotificationTimeSplit = firebaseNotificationTime.split(":");
                        let firebaseNotificationHour = Number(firebaseNotificationTimeSplit[0]);
                        let firebaseNotificationMinutes = Number(firebaseNotificationTimeSplit[1]);
                        let offset = new Date().getTimezoneOffset();

                        if (this.firebaseNotification.frequency == 2) {
                            firebaseTimeDiff = (firebaseNotificationHour * 60 + firebaseNotificationMinutes + offset + 1440) % 1440;
                        }
                        if (this.firebaseNotification.frequency == 3) {
                            firebaseTimeDiff = (firebaseNotificationHour * 60 + firebaseNotificationMinutes + offset + this.firebaseNotification.weekdayValue * 1440 + 10080) % 10080;
                        }
                    }
                }
                
                let langValue = null;
                if (this.languagesValue == "pl") {
                    langValue = 0;
                }
                else if (this.languagesValue == "en") {
                    langValue = 1;
                }
                else if (this.languagesValue == "de") {
                    langValue = 2;
                }

                let settingsResponse = await axios({
                    method: "PATCH",
                    url: localStorage.getItem("current_env") + "/api/v2/user-settings/",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                    },
                    data: {
                        new_docs_notifications_enabled: this.emailCheckboxValue,
                        new_docs_notifications_frequency: this.frequencyValue,
                        new_docs_notifications_time: timeDiff,
                        preferred_language: langValue,
                        new_docs_notifications_phone_enabled: this.firebaseNotification.turnedOn,
                        new_docs_notifications_phone_frequency: this.firebaseNotification.frequency,
                        new_docs_notifications_phone_time: firebaseTimeDiff,
                    }
                });
                this.$emit('set-state', 'default');
                let response = settingsResponse.data;

                this.emailCheckboxValue = response.new_docs_notifications_enabled;
                this.frequencyValue = response.new_docs_notifications_frequency;

                // show message after successful update
                this.snackbar.color = "success";
                this.snackbar.message = this.lview.updatedCorrectly;
                this.snackbar.visible = true;
            } catch (error) {
                this.$emit('set-state', 'default');
                // show error snackbar
                this.snackbar.color = "error";
                this.snackbar.message = this.lview.couldNotUpdate;
                this.snackbar.visible = false;

                if (error.isAxiosError) {
                    if (error.response.data.new_docs_notifications_time != null && error.response.data.new_docs_notifications_time != undefined) {
                        for (var i=0; i < this.lerrors.preferences.new_docs_notifications_time.length; i++) {
                            if (this.lerrors.preferences.new_docs_notifications_time[i].code == error.response.data.new_docs_notifications_time[0].code) {
                                this.$emit('display-error', this.lerrors.preferences.new_docs_notifications_time[i].header, this.lerrors.preferences.new_docs_notifications_time[i].string, String(error));
                                return;
                            }
                        }          
                    }
                    if (error.response.data.new_docs_notification_phone_time != null && error.response.data.new_docs_notification_phone_time != undefined) {
                        for (let i = 0; i < this.lerrors.preferences.new_docs_notification_phone_time.length; i++) {
                            if (this.lerrors.preferences.new_docs_notification_phone_time[i].code == error.response.data.new_docs_notification_phone_time[0].code) {
                                this.$emit('display-error', this.lerrors.preferences.new_docs_notification_phone_time[i].header, this.lerrors.preferences.new_docs_notification_phone_time[i].string, String(error));
                                return;
                            }
                        }
                    }
                    if (error.response.data.non_field_errors != null && error.response.data.non_field_errors != undefined) {
                        this.$emit('display-error', this.lerrors.preferences.non_field_errors.header, this.lerrors.preferences.non_field_errors.string, String(error));
                        return;
                    }                
                } 

                // default error
                this.$emit('display-error', this.lerrors.preferences.default_error.header, this.lerrors.preferences.default_error.string, String(error));
            }

            // if firebase notifications were set properly after request to server => call "notifyMe()"
            if (this.firebaseNotification.turnedOn) {
                this.notifyMe(); // send request and set notification in the browser
            }
        },

        loadTranslations: function() {
            // frequency translations
            this.preferences.frequency[0].name = this.lview.asap;
            this.preferences.frequency[1].name = this.lview.hourly;
            this.preferences.frequency[2].name = this.lview.daily;
            this.preferences.frequency[3].name = this.lview.weekly;

            // week day translations
            this.preferences.weekday[0].name = this.lview.weekday.monday;
            this.preferences.weekday[1].name = this.lview.weekday.tuesday;
            this.preferences.weekday[2].name = this.lview.weekday.wednesday;
            this.preferences.weekday[3].name = this.lview.weekday.thursday;
            this.preferences.weekday[4].name = this.lview.weekday.friday;
            this.preferences.weekday[5].name = this.lview.weekday.saturday;
            this.preferences.weekday[6].name = this.lview.weekday.sunday;
        },

        notifyMe: function() {
			if (("Notification" in window)) {
				// Otherwise, we need to ask the user for permission
				if (Notification.permission !== "denied" || Notification.permission !== "default") {
					Notification.requestPermission().then(
                        function (permission) {
                            if (permission === "granted") {
                                window.location.reload();
                            }
					    }
                    );
				}
                // At last, if the user has denied notifications, and you
                // want to be respectful there is no need to bother them any more.
			}
        },
    },

    mounted: async function () {
        this.$emit('set-display', 'BACK_WINDOW');
        this.$emit('set-title', this.lview.title);

        this.loadTranslations();

        // Display loading overlayer only if page takes longer than 0.2s to load
        this.$emit('set-state', 'LOADING', this.lview.loading);

        this.languagesValue = this.$cookies.get('language');
        if(this.languagesValue == null) this.languagesValue = 'pl';

        let settingsResponse = null;
        try {
            settingsResponse = await axios({
                method: "GET",
                url: localStorage.getItem("current_env") + "/api/v2/user-settings/",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                },
            });
        } catch (error) {
            this.$emit('set-state', 'DEFAULT');
            this.$emit('set-state', 'ERROR', this.lview.couldNotFetchSettingsData);
            return;
        }
        
        //clearTimeout(timeout);
        this.$emit('set-state', 'DEFAULT');

        if (settingsResponse) {
            let response = settingsResponse.data;
            this.emailCheckboxValue = response.new_docs_notifications_enabled;
            this.frequencyValue = response.new_docs_notifications_frequency;
            this.timeValue = response.new_docs_notifications_time;

            if (this.timeValue != null && this.timeValue > 0) {
                let offset = new Date().getTimezoneOffset();

                if (this.frequencyValue == 2) {
                    let timeDiff = (this.timeValue - offset + 1440) % 1440;
                    let hour = Math.floor(timeDiff/60);
                    let minutes = timeDiff - hour * 60;
                    this.timePicker.time = String(hour).padStart(2, '0') + ':' + String(minutes).padStart(2, '0');
                }
                if (this.frequencyValue == 3) {
                    let timeDiff = (this.timeValue - offset + 10080) % 10080;
                    let day = Math.floor(timeDiff/1440);
                    let hour = Math.floor((timeDiff - day * 1440) / 60);
                    let minutes = timeDiff - day * 1440 - hour * 60;
                    this.weekdayValue = day;
                    this.timePicker.time = String(hour).padStart(2, '0') + ':' + String(minutes).padStart(2, '0');        
                }
            }

            let firebaseTimeValue = response.new_docs_notifications_phone_time;
            this.firebaseNotification.turnedOn = response.new_docs_notifications_phone_enabled;
            this.firebaseNotification.frequency = response.new_docs_notifications_phone_frequency;
            if (firebaseTimeValue != null && firebaseTimeValue > 0) {
                let firebaseOffset = new Date().getTimezoneOffset();

                // daily notifications
                if (this.firebaseNotification.frequency == 2) {
                    let firebaseTimeDiff = (firebaseTimeValue - firebaseOffset + 1440) % 1440;
                    let fHour = Math.floor(firebaseTimeDiff/60);
                    let fMinutes = firebaseTimeDiff - fHour * 60;
                    this.firebaseNotificationTimePicker.time = String(fHour).padStart(2, '0') + ":" + String(fMinutes).padStart(2, '0');
                }

                // week notifications
                if (this.firebaseNotification.frequency == 3) {
                    let firebaseTimeDiff = (firebaseTimeValue - firebaseOffset + 10080) % 10080;
                    let fDay = Math.floor(firebaseTimeDiff/1440);
                    let fHour = Math.floor((firebaseTimeDiff - fDay * 1440) / 60);
                    let fMinutes = firebaseTimeDiff - fDay * 1440 - fHour * 60;

                    this.firebaseNotification.weekdayValue = fDay;
                    this.firebaseNotificationTimePicker.time = String(fHour).padStart(2, '0') + ":" + String(fMinutes).padStart(2, '0');
                }
            }
        }
    },

    beforeDestroy: function(){
        this.$emit('set-display', 'default');
        this.$emit('set-title', '');
    },

    computed: {
        lview: {
            get: function(){
                return this.$t('views.preferences');
            }
        },
        lerrors: { 
            get: function() { 
                return this.$t('errors'); 
            } 
        },
    }
}
</script>

<style scoped>
    .form-container {
        margin-left: auto; 
        margin-right: auto;
        width: 80%;
        max-width: 350px;
        text-align: center;
    }
    .form-input {
        max-width: 250px;
        margin-left: auto;
        margin-right: auto;
    }

    .firebase-notifications-wrapper {
        display: grid;
        grid-gap: 0;
    }
</style>