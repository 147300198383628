<template>
	<v-app>
		<!-- form to new document type configuration -->
		<v-form
			ref="documentConfigurationForm"
			v-model="documentConfiguration.newTypeValid"
			lazy-validation
			style='padding-top:50px;'
		>
			<!-- dropdown for document types configuration form -->
			<v-card-actions style='background: #bdbdbd; height:50px; margin: auto; margin-right:10%; margin-left:5%; margin-bottom:20px;'>
				<v-card-text color="black" style = 'font-weight:bold;' text>
					{{ lviews.documentsSection }}
				</v-card-text>

				<v-spacer></v-spacer>

				<v-btn icon @click="documentConfiguration.visible = !documentConfiguration.visible">
					<v-icon>{{ documentConfiguration.visible ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
				</v-btn>
			</v-card-actions>

			<!-- expanding document type configuration form -->
			<v-expand-transition>
				<div v-show="documentConfiguration.visible" style='margin: auto; margin-right:10%; margin-left:5%;'>
					<!-- box for form fields -->
					<div v-if="userHasChangePermission" class="configuration-view-doc-type-add-wrapper">
						<div class="configuration-view-doc-type-text-field">
							<div style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;"> {{ lviews.addDocumentType }} </div>

							<v-text-field
								v-model="documentConfiguration.newDocumentType"
								required
								outlined
								dense
								height="20px"
								maxlength="20"
								counter
							></v-text-field>
						</div>

						<div class="configuration-button-add-center-vert">
							<v-btn
								primary
								:disabled="!documentConfiguration.newTypeValid"
								color="primary"
								@click="documentConfiguration.addDocumentType"
								class="configuration-button-add"
							>
								{{ lviews.add }}
							</v-btn>
						</div>
					</div>

					<!-- displaying all existing document types -->
					<v-flex class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
						<div style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;"> {{ lviews.documentTypes }} </div>

						
						<v-card class="mx-auto" style="border: 1px solid gray;">
							<!-- table header -->
							<v-card-text class="pt-4 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" style="display: flex;">
								<div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-xs-7" style="margin: 0px; padding: 0px;"> {{ lviews.name }} </div>
								<div v-if="userHasChangePermission || userHasDeletePermission" class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-5" style="margin: 0px; padding: 0px;"> {{ lviews.action }} </div>
							</v-card-text>

							<v-divider></v-divider>
							
							<!-- document types table content -->
							<v-virtual-scroll
								:items="documentConfiguration.documentTypes"
								:item-height="50"
								height="200"
							>
								<template v-slot:default="{ item }">
									<v-list-item>
										<v-list-item-content class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-xs-7">
											<v-list-item-title :style="item.delete?'text-decoration: line-through; color: gray':'color: black;'">{{ item.name }}</v-list-item-title>
										</v-list-item-content>

										<v-list-item-action class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-5" style="margin: 0; padding: 0;">
											<div style="width: 100%; display: flex;">
												<!-- edit option -->
												<v-icon v-if="userHasChangePermission" :disabled="item.disabled || item.delete" color="primary" style="margin-right: 5px;" @click="() => { editDocumentType(item); }"> mdi-pencil </v-icon>
												
												<!-- delete option -->
												<v-icon :disabled="!item.canDelete" v-if="!item.delete && userHasDeletePermission" color="error" style="margin-right: 5px;" @click="() => { deleteDocumentType(item); }"> mdi-delete </v-icon>
												<v-icon :disabled="!item.canDelete" v-if="item.delete && userHasDeletePermission" color="primary" style="margin-right: 5px;" @click="() => { deleteDocumentType(item); }"> mdi-undo </v-icon>

												<!-- activation/deactivation option -->
												<div v-if="userHasChangePermission" @click="() => { deactivateDocumentType(item); }" style="display: flex; cursor: pointer;">
													<v-icon :color="item.disabled?'primary':'error'" :disabled="item.delete"> mdi-backup-restore </v-icon>
													<div v-if="!item.disabled" style="margin-left: 5px;" :style="item.delete?'color: gray;':'color: red;'"> {{ lviews.deactivate }} </div>
													<div v-else style="margin-left: 5px;" :style="item.delete?'color: gray;':'color: green;'"> {{ lviews.activate }} </div>
												</div>
											</div>
										</v-list-item-action>
									</v-list-item>
								</template>
							</v-virtual-scroll>
						</v-card>

						<v-card-actions v-if="userHasChangePermission || userHasDeletePermission">
							<v-spacer></v-spacer>
							<v-btn style="margin-top: 10px; margin-right: -8px;" color="primary" @click="saveDocumentTypesChanges"> {{ lviews.save }} </v-btn>
						</v-card-actions>
					</v-flex>
				</div>
			</v-expand-transition>
		</v-form>

		<!-- form to new boarding configuration -->
		<!-- <v-form
			ref="boardingConfigurationForm"
			v-model="boardingConfiguration.newBoardingStatusValid"
			lazy-validation
			style='padding-top:50px;'
		> -->
			<!-- dropdown for boarding configuration form -->
			<!-- <v-card-actions style='background: #bdbdbd; height:50px; margin: auto; margin-right:10%; margin-left:5%; margin-bottom:20px;'>
				<v-card-text color="black" style = 'font-weight:bold;' text>
					{{ lviews.boardingSection }}
				</v-card-text>

				<v-spacer></v-spacer>

				<v-btn icon @click="boardingConfiguration.visible = !boardingConfiguration.visible">
					<v-icon> {{ boardingConfiguration.visible ? 'mdi-chevron-up' : 'mdi-chevron-down' }} </v-icon>
				</v-btn>
			</v-card-actions> -->

			<!-- expanding boarding configuration form -->
			<!-- <v-expand-transition>
				<div v-show="boardingConfiguration.visible" style='margin: auto; margin-right:10%; margin-left:5%;'>
					<v-divider></v-divider> -->

					<!-- new boarding status input -->
					<!-- <div style='display:inline-flex; flex-wrap: wrap; justify-content:center; width:100%; margin:auto; padding: 0 10px;'>
						<v-flex class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-12">
							<div style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;"> {{ lviews.addBoardingStatus }} </div>

							<v-text-field
								v-model="boardingConfiguration.newBoardingStatus"
								required
								outlined
								dense
								height="20px"
							></v-text-field>
						</v-flex>
						<v-spacer></v-spacer>

						<v-layout align-center justify-left class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
							<v-btn
								primary
								:disabled="!boardingConfiguration.newBoardingStatusValid"
								color="primary"
								@click="boardingConfiguration.addBoardingStatus"
								style='vertical-align:top;'
								class="smart-button-width"
							>
								{{ lviews.add }}
							</v-btn>
						</v-layout>
					</div> -->

					<!-- displaying all existing boarding statuses -->
					<!-- <v-flex class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
						<div style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;"> {{ lviews.boardingStatuses }} </div>
						
						<v-card class="mx-auto" style="border: 1px solid gray;">-->
							<!-- table header -->
							<!-- <v-card-text class="pt-4 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" style="display: flex;">
								<div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-xs-7" style="margin: 0px; padding: 0px;"> {{ lviews.name }} </div>
								<div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-5" style="margin: 0px; padding: 0px;"> {{ lviews.action }} </div>
							</v-card-text> -->

							<!-- <v-divider></v-divider> -->
							
							<!-- boarding statuses table content -->
							<!-- <v-virtual-scroll
								:items="boardingConfiguration.boardingStatuses"
								:item-height="50"
								height="200"
							>
								<template v-slot:default="{ item }">
									<v-list-item>
										<v-list-item-content class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-xs-7">
											<v-list-item-title :style="item.disabled?'text-decoration: line-through; color: gray':'color: black;'">{{ item.name }}</v-list-item-title>
										</v-list-item-content>

										<v-list-item-action class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-5" style="margin: 0; padding: 0;">
											<div style="width: 100%; display: flex;">
												<v-icon :disabled="item.disabled" color="primary" style="margin-right: 5px;" @click="() => { editBoardingStatus(item); }"> mdi-pencil </v-icon>
												<v-icon :color="item.disabled?'success':'error'" @click="() => { deactivateBoardingStatus(item); }"> mdi-backup-restore </v-icon>
												<div v-if="!item.disabled" style="color: red; margin-left: 5px;"> {{ lviews.deactivate }} </div>
												<div v-else style="color: green; margin-left: 5px;"> {{ lviews.activate }} </div>
											</div>
										</v-list-item-action>
									</v-list-item>
								</template>
							</v-virtual-scroll>
						</v-card> -->

						<!-- <v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="primary" @click="saveBoardingStatusesChanges"> {{ lviews.save }} </v-btn>
						</v-card-actions>
					</v-flex> -->

				<!-- </div>
			</v-expand-transition>

		</v-form> -->

		<!-- new document type edit dialog -->
		<v-dialog
			v-model="editDocumentDialog.visible"
			persistent
			max-width="350"
		>
			<v-card>
				<v-card-title class="text-h5">
					{{ lviews.editDocumentType }}
				</v-card-title>

				<v-text-field
					v-model="editDocumentDialog.modifiedType.name"
					style="margin: auto 20px;"
					clearable
					:rules="editDocumentDialog.rules.name"
				></v-text-field>
				
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						@click="cancelEditingDocument"
					>
						{{ lviews.cancel }}
					</v-btn>

					<v-btn
						text
						@click="editDocumentTypeCallback"
						:disabled="editDocumentDialog.errors.name"
					>
						{{ lviews.save }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- boarding edit dialog -->
		<!-- <v-dialog
			v-model="editBoardingDialog.visible"
			persistent
			max-width="350"
		>
			<v-card>
				<v-card-title class="text-h5">
					{{ lviews.editBoardingStatus }}
				</v-card-title>

				<v-text-field
					v-model="editBoardingDialog.modifiedBoarding.name"
					style="margin: auto 20px;"
					clearable
					:rules="editBoardingDialog.rules.name"
				></v-text-field>
				
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						@click="cancelEditingBoardingStatus"
					>
						{{ lviews.cancel }}
					</v-btn>

					<v-btn
						text
						@click="editBoardingStatusCallback"
						:disabled="editBoardingDialog.errors.name"
					>
						{{ lviews.save }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog> -->

		<!-- configuration error dialogs -->
		<v-dialog
			v-model="errorDialog.visible"
			persistent
			max-width="350px"
		>
			<v-card>
				<v-card-title>
					{{ errorDialog.title }}
				</v-card-title>

				<v-card-text>
					{{ errorDialog.content }}
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>

					<v-btn
						text
						@click="closeErrorDialog"
					>
						{{ lviews.understand }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- API call notification snackbar -->
		<v-snackbar
			v-model="snackbar.visible"
			:color="snackbar.color"
			max-width="400px"
			timeout="1500"
		>
			{{ snackbar.message }}

			<template v-slot:action="{ attrs }">
				<v-btn
					color="white"
					text
					v-bind="attrs"
					@click="snackbar.visible = false"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
	</v-app>
</template>

<script>
import axios from 'axios';
import fob_config from '../fob_config.js';

export default {
	name: 'Configuration',
	data() {
		return{
			snackbar: {
				visible: false,
				color: null,
				message: "",
			},

			documentConfiguration: {
				visible: true,
				newDocumentType: '',
				newTypeValid: false,
				documentTypes: [
					/**
					 * {
					 *      name     : {String} - name of document type
					 *      id       : {Number} - document type identifier (in frontend)
					 *      disabled : {Boolean} - information whether document type is disabled or not
					 * }
					 */
				],
				sourceData: [
					/**
					 * {
					 *      name     : {String} - name of document type
					 *      id       : {Number} - document type identifier (in frontend)
					 *      disabled : {Boolean} - information whether document type is disabled or not
					 * }
					 */
				],

				validate: () => {},
				reset: () => {},
				addDocumentType: () => {}
			},

			// edit document value
			editDocumentDialog: {
				visible: false,
				item: null,

				modifiedType: {
					name: null
				},
				errors: {
					name: false,
				},
				errorMessages: {
					name: "",
				},

				// edit dialog fields' validation rules
				rules: {
					name: [] // assigned in mounted()
				}
			},

			boardingConfiguration: {
				visible: true,
				newBoardingStatus: '',
				newBoardingStatusValid: false,
				boardingStatuses: [
					/**
					 * {
					 *      name     : {String} - name of boarding status
					 *      id       : {Number} - boarding identifier (in frontend)
					 *      disabled : {Boolean} - information whether boarding status is disabled or not
					 * }
					 */
				],

				validate: () => {},
				reset: () => {},
				addBoardingStatus: () => {}
			},

			// edit boarding value
			editBoardingDialog: {
				visible: false,
				item: null,

				modifiedBoarding: {
					name: null,
				},
				errors: {
					name: false,
				},
				errorMessages: {
					name: "",
				},

				rules: {
					name: []
				}
			},

			errorDialog: {
				visible: false,
				title: "",
				content: "",
			}
		};
	},
	mounted: async function() {
		this.$emit('set-display', "BACK_WINDOW");
		this.$emit('set-title', this.lviews.configurationTitle);
		let userPermissions = localStorage.getItem("user_permissions");
		if ( !userPermissions.includes("fob.admin_view") ) {
			this.$emit('set-state', 'ERROR', this.lviews.youDoNotHavePriviledgesToThisView);
			return;
		}
		this.editBoardingDialog.rules.name = [
			v => ( this.editBoardingStatusNameRule(v) ) || this.editBoardingDialog.errorMessages.name
		];

		this.editDocumentDialog.rules.name = [
			v => ( this.editDocumentTypeNameRule(v) ) || this.editDocumentDialog.errorMessages.name
		];

		this.documentConfiguration.validate = this.validateDocumentTypesInput;
		this.documentConfiguration.reset = this.resetDocumentTypeInput;
		this.documentConfiguration.addDocumentType = this.addNewDocumentType;

		this.boardingConfiguration.validate = this.validateBoardingStatusInput;
		this.boardingConfiguration.reset = this.resetBoardingStatusInput;
		this.boardingConfiguration.addBoardingStatus = this.addNewBoardingStatus;

		let documentTypesData = await this.fetchDocumentTypesData();
		if (documentTypesData != null){
			this.adaptDocumentTypesData(documentTypesData);
		}
	},
	computed: {
		lviews: {
			get: function() {
				return this.$t('views.configurationPanel');
			}
		},
		userHasAddPermission: {
			get: function() {
				let userPermissions = localStorage.getItem("user_permissions");
				return userPermissions.includes("fob.admin_add");
			}
		},
		userHasChangePermission: {
			get: function() {
				let userPermissions = localStorage.getItem("user_permissions");
				return userPermissions.includes("fob.admin_change");
			}
		},
		userHasDeletePermission: {
			get: function() {
				let userPermissions = localStorage.getItem("user_permissions");
				return userPermissions.includes("fob.admin_delete");
			}
		}
	},
	methods: {
		// DOCUMENT TYPE METHODS

		/**
		 * Adapts response data received from APIs:
		 *  -> ( link: api/v2/admin/document-types/ | method: GET )
		 *  -> ( link: api/v2/admin/document-types/ | method: PATCH )
		 * @param responseData: {Object}
		 */
		adaptDocumentTypesData: function (responseData) {
			this.documentConfiguration.documentTypes = [];
			this.documentConfiguration.sourceData = [];

			for(let i = 0; i < responseData.document_types.length; i++) {
				this.documentConfiguration.documentTypes.push({
					id: responseData.document_types[i].id,
					name: responseData.document_types[i].name,
					disabled: responseData.document_types[i].disabled,
					delete: false,
					canDelete: responseData.document_types[i].can_delete
				});

				this.documentConfiguration.sourceData.push({
					id: responseData.document_types[i].id,
					name: responseData.document_types[i].name,
					disabled: responseData.document_types[i].disabled,
					delete: false,
					canDelete: responseData.document_types[i].can_delete
				});
			}
		},

		// fetches document types data from database
		fetchDocumentTypesData: async function() {
			let responsePromise = axios({
				method: "GET",
				url: fob_config.api_url + "/api/v2/admin/document-types/",
				headers: {
					'Authorization': 'Bearer ' + localStorage.getItem('jwt')
				}
			});

			let response = null;
			try {
				response = await responsePromise;
			} catch (error) {
				// handle errors
			}

			return response.data;
		},

		// Saves all changes that were provided for existing document types
		saveDocumentTypesChanges: async function() {
			let changes = [
				// {
				//     id: {Number} or -1 if new document type added,
				//     name: {String} - name of document type,
				//     disabled: {0, 1} - Boolean value if specific document type is disabled or not
				// }
			];
			let deletions = []; // list of numbers

			// get all document types changes
			let documentObj = null;
			for(let i = 0; i < this.documentConfiguration.documentTypes.length; i++) {
				documentObj = this.documentConfiguration.documentTypes[i];
				if (documentObj.delete) {
					deletions.push(documentObj.id);
				}
				else {
					if (documentObj.id == -1) {
						changes.push({
							id: documentObj.id,
							name: documentObj.name,
							disabled: documentObj.disabled ? 1 : 0
						});
					}
					else {
						let sourceObj = this.documentConfiguration.sourceData.find(x => { return x.id == documentObj.id; });
						if (sourceObj.name !== documentObj.name || sourceObj.disabled !== documentObj.disabled) {
							changes.push({
								id: documentObj.id,
								name: documentObj.name,
								disabled: documentObj.disabled ? 1 : 0
							});
						}
					}
				}
			}

			if (changes.length == 0 && deletions.length == 0) {
				this.snackbar.color = 'warning';
				this.snackbar.message = this.lviews.cannotSaveWithoutChanges;
				this.snackbar.visible = true;
				return;
			}
			this.$emit('set-state', 'LOADING_OVERLAYER', this.lviews.documentTypesUpdatingMessage);

			let responseChangesPromise = null;
			if (changes.length > 0 && this.userHasChangePermission) {
				let changesRequestData = {
					'document_types': {
						'changes': changes
					}
				};

				responseChangesPromise = axios({
					url: fob_config.api_url + "/api/v2/admin/document-types/",
					method: "PATCH",
					headers: {
						'Authorization': 'Bearer ' + localStorage.getItem('jwt')
					},
					data: changesRequestData
				});
			}

			let changesResponse = null;
			if (responseChangesPromise != null) {
				try {
					changesResponse = await responseChangesPromise;
				} catch (error) {
					this.$emit('set-state', 'default');
					if (error.isAxiosError) {
						this.snackbar.color = 'error';
						this.snackbar.message = this.lviews.documentTypesUpdateFailed;
						this.snackbar.visible = true;
					}
					return;
				}
			}
			if (changesResponse != null) {
				this.adaptDocumentTypesData(changesResponse.data);
			}

			// delete request

			let responseDeletionsPromise = null;
			if (deletions.length > 0 && this.userHasDeletePermission) {
				let deletionsRequestData = {
					'document_types': {
						'deletions': deletions
					}
				};

				responseDeletionsPromise = axios({
					url: fob_config.api_url + "/api/v2/admin/document-types/",
					method: "DELETE",
					headers: {
						'Authorization': 'Bearer ' + localStorage.getItem('jwt')
					},
					data: deletionsRequestData
				});
			}

			let deleteResponse = null;
			if (responseDeletionsPromise != null) {
				try {
					deleteResponse = await responseDeletionsPromise;
				} catch (error) {
					this.$emit('set-state', 'default');
					if (error.isAxiosError) {
						this.snackbar.color = 'error';
						this.snackbar.message = this.lviews.documentTypesUpdateFailed;
						this.snackbar.visible = true;
					}
					return;
				}
			}
			if (deleteResponse != null) {
				this.adaptDocumentTypesData(deleteResponse.data);
			}

			this.$emit('set-state', 'default');

			this.snackbar.color = 'success';
			this.snackbar.message = this.lviews.documentTypesUpdatedSuccessfully;
			this.snackbar.visible = true;
		},

		// Validates new document type
		// When provided value is invalid, input component is put in error state
		validateDocumentTypesInput: function() {
			this.$refs.documentConfigurationForm.validate(); // TODO
		},

		// Resets new document type input
		resetDocumentTypeInput: function() {
			this.$refs.documentConfigurationForm.reset(); // TODO?
		},

		// Adds new document type
		// The first thing it does is to check whether it can add new document or not
		addNewDocumentType: function() {
			this.documentConfiguration.validate();
			if (this.documentConfiguration.newDocumentType == '' || !this.documentConfiguration.newTypeValid) {
				return;
			}

			let typeIdx = this.documentConfiguration.documentTypes.findIndex(x => { return x.name == this.documentConfiguration.newDocumentType; });
			if (typeIdx != -1) {
				this.openErrorDialog(this.lviews.error, this.lviews.errorDocumentExists);
				return;
			}

			this.documentConfiguration.documentTypes.push({
				name: this.documentConfiguration.newDocumentType,
				id: -1,
				disabled: false,
				delete: false,
			});
			this.documentConfiguration.newDocumentType = '';
		},

		deleteDocumentType: function(documentType){
			if (documentType.id == -1) {
				let idx = this.documentConfiguration.documentTypes.findIndex(x => { return x === documentType; }); // reference check
				this.documentConfiguration.documentTypes.splice(idx, 1);
			}
			else {
				documentType.delete = !documentType.delete;
			}
		},

		/**
		 * Edits existing/already added document type
		 * @param documentType - document type to edit
		 */
		editDocumentType: function(documentType) {
			this.editDocumentDialog.item = documentType;
			this.editDocumentDialog.modifiedType.name = documentType.name;
			this.editDocumentDialog.visible = true;
		},

		// Resets edit document type dialog and closes it
		cancelEditingDocument: function() {
			this.editDocumentDialog.item = null;
			this.modifiedType = {
				name: null
			};

			this.editDocumentDialog.visible = false;
		},

		// Edit document type callback
		// Closes document type edit dialog and modifies document type data
		editDocumentTypeCallback: function() {
			let modified = this.editDocumentDialog.modifiedType;
			// if there's any error, we cannot update document type
			if (this.editDocumentDialog.errors.name) {
				return;
			}

			// update document type
			for(var i = 0; i < this.documentConfiguration.documentTypes.length; i++) {
				if (this.documentConfiguration.documentTypes[i].id == this.editDocumentDialog.item.id) {
					this.documentConfiguration.documentTypes[i].name = modified.name;
					break;
				}
			}

			// reset dialog data and close it
			this.editDocumentDialog.item = null;
			this.editDocumentDialog.modifiedType = {
				name: null,
			};
			this.editDocumentDialog.errors = {
				name: false,
			}

			this.editDocumentDialog.visible = false;
		},

		// document type name edit rule
		editDocumentTypeNameRule: function(documentTypeName) {
			// document type cannot be empty
			if (documentTypeName == null || documentTypeName == '') {
				this.editDocumentDialog.errors.name = true;
				this.editDocumentDialog.errorMessages.name = this.lviews.errorTypeText;
				return false;
			}

			let idx = this.documentConfiguration.documentTypes.findIndex(x => { return x.name == documentTypeName; });
			if (idx != -1 && this.documentConfiguration.documentTypes[idx].id != this.editDocumentDialog.item.id) {
				this.editDocumentDialog.errors.name = true;
				this.editDocumentDialog.errorMessages.name = this.lviews.errorDocumentExists;
				return false;
			}
			this.editDocumentDialog.errors.name = false;
			this.editDocumentDialog.errorMessages.name = "";
			return true;
		},

		/**
		 * Deactivates given document type
		 * @param documentType - document type to deactivate
		 */
		deactivateDocumentType: function(documentType) {
			documentType.disabled = !documentType.disabled;
		},



		// BOARDING METHODS

		// Saves all changes that were provided for existing boardings
		saveBoardingStatusesChanges: function () {
			console.log("Saving all boarding changes...");
		},

		// Validates boarding status input
		validateBoardingStatusInput: function() {
			this.$refs.boardingConfigurationForm.validate(); // TODO
		},

		// Resets new boarding status form
		resetBoardingStatusInput: function() {
			this.$refs.boardingConfigurationForm.reset(); // TODO?
		},

		// Adds provided new boarding status to database
		addNewBoardingStatus: function() {
			this.boardingConfiguration.validate();
			if (this.boardingConfiguration.newBoardingStatus == '' || !this.boardingConfiguration.newBoardingStatusValid) {
				return;
			}

			let typeIdx = this.boardingConfiguration.boardingStatuses.findIndex(x => { return x.name == this.boardingConfiguration.newBoardingStatus; });
			if (typeIdx != -1) {
				this.openErrorDialog(this.lviews.error, this.lviews.errorBoardingExists);
				return;
			}

			let newBoardingFrontId = this.boardingConfiguration.boardingStatuses.length + 1;

			this.boardingConfiguration.boardingStatuses.push({
				name: this.boardingConfiguration.newBoardingStatus,
				id: newBoardingFrontId,
				disabled: false,
			});
			this.boardingConfiguration.newBoardingStatus = '';
		},

		/**
		 * Deactivates given document type
		 * @param boardingStatus - boarding status to deactivate
		 */
		deactivateBoardingStatus: function(boardingStatus) {
			boardingStatus.disabled = !boardingStatus.disabled;
		},

		/**
		 * Edits given boarding status
		 * @param boardingStatus - boarding status that we want to modify (in dialog which will appear soon)
		 */
		editBoardingStatus: function(boardingStatus) {
			this.editBoardingDialog.item = boardingStatus;
			this.editBoardingDialog.modifiedBoarding.name = boardingStatus.name;
			this.editBoardingDialog.visible = true;
		},

		// Resets edit boarding status dialog and closes it
		cancelEditingBoardingStatus: function() {
			this.editBoardingDialog.modifiedBoarding = {
				name: null,
			};

			this.editBoardingDialog.errors = {
				name: false,
			};

			this.editBoardingDialog.item = null;
			this.editBoardingDialog.visible = false;
		},

		// Edit boarding status callback
		// Closes boarding status edit dialog and modifies input boarding data
		editBoardingStatusCallback: function() {
			let modified = this.editBoardingDialog.modifiedBoarding;
			// if there's any error, we cannot update document type
			if (this.editBoardingDialog.errors.name) {
				return;
			}

			// update document type
			for(var i = 0; i < this.boardingConfiguration.boardingStatuses.length; i++) {
				if (this.boardingConfiguration.boardingStatuses[i].id == this.editBoardingDialog.item.id) {
					this.boardingConfiguration.boardingStatuses[i].name = modified.name;
					break;
				}
			}

			// reset dialog data and close it
			this.editBoardingDialog.item = null;
			this.editBoardingDialog.modifiedType = {
				name: null,
			};
			this.editBoardingDialog.errors = {
				name: false,
			};

			this.editBoardingDialog.visible = false;
		},

		// boarding status name edit rule
		editBoardingStatusNameRule: function(newBoardingStatusNameValue) {
			if (newBoardingStatusNameValue == null || newBoardingStatusNameValue == '') {
				this.editBoardingDialog.errors.name = true;
				this.editBoardingDialog.errorMessages.name = this.lviews.errorBoardingName;
				return false;
			}

			let idx = this.boardingConfiguration.boardingStatuses.findIndex(x => {return x.name == newBoardingStatusNameValue; });
			if (idx != -1 && this.boardingConfiguration.boardingStatuses[idx].id != this.editBoardingDialog.item.id) {
				this.editBoardingDialog.errors.name = true;
				this.editBoardingDialog.errorMessages.name = this.lviews.errorBoardingExists;
				return false;
			}
			this.editBoardingDialog.errors.name = false;
			this.editBoardingDialog.errorMessages.name = "";
			return true;
		},

		// OTHER METHODS
		openErrorDialog: function(title, message) {
			this.errorDialog.title = title;
			this.errorDialog.content = message;
			this.errorDialog.visible = true;
		},

		closeErrorDialog: function() {
			this.errorDialog.title = "";
			this.errorDialog.content = "";
			this.errorDialog.visible = false;
		}
	},
}
</script>

<style scoped>

.configuration-view-doc-type-add-wrapper {
	display: grid;
	width: 100%;
	padding: 0 12px;
	grid-gap: 10px;
	grid-template-columns: 1fr 120px;
}
.configuration-button-add-center-vert {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.configuration-button-add {
	margin-bottom: 0;
}

.smart-button-width {
	max-width: 200px;
}

@media only screen and (max-width: 450px){
	.configuration-view-doc-type-add-wrapper {
		grid-template-columns: none;
	}
	.configuration-button-add {
		margin-bottom: 30px;
	}
}
</style>
