// tenant definition
const DEF_TENANT = "porr"; // PORR deployment

// Rest of settings
module.exports = {
    app_tenant: DEF_TENANT,
    app_ver: "1.5_24_02_2022 (" + DEF_TENANT + ")",
    //api_url: "https://" + DEF_TENANT + "-desktop.fastonboarding.com",
    api_url: 'https://porr-desktop.fastonboarding.com', // local DEBUG overwrite
    service_worker_ver: "1.0_25_09_2019DEV",
    service_worker_update_timout: 1000 * 60 * 60,
    debug: false,
    client_logo: "/static/img/porr_logo.png",
    page_title: "Fast OnBoarding",
};