<template>
	<v-dialog
	  v-model="addressDialog.visible"
	  persistent
	  max-width="600px"
		scrollable
	>
		<v-card>
			<v-card-title>
				<span class="text-h5">{{ addressDialog.state == addressDialogState.CREATE ? lviews.newAddress : lviews.editAddress }}</span>
			</v-card-title>
			<v-divider style="margin-bottom: 25px;"></v-divider>

			<v-card-text class="address-dialog-card-text">
				<v-container class="address-dialog-container">
					<!-- street name & number -->
					<div class="address-dialog-row-wrapper" style="margin-top: 3px">
						<v-text-field
							v-model="addressDialogForm.form.streetName"
							class="address-dialog-form-field"
							:label="lviews.streetName"
							maxlength="100"
							counter
							outlined
							height="30px"
							dense
							clearable
							:error="addressDialogForm.errors.streetName.isError"
							:error-messages="addressDialogForm.errors.streetName.errorMessage"
							@input="() => { resetErrors(); checkChangesProvided(); }"
						></v-text-field>

						<v-text-field
							v-model="addressDialogForm.form.streetNumber"
							class="address-dialog-form-field"
							:label="lviews.streetNumber"
							maxlength="50"
							counter
							outlined
							height="30px"
							dense
							clearable
							:error="addressDialogForm.errors.streetNumber.isError"
							:error-messages="addressDialogForm.errors.streetNumber.errorMessage"
							@input="() => { resetErrors(); checkChangesProvided(); }"
						></v-text-field>
					</div>

					<!-- property number & zip code -->
					<div class="address-dialog-row-wrapper">
						<v-text-field
							v-model="addressDialogForm.form.propertyNumber"
							class="address-dialog-form-field"
							:label="lviews.propertyNumber"
							maxlength="50"
							counter
							outlined
							height="30px"
							dense
							clearable
							:error="addressDialogForm.errors.propertyNumber.isError"
							:error-messages="addressDialogForm.errors.propertyNumber.errorMessage"
							@input="() => { resetErrors(); checkChangesProvided(); }"
						></v-text-field>

						<v-text-field
							v-model="addressDialogForm.form.zipCode"
							class="address-dialog-form-field"
							:label="lviews.zipCode"
							maxlength="12"
							counter
							outlined
							height="30px"
							dense
							clearable
							:error="addressDialogForm.errors.zipCode.isError"
							:error-messages="addressDialogForm.errors.zipCode.errorMessage"
							@input="() => { resetErrors(); checkChangesProvided(); }"
						></v-text-field>
					</div>

					<!-- city & region -->
					<div class="address-dialog-row-wrapper">
						<v-text-field
							v-model="addressDialogForm.form.city"
							class="address-dialog-form-field"
							:label="lviews.city"
							maxlength="100"
							counter
							outlined
							height="30px"
							dense
							clearable
							:error="addressDialogForm.errors.city.isError"
							:error-messages="addressDialogForm.errors.city.errorMessage"
							@input="() => { resetErrors(); checkChangesProvided(); }"
						></v-text-field>

						<v-text-field
							v-model="addressDialogForm.form.region"
							class="address-dialog-form-field"
							:label="lviews.region"
							maxlength="100"
							counter
							outlined
							height="30px"
							dense
							clearable
							:error="addressDialogForm.errors.region.isError"
							:error-messages="addressDialogForm.errors.region.errorMessage"
							@input="() => { resetErrors(); checkChangesProvided(); }"
						></v-text-field>
					</div>

					<!-- country -->
					<div style="padding-bottom: 15px;">
						<v-select
							v-model="addressDialogForm.form.country"
							:items="countriesSelectItems"
							:label="lviews.country"
							item-value="key"
							item-text="text"
							:error="addressDialogForm.errors.country.isError"
							:error-messages="addressDialogForm.errors.country.errorMessage"
							@change="() => { resetErrors(); checkChangesProvided(); }"
						></v-select>
					</div>

					<!-- address description -->
					<div>
						<v-textarea
							v-model="addressDialogForm.form.description"
							class="address-dialog-form-field-full"
							:label="lviews.description"
							maxlength="100"
							counter
							outlined
							auto-grow
							@input="() => { if (addressDialog.state == addressDialogState.EDIT) { checkChangesProvided(); } }"
						></v-textarea>
					</div>

					<!-- assigning address to project -->
					<div style="padding-left: 0px;">
						<v-select
							style="padding-left: 0px; padding-top: 0px; padding-bottom: 0px;"
							class="address-dialog-form-field-full"
							:label="lviews.assignToProject"
							:placeholder="lviews.choose"
							:items="projects"
							item-value="id"
							item-text="name"
							v-model="chosenProjectsIds"
							@change="() => { resetErrors(); checkChangesProvided(); }"
							clearable
							multiple
						>
							<template v-slot:selection="{ item, index }">
								<div v-if="chosenProjectsIds.length == projects.length">
									<span v-if="index == 0"> 
										{{ lviews.allProjectsSelected }}
									</span>
								</div>
								<div v-else>
									<span v-if="index < 2" style="margin-right: 10px;">{{ item.name }} &nbsp;</span>
									<span
										v-else-if="index === 2"
										class="grey--text caption"
									>(+{{ chosenProjectsIds.length - 2 }})</span>
								</div>
							</template>
						</v-select>
					</div>
				</v-container>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="grey darken-1"
					text
					@click="closeDialog"
				>
					{{ lviews.cancel }}
				</v-btn>

				<v-btn
					color="primary"
					text
					@click="handleConfirmation"
					:disabled="!changesInEditAddressProvided && addressDialog.state == addressDialogState.EDIT"
				>
					{{ lviews.save }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import axios from 'axios';
import fobConfig from '../../fob_config';

export default {
	data: function() {
		return {
			addressDialog: {
				title: "",
				state: null,
				visible: false,
			},

			addressDialogForm: {
				form: {
					streetName: null,
					streetNumber: null,
					propertyNumber: null,
					zipCode: null,
					city: null,
					region: null,
					country: null,
					description: null,
				},

				errors: {
					streetName: {
						isError: false,
						errorMessage: "",
					},
					streetNumber: {
						isError: false,
						errorMessage: "",
					},
					propertyNumber: {
						isError: false,
						errorMessage: "",
					},
					zipCode: {
						isError: false,
						errorMessage: "",
					},
					city: {
						isError: false,
						errorMessage: "",
					},
					region: {
						isError: false,
						errorMessage: "",
					},
					country: {
						isError: false,
						errorMessage: "",
					}
				},

			},

			addressDialogState: {
				CREATE: 0,
				EDIT: 1,
			},

			countriesSelectItems: [],
			changesInEditAddressProvided: false,
			chosenProjectsIds: [],
		}
	},

	methods: {
		/**
		 * Resets address form data (including field errors data).
		 */
		resetAddressForm: function() {
			let fieldsKeys = Object.keys(this.addressDialogForm.form);
			for (let i = 0; i < fieldsKeys.length; i++) {
				this.addressDialogForm.form[ fieldsKeys[i] ] = null;
				if (this.addressDialogForm.errors[ fieldsKeys[i] ]) {
					this.addressDialogForm.errors[ fieldsKeys[i] ].isError = false;
					this.addressDialogForm.errors[ fieldsKeys[i] ].errorMessage = "";
				}
			}
		},

		/**
		 * Assigns address data to form
		 */
		assignAddressDataToForm: function() {
			this.addressDialogForm.form.streetName = this.address.streetName;
			this.addressDialogForm.form.streetNumber = this.address.streetNumber;
			this.addressDialogForm.form.propertyNumber = this.address.propertyNumber;
			this.addressDialogForm.form.zipCode = this.address.zipCode;
			this.addressDialogForm.form.city = this.address.city;
			this.addressDialogForm.form.region = this.address.region;
			this.addressDialogForm.form.country = this.address.country;
			this.addressDialogForm.form.description = this.address.description;

			this.chosenProjectsIds = [];
			for (let i = 0; i < this.address.projects.length; i++) {
				this.chosenProjectsIds.push(this.address.projects[i]);
			}
		},

		/**
		 * Checks if changes were provided to form by user.
		 */
		checkChangesProvided: function() {
			if (this.address != null) {
				let formFields = Object.keys(this.addressDialogForm.form);

				for (let i = 0; i < formFields.length; i++) {
					if (this.addressDialogForm.form[ formFields[i] ] != this.address[ formFields[i] ]) {
						this.changesInEditAddressProvided = true;
						return;
					}
				}

				if (this.chosenProjectsIds.length != this.address.projects.length) {
					this.changesInEditAddressProvided = true;
					return;
				}
				else {
					for (let i = 0; i < this.address.projects.length; i++) {
						if (!this.address.projects.includes(this.chosenProjectsIds[i])) {
							this.changesInEditAddressProvided = true;
							return;
						}
					}
				}
				this.changesInEditAddressProvided = false;
			}
			else {
				this.changesInEditAddressProvided = false;
			}
		},

		/**
		 * Opens address dialog according to 'address' prop value (if address is null -> we create new address, otherwise we're editing existing one).
		 */
		openDialog: function() {
			this.resetAddressForm();

			if (this.address == null) {
				this.addressDialog.state = this.addressDialogState.CREATE;
			}
			else {
				this.addressDialog.state = this.addressDialogState.EDIT;
				this.changesInEditAddressProvided = false;
				this.assignAddressDataToForm();
			}
			this.addressDialog.visible = true;
		},

		/**
		 * Closes address dialog.
		 */
		closeDialog: function() {
			this.addressDialog.visible = false;
		},

		/**
		 * Resets form errors.
		 */
		resetErrors: function() {
			let fieldsKeys = ["streetName", "streetNumber", "propertyNumber", "zipCode", "city", "region", "country"];
			for (let i = 0; i < fieldsKeys.length; i++) {
				this.addressDialogForm.errors[ fieldsKeys[i] ].isError = false;
				this.addressDialogForm.errors[ fieldsKeys[i] ].errorMessage = "";
			}
		},

		/**
		 * Handles confirmation.
		 */
		handleConfirmation: function() {
			if (this.addressDialog.state == this.addressDialogState.CREATE) {
				this.sendCreateAddressRequest();
			}
			else {
				this.sendUpdateAddressRequest();
			}
		},

		/**
		 * Sends request in order to create new address.
		 */
		sendCreateAddressRequest: async function() {
			let fieldsKeys = ["streetName", "streetNumber", "propertyNumber", "zipCode", "city", "region", "country"];
			let emptyFieldsNum = 0;
			for (let i = 0; i < fieldsKeys.length; i++) {
				if (this.addressDialogForm.form[ fieldsKeys[i] ] == "" || this.addressDialogForm.form[ fieldsKeys[i] ] == null || this.addressDialogForm.form[ fieldsKeys[i] ] == 'undefined') {
					emptyFieldsNum++;
				}
			}

			if (emptyFieldsNum == fieldsKeys.length) {
				for (let i = 0; i < fieldsKeys.length; i++) {
					this.addressDialogForm.errors[ fieldsKeys[i] ].isError = true;
					this.addressDialogForm.errors[ fieldsKeys[i] ].errorMessage = this.lviews.cannotSendEmptyForm;
				}
				return;
			}
			else {
				this.resetErrors();
			}

			let requestData = {
				primary_postal_address: {
					street_name: this.addressDialogForm.form.streetName,
					street_number: this.addressDialogForm.form.streetNumber,
					property_number: this.addressDialogForm.form.propertyNumber,
					zip_code: this.addressDialogForm.form.zipCode,
					city: this.addressDialogForm.form.city,
					region: this.addressDialogForm.form.region,
					country: this.addressDialogForm.form.country,
					description: this.addressDialogForm.form.description
				}
			};

			if (this.chosenProjectsIds.length > 0) {
				requestData['projects'] = [];
				for (let i = 0; i < this.chosenProjectsIds.length; i++) {
					requestData['projects'].push({
						id: this.chosenProjectsIds[i],
						operation: 1
					});
				}
			}

			this.$emit("show-overlayer", this.lviews.creatingNewAddress);
			let response = null;
			try {
				response = await axios({
					url: fobConfig.api_url + "/api/v2/admin/postal-address/",
					method: "POST",
					headers: {
						'Authorization': "Bearer " + localStorage.getItem("jwt")
					},
					data: requestData
				});
			} catch (error) {
				this.$emit("hide-overlayer");
				response = null;
				switch(error.response.status) {
					case 400:
						this.$emit("emit-error", this.lviews.badRequest, this.lviews.badRequestMessage, error.toString());
						break;
					case 401:
						this.$router.push("/login?next=/addresses");
						break;
					case 403:
						this.$emit("emit-error", this.lviews.forbiddenOperation, this.lviews.youDoNotHavePrivilegesToDoThatOperation, error.toString());
						break;
					case 404:
						this.$emit("emit-error", this.lviews.notFound, this.lviews.notFoundErrorMessage, error.toString());
						break;
					case 429:
						this.$emit("emit-error", this.lviews.tooManyRequests, this.lviews.tooManyRequestsMessage, error.toString());
						break;
					default:
						this.$emit("emit-error", this.lviews.errorOccurred, this.lviews.errorOccurredMessage, error.toString());
						break;
				}
			}
			if (response != null) {
				this.$emit("hide-overlayer");
				this.$emit("emit-update");
				this.closeDialog();
			}
		},

		/**
		 * Sends request in order to update existing address.
		 */
		sendUpdateAddressRequest: async function() {
			let updateRequestData = {
				primary_postal_address: {

				}
			};

			let apiFields = {
				streetName: 'street_name',
				streetNumber: 'street_number',
				propertyNumber: 'property_number',
				zipCode: 'zip_code',
				city: 'city',
				region: 'region',
				country: 'country',
				description: 'description'
			};

			let formFields = Object.keys(this.addressDialogForm.form);
			for (let i = 0; i < formFields.length; i++) {
				if (this.address[ formFields[i] ] != this.addressDialogForm.form[ formFields[i] ]) {
					updateRequestData.primary_postal_address[ apiFields[formFields[i]] ] = this.addressDialogForm.form[ formFields[i] ];
				}
			}

			// process dictionary project assignments
			let addressPreviousProjects = [];
			for (let i = 0; i < this.address.projects.length; i++) {
				addressPreviousProjects.push(this.address.projects[i]);
			}

			let removedProjects = [];
			for (let i = 0; i < this.address.projects.length; i++) {
				if (!this.chosenProjectsIds.includes(this.address.projects[i]) ) {
					removedProjects.push(this.address.projects[i]);
				}
			}

			let addedProjects = [];
			for (let i = 0; i < this.chosenProjectsIds.length; i++) {
				if ( !this.address.projects.includes(this.chosenProjectsIds[i]) ) {
					addedProjects.push(this.chosenProjectsIds[i]);
				}
			}

			if (removedProjects.length > 0 || addedProjects.length > 0) {
				updateRequestData['projects'] = [];
				if (removedProjects.length > 0) {
					for (let i = 0; i < removedProjects.length; i++) {
						updateRequestData.projects.push({
							id: removedProjects[i],
							operation: 0
						});
					}
				} 

				if (addedProjects.length > 0) {
					for (let i = 0; i < addedProjects.length; i++) {
						updateRequestData.projects.push({
							id: addedProjects[i],
							operation: 1
						});
					}
				}
			}

			let response = null;
			this.$emit("show-overlayer", this.lviews.updatingAddress);
			try {
				response = await axios({
					url: fobConfig.api_url + "/api/v2/admin/postal-address/" + this.address.id,
					method: "PATCH",
					headers: {
						'Authorization': "Bearer " + localStorage.getItem("jwt"),
						'Content-Type': "application/json",
					},
					data: updateRequestData
				})
			} catch (error) {
				this.$emit("hide-overlayer");
				response = null;

				switch(error.response.status) {
					case 400:
						this.$emit("emit-error", this.lviews.badRequest, this.lviews.badRequestMessage, error.toString());
						break;
					case 401:
						this.$router.push("/login?next=/addresses");
						break;
					case 403:
						this.$emit("emit-error", this.lviews.forbiddenOperation, this.lviews.youDoNotHavePrivilegesToDoThatOperation, error.toString());
						break;
					case 404:
						this.$emit("emit-error", this.lviews.notFound, this.lviews.notFoundErrorMessage, error.toString());
						break;
					case 429:
						this.$emit("emit-error", this.lviews.tooManyRequests, this.lviews.tooManyRequestsMessage, error.toString());
						break;
					default:
						this.$emit("emit-error", this.lviews.errorOccurred, this.lviews.errorOccurredMessage, error.toString());
						break;
				}
			}

			if (response != null) {
				this.$emit("hide-overlayer");
				this.$emit("emit-update");
				this.closeDialog();
			}
		},
	},

	mounted: function() {
		this.countriesSelectItems = this.lselectors.countries;
	},

	props: {
		address: {
			type: Object,
			required: false,
			default: null
		},

		projects: {
			type: Array,
			required: true,
		},
	},

	computed: {
		lviews: {
			get: function() {
				return this.$t("views.addressDialog");
			}
		},
		lselectors: {
			get: function() {
				return this.$t("commons.selectors");
			}
		},
	}
}
</script>

<style scoped>
.address-dialog-container {
	padding: 0;
}
.address-dialog-card-text {
	padding: 0 12px 20px !important;
}
.address-dialog-row-wrapper {
	display: grid;
	width: 100%;
	grid-gap: 10px;
	grid-template-columns: 1fr 1fr;
}

@media only screen and (max-width: 450px){
	.address-dialog-row-wrapper {
		grid-template-columns: none;
	}
}

</style>