<!--
SharedMasters 2020 - Fast OnBoarding Mobile

__copyright__ = "Copyright (C) 2020, Shared Masters sp. z o.o."
__license__ = "Fast OnBoarding can not be copied and/or distributed without the express permission of Shared Masters sp. z o.o."
__version__ = "1.2"
__author__ = "Jan Przychodniak, Adrian Bączek, Tomasz Drzewiecki"
__maintainer__ = "Shared Masters sp. z o.o."
__email__ = "it@sharedmasters.com"
__status__ = "PROD"

-->
<!--
	TODO: Show proper message if the list is empty
-->

<template>
	<div class="OnBoardingPM" :mode="mode">

		<!-- Edit toolbar -->
		<v-toolbar v-model="editToolbar"
				   fixed
				   text
				   dark
				   class="boarding-edit-toolbar left-offset100"
				   transition="slide-y-transition"
				   v-if="isMarkedRecordOnDisplayedList">
			<v-app-bar-nav-icon>
				<v-btn icon @click="finishEditing()">
					<v-icon>arrow_back</v-icon>
				</v-btn>
			</v-app-bar-nav-icon>

			<v-toolbar-title>
				{{ editMode.selected.length }}
			</v-toolbar-title>

			<v-spacer></v-spacer>

			<v-btn icon @click="openModifyBoardingDialog()"
				:disabled="!onlineStatus || !editMode.validStatus"
			>
				<v-icon>mdi-file-replace-outline</v-icon>
			</v-btn>

			<v-btn icon @click="openConfirmBoardingDialog()"
				:disabled="!onlineStatus || !editMode.validStatus"
			>
				<v-icon>thumb_up_alt</v-icon>
			</v-btn>

			<v-btn :disabled="!onlineStatus" v-if="editMode.selectedStatus == 1 && editMode.validStatus" icon @click="openRemoveBoardingDialog()">
				<v-icon>thumb_down_alt</v-icon>
			</v-btn>

			<v-btn :disabled="!onlineStatus" v-else-if="editMode.selectedStatus == 7 && editMode.validStatus" icon @click="openResignDialog()">
				<v-icon>thumb_down_alt</v-icon>
			</v-btn>

			<v-btn v-else disabled icon>
				<v-icon>thumb_down_alt</v-icon>
			</v-btn>

			<v-menu bottom left>
				<template v-slot:activator="{ on }">
					<!--
						<v-btn
							dark
							icon
							v-on="on"
							style="margin-left:-2px"
						>
							<v-icon>more_vert</v-icon>
						</v-btn>
					-->
				</template>

				<!-- Currently no additional options like Edit are required, so commenting this out
					<v-list>
					<v-list-tile
						v-for="(item, i) in editMode.editToolbarMoreOptions"
						:key="i"
					>
						<v-list-tile-title>{{ item.title }}</v-list-tile-title>
					</v-list-tile>
					</v-list>
				-->
			</v-menu>
		</v-toolbar>

		<confirm-boarding-dialog ref="confirmBoardingDialog"
			:workers="editMode.dialogs.confirmBoardingDialog.workers"
			:assignedProjects="editMode.dialogs.confirmBoardingDialog.assignedProjects"
			:markedProjectsIds="editMode.dialogs.confirmBoardingDialog.markedProjects"
			@request-sending-start="onRequestStart"
			@request-sending-success="onConfirmBoardingSuccess"
			@request-sending-end="onRequestEnd"
			@progress-request-sending-start="(message) => $emit('set-state', 'progress_overlayer', message)"
			@progress-request-sending-progress="(progress) => $emit('set-progress', progress)"
			@progress-request-uploaded="() => $emit('set-state', 'loading_overlayer', lmessages.processing)"
			@progress-request-sending-end="() => this.$emit('set-state', 'default')"
			@progress-request-sending-success="() => {}"
			@error="onError"
		></confirm-boarding-dialog>


		<resign-dialog ref="resignDialog"
			v-bind:workers="editMode.dialogs.resignDialog.workers"
			v-bind:assignedProjects="editMode.dialogs.resignDialog.assignedProjects"
			v-bind:markedProjectsIds="editMode.dialogs.resignDialog.markedProjects"
			@request-sending-start="onRequestStart"
			@request-sending-success="onResignSuccess"
			@request-sending-end="onRequestEnd"
			@error="onError"
		></resign-dialog>


		<change-boarding-plans-dialog ref="changeBoardingDialog"
			v-bind:workers="editMode.dialogs.modifyBoardingDialog.workers"
			v-bind:date="editMode.dialogs.modifyBoardingDialog.date"
			v-bind:managersDictionary="managersData"
			:defaultManagerId="editMode.dialogs.modifyBoardingDialog.defaultManagerId"
			:assignedProjects="editMode.dialogs.modifyBoardingDialog.assignedProjects"
			:markedProjectsIds="editMode.dialogs.modifyBoardingDialog.markedProjects"
			@request-sending-start="onRequestStart"
			@request-sending-success="onModifyBoardingSuccess"
			@request-sending-end="onRequestEnd"
			@error="onError"
		></change-boarding-plans-dialog>

		<remove-boarding-dialog
			ref="removeBoardingDialog"

			:workers="editMode.dialogs.removeBoardingDialog.workers"
			:assignedProjects="editMode.dialogs.removeBoardingDialog.assignedProjects"
			:markedProjectsIds="editMode.dialogs.removeBoardingDialog.markedProjects"

			@request-sending-start="onRequestStart"
			@request-sending-success="onRemoveBoardingSuccess"
			@request-sending-end="onRequestEnd"
			@error="onError"
		></remove-boarding-dialog>
		
		<v-dialog
			persistent
			v-model="defaultMode.addToExistingWorkerDialog.visible"
			max-width="290"
		>
		<v-card>
			<v-card-title class="headline">{{ defaultMode.addToExistingWorkerDialog.title }}</v-card-title>

			<v-card-text>
			{{ defaultMode.addToExistingWorkerDialog.error }}: <br />
			<b>{{ defaultMode.addToExistingWorkerDialog.existingWorker }}</b>
			<br /><br />
			{{ lview.wantToAddBoardingForExistingWorker }}
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>

				<v-btn
					color="rgba(4, 202, 90, 1)"
					text="text"
					@click="defaultMode.addToExistingWorkerDialog.visible = false"
				>
					{{ lmessages.no }}
				</v-btn>

				<v-btn
					color="rgba(4, 202, 90, 1)"
					text="text"
					@click="confirmAddBoardingToExistingDialog()"
				>
					{{ lmessages.yes }}
				</v-btn>
			</v-card-actions>
		</v-card>
		</v-dialog>
	
		<!-- TOP SORT BY COMPONENT -->
		<div class="boarding-sort-by">
			<v-container fluid grid-list-xl>
				<v-layout wrap align-center justify-center>
					<v-flex xs7 d-flex style="padding:0; padding-left:12px; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; text-rendering: geometricPrecision;">
						<v-select
							v-model="filtersSelected"
							:items="filtersSelect"
							item-value="id"
							item-text="name"
							item-color="green accent-4"
							color="rgba(4, 202, 90, 1)"
							style="-webkit-font-smoothing: antialiased; text-rendering: geometricPrecision;"
							hide-details
							:label="lview.filter"
							@change="refreshBoardingList()"
						></v-select>
					</v-flex>

					<!-- Sub Categories -->
					<!--<v-flex xs4 d-flex style="padding:0">
						<v-select
						:items="dateFilters"
						label="Filtruj"
						item-text="lang_pl"
						item-value="filterId"
						v-model="defaultDateFilter"
						hide-details
						class="boarding-sort-by__select"
						style="margin-right:10px;"
						v-on:change="updateDateFilter"
						></v-select>
					</v-flex>-->
					<!-- Main categories -->
					<v-flex xs5 d-flex style="padding-left:12px; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; text-rendering: geometricPrecision;">
						<v-select :items="sortItems"
								  :label="lview.sortBy"
								  item-text="sorterName"
								  item-value="sorterId"
								  item-color="green accent-4"
								  color="rgba(4, 202, 90, 1)"
								  v-model="defaultSelected"
								  hide-details
								  class="boarding-sort-by__select"
								  style="-webkit-font-smoothing: antialiased; text-rendering: geometricPrecision;"
								  v-on:change="updateGrouper"></v-select>
					</v-flex>
				</v-layout>
			</v-container>
		</div>

		<!-- LISTING -->
		<v-layout row style="margin-left:0; width:100%" v-if="onboardingListItems.length > 0">
			<v-flex xs12>
				<v-list class="boarding-list" expand style="padding:0; padding-bottom:10px">
					<v-list-group class="boarding-list__group"
								  v-for="item in onboardingListItems"
								  :key="item.title"
								  v-model="item.active"
								  no-action
								  color="rgba(4, 202, 90, 1)"
								  style="overflow-x:hidden">
						<template v-slot:activator>
							<v-list-item class="boarding-list__item list-group-item" :style="item.active ? 'border-bottom-color: rgba(4, 202, 90, 1);':'border-bottom-color: grey;'">
								<v-list-item-content>
									<v-list-item-title 
										class="boarding-list__main-title"
										:style="item.active? 'color: rgba(4, 202, 90, 1)':''"
										style="-webkit-font-smoothing: antialiased; text-rendering: geometricPrecision;"
									>
										{{ item.title }}
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</template>

						<v-list-item-group
							v-for="subItem in item.items"
							:key="subItem.recordId"
							class="boarding-list-group-sub-item"
						>
							<v-flex xs12 style="display: flex;">
								<v-layout class="boarding-list__inner-item__left-action"
									  justify-center align-center>
									<v-btn icon color="rgba(4, 202, 90, 1)" text @click="openConfirmBoardingDialog([subItem.id]); subItem.state = 'DEFAULT'">
										<v-icon>thumb_up_alt</v-icon>
									</v-btn>
								</v-layout>

								<v-layout class="boarding-list__inner-item__right-action"
										justify-center align-center>
									<v-btn icon color="rgba(4, 202, 90, 1)" text @click="openResignDialog([subItem.id]); subItem.state = 'DEFAULT'">
										<v-icon>thumb_down_alt</v-icon>
									</v-btn>
								</v-layout>

								<!--
									User initials, or tick icon if accepted
								-->
								<v-list-item-avatar>
									<div class="boarding-list__item-avatar" @click="onAvatarClick(subItem)">
										<!-- If user is accepted, we show the tick icon -->
										<div v-if="mode == 'edit' && editMode.selected.indexOf(subItem.recordId) != -1" class="boarding-list__item-avatar-check">
											<v-icon>check</v-icon>
										</div>
										<!-- Otherwise, show their initials -->
										<div v-else class="boarding-list__item-avatar-initials">
											{{ subItem.initials }}
										</div>
									</div>
								</v-list-item-avatar>


								<!-- Full name and location -->
								<v-layout
									@click="onSubItemClick(subItem.recordId)"
									@mousedown="onSubItemHoldStart(subItem)"
									@mouseup="onSubItemHoldStop(subItem.recordId)"
									@mouseleave="onSubItemHoldStop(subItem.recordId)"
									@touchstart="onSubItemHoldStart(subItem)"
									@touchend="onSubItemHoldStop(subItem.recordId)"
									@touchcancel="onSubItemHoldStop(subItem.recordId)"
									@touchmove="onSubItemHoldStop(subItem.recordId)"
								>
									
									<v-list-item-content style="padding-left: 10px;">
										<v-list-item-title>{{ subItem.fullName }}</v-list-item-title>
										<v-list-item-subtitle>{{ subItem.project == null ? lview.projectUnassigned : subItem.project }}</v-list-item-subtitle>
									</v-list-item-content>
									
									<!-- Thumb down icon -->
									<v-list-item-action style="margin-right: 15px" v-if="subItem.resigning">
										<v-icon>mdi-thumb-down</v-icon>
									</v-list-item-action>

									<v-list-item-action>
										<!-- Marks date as:
											* red if outdated
											* green if accepted
											* dark gray if not outdated, nor accepter
										-->
										<span v-if="subItem.accepted" style="color: #66FF66">
											{{ subItem.untilText }}
										</span>

										<span v-else-if="subItem.outdated" style="color:#FF3333">
											{{ subItem.untilText }}
										</span>

										<span v-else>
											{{ subItem.untilText }}
										</span>

									</v-list-item-action>
								</v-layout>

							</v-flex>
						</v-list-item-group>
					</v-list-group>
				</v-list>
			</v-flex>
		</v-layout>

		<v-layout justify-center align-center v-else style="min-height: 80px;">
			<v-flex>
                <h2 class="title" style="text-align: center">{{ lview.nothingFound }}</h2>
			</v-flex>
		</v-layout>


		<v-snackbar
          v-model="offlineNotifier"
		  fixed
		  class="snackbar-offline-notifier"
      	>
			<v-container class="align-self-center" style="height: 30px; width: 100%; padding-top: 0px; padding-bottom: 0px;">
				<v-flex xs12 class="align-self-center" style="display: flex;">
					<v-flex xs6 class="align-self-center" style="-webkit-font-smoothing: antialiased; text-rendering: optimiseSpeed;">{{ lmessages.networkError }}</v-flex>
					<v-flex xs6 class="align-self-center">
						<v-btn
							text
							@click="retryConnect()"
							class="align-self-center"
							style="color: orange; font-size: 12px; -webkit-font-smoothing: antialiased; text-rendering: optimiseSpeed;"
						>
							{{ lbuttons.retry }}
						</v-btn>
					</v-flex>
				</v-flex>
			</v-container>
      	</v-snackbar>

		<!-- Dialog: new Boarding -->

		<v-dialog persistent v-model="defaultMode.newBoardingData.visible"
				  max-width="290">
			<v-card dense>
				<v-card-title style="text-align:center; padding-bottom:0">
					<span class="headline">{{ lview.newBoarding.title }}</span>
				</v-card-title>

				<v-card-text>
					<v-layout style="height:50px;">
						<div v-if="!defaultMode.newBoardingData.noName" style="width:45%; padding-top: 22px; font-family: Verdana;">
							{{ lview.newBoarding.firstName }}:
						</div>
						<div v-if="defaultMode.newBoardingData.noName" style="width:45%; padding-top: 22px; font-family: Verdana; color: red">
							{{ lview.newBoarding.firstName }}:
						</div>
						<v-spacer style="width:10%;"></v-spacer>
						<v-flex xs12 sm6 md4 style="width:45%;">
							<v-text-field :error="defaultMode.newBoardingData.noName" required v-model="defaultMode.newBoardingData.newWorkerData.firstName"></v-text-field>
						</v-flex>
					</v-layout>

					<v-layout style="height:50px;">
						<div v-if="!defaultMode.newBoardingData.noSurname" style="width:45%; padding-top: 22px; font-family: Verdana;">
							{{ lview.newBoarding.lastName }}:
						</div>
						<div v-if="defaultMode.newBoardingData.noSurname" style="width:45%; padding-top: 22px; font-family: Verdana; color: red">
							{{ lview.newBoarding.lastName }}:
						</div>
						<v-spacer style="width:10%;"></v-spacer>
						<v-flex xs12 sm6 md4 style="width:45%;">
							<v-text-field :error="defaultMode.newBoardingData.noSurname" required v-model="defaultMode.newBoardingData.newWorkerData.surname"></v-text-field>
						</v-flex>
					</v-layout>

					<v-layout style="height:50px;">
						<div v-if="!defaultMode.newBoardingData.noEmail" style="width:45%; padding-top: 22px; font-family: Verdana;">
							{{ lview.newBoarding.email }}:
						</div>
						<div v-if="defaultMode.newBoardingData.noEmail" style="width:45%; padding-top: 22px; font-family: Verdana; color: red">
							{{ lview.newBoarding.email }}:
						</div>
						<v-spacer style="width:10%;"></v-spacer>
						<v-flex xs12 sm6 md4 style="width:45%;">
							<v-text-field :error="defaultMode.newBoardingData.noEmail" required v-model="defaultMode.newBoardingData.newWorkerData.email"></v-text-field>
						</v-flex>
					</v-layout>

					<v-layout style="height:50px;">
						<div v-if="!defaultMode.newBoardingData.wrongPeselNumber" style="width:45%; padding-top: 22px; font-family: Verdana;">
							{{ lview.newBoarding.pesel }}:
						</div>
						<div v-if="defaultMode.newBoardingData.wrongPeselNumber" style="width:45%; padding-top: 22px; font-family: Verdana; color: red">
							{{ lview.newBoarding.pesel }}:
						</div>
						<v-spacer style="width:10%;"></v-spacer>
						<v-flex xs12 sm6 md4 style="width:45%;">
							<v-text-field :error="defaultMode.newBoardingData.wrongPeselNumber" required v-model="defaultMode.newBoardingData.newWorkerData.peselNumber"></v-text-field>
						</v-flex>
					</v-layout>

					<v-layout>
						<v-dialog ref="newBoardingPickDateDialog"
								  v-model="defaultMode.newBoardingData.modal"
								  :return-value.sync="defaultMode.newBoardingData.date"
								  persistent
								  width="290px">

							<template v-slot:activator="{ on }">
								<v-text-field hide-details
											  v-model="defaultMode.newBoardingData.date"
											  :label="lview.newBoarding.boardingDate"
											  readonly
											  append-outer-icon="event"
											  :close-on-content-click="false"
											  v-on="on"
											  @click:append-outer="defaultMode.newBoardingData.modal = true"></v-text-field>
							</template>

							<v-date-picker ref="newBoardingDatePicker"
										   v-model="defaultMode.newBoardingData.date"
										   scrollable>
								<v-spacer></v-spacer>
								<v-btn text color="rgba(4, 202, 90, 1)" @click="defaultMode.newBoardingData.modal = false">{{ lbuttons.cancel }}</v-btn>
								<v-btn text color="rgba(4, 202, 90, 1)" @click="$refs.newBoardingPickDateDialog.save(defaultMode.newBoardingData.date);editMode.dialogs.resignDialog.visible = false;">{{ lbuttons.confirm }}</v-btn>
							</v-date-picker>

						</v-dialog>
					</v-layout>

					<v-layout>
						<v-select :items="managerProjectsDictionary"
								  item-text="name"
								  item-value="id"
								  v-model="defaultMode.newBoardingData.newWorkerData.projects"
								  :label="lview.newBoarding.project"
								  style="margin-top: 10px;"
								  chips
								  multiple
								  :error="defaultMode.newBoardingData.noProjects"></v-select>
					</v-layout>

				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="rgba(4, 202, 90, 1)" text @click="closeNewBoardingDialog()">{{ lbuttons.cancel }}</v-btn>
					<v-btn color="rgba(4, 202, 90, 1)" text @click="addWorker()">{{ lbuttons.confirm }}</v-btn>
				</v-card-actions>

			</v-card>
		</v-dialog>

		<div class="boarding-add-new" v-if="userHasAddBoardingPermission">
			<v-btn class="mx-2"
				   fab
				   color="primary"
				   @click="openNewBoardingDialog()">
				<v-icon style="height:50%;">add</v-icon>
			</v-btn>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	import store from '../store.js'

	export default {
		name: 'onBoardingDev',
		props: {
            version: {
                type: String,
                required: true,
            },
            appConfig: {
                type: Object,
                required: true
            }
        },
		mounted: function () {
			/*if(this.appConfig.applicationMode != "MANAGER"){
                this.$store.dispatch("logout").then(() => {
					this.$router.push("/login");
				});
            }*/

			this.$emit('set-title', this.lview.pageTitle);
			let userPermissions = localStorage.getItem("user_permissions");
			if ( !userPermissions.includes("fob.boardings_view") ) {
				this.$emit('set-state', 'ERROR', this.lview.youDoNotHavePriviledgesToThisView);
				return;
			}

			if (userPermissions.includes("fob.boardings_change")) {
				this.canClickAvatar = true;
			}

			var json = JSON.parse(localStorage.getItem('editModeOnBoardingPM'));
			if(json != null){
				this.editMode.validStatus = json.validStatus;
				this.editMode.selected = json.selected;
				if(this.editMode.selected.length > 0){
					this.$store.commit('updateMarkedRecordFlag', {isMarked: true});
					this.mode = "edit";
				} else {
					this.$store.commit('updateMarkedRecordFlag', {isMarked: false});
				}
			} else {
				this.$store.commit('updateMarkedRecordFlag', {isMarked: false});
			}

			var sortCondId = JSON.parse(localStorage.getItem('onBoardingSortCondition'));
			if(sortCondId != null){
				this.defaultSelected = sortCondId;
				this.currentGrouper = sortCondId;
				this.sortConditionId = sortCondId;
			}

			var appSrcData = JSON.parse(localStorage.getItem('appSourceDataBoardingScreen'));
			var appPrjDicData = JSON.parse(localStorage.getItem('appProjectsDictionaryBoardingScreen'));
			var appManPrjDicData = JSON.parse(localStorage.getItem('appManagerProjectDictionaryBoardingScreen'));
			var appManData = JSON.parse(localStorage.getItem('appManagersDataBoardingScreen'));

			if(appSrcData != null && appPrjDicData != null && appManPrjDicData != null && appManData != null){
				this.sourceData = appSrcData;
				this.projectsDictionary = appPrjDicData;
				this.managerProjectsDictionary = appManPrjDicData;
				this.managersData = appManData;
			}

			var workers = JSON.parse(localStorage.getItem("workers"));
			this.loadPageData();
			this.updateBoardingListDisplay();
		},
		data() {
			return {
				pageData: [],

				// Data from which workers in the view are currently loaded
				sourceData: [
					/*
						id: worker.user_profile.id,
						workerId: worker.id,
						managerId: worker.parent,
						firstName: worker.user_profile.first_name,
						lastName: worker.user_profile.last_name,
						middleName: worker.user_profile.middle_name,
						rating: worker.user_profile.rating,
						ratingComment: worker.user_profile.rating_comment,

						boardings: [
							boardingId: {Integer},
							projectId: {Integer},
							status: {Integer},
							onBoardingDate: {Date},
							offBoardingDate: {Date},
							recordId: worker.id+"_"+worker.boardings[i].boarding_status
						]
					*/
				],
				managersData: [
					/*
					managerId: (...) {int},
					firstName: (...) {String},
					middleName: (...) {String},
					lastName: (...) {String},
					fullName: (...) {String}, <optional>
					*/
				],

				listData: [
					/*
					id: (...) {int},
					fullName: (...) {String},
					sortName: (...) {String},
					state: (...) {DEFAULT|SWIPED_LEFT|SWIPED_RIGHT}
					initials: (...) {char[2]},
					project: (...) {String},
					projectId: (...) {Number},
					resigning: (...) {boolean},
					untilText: (...) {String},
					dateDiff: (...) {int} (difference in days),
					outdated: (...) {boolean}
					*/
				],
				editToolbar: false,

				projectsDictionary: [
					/*
					id: {int},
					name: {String}
					*/
				],
				managerProjectsDictionary: [
					/*
					id: {Int},
					name: {String}
					*/
				],

				// - default
				// - edit
				mode: 'default',

				defaultMode: {
					holdItemDownInterval: false,
					holdDownTime: 0,
					heldElementId: null,

					newBoardingData: {
						newWorkerData: {
							firstName: '',
							middleName: '',
							surname: '',
							email: '',
							peselNumber: '',
							projects: ''
						},
						visible: false,
						wrongPeselNumber: false,
						lastLength: 0,
						noName: false,
						noSurname: false,
						noEmail: false,
						noProjects: false,
						date: new Date().toJSON().slice(0, 10).replace('/-/g', '/'), //set default date as today's in format : YY-MM-DD
						modal: false,
						newBoardingProjects: [
							'Klient GROSSBEEREN', 'Inne', 'jeszcze inny'
						]
					},

					addToExistingWorkerDialog: {
						visible: false,
						title: "",
						error: "",
						existingWorker: null,
						workerId: null,
						projectId: null,
						onBoardingDate: null,
						workerResponse: null
					}
				},

				editMode: {
					selected: [],
					selectedStatus: null,
					consistentStatus: true,
					validStatus: true,

					workingOn: null,
					editToolbarMoreOptions: [
						{
							title: ""
						}
					],
					dialogs: {
						confirmBoardingDialog: {
							workers: [],
							assignedProjects: [],
							markedProjects: []
							//date: null,
						},
						resignDialog: {
							workers: [],
							date: null,
							assignedProjects: [],
							markedProjects: []
						},
						modifyBoardingDialog: {
							assignedProjects: [],
							markedProjects: [],
							defaultManagerId: null,

							date: null,
							workers: [],
						},
						removeBoardingDialog: {
							workers: [],
							assignedProjects: [],
							markedProjects: []
						},
					}
				},

				holdItemDownTimeout: false,
				heldElementId: null,

				// Sorting
				sortItems: [
					{
						sorterId: "DAT",
						sorterName: "",
					},
					{
						sorterId: "PRJ",
						sorterName: "",
					},
					{
						sorterId: "MNG",
						sorterName: ""
					}
				],
				defaultSelected: {
					sorterId: "DAT",
					lang_pl: "Dacie"
				},

				// Filters
				filtersSelected: "ALL",
				filtersSelect: [
					{
						id: "ALL",
						name: null
					},
					{
						id: "ONBOARDING",
						name: "On-Boarding"
					},
					{
						id: "OFFBOARDING",
						name: "Off-Boarding"
					}
				],

				sortConditionId: null,

				dateFilters: [
					{
						filterId: "ALL",
						lang_pl: "Wszystko"
					},
					{
						filterId: "TILL_NOW",
						lang_pl: "Do dzisiaj"
					},
					{
						filterId: "THIS_WEEK",
						lang_pl: "Ten tydzień"
					},
					{
						filterId: "LATER",
						lang_pl: "Później"
					}
				],
				defaultDateFilter: {
					filterId: "ALL",
					lang_pl: "Wszystko"
				},
				dateFiltersConditions: {
					"ALL": (item) => true,
					"TILL_NOW": (item) => item.dateDiff <= 0,
					"THIS_WEEK": (item) => item.dateDiff > 0 && item.dateDiff <= 7,
					"LATER": (item) => item.dateDiff > 7
				},

				projectsFilters: [],
				defaultProjectsFilter: null,

				filterCondition: (item) => true, // default filter condition: everything is fine

				onlineStatus: navigator.onLine, // is true when is online, false when offline
				offlineNotifier: false,

				// Groupers
				onboardingListGroupers: {
					"DAT": {
						// Avaible groups for the grouper
						groups: [
							{
								id: "TILL_NOW",
								title: "Do dzisiaj"
							},
							{
								id: "THIS_WEEK",
								title: "Ten tydzień"
							},
							{
								id: "LATER",
								title: "Później"
							}
						],
						// What operation should be performed before grouping source data
						onInit: null,
						// Grouping condition (returns index of group current item should be assigned to or -1 if to none)
						groupingCondition: null
					},
					"PRJ": {
						groups: [], // groups are defined onInit
						onInit: null, // Assigned in "beforeMount"
						groupingCondition: null,
					},
					"MNG": {
						groups: [{ id: "DEFAULT", title: "Nieprzypisane" }],
						onInit: null,
						groupingCondition: null
					}
				},
				onboardingCurrentGrouper: null, // further definition in computed

				// Items list
				onboardingListItems: [
					/*{
						id: "TILL_NOW",
						title: "Do dzisiaj",
						active: true,
						items: [],
					},
					{
						id: "THIS_WEEK",
						title: "Ten tydzień",
						active: false,
						items: [],
					},
					{
						id: "LATER",
						title: "Później",
						active: false,
						items: [],
					}*/
				],
				canClickAvatar: false,
			}
		},
		computed: {
			lview: { get: function () { return this.$t('views.onBoardingPM'); } },
			lbuttons: { get: function () { return this.$t('commons.buttons'); } },
			lmessages: { get: function () { return this.$t('commons.messages'); } },
			lerrors: { get: function() { return this.$t('errors'); } },

			// Further definition for grouper
			currentGrouper: {
				get: function () {
					if (this.onboardingCurrentGrouper == null) return this.onboardingListGroupers["DAT"];
					else return this.onboardingCurrentGrouper;
				},
				set: function (grouper) {
					this.onboardingCurrentGrouper = this.onboardingListGroupers[grouper];
				}
			},

			isMarkedRecordOnDisplayedList: {
				get: function(){
					return this.$store.getters.markedRecord;
				}
			},

			userHasAddBoardingPermission: {
				get: function() {
					let userPermissions = localStorage.getItem("user_permissions");
					return userPermissions.includes("fob.boardings_add");
				}
			}
		},
		beforeMount() {
			// Assigning language
			this.sortItems[0].sorterName = this.lview.sortByDate;
			this.sortItems[1].sorterName = this.lview.sortByProject;
			this.sortItems[2].sorterName = this.lview.sortByManager;

			this.onboardingListGroupers.DAT.groups[0].title = this.lview.tillToday;
			this.onboardingListGroupers.DAT.groups[1].title = this.lview.thisWeek;
			this.onboardingListGroupers.DAT.groups[2].title = this.lview.later;

			this.editMode.editToolbarMoreOptions[0].title = this.lview.editMenu.edit;

			this.filtersSelect[0].name = this.lview.filterAll;

			// Assigning functions to data objects
			this.onboardingListGroupers.PRJ.onInit = this.PRJonInit;
			this.onboardingListGroupers.PRJ.groupingCondition = this.PRJgroupingConditions;

			this.onboardingListGroupers.DAT.onInit = this.empty;
			this.onboardingListGroupers.DAT.groupingCondition = this.DATgroupingConditions;

			this.onboardingListGroupers.MNG.onInit = this.MNGonInit;
			this.onboardingListGroupers.MNG.groupingCondition = this.MNGgroupingConditions;


			var filterCond = JSON.parse(localStorage.getItem('onBoardingFilterCondition'));
			if(filterCond != null){
				this.filtersSelected = filterCond;
			}
			this.filterCondition = this.boardingStatusFilterCondition;
			
			window.addEventListener('scroll', this.onScroll);
			window.addEventListener('online', () => { this.onlineStatus = true; } );
			window.addEventListener('offline', () => { this.onlineStatus = false; } );
		},
		beforeDestroy() {
			window.removeEventListener('scroll', this.onScroll);
			window.removeEventListener('online', () => { this.onlineStatus = true; });
			window.removeEventListener('offline', () => { this.onlineStatus = false; });

			var dataToSerialize = {
				validStatus : this.editMode.validStatus,
				selected : this.editMode.selected,
			};
			
			localStorage.setItem( 'editModeOnBoardingPM', JSON.stringify(dataToSerialize) ); // saving marked records
			localStorage.setItem( 'onBoardingFilterCondition', JSON.stringify(this.filtersSelected) ); // saving filter condition
			localStorage.setItem( 'onBoardingSortCondition', JSON.stringify(this.sortConditionId)); // saving sort condition

			localStorage.setItem( 'appSourceDataBoardingScreen', JSON.stringify(this.sourceData) );
			localStorage.setItem( 'appProjectsDictionaryBoardingScreen', JSON.stringify(this.projectsDictionary));
			localStorage.setItem( 'appManagerProjectDictionaryBoardingScreen', JSON.stringify(this.managerProjectsDictionary));
			localStorage.setItem( 'appManagersDataBoardingScreen', JSON.stringify(this.managersData));
		},
		methods: {
			// ---------
			// EDIT MODE
			// ---------
			finishEditing() {
				this.$store.commit('updateMarkedRecordFlag', {isMarked: false}); // updating flag that record is marked => v-toolbar in vuetify 2.3.7 behaves really strange so it's necessary co have sync flag to render toolbar
				this.editMode.selectedStatus = null;
				this.editMode.selected.splice(0, this.editMode.selected.length);
				this.mode = 'default';
				this.refreshBoardingList();
			},

			/**
			 * Verfies selection consistency in terms of boarding status
			 */
			verifySelectionConsistency(){
				if(this.editMode.selected.length > 0){
					var worker;
					this.editMode.validStatus = true;
					for(var i = 0; i < this.editMode.selected.length; i++){
						worker = this.getSourceWorkerFromRecordId(this.editMode.selected[i]);

						if(!this.workerIsValidForEditing(worker)){
							this.editMode.validStatus = false;
							return;
						}
					}

					var status = this.getSourceRecordFromRecordId(this.editMode.selected[0]).status;
					var consistent = true;

					for(var i = 1; i < this.editMode.selected.length; i++){
						if(status != this.getSourceRecordFromRecordId(this.editMode.selected[i]).status){
							consistent = false;
							break;
						}
					}

					this.editMode.consistentStatus = consistent;
					this.editMode.selectedStatus = (consistent) ? status : null;
				}
			},

			//#region Dialogs
			openConfirmBoardingDialog(selected = null) {
				/*
					var employee;

					if (selected == null) {
						selected = this.editMode.selected;
					}
					this.editMode.workingOn = selected;
					this.editMode.dialogs.confirmBoardingDialog.workers = [];

					for (var i = 0; i < selected.length; i++) {
						employee = this.getSourceWorkerFromRecordId(selected[i]);
						this.editMode.dialogs.confirmBoardingDialog.workers.push({
							id: employee.workerId,
							profileId: employee.id
						});
					}

					this.$refs.confirmBoardingDialog.openDialog();
				*/

				if (this.editMode.selected.length > 0) {
					var employees = this.getSelectedWorkers();
					this.editMode.dialogs.confirmBoardingDialog.workers = employees;

					var employeesProjects = [];
					var markedEmployeesProjects = [];
					var records;
					var index;
					var employee;
					var record;
					var validProjectIds;
					var boarding;
					for (var employeeIndex in employees) {
						validProjectIds = [];
						employee = employees[employeeIndex];

						for(var i = 0; i < employee.boardings.length; i++){
							boarding = employee.boardings[i];
							validProjectIds.push(boarding.projectId);
						}

						employeesProjects.push(validProjectIds);

						markedEmployeesProjects.push([]);
						index = employeesProjects.length - 1;
						records = this.getSelectedRecordsForWorkerId(employee.workerId);

						for (var recordIndex in records) {
							record = records[recordIndex];
							markedEmployeesProjects[index].push(record.projectId);
						}
					}

					var assignedProjects = this.getSharedProjects(employeesProjects);

					// If there are no projects to display in the first place, don't display anything
					if(assignedProjects.length == 0){
						this.$emit("display-error", this.lview.errorNoProjectsToDisplayTitle, this.lview.errorNoProjectsToDisplay);
						return;
					}

					var dialog = this.editMode.dialogs.confirmBoardingDialog;
					dialog.assignedProjects.splice(0, dialog.assignedProjects.length);
					for (var i = 0; i < assignedProjects.length; i++) {
						dialog.assignedProjects.push({
							id: assignedProjects[i],
							name: this.getProjectName(assignedProjects[i])
						});
					}

					var markedProjects = this.getSharedProjects(markedEmployeesProjects);
					dialog.markedProjects.splice(0, dialog.markedProjects.length);
					for (var i = 0; i < markedProjects.length; i++) {
						dialog.markedProjects.push(markedProjects[i]);
					}

					this.$refs.confirmBoardingDialog.openDialog();
				}
			},
			onConfirmBoardingSuccess(modified) {
				//this.removeSourceDataByIds(this.editMode.workingOn);
				var boardingId;
				var record;
				var worker;
				for(var i = 0; i < modified.length; i++){
					boardingId = modified[i].boardingId;
					worker = this.getSourceWorkerFromBoardingId(boardingId);
					record = this.getSourceRecordInSourceWorkerFromBoardingId(worker, boardingId);
					
					worker.boardings.splice(worker.boardings.indexOf(record), 1);

					if(worker.boardings.length == 0){
						this.sourceData.splice(this.sourceData.indexOf(worker), 1);
					}
				}

				this.updateBoardingListDisplay(true);
				this.finishEditing();
			},
			// Off-boarding -> historical
			openResignDialog(selected = null) {
				if (selected == null) {
					selected = this.editMode.selected;
				}
				//this.editMode.workingOn = selected;
				//this.editMode.dialogs.resignDialog.workerIds = this.getSourceWorkerIds(selected);
				//this.editMode.dialogs.resignDialog.assignedProjects = this.getSharedProjects(this.editMode.dialogs.resignDialog.workerIds);
				//this.$refs.resignDialog.openDialog();
				var employees = this.getSelectedWorkers();
				this.editMode.dialogs.resignDialog.workers = employees;

				var employeesProjects = [];
				var markedEmployeesProjects = [];
				var records;
				var index;
				var employee;
				var record;
				var validProjectIds;
				var boarding;
				for (var employeeIndex in employees) {
					validProjectIds = [];
					employee = employees[employeeIndex];

					for(var i = 0; i < employee.boardings.length; i++){
						boarding = employee.boardings[i];
						if(boarding.status == this.editMode.selectedStatus){
							validProjectIds.push(boarding.projectId);
						}
					}

					employeesProjects.push(validProjectIds);

					markedEmployeesProjects.push([]);
					index = employeesProjects.length - 1;
					records = this.getSelectedRecordsForWorkerId(employee.workerId);

					for (var recordIndex in records) {
						record = records[recordIndex];
						markedEmployeesProjects[index].push(record.projectId);
					}
				}

				var dialog = this.editMode.dialogs.resignDialog;
				dialog.assignedProjects.splice(0, dialog.assignedProjects.length);
				var assignedProjects = this.getSharedProjects(employeesProjects);
				for (var i = 0; i < assignedProjects.length; i++) {
					dialog.assignedProjects.push({
						id: assignedProjects[i],
						name: this.getProjectName(assignedProjects[i])
					});
				}

				// If there are no projects to display in the first place, don't display anything
				if(assignedProjects.length == 0){
					this.$emit("display-error", this.lview.errorNoProjectsToDisplayTitle, this.lview.errorNoProjectsToDisplay);
					return;
				}

				var markedProjects = this.getSharedProjects(markedEmployeesProjects);
				dialog.markedProjects.splice(0, dialog.markedProjects.length);
				for (var i = 0; i < markedProjects.length; i++) {
					dialog.markedProjects.push(markedProjects[i]);
				}
				this.$refs.resignDialog.openDialog();
			},
			// Off-boarding -> historical
			onResignSuccess(modified, modifiedUserProfile) {
				var boarding;
				var worker;

				if(modifiedUserProfile != null){
					modifiedUserProfile.worker.rating = modifiedUserProfile.rating;
					modifiedUserProfile.worker.ratingComment = modifiedUserProfile.ratingComment;
				}
				for(var i = 0; i < modified.length; i++){
					worker = this.getSourceWorkerFromWorkerId(modified[i].worker.workerId);
					for(var j = 0; j < modified[i].worker.boardings.length; j++){
						boarding = worker.boardings[j];
						if(boarding.boardingId == modified[i].boardingId){
							worker.boardings.splice(j, 1);
							break;
						}
					}
					if(worker.boardings.length == 0){
						var indicesToRemove = [];
						for(var k = 0; k < this.sourceData.length; k++) {
							if (this.sourceData[k].workerId == worker.workerId) {
								indicesToRemove.push(k);
							}
						}

						while(indicesToRemove.length > 0) {
							this.sourceData.splice(indicesToRemove[indicesToRemove.length - 1], 1);
							indicesToRemove.pop();
						}
						//this.sourceData.splice(this.sourceData.indexOf(worker));
					}
				}

				this.updateBoardingListDisplay(true);
				this.finishEditing();
			},

			openModifyBoardingDialog() {
				if (this.editMode.selected.length > 0) {
					var employees = this.getSelectedWorkers();
					this.editMode.dialogs.modifyBoardingDialog.workers = employees;

					var employeesProjects = [];
					var markedEmployeesProjects = [];
					var records;
					var index;
					var employee;
					var record;
					var validProjectIds;
					var boarding;
					var manager = -1;

					var consistentDate = true;
					var consistentManager = true;
					var date = null;
					var tmpDate;
					for (var employeeIndex in employees) {
						validProjectIds = [];
						employee = employees[employeeIndex];

						if(manager == -1)
							manager = employee.managerId;

						else if(manager != employee.managerId)
							consistentManager = false;

						for(var i = 0; i < employee.boardings.length; i++){
							boarding = employee.boardings[i];

							validProjectIds.push(boarding.projectId);
						}

						employeesProjects.push(validProjectIds);

						markedEmployeesProjects.push([]);
						index = employeesProjects.length - 1;
						records = this.getSelectedRecordsForWorkerId(employee.workerId);

						for (var recordIndex in records) {
							record = records[recordIndex];

							if(date == null){
								date = (record.status == 7) ? record.offBoardingDate : record.onBoardingDate;
							}
							else if(consistentDate){
								tmpDate = (record.status == 7) ? record.offBoardingDate : record.onBoardingDate;

								if(tmpDate == null || date == null || tmpDate.getTime() !== date.getTime()){
									consistentDate = false;
								}
							}

							markedEmployeesProjects[index].push(record.projectId);
						}
					}

					var dialog = this.editMode.dialogs.modifyBoardingDialog;
					dialog.assignedProjects.splice(0, dialog.assignedProjects.length);
					var assignedProjects = this.getSharedProjects(employeesProjects);
					for (var i = 0; i < assignedProjects.length; i++) {
						dialog.assignedProjects.push({
							id: assignedProjects[i],
							name: this.getProjectName(assignedProjects[i])
						});
					}
					
					// If there are no projects assigned, cannot show dialog
					if(assignedProjects.length == 0){
						this.$emit('display-error', this.lview.errorNoProjectsToDisplayTitle, this.lview.errorNoProjectsToDisplay);
						return;
					}

					var markedProjects = this.getSharedProjects(markedEmployeesProjects);
					dialog.markedProjects.splice(0, dialog.markedProjects.length);
					for (var i = 0; i < markedProjects.length; i++) {
						dialog.markedProjects.push(markedProjects[i]);
					}

					if(consistentDate && date != null)
						this.editMode.dialogs.modifyBoardingDialog.date = date.toJSON().slice(0, 10);
					else
						this.editMode.dialogs.modifyBoardingDialog.date = new Date().toJSON().slice(0, 10);

					if(consistentManager){
						this.editMode.dialogs.modifyBoardingDialog.defaultManagerId = manager;
					}
					else{
						this.editMode.dialogs.modifyBoardingDialog.defaultManagerId = null;
					}

					this.$nextTick(() => this.$refs.changeBoardingDialog.openDialog());
				}
			},

			/**
			 * returns project assignment of worker with id
			 * @param worker worker structure to search in
			 * @param id id of project
			 */
			getProjectAssignmentById(worker, id) {
				for (var i = 0; i < worker.project_assignments.length; i++) {
					if (worker.project_assignments[i].id == id) {
						return worker.project_assignments[i];
					}
				}
				return null;
			},

			onModifyBoardingSuccess(workers) {
				this.$nextTick(() => this.updateBoardingListDisplay(true));
				this.finishEditing();
			},
			// Prospect -> removed
			openRemoveBoardingDialog(){
				if (this.editMode.selected.length > 0) {
					var employees = this.getSelectedWorkers();
					this.editMode.dialogs.removeBoardingDialog.workers = employees;

					var employeesProjects = [];
					var markedEmployeesProjects = [];
					var records;
					var index;
					var employee;
					var record;
					var validProjectIds;
					var boarding;
					for (var employeeIndex in employees) {
						validProjectIds = [];
						employee = employees[employeeIndex];

						for(var i = 0; i < employee.boardings.length; i++){
							boarding = employee.boardings[i];
							if(boarding.status == this.editMode.selectedStatus){
								validProjectIds.push(boarding.projectId);
							}
						}

						employeesProjects.push(validProjectIds);

						markedEmployeesProjects.push([]);
						index = employeesProjects.length - 1;
						records = this.getSelectedRecordsForWorkerId(employee.workerId);

						for (var recordIndex in records) {
							record = records[recordIndex];
							markedEmployeesProjects[index].push(record.projectId);
						}
					}

					var dialog = this.editMode.dialogs.removeBoardingDialog;
					dialog.assignedProjects.splice(0, dialog.assignedProjects.length);
					var assignedProjects = this.getSharedProjects(employeesProjects);
					for (var i = 0; i < assignedProjects.length; i++) {
						dialog.assignedProjects.push({
							id: assignedProjects[i],
							name: this.getProjectName(assignedProjects[i])
						});
					}

					var markedProjects = this.getSharedProjects(markedEmployeesProjects);
					dialog.markedProjects.splice(0, dialog.markedProjects.length);
					for (var i = 0; i < markedProjects.length; i++) {
						dialog.markedProjects.push(markedProjects[i]);
					}

					this.$refs.removeBoardingDialog.openDialog();
				}
			},
			// Prospect -> removed
			onRemoveBoardingSuccess(modified){
				var boarding;
				var worker;
				for(var i = 0; i < modified.length; i++){
					worker = this.getSourceWorkerFromWorkerId(modified[i].worker.workerId);
					for(var j = 0; j < worker.boardings.length; j++){
						boarding = worker.boardings[j];
						if(boarding.boardingId == modified[i].boardingId){
							worker.boardings.splice(j, 1);
							break;
						}
					}
					if(worker.boardings.length == 0){
						this.sourceData.splice(this.sourceData.indexOf(worker), 1);
					}
				}

				this.updateBoardingListDisplay(true);
				this.finishEditing();
			},
			//#endregion



			// ------------
			// DEFAULT MODE
			// ------------

			openNewBoardingDialog() {
				if(!this.onlineStatus){
					this.offlineNotifier = true;
					return;
				}

				this.defaultMode.newBoardingData.newWorkerData.firstName = '';
				this.defaultMode.newBoardingData.newWorkerData.middleName = '';
				this.defaultMode.newBoardingData.newWorkerData.surname = '';
				this.defaultMode.newBoardingData.newWorkerData.peselNumber = '';
				this.defaultMode.newBoardingData.newWorkerData.project = '';
				this.defaultMode.newBoardingData.newWorkerData.email = '';
				this.defaultMode.newBoardingData.wrongPeselNumber = false;
				this.defaultMode.newBoardingData.noName = false;
				this.defaultMode.newBoardingData.noSurname = false;
				this.defaultMode.newBoardingData.noEmail = false;
				this.defaultMode.newBoardingData.visible = true;
				this.defaultMode.newBoardingData.newWorkerData.projects = '';
			},
			closeNewBoardingDialog() {
				this.defaultMode.newBoardingData.visible = false;
			},
			addWorker: async function () {
				var data = {
					user_profile: {},
					boarding: {},
					project_assignment: {}
				};

				if (this.defaultMode.newBoardingData.newWorkerData.projects == "") {
					//this.defaultMode.newBoardingData.newWorkerData.project = null;
					this.defaultMode.newBoardingData.noProjects = true;
				}
				else {
					this.defaultMode.newBoardingData.noProjects = false;
				}

				//check that name was entered
				if (this.defaultMode.newBoardingData.newWorkerData.firstName == '') {
					this.defaultMode.newBoardingData.noName = true;
				}
				else {
					this.defaultMode.newBoardingData.noName = false;
				}

				//check that surname was entered
				if (this.defaultMode.newBoardingData.newWorkerData.surname == '') {
					this.defaultMode.newBoardingData.noSurname = true;
				}
				else {
					this.defaultMode.newBoardingData.noSurname = false;
				}

				//check that email was entered
				if (this.defaultMode.newBoardingData.newWorkerData.email == '') {
					this.defaultMode.newBoardingData.noEmail = true;
				}
				else {
					var regex = /.*@.*\..*/g
					var match = this.defaultMode.newBoardingData.newWorkerData.email.match(regex);
					if (match == null) {
						this.defaultMode.newBoardingData.noEmail = true;
					}
					else {
						this.defaultMode.newBoardingData.noEmail = false;
					}
				}

				//check if user entered pesel number or not - if yes -> pesel verification
				if (this.defaultMode.newBoardingData.newWorkerData.peselNumber != "") {
					if(!this.peselValid(this.defaultMode.newBoardingData.newWorkerData.peselNumber)){
						this.defaultMode.newBoardingData.wrongPeselNumber = true;
					}
					else{
						data.user_profile.pesel = this.defaultMode.newBoardingData.newWorkerData.peselNumber;
						this.defaultMode.newBoardingData.wrongPeselNumber = false;
					}
				}
				else {
					this.defaultMode.newBoardingData.wrongPeselNumber = true;
				}

				//return if name, surname or email wasn't entered
				if (	this.defaultMode.newBoardingData.noName 
						|| this.defaultMode.newBoardingData.noSurname 
						|| this.defaultMode.newBoardingData.wrongPeselNumber 
						|| this.defaultMode.newBoardingData.noEmail
						|| this.defaultMode.newBoardingData.noProjects) {
					return;
				}

				this.$emit("set-state", "loading_overlayer", "Dodawanie do boardingu...");

				//check whether that worker exists in database or not -> create or update him

				// Current API first require creation of UserProfile , default instance of Worker is created also automaticaly
				// Additional call is requried to update Worker with onboarding data etc.

				var projects;
				try {
					//var parentWorker = JSON.parse(localStorage.getItem("workers"))[0];
			
					if (this.defaultMode.newBoardingData.newWorkerData.projects == "") {
						projects = [];
						errorMessage = this.lmessages.projectsNotChosen;
						this.$emit("set-state", "default");
						this.$emit('display-error', errorTitle, errorMessage);
						return;
					}
					else{
						projects = this.defaultMode.newBoardingData.newWorkerData.projects;
					}
			
					data.user_profile.first_name = this.defaultMode.newBoardingData.newWorkerData.firstName,
					data.user_profile.last_name = this.defaultMode.newBoardingData.newWorkerData.surname,
					data.user_profile.personal_email = this.defaultMode.newBoardingData.newWorkerData.email,
					data.project_assignment.project_id = projects,
					data.project_assignment.start_date = new Date(this.defaultMode.newBoardingData.date).toJSON().slice(0, 10),
					data.boarding.on_boarding_date = new Date(this.defaultMode.newBoardingData.date).toJSON().slice(0, 10)

					var APIresponse = await axios({
						method: "POST",
						url: localStorage.getItem("current_env") + "/api/v2/boardings/",
						headers: {
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + localStorage.getItem('jwt')
						},

						data: data
					});
					
					var response = APIresponse.data;

					var worker = {
						id: response.user_profile.id,
						rating: null,
						ratingComment: null,
						workerId: response.worker.id,
						managerId: response.worker.parent,
						firstName: response.user_profile.first_name,
						middleName: response.user_profile.middle_name,
						lastName: response.user_profile.last_name,
						boardings: []
					}

					for(var i = 0; i < response.boardings.length; i++){
						var projectAssignment = this.getProjectAssignmentById(response, response.boardings[i].project_assignment);
						var onBoardingDate = response.boardings[i].on_boarding_date;
						onBoardingDate = (onBoardingDate == null) ? null : new Date(onBoardingDate);

						var offBoardingDate = response.boardings[i].off_boarding_date;
						offBoardingDate = (offBoardingDate == null) ? null : new Date(offBoardingDate);

						var recordId;
						if(response.boardings[i].boarding_status == 1)
							recordId = response.worker.id + "_" + response.boardings[i].boarding_status + "_" + (response.boardings[i].on_boarding_date.toString());
						else if(response.boardings[i].boarding_status == 7)
							recordId = response.worker.id + "_" + response.boardings[i].boarding_status + "_" + (response.boardings[i].off_boarding_date.toString());
						else
							recordId = response.worker.id + "_" + response.boardings[i].boarding_status;

						if(projectAssignment.project == null || projectAssignment.project == null){
							worker.boardings.push({
								boardingId: response.boardings[i].id,
								projectId: -1,
								projectAssignmentId: response.boardings[i].project_assignment,
								status: response.boardings[i].boarding_status,
								onBoardingDate: onBoardingDate,
								offBoardingDate: offBoardingDate,
								recordId: recordId
							});
						}
						else{
							worker.boardings.push({
								boardingId: response.boardings[i].id,
								projectId: projectAssignment.project.id,
								projectAssignmentId: response.boardings[i].project_assignment,
								status: response.boardings[i].boarding_status,
								onBoardingDate: onBoardingDate,
								offBoardingDate: offBoardingDate,
								recordId: recordId
							});
						}
					}
					this.sourceData.push(worker);
				} catch (error) {
					var displayError = true;
					var errorTitle = this.lmessages.errorOccuredTitle;
					var errorMessage;
					if (projects == -1) {
						errorMessage = this.lmessages.projectsNotChosen;
						this.$emit("set-state", "default");
						this.$emit('display-error', errorTitle, errorMessage, String(error));
						return;
					}

					if(typeof(error.response) !== 'undefined' && typeof(error.response.data) !== 'undefined'){
						// project list
						if (error.response.data.project_assignment != null && error.response.data.project_assignment != undefined) {
							if (error.response.data.project_assignment.project_id != null && error.response.data.project_assignment.project_id != undefined) {
								this.$emit("set-state", "default");
								this.$emit('display-error', this.lerrors.project_assignment.project_id.header, this.lerrors.project_assignment.project_id.string, String(error));
								return;
							}
						}
						
						// invalid pesel
						if (typeof(error.response.data.user_profile.pesel) !== 'undefined') {
							errorMessage = this.lerrors.user_profile.pesel_unique.string; //error.response.data.user_profile.pesel[0].string
							errorTitle = this.lerrors.user_profile.pesel_unique.header;
						}

						// invalid email
						/*if (typeof(error.response.data.user_profile.personal_email) !== 'undefined') {
							errorMessage = error.response.data.user_profile.personal_email[0].string	
						}*/

						//if (typeof(error.response.data.))

						if (error.response.data.worker != null && error.response.data.worker != undefined) {
							var obj1;
							var obj2;
							var worker = error.response.data.worker;

							obj1 = {
								firstName: this.defaultMode.newBoardingData.newWorkerData.firstName,
								lastName: this.defaultMode.newBoardingData.newWorkerData.surname,
								pesel: (this.defaultMode.newBoardingData.newWorkerData.peselNumber == "")
									? null
									: this.defaultMode.newBoardingData.newWorkerData.peselNumber
							}
							obj2 = {
								firstName: worker.first_name,
								lastName: worker.last_name,
								pesel: worker.pesel
							}

							if(this.compareObjectsAttributes(obj1, obj2, 0.7)){
								displayError = false;

								if (error.response.data.user_profile.pesel != null && error.response.data.user_profile.pesel != undefined) {
									this.defaultMode.addToExistingWorkerDialog.error = this.lview.errorPeselAlreadyExists;
									this.defaultMode.addToExistingWorkerDialog.title = this.lview.errorPeselAlreadyExistsTitle;
								}

								if (error.response.data.user_profile.personal_email != null && error.response.data.user_profile.personal_email != undefined) {
									this.defaultMode.addToExistingWorkerDialog.error = this.lview.errorEmailAlreadyExists;
									this.defaultMode.addToExistingWorkerDialog.title = this.lview.errorEmailAlreadyExistsTitle;
								}

								this.defaultMode.addToExistingWorkerDialog.existingWorker = 
									worker.last_name + " "
									+ worker.first_name 
									+ " " 
									+ ((worker.middle_name == null) 
										? "" 
										: (worker.middle_name + " ")
									) 
									+ " (" 
									+ ((worker.pesel == null)
										? this.lview.newBoarding.noPesel
										: worker.pesel
									)
									+ ")";
								this.defaultMode.addToExistingWorkerDialog.workerId = worker.worker_id;
								this.defaultMode.addToExistingWorkerDialog.projectId = this.defaultMode.newBoardingData.newWorkerData.projects;
								this.defaultMode.addToExistingWorkerDialog.onBoardingDate = this.defaultMode.newBoardingData.date;
								this.defaultMode.addToExistingWorkerDialog.workerResponse = worker;

								this.defaultMode.addToExistingWorkerDialog.visible = true;
							}
						}

						/*errorMessage = error.response.data.error; // default error message 
						if (error.response.data.error == "EMAIL_ALREADY_EXISTS_IN_DATABASE" || error.response.data.error == "PESEL_ALREADY_EXISTS_IN_DATABASE") {
							if(error.response.data.workers.length > 0){
								var obj1;
								var obj2;
								var worker = error.response.data.workers[0];

								obj1 = {
									firstName: this.defaultMode.newBoardingData.newWorkerData.firstName,
									lastName: this.defaultMode.newBoardingData.newWorkerData.surname,
									pesel: (this.defaultMode.newBoardingData.newWorkerData.peselNumber == "")
										? null
										: this.defaultMode.newBoardingData.newWorkerData.peselNumber
								}
								obj2 = {
									firstName: worker.user_profile.first_name,
									lastName: worker.user_profile.last_name,
									pesel: worker.user_profile.pesel
								}

								if(this.compareObjectsAttributes(obj1, obj2, 0.7)){
									displayError = false;

									switch(error.response.data.error){
										case "EMAIL_ALREADY_EXISTS_IN_DATABASE":
											this.defaultMode.addToExistingWorkerDialog.error = this.lview.errorEmailAlreadyExists;
											this.defaultMode.addToExistingWorkerDialog.title = this.lview.errorEmailAlreadyExistsTitle;
											break;
										case "PESEL_ALREADY_EXISTS_IN_DATABASE":
											this.defaultMode.addToExistingWorkerDialog.error = this.lview.errorPeselAlreadyExists;
											this.defaultMode.addToExistingWorkerDialog.title = this.lview.errorPeselAlreadyExistsTitle;
											break;
									}

									this.defaultMode.addToExistingWorkerDialog.existingWorker = 
										worker.user_profile.last_name 
										+ worker.user_profile.first_name 
										+ " " 
										+ ((worker.user_profile.middle_name == null) 
											? "" 
											: (worker.user_profile.middle_name + " ")
										) 
										+ " (" 
										+ ((worker.user_profile.pesel == null)
											? this.lview.newBoarding.noPesel
											: worker.user_profile.pesel
										)
										+ ")";
									this.defaultMode.addToExistingWorkerDialog.workerId = worker.id;
									this.defaultMode.addToExistingWorkerDialog.projectId = this.defaultMode.newBoardingData.newWorkerData.projects;
									this.defaultMode.addToExistingWorkerDialog.onBoardingDate = this.defaultMode.newBoardingData.date;
									this.defaultMode.addToExistingWorkerDialog.workerResponse = worker;

									this.defaultMode.addToExistingWorkerDialog.visible = true;
								}
							}

							if(error.response.data.workers.length > 0){
								var worker = error.response.data.workers[0];

								switch(error.response.data.error){
									case "EMAIL_ALREADY_EXISTS_IN_DATABASE":
										errorMessage = this.lview.errorEmailAlreadyExists
										break;
									case "PESEL_ALREADY_EXISTS_IN_DATABASE":
										errorMessage = this.lview.errorPeselAlreadyExists;
										break;
								}

								errorMessage += ":<br /><b>"
									+ worker.user_profile.last_name
									+ worker.user_profile.first_name 
									+ " " 
									+ ((worker.user_profile.middle_name == null) 
										? "" 
										: (worker.user_profile.middle_name + " ")
									) 
									+ " (" 
									+ ((worker.user_profile.pesel == null)
										? this.lview.newBoarding.noPesel
										: worker.user_profile.pesel
									)
									+ ")"
									+ "<br />"
								;
							}
							else{
								switch(error.response.data.error){
									case "EMAIL_ALREADY_EXISTS_IN_DATABASE":
										errorMessage = this.lview.errorEmailAlreadyExists
										break;
									case "PESEL_ALREADY_EXISTS_IN_DATABASE":
										errorMessage = this.lview.errorPeselAlreadyExists;
										break;
								}
							}
						}*/
					}
					else if(error.message == "Network Error"){
						this.$emit('display-error', this.lmessages.networkErrorTitle, this.lmessages.networkError, error);
						this.$emit("set-state", "default");
						return;
					}
					else {
						errorMessage = this.lmessages.errorOccured;
					}

					if(displayError){
						this.$emit('display-error', errorTitle, errorMessage);
					}
					this.$emit("set-state", "default");

					return;
				}

				this.updateBoardingListDisplay(true);
				this.closeNewBoardingDialog();
				this.$emit("set-state", "default");
			},

			confirmAddBoardingToExistingDialog: async function(){
				this.defaultMode.addToExistingWorkerDialog.visible = false;
				var data = this.defaultMode.addToExistingWorkerDialog;

				this.$emit('set-state', 'loading_overlayer', this.lmessages.processing);

				if(data.projectId == "") data.projectId = [ -1 ];
				try {
					var addBoardingsToWorkerReponse = await axios({
						method: "POST",
						url: localStorage.getItem("current_env") + "/api/v2/boardings/worker/" + data.workerId,
						headers: {
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + localStorage.getItem('jwt')
						},
						data: {
							boarding: {
								boarding_status: 1,
								on_boarding_date: data.onBoardingDate
							},
							project_assignment: {
								project_id: data.projectId,
								start_date: data.onBoardingDate
							}
						}
					});

					var response = addBoardingsToWorkerReponse.data;

					for(var i = 0; i < response.boardings.length; i++){
						try {
							var newRecord = this.adaptAddToExistingWorkerData(response.worker, response.user_profile, response.boardings[i]);

							var sourceRecord = this.getSourceWorkerFromWorkerId(newRecord.workerId);
							if(sourceRecord == null){
								this.sourceData.push(newRecord);
							}
							else{
								for(var j = 0; j < newRecord.boardings.length; j++){
									sourceRecord.boardings.push(newRecord.boardings[j]);
								}
							}
						} catch (e) {
							console.log(e)
						}
					}

					this.updateBoardingListDisplay(true);

					this.$emit('set-state', 'default');

					this.defaultMode.newBoardingData.visible = false;

				} catch (e) {
					this.$emit('set-state', 'default');
					if (e.isAxiosError) {
						if (e.response.data.boarding.off_boarding_date != null && e.response.data.boarding.off_boarding_date != undefined) {
							this.$emit('display-error', this.lerrors.boarding.off_boarding_date.header, this.lerrors.boarding.off_boarding_date.string, e);
							return;
						}

						if (e.response.data.boarding.other_boarding != null && e.response.data.boarding.other_boarding != undefined) {
							this.$emit('display-error', this.lerrors.boarding.other_boarding.header, this.lerrors.boarding.other_boarding.string, e);
							return;
						}

						if (e.response.data.project_assignment.finish_date != null && e.response.data.project_assignment.finish_date != undefined) {
							this.$emit('display-error', this.lerrors.project_assignment.finish_date.header, this.lerrors.project_assignment.finish_date.string, e);
							return;
						}

						if (e.response.data.project_assignment.contract_end_date != null && e.response.data.project_assignment.contract_end_date != undefined) {
							this.$emit('display-error', this.lerrors.project_assignment.contract_end_date.header, this.lerrors.project_assignment.contract_end_date.string, e);
							return;
						}

						if (e.response.data.project_assignment.project_id != null && e.response.data.project_assignment.project_id != undefined) {
							this.$emit('display-error', this.lerrors.project_assignment.project_id.header, this.lerrors.project_assignment.project_id.string, e);
							return;
						}
					}

					this.$emit('display-error', this.lmessages.errorOccuredTitle, this.lmessages.errorOccured, e);
					return;					
				}
			},

			// ------
			// COMMON
			// ------

			/**
			 * Compares string attributes of two objects with given accuracy. By default compares all attributes obj1 has with obj2. If you wanna narrow down number of attributes to compare, provide "attributes" argument
			 * 
			 * @returns {Boolean} Value determining if objects attributes matched one another with given accuracy
			 */
			compareObjectsAttributes(obj1, obj2, accuracy = 0.8, attributes = "__all__"){
				var attr1;
				var attr2;
				for(var attr in obj1){
					if(typeof obj1[attr] === 'string'){
						if(typeof obj2[attr] === 'undefined'){
							return false;
						}
						if(typeof obj2[attr] !== 'string'){
							return false;
						}

						attr1 = obj1[attr];
						attr2 = obj2[attr];

						if(attr1 != attr2){
							return false;
						}
					}
					else if(typeof obj2[attr] === 'string'){
						return false;
					}

				}

				return true;
			},
			/**
			 * returns complete worker record found thanks to encoded ID (for example 7)
			 * @param recordId internal ID record representation (for example "7_1_2019-08-18")
			 */
			getSourceWorkerFromRecordId(recordId) {
				var workerId = parseInt(recordId.split("_", 1)[0]);

				// search in table with all workers for particular worker data
				for(var i = 0; i < this.sourceData.length; i++){
					if(this.sourceData[i].workerId == workerId){
						return this.sourceData[i];
					}
				}

				return null;
			},
			getSourceWorkerFromWorkerId(workerId) {
				// search in table with all workers for particular worker data
				for(var i = 0; i < this.sourceData.length; i++){
					if(this.sourceData[i].workerId == workerId){
						return this.sourceData[i];
					}
				}

				return null;
			},
			getSourceRecordFromRecordId(recordId){
				var worker = this.getSourceWorkerFromRecordId(recordId);

				var boardings = worker.boardings;
				for(var i = 0; i < boardings.length; i++){
					if(boardings[i].recordId == recordId){
						return boardings[i];
					}
				}
				return null;
			},
			getSourceRecordFromBoardingId(boardingId){
				var worker;
				var boarding;

				for(var i = 0; i < this.sourceData.length; i++){
					worker = this.sourceData[i];
					for(var j = 0; j < worker.boardings.length; j++){
						boarding = worker.boardings[j];
						if(boarding.boardingId == boardingId){
							return boarding;
						}
					}
				}
				return null;
			},
			getSourceWorkerFromBoardingId(boardingId){
				var worker;
				var boarding;

				for(var i = 0; i < this.sourceData.length; i++){
					worker = this.sourceData[i];
					for(var j = 0; j < worker.boardings.length; j++){
						boarding = worker.boardings[j];
						if(boarding.boardingId == boardingId){
							return worker;
						}
					}
				}
			},
			getSourceRecordInSourceWorkerFromBoardingId(sourceWorker, boardingId){
				var boarding;
				for(var i = 0; i < sourceWorker.boardings.length; i++){
					boarding = sourceWorker.boardings[i];
					if(boarding.boardingId == boardingId){
						return boarding;
					}
				}
				return null;
			},

			getSelectedWorkers() {
				var usedIds = [];
				var workers = [];
				var selected;

				for (var i = 0; i < this.editMode.selected.length; i++) {
					selected = this.getSourceWorkerFromRecordId(this.editMode.selected[i]);

					if(usedIds.indexOf(selected.workerId) == -1){
						workers.push(selected);
						usedIds.push(selected.workerId);
					}
				}

				return workers;
			},
			getSelectedRecordsForWorkerId(workerId) {
				var recordIds = [];
				var result = [];
				var wId;
				var selected;
				for (var i = 0; i < this.editMode.selected.length; i++) {
					selected = this.editMode.selected[i];
					wId = selected.split('_', 1)[0];
					if (wId == workerId) {
						recordIds.push(selected);
					}
				}
				var employee = this.getSourceWorkerFromWorkerId(workerId);
				for (var i = 0; i < employee.boardings.length; i++) {
					if (recordIds.indexOf(employee.boardings[i].recordId) != -1) {
						result.push(employee.boardings[i]);
					}
				}

				return result;
			},

			getSharedProjects(employeesProjects) {
				if (employeesProjects.length == 0) return [];

				var joined = [];
				var shortest = employeesProjects[0].length;
				var shortestIndex = 0;

				// Finding the shortest array to make it base one
				for (var i = 1; i < employeesProjects.length; i++) {
					if (shortest > employeesProjects[i].length) {
						shortest = employeesProjects[i].length;
						shortestIndex = i;
					}
				}

				var array = employeesProjects[shortestIndex];
				for (var i = 0; i < array.length; i++)
					joined.push(array[i]);

				// Joining arrays
				var projects;
				for (var i = 0; i < employeesProjects.length; i++) {
					if (i != shortestIndex) {
						projects = employeesProjects[i];

						for (var j = 0; j < joined.length; j++) {
							if (projects.indexOf(joined[j]) == -1) {
								joined.splice(j, 1);
								j--;
							}
						}

						// No reason to look anymore
						if (joined.length == 0) break;
					}
				}

				return joined;
			},

			/**
			 * Finds employee in source data by id
			 *
			 * @param {Integer} employeeId Id to look for
			 * @returns {Object} Employee if found, null otherwise
			 */
			getSourceEmployee(employeeId) {
				for (var i = 0; i < this.sourceData.length; i++) {
					if (this.sourceData[i].id == employeeId) {
						return this.sourceData[i];
					}
				}
				return null;
			},
			getSourceWorkerIds(employeeIds) {
				var result = [];

				for (var i = 0; i < employeeIds.length; i++) {
					result.push(this.getSourceEmployee(employeeIds[i]).workerId);
				}

				return result;
			},
			/**
			 * Finds project name in projects dictionary
			 *
			 * @param {Integer} projectId Id to look for
			 * @returns {String} Project name if foound, null otherwise
			 */
			getProjectName(projectId) {
				if(projectId == -1 || projectId == null){
					return this.lview.projectUnassigned;
				}

				for (var i = 0; i < this.projectsDictionary.length; i++) {
					if (this.projectsDictionary[i].id == projectId) {
						return this.projectsDictionary[i].name;
					}
				}
				return null;
			},

            /** 
             * Validate PESEL against its validity rules (control numbers/checksum)
             */
			peselValid(pesel){
				if (pesel.length != 11) {
					return false;
				}
				else {
					let weight = [9, 7, 3, 1, 9, 7, 3, 1, 9, 7];
					let sum = 0;
					let controlNumber = parseInt(pesel.substring(10, 11),10);
					for (var i = 0; i < weight.length; i++) {
						sum += (parseInt(pesel.substring(i, i + 1), 10) * weight[i]);
					}
					sum = sum % 10;
					if (!(sum === controlNumber)) {
						return false;
					}
				}
				return true;
			},

			/**
			 * Edits parameters of provided objects to edit
			 *
			 * @param {array} objectsToEdit array of objects to edit. Objects require the same structure as objects in sourceData
			 * @param {newParams} object with exclusively the fields the function is supposed to edit, e.x: { firstName: "Newfirstname", untilDate: new Date("2019-07-06") }. Only these fields will be modified
			 */
			editBoardingData(objectsToEdit, newParams) {
				var keys = Object.keys(newParams);
				for (var i = 0; i < objectsToEdit.length; i++) {

					for (var j = 0; j < keys.length; j++) {
						objectsToEdit[i][keys[j]] = newParams[keys[j]];
					}
				}
			},
			/**
			 * Finds objects with provided ids in sourceData
			 *
			 * @param {array} ids Array of ids of objects in source data
			 * @returns {array} Array of objects found in source data
			 */
			getSourceDataFromIds(ids) {
				var arr = [];
				for (var i = 0; i < ids.length; i++) {
					for (var j = 0; j < this.sourceData.length; j++) {
						if (ids[i] == this.sourceData[j].id) {
							arr.push(this.sourceData[j]);
							break;
						}
					}
				}
				return arr;
			},
			/**
			 * Finds objects with provided ids in sourceData and removes them
			 *
			 * @param {array} ids Array of ids of objects in source data
			 */
			removeSourceDataByIds(ids) {
				for (var i = 0; i < ids.length; i++) {
					for (var j = 0; j < this.sourceData.length; j++) {
						if (ids[i] == this.sourceData[j].id) {
							this.sourceData.splice(j, 1);
							break;
						}
					}
				}
			},

			getStyle(state) {
				switch (state) {
					case "SWIPE_LEFT":
						return { marginLeft: '-52px', marginRight: '52px', position: "relative" };
					case "SWIPE_RIGHT":
						return { marginRight: '-52px', marginLeft: '52px', position: "relative" };
					default:
						return { marginLeft: '0px', marginRight: '0px', position: "relative" };
				}
			},
			/*swipeLeft(item) {
				if (item.state == "SWIPE_LEFT") return;
				else if (item.state == "SWIPE_RIGHT") item.state = "DEFAULT";
				else item.state = "SWIPE_LEFT";
			},
			swipeRight(item) {
				if (item.state == "SWIPE_RIGHT") return;
				else if (item.state == "SWIPE_LEFT") item.state = "DEFAULT";
				else item.state = "SWIPE_RIGHT";
			},*/

			// UNUSED
			/**
			 * Automation for worker partial update request. Just provide data you want to send, the rest will be done by this function
			 *
			 * @param {Array} workerIds ids of worker to update
			 * @param {Object} requestData data to send in the request
			 * @throws {Object} error with structure { message: {String}, errors: [{ workerId: {int}, error: {String} }, ... ] }
			 */
			partialWorkerUpdate: async function (workerIds, requestData) {
				var worker;
				var axiosPromises = [];
				var workerIdsCopy = [];
				for (var i = 0; i < workerIds.length; i++) {
					workerIdsCopy.push(workerIds[i]);
					
					axiosPromises.push(
						axios({
							method: "PATCH",
							url: localStorage.getItem("current_env") + "/api/worker/" + workerIds[i],
							headers: {
								'Content-Type': 'application/json',
								'Authorization': 'Bearer ' + localStorage.getItem('jwt')
							},
							data: requestData
						})
					);
				}

				var response;
				var requestError = {
					errors: [],
					message: null
				};
				for (var i = 0; i < axiosPromises.length; i++) {
					try {
						response = await axiosPromises[i];
					} catch (error) {
						requestError.errors.push({
							workerId: workerIdsCopy[i],
							error: error
						});
					}
				}

				if (requestError.errors.length > 0) {
					requestError.message = "Following errors occured: \n";

					for (var i = 0; i < requestError.errors.length; i++) {
						requestError.message += "\nWorker " + requestError.errors[i].workerId + ": " + requestError.errors[i].error;
					}

					throw requestError;
				}
			},
			/**
			 * Updates filter and reloads data
			 *
			 * @param {function(object):boolean} newFilterCondition filter condition to assign as the new filter
			 */
			updateFilter(newFilterCondition) {
				this.filterCondition = newFilterCondition;
				this.refreshBoardingList();
			},
			/**
			 * Groups boarding data for updateBoardingListDisplay function
			 *
			 * @param {array} adaptedData object adapted to a format accepted by this component
			 * @returns {object} object adjusted for updateBoardingListDisplay function
			 */
			groupBoardingData(adaptedData) {
				var groups = [];
				var grouper = this.currentGrouper;

				grouper.onInit();

				for (var i = 0; i < grouper.groups.length; i++) {
					groups.push({
						id: grouper.groups[i].id,
						title: grouper.groups[i].title,
						active: false,
						items: []
					});
				}

				var index = -1;
				for (var i = 0; i < adaptedData.length; i++) {
					index = grouper.groupingCondition(adaptedData[i]);

					if (typeof index == 'object') {
						for (var k = 0; k < index.length; k++) {
							groups[index[k]].items.push(adaptedData[i]);
							groups[index[k]].items[groups[index[k]].items.length - 1].selected = false;
						}
					}
					else if (index != -1) {
						groups[index].items.push(adaptedData[i]);
						groups[index].items[groups[index].items.length - 1].selected = false;
					}
				}

				if (groups.length > 0 && typeof groups[0].sortName !== 'undefined') {
					groups.sort((a, b) => {
						if(a && a.sortName) {
							return (a.sortName).localeCompare(b.sortName); // standard ECMAScript function
						} else {
							return 0; // if 'a.sortName' is empty return equal
						}
					});
				}

				// Remove empty groups
				for (var i = groups.length - 1; i >= 0; i--) {
					if (groups[i].items.length == 0) {
						groups.splice(i, 1);
					}
				}
				// If exactly one group - expand by default
				if (groups.length == 1) {
					groups[0].active = true;
				}
				return groups;
			},
			/**
			 * Filters adapted data using provided filterCondition
			 *
			 * @param {array} adaptedData object adapted to a format accepted by this component
			 * @param {function(object):boolean} filterCondition boolean function, defining whether current item meets filter condition
			 * @returns {array} adaptedData without items that did not meet the filter condition
			 */
			filterBoardingData(adaptedData, filterCondition) {
				var shrinkedArray = [];

				for (var i = 0; i < adaptedData.length; i++) {
					if (filterCondition(adaptedData[i])) {
						shrinkedArray.push(adaptedData[i]);
					}
				}

				return shrinkedArray;
			},

			/**
			 * Internal function to calculate difference between input date & now
			 *
			 * @param {date} inputDate to calculate difference
			 */
			calcDateDiff(inputDate) {
				var todayDate = new Date();
				todayDate.setHours(0, 0, 0, 0);

				// protect in case of (wrong) null/empty values
				if (inputDate) {
					return Math.floor((inputDate.getTime() - todayDate.getTime()) / (1000 * 60 * 60 * 24));
				} else {
					// in case of any errors just return 0
					return null;
				}
			},

			/**
			 * Internal function to convert Date to 'easy-to-read text'
			 *
			 * @param {date} inputDate to convert to text
			 */
			convertDateToLabelText(inputDate, datesDiff) {
				var todayDate = new Date();
				todayDate.setHours(0, 0, 0, 0);
				// in the worst case display 'nodate' message
				var outputText = this.lview.nodate;
				// protect in case of (wrong) null/empty values
				if (inputDate) {
					if (datesDiff == 0) {
						outputText = this.lview.today;
					} else if (Math.abs(datesDiff) == 1) {
						if (datesDiff < 0)
							outputText = this.lview.yesterday;
						else
							outputText = this.lview.tomorrow;
					}
					else if (inputDate.getFullYear() == todayDate.getFullYear()) {
						outputText = inputDate.getDate() + " " + this.monthNumberToText(inputDate.getMonth());
					}
					else {
						outputText = inputDate.getDate() + " " + this.monthNumberToText(inputDate.getMonth()) + " " + inputDate.getFullYear();
					}
				}
				return outputText;
			},

			/**
			 * Converts month number to its text representation
			 *
			 * @param {integer} monthNumber number of month from 0 to 11
			 * @returns {string} text representation of the month
			 */
			monthNumberToText(monthNumber) {
				var months = [
					"sty",
					"lut",
					"mar",
					"kwi",
					"maj",
					"cze",
					"lip",
					"sie",
					"wrz",
					"paź",
					"lis",
					"gru"
				];

				return months[monthNumber];
			},

			updateDateFilter(newValue) {
				this.updateFilter(this.dateFiltersConditions[newValue]);
			},
			updateGrouper(newValue) {
				this.currentGrouper = newValue;
				this.sortConditionId = newValue;
				this.updateBoardingListDisplay();
			},

			workerIsValidForEditing(worker){
				if (worker == null || worker == undefined) 
					return false;
				for(var i = 0; i < worker.boardings.length; i++){
					for(var j = i+1; j < worker.boardings.length; j++){
						if(worker.boardings[i].projectId == worker.boardings[j].projectId){
							return false;
						}
					}
				}
				return true;
			},


			// --------------------
			// LIST DATA OPERATIONS
			// --------------------
			
			/**
			 * Refreshes the boarding list by updating filtering and grouping
			 *
			 * @param {Boolean} keepActiveGroups keeps currently active groups active
			 */
			refreshBoardingList(keepActiveGroups = false) {

				var filteredData = this.filterBoardingData(this.listData, this.filterCondition);
				var groupedData = this.groupBoardingData(filteredData);

				if (keepActiveGroups) {
					for (var i = 0; i < groupedData.length; i++) {
						groupedData[i].active = false;
					}

					for (var i = 0; i < this.onboardingListItems.length; i++) {
						if (this.onboardingListItems[i].active) {
							for (var j = 0; j < groupedData.length; j++) {
								if (groupedData[j].id == this.onboardingListItems[i].id) {
									groupedData[j].active = true;
									break;
								}
							}
						}
					}
				}

				this.onboardingListItems = groupedData;
			},

			/**
			 * Retrieves display text for list of project ids
			 * ex: "Project 1, Project 2, Project3"
			 */
			getProjectsDisplayText(projectIds) {
				var name;
				var text;
				var names = [];
				for (var i = 0; i < projectIds.length; i++) {
					if (projectIds[i] == -1) {
						name = this.lview.projectUnassigned;
					}
					else {
						name = this.getProjectName(projectIds[i]);
						if (name == null) {
							name = this.lview.projectUnassigned;
						}
					}

					names.push({
						name: name,
						sortName: (projectIds[i] == -1) ? "#" : name
					});
				}
				names.sort((a, b) => {
					if(a && a.sortName) {
						return (a.sortName).localeCompare(b.sortName); // standard ECMAScript function
					} else {
						return 0; // if 'a.sortName' is empty return equal
					}
				});
				for (var i = 0; i < names.length; i++) {
					if (i == 0) text = names[i].name;
					else text += (", " + names[i].name);
				}
				return text;
			},
			/**
			 * Converts boarding records from source data into list records by merging boarding records with the same id into one
			 * 
			 * Case: boarding records with the same id but separate projects should be displayed as one record, with the projects listed after comma.
			 */
			getListRecords(sourceRecords) {
				var result = [];
				var recordIds = [];
				var record;
				var index;

				for (var i = 0; i < sourceRecords.length; i++) {
					record = {
						id: sourceRecords[i].recordId,
						offBoardingDate: sourceRecords[i].offBoardingDate,
						onBoardingDate: sourceRecords[i].onBoardingDate,
						projectIds: [sourceRecords[i].projectId],
						status: sourceRecords[i].status
					};
					if ((index = recordIds.indexOf(record.id)) != -1) {
						result[index].projectIds.push(record.projectIds[0]);
					}
					else {
						result.push(record);
						recordIds.push(record.id);
					}
				}

				return result;
			},

			/**
			 * Updates boarding data
			 *
			 * @param {boolean} keepActiveGroups keeps currently active groups active
			 */
			updateBoardingListDisplay(keepActiveGroups = false) {
				this.listData = [];
				var fullName;
				var sortName;
				var untilText;
				var untilDate;
				var dateDiff;
				var person;
				for (var i = 0; i < this.sourceData.length; i++) {
					person = this.sourceData[i];
					// Assigning full name
					if (person.middleName != null)
						fullName = person.lastName + " " + person.firstName + " " + person.middleName;
					else
						fullName = person.lastName + " " + person.firstName;

					// Sorting can be different (was in the past and might be in the future)
					if (person.middleName != null)
						sortName = person.lastName + " " + person.firstName + " " + person.middleName;
					else
						sortName = person.lastName + ' ' + person.firstName;

					// Assigning until date
					
					//if (this.currentGrouper == this.onboardingListGroupers.PRJ) {
						var projects1 = this.getProjectName(person.boardings[0].projectId)+", ";
						var projects2 = "";
						var project_ids1 = [];
						var project_ids2 = [];
						var date_onboard = null;
						var date_offboard = null;

						var records = this.getListRecords(person.boardings);
						var record;
						for(var l = 0; l < records.length; l++){
							record = records[l];
							if(record.status == 7 || record.status == 1){
								if(record.status == 1){ //onboarding
									if(typeof(record.onBoardingDate) != "Date"){
										record.onBoardingDate = new Date(record.onBoardingDate);
									}
									dateDiff = this.calcDateDiff(record.onBoardingDate);
									untilText = this.convertDateToLabelText(record.onBoardingDate, dateDiff);
								}
								else if(record.status == 7){ //offboarding
									if(typeof(record.onBoardingDate) != "Date"){
										record.offBoardingDate = new Date(record.offBoardingDate);
									}
									dateDiff = this.calcDateDiff(record.offBoardingDate);
									untilText = this.convertDateToLabelText(record.offBoardingDate, dateDiff);
								}

								this.listData.push({
									id: person.id,
									recordId: record.id,
									fullName: fullName,
									sortName: sortName,
									managerId: person.managerId,
									state: "DEFAULT",
									initials: person.lastName[0] + person.firstName[0],
									projectId: record.projectIds,
									project: this.getProjectsDisplayText(record.projectIds),
									resigning: record.status == 7,
									boardingStatus: record.status,
									untilText: untilText,
									dateDiff: dateDiff,
									outdated: (dateDiff < 0)
								});
							}
						}

				}

				this.listData.sort((a, b) => {
					if (a.dateDiff - b.dateDiff == 0) {
						if(a && a.sortName) {
							return (a.sortName).localeCompare(b.sortName); // standard ECMAScript function
						} else {
							return 0; // if 'a.sortName' is empty return equal
						}						
					}
					return a.dateDiff - b.dateDiff;
				});
				
				this.refreshBoardingList(keepActiveGroups);
			},

			// -------------------------------
			// REQUEST AND ADAPTING OPERATIONS
			// -------------------------------

			adaptAddToExistingWorkerData(workerResponse, userProfileResponse, boardingResponse){
				var record = {
					id: userProfileResponse.id,
					workerId: workerResponse.id,
					managerId: workerResponse.parent,
					firstName: userProfileResponse.first_name,
					middleName: userProfileResponse.middle_name,
					lastName: userProfileResponse.last_name,
					rating: (userProfileResponse.rating == null) ? null : userProfileResponse.rating / 2,
					ratingComment: userProfileResponse.ratingComment,

					boardings: []
				};

				var boarding = boardingResponse;
				boarding.worker = {};
				boarding.worker.id = workerResponse.id;

				record.boardings.push(
					this.adaptBoardingRecordData(boarding)
				);

				return record;
			},
			adaptPageData(data) {
				/*
					for (var i = 0; i < data.length; i++) {
						this.adaptWorker(data[i], data[i]);

						this.managersData.push({
							managerId: data[i].id,
							firstName: data[i].user_profile.first_name,
							middleName: data[i].user_profile.middle_name,
							lastName: data[i].user_profile.last_name,
							fullName: data[i].user_profile.middle_name == null ? data[i].user_profile.first_name + " " + data[i].user_profile.last_name : data[i].user_profile.first_name + " " + data[i].user_profile.middle_name + " " + data[i].user_profile.last_name
						});

						this.adaptProjects(data[i]);
					}
					this.projectsDictionary.sort((a, b) => {
						if(a && a.name) {
							return (a.name).localeCompare(b.name); // standard ECMAScript function
						} else {
							return 0; // if 'a.name' is empty return equal
						}						
					})
					
					this.updateBoardingListDisplay();
				*/
			},
			/**
			 * checks whether projectsDictionary contains project (Janek: AND verifies if these were already adapted. Actually that's its primary goal)
			 *
			 * @param {object} object that contains project info
			 * @returns {bool} information that project is in projectsDictionary or not
			 */
			projectAdapted(project) {
				if (project == null) return true;

				for (var i = 0; i < this.projectsDictionary.length; i++) {
					if (this.projectsDictionary[i].id == project.id && this.projectsDictionary[i].name == project.name) {
						return true;
					}
				}
				return false;
			},

			adaptProjects(worker) {
				if (worker.project_assignments != null) {
					for (var i = 0; i < worker.project_assignments.length; i++) {
						let assignment = worker.project_assignments[i];
						let project = (assignment.project != null) ? {
							id: assignment.project.id,
							name: assignment.project.name
						} : null;
						if (!this.projectAdapted(project)) {
							this.projectsDictionary.push(project);
						}
					}
				}
				if (worker.workers != null) {
					for (var i = 0; i < worker.workers.length; i++) {
						this.adaptProjects(worker.workers[i]);
					}
				}
			},

			/**
			 * adapts worker
			 */
			adaptWorker(worker, head) {
				//console.log(worker);
				if (worker.boardings != null && worker != head) {

					let w = {
							//for sourceData Format
							id: worker.user_profile.id,
							workerId: worker.id,
							managerId: worker.parent,
							firstName: worker.user_profile.first_name,
							lastName: worker.user_profile.last_name,
							middleName: worker.user_profile.middle_name,

							boardings: [
								/*
									project_id: ,
									status: ,
									onBoardingDate: ,
									offBoardingDate: ,
									recordId: worker.id+"_"+worker.boardings[i].boarding_status
								*/
							]
					}

					for (var i = 0; i < worker.boardings.length; i++) {
						let projectAssignment = this.getProjectAssignmentById(worker, worker.boardings[i].project_assignment);

						var onBoardingDate = worker.boardings[i].on_boarding_date;
						onBoardingDate = (onBoardingDate == null) ? null : new Date(onBoardingDate);

						var offBoardingDate = worker.boardings[i].off_boarding_date;
						offBoardingDate = (offBoardingDate == null) ? null : new Date(offBoardingDate);

						var recordId;
						var date;
						if(worker.boardings[i].boarding_status == 1){
							date = (worker.boardings[i].on_boarding_date == null) ? "NONE" : (worker.boardings[i].on_boarding_date.toString());
							recordId = worker.id + "_" + worker.boardings[i].boarding_status + "_" + date;
						}
						else if(worker.boardings[i].boarding_status == 7){
							date = (worker.boardings[i].off_boarding_date == null) ? "NONE" : (worker.boardings[i].off_boarding_date.toString());
							recordId = worker.id + "_" + worker.boardings[i].boarding_status + "_" + date;
						}
						else
							recordId = worker.id + "_" + worker.boardings[i].boarding_status;
						

						if(projectAssignment == null || projectAssignment.project == null){
							w.boardings.push({
								projectId: -1,
								status: worker.boardings[i].boarding_status,
								onBoardingDate: onBoardingDate,
								offBoardingDate: offBoardingDate,
								recordId: recordId
							});
						}
						else{
							w.boardings.push({
								projectId: projectAssignment.project.id,
								status: worker.boardings[i].boarding_status,
								onBoardingDate: onBoardingDate,
								offBoardingDate: offBoardingDate,
								recordId: recordId
							});
						}
					}
					this.sourceData.push(w);
				}
				if (worker.workers != null) {
					for (var i = 0; i < worker.workers.length; i++) {
						this.adaptWorker(worker.workers[i], head);
					}
				}
			},

			adaptBoardingRecordData(json){
				if(json.archived == true) return null;

				var recordId = json.worker.id + "_" + json.boarding_status;
				if(json.boarding_status == 7)
					recordId += ("_" + json.off_boarding_date);
				else
					recordId += ("_" + json.on_boarding_date);

				var result = {
					boardingId: json.id,
					projectId: 
						(json.project_assignment.project == null)
						? -1
						: json.project_assignment.project.id
					,

					status: json.boarding_status,

					onBoardingDate:
						(json.on_boarding_date == null)
						? null
						: new Date(json.on_boarding_date)
					,
					offBoardingDate:
						(json.off_boarding_date == null)
						? null
						: new Date(json.off_boarding_date)
					,
					projectAssignmentId: json.project_assignment.id,
					recordId: recordId
				}

				return result;
			},
			adaptBoardingData(json){
				var workersDictionary = {};
				var currentBoardingRecord;
				var worker;
				
				// Adapt data
				for(var i = 0; i < json.boardings.length; i++){
					this.saveInLocalStorageInfo(json.boardings[i]);
					currentBoardingRecord = this.adaptBoardingRecordData(json.boardings[i]);
					if(currentBoardingRecord != null){
						if(typeof(workersDictionary[json.boardings[i].worker.id.toString()]) === "undefined"){
							// console.log(worker);
							worker = json.boardings[i].worker;
							workersDictionary[json.boardings[i].worker.id.toString()] = {
								id: worker.user_profile.id,
								rating: (worker.user_profile.rating == null) ? null : worker.user_profile.rating / 2,
								ratingComment: worker.user_profile.rating_comment,
								workerId: worker.id,
								managerId: worker.parent,
								firstName: worker.user_profile.first_name,
								middleName: worker.user_profile.middle_name,
								lastName: worker.user_profile.last_name,

								boardings: []
							};
						}

						workersDictionary[json.boardings[i].worker.id.toString()].boardings.push(currentBoardingRecord);
					}
				}

				// Convert data to array
				var result = [];
				for(var index in workersDictionary){
					result.push(workersDictionary[index]);
				}

				return result;
			},
			adaptManagerProjectsData(json){
				var result = [];
				var project;

                // handle lack of project assignments
                if (json.project_assignments) {
                    for (var i = 0; i < json.project_assignments.length; i++) {
                        project = json.project_assignments[i].project;

                        if (project != null && json.project_assignments[i].assignment_status != 5) {
                            result.push({
                                id: project.id,
                                name: project.name
                            });
                        }
                    }
                }

				return result;
			},
			adaptProjectsDictionaryData(json){
				var result = [];

				for(var i = 0; i < json.projects.length; i++){
					result.push({
						id: json.projects[i].id,
						name: json.projects[i].name
					})
				}

				return result;
			},
			adaptManagersData(json){
				var result = [];
				var worker;
				var fullName;

				for(var i = 0; i < json.workers.length; i++){
					worker = json.workers[i];

					fullName = (worker.user_profile.middle_name == null)
						? worker.user_profile.last_name + " " + worker.user_profile.first_name
						: worker.user_profile.last_name + " " + worker.user_profile.middle_name + " " + worker.user_profile.first_name;
					
					result.push({
						managerId: worker.id,
						firstName: worker.user_profile.first_name,
						middleName: worker.user_profile.middle_name,
						lastName: worker.user_profile.last_name,
						fullName: fullName
					});
				}

				var myWorkers = JSON.parse(localStorage.getItem("workers"));
				worker = myWorkers[0];
				var firstName = localStorage.getItem("user_profile_first_name");
				var middleName = localStorage.getItem("user_profile_middle_name");
				var lastName = localStorage.getItem("user_profile_last_name");
				if(middleName != 'null'){
					fullName = lastName + " " + middleName + " " + firstName;
				}
				else{
					fullName = lastName + " " + firstName;
				}
				result.push({
					managerId: worker.id,
					firstName: firstName,
					middleName: (middleName == 'null') ? null : middleName,
					lastName: lastName,
					fullName: fullName
				});

				result.sort((a, b) => {
					if(a && a.fullName) {
						return (a.fullName).localeCompare(b.fullName); // standard ECMAScript function
					} else {
						return 0; // if 'a.fullName' is empty return equal
					}
				});

				return result;
			},

			saveInLocalStorageInfo(json){
				var id = json.worker.id;
				var project_assignment = json.project_assignment;

				var workerData = {
					firstName : json.worker.user_profile.first_name,
					middleName : json.worker.user_profile.middle_name,
					lastName : json.worker.user_profile.last_name,
					phoneNumber : json.worker.user_profile.personal_mobile_number,
					worker_assignments : []
				};

				var data = JSON.parse(localStorage.getItem(id + "_workerData"));
				if(data != null){
					if(this.findItemInArray(project_assignment, data.worker_assignments) == -1){
						data.worker_assignments.push(project_assignment);
					}
					localStorage.setItem(id + "_workerData", JSON.stringify(data));
				}
				else{
					workerData.worker_assignments.push(project_assignment);
					localStorage.setItem(id + "_workerData", JSON.stringify(workerData));
				}
			},

			/**
			 * @param {Object} item - item for which we want to find index in array
			 * @param {Object} array - array in which we're searching for index of item
			 */
			findItemInArray(item, array){
				for(var i = 0; i < array.length; i++){
					if(array[i].id == item.id){
						return i;
					}
				}
				return -1;
			},

			handleData(data){
				this.sourceData = this.adaptBoardingData(data.boardingData);
				this.projectsDictionary = this.adaptProjectsDictionaryData(data.projectsDictionaryData);

				// As for current data model, we do not consider hierarchy, so projectsDictionary and managerProjectsDictionary are the same
				this.managerProjectsDictionary = this.projectsDictionary; //this.adaptManagerProjectsData(data.managerProjectsData);

				this.managersData = this.adaptManagersData(data.managersData);

				this.updateBoardingListDisplay();
			},

			/*getManagerProjectsDictionaryData: async function(managerId){
				var response = await axios({
					method: "GET",
					url: localStorage.getItem("current_env") + "/api/worker_with_projects/" + managerId,
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + localStorage.getItem('jwt'),
					}
				});

				return response.data;
			},*/

			getManagersData: async function(managerId){
				var response = await axios({
					url: localStorage.getItem("current_env") + "/api/v2/boardings/managers-list/" + managerId,
					method: "GET",
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + localStorage.getItem('jwt'),
					}
				});

				return response.data;
			},

			getProjectsDictionaryData: async function(){
				var response = await axios({
					method: "GET",
					url: localStorage.getItem("current_env") + "/api/v2/boardings/projects",
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + localStorage.getItem('jwt'),
					}
				});

				return response.data;
			},

			getBoardingData: async function(managerId){
				var response = await axios({
					method: "GET",
					url: localStorage.getItem("current_env") + "/api/v2/boardings/worker-projects-list/" + managerId,
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + localStorage.getItem('jwt'),
					},
					params: {
						'boarding_status': '1,7'
					}
				});
				// console.log(response);
				return response.data;
			},

			loadPageData: async function () {
				this.$emit('set-state', 'loading');
				
                // handle initial (or incorrect) case of no workers assigned
                if (localStorage.getItem("workers") != null) {
                    var managerId = JSON.parse(localStorage.getItem("workers"))[0].id;

                    var boardingDataPromise = this.getBoardingData(managerId);
                    var projectsDictionaryDataPromise = this.getProjectsDictionaryData();
					//var managerProjectsDataPromise = null;
					var managersDataPromise = this.getManagersData(managerId);

					var errorOccured = false;

					try{
						var boardingData = await boardingDataPromise;
						var projectsDictionaryData = await projectsDictionaryDataPromise;
						//var managerProjectsData = null;
						var managersData = await managersDataPromise;
					} catch(error){
						if(error.message == "Network Error"){
							this.offlineNotifier = true;
						}
						else{
							this.$emit('display-error', this.lmessages.errorOccuredTitle, this.lmessages.errorOccured, error);
						}
						errorOccured = true;
					}

					if(!errorOccured){
						this.handleData({
							boardingData: boardingData,
							projectsDictionaryData: projectsDictionaryData,
							managerProjectsData: null,
							managersData: managersData
						});
					}
                }

				this.$emit('set-state', 'default');
			},



			// ------
			// EVENTS
			// ------
			
			onSubItemHoldStart(item) {
				//if(this.editMode.selectedStatus == null || this.editMode.selectedStatus == item.boardingStatus){
					if (this.holdItemDownTimeout != false) {
						clearTimeout(this.holdItemDownTimeout);
					}

					this.holdItemDownTimeout = setTimeout(this.subItemHoldTimeout, 650);
					this.heldElementId = item.recordId;
				//}
			},
			subItemHoldTimeout() {
				var index;
				if ((index = this.editMode.selected.indexOf(this.heldElementId)) != -1) {
					this.editMode.selected.splice(index, 1);
					if (this.editMode.selected.length == 0) {
						this.onSubItemHoldStop(this.heldElementId);
						this.finishEditing();
						return;
					}
				}
				else {
					this.editMode.selected.push(this.heldElementId);

					var worker = this.getSourceWorkerFromRecordId(this.heldElementId);

					if(!this.workerIsValidForEditing(worker)){
						this.$emit(
							'display-alert',

							this.lview.alertWorkerNotValidForEditing,
							this.$t('views.onBoardingPM.alertWorkerNotValidForEditingDetails', {
								worker: worker.lastName + " " + worker.firstName
							}),

							undefined,
							"warning"
						);
					}
				}
				this.onSubItemHoldStop(this.heldElementId);
				if (this.mode != 'edit') {
					this.$store.commit('updateMarkedRecordFlag', {isMarked: true});
					this.mode = 'edit';
				}
				this.verifySelectionConsistency();
			},
			onSubItemHoldStop(id) {
				if (this.heldElementId == id && this.holdItemDownTimeout != false) {
					clearTimeout(this.holdItemDownTimeout);
					this.holdItemDownTimeout = false;
					this.heldElementId = null;
				}
			},
			onScroll() {
				this.onSubItemHoldStop(this.defaultMode.heldElementId);
			},
			// On click on sub item of list displayed
            onSubItemClick(itemId) {
                if (this.mode == "edit") {
                    var index;
                    if ((index = this.editMode.selected.indexOf(itemId)) != -1) {
                        this.editMode.selected.splice(index, 1);
                        if (this.editMode.selected.length == 0) {
                            this.finishEditing();
                            return;
                        }
                    }
                }
                else {
                    var employee = this.getSourceWorkerFromRecordId(itemId);
                    this.$router.push('/user-profile/' + employee.workerId);
				}
			},

			// redirecting to profile
			redirectToProfile(itemId){
				var employee = this.getSourceWorkerFromRecordId(itemId);
				this.$router.push('/user-profile/' + employee.workerId);
			},

			// On click on avatar item
			onAvatarClick(item){
				if (!this.canClickAvatar) return;
				
				var index;
				if((index = this.editMode.selected.indexOf(item.recordId)) != -1){
					this.editMode.selected.splice(index, 1);
					this.verifySelectionConsistency();

					if(this.editMode.selected.length == 0){
						this.finishEditing();
					}
				}
				else{
					if(this.editMode.selected.length == 0){
						this.$store.commit('updateMarkedRecordFlag', {isMarked: true});
						this.mode = "edit";
					}
					this.editMode.selected.push(item.recordId);

					var worker = this.getSourceWorkerFromRecordId(item.recordId);

					if(!this.workerIsValidForEditing(worker)){
						this.$emit(
							'display-alert',

							this.lview.alertWorkerNotValidForEditing,
							this.$t('views.onBoardingPM.alertWorkerNotValidForEditingDetails', {
								worker: worker.lastName + " " + worker.firstName
							}),

							undefined,
							"warning"
						);
					}

					this.verifySelectionConsistency();
				}
			},

			onRequestStart() {
				if(this.onlineStatus){
					this.$emit("set-state", "LOADING_OVERLAYER", this.lmessages.savingChanges);
				}
			},
			onRequestError(error) {
				this.$emit("display-error", this.lmessages.errorOccuredTitle, this.errorOccured, error);
			},
			onRequestEnd() {
				this.$emit("set-state", "DEFAULT");
			},
			onError(error) {
				this.$emit(
					"display-error",
					error.title,
					error.message,
					error.errorCodeShort + " " + error.errorCodeLong + (error.details == null ? "" : "<br />" + error.details)
				);
			},


			// --------------------------------
			// Methods assigned before mounting
			// --------------------------------

			boardingStatusFilterCondition(item){
				if(this.filtersSelected == 'ALL') return true;

				var searchKey = (item.boardingStatus == 7 || item.boardingStatus == 6)
					? "OFFBOARDING"
					: "ONBOARDING"
				;

				if(this.filtersSelected == searchKey)
					return true;
				return false;

			},

			PRJonInit() {
				this.onboardingListGroupers.PRJ.groups.splice(0, this.onboardingListGroupers.PRJ.groups.length);

				for (var i = 0; i < this.projectsDictionary.length; i++) {
					this.onboardingListGroupers.PRJ.groups.push({
						id: this.projectsDictionary[i].id,
						title: this.projectsDictionary[i].name,
					});
				}

				this.onboardingListGroupers.PRJ.groups.push({
					id: -1,
					title: this.lview.unassigned
				});
			},
			PRJgroupingConditions(item) {
				var projects = [];
				for (var i = 0; i < this.onboardingListGroupers.PRJ.groups.length; i++) {
					if (item.projectId.includes(this.onboardingListGroupers.PRJ.groups[i].id)) {
						projects.push(i);
						//return i;
					}
				}
				if (projects.length > 0) return projects;
				return -1;
			},

			DATgroupingConditions(item) {
				if (item.dateDiff <= 0) return 0;
				else if (item.dateDiff <= 7) return 1;
				else return 2;
			},

			MNGonInit() {
				this.onboardingListGroupers.MNG.groups = [];

				this.onboardingListGroupers.MNG.groups.push({
					id: null,
					title: this.lview.unassigned,
					sortName: '_',
				});
				this.onboardingListGroupers.MNG.groups.push({
					id: null,
					title: this.lview.myEmployees,
					sortName: '#',
				});
				let myId = JSON.parse(localStorage.getItem('workers'))[0].id;
				for (var i = 0; i < this.managersData.length; i++) {
					var manager = this.managersData[i];

					let managerFullName = "";
					let managerSortName = "";
					if (manager.middleName == null) {
						managerFullName = manager.lastName + " " + manager.firstName;
						managerSortName = manager.lastName + " " + manager.firstName;
					}
					else {
						managerFullName = manager.lastName + " " + manager.firstName + " " + manager.middleName;
						managerSortName = manager.lastName + " " + manager.firstName + " " + manager.middleName;
					}

					if (manager.managerId != myId) {
						this.onboardingListGroupers.MNG.groups.push({
							id: manager.managerId,
							title: managerFullName,
							sortName: managerSortName
						});
					}
				}
			},
			MNGgetIndex(managerId) {

				let managers = this.onboardingListGroupers.MNG.groups;
				for (var i = 0; i < managers.length; i++) {
					if (managerId == managers[i].id) {
						return i;
					}
				}

				return 0;
			},
			MNGgroupingConditions(item) {
				var myId = JSON.parse(localStorage.getItem('workers'))[0].id;

				if (item.managerId == null) return 0;
				else if (item.managerId == myId) return 1;
				else return this.MNGgetIndex(item.managerId);
			},

			// Empty function for groupers without onInit
			empty() { },

			// --------
			// OFFLINE MODE methods
			// --------

			// retry to load page, when onlineStatus is still false shows 
			// snackbar with offline status notification
			// otherwise loads page
			retryConnect(){
				if(this.onlineStatus){
					window.location.reload(true);
					this.offlineNotifier = false;
				} else {
					this.offlineNotifier = true;
				}
			}
		},
	}

</script>


<style>
	/* This components do not have scoped data tag after rendering, have to do define style globally */
	.OnBoardingPM .v-toolbar__content {
		padding-right: 0;
	}

	.boarding-list .v-list__group__header {
		border-bottom: 2px solid rgba(0,0,0,.42);
	}

	.boarding-list .v-list__group__header--active {
		border-bottom: 2px solid var(--v-primary-base);
	}

	.boarding-list .v-list__group__header .v-icon {
		color: rgba(0,0,0,.42);
		-webkit-font-smoothing: antialiased;
		text-rendering: geometricPrecision;
	}

	.boarding-list .v-list__group__header--active .v-icon {
		color: var(rgba(4, 202, 90, 1));
		-webkit-font-smoothing: antialiased;
		text-rendering: geometricPrecision;
	}

	.boarding-list .v-list__group > .v-list__group__header {
		background-color: rgba(0,0,0,0) !important;
	}

	.boarding-list .v-list__group__header .boarding-list__main-title {
		color: rgba(0,0,0,.42);
		-webkit-font-smoothing: antialiased;
		text-rendering: geometricPrecision;
	}

	.boarding-list .v-list__group__header--active .boarding-list__main-title {
		color: var(rgba(4, 202, 90, 1));
		-webkit-font-smoothing: antialiased;
		text-rendering: geometricPrecision;
	}

	.OnBoardingPM .boarding-list .v-list__group__items--no-action .v-list__tile {
		padding-left: 5px;
		padding-right: 5px;
	}


	.OnBoardingPM[mode="edit"] .boarding-list__inner-item .v-list__tile {
		transition: background-color 0.1s linear;
	}

		.OnBoardingPM[mode="edit"] .boarding-list__inner-item .v-list__tile:hover {
			background-color: rgba(0,0,0,0);
		}

	.OnBoardingPM[mode="edit"] .boarding-list__inner-item.selected .v-list__tile {
		background-color: rgba(0,0,0,0.08);
	}

	.snackbar-offline-notifier{
		height: 30px !important;
		margin-bottom: 70px;
		top: calc(90% - 35px) !important;
		-webkit-font-smoothing: antialiased; 
		text-rendering: geometricPrecision;
		width: 80% !important;
		margin-left: 10% !important;
		margin-right: 10% !important;
		transition: 1s !important;
	}

	.v-sheet .v-snack--multi-line .v-snack__wrapper{
		height: 30px !important;
		min-height: 30px !important;
	}
</style>

<style scoped>
	.OnBoardingPM {
		margin-bottom: 100px;
		overflow-x:unset !important;
	}

	.boarding-edit-toolbar {
		z-index: 11 !important;
		position: fixed;
		margin-top: -64px;
	}
	@media only screen and (max-width: 943px){
		.boarding-edit-toolbar {
			margin-top: -56px;
		}
	}

	/* Listing */
	.boarding-list {
		background-color: rgba(0,0,0,0);
		padding-left: 12px;
		padding-right: 12px;
	}

	.list-group-item{
		border-bottom: 2px solid gray;
		-webkit-font-smoothing: antialiased; 
		-moz-osx-font-smoothing: grayscale; 
		text-rendering: geometricPrecision;
	}

	.boarding-list__inner-item {
		width: 100%;
		-webkit-transition: margin-left 0.3s, margin-right 0.3s; /* Safari prior 6.1 */
		transition: margin-left 0.3s, margin-right 0.3s;
	}

	.boarding-list-group-sub-item{
		color: black;
		width: auto;
		margin-left: 10px;
		margin-right: 10px;
		width: auto;
		-webkit-font-smoothing: antialiased;
		text-rendering: geometricPrecision;
	}

	.boarding-list__inner-item__left-action {
		position: absolute;
		left: -50px;
		top: 0px;
		width: 50px;
		height: 100%;
	}

	.boarding-list__inner-item__right-action {
		position: absolute;
		right: -50px;
		top: 0px;
		width: 50px;
		height: 100%;
	}

	.boarding-list__main-title {
		color: var(rgba(4, 202, 90, 1));
		width: auto !important;
		margin-left: 10px !important;
		margin-right: 10px !important;
		-webkit-font-smoothing: antialiased;
		text-rendering: geometricPrecision;
	}

	.v-list__group--active::before {
		height: 0;
	}

	.v-list__group--active::after {
		height: 0;
	}

	.boarding-list__item-avatar {
		width: 100%;
		height: 100%;
		line-height: 100%;
	}

		.boarding-list__item-avatar > .boarding-list__item-avatar-check {
			height: calc(100% - 2px);
			width: calc(100% - 2px);
			border: 1px solid var(--v-primary-base);
			display: flex;
			flex-direction: column;
			justify-content: center;
			background-color: #FFF;
			border-radius: 50%;
			font-weight: bold;
		}

		.boarding-list__item-avatar > .boarding-list__item-avatar-initials {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			background-color: #CFCFCF;
			border-radius: 50%;
			font-weight: bold;
		}

	.boarding-add-new {
		position: fixed;
		left: calc(50% - 36px + 128px);
		bottom: 28px;
		z-index: 4;
	}

	@media only screen and (max-width: 800px){
		.boarding-add-new {
			left: calc(50% - 36px);
		}
	}
</style>
