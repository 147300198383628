<!--
SharedMasters 2020 - Fast OnBoarding Application

__copyright__ = "Copyright (C) 2020, Shared Masters sp. z o.o."
__license__ = "Fast OnBoarding can not be copied and/or distributed without the express permission of Shared Masters sp. z o.o."
__version__ = "1.2"
__author__ = "Jan Przychodniak, .."
__maintainer__ = "Shared Masters"
__email__ = "it@sharedmasters.com"
__status__ = "PROD"

-->

<template>
	<div class="EmployeesPM" :mode="mode">
		<!-- Edit toolbar -->
		<v-toolbar v-model="editToolbar"
				   fixed
				   text
				   dark
				   class="employees-edit-toolbar left-offset100"
				   transition="slide-y-transition"
				   v-if="isMarkedRecordOnDisplayedList">
			<v-btn icon @click="finishEditing()">
				<v-icon>arrow_back</v-icon>
			</v-btn>

			<v-toolbar-title>
				{{ editMode.selected.length }}
			</v-toolbar-title>

			<v-spacer></v-spacer>

			<v-btn icon @click="openEmployeeMessageDialog()" :disabled="!onlineStatus">
				<v-icon>textsms</v-icon>
			</v-btn>

			<!-- <v-btn icon @click="openChangePlansDialog()" :disabled="!onlineStatus">
				<v-icon>mdi-file-replace-outline</v-icon>
			</v-btn> -->

			<!--<v-btn icon
				@click="openExtendOffBoardingDialog()"
				v-if="editMode.displayForOffBoardingExtending"
				:disabled="!onlineStatus || !editMode.validForOffBoardingExtending"
			>
				<v-icon>thumb_up_alt</v-icon>
			</v-btn>-->
			<v-tooltip bottom v-if="!editMode.validForResign">
				<template v-slot:activator="{ on, attrs }">
					<div
						v-bind="attrs"
						v-on="on"
					>
						<v-btn icon
							:disabled="true"
						>
							<v-icon>thumb_down_alt</v-icon>
						</v-btn>
					</div>
				</template>
				<span>Funkcja finalnego zwolnienia dostępna tylko w Boarding</span>
			</v-tooltip>
			<v-btn icon
				v-else
				@click="openResignDialog()"
				:disabled="!onlineStatus"
			>
				<v-icon>thumb_down_alt</v-icon>
			</v-btn>

			<v-menu bottom left>
				<!-- <template v-slot:activator="{ on }"> -->
					<!-- <v-btn
					dark
					icon
					v-on="on"
					style="margin-left:-2px"
				>
					<v-icon>more_vert</v-icon>
				</v-btn>
			-->
				<!-- </template> -->

				<!-- <v-list>
			<v-list-item
				v-for="(item, i) in editMode.editToolbarMoreOptions"
				:key="i"
			>
				<v-list-item-title>{{ item.title }}</v-list-item-title>
			</v-list-item>
			</v-list>
		 -->
			</v-menu>
		</v-toolbar>

		<employees-filters-dialog ref="employeeFiltersDialog"
			:reason="filters.offBoardingReason"
			:details="filters.offBoardingDetails"
			:selectForResignReasons="offBoardingReasonsDictionary"
			:selectForResignDetails="offBoardingDetailsDictionary"
			@search="setFilters"
			@clear="clearFilters"
		></employees-filters-dialog>

		<send-employee-message-dialog ref="sendEmployeeMessageDialog"
			v-bind:phoneDictionary="editMode.dialogs.messageDialog.phoneNumbers"
			v-bind:managersDictionary="managersData"
			v-bind:defaultManagerId="editMode.dialogs.changePlansDialog.defaultManager"
			@error="onError"
			@request-sending-success="onSendEmployeeMessageSuccess"
			@request-sending-end="onRequestEnd"
		></send-employee-message-dialog>

		<off-boarding-dialog ref="offBoardingDialog"
			:workers="editMode.dialogs.resignDialog.workers"
			:assignedProjects="editMode.dialogs.resignDialog.assignedProjects"
			:markedProjectsIds="editMode.dialogs.resignDialog.markedProjects"
			:details="offBoardingDetailsDictionary"
			:reasons="offBoardingReasonsDictionary"
			@request-sending-start="onRequestStart"
			@request-sending-success="onResignSuccess"
			@request-sending-end="onRequestEnd"
			@error="onError">
		</off-boarding-dialog>


		<!-- <change-employee-plans-dialog ref="changeEmployeePlansDialog"
			v-bind:workers="editMode.dialogs.changePlansDialog.workers"
			v-bind:managersDictionary="managersData"
			v-bind:defaultManagerId="editMode.dialogs.changePlansDialog.defaultManager"
			@request-sending-start="onRequestStart"
			@request-sending-success="onChangeEmployeePlansSuccess"
			@request-sending-end="onRequestEnd"
			@error="onError"
		></change-employee-plans-dialog> -->

		<extend-offboarding-dialog ref="extendOffBoardingDialog"
			:boardingsToUpdate="editMode.dialogs.extendOffBoardingDialog.boardings"
			:initialDate="editMode.dialogs.extendOffBoardingDialog.defaultDate"
			:warning="editMode.dialogs.extendOffBoardingDialog.warning"
			:assignedProjects="editMode.dialogs.extendOffBoardingDialog.assignedProjects"
			:markedProjectsIds="editMode.dialogs.extendOffBoardingDialog.markedProjectsIds"

			@request-sending-start="onRequestStart"
			@request-sending-success="onExtendOffBoardingDialogSuccess"
			@request-sending-end="onRequestEnd"
			@error="onError"
		></extend-offboarding-dialog>

		<!-- Search bar -->
		<v-layout row align-justify-end justify-center style="margin-top: 50px; margin-bottom: -15px; padding-bottom: 0px; width:100%; overflow-x: hidden;" fill-width>
			<v-flex xs6>
				<v-text-field style="margin:5px 10px 0px 0px; -webkit-font-smoothing: antialiased; text-rendering: geometricPrecision;"
							  :label="lview.search"
							  append-icon="search"
							  :clearable="true"
							  v-model="searchValue"
							  v-on:change="onSearchChange"
							  v-on:click:clear="onClear"
							  color="rgba(4, 202, 90, 1)"
							  @input="onSearchInput"></v-text-field>
			</v-flex>

			<v-flex xs4>
				<v-select :items="dropdownStatus"
						  label="Status"
						  item-text="statusName"
						  item-value="statusId"
						  item-color="green accent-4"
						  v-model="selectedStatus"
						  v-on:change="statusChange"
						  hide-details
						  color="rgba(4, 202, 90, 1)"
						  style="-webkit-font-smoothing: antialiased; text-rendering: geometricPrecision;"
						  ></v-select>
			</v-flex>

			<v-flex xs1 align-self-center class="pl-2">
				<v-icon :color="(filters.offBoardingReason || filters.offBoardingDetails)? 'green darken-1': ''" class="ml-3" @click="openEmployeeFiltersDialog">mdi-filter-variant</v-icon>
			</v-flex>


		</v-layout>

		
		<!-- LISTING -->
		<v-layout row style="margin-left:0; width:100%" v-if="listProjectItems.length > 0">
			<v-flex xs12>
				<v-list class="employees-list" expand tyle="padding:0; padding-bottom:10px">
					<v-list-group class="employees-list__group"
								  v-for="(item, itemKey) in listProjectItems"
								  :key="itemKey"
								  :id="'Group'+item.id"
								  v-model="item.active"
								  no-action
								  @click="!item.active? setProjectData(item): ''"
								  color="rgba(4, 202, 90, 1)"
					>
						<template v-slot:activator>
							<v-list-item class="employees-list__item list-group-item" :style="item.active ? 'border-bottom-color: rgba(4, 202, 90, 1);':'border-bottom-color: grey;'">
								<v-list-item-content>
									<v-list-item-title 
										class="employees-list__main-title"
										style="-webkit-font-smoothing: antialiased; text-rendering: geometricPrecision;"
										:style="item.active? 'color: rgba(4, 202, 90, 1)':''"
									>
										{{ item.title }}
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</template>					

						<v-list-item-group
							v-for="(subItem, subItemKey) in item.items"
							class="employees-list-group-sub-item"
							:key="subItemKey"
							v-bind:employeeId="subItem.recordId"
						>
							<v-flex xs12 style="display: flex;">
								<!--
										User initials, or tick icon if accepted
								-->
								<v-list-item-avatar>
									<div class="employees-list__item-avatar" @click="onAvatarClick(subItem)">
										<!-- If user is accepted, we show the tick icon -->
										<div v-if="editMode.selected.indexOf(subItem.id) != -1 && getEmployeeAvatarType(subItem) == 0" class="employees-list__item-avatar-check">
											<v-icon>check</v-icon>
										</div>

										<!-- nearly outdated employee -->
										<div v-else-if="editMode.selected.indexOf(subItem.id) != -1 && getEmployeeAvatarType(subItem) == 1" class="employees-list__item-avatar-check-nearly-outdated">
											<v-icon>check</v-icon>
										</div>

										<!-- outdated employee -->
										<div v-else-if="editMode.selected.indexOf(subItem.id) != -1 && getEmployeeAvatarType(subItem) == 2" class="employees-list__item-avatar-check-outdated">
											<v-icon>check</v-icon>
										</div>

										<!-- Otherwise, show their initials -->
										<div v-else-if="getEmployeeAvatarType(subItem) == 1" class="employees-list__item-avatar-nearly-outdated">
											{{ subItem.initials }}
										</div>

										<div v-else-if="getEmployeeAvatarType(subItem) == 2" class="employees-list__item-avatar-outdated">
											{{ subItem.initials }}
										</div>

										<div v-else class="employees-list__item-avatar-initials">
											{{ subItem.initials }}
										</div>
									</div>
								</v-list-item-avatar>

								<!-- Full name and location -->
								<v-layout
									@click="onSubItemClick(subItem.id)"
									@mousedown="onSubItemHoldStart(subItem.id)"
									@mouseup="onSubItemHoldStop(subItem.id)"
									@mouseleave="onSubItemHoldStop(subItem.id)"
									@touchstart="onSubItemHoldStart(subItem.id)"
									@touchend="onSubItemHoldStop(subItem.id)"
									@touchcancel="onSubItemHoldStop(subItem.id)"
									@touchmove="onSubItemHoldStop(subItem.id)"
								>
									<v-list-item-content>
										<!-- here clicking -->
										<v-list-item-title class="font-weight-bold" v-if="isManager(subItem)">{{ subItem.fullName }}</v-list-item-title>
										<v-list-item-title v-else>{{ subItem.fullName }}</v-list-item-title>
										<!-- <v-list-item-subtitle>{{ subItem.projectsDisplayText == null ? lmessages.unassigned : subItem.projectsDisplayText }}</v-list-item-subtitle> -->
										<v-list-item-subtitle :style="{'color': subItem.active? 'rgba(4, 202, 90, 1)': 'gray'}">{{ subItem.boardingTimeText }}</v-list-item-subtitle>
										<v-list-item-subtitle v-if="subItem.resignReason">
											<v-chip small>{{subItem.resignReason}}</v-chip>
										</v-list-item-subtitle>
									</v-list-item-content>

									<!-- Thumb down icon -->
									<v-list-item-action v-if="subItem.resigning">
										<v-icon>mdi-thumb-down</v-icon>
									</v-list-item-action>

									<v-list-item-action v-if="subItem.hasHome">
										<v-icon>home</v-icon>
									</v-list-item-action>

									<v-list-item-action v-if="subItem.resigning">
										<span v-if="subItem.outdate" style="color:#FF3333">
											{{ subItem.offBoardingDateText }}
										</span>
										<span v-else>
											{{ subItem.offBoardingDateText }}
										</span>
									</v-list-item-action>
								</v-layout>
							</v-flex>
						</v-list-item-group>

						<template v-if="(item.requestedItems - item.loadedItems)>-1">
							<v-skeleton-loader v-for="index in item.requestedItems - item.loadedItems"
								:key="'skeleton-loader-' + index"
								class="employees-pm__skeleton-loader-list"
								type="list-item-avatar-two-line"
							></v-skeleton-loader>
						</template>
					</v-list-group>
				</v-list>
			</v-flex>
		</v-layout>

		<v-layout justify-center align-center v-else style="min-height: 80px;">
			<v-flex>
				<h2 class="title" style="text-align: center">{{ lview.nothingFound }}</h2>
			</v-flex>
		</v-layout>

		<v-snackbar
		  v-model="offlineNotifier"
		  fixed
		  class="snackbar-offline-notifier"
	  	>
			<v-container class="align-self-center" style="height: 30px; width: 100%; padding-top: 0px; padding-bottom: 0px;">
				<v-flex xs12 class="align-self-center" style="display: flex;">
					<v-flex xs6 class="align-self-center" style="-webkit-font-smoothing: antialiased; text-rendering: optimiseSpeed;">{{ lmessages.networkError }}</v-flex>
					<v-flex xs6 class="align-self-center">
						<v-btn
							text
							@click="retryConnect()"
							class="align-self-center"
							style="color: orange; font-size: 12px; -webkit-font-smoothing: antialiased; text-rendering: optimiseSpeed;"
						>
							{{ lbuttons.retry }}
						</v-btn>
					</v-flex>
				</v-flex>
			</v-container>
	  	</v-snackbar>
	</div>
</template>

<script>
	import axios from 'axios'
	import store from '../store.js'
	import Vue from 'vue'

	export default {
		name: 'EmployeesPM',
		beforeMount() {

			this.sortItems[0].sorterName = this.lview.sortByProject;
			this.sortItems[1].sorterName = this.lview.sortByManager;

			this.groupers.DEFAULT.onInit = this.empty;
			this.groupers.DEFAULT.groupingCondition = this.grouperDEFAULTgroupingCondition;

			this.groupers.OFFICE.onInit = this.MNGonInit;
			this.groupers.OFFICE.groupingCondition = this.grouperOFFICEgroupingCondition;

			this.groupers.PROJECT.onInit = this.PRJonInit;
			this.groupers.PROJECT.groupingCondition = this.PRJgroupingConditions;

			this.filters.all.condition = this.acceptAll;

			this.filters.searchNames.condition = this.filterSEARCHNAMEScondition;
			this.currentCondition = this.acceptAll;

			window.addEventListener('online', () => { this.onlineStatus = true; } );
			window.addEventListener('offline', () => { this.onlineStatus = false; } );
		},

		beforeDestroy(){
			var dataToSerialize = {
				validStatus : this.editMode.validStatus,
				selected : this.editMode.selected
			};
			localStorage.setItem('editModeEmployeesPM', JSON.stringify(dataToSerialize));
			localStorage.setItem('employeesFilterNamesSearchValue', JSON.stringify(this.searchValue));
			localStorage.setItem('employeesFilterSortGrouperValue', JSON.stringify(this.sortConditionId));

			localStorage.setItem('appSourceDataEmployeesScreen', JSON.stringify(this.sourceData));
			localStorage.setItem('appProjectDataEmployeesScreen', JSON.stringify(this.projectsDictionary));
			localStorage.setItem('appManagersEmployeesScreen', JSON.stringify(this.managersData));
		
			window.removeEventListener('online', () => { this.onlineStatus = true; });
			window.removeEventListener('offline', () => { this.onlineStatus = false; });
		},

		props: {
			version: {
				type: String,
				required: true,
			},
			appConfig: {
				type: Object,
				required: true
			}
		},
		mounted: async function () {
			/*if(this.appConfig.applicationMode != "MANAGER"){
				this.$store.dispatch("logout").then(() => {
					this.$router.push("/login");
				});
			}*/
			this.$emit('set-title', this.lview.pageTitle);

			let userPermissions = localStorage.getItem("user_permissions");
			if ( !userPermissions.includes("fob.boardings_view") ) {
				this.$emit('set-state', 'ERROR', this.lview.youDoNotHavePriviledgesToThisView);
				return;
			}

			if (userPermissions.includes("fob.boardings_change")) {
				this.canClickAvatar = true;
			}

			var workers = JSON.parse(localStorage.getItem("workers"));

			this.groupers.DEFAULT.groups[0].title = this.lview.myEmployees;
			this.groupers.DEFAULT.groups[1].title = this.lview.unassigned;
			this.groupers.DEFAULT.groups[2].title = this.lview.otherManagers;

			this.editMode.editToolbarMoreOptions[0].title = this.lview.editMenu.edit;

			var appSourceData = JSON.parse(localStorage.getItem('appSourceDataEmployeesScreen'));
			var appProjectData = JSON.parse(localStorage.getItem('appProjectDataEmployeesScreen'));
			var appManagersData = JSON.parse(localStorage.getItem('appManagersEmployeesScreen'));
			
			if(appSourceData != null && appProjectData != null && appManagersData != null){
				this.sourceData = appSourceData;
				this.projectsDictionary = appProjectData;
				this.managersData = appManagersData;
				this.updateEmployeeListDisplay();
			}
			else{
				this.employeesListItems = [];
			}

			if(!this.onlineStatus){
				this.offlineNotifier = true;
			}

			var input = JSON.parse(localStorage.getItem('employeesFilterNamesSearchValue'));
			if(input != null){
				this.searchValue = input;
			}
			this.filters.searchNames.input = this.searchValue.toUpperCase();

			this.getDictionaries()
					.then(response => { this.handleDictionariesData(response.jsonOffBoardingReasons, response.jsonOffBoardingDetails); })
					.catch(err => {
						var error_text = err.toString()
						if (err.request) {
							error_text += ': ' + err.request.responseText
						}
						console.error(error_text);
						if(err.message == "Network Error"){
							this.offlineNotifier = true;
						}
						else{
							this.$emit("set-state", "error", this.lmessages.errorOccured);
							this.$emit('display-error', this.lmessages.errorOccuredTitle, this.lmessages.errorOccured, error_text);
						}
					});



			if (typeof workers !== 'undefined' && workers != null) {
				this.getPageData()
					.then(response => { this.handleData(response.jsonProjects, response.jsonEmployeesProjects); })
					.catch(err => {
						var error_text = err.toString()
						if (err.request) {
							error_text += ': ' + err.request.responseText
						}
						console.error(error_text);
						if(err.message == "Network Error"){
							this.offlineNotifier = true;
						}
						else{
							this.$emit("set-state", "error", this.lmessages.errorOccured);
							this.$emit('display-error', this.lmessages.errorOccuredTitle, this.lmessages.errorOccured, error_text);
						}
					});
			}
			else {
				// TODO : show error message
			}

			// var input = JSON.parse(localStorage.getItem('employeesFilterNamesSearchValue'));
			// if(input != null){
			// 	this.searchValue = input;
			// }
			// this.filters.searchNames.input = this.searchValue.toUpperCase();
			// this.onSearchChange();

			var sortCondId = JSON.parse(localStorage.getItem('employeesFilterSortGrouperValue'));
			if(sortCondId != null){
				this.defaultSelected = sortCondId;
				this.currentGrouper = sortCondId;
				this.sortConditionId = sortCondId;
			}

			this.loadMoreData()
			//this.$refs.changeEmployeePlansDialog.openDialog();
		},

		data() {
			return {
				// Employees data
				sourceData: [
					/*
					id: (...) {int}, // user profile id
					records: [{
						id: {String}, // recordId
						boardingId: {Int},
						offBoardingDate: {Date},
						projectId: {int},
						boardingStatus: {int}
					}],
					workerId: (...) {int},
					firstName: (...) {String},
					middleName: (...) {String},
					lastName: (...) {String},
					hasHome: (...) {bool},
					projectIds: (...) {Array},
					managerId: (...) {int}
					*/
				],

				managersData: [
					/*
					{
						managerId : 91,
						firstName : "Adrian",
						middleName : null,
						lastName : "Bączek"
					}
					*/
				],
				projectsDictionary: [
					/*
					id: (...) {int}
					name: (...) {String}
					*/
				],
				managerProjectsDictionary: [
					/*
					id: {int},
					name: (...)
					*/
				],
				offBoardingReasonsDictionary:[
					/*
					id: {int},
					name: (...)
					*/
				],
				offBoardingDetailsDictionary:[
					/*
					id: {int},
					name: (...)
					*/
				],
				listData: [],
				listProjectItems: [],

				editToolbar: true,

				employeesListItems: [],

				onlineStatus: navigator.onLine,
				offlineNotifier: false,

				// - default
				// - edit
				mode: 'default',

				defaultMode: {
					holdItemDownInterval: false,
					holdDownTime: 0,
					heldElementId: null,
				},

				editMode: {
					validStatus: true,
					validForOffBoardingExtending: true,
					validForResign: true,
					displayForOffBoardingExtending: true,
					displayForResign: true,
					selected: [],
					editToolbarMoreOptions: [
						{
							title: ""
						}
					],
					dialogs: {
						changePlansDialog: {
							workers: [],
							defaultProject: null,
							defaultManager: null,
							assignedProjects: []
						},
						messageDialog: {
							phoneNumbers: [],
							currentManager: null
						},
						resignDialog: {
							workers: [],
							assignedProjects: [],
							markedProjects: [],
						},
						extendOffBoardingDialog: {
							boardings: [],
							assignedProjects: [],
							markedProjectsIds: [],
							defaultDate: null,
							warning: null
						}
					}
				},

				holdItemDownTimeout: false,
				heldElementId: null,


				dropdownStatus: [
					{
						statusId: -1,
						statusName: "All"
					},
					{
						statusId: 3,
						statusName: "Active"
					},
					{
						statusId: 7,
						statusName: "Inactive"
					},
				],

				selectedStatus: null,

				openProject: null,

				groupers: {
					"DEFAULT": {
						groups: [
							{
								id: "MINE",
								title: ""
							},
							{
								id: "UNASSIGNED",
								title: ""
							},
							{
								id: "OTHERS",
								title: ""
							}
						],
						onInit: null,
						groupingCondition: null
					},
					//OFFICE grouper - made for using other managers, in the future may be recalled to "DEFAULT", at the moment is OFFICE
					"OFFICE": {
						groups: [],
						onInit: null,
						groupingCondition: null
					},
					"PROJECT": {
						groups: [],
						onInit: null,
						groupingCondition: null
					}
				},
				currentGrouperValue: null,

				sortConditionId: null,

				sortItems: [
					{
						sorterId: "PROJECT",
						sorterName: "",
					},
					{
						sorterId: "OFFICE",
						sorterName: ""
					}
				],
				defaultSelected: {
					sorterId: "OFFICE",
					lang_pl: "Kierowniku"
				},

				filters: {
					all: {
						condition: null
					},
					searchNames: {
						input: "",
						condition: null
					},
					offBoardingReason: null,
					offBoardingDetails: null
				},
				currentCondition: null,

				searchValue: "",
				searchTimeout: false,

				requestSent: false,

				previousSearchValue: "",
				dialog: false,
				editedWorker: {
					id: null,
					firstName: null,
					middleName: null,
					lastName: null,
					age: null,
					email: null,
				},
				canClickAvatar: false,
			}
		},
		computed: {
			lview: { get: function () { return this.$t('views.employeesPM'); } },
			lbuttons: { get: function () { return this.$t('commons.buttons'); } },
			lmessages: { get: function () { return this.$t('commons.messages'); } },

			// Further definition for grouper
			currentGrouper: {
				get: function () {
					if (this.currentGrouperValue == null) return this.groupers["OFFICE"];
					else return this.currentGrouperValue;
				},
				set: function (grouper) {
					this.currentGrouperValue = this.groupers[grouper];
				}
			},
			isMarkedRecordOnDisplayedList: {
				get: function(){
					return this.$store.getters.markedRecord;
				}
			}
		},
		methods: {
			test() {
				alert("I'm here!");
			},

			// ---------
			// EDIT MODE
			// ---------

			finishEditing() {
				this.editMode.selected.splice(0, this.editMode.selected.length);
				this.$store.commit('updateMarkedRecordFlag', {isMarked: false});
				this.mode = 'default';
			},
			/**
			 * Load previous selection stored in cache
			 */
			assignSelectedFromCache(){
				var json = JSON.parse(localStorage.getItem('editModeEmployeesPM'));
				if(json != null){
					this.editMode.validStatus = json.validStatus;
					this.editMode.selected = json.selected;
					if(this.editMode.selected.length > 0){
						this.$store.commit('updateMarkedRecordFlag', {isMarked: true});
						this.mode = "edit"; 
					} else {
						this.$store.commit('updateMarkedRecordFlag', {isMarked: false});
					}
					this.verifySelection();
				} else {
					this.$store.commit('updateMarkedRecordFlag', {isMarked: false});
				}
			},

			verifySelection(){
				this.editMode.validStatus = true;

				this.verifySelectedItemsExistance();

				for(var i = 0; i < this.editMode.selected.length; i++){
					// var worker = this.getSourceEmployeeFromRecordId(this.editMode.selected[i]);
					var worker = this.getSourceEmployeeFromWorkerId(this.editMode.selected[i]);

					if(!this.workerIsValidForEditing(worker)){
						this.editMode.validStatus = false;
						break;
					}
				}

				// this.verifySelectionConsistency();
				this.verifySelectionForOffboardingExtending();
			},
			/**
			 * Some items might not exist in the array anymore, this should be verified before attempting any operations on these items
			 */
			verifySelectedItemsExistance(){
				var indexesToRemove = [];
				for(var i = 0; i < this.editMode.selected.length; i++){
					// if(this.getSourceRecordFromRecordId(this.editMode.selected[i]) == null){
					// 	indexesToRemove.push(i);
					// }
					if(this.getSourceRecordsFromWorkerId(this.editMode.selected[i]).length == 0){
						indexesToRemove.push(i);
					}
				}
				for(var i = indexesToRemove.length - 1; i >= 0; i--){
					this.editMode.selected.splice(indexesToRemove[i], 1);
				}
			},
			verifySelectionForOffboardingExtending(){
				this.editMode.validForOffBoardingExtending = true;
				this.editMode.validForResign = true;
				this.editMode.displayForOffBoardingExtending = false;
				this.editMode.displayForResign = false;
				var tmp;

				for(var i = 0; i < this.editMode.selected.length; i++){
					// var record = this.getSourceRecordFromRecordId(this.editMode.selected[i]);
					var records = this.getSourceRecordsFromWorkerId(this.editMode.selected[i]);

					tmp = false;

					for(let record of records){
						if(record.boardingStatus == 3){
							tmp = true;
							break;
						}
					}
					console.log(tmp);

					if (tmp == false){
						this.editMode.validForResign = false;
					}
				}
			},

			workerIsValidForEditing(worker){
				for(var i = 0; i < worker.records.length; i++){
					for(var j = i+1; j < worker.records.length; j++){
						if(worker.records[i].projectId == worker.records[j].projectId){
							return false;
						}
					}
				}
				return true;
			},

			listsInnerJoin(list1, list2) {
				var primary = list1;
				var secondary = list2;
				var joined = [];
				if (list1.length > list2.length) {
					primary = list2;
					secondary = list1;
				}

				var element;
				var index;
				for (var i = 0; i < primary.length; i++) {
					element = primary[i];
					index = secondary.indexOf(element)

					if (index != -1) {
						joined.push(element);
					}
				}

				return joined;
			},

			openEmployeeFiltersDialog(){
				this.$nextTick(this.$refs.employeeFiltersDialog.openDialog);
			},

			openEmployeeMessageDialog() {
				// TODO:
				// 1. Pobranie aktualnego id/telefonu obecnie zalogowanego menedżera
				//
				if (this.editMode.selected.length > 0) {
					var dialog = this.editMode.dialogs.messageDialog;
					dialog.phoneNumbers = []; // reset old/temporary list

					for (var i = 0; i < this.editMode.selected.length; i++) {
						// var selected = this.getSourceEmployeeFromRecordId(this.editMode.selected[i]);
						var selected = this.getSourceEmployeeFromWorkerId(this.editMode.selected[i]);
						if (selected.mobileNumber == "") {
							this.$emit('display-error', "Brak numeru telefonu", "Pracownik:<br>" + selected.lastName + " " + selected.firstName);
							return;
						}
						dialog.phoneNumbers.push(selected.mobileNumber);
					}
					this.$nextTick(this.$refs.sendEmployeeMessageDialog.openDialog);
				} else {
					this.$emit('display-error', "Błąd", "Brak zaznaczonych pracowników");
				}
			},

			// openChangePlansDialog() {
			// 	if (this.editMode.selected.length > 0) {

			// 		// Only allow to edit one worker at a time or workers with the same projects
			// 		var worker;
			// 		var workers = [];
			// 		var workerIds = this.editMode.selected;

			// 		var consistentManager = true;
			// 		var sharedProjects = null;
			// 		var markedProjects = null;

			// 		var defaultManager = -1;
			// 		var selected;
			// 		var record;

			// 		// for (var i = 0; i < this.editMode.selected.length; i++) {
			// 		// 	selected = this.getSourceEmployeeFromRecordId(this.editMode.selected[i]);

			// 		// 	if (workerIds.indexOf(selected.workerId) == -1) {
			// 		// 		workerIds.push(selected.workerId);
			// 		// 	}
			// 		// }

			// 		var dialog = this.editMode.dialogs.changePlansDialog;
			// 		dialog.workers.splice(0, dialog.workers.length);
			// 		for (var i = 0; i < workerIds.length; i++) {
			// 			var worker = this.getSourceEmployeeFromWorkerId(workerIds[i]);
			// 			dialog.workers.push(worker);

			// 			if(defaultManager == -1)
			// 				defaultManager = worker.managerId;

			// 			else if(defaultManager != worker.managerId){
			// 				consistentManager = false;
			// 			}
			// 		}

			// 		if(consistentManager){
			// 			dialog.defaultManager = defaultManager;
			// 		}
			// 		else{
			// 			dialog.defaultManager = null;
			// 		}
					
			// 		this.$nextTick(this.$refs.changeEmployeePlansDialog.openDialog);
			// 	}
			// },

			onChangeEmployeePlansSuccess() {
				this.updateEmployeeListDisplay(true);
				this.finishEditing();
			},

			onSendEmployeeMessageSuccess() {
				this.$emit('display-error', "<< Info >>", "Wiadomość wysłano");
					// this.lview.errorInvalidWorkerTitle, this.lview.errorInvalidWorkerMessage);
			},

			openResignDialog() {
				if (this.editMode.selected.length > 0) {
					var employees = this.getSelectedEmployees();
					this.editMode.dialogs.resignDialog.workers = employees;

					var employeesProjects = [];
					var markedEmployeesProjects = [];
					var records;
					var index;
					var employee;
					var record;
					for (var employeeIndex in employees) {
						employee = employees[employeeIndex];
						employeesProjects.push(employee.projectsToResignIds);

						markedEmployeesProjects.push([]);
						index = employeesProjects.length - 1;
						records = this.getSelectedRecordsForWorkerId(employee.workerId);

						for (var recordIndex in records) {
							record = records[recordIndex];
							markedEmployeesProjects[index].push(record.projectId);
						}
					}

					var dialog = this.editMode.dialogs.resignDialog;
					dialog.assignedProjects.splice(0, dialog.assignedProjects.length);
					var assignedProjects = this.getSharedProjects(employeesProjects);
					for (var i = 0; i < assignedProjects.length; i++) {
						dialog.assignedProjects.push({
							id: assignedProjects[i],
							name: this.getProjectName(assignedProjects[i])
						});
					}

					// If there are no projects to display in the first place, don't display anything
					if(assignedProjects.length == 0){
						this.$emit("display-error", this.lview.errorNoProjectsToDisplayTitle, this.lview.errorNoProjectsToDisplay);
						return;
					}

					var markedProjects = this.getSharedProjects(markedEmployeesProjects);
					dialog.markedProjects.splice(0, dialog.markedProjects.length);
					for (var i = 0; i < markedProjects.length; i++) {
						dialog.markedProjects.push(markedProjects[i]);
					}

					this.$refs.offBoardingDialog.openDialog();
				}
			},
			getProjectsListRecordFromBoardingId(){
				console.log("TEST");
			},
			onResignSuccess(modified, modifiedUserProfile) {
				let project = this.listProjectItems.find(i => i.id==modified[0].projectId)
				project.items=[]
				project.itemsRequested=false
				project.loadedItems=0
				project.loadedPages=0
				let idx=this.projectsDictionary.findIndex(i => i.id==project.id)
				if(idx!=-1){
					project.requestedItems=(this.projectsDictionary[idx].workersNumber > 10) ? 10 : this.projectsDictionary[idx].workersNumber
					this.setProjectData(project)
				}

				// var employee;
				// let record;

				// if(modifiedUserProfile != null){
				// 	modifiedUserProfile.record.rating = modifiedUserProfile.rating;
				// 	modifiedUserProfile.record.ratingComment = modifiedUserProfile.ratingComment;
				// }

				// for (var i = 0; i < modified.length; i++) {
				// 	employee = this.getSourceEmployeeFromWorkerId(modified[i].workerId);
				// 	record = null;
				// 	for (var j = 0; j < employee.records.length; j++) {
				// 		if (employee.records[j].projectId == modified[i].projectId) {
				// 			record = employee.records[j];
				// 			break;
				// 		}
				// 	}

				// 	if (record != null) {
				// 		var oldId = record.id
				// 		record.offBoardingDate = modified[i].offBoardingDate;
				// 		record.boardingStatus = 7;
				// 		record.id = this.createRecordId(employee.workerId, record);
				// 		record.projectAssignmentReason = modified[i].projectAssignmentReason

				// 		//Update record in display list
				// 		let project = this.listProjectItems.find(i => i.id==record.projectId)
				// 		if(project){
				// 			let recordToChange = project.items.find(i=> i.recordId==oldId)
				// 			if(recordToChange){
				// 				let active
				// 				let offDateText
				// 				let today = new Date();
				// 				today.setHours(0, 0, 0, 0);
				// 				let offDate = new Date(record.offBoardingDate);
				// 				let dateDiff = Math.floor((offDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));

				// 				if(dateDiff<0){
				// 					active=false
				// 				}
				// 				else{
				// 					active=true
				// 				}

				// 				if (dateDiff == 0)
				// 					offDateText = this.lview.today;
				// 				else if (Math.abs(dateDiff) == 1) {
				// 					if (dateDiff < 0)
				// 						offDateText = this.lview.yesterday;
				// 					else
				// 						offDateText = this.lview.tomorrow;
				// 				}
				// 				else if (offDate.getFullYear() == today.getFullYear()) {
				// 					offDateText = offDate.getDate() + " " + this.monthNumberToText(offDate.getMonth());
				// 				}
				// 				else
				// 					offDateText = offDate.getDate() + " " + this.monthNumberToText(offDate.getMonth()) + " " + offDate.getFullYear();

				// 				let onDate=record.onBoardingDate
				// 				let boardingTimeText="Since " + this.dateToString(onDate)

				// 				if(offDate!=null){
				// 					boardingTimeText+=" to "
				// 					boardingTimeText+=this.dateToString(offDate)
				// 				}
				// 				recordToChange.active=active
				// 				recordToChange.boardingTimeText=boardingTimeText
				// 				recordToChange.offBoardingDate=record.offBoardingDate
				// 				recordToChange.offBoardingDateText=offDateText
				// 				recordToChange.offBoardingDateDiff=dateDiff
				// 				recordToChange.outdated=(dateDiff < 0)
				// 				recordToChange.resignReason = this.getReasonName(record.projectAssignmentReason)
				// 			}
				// 		}
				// 	}
				// }

				
				this.updateEmployeeListDisplay(true);
				this.finishEditing();
			},

			getSelectedEmployees() {
				var workers = [];
				var selected;

				for (var i = 0; i < this.editMode.selected.length; i++) {
					// selected = this.getSourceEmployeeFromRecordId(this.editMode.selected[i]);
					selected = this.getSourceEmployeeFromWorkerId(this.editMode.selected[i]);
					workers.push(selected);
				}

				return workers;
			},

			openExtendOffBoardingDialog(){
				// var employees = this.getSelectedEmployees();
				// var workerId;
				var records = [];

				// getting selections
				for(var i = 0; i < this.editMode.selected.length; i++){
					// records = records.concat(this.getSourceRecordsFromRecordId(this.editMode.selected[i]));
					records = records.concat(this.getSourceRecordsFromWorkerId(this.editMode.selected[i]));
				}
				if(records.length > 0){
					var consistentOffBoardingDate = true;
					var date = new Date( records[0].offBoardingDate);

					// Verifying consistency of off boarding dates
					for(var i = 1; i < records.length; i++){
						// null case
						if((date == null || records[i].offBoardingDate == null) && date != records[i].offBoardingDate){
							consistentOffBoardingDate = false;
							break;
						}

						// non-null case
						if(date.getTime() != (new Date(records[i].offBoardingDate)).getTime()){
							consistentOffBoardingDate = false;
							break;
						}
					}

					if (consistentOffBoardingDate){
						this.editMode.dialogs.extendOffBoardingDialog.warning = null;
						this.editMode.dialogs.extendOffBoardingDialog.defaultDate = date.toJSON().slice(0, 10).replace("/-/", "/");
					}
					else {
						this.editMode.dialogs.extendOffBoardingDialog.warning = this.lview.warningDifferentOffBoardingDates;
						this.editMode.dialogs.extendOffBoardingDialog.defaultDate = null;
					}

					// Assigning edit records
					this.editMode.dialogs.extendOffBoardingDialog.boardings = records;

					var projects = this.getProjectsForEditTable(
						this.editMode.selected, 
						(employee, projectId) => {
							var record;
							for(var i = 0; i < employee.records.length; i++){
								record = employee.records[i];
								if(record.projectId == projectId){
									if(record.boardingStatus != 7){
										return false;
									}
									return true;
								}
							}
							return false;
						}
					);
					
					// Copying assigned projects
					this.editMode.dialogs.extendOffBoardingDialog.assignedProjects.splice(0);
					for(var i = 0; i < projects.assignedProjects.length; i++){
						this.editMode.dialogs.extendOffBoardingDialog.assignedProjects.push({
							id: projects.assignedProjects[i],
							name: this.getProjectName(projects.assignedProjects[i])
						});
					}

					// Copying marked projects
					this.editMode.dialogs.extendOffBoardingDialog.markedProjectsIds.splice(0);
					for(var i = 0; i < projects.markedProjects.length; i++){
						this.editMode.dialogs.extendOffBoardingDialog.markedProjectsIds.push(projects.markedProjects[i]);
					}

					this.$nextTick(this.$refs.extendOffBoardingDialog.openDialog);
				}
			},
			onExtendOffBoardingDialogSuccess(modified){
				var worker;
				for(var i = 0; i < modified.length; i++){
					worker = this.getSourceEmployeeFromRecordId(modified[i].id);

					modified[i].id = this.createRecordId(worker.workerId, modified[i]);
				}

				// this.updateEmployeeListDisplay(true);

				this.finishEditing();
			},



			// ------
			// COMMON
			// ------
			

			// UNUSED
			/**
			 * Automation for worker partial update request. Just provide data you want to send, the rest will be done by this function
			 *
			 * @param {Object} requestData data to send in the request
			 * @throws {Object} error with structure { message: {String}, errors: [{ workerId: {int}, error: {String} }, ... ] }
			 */
			/*partialWorkerUpdate: async function (requestData) {
				var worker;
				var axiosPromises = [];
				var workerIds = [];
				for (var i = 0; i < this.editMode.selected.length; i++) {
					worker = this.getSourceEmployeeFromRecordId(this.editMode.selected[i]);
					workerIds.push(worker.workerId);

					axiosPromises.push(
						axios({
							method: "PATCH",
							url: localStorage.getItem("current_env") + "/api/worker/" + worker.workerId,
							headers: {
								'Content-Type': 'application/json',
								'Authorization': 'Bearer ' + localStorage.getItem('jwt')
							},
							data: requestData
						})
					);
				}

				var response;
				var requestError = {
					errors: [],
					message: null
				};
				for (var i = 0; i < axiosPromises.length; i++) {
					try {
						response = await axiosPromises[i];
					} catch (err) {
						var error_text = err.toString()
						if (err.request) {
							error_text += ': ' + err.request.responseText
						}
						console.error(error_text);
						requestError.errors.push({
							workerId: workerIds[i],
							error: error_text
						});
					}
				}

				if (requestError.errors.length > 0) {
					requestError.message = "Following errors occured: \n";

					for (var i = 0; i < requestError.errors.length; i++) {
						requestError.message += "\nWorker " + requestError.errors[i].workerId + ": " + requestError.errors[i].error;
					}

					throw requestError;
				}
			},*/
			/**
			 * Finds employee in source data by id
			 *
			 * @param {Integer} employeeId Id to look for
			 * @returns {Object} Employee if foound, null otherwise
			 */
			getSourceEmployeeFromUserProfileId(employeeId) {
				for (var i = 0; i < this.sourceData.length; i++) {
					if (this.sourceData[i].id == employeeId) {
						return this.sourceData[i];
					}
				}
				return null;
			},
			getSourceEmployeeFromWorkerId(workerId) {
				for (var i = 0; i < this.sourceData.length; i++) {
					if (this.sourceData[i].workerId == workerId) {
						return this.sourceData[i];
					}
				}
				return null;
			},
			getSourceEmployeeFromRecordId(recordId) {
				var workerId = recordId.split('_', 1)[0];
				return this.getSourceEmployeeFromWorkerId(workerId);
				/*for(var i = 0; i < this.sourceData.length; i++){
					for(var j = 0; j < this.sourceData[i].records.length; j++){
						if(this.sourceData[i].records[j].id == recordId){
							return this.sourceData[i];
						}
					}
				}
				return null;*/
			},
			getSourceManager(managerId) {
				for (var i = 0; i < this.managersData.length; i++) {
					if (this.managersData[i].managerId == managerId) {
						return this.managersData[i];
					}
				}
				return null;
			},
			getRecord(recordId, records) {
				for (var i = 0; i < records.length; i++) {
					if (records[i].id == recordId) {
						return records[i];
					}
				}
				return null;
			},
			/**
			 * Finds project name in projects dictionary
			 *
			 * @param {Integer} projectId Id to look for
			 * @returns {String} Project name if foound, null otherwise
			 */
			getProjectName(projectId) {
				if (projectId == -1) {
					return this.lmessages.unassigned;
				}
				for (var i = 0; i < this.projectsDictionary.length; i++) {
					if (this.projectsDictionary[i].id == projectId) {
						return this.projectsDictionary[i].name;
					}
				}
				return null;
			},
			/**
			 * Finds resign reason name in reasons dictionary
			 */
			getReasonName(reasonId) {
				if(reasonId){
					let reason = this.offBoardingReasonsDictionary.find(item => item.id==reasonId)
					if(reason){
						return reason.name
					}
				}
			},
			/**
			 * Groups employees
			 *
			 * @param {array} adaptedEmployees object adapted to a format accepted by this component
			 * @returns {object} object adjusted for updateEmployeeListDisplay function
			 */
			groupEmployees(adaptedEmployees) {
				var groups = [];
				var grouper = this.currentGrouper;

				if (grouper.groups.length == 0) {
					grouper.onInit();
				}

				for (var i = 0; i < grouper.groups.length; i++) {
					groups.push({
						id: grouper.groups[i].id,
						title: grouper.groups[i].title,
						sortName: grouper.groups[i].sortName,
						active: false,
						items: []
					});
				}

				var index = -1;
				for (var i = 0; i < adaptedEmployees.length; i++) {
					index = grouper.groupingCondition(adaptedEmployees[i]);
					if (Array.isArray(index)) {
						if (index != -1) {
							for (var j = 0; j < index.length; j++) {
								groups[index[j]].items.push(adaptedEmployees[i]);
							}
						}
					}

					else if (index != -1) {
						groups[index].items.push(adaptedEmployees[i]);
					}
				}

				if (groups.length > 0 && typeof groups[0].sortName !== 'undefined') {
					groups.sort((a, b) => {
						if(a && a.sortName) {
							return (a.sortName).localeCompare(b.sortName); // standard ECMAScript function
						} else {
							return 0; // if 'a.sortName' is empty return equal
						}
					});

					for(var i = 0; i < groups.length; i++){ // sort each group
						groups[i].items.sort((a, b)=>{
							var isManagerA = this.isManager(a);
							var isManagerB = this.isManager(b);
							if(isManagerA && !isManagerB)return -1;
							else if(!isManagerA && isManagerB){
								return 1;
							}
							else { // both are managers or both are not
								if(a && a.sortName) {
									return (a.sortName).localeCompare(b.sortName); // standard ECMAScript function
								} else {
									return 0; // if 'a.sortName' is empty return equal
								}
							}
						});
					}
				}

				// Remove empty groups
				for (var i = groups.length - 1; i >= 0; i--) {
					if (groups[i].items.length == 0) {
						groups.splice(i, 1);
					}
				}
				// If exactly one group - expand by default
				if (groups.length == 1) {
					groups[0].active = true;
				}
				return groups;
			},

			adaptProjectEmployees(json){
				let workersDictionary = {};
				var currentRecord;
				var worker;
				var data;
				// Adapt data
				for(var i = 0; i < json.boardings.length; i++){
					// this.saveInLocalStorageInfo(json.boardings[i]);
					currentRecord = this.adaptRecordData(json.boardings[i]);
					try{
						if(currentRecord != null){
							if(typeof(workersDictionary[json.boardings[i].worker.id.toString()]) === "undefined"){
								worker = json.boardings[i].worker;
								workersDictionary[json.boardings[i].worker.id.toString()] = {
									id: worker.user_profile.id,
									workerId: worker.id,
									managerId: worker.parent,
									rating: (worker.user_profile.rating == null) ? null : worker.user_profile.rating / 2,
									ratingComment: worker.user_profile.rating_comment,
									firstName: worker.user_profile.first_name,
									middleName: worker.user_profile.middle_name,
									lastName: worker.user_profile.last_name,
									mobileNumber: worker.user_profile.personal_mobile_number,
									//hasHome: worker.user_profile.has_home,
									projectIds: [],
									projectsToResignIds: [],
									records: []
								};

								data = workersDictionary[json.boardings[i].worker.id.toString()];

								if(!(data.records.find(i=>i.boardingId==currentRecord.boardingId))){
									data.records.push(currentRecord);
								}

								let projectIds=[]
								let projectsToResignIds= []
								for(let item of worker.all_boardings){
									projectIds.push(item.project_id)
									if(item.boarding_status==3){
										projectsToResignIds.push(item.project_id)
									}
									if(item.boarding_id!=currentRecord.boardingId){
										let newRecord = {
											boardingId: item.boarding_id,
											boardingStatus: item.boarding_status,
											onBoardingDate: item.on_boarding_date? item.on_boarding_date: null,
											offBoardingDate: item.off_boarding_date? item.off_boarding_date: null,
											projectId: item.project_id,
											projectAssignmentId: item.project_assignment_id,
											projectAssignmentReason: item.reason,
										}
										newRecord.id = this.createRecordId(data.workerId, newRecord);
										data.records.push(newRecord)
									}
								}
								data.projectIds=[...new Set(projectIds)];
								data.projectsToResignIds = [...new Set(projectsToResignIds)];
								}


							// data = workersDictionary[json.boardings[i].worker.id.toString()];
							// if(!(data.records.find(i=>i.boardingId==currentRecord.boardingId))){
							// 	data.records.push(currentRecord);
							// }

							// let projectIds=[]
							// for(let item of worker.all_boardings){
							// 	projectIds.push(item.project_id)
							// 	if(item.boarding_id!=currentRecord.boardingId){
							// 		let newRecord = {
							// 			boardingId: item.boarding_id,
							// 			boardingStatus: item.boarding_status,
							// 			onBoardingDate: item.on_boarding_date? item.on_boarding_date: null,
							// 			offBoardingDate: item.off_boarding_date? item.off_boarding_date: null,
							// 			projectId: item.project_id,
							// 			projectAssignmentId: item.project_assignment_id,
							// 			projectAssignmentReason: item.reason,
							// 		}
							// 		newRecord.id = this.createRecordId(data.workerId, newRecord);
							// 		data.records.push(newRecord)
							// 	}
							// }
							// data.projectIds=[...new Set(projectIds)];
						}
					} catch(error) {
						console.error('adaptEmployees: '+i)
						console.log(error.message)
					}
				}
				// Convert data to array
				var result = [];
				for(var index in workersDictionary){
					result.push(workersDictionary[index]);
				}
				return result;
			},

			/**
			 * Filters provided array
			 *
			 * @param {array} adaptedEmployees object adapted to a format accepted by this component
			 * @param {function(object):boolean} filterCondition boolean function, defining whether current item meets filter condition
			 * @returns {array} adaptedData without items that did not meet the filter condition
			 */
			filterEmployees(adaptedEmployees, filterCondition) {
				var shrinkedArray = [];

				for (var i = 0; i < adaptedEmployees.length; i++) {
					if (filterCondition(adaptedEmployees[i])) {
						shrinkedArray.push(adaptedEmployees[i]);
					}
				}

				return shrinkedArray;
			},
			/**
			 * Goes through array of employees and creates a list of avaible names
			 *
			 * @param {array} adaptedEmployees object adapted to a format accepted by this component
			 * @returns {array} array of employees' names
			 */
			getEmployeesNames(adaptedEmployees) {
				var result = [];

				for (var i = 0; i < adaptedEmployees.length; i++) {
					result.push(adaptedEmployees[i].fullName);
				}
				// Remove repetitions (expecting list sorted by names)
				var j;
				for (var i = 0; i < result.length; i++) {
					while (i + 1 < result.length && result[i] == result[i + 1]) {
						result.splice(i + 1, 1);
					}
				}

				return result;
			},

			getProjectsForEditTable(workerIds, condition = null){
				// Retrieving employees
				var employees = [];
				var employee;
				for(var i = 0; i < workerIds.length; i++){
					// employee = this.getSourceEmployeeFromRecordId(recordIds[i]);
					employee = this.getSourceEmployeeFromWorkerId(workerIds[i]);

					if(employees.indexOf(employee) == -1){
						employees.push(employee);
					}
				}

				// Retrieving porjects and marked proejcts for each employee
				var employeesProjects = [];
				var markedEmployeesProjects = [];
				var index;
				var records;
				var record;
				for (var employeeIndex in employees) {
					employee = employees[employeeIndex];
					employeesProjects.push([]);
					for(var i = 0; i < employee.projectIds.length; i++){
						if(condition == null || condition(employee, employee.projectIds[i])){
							employeesProjects[employeesProjects.length - 1].push(employee.projectIds[i]);
						}
					}

					markedEmployeesProjects.push([]);
					index = employeesProjects.length - 1;
					records = this.getSelectedRecordsForWorkerId(employee.workerId);

					for (var recordIndex in records) {
						record = records[recordIndex];
						markedEmployeesProjects[index].push(record.projectId);
					}
				}

				// Retrieving shared assigned projects
				var assignedProjects = this.getSharedProjects(employeesProjects);

				// If there are no projects to display in the first place, don't display anything
				if(assignedProjects.length == 0){
					this.$emit("display-error", this.lview.errorNoProjectsToDisplayTitle, this.lview.errorNoProjectsToDisplay);
					return;
				}

				// Retrieving shared marked projects
				var markedProjects = this.getSharedProjects(markedEmployeesProjects);

				return {
					assignedProjects: assignedProjects,
					markedProjects: markedProjects
				};
			},

			getProjectFromBoardingAssignment(projectAssignments, assignmentId) {
				if (projectAssignments != null) {
					for (var i = 0; i < projectAssignments.length; i++) {
						if (projectAssignments[i].id == assignmentId) {
							return projectAssignments[i].project;
						}
					}
				}
				throw new Error("Project assignment not found (id=" + assignmentId + ")");
			},

			getRecordIndex(records, record) {
				for (var i = 0; i < records.length; i++) {
					if (records[i].id == record.id) {
						return i;
					}
				}
				return -1;
			},

			getSelectedRecordsForWorkerId(workerId) {
				var recordIds = [];
				var result = [];
				var wId;
				var selected;
				for (var i = 0; i < this.editMode.selected.length; i++) {
					selected = this.editMode.selected[i];
					// wId = selected.split('_', 1)[0];
					if (wId == workerId) {
						recordIds.push(selected);
					}
				}
				var employee = this.getSourceEmployeeFromWorkerId(workerId);
				for (var i = 0; i < employee.records.length; i++) {
					if (recordIds.indexOf(employee.records[i].id) != -1) {
						result.push(employee.records[i]);
					}
				}

				return result;
			},

			// create identifier of selected WorkerId with it's status & offboardingdate
			createRecordId(workerId, record) {
				var result = workerId + "_" + ((record.boardingStatus == 3)
					? record.boardingStatus
					: record.boardingStatus + ((record.offBoardingDate != null)
						? (new Date(record.offBoardingDate)).toJSON().slice(0, 10).replace('\/', '\-')
						: "NONE"
					)
				);

				return result;
			},

			getSharedProjects(employeesProjects) {
				if (employeesProjects.length == 0) return [];

				var joined = [];
				var shortest = employeesProjects[0].length;
				var shortestIndex = 0;

				// Finding the shortest array to make it base one
				for (var i = 1; i < employeesProjects.length; i++) {
					if (shortest > employeesProjects[i].length) {
						shortest = employeesProjects[i].length;
						shortestIndex = i;
					}
				}

				var array = employeesProjects[shortestIndex];
				for (var i = 0; i < array.length; i++)
					joined.push(array[i]);

				// Joining arrays
				var projects;
				for (var i = 0; i < employeesProjects.length; i++) {
					if (i != shortestIndex) {
						projects = employeesProjects[i];

						for (var j = 0; j < joined.length; j++) {
							if (projects.indexOf(joined[j]) == -1) {
								joined.splice(j, 1);
								j--;
							}
						}

						// No reason to look anymore
						if (joined.length == 0) break;
					}
				}

				return joined;
			},


			//
			// Requests
			//

			/**
			 * Requests employees data from server
			 *
			 * @param {Integer} managerId Id of manager to send a request for
			 * @returns {Object} Response for the request
			 */
			getEmployees: async function (managerId) {
				var res = await axios.get(localStorage.getItem("current_env") + "/api/v2/boardings/worker-projects-list/" + managerId,
					{
						headers: {
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + localStorage.getItem('jwt')
						},
						params: {
							'boarding_status': '3,7'
						}
					})
					.catch(err => {
						var error_text = err.toString()
						if (err.request) {
							error_text += ': ' + err.request.responseText
						}
						console.error(error_text);
						if(err.message != "Network Error"){
							this.$emit("set-state", "error", this.lmessages.errorOccured);
							this.$emit(
								"display-error",
								this.lmessages.errorOccuredTitle,
								this.lmessages.errorOccured,
								error_text
							);
						}
						return null;
					});
				if(res == null){
					return null;
				}
				return res.data;
			},
			/**
			 * Request for projects dictionary
			 *
			 * @returns {Object} Response for locations dictionary request
			 */
			getProjectsDictionary: async function () {
				var res = null;
				try {
					res = await axios.get(localStorage.getItem("current_env") + "/api/v2/boardings/projects/",
						{
							headers: {
								'Content-Type': 'application/json',
								'Authorization': 'Bearer ' + localStorage.getItem('jwt')
							},
						}
					);
				}
				catch (err) {
					var error_text = err.toString()
					if (err.request) {
						error_text += ': ' + err.request.responseText
					}
					console.error(error_text);
					if(err.message != "Network Error"){
						this.$emit("set-state", "error", this.lmessages.errorOccured);
						this.$emit(
							"display-error",
							this.lmessages.errorOccuredTitle,
							this.lmessages.errorOccured,
							error_text
						);
					}
					return null;
				}
				return res.data;
			},
			getOffBoardingReasons: async function () {
				var res = null;
				try {
					res = await axios.get(localStorage.getItem("current_env") + "/api/v2/boardings/boarding-reasons-types/",
						{
							headers: {
								'Content-Type': 'application/json',
								'Authorization': 'Bearer ' + localStorage.getItem('jwt')
							},
						}
					);
				}
				catch (err) {
					var error_text = err.toString()
					if (err.request) {
						error_text += ': ' + err.request.responseText
					}
					console.error(error_text);
					if(err.message != "Network Error"){
						this.$emit("set-state", "error", this.lmessages.errorOccured);
						this.$emit(
							"display-error",
							this.lmessages.errorOccuredTitle,
							this.lmessages.errorOccured,
							error_text
						);
					}
					return null;
				}
				return res.data;
			},
			getOffBoardingDetails: async function () {
				var res = null;
				try {
					res = await axios.get(localStorage.getItem("current_env") + "/api/v2/boardings/boarding-details-types/",
						{
							headers: {
								'Content-Type': 'application/json',
								'Authorization': 'Bearer ' + localStorage.getItem('jwt')
							},
						}
					);
				}
				catch (err) {
					var error_text = err.toString()
					if (err.request) {
						error_text += ': ' + err.request.responseText
					}
					console.error(error_text);
					if(err.message != "Network Error"){
						this.$emit("set-state", "error", this.lmessages.errorOccured);
						this.$emit(
							"display-error",
							this.lmessages.errorOccuredTitle,
							this.lmessages.errorOccured,
							error_text
						);
					}
					return null;
				}
				return res.data;
			},
			getManagers: async function(managerId){
				var response;
				try{
					response = await axios({
						url: localStorage.getItem("current_env") + "/api/v2/boardings/managers-list/" + managerId,
						method: "GET",
						headers: {
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + localStorage.getItem('jwt'),
						}
					});

				}catch(err){
					var error_text = err.toString()
					if (err.request) {
						error_text += ': ' + err.request.responseText
					}
					console.error(error_text);
					return [];
				}
				return response.data;
			},
			getEmployeesProjects: async function(){
				var response;
				let params = {
					boarding_status: '3,7'
				}
				if(this.selectedStatus!=-1 && this.selectedStatus!=null){
					params.boarding_status=this.selectedStatus
				}
				if(this.searchValue!="" && this.searchValue!= null){
					params.user_profile_search = this.searchValue
				}
				if(this.filters.offBoardingReason){
					params.reason=this.filters.offBoardingReason
				}
				if(this.filters.offBoardingDetails){
					params.details = this.filters.offBoardingDetails.toString()
				}
				try{
					response = await axios({
						url: localStorage.getItem("current_env") + "/api/v2/boardings/employee-projects/",
						method: "GET",
						headers: {
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + localStorage.getItem('jwt'),
						},
						params: params
					});

				}catch(err){
					var error_text = err.toString()
					if (err.request) {
						error_text += ': ' + err.request.responseText
					}
					console.error(error_text);
					return [];
				}
				return response.data;
			},
			getEmployeesProjectsList: async function(loadedPages){
				var response;
				let page = loadedPages + 1;
				let per_page = 10;
				let params={
						'page': page,
						'per_page': per_page,
						'projects': this.openProject
					}
				if(this.selectedStatus!=null && this.selectedStatus!=-1){
					params.boarding_status=this.selectedStatus
				}
				else{
					params.boarding_status='3,7'
				}
				if(this.searchValue!="" && this.searchValue!= null){
					params.user_profile_search = this.searchValue
				}
				if(this.filters.offBoardingReason){
					params.reason=this.filters.offBoardingReason
				}
				if(this.filters.offBoardingDetails){
					params.details = this.filters.offBoardingDetails.toString()
				}
				try{	
					response = await axios({
						url: localStorage.getItem("current_env") + "/api/v2/boardings/employee-projects/assignments/",
						method: "GET",
						headers: {
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + localStorage.getItem('jwt'),
						},
						params: params
					});

				}catch(err){
					var error_text = err.toString()
					if (err.request) {
						error_text += ': ' + err.request.responseText
					}
					console.error(error_text);
					return [];
				}			
				return response.data;
			},
			
			/**
			 * Downloads page data
			 *
			 * @param {Integer} managerId id of manager to download workers for
			 * @returns {Object} Reponses for employees and locations dictionary requests in format { jsonEmployees, jsonProjects }
			 */
			// getPageData: async function (managerId) {
			// 	this.$emit("set-state", "loading");

			// 	var jsonEmployeesPromise = this.getEmployees(managerId);
			// 	var jsonProjectsPromise = this.getProjectsDictionary();
			// 	var jsonManagersPromise = this.getManagers(managerId);

			// 	//New
			// 	var jsonEmployeesProjectsPromise = this.getEmployeesProjects();

			// 	var jsonEmployeesProjects = await jsonEmployeesProjectsPromise
			// 	//New-end

			// 	var jsonEmployees = await jsonEmployeesPromise;
			// 	var jsonProjects = await jsonProjectsPromise;
			// 	var jsonManagers = await jsonManagersPromise;

			// 	this.$emit("set-state", "default");

			// 	return {
			// 		jsonEmployees: jsonEmployees,
			// 		jsonProjects: jsonProjects,
			// 		jsonManagers: jsonManagers,
			// 		//New
			// 		jsonEmployeesProjects: jsonEmployeesProjects,
			// 	};
			// },

			getDictionaries: async function (){
				this.$emit("set-state", "loading");

				var jsonOffBoardingReasonsPromise = this.getOffBoardingReasons();
				var jsonOffBoardingDetailsPromise = this.getOffBoardingDetails();

				var jsonOffBoardingReasons = await jsonOffBoardingReasonsPromise
				var jsonOffBoardingDetails = await jsonOffBoardingDetailsPromise

				this.$emit("set-state", "default");

				return {
					jsonOffBoardingReasons: jsonOffBoardingReasons,
					jsonOffBoardingDetails: jsonOffBoardingDetails,
				};

			},

			//New API
			getPageData: async function () {
				this.$emit("set-state", "loading");

				var jsonProjectsPromise = this.getProjectsDictionary();
				var jsonEmployeesProjectsPromise = this.getEmployeesProjects();
				// var jsonOffBoardingReasonsPromise = this.getOffBoardingReasons();
				// var jsonOffBoardingDetailsPromise = this.getOffBoardingDetails();

				var jsonEmployeesProjects = await jsonEmployeesProjectsPromise
				var jsonProjects = await jsonProjectsPromise;
				// var jsonOffBoardingReasons = await jsonOffBoardingReasonsPromise
				// var jsonOffBoardingDetails = await jsonOffBoardingDetailsPromise

				this.$emit("set-state", "default");

				return {
					jsonProjects: jsonProjects,
					jsonEmployeesProjects: jsonEmployeesProjects,
					// jsonOffBoardingReasons: jsonOffBoardingReasons,
					// jsonOffBoardingDetails: jsonOffBoardingDetails,
				};
			},
			

			//Downloads project employees
			// This method does... Nothing
			getProjectsData: async function(loadedPages) {
				// this.$emit("set-state", "loading");
				// this.$emit("set-state", "LOADING_OVERLAYER");

				var jsonEmployeesProjectsListPromise = this.getEmployeesProjectsList(loadedPages);

				var jsonEmployeesProjectsList = await jsonEmployeesProjectsListPromise

				this.$emit("set-state", "default");

				return {
					jsonEmployeesProjectsList: jsonEmployeesProjectsList
				};
			},



			//
			// Data handling & adapting
			//

			/**
			 * Handles response data from getData
			 *
			 * @param {Object} jsonEmployees Response from getEmployees
			 * @param {Object} jsonProjects Response from getProjectsDictionary
			 */
			handleData(jsonProjects, jsonEmployeesProjects) {
				// if(jsonEmployees != null){
				// 	var adaptedData = this.adaptEmployees(jsonEmployees);
				// 	this.sourceData = adaptedData;

				// }

				// if(jsonManagers != null){
				// 	this.managersData = this.adaptManagers(jsonManagers);
				// 	this.managersData.sort((a, b)=>{
				// 		if(a.managerId < b.managerId) return -1;
				// 		else return 1;
				// 	});
				// }

				//NEW - Adapting projects with boardings
				if(jsonProjects!= null && jsonEmployeesProjects!=null){
					this.projectsDictionary=this.adaptEmployeesProjects(jsonProjects, jsonEmployeesProjects)
				}


				// manager projects dictionary
				// var myWorker = JSON.parse(localStorage.getItem('workers'))[0];
				// var manager = this.getSourceManager(myWorker.id);
				/*
				if (manager == null) {
					// TODO: go back to this error
					//this.$emit("display-error", this.lview.errorLocalDataInconsistencyTitle, this.lview.errorLocalDataInconsistencyMessage);
				}
				else {
					var projects = [];
					for (var i = 0; i < manager.projectIds.length; i++) {
						projects.push({
							id: manager.projectIds[i],
							name: this.getProjectName(manager.projectIds[i])
						});
					}
				}
				*/

				this.assignSelectedFromCache();
				this.verifySelection();

				this.updateProjectListDisplay();

				this.updateEmployeeListDisplay();
			},

			handleDictionariesData(jsonOffBoardingReasons, jsonOffBoardingDetails){
				this.offBoardingReasonsDictionary= this.adaptOffBoardingReasonsResponse(jsonOffBoardingReasons)
				this.offBoardingDetailsDictionary= this.adaptOffBoardingReasonsResponse(jsonOffBoardingDetails)
			},

			handleProjectData(jsonEmployeesProjectsList, projectId) {
				if(jsonEmployeesProjectsList){
					var projectItems= this.adaptProjectEmployees(jsonEmployeesProjectsList)
					if(!this.sourceData){
						this.sourceData=[]
					}
					this.updateSourceData(projectItems)
					this.updateProjectItemsListDisplay(projectItems, projectId)

				}

				// this.assignSelectedFromCache();
				// this.verifySelection();

				// this.updateProjectListDisplay();

				// this.updateEmployeeListDisplay();
			},

			updateSourceData(data){
				if(!this.sourceData){
					this.sourceData=[]
				}
				if(this.sourceData.length==0){
					this.sourceData = this.sourceData.concat(data)
				}
				else{
					for(let worker of data){
						let existingWorker=this.sourceData.find(i=> i.id==worker.id)
						if(existingWorker){
							existingWorker.firstName=worker.firstName
							existingWorker.lastName=worker.lastName
							existingWorker.rating=worker.rating
							existingWorker.managerId=worker.managerId
							existingWorker.mobileNumber=worker.mobileNumber
							existingWorker.ratingComment=worker.ratingComment
							existingWorker.projectIds.splice(0, existingWorker.projectIds.length)
							existingWorker.projectIds=Object.assign([], worker.projectIds )
							existingWorker.projectsToResignIds.splice(0, existingWorker.projectsToResignIds.length)
							existingWorker.projectsToResignIds=Object.assign([], worker.projectsToResignIds )

							//update worker boarding list
							for(let record of worker.records){
								// let idx = existingWorker.records.findIndex(i => i.boardingId==record.boardingId)
								let idx = existingWorker.records.findIndex(i => i.projectAssignmentId==record.projectAssignmentId)
								if(idx!=-1){
									existingWorker.records.splice(idx, 1)
								}
								existingWorker.records.push(record)
							}
						}
						else{
							this.sourceData.push(worker)
						}
					}
				}
			},

			getRelevantBoardings(boardings) {
				var result = [];

				for (var i = 0; i < boardings.length; i++) {
					// if boarding is (3, 'Hired') or (7, 'Off-Boarding')
					if (boardings[i].boarding_status == 3 || boardings[i].boarding_status == 7) {
						result.push(boardings[i]);
					}
				}

				return result;
			},

			getProjectIds(projectAssignments) {
				var projectIds = [];
				if (projectAssignments != null) {
					for (var i = 0; i < projectAssignments.length; i++) {
						projectIds.push((projectAssignments[i].project == null) ? -1 : projectAssignments[i].project.id);
					}
				}
				return projectIds;
			},

			adaptOffBoardingReasonsResponse(data){
				let offboardingTypes = []
				if(data){
					for(let item of data){
					offboardingTypes.push({
						id: item.id,
						name: item.choice_name
					})
					}
				}
				return offboardingTypes
			},

			adaptManager(json) {
				if (json.management_role == null) {
					return {
						managers: [],
						workers: []
					};
				}

				var worker;
				var manager;
				var nestedResponse;

				manager = {
					managerId: json.id,
					firstName: json.user_profile.first_name,
					middleName: json.user_profile.middle_name,
					lastName: json.user_profile.last_name,
					mobileNumber: json.user_profile.personal_mobile_number,
					projectIds: this.getProjectIds(json.project_assignments)
				};
				var managers = [manager];
				var workers = [];
				var record;

				if (json.workers != null) {
					var tmpWorker;
					for (var i = 0; i < json.workers.length; i++) {
						worker = json.workers[i];

						var boarding;
						var index;

						if (worker.boardings != null) {
							var boardings = this.getRelevantBoardings(worker.boardings);
							var records = [];
							var record;
							for (var j = 0; j < boardings.length; j++) {
								boarding = boardings[j];

								var project = this.getProjectFromBoardingAssignment(worker.project_assignments, boarding.project_assignment);

								/*record = {
									id: (boarding.boarding_status == 3)
										? worker.id + "_EMPLOYED"
										: worker.id + "_OFF_BOARD_" + ( (boarding.off_boarding_date == null)
											? "NONE"
											: boarding.off_boarding_date.slice(0, 10).replace('/', '-')
										),

									offBoardingDate: (boarding.boarding_status == 3)
										? null
										: Date(boarding.off_boarding_date),

									projectIds: [ (project == null) ? -1 : project.id ],
									resigning: boarding.boarding_status != 3
								};

								index = this.getRecordIndex(records, record);
								if(index == -1){
									records.push(record);
								}
								else{
									records[index].projectIds.push(record.projectIds[0]);
								}*/
								record = {
									id: null,
									offBoardingDate: (boarding.boarding_status == 3)
										? null
										: new Date(boarding.off_boarding_date),
									boardingStatus: boarding.boarding_status,
									projectId: (project != null) ? project.id : -1
								};
								record.id = this.createRecordId(worker.id, record);
								records.push(record);
							}

							tmpWorker = {
								id: worker.user_profile.id,
								workerId: worker.id,
								rating: (worker.user_profile.rating == null) ? null : worker.user_profile.rating / 2,
								ratingComment: worker.user_profile.ratingComment,
								firstName: worker.user_profile.first_name,
								middleName: worker.user_profile.middle_name,
								lastName: worker.user_profile.last_name,
								mobileNumber: worker.user_profile.personal_mobile_number,
								hasHome: worker.user_profile.contact_postal_address != null && worker.user_profile.contact_postal_address != "None",
								projectIds: [],
								managerId: manager.managerId,
								records: []
							};

							for (var j = 0; j < records.length; j++) {
								tmpWorker.projectIds = tmpWorker.projectIds.concat(records[j].projectId);
								tmpWorker.records.push(records[j]);
							}

							workers.push(tmpWorker);
						}
						if (worker.workers != null && Array.isArray(worker.workers)) {
							nestedResponse = this.adaptManager(worker);

							managers = managers.concat(nestedResponse.managers);
							workers = workers.concat(nestedResponse.workers);
						}
					}
				}

				return {
					managers: managers,
					workers: workers
				};
			},

			adaptRecordData(boarding){
				//if(boarding.archived == true) return null;

				var result = {
					boardingId: boarding.id,
					projectId: 
						(boarding.project_assignment.project == null)
						? -1
						: boarding.project_assignment.project.id
					,

					boardingStatus: boarding.boarding_status,

					onBoardingDate: new Date(boarding.on_boarding_date),

					offBoardingDate:
						(boarding.off_boarding_date == null)
						? null
						: new Date(boarding.off_boarding_date)
					,
					projectAssignmentId: boarding.project_assignment.id,
					projectAssignmentReason: boarding.project_assignment.reason,

					id: null,
				}

				result.id = this.createRecordId(boarding.worker.id, result);

				return result;
			},
			/**
			 * Adapter for api response
			 * Adapts the response to the component
			 *
			 * @param {object} json response from server (JSON object)
			 * @returns {array} objects adapted to a format accepted by this component
			 */
			// adaptEmployees(json) {
			// 	var workersDictionary = {};
			// 	var currentRecord;
			// 	var worker;
			// 	var data;

			// 	// Adapt data
			// 	for(var i = 0; i < json.boardings.length; i++){
			// 		this.saveInLocalStorageInfo(json.boardings[i]);
			// 		currentRecord = this.adaptRecordData(json.boardings[i]);
			// 		try{
			// 			if(currentRecord != null){
			// 				if(typeof(workersDictionary[json.boardings[i].worker.id.toString()]) === "undefined"){
			// 					worker = json.boardings[i].worker;
			// 					workersDictionary[json.boardings[i].worker.id.toString()] = {
			// 						id: worker.user_profile.id,
			// 						workerId: worker.id,
			// 						managerId: worker.parent,
			// 						rating: (worker.user_profile.rating == null) ? null : worker.user_profile.rating / 2,
			// 						ratingComment: worker.user_profile.rating_comment,
			// 						firstName: worker.user_profile.first_name,
			// 						middleName: worker.user_profile.middle_name,
			// 						lastName: worker.user_profile.last_name,
			// 						mobileNumber: worker.user_profile.personal_mobile_number,
			// 						//hasHome: worker.user_profile.has_home,
			// 						projectIds: [],

			// 						records: []
			// 					};
			// 				}

			// 				data = workersDictionary[json.boardings[i].worker.id.toString()];
			// 				data.records.push(currentRecord);
			// 				data.projectIds.push(currentRecord.projectId);
			// 			}
			// 		} catch(error) {
			// 			console.error('adaptEmployees: '+i)
			// 		}
			// 	}

			// 	// Convert data to array
			// 	var result = [];
			// 	for(var index in workersDictionary){
			// 		result.push(workersDictionary[index]);
			// 	}

			// 	return result;

			// 	/*
			// 		var manager;
			// 		var workers = [];
			// 		var managers = [];
			// 		var adaptedManager;

			// 		for (var i = 0; i < json.tree.length; i++) {
			// 			manager = json.tree[i];

			// 			adaptedManager = this.adaptManager(manager);
			// 			managers = managers.concat(adaptedManager.managers);
			// 			workers = workers.concat(adaptedManager.workers);
			// 		}

			// 		return {
			// 			managers: managers,
			// 			workers: workers
			// 		};
			// 	*/
			// },

			/**
			 * Adapter for api response
			 * Adapts the response to the component
			 * @param {object} json response from server (JSON object)
			 * @returns {array} objects adapted to a format accepted by this component
			 */
			adaptProjects(json) {
				var result = [];

				for (var i = 0; i < json.projects.length; i++) {
					result.push({
						id: json.projects[i].id,
						name: json.projects[i].name
					});
				}

				result.sort((a, b) => {
					if(a && a.name) {
						return (a.name).localeCompare(b.name); // standard ECMAScript function
					} else {
						return 0; // if 'a' is empty return equal
					}
				});

				return result;
			},

			adaptEmployeesProjects(jsonProjects, jsonEmployeesProjects) {
				var result = [];

				for(let i=0;i<jsonEmployeesProjects.length;i++){
					let item=jsonEmployeesProjects[i]
					let project = jsonProjects.projects.find(p => p.id==item.id)
					result.push({
						id: project.id,
						name: project.name,
						workersNumber: item.workers_num
					});
				}

				result.sort((a, b) => {
					if(a && a.name) {
						return (a.name).localeCompare(b.name); // standard ECMAScript function
					} else {
						return 0; // if 'a' is empty return equal
					}
				});

				return result;
			},

			adaptManagers(json){
				var result = [];
				var worker;
				var sortName;

				for(var i = 0; i < json.workers.length; i++){
					worker = json.workers[i];

					sortName = (worker.user_profile.middle_name == null)
						? worker.user_profile.last_name + " " + worker.user_profile.first_name
						: worker.user_profile.last_name + " " + worker.user_profile.middle_name + " " + worker.user_profile.first_name;

					result.push({
						managerId: worker.id,
						firstName: worker.user_profile.first_name,
						middleName: worker.user_profile.middle_name,
						lastName: worker.user_profile.last_name,
						mobileNumber: worker.user_profile.personal_mobile_number,
						sortName: sortName
					});
				}

				var myWorkers = JSON.parse(localStorage.getItem("workers"));
				worker = myWorkers[0];
				var firstName = localStorage.getItem("user_profile_first_name");
				var middleName = localStorage.getItem("user_profile_middle_name");
				var lastName = localStorage.getItem("user_profile_last_name");
				var mobileNumber = localStorage.getItem("personal_mobile_number");
				if(middleName != 'null'){
					sortName = lastName + " " + middleName + " " + firstName;
				}
				else{
					sortName = lastName + " " + firstName;
				}
				result.push({
					managerId: worker.id,
					firstName: firstName,
					middleName: (middleName == 'null') ? null : middleName,
					lastName: lastName,
					mobileNumber: mobileNumber,
					sortName: sortName
				});

				result.sort((a, b) => {
					if(a && a.sortName) {
						return (a.sortName).localeCompare(b.sortName); // standard ECMAScript function
					} else {
						return 0; // if 'a.sortName' is empty return equal
					}
				});

				return result;
			},

			/**
			 * @param {Object} item - item for which we want to find index in array
			 * @param {Object} array - array in which we're searching for index of item
			 */
			findProjectItemInArray(item, array){
				for(var i = 0; i < array.length; i++){
					// protection in case there is no project assigned to worker yet (can be the case rarely)
					if(array[i].project == null || item.project == null) {
						return -1;
					}
					// compare project IDs
					if(array[i].project.id == item.project.id){
						return i;
					}
				}
				return -1;
			},

			saveInLocalStorageInfo(json){
				var keys = Object.keys(localStorage);
				// remove all existing worker records, new ones will appear soon
				for(var i = 0; i < keys.length; i++) {
					if (keys[i].includes("_workerData")) {
						localStorage.removeItem(keys[i]);
					}
				}

				try {
					var id = json.worker.id;
					var project_assignment = json.project_assignment;

					var workerData = {
						firstName : json.worker.user_profile.first_name,
						middleName : json.worker.user_profile.middle_name,
						lastName : json.worker.user_profile.last_name,
						phoneNumber : json.worker.user_profile.personal_mobile_number,
						worker_assignments : []
					};

					var data = JSON.parse(localStorage.getItem(id + "_workerData"));
					if(data != null){
						var containsItem = this.findProjectItemInArray(project_assignment, data.worker_assignments);
						if(containsItem == -1){
							data.worker_assignments.push(project_assignment);
						}
						localStorage.setItem(id + "_workerData", JSON.stringify(data));
					}
					else{
						workerData.worker_assignments.push(project_assignment);
						localStorage.setItem(id + "_workerData", JSON.stringify(workerData));
					}
				}catch(error){
					console.error('saveInLocalStorageInfo');
					console.log(error);
					//throw error;
				}
			},


			//
			// Updating view
			//

			updateGrouper(newValue) {
				this.sortConditionId = newValue;
				this.currentGrouper = newValue;
				this.refreshEmployees();
			},

			/**
			 * Checks whether concrete worker is manager or not
			 * Check uses an iterative binary search on sorted managersData vector - managersData is sorted after download
			 * https://www.geeksforgeeks.org/binary-search-in-javascript/
			 */
			isManager(worker){
				var id = worker.id;
				if(id == undefined || id == null) return false;
				else{
					var p = 0; // start
					var k = this.managersData.length - 1; // end
					// Iterate while start not meets end
					while(p <= k){
						// Find the mid index
						var s = Math.floor((k + p)/2);

						// If element is present at mid, return True 
						if(this.managersData[s].managerId == id){
							return true;
						
						// Else look in left or right half accordingly
						} else if(this.managersData[s].managerId < id){
							p = s + 1;
						} else {
							k = s - 1;
						}
					}
					return false;
				}
			},

			/**
			 * Refreshes employees list
			 */
			refreshEmployees() {
				var filteredData = this.filterEmployees(this.listData, this.currentCondition);
				var groupedData = this.groupEmployees(filteredData);
				this.employeesListItems = groupedData;
			},
			getProjectsDisplayText(projectIds) {
				var name;
				var text;
				var names = [];
				for (var i = 0; i < projectIds.length; i++) {
					if (projectIds[i] == -1) {
						name = this.lmessages.unassigned;
					}
					else {
						name = this.getProjectName(projectIds[i]);
						if (name == null) {
							name = this.lmessages.unassigned;
						}
					}

					names.push({
						name: name,
						sortName: (projectIds[i] == -1) ? "#" : name
					});
				}
				names.sort((a, b) => {
					if(a && a.sortName) {
						return (a.sortName).localeCompare(b.sortName); // standard ECMAScript function
					} else {
						return 0; // if 'a.sortName' is empty return equal
					}
				});
				for (var i = 0; i < names.length; i++) {
					if (i == 0) text = names[i].name;
					else text += (", " + names[i].name);
				}
				return text;
			},
			getListRecords(workerId, sourceRecords) {
				var result = [];
				var recordIds = [];
				var record;
				var index;

				for (var i = 0; i < sourceRecords.length; i++) {
					record = {
						id: sourceRecords[i].id,
						boardingId: sourceRecords[i].boardingId,
						onBoardingDate: sourceRecords[i].onBoardingDate,
						offBoardingDate: sourceRecords[i].offBoardingDate,
						projectIds: [sourceRecords[i].projectId],
						projectId: sourceRecords[i].projectId,
						resigning: sourceRecords[i].boardingStatus == 7,
						reason: sourceRecords[i].projectAssignmentReason
					};

					// if ((index = recordIds.indexOf(record.id)) != -1) {
					if ((index = recordIds.indexOf(record.boardingId)) != -1) {
						result[index].projectIds.push(record.projectIds[0]);
					}
					else {
						result.push(record);
						recordIds.push(record.boardingId);
					}
				}

				return result;
			},
			/**
			 * Updates employees list
			 */
			updateEmployeeListDisplay() {
				this.listData.splice(0, this.listData.length);
				var person;
				var fullName;
				var sortName;

				for (var i = 0; i < this.sourceData.length; i++) {
					person = this.sourceData[i];
					// Assigning full name
					if (person.middleName != null)
						fullName = person.lastName + " " + person.firstName + " " + person.middleName;
					else
						fullName = person.lastName + " " + person.firstName;

					// Sorting can be different (was in the past and might be in the future)
					if (person.middleName != null)
						sortName = person.lastName + " " + person.firstName + " " + person.middleName;
					else
						sortName = person.lastName + ' ' + person.firstName;

					// Offboarding date
					var today = new Date();
					today.setHours(0, 0, 0, 0);
					var offDate;
					var offDateText;
					var dateDiff;
					var record;
					var records = this.getListRecords(person.workerId, person.records);

					// Each person might have multiple records to display if they have multiple boardings that differ between each other
					for (var j = 0; j < records.length; j++) {
						record = records[j];
						if (record.offBoardingDate != null){
							offDate = new Date(record.offBoardingDate);
							dateDiff = Math.floor((offDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));

							if (dateDiff == 0)
								offDateText = this.lview.today;
							else if (Math.abs(dateDiff) == 1) {
								if (dateDiff < 0)
									offDateText = this.lview.yesterday;
								else
									offDateText = this.lview.tomorrow;
							}
							else if (offDate.getFullYear() == today.getFullYear()) {
								offDateText = offDate.getDate() + " " + this.monthNumberToText(offDate.getMonth());
							}
							else
								offDateText = offDate.getDate() + " " + this.monthNumberToText(offDate.getMonth()) + " " + offDate.getFullYear();
						}
						else {
							dateDiff = 0;
							offDate = null;
							offDateText = null;
						}
						var data = {
							id: person.workerId,//person.id,
							fullName: fullName,
							sortName: sortName,
							initials: person.lastName[0] + person.firstName[0],
							mobileNumber: person.mobileNumber,
							projectIds: record.projectIds,
							projectsDisplayText: this.getProjectsDisplayText(record.projectIds),
							managerId: person.managerId,
							resigning: record.resigning,
							hasHome: person.hasHome,
							offBoardingDate: offDate,
							offBoardingDateText: offDateText,
							outdated: (dateDiff < 0),
							offBoardingDateDiff: dateDiff,
							recordId: record.id,
						};
						this.listData.push(data);
					}
				}

				this.listData.sort((a, b) => {
					if(a && a.sortName) {
						return (a.sortName).localeCompare(b.sortName); // standard ECMAScript function
					} else {
						return 0; // if 'a.sortName' is empty return equal
					}
				});

				this.refreshEmployees();
			},

			updateProjectItemsListDisplay(items, projectId) {
				var person;
				var fullName;
				var sortName;
				var projectItems=[];
				var resignReason;
				for (var i = 0; i < items.length; i++) {
					person = items[i];
					// Assigning full name
					if (person.middleName != null)
						fullName = person.lastName + " " + person.firstName + " " + person.middleName;
					else
						fullName = person.lastName + " " + person.firstName;

					// Sorting can be different (was in the past and might be in the future)
					if (person.middleName != null)
						sortName = person.lastName + " " + person.firstName + " " + person.middleName;
					else
						sortName = person.lastName + ' ' + person.firstName;

					// Offboarding date
					var today = new Date();
					today.setHours(0, 0, 0, 0);
					var onDate;
					var onDateText;
					var boardingTimeText;
					var offDate;
					var offDateText;
					var dateDiff;
					var record;
					var records = this.getListRecords(person.workerId, person.records);
					var active;
					
					let projectRecords=records.filter(i=> i.projectId==projectId)

					// Each person might have multiple records to display if they have multiple boardings that differ between each other
					for (var j = 0; j < projectRecords.length; j++) {
						record = projectRecords[j];
						if (record.offBoardingDate != null){
							offDate = new Date(record.offBoardingDate);
							dateDiff = Math.floor((offDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));

							if(dateDiff<0){
								active=false
							}
							else{
								active=true
							}

							if (dateDiff == 0)
								offDateText = this.lview.today;
							else if (Math.abs(dateDiff) == 1) {
								if (dateDiff < 0)
									offDateText = this.lview.yesterday;
								else
									offDateText = this.lview.tomorrow;
							}
							else if (offDate.getFullYear() == today.getFullYear()) {
								offDateText = offDate.getDate() + " " + this.monthNumberToText(offDate.getMonth());
							}
							else
								offDateText = offDate.getDate() + " " + this.monthNumberToText(offDate.getMonth()) + " " + offDate.getFullYear();
						}
						else {
							dateDiff = 0;
							offDate = null;
							offDateText = null;
							active=true
						}

						if(record.onBoardingDate != null){
							onDate=record.onBoardingDate
							boardingTimeText="Since " + this.dateToString(onDate)
							resignReason=record.reason
						}
						else{
							onDate=null
						}
						if(offDate!=null){
							boardingTimeText+=" to "
							boardingTimeText+=this.dateToString(offDate)
						}
						var data = {
							id: person.workerId, //person.id,
							fullName: fullName,
							sortName: sortName,
							initials: person.lastName[0] + person.firstName[0],
							mobileNumber: person.mobileNumber,
							projectIds: record.projectIds,
							// projectsDisplayText: this.getProjectsDisplayText(record.projectIds),
							managerId: person.managerId,
							resigning: record.resigning,
							active: active,
							hasHome: person.hasHome,
							offBoardingDate: offDate,
							offBoardingDateText: offDateText,
							boardingTimeText: boardingTimeText,
							outdated: (dateDiff < 0),
							offBoardingDateDiff: dateDiff,
							recordId: record.id,
							boardingId: record.boardingId,
							resignReason: this.getReasonName(resignReason)
						};
						projectItems.push(data);
					}
				}

				projectItems.sort((a, b) => {
					if(a && a.sortName) {
						return (a.sortName).localeCompare(b.sortName); // standard ECMAScript function
					} else {
						return 0; // if 'a.sortName' is empty return equal
					}
				});
				this.assignItemsToProjectGroup(projectItems, projectId)
			},

			updateProjectListDisplay() {
				let groups=[]

				for(let i=0;i<this.projectsDictionary.length;i++){
					groups.push({
						id: this.projectsDictionary[i].id,
						name: this.projectsDictionary[i].name,
						title: this.projectsDictionary[i].name+" ("+this.projectsDictionary[i].workersNumber+")",
						active: false,
						items: [],
						itemsRequested: false,
						totalItems: this.projectsDictionary[i].workersNumber,
						loadedPages: 0,
						loadedItems: 0,
						requestedItems: (this.projectsDictionary[i].workersNumber > 10) ? 10 : this.projectsDictionary[i].workersNumber
					})
				}
				if(groups.length==1){
					groups[0].active = true;
					this.setProjectData(groups[0])
				}

				this.listProjectItems=groups;
			},

			loadMoreData() {
				window.onscroll = () => {
					let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
					let project = this.listProjectItems.find(item =>item.id==this.openProject)
					if(project){
						let openProjectElement = document.getElementById("Group"+project.id)
						if(openProjectElement){
							bottomOfWindow=(openProjectElement.offsetHeight - document.documentElement.scrollTop) < window.innerHeight;
						}
					}
					// let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
					if (bottomOfWindow && !this.requestSent) {
						this.updateOpenProjectData();
					}
				}
			},

			loadMoreDataIfBottomOfWindow(projectId){
				let openProjectElement = document.getElementById("Group"+projectId)
				let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
				if(openProjectElement){
					bottomOfWindow = (openProjectElement.offsetHeight - document.documentElement.scrollTop) < window.innerHeight;
				}
				if (bottomOfWindow && !this.requestSent) {
					this.updateOpenProjectData();
				}
			},

			assignItemsToProjectGroup(items, id){
				let project = this.listProjectItems.find(item =>item.id==id)
				if(typeof(project) !== 'undefined' && project){
					for(let item of items){
						let idx=project.items.findIndex(i=>i.boardingId==item.boardingId)
						if(idx==-1){
							project.items.push(item)
						}
					}
					// project.items=project.items.concat(items)
					project.loadedItems=(project.loadedItems+10)<project.totalItems? project.loadedItems+10: project.totalItems;
					project.loadedPages++
				}
			},

			/**
			 * @param employee {Object} from employeesListItems
			 * @return {int} 1 or 2 
			 * returns 0 if employee is neither nearly outdated nor outdated
			 * returns 1 if employee is nearly outdated
			 * returns 2 if employee is outdated
			 */
			getEmployeeAvatarType(employee){
				let offBoardingDate = employee.offBoardingDate;
				if(offBoardingDate != null){
					let today = new Date();
					let dateDiff = (offBoardingDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24) ;
					
					if(dateDiff < 7 && dateDiff > 0)return 1;
					else if(dateDiff <= 0) return 2;
					else return 0;
				}
				else{
					return 0;
				}
			},

			/**
			 * Converts month number to its text representation
			 *
			 * @param {integer} monthNumber number of month from 0 to 11
			 * @returns {string} text representation of the month
			 */
			monthNumberToText(monthNumber) {
				var months = [
					"sty",
					"lut",
					"mar",
					"kwi",
					"maj",
					"cze",
					"lip",
					"sie",
					"wrz",
					"paź",
					"lis",
					"gru"
				];

				return months[monthNumber];
			},

			//converts date to DD.MM.YYYY format
			dateToString(date){
			if(!date){
				return ''
			}
			date=new Date(date)

			let month = date.getMonth()+1
			if(month<10){
				month = "0"+month
			}
			let day = date.getDate()
			if(day<10){
				day = "0"+day
			}
			return day+"."+month+"."+date.getFullYear()
		},


			// ------
			// EVENTS
			// ------

			// on avatar sign click - marks/unmarks record
			onAvatarClick(item){
				if (!this.canClickAvatar) return;

				var index;
				if((index = this.editMode.selected.indexOf(item.id)) != -1){
					this.editMode.selected.splice(index, 1);
					this.verifySelection();

					if(this.editMode.selected.length == 0){
						this.finishEditing();
					}
				}
				else{
					if(this.editMode.selected.length == 0){
						this.mode = "edit";
						this.$store.commit('updateMarkedRecordFlag', {isMarked: true});
					}
					this.editMode.selected.push(item.id);

					// var worker = this.getSourceEmployeeFromRecordId(item.id);
					var worker = this.getSourceEmployeeFromWorkerId(item.id)

					if(!this.workerIsValidForEditing(worker)){
						this.$emit(
							'display-alert',

							this.lview.alertWorkerNotValidForEditing,
							this.$t('views.onBoardingPM.alertWorkerNotValidForEditingDetails', {
								worker: worker.lastName + " " + worker.firstName
							}),

							undefined,
							"warning"
						);
					}

					this.verifySelection();
				}
			},

			closeOtherProjectGroups(projectId){
				this.openProject=projectId
				for(let item of this.listProjectItems){
					if(item.id!=projectId){
						item.active=false
					}
				}
			},

			//downloads and sets data to selected project
			setProjectData: function(project){
				this.requestSent=true
				var that=this

				this.closeOtherProjectGroups(project.id)
				if(project.loadedItems==0 && !project.itemsRequested){
					project.itemsRequested = true;
					var that = this;
					this.getProjectsData(project.loadedPages)
						.then(async function(response) { 
							that.handleProjectData(response.jsonEmployeesProjectsList, project.id); 
							project.itemsRequested = false;
							project.totalItems=response.jsonEmployeesProjectsList.total
							project.title=project.name+" ("+project.totalItems+")"
							that.requestSent=false

							setTimeout(function(){
								that.loadMoreDataIfBottomOfWindow(project.id);
							}, 300);

						})

						.catch(err => {
							project.itemsRequested = false;
							var error_text = err.toString()
							if (err.request) {
								error_text += ': ' + err.request.responseText
							}
							console.error(error_text);
							if(err.message == "Network Error"){
								this.offlineNotifier = true;
							}
							else{
								this.$emit("set-state", "error", this.lmessages.errorOccured);
								this.$emit('display-error', this.lmessages.errorOccuredTitle, this.lmessages.errorOccured, error_text);
							}
						});
				}
			},

			updateOpenProjectData: async function(){
				let project = this.listProjectItems.find(item =>item.id==this.openProject)
				if(project && !project.itemsRequested){
					if(project.loadedItems<project.totalItems){
						project.itemsRequested = true;
						// this.getEmployeesProjectsList(project.loadedItems)

						project.requestedItems = project.loadedItems + 10;
						if(project.requestedItems > project.totalItems){
							project.requestedItems = project.totalItems;
						}

						var that = this;
						await this.getProjectsData(project.loadedPages)
						// For god's sake there is NO REASON for all this to be in a single line!
						.then(async function(response) { 
							that.handleProjectData(response.jsonEmployeesProjectsList, project.id);
							project.itemsRequested = false;
							project.totalItems=response.jsonEmployeesProjectsList.total
							project.title=project.name+" ("+project.totalItems+")"
							
							setTimeout(function(){
								that.loadMoreDataIfBottomOfWindow(project.id);
							}, 300);
						})
						.catch(err => {
							project.itemsRequested = false;
							project.requestedItems = project.loadedItems;
							var error_text = err.toString()
							if (err.request) {
								error_text += ': ' + err.request.responseText
							}
							console.error(error_text);
							if(err.message == "Network Error"){
								this.offlineNotifier = true;
							}
							else{
								this.$emit("set-state", "error", this.lmessages.errorOccured);
								this.$emit('display-error', this.lmessages.errorOccuredTitle, this.lmessages.errorOccured, error_text);
							}
						});
					}
				}
			},

			// redirecting to UserProfile
			redirectToUserProfile(itemId){
				var employee = this.getSourceEmployeeFromRecordId(itemId);
					// alert(JSON.stringify(employee));
				if (employee != null)
					this.$router.push('/user-profile/' + employee.workerId);
				else
					this.$emit('display-error', this.lview.errorInvalidWorkerTitle, this.lview.errorInvalidWorkerMessage);
			},

			// verifySelectionConsistency(){
			// 	if(this.editMode.selected.length > 0){
			// 		var status = this.getSourceRecordFromRecordId(this.editMode.selected[0]).boardingStatus;
			// 		var consistent = true;

			// 		for(var i = 1; i < this.editMode.selected.length; i++){
			// 			if(status != this.getSourceRecordFromRecordId(this.editMode.selected[i]).boardingStatus){
			// 				consistent = false;
			// 				break;
			// 			}
			// 		}

			// 		this.editMode.consistentStatus = consistent;
			// 		// this.editMode.selectedStatus = (consistent) ? status : null;
			// 	}
			// },

			// getSourceRecordFromRecordId(recordId){
			// 	var worker = this.getSourceEmployeeFromRecordId(recordId);
			// 	var records = worker.records;
			// 	for(var i = 0; i < records.length; i++){
			// 		if(records[i].id == recordId){
			// 			return records[i];
			// 		}
			// 	}
			// 	return null;
			// },
			getSourceRecordFromWorkerId(workerId){
				var worker = this.getSourceEmployeeFromWorkerId(workerId)
				var records = worker.records;
				for(var i = 0; i < records.length; i++){
					if(records[i].id == recordId){
						return records[i];
					}
				}
				return null;
			},
			// getSourceRecordsFromRecordId(recordId){
			// 	var result = [];
			// 	var worker = this.getSourceEmployeeFromRecordId(recordId);
			// 	var records = worker.records;
			// 	for(var i = 0; i < records.length; i++){
			// 		if(records[i].id == recordId){
			// 			result.push(records[i]);
			// 		}
			// 	}
			// 	return result;
			// },
			getSourceRecordsFromWorkerId(workerId){
				var result = [];
				var worker = this.getSourceEmployeeFromWorkerId(workerId);
				var records = worker.records;
				for(var i = 0; i < records.length; i++){
					// if(records[i].id == recordId){
						result.push(records[i]);
					// }
				}
				return result;
			},

			onSubItemHoldStart(id) {
				if (this.holdItemDownTimeout != false) {
					clearTimeout(this.holdItemDownTimeout);
				}

				this.holdItemDownTimeout = setTimeout(this.subItemHoldTimeout, 650);
				this.heldElementId = id;
			},
			subItemHoldTimeout() {
				var index;
				if ((index = this.editMode.selected.indexOf(this.heldElementId)) != -1) {
					this.editMode.selected.splice(index, 1);
					if (this.editMode.selected.length == 0) {
						this.onSubItemHoldStop(this.heldElementId);
						this.$store.commit('updateMarkedRecordFlag', {isMarked: false});
						this.mode = 'default';
						return;
					}
				}
				else {
					this.editMode.selected.push(this.heldElementId);
					this.verifySelection();
					// var worker = this.getSourceEmployeeFromRecordId(this.heldElementId);
					var worker = this.getSourceEmployeeFromWorkerId(this.heldElementId);

					if(!this.workerIsValidForEditing(worker)){
						this.$emit(
							'display-alert',

							this.lview.alertWorkerNotValidForEditing,
							this.$t('views.employeesPM.alertWorkerNotValidForEditingDetails', {
								worker: worker.lastName + " " + worker.firstName
							}),

							undefined,
							"warning"
						);
					}
				}
				this.onSubItemHoldStop(this.heldElementId);
				if (this.mode != 'edit'){
					this.$store.commit('updateMarkedRecordFlag', {isMarked: true});
					this.mode = 'edit';
				}
			},
			onSubItemHoldStop(id) {
				if (this.heldElementId == id && this.holdItemDownTimeout != false) {
					clearTimeout(this.holdItemDownTimeout);
					this.holdItemDownTimeout = false;
					this.heldElementId = null;
				}
			},
			onScroll() {
				this.onSubItemHoldStop(this.defaultMode.heldElementId);
			},

			onSubItemClick(itemId) {
				if (this.mode == "edit") {
					var index;
					if ((index = this.editMode.selected.indexOf(itemId)) != -1) {
						this.editMode.selected.splice(index, 1);
						if (this.editMode.selected.length == 0) {
							this.$store.commit('updateMarkedRecordFlag', {isMarked: false});
							this.mode = 'default';
							return;
						}
					}
					this.verifySelection();
				}
				else {
					// var employee = this.getSourceEmployeeFromRecordId(itemId);
					var employee = this.getSourceEmployeeFromWorkerId(itemId);
					// alert(JSON.stringify(employee));

					if (employee != null)
						this.$router.push('/user-profile/' + employee.workerId);
					else
						this.$emit('display-error', this.lview.errorInvalidWorkerTitle, this.lview.errorInvalidWorkerMessage);
				}
			},

			onClear() {
				this.searchValue = "";
				this.onSearchChange();
			},
			onSearchInput() {
				if (this.searchTimeout != false) {
					clearTimeout(this.searchTimeout);
					this.searchTimeout = false;
				}

				this.searchTimeout = setTimeout(this.onSearchChange, 500);
			},
			// onSearchChange() {
			// 	if (this.searchTimeout != false) {
			// 		clearTimeout(this.searchTimeout);
			// 		this.searchTimeout = false;
			// 	}

			// 	var newSearchValue = (this.searchValue == "" || this.searchValue == null || typeof this.searchValue === 'undefined') ? "" : this.searchValue;

			// 	if (this.previousSearchValue != newSearchValue) {
			// 		this.previousSearchValue = newSearchValue;
			// 		if (typeof this.searchValue !== 'undefined' && this.searchValue != null) {
			// 			this.filters.searchNames.input = this.searchValue.toUpperCase();
			// 			this.currentCondition = this.filters.searchNames.condition;
			// 		}
			// 		else {
			// 			this.currentCondition = this.filters.all.condition;
			// 		}

			// 		this.updateEmployeeListDisplay();
			// 	}
			// },

			onSearchChange() {
				this.finishEditing()
				this.getPageData()
					.then(response => { this.handleData(response.jsonProjects, response.jsonEmployeesProjects); })
					.catch(err => {
						var error_text = err.toString()
						if (err.request) {
							error_text += ': ' + err.request.responseText
						}
						console.error(error_text);
						if(err.message == "Network Error"){
							this.offlineNotifier = true;
						}
						else{
							this.$emit("set-state", "error", this.lmessages.errorOccured);
							this.$emit('display-error', this.lmessages.errorOccuredTitle, this.lmessages.errorOccured, error_text);
						}
					});
			},

			statusChange(){
				this.finishEditing()
				this.getPageData()
					.then(response => { this.handleData(response.jsonProjects, response.jsonEmployeesProjects); })
					.catch(err => {
						var error_text = err.toString()
						if (err.request) {
							error_text += ': ' + err.request.responseText
						}
						console.error(error_text);
						if(err.message == "Network Error"){
							this.offlineNotifier = true;
						}
						else{
							this.$emit("set-state", "error", this.lmessages.errorOccured);
							this.$emit('display-error', this.lmessages.errorOccuredTitle, this.lmessages.errorOccured, error_text);
						}
					});
			},

			setFilters(reason, details){
				this.filters.offBoardingReason = reason
				this.filters.offBoardingDetails = details
				this.getPageData()
					.then(response => { this.handleData(response.jsonProjects, response.jsonEmployeesProjects); })
					.catch(err => {
						var error_text = err.toString()
						if (err.request) {
							error_text += ': ' + err.request.responseText
						}
						console.error(error_text);
						if(err.message == "Network Error"){
							this.offlineNotifier = true;
						}
						else{
							this.$emit("set-state", "error", this.lmessages.errorOccured);
							this.$emit('display-error', this.lmessages.errorOccuredTitle, this.lmessages.errorOccured, error_text);
						}
					});
			},
			clearFilters(){
				this.filters.offBoardingReason = null
				this.filters.offBoardingDetails = null
				this.getPageData()
					.then(response => { this.handleData(response.jsonProjects, response.jsonEmployeesProjects); })
					.catch(err => {
						var error_text = err.toString()
						if (err.request) {
							error_text += ': ' + err.request.responseText
						}
						console.error(error_text);
						if(err.message == "Network Error"){
							this.offlineNotifier = true;
						}
						else{
							this.$emit("set-state", "error", this.lmessages.errorOccured);
							this.$emit('display-error', this.lmessages.errorOccuredTitle, this.lmessages.errorOccured, error_text);
						}
					});
			},

			onRequestStart() {
				this.$emit("set-state", "LOADING_OVERLAYER", this.lmessages.savingChanges);
			},
			onRequestError(error) {
				this.$emit("display-error", this.lmessages.errorOccuredTitle, this.errorOccured, error);
			},
			onRequestEnd() {
				this.$emit("set-state", "DEFAULT");
			},
			onError(error) {
				this.$emit(
					"display-error",
					error.title,
					error.message,
					error.errorCodeShort + " " + error.errorCodeLong + (error.details == null ? "" : "<br />" + error.details)
				);
			},

			// --------------------------------
			// Methods assigned before mounting
			// --------------------------------

			grouperDEFAULTgroupingCondition(item) {
				var myId = JSON.parse(localStorage.getItem('workers'))[0].id;

				if (item.managerId == null) return 1;
				else if (item.managerId == myId) return 0;
				else return 2;
			},

			grouperOFFICEgetIndex(managerId) {
				let managers = this.groupers.OFFICE.groups;
				for (var i = 0; i < managers.length; i++) {
					if (managerId == managers[i].id) {
						return i;
					}
				}

				return 0;
			},

			grouperOFFICEgroupingCondition(item) {
				var myId = JSON.parse(localStorage.getItem('workers'))[0].id;

				if (item.managerId == null) return 0;
				else if (item.managerId == myId) {
					return 1;
				}
				else return this.grouperOFFICEgetIndex(item.managerId);
			},

			MNGonInit() {
				this.groupers.OFFICE.groups.push({
					id: null,
					title: this.lview.unassigned,
					sortName: '_',
				});
				this.groupers.OFFICE.groups.push({
					id: null,
					title: this.lview.myEmployees,
					sortName: '#',
				});
				for (var i = 0; i < this.managersData.length; i++) {
					var manager = this.managersData[i];

					let managerFullName = "";
					let managerSortName = "";
					if (manager.middleName == null) {
						managerFullName = manager.lastName + " " + manager.firstName;
						managerSortName = manager.lastName + " " + manager.firstName;
					}
					else {
						managerFullName = manager.lastName + " " + manager.firstName + " " + manager.middleName;
						managerSortName = manager.lastName + " " + manager.firstName + " " + manager.middleName;
					}

					let myId = JSON.parse(localStorage.getItem('workers'))[0].id;
					if (manager.managerId != myId) {
						this.groupers.OFFICE.groups.push({
							id: manager.managerId,
							title: managerFullName,
							sortName: managerSortName
						});
					}


				}
			},

			PRJonInit() {
				this.groupers.PROJECT.groups.splice(0, this.groupers.PROJECT.groups.length);

				for (var i = 0; i < this.projectsDictionary.length; i++) {
					this.groupers.PROJECT.groups.push({
						id: this.projectsDictionary[i].id,
						title: this.projectsDictionary[i].name,
						sortName: this.projectsDictionary[i].name,
					});
				}

				this.groupers.PROJECT.groups.push({
					id: -1,
					title: this.lmessages.unassigned
				});
			},
			PRJgroupingConditions(item) {
				var indexes = [];
				for (var i = 0; i < this.groupers.PROJECT.groups.length; i++) {
					/*if(this.groupers.PROJECT.groups[i].id == item.project.id){
						return i;
					}*/
					if (item.projectIds.indexOf(this.groupers.PROJECT.groups[i].id) != -1) {
						indexes.push(i);
					}
				}
				return (indexes.length == 0) ? -1 : indexes;
			},


			// Empty function for groupers without onInit
			empty() { },

			filterSEARCHNAMEScondition(item) {
				// table of all separate words in the input field
				var keyWords = this.filters.searchNames.input.split(' ');
				var word;

				// go through each search word separately
				for (var i = 0; i < keyWords.length; i++) {
					word = keyWords[i].toUpperCase();

					// in case there is no match with neither fullName or ProjectsDisplayText => break with False
					// protect in case of null names/projects or telephones (first check null, then check includes())
					if (!(
						(item.fullName != null && this.includesWord(item.fullName.toUpperCase(), word)) || //item.fullName.toUpperCase().includes(word)) ||
						(item.projectsDisplayText != null && item.projectsDisplayText.toUpperCase().includes(word)) ||
						(item.mobileNumber != null && item.mobileNumber.includes(word))
					)) {
						return false;
					}
				}
				return true;
			},

			includesWord(word, pattern){
				var lookingWord = "";
				for(var i = 0; i < word.length; i++){
					var char = word[i];
					var specialSign = this.lview.specialSigns.find((a)=>{return a.spec == char});
					if(typeof specialSign != 'undefined'){
						lookingWord += specialSign.norm;
					} else {
						lookingWord += char;
					}
				}

				var lookingPattern = "";
				for(var i = 0; i < pattern.length; i++){
					var char = pattern[i];
					var specialSign = this.lview.specialSigns.find((a)=>{return a.spec == char});
					if(typeof specialSign != 'undefined'){
						lookingPattern += specialSign.norm;
					} else {
						lookingPattern += char;
					}
				}

				return lookingWord.includes(lookingPattern);
			},

			// Function for a default 'accept all' filter
			acceptAll(arg) { return true; },

			// --------
			// OFFLINE MODE methods
			// --------

			// retry to load page, when onlineStatus is still false shows 
			// snackbar with offline status notification
			// otherwise loads page
			retryConnect(){
				if(this.onlineStatus){
					window.location.reload(true);
					this.offlineNotifier = false;
				} else {
					this.offlineNotifier = true;
				}
			}
		}
	}

</script>

<style>
	/* This components do not have scoped data tag after rendering, have to do define style globally */
	.EmployeesPM .v-toolbar__content {
		padding-right: 0;
	}

	.employees-list .v-list__group__header {
		border-bottom: 2px solid rgba(0,0,0,.42);
	}

	.employees-list .v-list__group__header--active {
		border-bottom: 2px solid var(--v-primary-base);
	}

	.employees-list .v-list__group__header .v-icon {
		color: rgba(0,0,0,.42);
	}

	.employees-list .v-list__group__header--active .v-icon {
		color: var(rgba(4, 202, 90, 1));
	}

	.employees-list .v-list__group > .v-list__group__header {
		background-color: rgba(0,0,0,0) !important;
	}

	.employees-list .v-list__group__header .employees-list__main-title {
		color: rgba(0,0,0,.42);
	}

	.employees-list .v-list__group__header--active .employees-list__main-title {
		color: var(rgba(4, 202, 90, 1));
	}

	.EmployeesPM .employees-list .v-list__group__items--no-action .v-list__tile {
		padding-left: 5px;
		padding-right: 5px;
	}

	.EmployeesPM[mode="edit"] .employees-list__inner-item .v-list__tile {
		transition: background-color 0.1s linear;
	}

		.EmployeesPM[mode="edit"] .employees-list__inner-item .v-list__tile:hover {
			background-color: rgba(0,0,0,0);
		}

	.EmployeesPM[mode="edit"] .employees-list__inner-item.selected .v-list__tile {
		background-color: rgba(0,0,0,0.08);
	}

	.snackbar-offline-notifier{
		height: 30px !important;
		margin-bottom: 70px;
		top: calc(90% - 35px) !important;
		-webkit-font-smoothing: antialiased; 
		text-rendering: geometricPrecision;
		width: 80% !important;
		margin-left: 10% !important;
		margin-right: 10% !important;
	}

	.v-sheet .v-snack--multi-line .v-snack__wrapper{
		height: 30px !important;
		min-height: 30px !important;
	}

	.employees-pm__skeleton-loader-list .v-skeleton-loader__list-item-avatar-two-line {
		height: 59px;
		padding-left: 8px;
		max-width: 300px;
	}
</style>

<style scoped>

	.EmployeesPM {
		margin-bottom: 56px;
		overflow-x:unset !important;
		width: 100%;
	}

	.employees-edit-toolbar {
		z-index: 11 !important;
		position: fixed;
		margin-top: -56px;
	}

	@media only screen and (max-width: 943px){
		.employees-edit-toolbar {
			margin-top: -48px;
		}
	}

	/* Listing */
	.employees-list {
		background-color: rgba(0,0,0,0);
		padding: 0 10px;
	}

	.employees-list-group-sub-item{
		color: black;
		width: auto;
		margin-left: 10px;
		margin-right: 10px;
	}

	.list-group-item{
		border-bottom: 2px solid gray;
	}

	.employees-list__main-title {
		color: var(rgba(4, 202, 90, 1));
	}

	.v-list__group--active::before {
		height: 0;
	}

	.v-list__group--active::after {
		height: 0;
	}

	.employees-list__item-avatar {
		width: 100%;
		height: 100%;
		line-height: 100%;
	}

	.employees-list__item-avatar > .employees-list__item-avatar-check {
		height: calc(100% - 2px);
		width: calc(100% - 2px);
		border: 2px solid var(--v-primary-base);
		display: flex;
		flex-direction: column;
		justify-content: center;
		background-color: #FFF;
		border-radius: 50%;
		font-weight: bold;
	}

	.employees-list__item-avatar > .employees-list__item-avatar-check-nearly-outdated {
		height: calc(100% - 2px);
		width: calc(100% - 2px);
		border: 2px solid orange;
		display: flex;
		flex-direction: column;
		justify-content: center;
		background-color: #FFF;
		border-radius: 50%;
		font-weight: bold;
	}

	.employees-list__item-avatar > .employees-list__item-avatar-check-outdated {
		height: calc(100% - 2px);
		width: calc(100% - 2px);
		border: 2px solid red;
		display: flex;
		flex-direction: column;
		justify-content: center;
		background-color: #FFF;
		border-radius: 50%;
		font-weight: bold;
	}

	.employees-list__item-avatar > .employees-list__item-avatar-initials {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		background-color: #CFCFCF;
		border-radius: 50%;
		font-weight: bold;
	}

	.employees-list__item-avatar > .employees-list__item-avatar-outdated {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		background-color: #CFCFCF;
		border-radius: 50%;
		font-weight: bold;
		border: 2px red solid;
	}

	.employees-list__item-avatar > .employees-list__item-avatar-nearly-outdated {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		background-color: #CFCFCF;
		border-radius: 50%;
		font-weight: bold;
		border: 2px orange solid;
	}
</style>