export const firebaseConfig = {
    apiKey: "AIzaSyAQwlKBZkW8vn-UrrBYMl4S_yRqd7JNdGw",
    authDomain: "fob-porr.firebaseapp.com",
    projectId: "fob-porr",
    storageBucket: "fob-porr.appspot.com",
    messagingSenderId: "1042857178219",
    appId: "1:1042857178219:web:408ba15e55f8eaba572d2e",
    measurementId: "G-RJ7G58Y5TX"  
};
  

export const vapidKey = "BIKnfwoDSmrn7ZUsfJOWfocyTj26fPfPpoIcsFCoLP33XQ5FGFvskDtPogWGaMU_jPjQrDYthHWPm3VthTChRSw";