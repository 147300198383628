<template>
  <v-dialog persistent v-model="visible" max-width="290">
    <v-card dense>
      <div class="EmployeesFiltersDialog">
        <v-card-title style="text-align:center; padding-bottom:10px;">
          <span class="headline">{{ lview.title }} </span>
        </v-card-title>

        <v-divider class="green"></v-divider>

        <v-card-text>
			<v-select v-model="pickedResignReason"
                :items="selectForResignReasons"
                item-text="name"
                item-value="id"
                :label="lview.resignReason"
                style="margin-top: 10px;"
			></v-select>

			<v-select v-model="pickedResignDetails"
                multiple
                chips
                :items="selectForResignDetails"
                item-text="name"
                item-value="id"
                :label="lview.details"
                style="margin-top: 10px;"
            ></v-select>

        </v-card-text>

        <v-card-actions>
			<v-spacer></v-spacer>
			<v-btn text @click="clearFilters()">{{ lview.clear }}</v-btn>
			<v-btn color="rgba(4, 202, 90, 1)" text @click="search()">{{ lview.search }}</v-btn>
		</v-card-actions>
        
      </div>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "employeesFiltersDialog",
  data() {
    return {
      visible: false,
	  pickedResignReason: null,
	  pickedResignDetails: null
    };
  },
  computed: {
    lview: {
      get: function() {
        return this.$t("views.commons.employeesFiltersDialog");
      }
    },
    lbuttons: {
      get: function() {
        return this.$t("commons.buttons");
      }
    },
    lmessages: {
      get: function() {
        return this.$t("commons.messages");
      }
    },
    lerrors: { get: function() { return this.$t('errors'); } },
  },
  props: {
	  	reason: {
			type: Number,
			default: null
		},
		details: {
			type: Array,
			default() { 
				return []
			}
		},
	  	selectForResignReasons: {
			type: Array,
			default() { 
				return []
			}
		},
		selectForResignDetails: {
			type: Array,
			default() { 
				return []
			}
		},

  },
	mounted(){

	},
	watch:{
		reason(){
			this.pickedResignReason=this.reason
		},
		details: {
			handler: function(){
				if(this.details){
					this.pickedResignDetails=this.details.slice()
				}
				else this.pickedResignDetails=null
			},
			deep: true
		},
	},
	methods: {
		openDialog(){
			this.visible=true
		},
		clearFilters(){
			this.$emit("clear");
			this.visible=false
		},
		search(){
			this.$emit("search", this.pickedResignReason, this.pickedResignDetails);
			this.visible=false
		},
	}
};
</script>

<style scoped>


.action-buttons-wrapper{
  margin-top: 20px;
	display: flex;
	justify-content: flex-end;
}
</style>
