<!--
SharedMasters 2019 - Fast OnBoarding Application

__copyright__ = "Copyright (C) 2019, Shared Masters sp. z o.o."
__license__ = "Fast OnBoarding can not be copied and/or distributed without the express permission of Shared Masters sp. z o.o."
__version__ = "0.0.1-pre-beta"
__author__ = "Jan Przychodniak, .."
__maintainer__ = "Jan Przychodniak"
__email__ = "jan@sharedmasters.com"
__status__ = "DEV"


USAGE
-------
<change-employee-plans-dialog
  ref="yourReference"

  ...
></change-employee-plans-dialog>
$refs.yourReference.openDialog();

props:
  - workers {Array}, determines worker ids to modify in the database
	[
	  {
		workerId,
		managerId,
		projectIds
	  }
	]

  - managersDictionary {Array}, determines managers dictionary for reassigning worker's manager [optional]
	[
	  {
		managerId {Number}, manager id,
		firstName {String},
		middleName {String} [optional],
		lastName {String}
	  }
	]

  - defaultManagerId {Number}


events:
  - request-sending-start
  - request-sending-end
  - request-sending-success
  - error

errors:
  structure: {
	errorCodeShort,
	errorCodeLong,
	title,
	message,
	details
  }

  errorsId: CHNGEMP

-->

<template>
	<div>
		<v-dialog persistent v-model="visible" max-width="290">
			<v-card dense class="ChangeEmployeePlansDialog">
				<v-card-title style="text-align:center; padding-bottom:0">
					<span class="headline">{{ lview.title }}</span>
				</v-card-title>

				<v-card-text>
					<!-- <v-card elevation="1" align-center class="projects-table">
					  <v-card-title primary-title class="projects-table-title" style="position:relative">
						<h3 class="subheading">{L Change Projects}</h3>

						<v-btn absolute ripple icon style="right: -1px;">
						  <v-icon color="primary">add</v-icon>
						</v-btn>
					  </v-card-title>

					  <v-list style="width:100%; max-height: 110px; overflow-y: auto; overflow-x: hidden" dense >
						<v-list-tile style="padding:0 5px" v-for="project in tableProjects" :key="project.id">
						  <v-list-tile-content>
							<s v-if="project.status == 'REMOVED'">{{ project.name }}</s>
							<span v-else>{{ project.name }}</span>
						  </v-list-tile-content>
						  <v-list-tile-action>
							<v-btn icon v-if="project.status=='REMOVED'" @click="bringProjectBack(project.id)">
							  <v-icon color="primary lighten-1" style="font-size: 18px;">
								undo
							  </v-icon>
							</v-btn>
							<v-btn icon v-else @click="removeProject(project.id)">
							  <v-icon color="red lighten-1" style='font-size: 18px' >
								clear
							  </v-icon>
							</v-btn>
						  </v-list-tile-action>
						</v-list-tile>
					  </v-list>
					</v-card>-->

					<!-- Select new project -->

					<!-- <v-layout align-center>
					  <v-checkbox hide-details color="primary" v-model="changingLocationEnabled"></v-checkbox>
					  <div @click="
						if(!changingLocationEnabled){
						  changingLocationEnabled = true;
						  $nextTick($refs.changePlansDialogLocationSelect.focus);
						  $nextTick($refs.changePlansDialogLocationSelect.activateMenu);
						}
					  "> -->
						<!-- <v-select
						  ref="changePlansDialogLocationSelect"
						  v-model="pickedChangingLocation"
						  :items="projectsDictionary"
						  item-text="name"
						  item-value="id"
						  :label="lview.changeProject"
						  style="margin-top: 10px;"
						></v-select> -->
					  <!-- </div>
					</v-layout> -->

					<v-layout align-center v-if="managersSelect.length > 0">
						<v-checkbox hide-details color="rgba(4, 202, 90, 1)" v-model="changingManagerEnabled"></v-checkbox>

						<div @click="
							if(!changingManagerEnabled){
								changingManagerEnabled = true;
								$nextTick($refs.managersSelect.focus);
								$nextTick($refs.managersSelect.activateMenu);
							}
						">
							<v-select ref="managersSelect"
									  v-model="pickedChangingManager"
									  :items="managersSelect"
									  item-text="displayName"
									  color="rgba(4, 202, 90, 1)"
									  item-value="id"
									  :label="lview.changeManager"
									  style="margin-top: 10px;"
									  :disabled="!changingManagerEnabled"></v-select>
						</div>
						<v-spacer></v-spacer>
					</v-layout>

					<!-- TEMPORARY REMOVAL OF NOT YET COMPLETE FUNCTIONALITY - CODE STABILIZATION
					  <v-select
						v-model="pickedChangingReason"
						:items="selectForChangingReasons"
						:label="lview.reason"
						style="margin-top: 10px;"
					  ></v-select>
					-->
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text color="rgba(4, 202, 90, 1)" @click="closeDialog()">{{ lbuttons.cancel }}</v-btn>
					<v-btn text color="rgba(4, 202, 90, 1)" @click="onAcceptDialog()">{{ lbuttons.confirm }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import axios from 'axios'

	export default {
		name: 'changeEmployeePlansDialog',
		mounted: function () {
			
		},
		data() {
			return {
				visible: false,

				changingLocationEnabled: false,
				pickedChangingLocation: null,


				changingManagerEnabled: false,
				pickedChangingManager: null,
				managersSelect: [
					/*{
					  managerId: 1,
					  displayName: "Jan Kowalski"
					}*/
				],

				offBoardingDatePicker: {
					date: new Date()
					.toJSON()
					.slice(0, 10)
					.replace("/-/g", "/"), //set default date as today's in format : YY-MM-DD
					visible: false
				},
				onBoardingDatePicker: {
					date: new Date()
					.toJSON()
					.slice(0, 10)
					.replace("/-/g", "/"), //set default date as today's in format : YY-MM-DD
					visible: false
				},

				pickedChangingReason: null,
				selectForChangingReasons: [],

				tableProjects: [
					/*{
					  id: {Number},
					  name: {String},
					  status: (DEFAULT|REMOVED|ADDED)
					}*/
				]
			}
		},
		computed: {
			lview: { get: function () { return this.$t('views.commons.changeEmployeePlansDialog'); } },
			lbuttons: { get: function () { return this.$t("commons.buttons"); } },
			lmessages: { get: function () { return this.$t("commons.messages"); } },
			lerrors: { get: function() { return this.$t('errors'); } },
		},
		props: {
			workers: {
				type: Array,
				required: null,
			},

			managersDictionary: {
				type: Array,
				default: () => []
			},

			defaultManagerId: {
				type: Number,
				default: null
			},

			// projectsDictionary: {
			//   type: Array,
			//   required: null,
			//   default: [],
			// },
			assignedProjects: {
			  type: Array,
			  default: () => []
			},
		},
		watch: {
		},
		methods: {
			openDialog() {
				this.loadPropsData();

				//console.log(this.workers.length);
				this.changingManagerEnabled = false;
				this.pickedChangingManager = this.defaultManagerId;
				this.changingLocationEnabled = false;

				this.visible = true;
			},
			closeDialog() {
				this.visible = false;
			},
			onAcceptDialog: async function () {
				var errorOccured = false;
				var data = {};

				var workers = this.workers;
				var worker;

				// Nothing to do here, no data was changed
				if (!this.anyChanges()) {
					this.closeDialog();
					//console.log("NO CHANGES");
					return;
				}
				// Nothing to do here, no workers to update
				if (workers.length == 0) {
					this.closeDialog();
					//console.log("WORKERS LENGTH 0");
					return;
				}

				this.$emit('request-sending-start');


				// Changing manager
				var changeManagerPackages = [];
				if (this.changingManagerEnabled && typeof (this.pickedChangingManager) !== 'undefined' && this.pickedChangingManager != null) {
					for (var i = 0; i < workers.length; i++) {
						changeManagerPackages.push({
							worker: workers[i],
							managerId: this.pickedChangingManager,
							promise: axios({
								method: "PATCH",
								url: localStorage.getItem("current_env") + "/api/v2/boardings/worker/" + workers[i].workerId,
								headers: {
									'Content-Type': 'application/json',
									'Authorization': 'Bearer ' + localStorage.getItem('jwt')
								},
								data: {
									worker: {
										parent_id: this.pickedChangingManager
									}
								}
							})
						});
					}
				}

				for (var i = 0; i < changeManagerPackages.length; i++) {
					try {
						await changeManagerPackages[i].promise;

						changeManagerPackages[i].worker.managerId = changeManagerPackages[i].managerId;
						//console.log(">>>>>> " + JSON.stringify(changeManagerPackages[i].worker.managerId))
					} catch (ex) {
						errorOccured = true;
						this.$emit('request-sending-end');
						if (ex.isAxiosError) {
							if (ex.response.status == 403) {
								this.$emit('error', {
									title: this.lmessages.forbiddenOperation,
									message: this.lmessages.youDoNotHavePriviledgesToDoThatOperation,
									errorCodeShort: "CNFBRD#5",
									errorCodeLong: "REQUEST_FAILED",
									details: String(ex)
								});
								return;
							}
							else if (ex.response.data != null && ex.response.data != undefined) {
								if (ex.response.data.worker != null && ex.response.data.worker != undefined) {
									if(ex.response.data.worker.parent_id != null && ex.response.data.worker.parent_id != undefined) {
										for (var i=0; i < this.lerrors.worker.parent_id.length; i++) {
											if (this.lerrors.worker.parent_id[i].code == ex.response.data.worker.parent_id[0].code) {
												this.$emit('error', {
													title: this.lerrors.worker.parent_id[i].header,
													message: this.lerrors.worker.parent_id[i].string,
													errorCodeShort: this.lerrors.worker.parent_id.code,
													errorCodeLong: this.lerrors.worker.parent_id.code,
													details: String(ex)
												});
												return;									
											}
										}
									}
								}
							}
							else { // any other error
								this.$emit('error', {
									title: this.lmessages.errorOccuredTitle,
									errorCodeShort: ex.toString(),
									errorCodeLong: ex.toString(),
									message: this.lmessages.errorOccured,
									details: ex.toString()
								});
								return;
							}
						}
						else {
							this.$emit('error', {
								title: this.lmessages.errorOccuredTitle, 
								errorCodeShort: ex.toString(), 
								errorCodeLong: ex.toString(),
								message: this.lmessages.errorOccured,
								details: this.lmessages.errorOccured
							});
							return;
						}
						// TODO handle exception
						//console.log(ex);
					}
				}

				this.$emit('request-sending-end');

				if (!errorOccured) {
					this.$emit('request-sending-success', workers);
					this.closeDialog();
				}
			},

			// onAcceptDialog: async function () {
			// 	var errorOccured = false;
			// 	var workers = this.workers;
			// 	var data = {
			// 		project_id: this.pickedChangingLocation,
			// 		off_boarding_date: this.offBoardingDatePicker.date,
			// 		on_boarding_date: this.onBoardingDatePicker.date,
			// 	};

			// 	this.$emit('request-sending-start');

			// 	try {
			// 		await axios({
			// 				method: "PATCH",
			// 				url: localStorage.getItem("current_env") + "/api/v2/workers/change-project/"+this.pickedChangingActiveBoarding.id,
			// 				headers: {
			// 					'Content-Type': 'application/json',
			// 					'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			// 				},
			// 				data: data
			// 			})
			// 	} catch (ex) {
			// 		console.log(ex.response.data)
			// 		errorOccured = true;
			// 		this.$emit('request-sending-end');
			// 		if (ex.isAxiosError) {
			// 			if (ex.response.status == 403) {
			// 				this.$emit('error', {
			// 					title: this.lmessages.forbiddenOperation,
			// 					message: this.lmessages.youDoNotHavePriviledgesToDoThatOperation,
			// 					errorCodeShort: "CNFBRD#5",
			// 					errorCodeLong: "REQUEST_FAILED",
			// 					details: String(ex)
			// 				});
			// 				return;
			// 			}
			// 			else if (ex.response.data != null && ex.response.data != undefined) {
			// 				if (ex.response.data.worker != null && ex.response.data.worker != undefined) {
			// 					if(ex.response.data.worker.parent_id != null && ex.response.data.worker.parent_id != undefined) {
			// 						for (var i=0; i < this.lerrors.worker.parent_id.length; i++) {
			// 							if (this.lerrors.worker.parent_id[i].code == ex.response.data.worker.parent_id[0].code) {
			// 								this.$emit('error', {
			// 									title: this.lerrors.worker.parent_id[i].header,
			// 									message: this.lerrors.worker.parent_id[i].string,
			// 									errorCodeShort: this.lerrors.worker.parent_id.code,
			// 									errorCodeLong: this.lerrors.worker.parent_id.code,
			// 									details: String(ex)
			// 								});
			// 								return;									
			// 							}
			// 						}
			// 					}
			// 				}
			// 			}
			// 			else { // any other error
			// 				this.$emit('error', {
			// 					title: this.lmessages.errorOccuredTitle,
			// 					errorCodeShort: ex.toString(),
			// 					errorCodeLong: ex.toString(),
			// 					message: this.lmessages.errorOccured,
			// 					details: ex.toString()
			// 				});
			// 				return;
			// 			}
			// 		}
			// 		else {
			// 			this.$emit('error', {
			// 				title: this.lmessages.errorOccuredTitle, 
			// 				errorCodeShort: ex.toString(), 
			// 				errorCodeLong: ex.toString(),
			// 				message: this.lmessages.errorOccured,
			// 				details: this.lmessages.errorOccured
			// 			});
			// 			return;
			// 		}
			// 			// TODO handle exception
			// 			//console.log(ex);
			// 	}

			// 	this.$emit('request-sending-end');

			// 	if (!errorOccured) {
			// 		this.$emit('request-sending-success', workers);
			// 		this.closeDialog();
			// 	}
			// },

			anyChanges() {
				if (this.changingManagerEnabled && this.defaultManager != this.pickedChangingManager) {
					return true;
				}

				/*for(var i = 0; i < this.tableProjects.length; i++){
				  if(this.tableProjects[i].status != "DEFAULT"){
					return true;
				  }
				}*/

				return false;
			},

			getProject(projectId) {
				for (var i = 0; i < this.tableProjects.length; i++) {
					if (this.tableProjects[i].id == projectId) {
						return this.tableProjects[i];
					}
				}
				return null;
			},
			removeProject(projectId) {
				var project = this.getProject(projectId);

				if (project != null) {
					if (project.status != "ADDED")
						project.status = "REMOVED";
					else
						this.tableProjects.splice(
							this.tableProjects.indexOf(project), 1
						);
				}
			},
			bringProjectBack(projectId) {
				var project = this.getProject(projectId);

				if (project != null) {
					project.status = "DEFAULT";
				}
			},

			loadPropsData() {
				this.pickedChangingLocation = this.defaultProject;
				this.pickedChangingManager = this.defaultManager;

				console.log("ManagesrDictionary")
				console.log(this.managersDictionary)

				this.managersSelect.splice(0, this.managersSelect.length);
				this.managersDictionary.forEach((manager) => {
					this.managersSelect.push({
						id: manager.managerId,
						displayName: this.getManagerDisplayName(manager)
					});
				});

				/*this.tableProjects.splice(0, this.tableProjects.length);
				this.assignedProjects.forEach((project) => {
				  this.tableProjects.push({
					id: project.id,
					name: project.name,
					status: "DEFAULT"
				  });
				})*/
			},
			getManagerDisplayName(manager) {
				var firstName = manager.firstName;
				var middleName = (typeof manager.middleName === 'undefined' || manager.middleName == null) ? "" : " " + manager.middleName;
				var lastName = " " + manager.lastName;

				return firstName + middleName + lastName;
			},
			/**
			 * Finds project name in projects dictionary
			 *
			 * @param {Integer} projectId Id to look for
			 * @returns {String} Project name if foound, null otherwise
			 */
			getProjectName(projectId) {
				for (var i = 0; i < this.projectsDictionary.length; i++) {
					if (this.projectsDictionary[i].id == projectId) {
						return this.projectsDictionary[i].name;
					}
				}
				return null;
			},
			partialWorkerUpdate: async function (workerIds, requestData) {
				var worker;
				var axiosPromises = [];
				var workerIdsCopy = [];
				for (var i = 0; i < workerIds.length; i++) {
					workerIdsCopy.push(workerIds[i]);

					axiosPromises.push(
						axios({
							method: "PATCH",
							url:
								localStorage.getItem("current_env") +
								"/api/worker/" +
								workerIds[i],
							headers: {
								"Content-Type": "application/json",
								Authorization: "Bearer " + localStorage.getItem("jwt")
							},
							data: requestData
						})
					);
				}

				var response;
				var requestError = {
					errors: [],
					message: null
				};
				for (var i = 0; i < axiosPromises.length; i++) {
					try {
						response = await axiosPromises[i];
					} catch (error) {
						requestError.errors.push({
							workerId: workerIdsCopy[i],
							error: error
						});
					}
				}

				if (requestError.errors.length > 0) {
					requestError.message = "Following errors occured: \n";

					for (var i = 0; i < requestError.errors.length; i++) {
						requestError.message +=
							"\nWorker " +
							requestError.errors[i].workerId +
							": " +
							requestError.errors[i].error;
					}

					throw requestError;
				}
			}
		}
	};
</script>

<style>
	div.ChangeEmployeePlansDialog .projects-table .v-subheader {
		padding: 0;
	}

	div.ChangeEmployeePlansDialog .projects-table .v-list__tile {
		padding: 0;
	}
</style>

<style scoped>
	.projects-table {
		padding: 0;
	}

	.projects-table-title {
		border-bottom: 1px solid rgba(0,0,0,0.12);
		padding: 5px;
	}
</style>
