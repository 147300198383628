<!--
SharedMasters 2021 - Fast OnBoarding Application

__copyright__ = "Copyright (C) 2021, Shared Masters sp. z o.o."
__license__ = "Fast OnBoarding can not be copied and/or distributed without the express permission of Shared Masters sp. z o.o."
__version__ = "1.4.0"
__author__ = "Adrian Bączek, .."
__maintainer__ = "Adrian Bączek"
__email__ = "adrian@sharedmasters.com"
__status__ = "DEV"

WARNING: This component can only be opened, when id of passed (as a prop) address data object is NOT NULL.

usage:
    <remove-address-dialog
        ref="yourReference"
        :address="some address data structure"

        @show-overlayer="showOverlayerEventHandler"
        @hide-overlayer="hideOverlayerEventHandler"
        @emit-error="showErrorEventHandler"
        @emit-update="emitUpdateEventHandler"
    ></remove-address-dialog>

    // update address prop
    this.$nextTick(() => { this.$refs.yourReference.openDialog(); });

props:
    - address - {Object} - object with all address data. It contains all necessary data about address. Sample structure is presented below
        {
            id: {Number},                        | ex. 31,
            streetName: {String},                | ex. "Marszałkowska"               
            streetNumber: {String},              | ex. "123"
            propertyNumber: {String},            | ex. "12A"
            zipCode: {String},                   | ex. "02-123"
            city: {String},                      | ex. "Warszawa"
            region: {String},                    | ex. "Mazowieckie"
            country: {String},                   | ex. "Polska"
        }

events:
    - show-overlayer - shall put app at loading overlayer state
    - hide-overlayer - shall put app at default state
    - emit-error - shall display error on screen (in some dialog)
    - emit-update - shall make parent component update its data
-->

<template>
    <v-dialog 
        persistent 
        v-model="dialog"
		max-width="320"
    >
		<v-card>
			<!-- dialog title -->
            <v-card-title class="headline">{{ title }}</v-card-title>

            <!-- dialog content -->
			<v-card-text style="text-align:justify">
                <!-- confirmation message -->
                <div style="margin-bottom: 20px;"> {{ content }} </div>
                
                <!-- displaying address details -->
                <div class="justify-left">
                    <div style="display: flex;"> 
                        <div style="margin-right: 5px;"> {{ address.streetName }} </div> 
                        <div style="margin-right: 5px;"> {{ address.streetNumber }} </div>
                        <div v-if="address.propertyNumber != '' && address.propertyNumber != null"> / {{ address.propertyNumber }} </div>
                    </div>

                    <div style="display: flex;">
                        <div style="margin-right: 5px;"> {{ address.zipCode }} </div>
                        <div> {{ address.city }} </div>
                        <div v-if="address.region != '' && address.region != null"> , {{ address.region }} </div>
                    </div>

                    <div style="display: flex;">
                        <div v-if="address.country != '' && address.country != null"> {{ address.country }} </div>
                    </div>
                </div>    
            </v-card-text>

            <!-- dialog action buttons -->
			<v-card-actions>
				<v-spacer></v-spacer>

				<v-btn 
                    color="grey darken-1"
					text
					@click="cancelRemoveAddress"
                >
                    {{ lviews.cancel }}
				</v-btn>

				<v-btn 
                    color="red darken-1"
					text
				   @click="confirmRemoveAddress"
                >
                    {{ lviews.delete }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import axios from 'axios';
import fobConfig from '../../fob_config';

export default {
    data: function(){
        return {
            dialog: false,
            title: "",
            content: "",
        }
    },

    methods: {
        /**
         * Cancels removing address & closes dialog.
         */
        cancelRemoveAddress: function() {
            this.dialog = false;
        },

        /**
         * Confirms removing address and sends remove address request (and handles occurred errors).
         */
        confirmRemoveAddress: async function() {
            this.$emit("show-overlayer", this.lviews.removingAddress);
            let response = null;
            try {
                response = await axios({
                    url: fobConfig.api_url + "/api/v2/admin/postal-address/" + this.address.id,
                    method: "DELETE",
                    headers: {
                        'Authorization': "Bearer " + localStorage.getItem('jwt'),
                    }
                });
            } catch (error) {
                response = null;
                this.$emit('hide-overlayer');
                switch(error.response.status) {
                    case 400:
                        this.$emit('emit-error', this.lviews.badRequest, this.lviews.cannotRemoveAddressInUse, error.toString());
                        break;
                    case 401:
                        this.$store.dispatch('logout');
                        break;
                    case 403:
                        this.$store.dispatch('logout');
                        break;
                    case 404:
                        this.$emit('emit-error', this.lviews.notFound, this.lviews.cannotRemoveAddressDoesNotExists, error.toString());
                        break;
                    case 429:
                        this.$emit('emit-error', this.lviews.tooManyRequests, this.lviews.youSentTooManyRequestsMessage, error.toString());
                        break;
                    default:
                        this.$emit('emit-error', this.lviews.anErrorOccurredTitle, this.lviews.anErrorOccurredMessage, error.toString());
                        break;
                }
            }
            if (response != null) {
                this.$emit('hide-overlayer');
                this.$emit('emit-update');
                this.closeDialog();
            }
        },

        /**
         * Opens dialog in order to confirm whether user really wants to remove specific address or not.
         * WARNING: dialog opens only if address id is NOT NULL
         */
        openDialog: function() {
            if (this.address.id == null) {
                return;
            }
            this.title = this.lviews.deleteAddress,
            this.content = this.lviews.areYouSureYouWantRemoveAddress;
            this.dialog = true;
        },

        /**
         * Closes remove address dialog
         */
        closeDialog: function() {
            this.dialog = false;
        }
    },

    props: {
        address: {
            type: Object,
            required: true
        },
    },

    computed: {
        lviews: {
            get: function() {
                return this.$t('views.removeAddressDialog');
            }
        }
    }
}
</script>

<style>

</style>