<template>
    <div class="autocompleter-transform-find-blocker">
        <v-autocomplete
            v-model="model"
            :items="items"
            :loading="loading"
            :search-input.sync="search"
            item-text="workerFullsearch"
            item-value="workerId"
            :label="(label != null) ? label : lviews.chooseWorker"
            hide-no-data
            :placeholder="lviews.startWritingToFind"
            :disabled="disabled"
            clearable
            :prepend-icon="prependIcon"
            :append-icon="'mdi-magnify'"
            :error="(errors != null && errors.length > 0) || error"
            :error-messages="(error) ? errorMessages : errors"
        >
            <template v-slot:item="data">
                <v-list-item-content style="margin: 0px; padding-left: 10px;">
                    <v-list-item-title v-html="data.item.workerFullname"></v-list-item-title>
                    <v-list-item-subtitle> {{ lviews.birthDatePrefix }} {{ data.item.workerBirthDate }} </v-list-item-subtitle>
                </v-list-item-content>
            </template>
        </v-autocomplete>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "Autocompleter",
    data: function() {
        return {
            model: null,
            search: "",
            searchTimeout: null,
            preventUpdate: null,
            loading: false,
            errors: [],
            items: [
                // {
                //   workerId: 1,
                //   workerFullname: "User 1",
                //   workerBirthDate: "1969-07-16"
                // }
            ],
            disabled: false
        }
    },
    watch: {
        search: function() {
            if (!this.preventUpdate) {
                if (this.searchTimeout != null) {
                    clearTimeout(this.searchTimeout);
                }
                var that = this;
                this.searchTimeout = setTimeout(function(){
                    that.invokeUpdateAutocomplete();
                }, 500);
            }
            else {
                this.preventUpdate = false;
            }
        },
        model: function() {
            var id = this.model;
            var item = null;
            for(let i = 0; i < this.items.length; i++){
                if(this.items[i].workerId == id){
                    item = this.items[i];
                }
            }

            this.$emit("input", this.model, item);
        },
        outerModel: function(newVal, oldVal) {
            this.model = this.outerModel;
        },
        value: function(newVal, oldVal){
            this.model = this.value;
        }
    },
    methods: {
        reset(){

        },
        invokeUpdateAutocomplete: function() {
            if (this.search != null && this.search != "") {
                this.apiUpdateAutocomplete(this.search);
            }
        },

        apiUpdateAutocomplete: function(search) {
            this.loading = true;
            var that = this;
            let requestUrl = "";
            if (this.propApiLink != null) {
                requestUrl = this.propApiLink + "?search=" + search.toString();

                if (this.viewPermissionRequired != null) {
                    requestUrl += "&view=" + Number(this.viewPermissionRequired);
                }

                if (this.addPermissionRequired != null) {
                    requestUrl += "&add=" + Number(this.addPermissionRequired);
                }

                if (this.deletePermissionRequired != null) {
                    requestUrl += "&delete=" + Number(this.deletePermissionRequired);
                }

                if (this.changePermissionRequired != null) {
                    requestUrl += "&change=" + Number(this.changePermissionRequired);
                }
            }
            else {
                requestUrl = localStorage.getItem("current_env") + "/api/v2/worker-documents/workers/?search=" + search.toString();

                if (this.viewPermissionRequired != null) {
                    requestUrl += "&view=" + Number(this.viewPermissionRequired);
                }

                if (this.addPermissionRequired != null) {
                    requestUrl += "&add=" + Number(this.addPermissionRequired);
                }

                if (this.deletePermissionRequired != null) {
                    requestUrl += "&delete=" + Number(this.deletePermissionRequired);
                }

                if (this.changePermissionRequired != null) {
                    requestUrl += "&change=" + Number(this.changePermissionRequired);
                }
            }

            axios({
                method: "GET",
                url: requestUrl,
                headers: {
                    'Authorization': "Bearer " + localStorage.getItem('jwt')
                }
            }).then( response => {
                that.items = [];
                for(var i = 0; i < response.data.matching_workers.length; i++) {
                    var item = {
                        workerId: response.data.matching_workers[i].id,
                        workerFullname: response.data.matching_workers[i].worker_full_str,
                        workerBirthDate: response.data.matching_workers[i].worker_birth_date,
                        workerFullsearch: response.data.matching_workers[i].worker_full_str + " " + response.data.matching_workers[i].worker_birth_date,
                        additionalData: (typeof(response.data.matching_workers[i].additional) !== 'undefined') ? response.data.matching_workers[i].additional : null
                    };

                    that.items.push(item);
                }
                that.loading = false;
            }).catch( error => {
                that.loading = false;
                if (error.isAxiosError) {
                    that.errors.push(this.lviews.sorryWeCouldntConnectToServer);
                }
                else {
                    that.errors.push(error.toString());
                }
            });
        },
    },
    computed: {
        lviews: {
            get: function() {
                return this.$t("views.documents");
            }
        },
    },
    mounted: function() {
        let specificWorkerDocumentsSearchValueFromUserProfile = localStorage.getItem("Specific_worker_documents_search_value");
        if (specificWorkerDocumentsSearchValueFromUserProfile != null) {
            localStorage.removeItem("Specific_worker_documents_search_value");
            this.search = specificWorkerDocumentsSearchValueFromUserProfile;
            this.apiUpdateAutocomplete(this.search);
        }
        else {
            this.apiUpdateAutocomplete("");
        }
    },
    props: {
        value: {
            type: Number,
            required: false,
            default: null
        },
        error: {
            type: Boolean,
            required: false,
            default: false
        },
        errorMessages: {
            required: false,
            default: null
        },
        prependIcon: {
            type: String,
            required: false,
            default: ''
        },
        propApiLink: {
            type: String,
            required: false,
            default: null
        },
        label: {
            type: String,
            required: false,
            default: null
        },
        // worker autocompleter CRUD permissions (info if they should be passed in a link)
        addPermissionRequired: {
            type: Boolean,
            required: false,
            default: null,
        },
        viewPermissionRequired: {
            type: Boolean,
            required: false,
            default: null,
        },
        changePermissionRequired: {
            type: Boolean,
            required: false,
            default: null,
        },
        deletePermissionRequired: {
            type: Boolean,
            required: false,
            default: null,
        }
    }
}
</script>

<style>
.autocompleter-transform-find-blocker .v-select--is-menu-active .v-input__icon--append .v-icon {
    transform: none !important;
}
</style>