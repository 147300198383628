<template>
    <v-container class="d-flex align-content-center pdf-viewer-container" style="height: 100%; padding:0">
        <v-layout v-if="!errorOccurred">
            <iframe 
                v-if="documentObjectStream != null"
                class="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12" 
                style="margin-left: auto; margin-right: auto; zoom: 0.75; padding:0; border:none"
                :src="documentObjectStream">
            </iframe>
            <div id="image-document-viewer-div" :class="imageResized" v-if="imageObjectStream != null" @click="imageClick()">
                <img 
                    :src="imageObjectStream"
                    class="image-document-viewer"
                >
            </div>
        </v-layout>

        <v-layout v-else class="content-middler" column justify-center align-center>
            <v-icon size="100" style="color: red; border: 2px solid red; border-radius: 50%;"> mdi-block-helper </v-icon>
            <div style="width:90%; text-align: center; margin-top:10px; font-size: 20px; font-weight: 650;">{{ lview.couldNotDisplayDocument }}</div>
        </v-layout>

        <img :src="imageFromBytes">

        <v-snackbar
          v-model="showErrorSnackbar"
		  fixed
		  class="snackbar-offline-notifier"
      	>
			<v-container class="align-self-center" style="height: 30px; width: 100%; padding-top: 0px; padding-bottom: 0px;">
				<v-flex xs12 class="align-self-center" style="display: flex;">
					<v-flex xs6 class="align-self-center" style="-webkit-font-smoothing: antialiased; text-rendering: optimiseSpeed;">{{ lmessages.networkError }}</v-flex>
					<v-flex xs6 class="align-self-center">
						<v-btn
							text
							@click="retryConnect()"
							class="align-self-center"
							style="color: primary; font-size: 12px; -webkit-font-smoothing: antialiased; text-rendering: optimiseSpeed;"
						>
							{{ lbuttons.retry }}
						</v-btn>
					</v-flex>
				</v-flex>
			</v-container>
      	</v-snackbar>
    </v-container>
</template>

<script>
import fob_config from '../fob_config.js'
import axios from 'axios';

export default {
    mounted: async function(){
        this.$emit('set-actions', [
            {
                isIcon: true,
                value: 'mdi-download',
                description: 'downloading files',
                onClick: () => { this.downloadDocument(); }
            }
        ]);
        this.$emit('set-display', "BACK_WINDOW");
        if (localStorage.getItem("jwt") != null) {
            this.$emit('set-state', 'loading_overlayer', "");
            let response = null;
            let ackResponse = null;
            try {
                response = await axios({
                    url: fob_config.api_url + "/api/v2/my-documents/" + this.$route.params.document_slug + "/info",
                    method: "GET",
                    headers: {
                        "Authorization": "Bearer " + localStorage.getItem("jwt")
                    }
                });

                if (!response.data.acknowledged){
                    ackResponse = this.acknowledgeDocument();
                }
            } catch (error) {
                this.$emit('set-title', "");
                this.$emit('set-display', 'DEFAULT');
                this.$emit('set-actions', []);
                if(error.response.status == 403){
                    this.$emit("set-state", "ERROR", this.lmessages.forbidden);
                }
                else if (error.response.status == 404){
                    this.$emit("set-state", "ERROR", this.lmessages.documentNotExist);
                }
                else{
                    this.$emit("set-state", "ERROR", this.lmessages.errorOccured);
                }
                return;
            }

            this.$emit('set-title', response.data.file_name);
            this.$emit('set-state', 'DEFAULT');

            this.renderDocument();
        }
        window.addEventListener('online', () => { this.onlineStatus = true; } );
		window.addEventListener('offline', () => { this.onlineStatus = false; } );

        this.$emit('custom-top-bar-style', 'box-shadow:none');
    },
    beforeDestroy(){
        this.$emit('set-display', 'DEFAULT');
        this.$emit('set-actions', []);
        localStorage.removeItem('CurrentlySeenDocument');
    },
    data: function(){
        return {
            documentLink: "",
            documentObjectStream: null,
            imageObjectStream: null,
            imageResized: "resized",
            errorOccurred: false,
            showErrorSnackbar: false,
            onlineStatus: window.navigator.onLine,
            imageFromBytes: null,
        }
    },
    computed: {
        lview: {
            get: function(){
                return this.$t('views.pdfViewer');
            }
        },
        lmessages: {
            get: function(){
                return this.$t('commons.messages');
            }
        },
        lbuttons: {
            get: function(){
                return this.$t('commons.buttons');
            }
        }
    },
    methods: {
        downloadDocument: async function(){
            this.$emit('set-state', "LOADING_OVERLAYER");
            var xhr = new XMLHttpRequest();
            var that = this;
            xhr.onload = function() {
                that.$emit('set-state', 'default');
                var reader = new FileReader();
                reader.onloadend = function() {
                    let currentlySeenDocument = JSON.parse(localStorage.getItem('CurrentlySeenDocument')).name;
                    const linkSource = reader.result;
                    const downloadLink = document.createElement('a');

                    document.body.appendChild(downloadLink);
                    downloadLink.href = linkSource;
                    downloadLink.target = '_self';
                    downloadLink.download = currentlySeenDocument;
                    downloadLink.click();
                }
                reader.readAsDataURL(xhr.response);
            };
            xhr.onerror = function(){
                that.$emit('set-state', 'default');
                that.$emit('display-error', that.lmessages.errorOccurredTitle, that.lview.couldNotDownloadDocument);
            };
            xhr.open('GET', fob_config.api_url + "/api/v2/my-documents/" + this.$route.params.document_slug + "/download");
            xhr.responseType = 'blob';
            xhr.setRequestHeader("Authorization", "Bearer " + localStorage.getItem('jwt'));
            xhr.send();
        },
        acknowledgeDocument: async function(){
            return axios({
                url: fob_config.api_url + "/api/v2/my-documents/" + this.$route.params.document_slug + "/acknowledge",
                method: "PATCH",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                }
            });
        },
        renderDocument: async function(){
            this.$emit('set-state', "LOADING_OVERLAYER");
            var xhr = new XMLHttpRequest();
            var that = this;
            xhr.onload = function() {
                that.$emit('set-state', 'default');
                if (xhr.response.type == "application/pdf"){
                    var src = "/libs/pdfjs/web/viewer.html?file=" + fob_config.api_url + "/api/v2/my-documents/" + that.$route.params.document_slug + "/serve";

                    that.imageObjectStream = null;
                    that.documentObjectStream = src;
                }
                else if(xhr.response.type.includes("image")){
                    var reader = new FileReader();
                    reader.onloadend = function() {
                        that.documentObjectStream = null;
                        that.imageObjectStream = reader.result;
                    }
                    reader.readAsDataURL(xhr.response);
                }
                else{
                    that.errorOccurred = true;
                }
            };
            xhr.onerror = function(){
                that.$emit('set-state', 'default');
                that.errorOccurred = true;
                that.showErrorSnackbar = true;
            };
            xhr.open('GET', fob_config.api_url + "/api/v2/my-documents/" + this.$route.params.document_slug + "/serve");
            xhr.responseType = 'blob';
            xhr.setRequestHeader("Authorization", "Bearer " + localStorage.getItem('jwt'));
            xhr.send();
        },
        retryConnect(){
            if(this.onlineStatus){
                window.location.reload(true);
                this.offlineNotifier = false;
            } else {
                this.offlineNotifier = true;
            }
        },
        beforeDestroy(){
            window.removeEventListener('online', () => { this.onlineStatus = true; });
			window.removeEventListener('offline', () => { this.onlineStatus = false; });
        },
        imageClick(){
            if(this.imageResized == ""){
                this.imageResized = "resized";
            }
            else{
                this.imageResized = "";
            }
        }
    }
}
</script>

<style scoped>
    .pdf-viewer-container {
        padding-top: 0px;
    }
    /*@media only screen and (min-width: 960px){
        .pdf-viewer-container {
            padding-top: 5px !important;
        }
    }*/

    #image-document-viewer-div{
        max-width: 100%;
        overflow: auto;
        margin-left: auto;
        margin-right: auto;
    }

    .image-document-viewer{
        cursor:pointer;
    }
    #image-document-viewer-div.resized .image-document-viewer{
        max-width:100%;
    }
</style>