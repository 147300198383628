<template>
	<v-layout>
		<v-card-text style="padding-left: 15px; padding-right: 15px; margin-bottom: 10px;">
			<!-- company name and logo -->
			<div class="company-address-details-wrapper">
				<!-- upload file row -->
				<div class="company-address-details-row-wrapper">
					<!-- upload file form field -->
					<div class="company-address-details-form-field">
						<div style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;"> {{ lview.logo }} </div>
						<v-file-input
							class="company-address-details-file-field"
							height="10px"
							outlined
							dense
							show-size
							:hint="lview.logoHelpText"
							persistent-hint
							v-model="company.logo"
							:disabled="disabledEdit"
							:error="errors.logo.isError"
							:error-messages="errors.logo.errorMessage"
							@change="() => { (company.logo != null) ? setFieldError('logo', false, '', true) : setFieldError('logo', false, '', false)}"
						></v-file-input>
					</div>
				</div>

				<!-- company name row -->
				<div class="company-address-details-row-wrapper">
					<!-- company name field -->
					<div class="company-address-details-form-field">
						<div class="required" style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;"> {{ lview.name }} </div>
						<v-text-field
							class="company-address-details-text-field"
							v-model="company.name"
							:error="errors.name.isError"
							:error-messages="errors.name.errorMessage"
							:disabled="disabledEdit"
							outlined
							dense
							height="10px"
							required
							maxlength="100"
							counter
							@input="() => { (company.name.length > 0) ? setFieldError('name', false, '', true) : setFieldError('name', false, '', false)}"
						></v-text-field>
					</div>
				</div>
			</div>

			<!-- company address details -->
			<div class="company-address-details-wrapper">
				<!-- street name and number -->
				<div class="company-address-details-row-wrapper">
					<!-- street name -->
					<div class="company-address-details-form-field">
						<div style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;"> {{ lview.street }} </div>
						<v-text-field
							class="company-address-details-text-field"
							v-model="selectAddressDetail.street"
							:disabled="disabledEdit"
							outlined
							dense
							height="10px"
						></v-text-field>
					</div>

					<!-- street number -->
					<div class="company-address-details-form-field">
						<div style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;"> {{ lview.streetNumber }} </div>
						<v-text-field
							class="company-address-details-text-field"
							v-model="selectAddressDetail.streetNumber"
							:disabled="disabledEdit"
							outlined
							dense
							height="10px"
						></v-text-field>
					</div>
				</div>

				<!-- property number and zip code -->
				<div class="company-address-details-row-wrapper">
					<!-- property number -->
					<div class="company-address-details-form-field">
						<div style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;"> {{ lview.propertyNumber }} </div>
						<v-text-field
							class="company-address-details-text-field"
							v-model="selectAddressDetail.propertyNumber"
							:disabled="disabledEdit"
							outlined
							dense
							height="10px"
						></v-text-field>
					</div>

					<!-- zip code -->
					<div class="company-address-details-form-field">
						<div style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;"> {{ lview.zipCode }} </div>
						<v-text-field
							class="company-address-details-text-field"
							v-model="selectAddressDetail.zipCode"
							:disabled="disabledEdit"
							outlined
							dense
							height="10px"
						></v-text-field>
					</div>
				</div>

				<!-- city and region -->
				<div class="company-address-details-row-wrapper">
					<!-- city -->
					<div class="company-address-details-form-field">
						<div style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;"> {{ lview.city }} </div>
						<v-text-field
							class="company-address-details-text-field"
							v-model="selectAddressDetail.city"
							:disabled="disabledEdit"
							outlined
							dense
							height="10px"
							required="false"
						></v-text-field>
					</div>

					<!-- region -->
					<div class="company-address-details-form-field">
						<div style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;"> {{ lview.region }} </div>
						<v-text-field
							class="company-address-details-text-field"
							v-model="selectAddressDetail.region"
							:disabled="disabledEdit"
							outlined
							dense
							height="10px"
							required="false"
						></v-text-field>
					</div>
				</div>

				<!-- company NIP and email -->
				<div class="company-address-details-row-wrapper">
					<!-- company NIP -->
					<div class="company-address-details-form-field">
						<div style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;"> {{ lview.nip }} </div>
						<v-text-field
							class="company-address-details-text-field"
							v-model="company.nip"
							:error="errors.nip.isError"
							:error-messages="errors.nip.errorMessage"
							:disabled="disabledEdit"
							outlined
							dense
							height="10px"
							maxlength="10"
							counter
							type="tel"
							@input="() => { ((company.nip.toString().length > 0) ? setFieldError('nip', false, '', true) : setFieldError('nip', false, '', false))}"
							@keypress="onInputNip"
						></v-text-field>
					</div>

					<!-- company email -->
					<div class="company-address-details-form-field">
						<div class="required" style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;"> {{ lview.email }} </div>
						<v-text-field
							class="company-address-details-text-field"
							v-model="company.email"
							:error="errors.email.isError"
							:error-messages="errors.email.errorMessage"
							:disabled="disabledEdit"
							outlined
							dense
							required
							height="10px"
							maxlength="254"
							counter
							@input="() => { (company.email.length > 0) ? setFieldError('email', false, '', true) : setFieldError('email', false, '', false)}"
						></v-text-field>
					</div>
				</div>

				<!-- company phone number and country -->
				<div class="company-address-details-row-wrapper">
					<div class="company-address-details-form-field">
						<div style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;"> {{ lview.phoneNumber }} </div>
						<v-text-field
							class="company-address-details-text-field"
							v-model="company.phoneNumber"
							:error="errors.phoneNumber.isError"
							:error-messages="errors.phoneNumber.errorMessage"
							:rules="validationRules.phoneNumberRules"
							:disabled="disabledEdit"
							outlined
							dense
							height="10px"
							maxlength="128"
							counter
							@input="() => { (company.phoneNumber.length > 0) ? setFieldError('phoneNumber', false, '', true) : setFieldError('phoneNumber', false, '', false)}"
						></v-text-field>
					</div>

					<div class="company-address-details-form-field">
						<div style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;"> {{ lview.country }} </div>
						<v-select
							:items="countriesToSelect"
							v-model="selectAddressDetail.country"
							:disabled="disabledEdit"
							dense
          					outlined
							:label="lview.pickCountry"
							item-value="key"
							item-text="text"
						></v-select>
					</div>
				</div>

				<!-- buttons to save and cancel -->
				<div class="company-address-details-buttons-wrapper" v-if="disabledEdit == false">
					<div></div>
					<v-btn large @click="onCancel"> {{ lview.cancelButton }} </v-btn>
					<v-btn color="primary" large @click="saveCompany" > {{ lview.saveButton }} </v-btn>
				</div>
			</div>
		</v-card-text>
	</v-layout>
</template>

<script>
import axios from 'axios';
import PhoneNumber from 'awesome-phonenumber';

export default {
	data: function(){
		return {
			errors: {
				name: {
					isError: false,
					errorMessage: "",
					valueEntered: false
				},
				nip: {
					isError: false,
					errorMessage: "",
					valueEntered: false,
				},
				email: {
					isError: false,
					errorMessage: "",
					valueEntered: false,
				},
				phoneNumber: {
					isError: false,
					errorMessage: "",
					valueEntered: false,
				},
				logo: {
					isError: false,
					errorMessage: "",
					valueEntered: false
				}
			},
			company: {
				id: null,
				name: "",
				addressID: null,
				street: "",
				streetNumber: "",
				propertyNumber: "",
				zipCode: "",
				city: "",
				country: "",
				nip: "",
				email: "",
				phoneNumber: "",
				logo: [],
			},
			addresses: [],
			selectAddressID: null,
			selectAddressDetail: {
				street: null,
				streetNumber: null,
				propertyNumber: null,
				zipCode: null,
				city: null,
				region: null,
				country: null
			},
			method: null,
			countriesToSelect: [],

			validationRules: {
				phoneNumberRules: [ v => this.validatePhoneNumber(v) || this.errors.phoneNumber.errorMessage ],
			},
			disabledEdit: true,
		}
	},

	methods: {
		onCancel () {
			this.$router.go(-1);
		},
		onInputNip (event) {
			if (this.company.nip != null) {
				if (this.company.nip.toString().length > 9) {
					let nip = this.company.nip.slice(0, 9);
					this.company.nip = nip;
				}
			}
		},
		setFieldError: function(field, value, message, valueEntered) {
			this.errors[field].isError = value;
			this.errors[field].errorMessage = message;
			this.errors[field].valueEntered = valueEntered;
		},
		async saveCompany() {
			// check text inputs
			let error = false;

			if (!this.errors.name.valueEntered) {
				this.setFieldError('name', true, this.lview.errors.name.required, false);
				error = true;
			}

			if (!this.errors.email.valueEntered) {
				this.setFieldError('email', true, this.lview.errors.email.required, false);    
				error = true;
			}

			if (this.errors.nip.valueEntered) {
				if (this.company.nip != null) {
					if (this.company.nip.toString().length != 10) {
						this.setFieldError('nip', true, this.lview.errors.nip.tooShort, true);
						error = true;
					}
				} else {
					this.company.nip = ""; 
				}
			} else {
				this.company.nip = "";     
			}

			if (this.errors.phoneNumber.isError) {
				error = true;
			}

			if (error) return;

			this.$emit("set-state", "LOADING_OVERLAYER", this.lview.loadingSave);

			let data = new FormData();
			data.append("email", this.company.email);
			data.append("name", this.company.name);

			if (this.method == 'post') {
				data.append("fob_billing", true);

				let createError = false;
				// if logo file was provided, at first create company with assigned logo 
				if (this.company.logo) {
					data.append("upload_file", this.company.logo);

					// send request to create company with logo
					try {
						let response = await axios({
							method: "POST",
							url: localStorage.getItem('current_env') + '/api/v2/admin/company/',
							headers: {
								"Content-Type": "multipart/form-data",
								Authorization: "Bearer " + localStorage.getItem("jwt")
							},
							data: data
						});

						if (response != null && response != undefined) {
							this.company.id = response.data.id;
							this.company.name = response.data.name;
							this.company.email = response.data.email;
							this.company.nip = response.data.nip;
							this.company.phoneNumber = response.data.contact_number1;

							if (response.data.postal_address != null) {
								this.selectAddressID = response.data.postal_address;
								//this.onChangeSelect();
							}
						}

					} catch (error) {
						createError = true; // check if we have to send next request (to assign postal address - unfortunately we can't send both data in one request -> blame FormData)
						if (error.isAxiosError) {
							if (error.response.status == 400) {
								if (error.response.data.email != null && error.response.data.email != undefined) {
									this.setFieldError('email', true, this.lerrors.company.email.string, true);
								}
								if (error.response.data.nip != null && error.response.data.nip != undefined) {
									this.setFieldError('nip', true, this.lerrors.company.nip.string, true);
								}
								if (error.response.data.fob_billing != null && error.response.data.fob_billing != undefined) {
									this.$emit('display-error', this.lerrors.company.fob_billing.header, this.lerrors.company.fob_billing.string, String(error));
								}
								if (error.response.data.upload_file != null && error.response.data.upload_file != undefined) {
									for (var i=0; i < this.lerrors.company.upload_file.length; i++) {
										if (this.lerrors.company.upload_file[i].code == error.response.data.upload_file.code) {
											this.setFieldError('logo', true, this.lerrors.company.upload_file[i].string, true);
										} 
									}
								}
								if (error.response.data.contact_number1 != null && error.response.data.contact_number1 != undefined) {
									this.setFieldError('phoneNumber', true, this.lerrors.company.contact_number1.string, true);    
								} 
							}
							else if (error.response.status == 401 || error.response.status == 403) {
								this.$router.push("/login");
							}
						} else {
							this.$emit('display-error', this.lerrors.company.default_error.header, this.lerrors.company.default_error.string, String(error));
						}                  
					}
				}

				// if company with logo was created correctly or logo was not provided
				if (!createError) {
					try {
						let response = await axios({
							method: "PATCH",
							url: localStorage.getItem('current_env') + '/api/v2/admin/company/' + this.company.id,
							headers: {
								"Content-Type": "application/json",
								Authorization: "Bearer " + localStorage.getItem("jwt")
							},
							data: {
								name: this.company.name,
								email: this.company.email,
								contact_number1: this.company.phoneNumber,
								nip: this.company.nip,
								fob_billing: true,
								postal_address: {
									street_name: this.selectAddressDetail.street,
									street_number: this.selectAddressDetail.streetNumber,
									property_number: this.selectAddressDetail.propertyNumber,
									zip_code: this.selectAddressDetail.zipCode,
									city: this.selectAddressDetail.city,
									country: this.selectAddressDetail.country,
									region: this.selectAddressDetail.region,
								}
							}
						});

						if (response != null && response != undefined) {
							this.$router.go(-1);
						}
					} catch (error) {
						if (error.isAxiosError) {
							if (error.response.status == 400) {
								if (error.response.data.email != null && error.response.data.email != undefined) {
									this.setFieldError('email', true, this.lerrors.company.email.string, true);
								}
								if (error.response.data.nip != null && error.response.data.nip != undefined) {
									this.setFieldError('nip', true, this.lerrors.company.nip.string, true);
								}
								if (error.response.data.fob_billing != null && error.response.data.fob_billing != undefined) {
									this.$emit('display-error', this.lerrors.company.fob_billing.header, this.lerrors.company.fob_billing.string, String(error));
								}
								if (error.response.data.upload_file != null && error.response.data.upload_file != undefined) {
									for (var i=0; i < this.lerrors.company.upload_file.length; i++) {
										if (this.lerrors.company.upload_file[i].code == error.response.data.upload_file.code) {
											this.setFieldError('logo', true, this.lerrors.company.upload_file[i].string, true);
										} 
									}
								}
								if (error.response.data.contact_number1 != null && error.response.data.contact_number1 != undefined) {
									this.setFieldError('phoneNumber', true, this.lerrors.company.contact_number1.string, true);    
								}
							}
							else if (error.response.status == 401 || error.response.status == 403) {
								this.$router.push("/login");
							}
						} else {
							this.$emit('display-error', this.lerrors.company.default_error.header, this.lerrors.company.default_error.string, String(error));
						}
					}
				}
			}

			if (this.method == 'patch') {
				let patchError = false;
				// patch for company logo
				if (this.company.logo) {
					data.append("upload_file", this.company.logo); // add company logo if it's provided

					try {
						let response = await axios({
							method: "PATCH",
							url: localStorage.getItem('current_env') + '/api/v2/admin/company/' + this.company.id,
							headers: {
								"Content-Type": "multipart/form-data",
								Authorization: "Bearer " + localStorage.getItem("jwt")
							},
							data: data
						});

						if (response != null && response != undefined) {
							this.company.name = response.data.name;
							this.company.email = response.data.email;
							this.company.nip = response.data.nip;
							this.company.phoneNumber = response.data.contact_number1;

							if (response.data.postal_address != null) {
								this.selectAddressID = response.data.postal_address;
								//this.onChangeSelect();
							}
						}

					} catch(error) {
						patchError = true;
						if (error.isAxiosError) {
							if (error.response.status == 400) {
								if (error.response.data.email != null && error.response.data.email != undefined) {
									this.setFieldError('email', true, this.lerrors.company.email.string, true);
								}
								if (error.response.data.nip != null && error.response.data.nip != undefined) {
									this.setFieldError('nip', true, this.lerrors.company.nip.string, true);
								}
								if (error.response.data.fob_billing != null && error.response.data.fob_billing != undefined) {
									this.$emit('display-error', this.lerrors.company.fob_billing.header, this.lerrors.company.fob_billing.string, String(error));
								}
								if (error.response.data.upload_file != null && error.response.data.upload_file != undefined) {
									for (var i=0; i < this.lerrors.company.upload_file.length; i++) {
										if (this.lerrors.company.upload_file[i].code == error.response.data.upload_file[0].code) {
											this.setFieldError('logo', true, this.lerrors.company.upload_file[i].string, true);
										} 
									}
								}
								if (error.response.data.contact_number1 != null && error.response.data.contact_number1 != undefined) {
									this.setFieldError('phoneNumber', true, this.lerrors.company.contact_number1.string, true);    
								}
							}
							else if (error.response.status == 401 || error.response.status == 403) {
								this.$router.push("/login");
							}
						} else {
							this.$emit('display-error', this.lerrors.company.default_error.header, this.lerrors.company.default_error.string, String(error));
						} 
					}
				}

				// if updating logo was not necessary or it ended successfully update rest of company information
				if (!patchError) {
					try {
						let response = await axios({
							method: "PATCH",
							url: localStorage.getItem('current_env') + '/api/v2/admin/company/' + this.company.id,
							headers: {
								"Content-Type": "application/json",
								Authorization: "Bearer " + localStorage.getItem("jwt")
							},
							data: {
								name: this.company.name,
								email: this.company.email,
								contact_number1: this.company.phoneNumber,
								nip: this.company.nip,
								postal_address: {
									street_name: this.selectAddressDetail.street,
									street_number: this.selectAddressDetail.streetNumber,
									property_number: this.selectAddressDetail.propertyNumber,
									zip_code: this.selectAddressDetail.zipCode,
									city: this.selectAddressDetail.city,
									country: this.selectAddressDetail.country,
									region: this.selectAddressDetail.region,
								}
							}
						});

						// company data updated properly
						if (response != null && response != undefined) {
							this.$router.go(-1);
						}
					} catch (error) {
						if (error.isAxiosError) {
							if (error.response.status == 400) {
								if (error.response.data.email != null && error.response.data.email != undefined) {
									this.setFieldError('email', true, this.lerrors.company.email.string, true);
								}
								if (error.response.data.nip != null && error.response.data.nip != undefined) {
									this.setFieldError('nip', true, this.lerrors.company.nip.string, true);
								}
								if (error.response.data.fob_billing != null && error.response.data.fob_billing != undefined) {
									this.$emit('display-error', this.lerrors.company.fob_billing.header, this.lerrors.company.fob_billing.string, String(error));
								}
								if (error.response.data.upload_file != null && error.response.data.upload_file != undefined) {
									for (var i=0; i < this.lerrors.company.upload_file.length; i++) {
										if (this.lerrors.company.upload_file[i].code == error.response.data.upload_file[0].code) {
											this.setFieldError('logo', true, this.lerrors.company.upload_file[i].string, true);
										} 
									}
								}
								if (error.response.data.contact_number1 != null && error.response.data.contact_number1 != undefined) {
									this.setFieldError('phoneNumber', true, this.lerrors.company.contact_number1.string, true);    
								}
							}
							else if (error.response.status == 401 || error.response.status == 403) {
								this.$router.push("/login");
							}
						} else {
							this.$emit('display-error', this.lerrors.company.default_error.header, this.lerrors.company.default_error.string, String(error));
						}
					}
				}
			}

			this.$emit('set-state', 'DEFAULT');
		},
		/**
		 * Validates phone number field value (PL, GB, US, DE, UA) and updates phone number error field data.
		 * @param phoneNumberValue {String} User's phone number to validate.
		 * @returns {Boolean} Boolean value whether phone number is valid or not.
		 */
		validatePhoneNumber: function(phoneNumberValue) {
			if (this.errors.phoneNumber.valueEntered) {
				if (phoneNumberValue != null) {
					let validators = [
						new PhoneNumber(phoneNumberValue, "PL"),
						new PhoneNumber(phoneNumberValue, "GB"),
						new PhoneNumber(phoneNumberValue, "DE"),
						new PhoneNumber(phoneNumberValue, "UA"),
						new PhoneNumber(phoneNumberValue, "US")
					];

					let anyValidatorValid = false, value;
					validators.forEach(validator => {
						value = validator.isValid();
						if (value) {
							anyValidatorValid = true;
						}
					});

					if (!anyValidatorValid) {
						this.setFieldError("phoneNumber", true, this.lview.errors.phoneNumber.wrong, true);
						return false;
					}

					if (!phoneNumberValue.toString().startsWith('+')) {
						this.setFieldError("phoneNumber", true, this.lview.errors.phoneNumber.areaCode, true);
						return false;
					}


					this.setFieldError("phoneNumber", false, "", true);
					return true;
				}
				this.setFieldError("phoneNumber", false, "", false);
				return true;
			}
			return true;
		},
	},

	mounted: async function () {
		this.$emit('set-display', 'BACK_WINDOW');
		this.$emit('set-title', this.lview.title);

		this.$emit('set-state', 'LOADING', this.lview.loading);

		this.countriesToSelect = this.lselectors.countries;
		// check permissions
		let userPermissions = localStorage.getItem("user_permissions");

		if (!userPermissions.includes("fob.admin_view")) {
			this.$emit('set-state', 'ERROR', this.lview.youDoNotHavePriviledgesToThisView);
			return;
		} 

		// get comapny with fob_billing = true
		try {
			let responseCompany = await axios({
				method: "GET",
				url: localStorage.getItem('current_env') + '/api/v2/admin/company/',
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + localStorage.getItem("jwt")
				}
			});

			if (responseCompany != null && responseCompany != undefined) {
				this.method = 'patch';
				
				// check change permissions
				if (userPermissions.includes("fob.admin_change")) {
					this.disabledEdit = false;
				}

				this.company.id = responseCompany.data.id;

				this.company.name = responseCompany.data.name;
				if (this.company.name != null && this.company.name != "") this.setFieldError('name', false, '', true);

				this.company.email = responseCompany.data.email;
				if (this.company.email != null && this.company.email != "") this.setFieldError('email', false, '', true);

				this.company.phoneNumber = responseCompany.data.contact_number1;
				if (this.company.phoneNumber != null && this.company.phoneNumber != "") this.setFieldError('phoneNumber', false, '', true);

				this.company.nip = responseCompany.data.nip;
				if (this.company.nip != null && this.company.nip != "") this.setFieldError('nip', false, '', true);

				// assigning postal address data
				if (responseCompany.data.postal_address != null && responseCompany.data.postal_address != undefined) {
					this.company.addressID = responseCompany.data.postal_address.id;
					this.company.street = responseCompany.data.postal_address.street_name;
					this.company.streetNumber = responseCompany.data.postal_address.street_number;
					this.company.propertyNumber = responseCompany.data.postal_address.property_number;
					this.company.zipCode = responseCompany.data.postal_address.zip_code;
					this.company.city = responseCompany.data.postal_address.city;
					this.company.country = responseCompany.data.postal_address.country;
					
					this.selectAddressDetail.street = responseCompany.data.postal_address.street_name;
					this.selectAddressDetail.streetNumber = responseCompany.data.postal_address.street_number;
					this.selectAddressDetail.propertyNumber = responseCompany.data.postal_address.property_number;
					this.selectAddressDetail.zipCode = responseCompany.data.postal_address.zip_code;
					this.selectAddressDetail.city = responseCompany.data.postal_address.city;
					this.selectAddressDetail.region = null;
					this.selectAddressDetail.country = responseCompany.data.postal_address.country;
				}
				// address 
				
			}
		} catch (error) {
			if (error.isAxiosError) {
				if (error.response.status == 404) {
					this.method = 'post';

					if (userPermissions.includes("fob.admin_add")) {
						this.disabledEdit = false;
					}

				} else {
					this.$emit('set-state', 'DEFAULT');
					this.$emit('set-state', 'ERROR', this.lview.couldNotFetchData);     
					return;               
				}
			} else {
				this.$emit('set-state', 'DEFAULT');
				this.$emit('set-state', 'ERROR', this.lview.couldNotFetchData);
				return;
			}
		}

		this.$emit('set-state', 'DEFAULT');
	},

	beforeDestroy: function(){
		this.$emit('set-display', 'default');
		this.$emit('set-title', '');
	},

	computed: {
		lview: {
			get: function(){
				return this.$t('views.company');
			}
		},

		lerrors: { 
			get: function() { 
				return this.$t('errors'); 
			} 
		},

		lselectors: {
			get: function() {
				return this.$t('commons.selectors');
			}
		}
	}
}
</script>

<style>
.company-address-details-wrapper {
	display: grid;
	grid-gap: 0;
	grid-template-columns: 1fr;
}

.company-address-details-row-wrapper {
	display: grid;
	grid-gap: 0;
	grid-template-columns: 0.5fr 0.5fr;
	padding-top: 20px;
}

@media only screen and (max-width: 500px) {
	.company-address-details-row-wrapper {
		display: grid;
		grid-gap: 0;
		grid-template-columns: 1fr;
		padding-top: 20px;
	}
}

.company-address-details-text-field {
	width: 83%;
}

@media only screen and (max-width: 500px) {
	.company-address-details-text-field {
		width: 100%;
	}
}


.company-address-details-file-field {
	width: 83%;
}

@media only screen and (max-width: 500px) {
	.company-address-details-file-field {
		width: 100%;
	}
}


.company-address-details-form-field {
	display: inline-block;
}

.company-address-details-buttons-wrapper {
	display: grid;
	grid-gap: 15px;
	grid-template-columns: 1fr 100px 100px;
	padding-right: 20px;
}
</style>

<style scoped>
	.form-container {
		margin-left: auto; 
		margin-right: auto;
		width: 80%;
		max-width: 350px;
		text-align: center;
	}
	.form-input {
		max-width: 250px;
		margin-left: auto;
		margin-right: auto;
	}
</style>