<!--
SharedMasters 2019 - Fast OnBoarding Application

__copyright__ = "Copyright (C) 2019, Shared Masters sp. z o.o."
__license__ = "Fast OnBoarding can not be copied and/or distributed without the express permission of Shared Masters sp. z o.o."
__version__ = "0.0.1-pre-beta"
__author__ = "Jan Przychodniak, .."
__maintainer__ = "Jan Przychodniak"
__email__ = "jan@sharedmasters.com"
__status__ = "DEV"


USAGE
-------
<extend-offboarding-dialog
  ref='yourReference'

  ...
></extend-offboarding-dialog>
$refs.youReference.openDialog();

props:
  - boardingsToUpdate: {Array}
    MIN {
      boardingId {Integer}
      offBoardingDate {Date}
    }

  - initialDate: {String}, initial date to display
  - warning: {String}, warning to display, leave as null if none
  - headline: {String}, headline to display, leave as null for default

events:
  - confirm
  - error
  - request-sending-start
  - request-sending-end

errors:
  structure: {
    errorCodeShort,
    errorCodeLong,
    title,
    message,
    details
  }

  errorsId: EOD

-->
<template>
  <v-layout row justify-center>
    <v-dialog persistent v-model="dialog" max-width="350">
      <v-card dense>
        <div class="ExtendOffBoardingDialog">
          <v-card-title style="text-align:center; padding-bottom:0">
          <span class="headline">{{ dialogHeadline }}</span>
        </v-card-title>

        <v-card-text>
          <v-card elevation="1" align-center class="projects-table">
            <v-card-title primary-title class="projects-table-title" style="position:relative">
              {{ lview.chooseProjects }}
            </v-card-title>

            <v-list
              style="width:100%; max-height: 110px; overflow-y: auto; overflow-x: hidden"
            >
              <v-list-item style="padding:0 5px;" v-for="project in tableProjects" :key="project.id">
                <v-flex xs12>
                  <v-checkbox
                    v-model="checkboxes[project.id.toString()]"
                    color="rgba(4, 202, 90, 1)"
                    style="margin-top: 0; margin-bottom: 0;"
                    :label="project.name"
                  ></v-checkbox>
                </v-flex>
              </v-list-item>
            </v-list>
          </v-card>

          <v-layout align-center style="margin-top: 20px;">
              <!-- DatePicker -->
              <v-dialog
                ref="datePickerDialog"
                v-model="datePickerDialog"
                :return-value.sync="date"
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date"
                    :label="lview.pickNewOffBoardingDate"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                    style="padding: 0 10px; margin-top: 30px; width: 100%;"
                  ></v-text-field>
                </template>
                <v-date-picker color="rgba(4, 202, 90, 1)" v-model="date" scrollable :min="minDate">
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="rgba(4, 202, 90, 1)"
                    @click="datePickerDialog = false"
                  >{{ lbuttons.cancel }}</v-btn>
                  <v-btn
                    text
                    color="rgba(4, 202, 90, 1)"
                    @click="$refs.datePickerDialog.save(date)"
                  >{{ lbuttons.confirm }}</v-btn>
                </v-date-picker>
              </v-dialog>

              <!-- Warning message -->
              <div style="text-align:center; width:100%" class="caption" justify-center align-center
                v-if="warningMessage != null"
              >
                <v-layout justify-center align-center>
                  <div
                    style="height:100%; padding-right:5px; float:left;"
                    justify-center
                    align-center
                  >
                    <v-icon>warning</v-icon>
                  </div>
                  <div style="height:100%; float:left; text-align:justify" align-center>
                    <div>{{ warningMessage }}</div>
                  </div>
                  <div style="clear:both"></div>
                </v-layout>
              </div>
            </v-layout>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="closeDialog()">{{ lbuttons.cancel }}</v-btn>
            <v-btn color="green darken-1" text @click="onAcceptDialog()">{{ lbuttons.confirm }}</v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import axios from "axios"

export default {
  name: "extendOffboardingDialog",
  mounted: function() {},
  data() {
    return {
      dialog: false,
      datePickerDialog: false,
      date: null,
      warningMessage: null,
      dialogHeadline: null,
      boardings: null,

      checkboxes: {},
      tableProjects: [],

      minDate: null,
    };
  },
  computed: {
    lview: {
      get: function() {
        return this.$t("views.commons.extendOffboardingDialog");
      }
    },
    lmessages: {
      get: function() {
        return this.$t("commons.messages");
      }
    },
    lbuttons: {
      get: function() {
        return this.$t("commons.buttons");
      }
    },
    lerrors: { get: function() { return this.$t('errors'); } },
  },
  props: {
    initialDate: {
      type: String,
      required: false,
      default: new Date()
        .toJSON()
        .slice(0, 10)
        .replace("/-/g", "/")
    },
    warning: {
      type: String,
      required: false,
      default: null
    },
    headline: {
      type: String,
      required: false,
      deafult: null
    },
    boardingsToUpdate: {
      type: Array,
      required: true
    },
    assignedProjects: {
      type: Array,
      default: () => []
    },
    markedProjectsIds: {
      type: Array,
      default: () => []
    }
  },
  watch: {},
  methods: {
    openDialog() {
      this.loadPropsData();

      // Setting up table for projects displaying
      this.setupCheckboxes();
      this.tableProjects.splice(0, this.tableProjects.length);
      for (var i = 0; i < this.assignedProjects.length; i++) {
        this.tableProjects.push({
          id: this.assignedProjects[i].id,
          name: this.assignedProjects[i].name,
          sortName:
            this.assignedProjects[i].id == -1
              ? "#"
              : this.assignedProjects[i].name
        });

        this.tableProjects.sort((a, b) => {
          if (a.sortName < b.sortName) return -1;
          else if (a.sortName == b.sortName) return 0;
          else return 1;
        });
      }

      this.minDate = new Date()
        .toJSON()
        .slice(0,10)
        .replace("/-/", "/");

      this.$nextTick(() => (this.dialog = true));
    },
    loadPropsData() {
      if(this.initialDate != null)
        this.date = this.initialDate;
      else
        this.date = new Date().toJSON().slice(0,10).replace("/-/", "/");

      this.warningMessage = this.warning;

      if (this.headline != null) {
        this.dialogHeadline = this.headline;
      } else {
        this.dialogHeadline = this.lview.defaultHeadline;
      }
    },
    closeDialog(){
      this.dialog = false;
    },
    onAcceptDialog: async function(){
      this.$emit("request-sending-start");

      var projectIds = [];
      for(var key in this.checkboxes){
        if(this.checkboxes[key]){
          projectIds.push(parseInt(key));
        }
      }

      var boardings = this.filterBoardings(projectIds);

      var packages = [];
      var errors = [];
      var modified = [];

      var projectAssignmentIds = [];
      for(var prjIdx = 0; prjIdx < boardings.length; prjIdx++) {
          projectAssignmentIds.push(boardings[prjIdx].projectAssignmentId);
      }

      let responsePromise = axios({
          method: "PATCH",
          url: localStorage.getItem("current_env") + "/api/v2/boardings/projects-assignment/",
          headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("jwt")
          },
          data: {
              projects_assignment_id: projectAssignmentIds,
              boarding: {
                  off_boarding_date: this.date
              }
          }
      });

      try {
          var response = await responsePromise;

          for(var brdIdx = 0; brdIdx < boardings.length; brdIdx++) {
              boardings[brdIdx].offBoardingDate = new Date(this.date);
              boardings[brdIdx].boardingId = response.data.boardings[brdIdx].id;

              modified.push(boardings[brdIdx]);
          }
      } catch (error) {
          this.$emit("request-sending-end");

          if (error.isAxiosError) {
              if (error.response.status == 403) {
                  this.$emit('error', {
                      title: this.lmessages.forbiddenOperation,
                      message: this.lmessages.youDoNotHavePriviledgesToDoThatOperation,
                      errorCodeShort: "CNFBRD#5",
                      errorCodeLong: "REQUEST_FAILED",
                      details: String(error)
                  });
              }
              else if (error.response.data != null && error.response.data != undefined) {
                  if (error.response.data.boarding != null && error.response.data.boarding != undefined) {
                      if (error.response.data.boarding.off_boarding_date != null && error.response.data.boarding.off_boarding_date != undefined) {
                          this.$emit('error', {
                            title: this.lerrors.boarding.off_boarding_date.header,
                            message: this.lerrors.boarding.off_boarding_date.string,
                            errorCodeShort: this.lerrors.boarding.off_boarding_date.code,
                            errorCodeLong: this.lerrors.boarding.off_boarding_date.code,
                            details: String(error)
                          });
                      }
                  }
                  
                  if (error.response.data.projects_assignment_id != null && error.response.data.projects_assignment_id != undefined) {
                    this.$emit('error', {
                      title: this.lerrors.projects_assignment_id.header,
                      message: this.lerrors.projects_assignment_id.string,
                      errorCodeShort: this.lerrors.projects_assignment_id.code,
                      errorCodeLong: this.lerrors.projects_assignment_id.code,
                      details: String(error)
                    });									
                  }
              }
          }
          else {
              errors.push(error);
              this.$emit('error', {
                  title: this.lmessages.requestFailedTitle,
                  message: this.$t("commons.messages.requestBoardingFailed", { id: packages[i].boarding.boardingId }),
                  errorCodeShort: "EOD#1",
                  errorCodeLong: "REQUEST_FAILED",
                  details: String(error)
              });
          }
      }

      // Sending requests
      /*for(var i = 0; i < boardings.length; i++){
        packages.push({
          boarding: boardings[i],
          promise: axios({
            method: "PATCH",
            url: localStorage.getItem("current_env") + "/api/v2/boardings/" + boardings[i].projectAssignmentId,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("jwt")
            },
            data: {
              boarding: {
                off_boarding_date: this.date
              }
            }
          })
        });
      }*/

      // Awaiting requests
      /*var response;
      for(var i = 0; i < packages.length; i++){
        try{
          response = await packages[i].promise;

          packages[i].boarding.offBoardingDate = new Date(response.data.boarding.off_boarding_date);
          packages[i].boarding.boardingId = response.data.boarding.id;

          modified.push(packages[i].boarding);
        } catch(error){
          
        }
      }*/

      this.$emit("request-sending-end");

      // If any requests were successful, finish editing
      if(modified.length > 0){
        this.$emit("request-sending-success", modified);
        this.closeDialog();
      }
    },

    filterBoardings: function(projectIds) {
      var result = [];

      var boarding;
      for(var i = 0; i < this.boardingsToUpdate.length; i++){
        boarding = this.boardingsToUpdate[i];

        if(projectIds.indexOf(boarding.projectId) != -1){
          result.push(boarding);
        }
      }

      return result;
    },

    setupCheckboxes() {
      this.checkboxes = {};

      for (var i = 0; i < this.assignedProjects.length; i++) {
        this.checkboxes[this.assignedProjects[i].id] = false;
      }

      for (var i = 0; i < this.markedProjectsIds.length; i++) {
        this.checkboxes[this.markedProjectsIds[i]] = true;
      }
    }
  }
};
</script>

<style>
div.ExtendOffBoardingDialog .projects-table .v-subheader {
  padding: 0;
}

div.ExtendOffBoardingDialog .projects-table .v-list__tile {
  padding: 0;
}
</style>

<style scoped>
.projects-table {
  padding: 0;
}

.projects-table-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 5px;
}
</style>