import Vue from 'vue'

import NavigationSide from '@/components/commons/NavigationSide'
import ConfirmBoardingDialog from '@/components/commons/ConfirmBoardingDialog'
import ResignDialog from '@/components/commons/ResignDialog'
import ChangeBoardingPlansDialog from '@/components/commons/ChangeBoardingPlansDialog'
import ChangeEmployeePlansDialog from '@/components/commons/ChangeEmployeePlansDialog'
import ChangeEmployeeProjectDialog from '@/components/commons/ChangeEmployeeProjectDialog'
import EmployeesFiltersDialog from '@/components/commons/EmployeesFiltersDialog'
import SendEmployeeMessageDialog from '@/components/commons/SendEmployeeMessageDialog'
import OffBoardingDialog from '@/components/commons/OffBoardingDialog'
import RemoveBoardingDialog from '@/components/commons/RemoveBoardingDialog'

import YesNoDialog from '@/components/commons/YesNoDialog'
import AttachPicturesDialog from '@/components/commons/AttachPicturesDialog'
import PostalAddressEditDialog from '@/components/commons/PostalAddressEditDialog'
import AlertWithDetails from '@/components/commons/AlertWithDetails'
import ErrorDialog from '@/components/commons/ErrorDialog'
import ExtendOffboardingDialog from '@/components/commons/ExtendOffboardingDialog'

import NullableRating from '@/components/commons/NullableRating'
import ProjectsPickTable from '@/components/commons/ProjectsPickTable'

import TerminateBoardingForm from '@/components/commons/forms/TerminateBoardingForm'

import FOBAutocompleter from '@/components/commons/WorkersAutocompleter';
import CustomWorkerDocumentDataTableFooter from "@/components/commons/CustomWorkerDocumentDataTableFooter";

import UserDialog from "@/components/commons/UserDialog";
import RemoveAddressDialog from "@/components/commons/RemoveAddressDialog";
import AddressDialog from '@/components/commons/AddressDialog';

Vue.component('navigation-side-content', NavigationSide);

Vue.component('confirm-boarding-dialog', ConfirmBoardingDialog);
Vue.component('resign-dialog', ResignDialog);
Vue.component('change-boarding-plans-dialog', ChangeBoardingPlansDialog);
Vue.component('change-employee-plans-dialog', ChangeEmployeePlansDialog);
Vue.component('change-employee-project-dialog', ChangeEmployeeProjectDialog);
Vue.component('employees-filters-dialog', EmployeesFiltersDialog);
Vue.component('send-employee-message-dialog', SendEmployeeMessageDialog)
Vue.component('off-boarding-dialog', OffBoardingDialog);
Vue.component('remove-boarding-dialog', RemoveBoardingDialog);
Vue.component("extend-offboarding-dialog", ExtendOffboardingDialog);

Vue.component('yes-no-dialog', YesNoDialog);
Vue.component('attach-pictures-dialog', AttachPicturesDialog);
Vue.component('postal-address-edit-dialog', PostalAddressEditDialog);
Vue.component('alert-with-details', AlertWithDetails);
Vue.component('error-dialog', ErrorDialog);

Vue.component('nullable-rating', NullableRating);
Vue.component('projects-pick-table', ProjectsPickTable);

Vue.component('terminate-boarding-form', TerminateBoardingForm);
Vue.component('fob-worker-autocompleter', FOBAutocompleter);

Vue.component('worker-document-data-table-footer', CustomWorkerDocumentDataTableFooter);
Vue.component('user-dialog', UserDialog);
Vue.component('remove-address-dialog', RemoveAddressDialog);
Vue.component('address-dialog', AddressDialog);