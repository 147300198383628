<!--
SharedMasters 2019 - Fast OnBoarding Application

__copyright__ = "Copyright (C) 2019, Shared Masters sp. z o.o."
__license__ = "Fast OnBoarding can not be copied and/or distributed without the express permission of Shared Masters sp. z o.o."
__version__ = "0.0.1-pre-beta"
__author__ = "Jan Przychodniak, .."
__maintainer__ = "Jan Przychodniak"
__email__ = "jan@sharedmasters.com"
__status__ = "DEV"


USAGE
-------
<off-boarding-dialog
  ref='yourReference'

  ...
></off-boarding-dialog>
$refs.youReference.openDialog();

props:
  - workers {Array}, workers to modify
	[
	  {
		workerId,
		offBoardingDate
	  }
	]

  - defaultDate {String}, default date assigned when date is null [optional]
  - date {String}, date assigned while dialog is being open [optional]

  - assignedProjects {Array}, project to display as updatable
	[
	  {
		id: {Int},
		name: {String}
	  }
	]
  - markedProjectsIds {Array}, Ids of projects to mark as picked

events:
  - request-sending-start
  - request-sending-end
  - request-sending-success
  - error

errors:
  structure: {
	errorCodeShort,
	errorCodeLong,
	title,
	message,
	details
  }

  errorsId: OFFBRD

-->

<template>
  <v-dialog persistent v-model="visible" max-width="290">
    <v-card dense>
      <div class="OffBoardingDialog">
        <v-card-title style="text-align:center; padding-bottom:10px;">
          <span class="headline">{{ lview.title }} </span>
        </v-card-title>

        <v-divider></v-divider>

        <!-- <v-card-text> -->
        <v-stepper v-model="stepper" alt-labels style="box-shadow: none;">
          <v-stepper-header style="box-shadow: none;">
            <v-stepper-step
              :complete="stepper > 1"
              style="margin-left: -45px;"
              step="1"
            >{{lview.reason}}</v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              :complete="stepper > 2"
              step="2"
            >{{lview.date}}</v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step 
              v-if="displayRatingSection"
              :complete="stepper > 3"
              step="3"
            >{{lview.rating}}</v-stepper-step>

            <v-divider v-if="displayRatingSection"></v-divider>

            <v-stepper-step
              :step="displayRatingSection? 4:3"
              style="margin-right: -45px;"
            >{{lview.details}}</v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1" class="pt-0">
              <v-select v-model="pickedResignReason"
                :items="reasons"
                item-text="name"
                item-value="id"
                :label="lview.reason"
                style="margin-top: 10px;"
              ></v-select>

              <v-textarea
                v-model="comment"
                :auto-grow="true"
                :rows="1"
                :clearable="true"
                :label="lview.comment"
                counter
                maxlength="500"
              ></v-textarea>
         
              <div class="action-buttons-wrapper">
                <v-btn text @click="closeDialog()">{{ lbuttons.cancel }}</v-btn>
                <v-btn :disabled="pickedResignReason==null || pickedResignReason==undefined" color="green darken-1" text @click="stepper=2">{{ lbuttons.continue }}</v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content step="2" class="pt-0">
              <!-- <v-card elevation="1" align-center class="projects-table"> -->
                <!-- <v-card-title primary-title class="projects-table-title" style="position:relative">
                  {{ lview.pickProjects }}
                </v-card-title> -->

                <!-- <v-list
                  style="width:100%; max-height: 110px; overflow-y: auto; overflow-x: hidden"
                >
                  <v-list-item style="padding:0 5px;" v-for="project in tableProjects" :key="project.id">
                    <v-flex xs12>
                      <v-checkbox
                        v-model="checkboxes[project.id.toString()]"
                        color="rgba(4, 202, 90, 1)"
                        style="margin-top: 0; margin-bottom: 0;"
                        :label="project.name"
                      ></v-checkbox>
                    </v-flex>
                  </v-list-item>
                </v-list> -->
              <!-- </v-card> -->
              <v-select
                v-model="selectedProject"
								:items="tableProjects"
								item-text="name"
								item-value="id"
								:label="lview.project"
              ></v-select>

              <!-- <v-layout align-center style="margin-top: 20px;"> -->
                <!-- <v-checkbox v-model="datePickerEnabled" hide-details color="rgba(4, 202, 90, 1)"></v-checkbox> -->

                <!-- Offboarding date -->
                <v-dialog
                  ref="dialogOffboardingDate"
                  v-model="offBoardingDatePicker.visible"
                  :return-value.sync="offBoardingDatePicker.date"
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <div
                      style="width:100%; margin-bottom: 25px;"
                    >
                      <v-text-field
                        style="width:100%"
                        hide-details
                        v-model="offBoardingDatePicker.date"
                        :label="lview.offBoardingDate"
                        append-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </div>
                  </template>

                  <v-date-picker color="rgba(4, 202, 90, 1)" v-model="offBoardingDatePicker.date" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="rgba(4, 202, 90, 1)"
                      @click="offBoardingDatePicker.visible = false"
                    >{{ lbuttons.cancel }}</v-btn>
                    <v-btn
                      text
                      color="rgba(4, 202, 90, 1)"
                      @click="$refs.dialogOffboardingDate.save(offBoardingDatePicker.date); offBoardingDatePicker.visible = false"
                    >{{ lbuttons.confirm }}</v-btn>
                  </v-date-picker>
                </v-dialog>

                <!-- Contract end date -->
                <v-dialog
                  ref="dialogContractEndDate"
                  v-model="contractEndDatePicker.visible"
                  :return-value.sync="contractEndDatePicker.date"
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <div
                      style="width:100%"
                    >
                      <v-text-field
                        style="width:100%"
                        hide-details
                        v-model="contractEndDatePicker.date"
                        :label="lview.contractEndDate"
                        append-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </div>
                  </template>

                  <v-date-picker color="rgba(4, 202, 90, 1)" v-model="contractEndDatePicker.date" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="rgba(4, 202, 90, 1)"
                      @click="contractEndDatePicker.visible = false"
                    >{{ lbuttons.cancel }}</v-btn>
                    <v-btn
                      text
                      color="rgba(4, 202, 90, 1)"
                      @click="$refs.dialogContractEndDate.save(contractEndDatePicker.date); contractEndDatePicker.visible = false"
                    >{{ lbuttons.confirm }}</v-btn>
                  </v-date-picker>
                </v-dialog>
                <!-- <v-icon>event</v-icon> -->
              <!-- </v-layout> -->

              <div class="action-buttons-wrapper">
                <v-btn text @click="closeDialog()">{{ lbuttons.cancel }}</v-btn>
                <v-btn :disabled="!selectedProject" color="green darken-1" text @click="stepper=3">{{ lbuttons.continue }}</v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content v-if="displayRatingSection" step="3" class="pt-0">
              <div v-if="workers.length == 1">
                <!-- Ratings -->
                <nullable-rating
                  style="margin-top:10px; margin-bottom: 20px;"
                  v-model="rating"
                ></nullable-rating>

                <v-textarea
                  v-model="ratingComment"
                  :auto-grow="true"
                  :rows="1"
                  :clearable="true"
                  :label="lview.ratingComment"
                  counter
                  maxlength="500"
                ></v-textarea>
              </div>

              <div class="action-buttons-wrapper">
                <v-btn text @click="closeDialog()">{{ lbuttons.cancel }}</v-btn>
                <v-btn :disabled="editRating && !rating" color="green darken-1" text @click="stepper=4">{{ lbuttons.continue }}</v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content :step="displayRatingSection? 4:3" class="pt-0">
              <v-select v-model="pickedResignDetails"
                multiple
                chips
                :items="details"
                item-text="name"
                item-value="id"
                :label="lview.details"
                style="margin-top: 10px;"
              ></v-select>
         
              <div class="action-buttons-wrapper">
                <v-btn text @click="closeDialog()">{{ lbuttons.cancel }}</v-btn>
                <v-btn color="green darken-1" text @click="onAcceptDialog()">{{ lbuttons.confirm }}</v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "offBoardingDialog",
  data() {
    return {
      visible: false,
      stepper: 1,
      selectForResignReasons:[

      ],
      selectForResignDetails: [
        // {
        //   id: 1,
        //   name: "Niepodjęcie pracy",
        // },
        // {
        //   id: 2,
        //   name: "Porzucenie pracy",
        // },
        // {
        //   id: 3,
        //   name: "Zwolnienie polubowne",
        // },
        // {
        //   id: 4,
        //   name: "Zwolnienie dyscyplinarne",
        // },
        // {
        //   id: 5,
        //   name: "Umowa zakończona",
        // },
        // {
        //   id: 6,
        //   name: "Umowa zakończona z wyróżnieniem"
        // },
      ],
      pickedResignReason: null,
      pickedResignDetails: null,
      rating: null,
      ratingComment: null,
      comment: null,

      checkboxes: {},

      offBoardingDatePicker: {
        date: new Date()
          .toJSON()
          .slice(0, 10)
          .replace("/-/g", "/"), //set default date as today's in format : YY-MM-DD
        visible: false
      },

      contractEndDatePicker: {
        date: new Date()
          .toJSON()
          .slice(0, 10)
          .replace("/-/g", "/"), //set default date as today's in format : YY-MM-DD
        visible: false
      },

      permissions: [],

      selectedProject: null,
      tableProjects: []
    };
  },
  computed: {
    displayRatingSection(){
      if(this.workers && this.workers.length>1){
        return false
      }
      if(!this.permissions.includes("fob.worker_rating_view")){
        return false
      }
      return true
    },
    editRating(){
       if(!this.permissions.includes("fob.worker_rating_change")){
        return false
      }
      return true
    },
    lview: {
      get: function() {
        return this.$t("views.commons.offBoardingDialog");
      }
    },
    lbuttons: {
      get: function() {
        return this.$t("commons.buttons");
      }
    },
    lmessages: {
      get: function() {
        return this.$t("commons.messages");
      }
    },
    lerrors: { get: function() { return this.$t('errors'); } },
  },
  props: {
    workers: {
      type: Array,
      required: true
    },

    defaultDate: {
      type: String,
      default: new Date()
        .toJSON()
        .slice(0, 10)
        .replace("/-/g", "/")
    },

    date: {
      type: String,
      default: null
    },

    assignedProjects: {
      type: Array,
      default: () => []
    },
    markedProjectsIds: {
      type: Array,
      default: () => []
    },
    reasons: {
      type: Array,
      default: () => []
    },
    details: {
      type: Array,
      default: () => []
    },
  },
  mounted(){
    this.permissions = localStorage.getItem("user_permissions");
  },
  methods: {
    openDialog() {
      if (this.date == null) {
        this.offBoardingDatePicker.date = this.defaultDate;
        this.contractEndDatePicker.date = this.defaultDate
      }
      else {
        this.offBoardingDatePicker.date = this.date;
        this.contractEndDatePicker.date = this.date;
      }

      this.pickedResignReason = null;
      this.pickedResignDetails = null;
      this.selectedProject=null;
      this.comment=null;

      if (this.assignedProjects.length == 0) {
        this.$emit("error", {
          errorCodeShort: "OFFBRD#2",
          errorCodeLong: "NO_PROJECTS_PROVIDED",
          title: this.lview.errorNoProjectsAssignedTitle,
          message: this.lview.errorNoProjectsAssignedMessage,
          details: null
        });
      }

      //console.log(">>>>" + JSON.stringify(this.assignedProjects));
      this.setupCheckboxes();
      this.tableProjects.splice(0, this.tableProjects.length);
      for (var i = 0; i < this.assignedProjects.length; i++) {
        this.tableProjects.push({
          id: this.assignedProjects[i].id,
          name: this.assignedProjects[i].name,
          sortName:
            this.assignedProjects[i].id == -1
              ? "#"
              : this.assignedProjects[i].name
        });

        this.tableProjects.sort((a, b) => {
          if (a.sortName < b.sortName) return -1;
          else if (a.sortName == b.sortName) return 0;
          else return 1;
        });
      }

      var that = this;
      setTimeout(function() {
        // console.log(that.workers);
        if(that.workers.length == 1){
          //console.log(that.workers[0]);
          that.rating = that.workers[0].rating;
          that.ratingComment = that.workers[0].ratingComment;
        }
      }, 1);

      this.visible = true;
    },

    closeDialog() {
      this.stepper=1;
      this.visible = false;
    },

    onAcceptDialog: async function() {
      this.$emit("request-sending-start");

      var errorOccured = false;
      var workerIds = [];
      var projectIds = [];
      var worker;
      var workers = this.workers;
      var date = this.offBoardingDatePicker.date;
      var contractEndDate = this.contractEndDatePicker.date

      // for (var key in this.checkboxes) {
      //   if (this.checkboxes[key]) {
      //     projectIds.push(parseInt(key));
      //   }
      // }

      projectIds.push(this.selectedProject)

      var records = this.filterBoardings(projectIds);

      var packages = [];
      var userProfilePackage = null;
      var errors = [];
      var modified = [];

      var projectsAssignmentIdsList = [];
      for (var idx = 0; idx < records.length; idx++){
          projectsAssignmentIdsList.push(records[idx].projectAssignmentId);
      }

      var response;
      let data = {
                  projects_assignment_id: projectsAssignmentIdsList,
                  project_assignment: {
                    description: this.comment,
                    contract_end_date: contractEndDate,
                    reason: this.pickedResignReason
                  },
                  boarding: {
                      off_boarding_date: date,
                      boarding_status: 7,
                  },
              }
      if(this.pickedResignDetails && this.pickedResignDetails.length>0){
        data.details=this.pickedResignDetails
      }
      try {
          response = await axios({
              method: "PATCH",
              url: localStorage.getItem("current_env") + "/api/v2/boardings/projects-assignment/",// + records[i].projectAssignmentId,
              headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("jwt")
              },
              data: data,
          });
      } catch (error) {
        console.log(error.response.data)
          this.$emit("request-sending-end");
          if (error.isAxiosError) {
              if (error.response.data != null && error.response.data != undefined) {
                  if (error.response.data.boarding != null && error.response.data.boarding != undefined) {
                      if (error.response.data.boarding.non_field_errors != null && error.response.data.boarding.non_field_errors != undefined) {
                          this.$emit('error', {
                              title: this.lerrors.boarding.non_field_errors.header,
                              message: this.lerrors.boarding.non_field_errors.string,
                              errorCodeShort: this.lerrors.boarding.non_field_errors.code,
                              errorCodeLong: this.lerrors.boarding.non_field_errors.code,
                              details: String(error)
                          });
                      }

                      if (error.response.data.boarding.boarding_status != null && error.response.data.boarding.boarding_status != undefined) {
                          this.$emit('error', {
                              title: this.lerrors.boarding.boarding_status.header,
                              message: this.lerrors.boarding.boarding_status.string,
                              errorCodeShort: this.lerrors.boarding.boarding_status.code,
                              errorCodeLong: this.lerrors.boarding.boarding_status.code,
                              details: String(error)
                          });
                      }

                      if (error.response.data.boarding.off_boarding_date != null && error.response.data.boarding.off_boarding_date != undefined) {
                          this.$emit('error', {
                              title: this.lerrors.boarding.off_boarding_date.header,
                              message: this.lerrors.boarding.off_boarding_date.string,
                              errorCodeShort: this.lerrors.boarding.off_boarding_date.code,
                              errorCodeLong: this.lerrors.boarding.off_boarding_date.code,
                              details: String(error)
                          });
                      }
                  }
                  if (error.response.data.projects_assignment_id != null && error.response.data.projects_assignment_id != undefined) {
                    this.$emit('error', {
                      title: this.lerrors.projects_assignment_id.header,
                      message: this.lerrors.projects_assignment_id.string,
                      errorCodeShort: this.lerrors.projects_assignment_id.code,
                      errorCodeLong: this.lerrors.projects_assignment_id.code,
                      details: String(error)
                    });									
                }
              }
              
              if (error.response.status == 403) {
                  this.$emit('error', {
                      title: this.lmessages.forbiddenOperation,
                      message: this.lmessages.youDoNotHavePriviledgesToDoThatOperation,
                      errorCodeShort: "CNFBRD#5",
                      errorCodeLong: "REQUEST_FAILED",
                      details: String(error)
                  });
              }
              
              return;
          }
          else {
              errors.push(error);

              this.$emit('error', {
                title: this.lview.errorOccured,
                message: this.lview.errorMessage + packages[i].record.boardingId,
                errorCodeShort: "OFFBRD#1",
                errorCodelong: this.lview.errorCodeLong,
                details: String(error)
              });
          }
      }

      var successes = 0;
      for (var i = 0; i < response.data.boardings.length; i++) {
          try {
              modified.push({
                  boardingId: response.data.boardings[i].id,
                  workerId: response.data.boardings[i].worker,
                  projectId: response.data.boardings[i].project_assignment.project == null ? -1 : response.data.boardings[i].project_assignment.project.id,
                  offBoardingDate: new Date(date),
                  projectAssignmentReason: response.data.boardings[i].project_assignment.reason
              });

              records[i].offBoardingDate = new Date(date);
              records[i].boardingId = response.data.boardings[i].id;
              records[i].boardingStatus = response.data.boardings[i].boarding_status;
              records[i].id = this.createRecordId(response.data.boardings[i].worker, records[i]);

              successes++;
          } catch (error) {
              if (error.isAxiosError) {
                  if (error.response.data.boarding.non_field_errors != null && error.response.data.boarding.non_field_errors != undefined) {
                      if (error.response.data.boarding.non_field_errors.length > 0) {
                          this.$emit('error', {
                              title: this.lview.errorOccured,
                              message: error.response.data.boarding.non_field_errors[0].string,
                              errorCodeShort: error.response.data.boarding.non_field_errors[0].code,
                              errorCodeLong: error.response.data.boarding.non_field_errors[0].code,
                              details: String(error)
                          });
                      }
                  }

                  if (error.response.data.boarding.boarding_status != null && error.response.data.boarding.boarding_status != undefined) {
                      if (error.response.data.boarding.boarding_status.length > 0) {
                          this.$emit('error', {
                              title: this.lview.errorOccured,
                              message: error.response.data.boarding.boarding_status[0].string,
                              errorCodeShort: error.response.data.boarding.boarding_status[0].code,
                              errorCodeLong: error.response.data.boarding.boarding_status[0].code,
                              details: String(error)
                          });
                      }
                  }

                  if (error.response.data.boarding.off_boarding_date != null && error.response.data.boarding.off_boarding_date != undefined) {
                      if (error.response.data.boarding.off_boarding_date.length > 0) {
                          this.$emit('error', {
                              title: this.lview.errorOccured,
                              message: error.response.data.boarding.off_boarding_date[0].string,
                              errorCodeShort: error.response.data.boarding.off_boarding_date[0].code,
                              errorCodeLong: error.response.data.boarding.off_boarding_date[0].code,
                              details: String(error)
                          });
                      }
                  }
              }
              else {
                  errors.push(error);

                  this.$emit('error', {
                    title: this.lview.errorOccured,
                    message: this.lview.errorMessage + packages[i].record.boardingId,
                    errorCodeShort: "OFFBRD#1",
                    errorCodelong: this.lview.errorCodeLong,
                    details: String(error)
                  });
              }
          }
      }

      var rating = this.rating;
      var ratingComment = this.ratingComment;
      if(this.workers.length == 1 && this.editRating){
        userProfilePackage = {
          record: records[0],
          promise: axios({
            method: "PATCH",
            url: localStorage.getItem("current_env") + "/api/v2/boardings/worker/" + this.workers[0].workerId,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("jwt")
            },
            data: {
              user_profile: {
                rating: rating != null ? Number.parseInt(rating * 2) : null,
                rating_comment: ratingComment == "" ? null : ratingComment
              }
            }
          })
        }
      }

      /*var successes = 0;
      var response;
      for (var i = 0; i < packages.length; i++) {
        try {
          response = await packages[i].promise;

          modified.push({
            boardingId: response.data.boarding.id,
            workerId: response.data.boarding.worker,
            projectId: response.data.project_assignment.project == null
              ? -1
              : response.data.project_assignment.project.id
            ,
            offBoardingDate: new Date(date)
          });

          packages[i].record.offBoardingDate = new Date(date);
          packages[i].record.boardingId = response.data.boarding.id;
          packages[i].record.boardingStatus = response.data.boarding.boarding_status;
          packages[i].record.id = this.createRecordId(response.data.boarding.worker, packages[i].record);

          successes++;

        } catch (error) {

            if (error.isAxiosError) {
                if (error.response.data.boarding.non_field_errors != null && error.response.data.boarding.non_field_errors != undefined) {
                    if (error.response.data.boarding.non_field_errors.length > 0) {
                        this.$emit('error', {
                            title: this.lview.errorOccured,
                            message: error.response.data.boarding.non_field_errors[0].string,
                            errorCodeShort: error.response.data.boarding.non_field_errors[0].code,
                            errorCodeLong: error.response.data.boarding.non_field_errors[0].code,
                            details: String(error)
                        });
                    }
                }

                if (error.response.data.boarding.boarding_status != null && error.response.data.boarding.boarding_status != undefined) {
                    if (error.response.data.boarding.boarding_status.length > 0) {
                        this.$emit('error', {
                            title: this.lview.errorOccured,
                            message: error.response.data.boarding.boarding_status[0].string,
                            errorCodeShort: error.response.data.boarding.boarding_status[0].code,
                            errorCodeLong: error.response.data.boarding.boarding_status[0].code,
                            details: String(error)
                        });
                    }
                }

                if (error.response.data.boarding.off_boarding_date != null && error.response.data.boarding.off_boarding_date != undefined) {
                    if (error.response.data.boarding.off_boarding_date.length > 0) {
                        this.$emit('error', {
                            title: this.lview.errorOccured,
                            message: error.response.data.boarding.off_boarding_date[0].string,
                            errorCodeShort: error.response.data.boarding.off_boarding_date[0].code,
                            errorCodeLong: error.response.data.boarding.off_boarding_date[0].code,
                            details: String(error)
                        });
                    }
                }
            }
            else {
                errors.push(error);

                this.$emit('error', {
                  title: this.lview.errorOccured,
                  message: this.lview.errorMessage + packages[i].record.boardingId,
                  errorCodeShort: "OFFBRD#1",
                  errorCodelong: this.lview.errorCodeLong,
                  details: String(error)
                });
            }
        }
      }*/

      var modifiedUserProfile = null;
      if(this.workers.length == 1){
        try{
          await userProfilePackage.promise;

          modifiedUserProfile = {
            record: userProfilePackage.record,
            rating: rating,
            ratingComment: ratingComment,
          };
        } catch (error) {
          errors.push(error);

          this.$emit('error', {
            title: this.lview.errorOccured,
            message: "Error updating user profile",
            errorCodeShort: this.lview.errorCodeShort,
            errorCodelong: this.lview.errorCodeLong,
            details: String(error)
          });
        }
      }
      
      this.$emit("request-sending-end");
      if (successes > 0) {
        this.$emit("request-sending-success", modified, modifiedUserProfile);
        this.closeDialog();
      }
    },

    // create identifier of selected WorkerId with it's status & offboardingdate
    createRecordId(workerId, record) {
      var result = workerId + "_" + ((record.boardingStatus == 3)
        ? record.boardingStatus
        : record.boardingStatus + ((record.offBoardingDate != null)
          ? record.offBoardingDate.toJSON().slice(0, 10).replace('\/', '\-')
          : "NONE"
        )
      );

      return result;
    },

    /**
     * Filters boarding for provided project ids
     * 
     * @param {Array} projectIds Array of integers
     * @returns {Array} Array of boardings for provided project ids
     */
    filterBoardings: function(projectIds) {
      var result = [];
      var boarding;
      for(var i = 0; i < this.workers.length; i++){
        for(var j = 0; j < this.workers[i].records.length; j++){
          boarding = this.workers[i].records[j];

          if(projectIds.indexOf(boarding.projectId) != -1){
            result.push(boarding);
          }
        }
      }

      return result;
    },

    setupCheckboxes() {
      this.checkboxes = {};

      for (var i = 0; i < this.assignedProjects.length; i++) {
        this.checkboxes[this.assignedProjects[i].id] = false;
      }
      //console.log(JSON.stringify(this.markedProjectsIds));
      for (var i = 0; i < this.markedProjectsIds.length; i++) {
        this.checkboxes[this.markedProjectsIds[i]] = true;
      }
    },

    // partialWorkerUpdate: async function(workerIds, requestData) {
    //   var worker;
    //   var axiosPromises = [];
    //   var workerIdsCopy = [];
    //   for (var i = 0; i < workerIds.length; i++) {
    //     workerIdsCopy.push(workerIds[i]);

    //     axiosPromises.push(
    //       axios({
    //         method: "PATCH",
    //         url:
    //           localStorage.getItem("current_env") +
    //           "/api/worker/" +
    //           workerIds[i],
    //         headers: {
    //           "Content-Type": "application/json",
    //           Authorization: "Bearer " + localStorage.getItem("jwt")
    //         },
    //         data: requestData
    //       })
    //     );
    //   }

    //   var response;
    //   var requestError = {
    //     errors: [],
    //     message: null
    //   };
    //   for (var i = 0; i < axiosPromises.length; i++) {
    //     try {
    //       response = await axiosPromises[i];
    //     } catch (error) {
    //       requestError.errors.push({
    //         workerId: workerIdsCopy[i],
    //         error: error
    //       });
    //     }
    //   }

    //   if (requestError.errors.length > 0) {
    //     requestError.message = "Following errors occured: \n";

    //     for (var i = 0; i < requestError.errors.length; i++) {
    //       requestError.message +=
    //         "\nWorker " +
    //         requestError.errors[i].workerId +
    //         ": " +
    //         requestError.errors[i].error;
    //     }

    //     throw requestError;
    //   }
    // }
  }
};
</script>

<style>
div.OffBoardingDialog .projects-table .v-subheader {
  padding: 0;
}

div.OffBoardingDialog .projects-table .v-list__tile {
  padding: 0;
}
</style>

<style scoped>
.projects-table {
  padding: 0;
}

.projects-table-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 5px;
}

.action-buttons-wrapper{
  margin-top: 20px;
	display: flex;
	justify-content: flex-end;
}
</style>
