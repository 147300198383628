<!-- 
SharedMasters 2020 - Fast OnBoarding Application

__copyright__ = "Copyright (C) 2020, Shared Masters sp. z o.o."
__license__ = "Fast OnBoarding can not be copied and/or distributed without the express permission of Shared Masters sp. z o.o."
__version__ = "1.4.0"
__author__ = "Adrian Bączek"
__maintainer__ = "Shared Masters"
__email__ = "it@sharedmasters.com"
__status__ = "PROD"
-->

<template>
	<v-layout style="margin-top: 10px;">
		<!-- addresses list rendering -->
		<v-data-table
			:headers="addressDataTableHeadersDialog.renderedColumns"
			:items="addressDataTable.items"
			:loading="addressDataTable.loading"
			:options.sync="addressDataTable.options"
			:footer-props="{'items-per-page-options': addressDataTable.itemsPerPageSelectValues}"
			:server-items-length="addressDataTable.total"
			hide-default-footer
		>
			<!-- datatable top : search, columns modification -->
			<template v-slot:top>
				<!--  -->
				<div style="width:100%">
					<v-text-field
						v-model="addressDataTable.search"
						:label="lviews.search"
						style="height: 66px;"
						clearable
						append-icon="search"
						class="address-list-search-field"
					></v-text-field>

					<div class="address-list-projects-select-wrapper">
						<!-- project filter -->
						<v-select
							v-model="addressDataTable.filteredProjects"
							class="address-list-projects-select-field"
							:items="addressDataTable.projects"
							:label="lviews.project"
							multiple
							item-value="id"
							item-text="name"
							@change="updateAddressData"
						>
							<template v-slot:selection="{ item, index }">
								<div v-if="addressDataTable.filteredProjects.length == addressDataTable.projects.length">
									<span v-if="index == 0"> 
										{{ lviews.allProjectsSelected }}
									</span>
								</div>
								<div v-else>
									<span v-if="index < 2" style="margin-right: 10px;">{{ item.name }} &nbsp;</span>
									<span
										v-else-if="index === 2"
										class="grey--text caption"
									>(+{{ addressDataTable.filteredProjects.length - 2 }})</span>
								</div>
							</template>
						</v-select>

						<!-- modify columns button -->
						<v-btn
							style="margin-top: 10px;"
							color="primary"
							small
							fab
							@click="openModifyDataTableColumnsDialog"
						> <v-icon> mdi-settings </v-icon> 
						</v-btn>
					</div>
				</div>
			</template>

			<!-- template for item options -->
			<template v-slot:[`item.options`]="{ item }">
				<v-menu v-if="item.options[0].length > 0" offset-y>
					<!-- menu activator -->
					<template v-slot:activator="{ on, attrs }">
						<v-icon
							color="primary"
							dark
							v-bind="attrs"
							v-on="on"
							style="z-index:0"
						>
							mdi-dots-vertical
						</v-icon>
					</template>

					<!-- rendering menu options -->
					<v-list style="min-width: 250px;">
						<div
							v-for="(group, groupIndex) in item.options"
							:key="groupIndex"
						>
							<v-list-item v-for="(groupItem, groupItemKey) in group" :key="groupItemKey" class="address-list-menu-option" @click="() => { groupItem.action(item); }">
								<v-flex :style="groupItem.style" :class="groupItem.class">
									<v-icon :style="groupItem.style"> {{ groupItem.icon }} </v-icon>
									{{ groupItem.title }}
								</v-flex>
							</v-list-item>
							<v-divider v-if="groupIndex < item.options.length - 1" style="border-bottom: 1px solid lightgray;"></v-divider>
						</div>
					</v-list>
				</v-menu>
			</template>

			<!-- template for item projects -->
			<template v-slot:[`item.projects`]="{ item }">
				<v-tooltip left>
					<template v-slot:activator="{ on, attrs }">
						<div v-on="on" style="display: flex;">
							<div class="projects-cut-when-too-long" style="margin-top: 2px; margin-left: 2px;"> {{ getProjectsList(item.projects) }} </div>
						</div>
					</template>
					<span v-if="item.projects.length > 0">{{ getProjectsList(item.projects) }}</span>
				</v-tooltip>
			</template>

			<!-- template for datatable custom footer -->
			<template v-slot:footer>
				<v-divider></v-divider>
				<worker-document-data-table-footer
					:lviews="lviews"
					:itemsPerPageOptions="addressDataTable.itemsPerPageSelectValues"

					:pageNumber="addressDataTable.options.page"
					:itemsPerPageNumber="addressDataTable.options.itemsPerPage"
					@update-page-number="updatePageNumber"
					@update-page-size="updatePageSize"

					:totalItemsNumber="addressDataTable.total"
				></worker-document-data-table-footer>
			</template>
		</v-data-table>

		<!-- remove address dialog -->
		<remove-address-dialog
			ref="removeAddressDialog"
			:address="addressToRemove"

			@show-overlayer="showOverlayerEventHandler"
			@hide-overlayer="hideOverlayerEventHandler"
			@emit-error="showErrorEventHandler"
			@emit-update="updateAddressData"
		></remove-address-dialog>

		<!-- address dialog : CREATE | UPDATE -->
		<address-dialog
			ref="addressDialog"
			:address="addressToEdit"
			:projects="addressDataTable.projects"

			@show-overlayer="showOverlayerEventHandler"
			@hide-overlayer="hideOverlayerEventHandler"
			@emit-error="showErrorEventHandler"
			@emit-update="updateAddressData"
		></address-dialog>

		<!-- edit address datatable headers dialog -->
		<v-dialog
			v-model="addressDataTableHeadersDialog.visible"
			width="350"
			persistent
		>
			<v-card>
				<v-card-title> {{ lviews.chooseDataTableColumns }} </v-card-title>
				<v-list>
					<v-list-item v-for="(option, index) in addressDataTableHeadersDialog.items" :key="index">
						<v-checkbox
							v-model="addressDataTableHeadersDialog.selectedColumns"
							:label="option.text"
							:value="option.id"
							style="padding:0; margin:0;"
						></v-checkbox>
					</v-list-item>
				</v-list>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text color="primary" class="align-self-center" style="margin-bottom: 15px;" @click="() => { addressDataTableHeadersDialog.visible = false; }"> {{ lviews.cancel }} </v-btn>
					<v-btn text color="primary" class="align-self-center" style="margin-bottom: 15px;" @click="() => { updateDataTablesColumns(); addressDataTableHeadersDialog.visible = false; }" :disabled="addressDataTableHeadersDialog.selectedColumns.length == 1"> {{ lviews.modify }} </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- add new address button -->
		<v-btn
			color="primary"
			fab
			v-if="canUserAddNewAddress"
			@click="openCreateNewAddressDialog"
			class="add-new-address-button"
		>
			<v-icon>
				mdi-plus 
			</v-icon>
		</v-btn>
	</v-layout>
</template>

<script>
import axios from 'axios';
import fobConfig from '../fob_config';

export default {
	data: function() {
		return {
			// address datatable data structure
			addressDataTable: {
				headers: [
					{ id: 0, text: '', align: 'start', sortable: true, value: 'streetName', class: "datatable-header" },
					{ id: 1, text: '', align: 'start', sortable: true, value: 'streetNumber', class: "datatable-header" },
					{ id: 2, text: '', align: 'start', sortable: true, value: 'propertyNumber', class: "datatable-header" },
					{ id: 3, text: '', align: 'start', sortable: true, value: 'zipCode', class: "datatable-header" },
					{ id: 4, text: '', align: 'start', sortable: true, value: 'city', class: "datatable-header" },
					{ id: 5, text: '', align: 'start', sortable: true, value: 'region', class: "datatable-header" },
					{ id: 6, text: '', align: 'start', sortable: true, value: 'country', class: "datatable-header" },
					{ id: 7, text: '', align: 'start', sortable: false, value: 'description', class: "datatable-header" },
					{ id: 8, text: '', align: 'start', sortable: false, value: 'projects', class: "datatable-header" },
					{ id: 9, text: '', align: 'start', sortable: false, value: 'options', class: "datatable-header" },
				],
				items: [
					/**
					 * {
					 *      id:             {Number},
					 *      streetName:     {String},
					 *      streetNumber:   {String},
					 *      propertyNumber: {String},
					 *      zipCode:        {String},
					 *      city:           {String},
					 *      region:         {String},
					 *      country:        {String},
					 *      description:    {String},
					 *      options:        {Array of Objects},
					 *      projects:       {Array of Numbers}
					 * }
					 */
				],

				options: {},
				loading: false,
				total: 0,

				itemsPerPageSelectValues: [5, 10, 15, 30, 50, 100, 500],
				search: "",
				projects: [],
				filteredProjects: []
			},

			addressDataTableHeadersDialog: {
				visible: false,
				items: [],
				selectedColumns: [],
				renderedColumns: [],
			},

			addressToRemove: { id: null },
			addressToEdit: null,

			searchTimeout: null,
		}
	},
	methods: {
		/**
		 * Creates projects list string based on item's projects ids list.
		 * @param projectsIdsList {[Number]} List of item's projects ids.
		 * @returns Merges list of projects names.
		 */
		getProjectsList: function(projectsIdsList) {
			let projectsNames = [];
			let index = -1;
			for (let i = 0; i < projectsIdsList.length; i++) {
				index = this.addressDataTable.projects.findIndex(x => { return x.id == projectsIdsList[i]; });
				if (index != -1) {
					projectsNames.push(this.addressDataTable.projects[index].name);
				}
			}
			return projectsNames.join(", ");
		},

		/**
		 * Updates datatable columns view
		 */
		updateDataTablesColumns: function(){
			this.addressDataTableHeadersDialog.renderedColumns = [];
			this.addressDataTableHeadersDialog.selectedColumns.sort((a, b) => { return a < b ? -1 : 1; });
			for(var i = 0; i < this.addressDataTableHeadersDialog.selectedColumns.length; i++){
				this.addressDataTableHeadersDialog.renderedColumns.push(
					this.addressDataTable.headers[ this.addressDataTableHeadersDialog.selectedColumns[i] ]
				);
			}
		},

		/**
		 * Opens dialog in order to modify datatable column
		 */
		openModifyDataTableColumnsDialog: function() {
			this.addressDataTableHeadersDialog.selectedColumns = [];
			for(var i = 0; i < this.addressDataTableHeadersDialog.renderedColumns.length; i++) {
				this.addressDataTableHeadersDialog.selectedColumns.push(this.addressDataTableHeadersDialog.renderedColumns[i].id);
			}

			this.addressDataTableHeadersDialog.visible = true;
		},

		/**
		 * Fetches address data from API.
		 * @returns Address API response.
		 */
		fetchAddressData: async function() {
			let response = null;
			let apiUrl = fobConfig.api_url + "/api/v2/admin/postal-address/?page=" + this.addressDataTable.options.page + "&per_page=" + this.addressDataTable.options.itemsPerPage;
			this.addressDataTable.loading = true;
			
			// add search address in api url
			if (this.addressDataTable.search != null && this.addressDataTable.search != '') {
				apiUrl += "&search=" + this.addressDataTable.search;
			}

			if (this.addressDataTable.options.sortBy.length > 0) {
				switch (this.addressDataTable.options.sortBy[0]) {
					case "streetName":
						apiUrl += ("&sort_by=0");
						break;
					case "streetNumber":
						apiUrl += ("&sort_by=1");
						break;
					case "propertyNumber":
						apiUrl += ("&sort_by=2");
						break;
					case "zipCode":
						apiUrl += ("&sort_by=3");
						break;
					case "city":
						apiUrl += ("&sort_by=4");
						break;
					case "region":
						apiUrl += ("&sort_by=5");
						break;
					case "country":
						apiUrl += ("&sort_by=6");
						break;
				}

				if (this.addressDataTable.options.sortDesc[0]) {
					apiUrl += ("&sort_desc=1");
				}
				else {
					apiUrl += ("&sort_desc=0");
				}
			}

			// search address filtered with assigned projects
			if (this.addressDataTable.filteredProjects.length > 0) {
				apiUrl += ("&projects=" + this.addressDataTable.filteredProjects.toString());
			}

			try {
				response = await axios({
					url: apiUrl,
					method: "GET",
					headers: {
						'Authorization': 'Bearer ' + localStorage.getItem("jwt"),
					},
				});
			} catch (error) {
				this.addressDataTable.loading = false;
				response = null;
				switch(error.response.status) {
					case 400:
						this.$emit("display-error", this.lviews.badRequest, this.lviews.badRequestMessage, error.toString());
						break;
					case 401:
						this.$router.push("/login?next=/addresses");
						break;
					case 403:
						this.$emit("display-error", this.lviews.forbiddenOperation, this.lviews.youDoNotHavePrivilegesToDoThatOperation, error.toString());
						break;
					case 404:
						this.$emit('display-error', this.lviews.notFound, this.lviews.notFoundErrorMessage, error.toString());
						break;
					default:
						this.$emit("display-error", this.lviews.errorOccurred, this.lviews.errorOccurredMessage, error.toString());
						break;
				}
			}

			this.addressDataTable.loading = false;
			return response;
		},

		/**
		 * Adapts address API response data to component address data format.
		 * @param addressData {List} List with data returned by address API. 
		 */
		adaptAddressResponseData: function(addressData) {
			this.addressDataTable.items = [];

			let options = this.checkOptionsPermissions();

			this.addressDataTable.total = addressData.total;
			for (let i = 0; i < addressData.items.length; i++) {
				this.addressDataTable.items.push({
					id: addressData.items[i].id,
					streetName: addressData.items[i].street_name,
					streetNumber: addressData.items[i].street_number,
					propertyNumber: addressData.items[i].property_number,
					zipCode: addressData.items[i].zip_code,
					city: addressData.items[i].city,
					region: addressData.items[i].region,
					country: addressData.items[i].country,
					description: addressData.items[i].description,
					options: options,
					projects: addressData.items[i].projects,
				});
			}

			this.addressDataTable.projects = addressData.projects;
		},

		/**
		 * Check permissions to options.
		 * @returns Options list.
		 */
		checkOptionsPermissions: function() {
			let usersPermissions = localStorage.getItem("user_permissions");
			let tempOptions = [];
			let options = [];

			if (usersPermissions.includes("fob.admin_change")) {
				tempOptions.push(
					{ title: this.lviews.edit, action: this.openEditAddressDialog, class: "", icon: "mdi-pencil"},	
				);	
			}

			if (usersPermissions.includes("fob.admin_delete")) {
				tempOptions.push(
					{ title: this.lviews.remove, action: this.openRemoveAddressDialog, class: "address-datatable-remove-option", icon: "mdi-delete"},
				);
			}

			options.push(
				tempOptions
			)

			return options
		},

		/**
		 * Updates datatables data using other methods.
		 */
		updateAddressData: async function() {
			let response = await this.fetchAddressData();
			if (response != null) {
				this.adaptAddressResponseData(response.data);
			}
		},

		// REMOVE ADDRESS DIALOG EVENT HANDLERS/TRIGGERS
		/**
		 * Updates addressData prop for remove-address-dialog and opens dialog
		 * @param address{Object} Address meant to be removed.
		 */
		openRemoveAddressDialog: function(address) {
			this.addressToRemove = address;
			this.$nextTick(() => { this.$refs.removeAddressDialog.openDialog(); });
		},

		/**
		 * Emits proper 'set-state' event to parent component in order to show loading overlayer.
		 * @param message {String} Message that should be displayed under loading icon.
		 */
		showOverlayerEventHandler: function(message) {
			this.$emit('set-state', 'LOADING_OVERLAYER', message);
		},

		/**
		 * Emits proper 'set-state' event to parent component in order to hide loading overlayer.
		 */
		hideOverlayerEventHandler: function() {
			this.$emit('set-state', 'default');
		},

		/**
		 * Emits event 'display-error' to parent component in order to render error detailed message.
		 * @param errorTitle {String} Error title.
		 * @param errorMessage {String} Error displayed content.
		 * @param errorDetails {String} Stringified error passed to error dialog (just in case user wants to copy error details and send it to administrators).
		 */
		showErrorEventHandler: function(errorTitle, errorMessage, errorDetails) {
			this.$emit("display-error", errorTitle, errorMessage, errorDetails);
		},

		/**
		 * Updates datatable page number due to event emitted by custom footer component
		 * @param newPageNumber {Number} New page number.
		 */
		updatePageNumber: function(newPageNumber) {
			this.addressDataTable.options.page = newPageNumber;
		},

		/**
		 * Updates datatable page size (items per page value) due to event emitted by custom footer component.
		 * @param newPageSize {Number} New items-per-page value.
		 */
		updatePageSize: function(newPageSize) {
			this.addressDataTable.options.itemsPerPage = newPageSize;
		},

		// CREATE NEW ADDRESS METHODS
		/**
		 * Opens new address dialog
		 */
		openCreateNewAddressDialog: function() {
			this.addressToEdit = null;
			this.$nextTick(() => { this.$refs.addressDialog.openDialog(); });
		},

		// EDIT EXISTING ADDRESS METHODS
		/**
		 * Updates addressData prop for address-dialog and opens dialog
		 * @param address{Object} Address meant to be edited.
		 */
		openEditAddressDialog: function(address) {
			this.addressToEdit = address;
			this.$nextTick(() => { this.$refs.addressDialog.openDialog(); });
		},
	},
	mounted: async function() {
		this.$emit('set-title', this.lviews.title);

		this.addressDataTable.headers[0].text = this.lviews.streetName;
		this.addressDataTable.headers[1].text = this.lviews.streetNumber;
		this.addressDataTable.headers[2].text = this.lviews.propertyNumber;
		this.addressDataTable.headers[3].text = this.lviews.zipCode;
		this.addressDataTable.headers[4].text = this.lviews.city;
		this.addressDataTable.headers[5].text = this.lviews.region;
		this.addressDataTable.headers[6].text = this.lviews.country;
		this.addressDataTable.headers[7].text = this.lviews.description;
		this.addressDataTable.headers[8].text = this.lviews.projects;

		for (let i = 0; i < this.addressDataTable.headers.length - 1; i++) {
			this.addressDataTableHeadersDialog.items.push(this.addressDataTable.headers[i]);
		}
		
		await this.updateAddressData();

		this.addressDataTableHeadersDialog.renderedColumns = [];
		this.addressDataTableHeadersDialog.selectedColumns = [];
		for(var i = 0; i < this.addressDataTable.headers.length; i++){
			this.addressDataTableHeadersDialog.renderedColumns.push(this.addressDataTable.headers[i]);
			this.addressDataTableHeadersDialog.selectedColumns.push(i);
		}

		this.updateDataTablesColumns();
	},
	watch: {
		"addressDataTable.options": {
			handler: async function() {
				await this.updateAddressData();
			},
			deep: true
		},

		"addressDataTable.search": {
			handler: function() {
				if (this.searchTimeout != null) {
					clearTimeout(this.searchTimeout);
				}

				this.searchTimeout = setTimeout(
					() => { this.updateAddressData(); },
					500
				);
			}
		},

	},
	computed: {
		lviews: {
			get: function() {
				return this.$t("views.addressListPanel");
			}
		},
		canUserAddNewAddress: {
			get: function() {
				let usersPermissions = localStorage.getItem("user_permissions");

				if (usersPermissions.includes("fob.admin_add")) {
					return true;
				} else {
					return false;
				}
			}
		},
	}
}
</script>

<style>
.address-datatable-remove-option {
	color: red !important;
}

.address-list-menu-option {
	background-color: white;
}

.address-list-menu-option:hover {
	background-color: #e4e4e4;
}

.add-new-address-button {
	position: fixed !important;
	bottom: 25px !important;
	right: 25px !important;
}

.projects-cut-when-too-long {
	max-width: 170px;
	overflow: hidden; 
	white-space: nowrap; 
	text-overflow: ellipsis;
}

.address-list-search-field {
		width: 250px;
		display: inline-block !important;
		padding-left: 10px;
		padding-right: 10px;
}
.address-list-projects-select-wrapper {
	width: 350px;
	display: inline-block !important;
}
.address-list-projects-select-field {
		width: 300px;
		display: inline-block !important;
		padding-left: 10px;
		padding-right: 10px;
}

@media only screen and (max-width: 630px) {
		.address-list-search-field {
				width: 100%;
		}
		.address-list-projects-select-wrapper {
				width: 100%;
		}
		.address-list-projects-select-field {
				width: calc(100% - 50px);
		}
}
</style>