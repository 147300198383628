<!--
Fast OnBoarding Application

__copyright__ = "Copyright (C) 2020, Shared Masters sp. z o.o."
__license__ = "Fast OnBoarding can not be copied and/or distributed without the express permission of Shared Masters sp. z o.o."
__version__ = "1.1.0"
__author__ = "Adrian Bączek, Jan Przychodniak, Tomasz Drzewiecki"
__maintainer__ = "Shared Masters sp. z o.o."
__email__ = "it@sharedmasters.com"
__status__ = "PROD"

-->

<template>
	<v-layout class="Projects">
		<v-toolbar v-if="isMarkedRecordOnDisplayedList" fixed dark text class="edit-toolbar left-offset100" :edit-mode="editMode">
			<v-btn text icon @click="closeEditMode()">
				<v-icon>
					arrow_back
				</v-icon>
			</v-btn>
			<v-toolbar-title>{{ selected.length }}</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-toolbar-items>
                <!-- Change plans -->
                <v-btn
                    text
                    icon
                    @click="openEditBoardingDialog()"
                    v-if="toolbarIcons.editActive"
                ><v-icon>mdi-file-replace-outline</v-icon></v-btn>

                <!-- Confirm boarding -->
                <v-btn
                    text
                    icon
                    @click="dialogs.confirmBoarding.visible = true"
                    v-if="toolbarIcons.confirmBoardingActive"
                ><v-icon>thumb_up</v-icon></v-btn>

                <!-- Remove planned project -->
                <v-btn
                    text
                    icon
                    @click="dialogs.removeBoarding.visible = true"
                    v-if="toolbarIcons.removePlannedProjectActive"
                ><v-icon>thumb_down</v-icon></v-btn>

                <!-- Off boarding -->
                <v-btn
                    text
                    icon
                    @click="openOffBoardingDialog"
                    v-if="toolbarIcons.offBoardingActive"
                ><v-icon>thumb_down</v-icon></v-btn>

                <!-- Off boarding remove project (Terminate boarding) -->
                <v-btn
                    text
                    icon
                    @click="openTerminateBoardingDialog()"
                    v-if="toolbarIcons.offBoardingRemoveActive"
                ><v-icon>thumb_down</v-icon></v-btn>
			</v-toolbar-items>
		</v-toolbar>

		<v-flex xs12 style="margin-top: 10px;">
			<v-select v-model="filters"
					  :items="selectItems"
					  item-value="key"
					  item-text="name"
                      item-color="green accent-4"
					  chips
					  label="Select projects"
					  multiple
					  solo
					  @change="updateList()"></v-select>
		</v-flex>

		<v-list three-line dense>
			<v-list-item :class="selected.indexOf(item.assignmentId) == -1 ? 'project-record' : 'project-record selected'"
						 v-for="(item, key) in listData"
						 :key="key"
						 @click="onItemClick(item)"
						 @mousedown="onItemHoldStart(item)"
						 @mouseup="onItemHoldStop(item)"
						 @mouseleave="onItemHoldStop(item)"
						 @touchstart="onItemHoldStart(item)"
						 @touchend="onItemHoldStop(item)"
						 @touchcancel="onItemHoldStop(item)"
						 @touchmove="onItemHoldStop(item)">
				<v-list-item-avatar class="record-avatar" @click.stop="onAvatarClick(item)">
                    <div v-if="item.recordStatus != 'OFFBOARDING' && selected.indexOf(item.assignmentId) != -1" class="record-avatar-initials"> <v-icon> check </v-icon> </div>
                    <div v-else class="record-avatar-initials"> {{ item.avatarSign }} </div>
				</v-list-item-avatar>

				<v-list-item-content style="min-width: 70%;">
					<v-list-item-title v-if="item.avatarSign=='A'&&item.recordStatus != 'OFFBOARDING'" style="color:green;" v-html="item.projectName"></v-list-item-title>
                    <v-list-item-title v-else-if="item.avatarSign=='H' && item.recordStatus != 'OFFBOARDING'" style="color:gray;" v-html="item.projectName"></v-list-item-title>
                    <v-list-item-title v-else style="color:black;" v-html="item.projectName"></v-list-item-title>
					<v-list-item-subtitle v-html="item.displayDates"></v-list-item-subtitle>
                    <v-list-item-subtitle>
                        <v-chip 
                            v-for="detail in item.displayProjectDetails" 
                            :key="detail"
                            class="mr-1"
                            small
                        >{{detail}}</v-chip>
                    </v-list-item-subtitle>
				</v-list-item-content>

                <v-list-item-content>
                    <v-list-item-action v-if="item.recordStatus == 'ONBOARDING'">
						<v-icon>mdi-thumb-up</v-icon>
					</v-list-item-action>
                    <v-list-item-action v-else-if="item.recordStatus == 'OFFBOARDING'">
						<v-icon>mdi-thumb-down</v-icon>
					</v-list-item-action>
                </v-list-item-content>
			</v-list-item>
		</v-list>

		<div class="boarding-add-new">
			<v-btn class="boarding-add-new mx-2"
				   fab
                   color="primary"
				   @click="openNewProjectDialog()">
				<v-icon>add</v-icon>
			</v-btn>
		</div>

		<v-dialog persistent v-model="dialogs.newProject.visible"
				  width="350px">
			<v-card>
				<v-card-title class="headline"
							  primary-title>
					{{lview.addProject}}
				</v-card-title>

				<v-card-text>
					<v-select v-model="dialogs.newProject.projectId"
							  :items="dialogs.newProject.projectIdsItems"
							  item-value="id"
							  item-text="name"
                              item-color="green accent-4"
                              color="green accent-4"
							  :label="lview.project"></v-select>

					<v-select v-model="dialogs.newProject.assignmentType"
							  :items="dialogs.newProject.assignmentTypeSelectItems"
							  item-value="key"
							  item-text="text"
                              item-color="green accent-4"
                              color="green accent-4"
							  :label="lview.type"></v-select>

					<v-layout align-center>
						<v-dialog persistent 
                                  ref="newProjectAssignmentStartDate"
								  v-model="dialogs.newProject.datePickerAssignmentStart.visible"
								  :return-value.sync="dialogs.newProject.datePickerAssignmentStart.date"
								  width="290px">
							<template v-slot:activator="{ on }">
								<v-text-field v-model="dialogs.newProject.datePickerAssignmentStart.date"
											  :label="lview.assignmentStartDate"
											  append-icon="event"
											  readonly
											  v-on="on"></v-text-field>
							</template>
							<v-date-picker color="rgba(4, 202, 90, 1)" v-model="dialogs.newProject.datePickerAssignmentStart.date" scrollable>
								<v-spacer></v-spacer>
								<v-btn text color="rgba(4, 202, 90, 1)" @click="dialogs.newProject.datePickerAssignmentStart.visible = false">Cancel</v-btn>
								<v-btn text color="rgba(4, 202, 90, 1)" @click="$refs.newProjectAssignmentStartDate.save(dialogs.newProject.datePickerAssignmentStart.date)">OK</v-btn>
							</v-date-picker>
						</v-dialog>
					</v-layout>

					<v-layout align-center>
						<v-dialog ref="newProjectOnBoardingStartDate"
								  v-model="dialogs.newProject.datePickerOnBoardingStart.visible"
								  :return-value.sync="dialogs.newProject.datePickerOnBoardingStart.date"
                                  persistent
								  width="290px">
							<template v-slot:activator="{ on }">
								<v-text-field v-model="dialogs.newProject.datePickerOnBoardingStart.date"
											  :label="lview.onBoardingDate"
											  append-icon="event"
											  readonly
											  v-on="on"></v-text-field>
							</template>
							<v-date-picker color="rgba(4, 202, 90, 1)" v-model="dialogs.newProject.datePickerOnBoardingStart.date" scrollable>
								<v-spacer></v-spacer>
								<v-btn text color="rgba(4, 202, 90, 1)" @click="dialogs.newProject.datePickerOnBoardingStart.visible = false">Cancel</v-btn>
								<v-btn text color="rgba(4, 202, 90, 1)" @click="$refs.newProjectOnBoardingStartDate.save(dialogs.newProject.datePickerOnBoardingStart.date)">OK</v-btn>
							</v-date-picker>
						</v-dialog>
					</v-layout>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text
						   color="rgba(4, 202, 90, 1)"
						   @click="dialogs.newProject.visible = false">{{lview.cancel}}</v-btn>

					<v-btn text
						   color="rgba(4, 202, 90, 1)"
						   @click="confirmNewProjectDialog">{{lview.confirm}}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

        <!-- Edit dialogs -->

        <!-- Confirm boarding dialog -->
        <v-dialog
            v-model="dialogs.confirmBoarding.visible"
            persistent
            width="350px"
        >
            <v-card>
                <v-card-title
                    class="headline"
                    primary-title
                >
                    {{lview.confirmEmployment}}
                </v-card-title>

                <v-card-text>
                    {{lview.assuranceEmployment}}
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="rgba(4, 202, 90, 1)"
                        text
                        @click="dialogs.confirmBoarding.visible = false"
                    >{{lview.no}}</v-btn>
                    <v-btn
                        color="rgba(4, 202, 90, 1)"
                        text
                        @click="confirmConfirmBoardingDialog()"
                    >{{lview.yes}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Remove project from boarding -->
        <v-dialog
            v-model="dialogs.removeBoarding.visible"
            persistent
            width="350px"
        >
            <v-card>
                <v-card-title
                    class="headline"
                    primary-title
                >
                    {{lview.removeBoarding}}
                </v-card-title>

                <v-card-text>
                    {{lview.assuranceRemovement}}
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="rgba(4, 202, 90, 1)"
                        text
                        @click="dialogs.removeBoarding.visible = false"
                    >{{lview.no}}</v-btn>
                    <v-btn
                        color="rgba(4, 202, 90, 1)"
                        text
                        @click="confirmRemoveBoardingDialog()"
                    >{{lview.yes}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Off boarding -->
        <v-dialog
            v-model="dialogs.offBoarding.visible"
            persistent
            width="350px"
        >
            <v-card>
                <v-card-title
                    class="headline"
                    primary-title
                >
                    {{lview.employeeResignation}}
                </v-card-title>

                <v-card-text>
                    <v-dialog
                        ref="offBoardingDatePickerDialog"
                        v-model="dialogs.offBoarding.datePicker.visible"
                        :return-value.sync="dialogs.offBoarding.datePicker.date"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="dialogs.offBoarding.datePicker.date"
                                :label="lview.offBoardingDate"
                                append-icon="event"
                                readonly
                                v-on="on"
                            ></v-text-field>
                        </template>

                        <v-date-picker color="rgba(4, 202, 90, 1)" v-model="dialogs.offBoarding.datePicker.date" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="rgba(4, 202, 90, 1)" @click="dialogs.offBoarding.datePicker.visible = false">{{lview.cancel}}</v-btn>
                        <v-btn text color="rgba(4, 202, 90, 1)" @click="$refs.offBoardingDatePickerDialog.save(dialogs.offBoarding.datePicker.date)">{{lview.confirm}}</v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="rgba(4, 202, 90, 1)"
                        text
                        @click="dialogs.offBoarding.visible = false"
                    >{{lview.cancel}}</v-btn>
                    <v-btn
                        color="rgba(4, 202, 90, 1)"
                        text
                        @click="confirmOffBoardingDialog()"
                    >{{lview.confirm}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Terminate project dialog --> 
        <v-dialog
            v-model="dialogs.terminateBoarding.visible"
            persistent
            width="350px"
        >
            <v-card>
                <v-card-title
                    class="headline"
                    primary-title
                >
                    {{lview.terminateEmployment}}
                </v-card-title>

                <v-card-text>
                    <!-- offBoardingDate -->
                    <v-dialog
                        ref="terminateBoardingOffBoardingDatePickerDialog"
                        v-model="dialogs.terminateBoarding.offBoardingDatePicker.visible"
                        :return-value.sync="dialogs.terminateBoarding.offBoardingDatePicker.date"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="dialogs.terminateBoarding.offBoardingDatePicker.date"
                                :label="lview.offBoardingDate"
                                append-icon="event"
                                readonly
                                v-on="on"
                            ></v-text-field>
                        </template>

                        <v-date-picker color="rgba(4, 202, 90, 1)" v-model="dialogs.terminateBoarding.offBoardingDatePicker.date" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="rgba(4, 202, 90, 1)" @click="dialogs.terminateBoarding.offBoardingDatePicker.visible = false">{{lview.cancel}}</v-btn>
                        <v-btn text color="rgba(4, 202, 90, 1)" @click="$refs.terminateBoardingOffBoardingDatePickerDialog.save(dialogs.terminateBoarding.offBoardingDatePicker.date)">{{lview.confirm}}</v-btn>
                        </v-date-picker>
                    </v-dialog>
                    <!-- projectEndDate -->
                    <v-dialog
                        ref="terminateBoardingProjectEndDatePickerDialog"
                        v-model="dialogs.terminateBoarding.projectEndDatePicker.visible"
                        :return-value.sync="dialogs.terminateBoarding.projectEndDatePicker.date"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="dialogs.terminateBoarding.projectEndDatePicker.date"
                                :label="lview.projectEndDate"
                                append-icon="event"
                                readonly
                                v-on="on"
                            ></v-text-field>
                        </template>

                        <v-date-picker color="rgba(4, 202, 90, 1)" v-model="dialogs.terminateBoarding.projectEndDatePicker.date" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="rgba(4, 202, 90, 1)" @click="dialogs.terminateBoarding.projectEndDatePicker.visible = false">{{lview.cancel}}</v-btn>
                        <v-btn text color="rgba(4, 202, 90, 1)" @click="$refs.terminateBoardingProjectEndDatePickerDialog.save(dialogs.terminateBoarding.projectEndDatePicker.date)">{{lview.confirm}}</v-btn>
                        </v-date-picker>
                    </v-dialog>                    
                    <!-- offBoardingDate -->
                    <v-dialog
                        ref="terminateBoardingContractEndDatePickerDialog"
                        v-model="dialogs.terminateBoarding.contractEndDatePicker.visible"
                        :return-value.sync="dialogs.terminateBoarding.contractEndDatePicker.date"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="dialogs.terminateBoarding.contractEndDatePicker.date"
                                :label="lview.contractEndDate"
                                append-icon="event"
                                readonly
                                v-on="on"
                            ></v-text-field>
                        </template>

                        <v-date-picker color="rgba(4, 202, 90, 1)" v-model="dialogs.terminateBoarding.contractEndDatePicker.date" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="rgba(4, 202, 90, 1)" @click="dialogs.terminateBoarding.contractEndDatePicker.visible = false">{{lview.cancel}}</v-btn>
                        <v-btn text color="rgba(4, 202, 90, 1)" @click="$refs.terminateBoardingContractEndDatePickerDialog.save(dialogs.terminateBoarding.contractEndDatePicker.date)">{{lview.confirm}}</v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="rgba(4, 202, 90, 1)"
                        text
                        @click="dialogs.terminateBoarding.visible = false"
                    >{{lview.cancel}}</v-btn>
                    <v-btn
                        color="rgba(4, 202, 90, 1)"
                        text
                        @click="confirmTerminateBoardingDialog()"
                    >{{lview.confirm}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Edit boarding -->
        <v-dialog
            v-model="dialogs.editBoarding.visible"
            persistent
            width="350px"
        >
            <v-card>
                <v-card-title
                    class="headline"
                    primary-title
                >
                    {{lview.editBoarding}}
                </v-card-title>

                <v-card-text style="overflow-y:auto; height: 300px;">
                    <!-- Comment -->
                    <v-layout>
                        <v-checkbox
                            color="rgba(4, 202, 90, 1)"
                            v-model="dialogs.editBoarding.editComment.enabled"
                        ></v-checkbox>
                        
                        <div style="width: 250px;"  @click="
                            if(!dialogs.editBoarding.editComment.enabled){
                                dialogs.editBoarding.editComment.enabled = true;
                                $nextTick($refs.editBoardingComment.focus);
                            }
                        ">
                            <v-textarea
                                ref="editBoardingComment"
                                v-model="dialogs.editBoarding.editComment.text"
                                :label="lview.comment"
                                :disabled="!dialogs.editBoarding.editComment.enabled"
                                :auto-grow="true"
                                :rows="3"
                                :clearable="true"
                                counter
                                maxlength="500"
                            ></v-textarea>
                        </div>
                    </v-layout>
                    
                    <!-- change project -->
                    <v-layout v-if="dialogs.editBoarding.changeProjectSelect.options.length > 0">
                        <v-checkbox
                            color="rgba(4, 202, 90, 1)"
                            v-model="dialogs.editBoarding.changeProjectSelect.enabled"
                        ></v-checkbox>
                        
                        <div  @click="
                            if(!dialogs.editBoarding.changeProjectSelect.enabled){
                                dialogs.editBoarding.changeProjectSelect.enabled = true;
                                $nextTick($refs.editBoardingChangeProjectSelect.focus);
                                $nextTick($refs.editBoardingChangeProjectSelect.activateMenu);
                            }
                        ">
                            <v-select
                                ref="editBoardingChangeProjectSelect"
                                v-model="dialogs.editBoarding.changeProjectSelect.picked"
                                :disabled="!dialogs.editBoarding.changeProjectSelect.enabled"

                                :items="dialogs.editBoarding.changeProjectSelect.options"
                                item-value="id"
                                item-text="name"
                                :label="lview.changeProject"
                                item-color="green accent-4"
                                color="green accent-4"
                            ></v-select>
                        </div>
                    </v-layout>

                    <!-- 
                        WORK TYPE
                    -->
                    <v-layout>
                        <v-checkbox 
                            color="rgba(4, 202, 90, 1)" 
                            v-model="dialogs.editBoarding.changeWorkTypeSelect.enabled">
                        </v-checkbox>

                        <div @click="
                            if(!dialogs.editBoarding.changeWorkTypeSelect.enabled){
                                dialogs.editBoarding.changeWorkTypeSelect.enabled = true;
                                $nextTick($refs.editBoardingChangeWorkTypeSelect.focus);
                                $nextTick($refs.editBoardingChangeWorkTypeSelect.activateMenu);
                            }
                        ">
                            <v-select
                                ref="editBoardingChangeWorkTypeSelect"
                                v-model="dialogs.editBoarding.changeWorkTypeSelect.picked"
                                :disabled="!dialogs.editBoarding.changeWorkTypeSelect.enabled"

                                :items="dialogs.editBoarding.changeWorkTypeSelect.types"
                                item-value="id"
                                item-text="name"
                                :label="lview.changeWorkType"
                                item-color="green accent-4"
                                color="green accent-4"
                            ></v-select>
                        </div>

                    </v-layout>

                    <!-- 
                        ON BOARDING DATE
                    -->
                    <v-layout>
                        <v-checkbox
                            color="rgba(4, 202, 90, 1)"
                            v-model="dialogs.editBoarding.onBoardingDatePicker.enabled"
                        ></v-checkbox>
                        <v-dialog
                            ref="editBoardingOnBoardingDatePickerDialog"
                            v-model="dialogs.editBoarding.onBoardingDatePicker.visible"
                            :return-value.sync="dialogs.editBoarding.onBoardingDatePicker.date"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <div
                                    @click="onEnablableDatePickerClick(dialogs.editBoarding.onBoardingDatePicker)"
                                >
                                    <v-text-field
                                        :disabled="!dialogs.editBoarding.onBoardingDatePicker.enabled"
                                        v-model="dialogs.editBoarding.onBoardingDatePicker.date"
                                        :label="lview.onBoardingDate"
                                        append-icon="event"
                                        readonly
                                        v-on="on"
                                    ></v-text-field>
                                </div>
                            </template>

                            <v-date-picker color="rgba(4, 202, 90, 1)" v-model="dialogs.editBoarding.onBoardingDatePicker.date" scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="rgba(4, 202, 90, 1)" @click="dialogs.editBoarding.onBoardingDatePicker.visible = false">{{lview.cancel}}</v-btn>
                            <v-btn text color="rgba(4, 202, 90, 1)" @click="$refs.editBoardingOnBoardingDatePickerDialog.save(dialogs.editBoarding.onBoardingDatePicker.date)">{{lview.confirm}}</v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-layout>
                    <!--
                        OFF BOARDING DATE
                    -->
                    <v-layout>
                        <v-checkbox
                            color="rgba(4, 202, 90, 1)"
                            v-model="dialogs.editBoarding.offBoardingDatePicker.enabled"
                        ></v-checkbox>

                        <v-dialog
                            ref="editBoardingOffBoardingDatePickerDialog"
                            v-model="dialogs.editBoarding.offBoardingDatePicker.visible"
                            :return-value.sync="dialogs.editBoarding.offBoardingDatePicker.date"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <div
                                    @click="onEnablableDatePickerClick(dialogs.editBoarding.offBoardingDatePicker)"
                                >
                                    <v-text-field
                                        :disabled="!dialogs.editBoarding.offBoardingDatePicker.enabled"
                                        v-model="dialogs.editBoarding.offBoardingDatePicker.date"
                                        :label="lview.offBoardingDate"
                                        append-icon="event"
                                        readonly
                                        v-on="on"
                                    ></v-text-field>
                                </div>
                            </template>

                            <v-date-picker color="rgba(4, 202, 90, 1)" v-model="dialogs.editBoarding.offBoardingDatePicker.date" scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="rgba(4, 202, 90, 1)" @click="dialogs.editBoarding.offBoardingDatePicker.visible = false">{{lview.cancel}}</v-btn>
                            <v-btn text color="rgba(4, 202, 90, 1)" @click="$refs.editBoardingOffBoardingDatePickerDialog.save(dialogs.editBoarding.offBoardingDatePicker.date)">{{lview.confirm}}</v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-layout>
                    <!--
                        PROJECT START DATE
                    -->
                    <v-layout>
                        <v-checkbox
                            color="rgba(4, 202, 90, 1)"
                            v-model="dialogs.editBoarding.projectStartDatePicker.enabled"
                        ></v-checkbox>

                        <v-dialog
                            ref="editBoardingProjectStartDatePickerDialog"
                            v-model="dialogs.editBoarding.projectStartDatePicker.visible"
                            :return-value.sync="dialogs.editBoarding.projectStartDatePicker.date"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <div
                                    @click="onEnablableDatePickerClick(dialogs.editBoarding.projectStartDatePicker)"
                                >
                                    <v-text-field
                                        :disabled="!dialogs.editBoarding.projectStartDatePicker.enabled"
                                        v-model="dialogs.editBoarding.projectStartDatePicker.date"
                                        :label="lview.projectStartDate"
                                        append-icon="event"
                                        readonly
                                        v-on="on"
                                    ></v-text-field>
                                </div>
                            </template>

                            <v-date-picker color="rgba(4, 202, 90, 1)" v-model="dialogs.editBoarding.projectStartDatePicker.date" scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="rgba(4, 202, 90, 1)" @click="dialogs.editBoarding.projectStartDatePicker.visible = false">{{lview.cancel}}</v-btn>
                            <v-btn text color="rgba(4, 202, 90, 1)" @click="$refs.editBoardingProjectStartDatePickerDialog.save(dialogs.editBoarding.projectStartDatePicker.date)">{{lview.confirm}}</v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-layout>
                    <!--
                        PROJECT END DATE
                    -->
                    <v-layout>
                        <v-checkbox
                            color="rgba(4, 202, 90, 1)"
                            v-model="dialogs.editBoarding.projectEndDatePicker.enabled"
                        ></v-checkbox>

                        <v-dialog
                            ref="editBoardingProjectEndDatePickerDialog"
                            v-model="dialogs.editBoarding.projectEndDatePicker.visible"
                            :return-value.sync="dialogs.editBoarding.projectEndDatePicker.date"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <div
                                    @click="onEnablableDatePickerClick(dialogs.editBoarding.projectEndDatePicker)"
                                >
                                    <v-text-field
                                        :disabled="!dialogs.editBoarding.projectEndDatePicker.enabled"
                                        v-model="dialogs.editBoarding.projectEndDatePicker.date"
                                        :label="lview.projectEndDate"
                                        append-icon="event"
                                        readonly
                                        v-on="on"
                                    ></v-text-field>
                                </div>
                            </template>

                            <v-date-picker color="rgba(4, 202, 90, 1)" v-model="dialogs.editBoarding.projectEndDatePicker.date" scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="rgba(4, 202, 90, 1)" @click="dialogs.editBoarding.projectEndDatePicker.visible = false">{{lview.cancel}}</v-btn>
                            <v-btn text color="rgba(4, 202, 90, 1)" @click="$refs.editBoardingProjectEndDatePickerDialog.save(dialogs.editBoarding.projectEndDatePicker.date)">{{lview.confirm}}</v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-layout>
                    <!--
                        CONTRACT START DATE
                    -->
                    <v-layout>
                        <v-checkbox
                            color="rgba(4, 202, 90, 1)"
                            v-model="dialogs.editBoarding.contractStartDatePicker.enabled"
                        ></v-checkbox>

                        <v-dialog
                            ref="editBoardingContractStartDatePickerDialog"
                            v-model="dialogs.editBoarding.contractStartDatePicker.visible"
                            :return-value.sync="dialogs.editBoarding.contractStartDatePicker.date"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <div
                                    @click="onEnablableDatePickerClick(dialogs.editBoarding.contractStartDatePicker)"
                                >
                                    <v-text-field
                                        :disabled="!dialogs.editBoarding.contractStartDatePicker.enabled"
                                        v-model="dialogs.editBoarding.contractStartDatePicker.date"
                                        :label="lview.contractStartDate"
                                        append-icon="event"
                                        readonly
                                        v-on="on"
                                    ></v-text-field>
                                </div>
                            </template>

                            <v-date-picker color="rgba(4, 202, 90, 1)" v-model="dialogs.editBoarding.contractStartDatePicker.date" scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="rgba(4, 202, 90, 1)" @click="dialogs.editBoarding.contractStartDatePicker.visible = false">{{lview.cancel}}</v-btn>
                            <v-btn text color="rgba(4, 202, 90, 1)" @click="$refs.editBoardingContractStartDatePickerDialog.save(dialogs.editBoarding.contractStartDatePicker.date)">{{lview.confirm}}</v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-layout>
                    <!--
                        CONTRACT END DATE
                    -->
                    <v-layout>
                        <v-checkbox
                            color="rgba(4, 202, 90, 1)"
                            v-model="dialogs.editBoarding.contractEndDatePicker.enabled"
                        ></v-checkbox>

                        <v-dialog
                            ref="editBoardingContractEndDatePickerDialog"
                            v-model="dialogs.editBoarding.contractEndDatePicker.visible"
                            :return-value.sync="dialogs.editBoarding.contractEndDatePicker.date"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <div
                                    @click="onEnablableDatePickerClick(dialogs.editBoarding.contractEndDatePicker)"
                                >
                                    <v-text-field
                                        :disabled="!dialogs.editBoarding.contractEndDatePicker.enabled"
                                        v-model="dialogs.editBoarding.contractEndDatePicker.date"
                                        :label="lview.contractEndDate"
                                        append-icon="event"
                                        readonly
                                        v-on="on"
                                    ></v-text-field>
                                </div>
                            </template>

                            <v-date-picker color="rgba(4, 202, 90, 1)" v-model="dialogs.editBoarding.contractEndDatePicker.date" scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="rgba(4, 202, 90, 1)" @click="dialogs.editBoarding.contractEndDatePicker.visible = false">{{lview.cancel}}</v-btn>
                            <v-btn text color="rgba(4, 202, 90, 1)" @click="$refs.editBoardingContractEndDatePickerDialog.save(dialogs.editBoarding.contractEndDatePicker.date)">{{lview.confirm}}</v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-layout>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="rgba(4, 202, 90, 1)"
                        text
                        @click="dialogs.editBoarding.visible = false"
                    >{{lview.cancel}}</v-btn>
                    <v-btn
                        color="rgba(4, 202, 90, 1)"
                        text
                        @click="confirmEditBoardingDialog()"
                    >{{lview.confirm}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Details of the historical project -->
        <v-dialog
            v-model="dialogs.detailsOfProject.visible"
            persistent
            width="350px"
        >
            <v-card>
                <v-card-title
                    class="headline"
                    primary-title
                >
                    {{lview.details}}
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text>
                    <v-layout>
                        <v-flex xs6>{{ dialogs.detailsOfProject.datesNames.contractStartDate }}</v-flex>
                        <v-flex xs6 class="project-details">{{ dialogs.detailsOfProject.dates.contractStartDate }}</v-flex>
                    </v-layout>
                </v-card-text>

                <v-card-text>
                    <v-layout>
                        <v-flex xs6>{{ dialogs.detailsOfProject.datesNames.contractEndDate }}</v-flex>
                        <v-flex xs6 class="project-details">{{ dialogs.detailsOfProject.dates.contractEndDate }}</v-flex>
                    </v-layout>
                </v-card-text>

                <v-card-text>
                    <v-layout>
                        <v-flex xs6>{{ dialogs.detailsOfProject.datesNames.projectStartDate }}</v-flex>
                        <v-flex xs6 class="project-details">{{ dialogs.detailsOfProject.dates.projectStartDate }}</v-flex>
                    </v-layout>
                </v-card-text>

                <v-card-text>
                    <v-layout>
                        <v-flex xs6>{{ dialogs.detailsOfProject.datesNames.projectEndDate }}</v-flex>
                        <v-flex xs6 class="project-details">{{ dialogs.detailsOfProject.dates.projectEndDate }}</v-flex>
                    </v-layout>
                </v-card-text>

                <v-card-text>
                    <v-layout>
                        <v-flex xs6>{{ dialogs.detailsOfProject.datesNames.onBoardingDate }}</v-flex>
                        <v-flex xs6 class="project-details">{{ dialogs.detailsOfProject.dates.onBoardingDate }}</v-flex>
                    </v-layout>
                </v-card-text>

                <v-card-text>
                    <v-layout>
                        <v-flex xs6>{{ dialogs.detailsOfProject.datesNames.offBoardingDate }}</v-flex>
                        <v-flex xs6 class="project-details">{{ dialogs.detailsOfProject.dates.offBoardingDate }}</v-flex>
                    </v-layout>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="rgba(4, 202, 90, 1)"
                        text
                        @click="dialogs.detailsOfProject.visible = false"
                    >{{lview.close}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
        <!-- Notifications -->
        <!--<v-layout
            align-center
            justify-center
            row
            
            style="position:fixed; z-index:6; bottom: 20px; width: 100%"
        >
            <v-alert
                :value="notification.visible"
                color="success"
                outline
                icon="check_circle"
                v-on:enter="onNotificationAppear"
                transition="scale-transition"

                style="background-color:#FFF;"
                class="elevation-3"
            >{{ notification.message }}</v-alert>
        </v-layout>-->

        <v-snackbar
            v-model="notification.visible"
            :timeout="notification.timeout"
            bottom
        >
            <v-icon style="margin-right: 10px; color:#FFF">
                check
            </v-icon>
            {{ notification.message }}

            <v-spacer></v-spacer>
            <v-btn
                dark
                text
                @click="notification.visible = false"
                icon
            >
                <v-icon>cancel</v-icon>
            </v-btn>
        </v-snackbar>
	</v-layout>
</template>

<script>
	import axios from "axios";

	export default {
		name: "projectsScreen",
		beforeMount() {
			// this.projectStatuses = this.lview.states; // left not removed - in case we need project statuses in the future
            //this.selectItems = this.lview.selectItems;
            this.projectsDictionary[0].name = this.lview.unassigned;
            this.selectItems[0].name = this.lview.hired;
            this.selectItems[1].name = this.lview.historical;
            this.selectItems[2].name = this.lview.planned;
            this.dialogs.newProject.assignmentTypeSelectItems[0].text = this.lview.fulltime;
            this.dialogs.newProject.assignmentTypeSelectItems[1].text = this.lview.parttime;
            this.dialogs.newProject.assignmentTypeSelectItems[2].text = this.lview.timebased;
            this.dialogs.newProject.assignmentTypeSelectItems[3].text = this.lview.fixedprice;

            this.dialogs.detailsOfProject.datesNames.contractStartDate = this.lview.contractStartDate;
            this.dialogs.detailsOfProject.datesNames.contractEndDate = this.lview.contractEndDate;
            this.dialogs.detailsOfProject.datesNames.projectStartDate = this.lview.projectStartDate;
            this.dialogs.detailsOfProject.datesNames.projectEndDate = this.lview.projectEndDate;
            this.dialogs.detailsOfProject.datesNames.onBoardingDate = this.lview.onBoardingDate;
            this.dialogs.detailsOfProject.datesNames.offBoardingDate = this.lview.offBoardingDate;

            this.dialogs.editBoarding.changeWorkTypeSelect.types = [];
            this.dialogs.editBoarding.changeWorkTypeSelect.types.push({
                id: 0,
                name: this.lview.fulltime
            });
            this.dialogs.editBoarding.changeWorkTypeSelect.types.push({
                id: 1,
                name: this.lview.parttime
            });
            this.dialogs.editBoarding.changeWorkTypeSelect.types.push({
                id: 2,
                name: this.lview.timebased
            });
            this.dialogs.editBoarding.changeWorkTypeSelect.types.push({
                id: 3,
                name: this.lview.fixedprice
            });

            this.assignmentTypes[0] = this.lview.fulltime;
            this.assignmentTypes[1] = this.lview.parttime;
            this.assignmentTypes[2] = this.lview.timebased;
            this.assignmentTypes[3] = this.lview.fixedprice;
		},
        props: {
            version: {
                type: String,
                required: true,
            },
            appConfig: {
                type: Object,
                required: true
            }
        },
		mounted: async function () {
            /*if(this.appConfig.applicationMode != "MANAGER"){
                this.$store.dispatch("logout").then(() => {
					this.$router.push("/login");
				});
            }*/

			this.$emit('set-display', "BACK_WINDOW");
			this.$emit('set-title', this.lview.title);
			var data = await this.getPageData();
            this.handleData(data);
		},
		computed: {
			lview: { get: function () { return this.$t('views.projects'); } },
            lmessages: { get: function () { return this.$t('commons.messages'); } },
            lerrors: { get: function() { return this.$t('errors'); } },
            isMarkedRecordOnDisplayedList: {
				get: function(){
					return this.$store.getters.markedRecord;
				}
			}
        },
        watch: {
            /*'notification.visible': function(){
                if(this.notification.visible == true){
                    this.onNotificationAppear();
                }
            }*/
            'dialogs.editBoarding.visible': function(){
                if(this.dialogs.editBoarding.visible == false){
                    this.closeEditMode();
                }
            }
        },
		data() {
			return {
				editMode: false,

				sourceData: [
					/*
					projectId: {Integer},
                    projectName: {String},
                    recordStatus: {String},
					assignmentType: {Integer},
					assignmentStatus: {Integer},
					boardingStatus: {Integer},
					projectStartDate: {Date},
                    projectEndDate: {Date},
                    contractStartDate: {Date},
                    contractEndDate: {Date},
                    offBoardingDate: {Date},
                    onBoardingDate: {Date}
					*/
				],

				listData: [
					/*
					projectId: {Integer},
					projectName: {String},
                    recordStatus: {String},
                    sortSign: {Char(1)},
					assignmentType: {Int},
                    assignmentStatus: {Int},
                    assignmentId: {Int},
					startDate: {String},
					endDate: {String},
					avatarSign: {Char(1)},
					markable: {Boolean},
					*/
				],

				projectsDictionary: [
					{
						id: -1,
						name: null
					},
					/*
						{
							id: {Integer},
							name: {String}
						}
					*/
				],

				recordStatuses: {
					"1": "ONBOARDING",
					"3": "HIRED",
					"7": "OFFBOARDING"
                },
                
                actionsForStatus: {
                    "ONBOARDING": {
                        editActive: true,
                        confirmBoardingActive: true,
                        removePlannedProjectActive: true,
                        offBoardingActive: false,
                        offBoardingRemoveActive: false
                    },
                    "HIRED": {
                        editActive: true,
                        confirmBoardingActive: false,
                        removePlannedProjectActive: false,
                        offBoardingActive: true,
                        offBoardingRemoveActive: false
                    },
                    "OFFBOARDING": {
                        editActive: true,
                        confirmBoardingActive: true,
                        removePlannedProjectActive: false,
                        offBoardingActive: false,
                        offBoardingRemoveActive: true
                    },
                    "NONE": {
                        editActive: false,
                        confirmBoardingActive: false,
                        removePlannedProjectActive: false,
                        offBoardingActive: false,
                        offBoardingRemoveActive: false
                    }
                },

                offBoardingTypes: [
                    // {
                    //     name: "",
                    //     id: ""
                    // }
                ],

				assignmentTypes: [
					"Full-Time", // value 0
					"Part-Time", // value 1
					"Time-Based", // value 2
					"Fixed-Price" // value 3
				],

				assignmentStatuses: [
					{
						name: "Planned", // value 0
						stateKey: "P"
					},
					{
						name: "-", // value 1 - not present in the model
						stateKey: "-"
					},
					{
						name: "Hired", // value 2
						stateKey: "A",
					},
					{
						name: "Removed", // value 3 - shouldn't be received from API, just in case
						stateKey: "R"
					},
					{
						name: "Bench", // value 4
						stateKey: "B",
					},
					{
						name: "Historical", // value 5
						stateKey: "H",
					}
				],

				selectItems: [
					{
						key: "A",
						name: null
					},
					{
						key: "H",
						name: null
					},
					{
						key: "P",
						name: null
					}
				],
				selected: [],
				filters: ['A', 'H', 'P'],

                toolbarIcons: {
                    editActive: true,
                    confirmBoardingActive: true,
                    removePlannedProjectActive: true,
                    offBoardingActive: true,
                    offBoardingRemoveActive: true
                },

				dialogs: {
					newProject: {
						visible: false,

						projectIdsItems: [
							/*
								{
									id: {Integer},
									name: {String}
								}
							*/
						],
						projectId: -1,

						assignmentTypeSelectItems: [
							{
								key: 0,
								text: null
							},
							{
								key: 1,
								text: null
							},
							{
								key: 2,
								text: null
							},
							{
								key: 3,
								text: null
							}
						],
						assignmentType: 0,

						datePickerAssignmentStart: {
							visible: false,
							date: new Date().toJSON().slice(0, 10).replace('/-/g', '/')
						},
						datePickerOnBoardingStart: {
							visible: false,
							date: new Date().toJSON().slice(0, 10).replace('/-/g', '/')
						}
                    },
                    confirmBoarding: {
                        visible: false
                    },
                    removeBoarding: {
                        visible: false
                    },
                    offBoarding: {
                        visible: false,
                        datePicker: {
                            visible: false,
                            date: new Date().toJSON().slice(0, 10)
                        }
                    },
                    terminateBoarding: {
                        visible: false,

                        offBoardingDatePicker: {
                            visible: false,
                            date: new Date().toJSON().slice(0, 10)
                        },
                        contractEndDatePicker: {
                            visible: false,
                            date: new Date().toJSON().slice(0, 10)
                        },
                        projectEndDatePicker: {
                            visible: false,
                            date: new Date().toJSON().slice(0, 10)
                        },
                    },
                    editBoarding: {
                        visible: false,

                        editComment: {
                            enabled: false,
                            text: null,
                        },

                        changeProjectSelect: {
                            enabled: false,
                            options: [],
                            picked: null
                        },

                        changeWorkTypeSelect: {
                            enabled: false,
                            types: [],
                            picked: null
                        },

                        onBoardingDatePicker: {
                            enabled: false,
                            visible: false,
                            date: new Date().toJSON().slice(0, 10)
                        },
                        offBoardingDatePicker: {
                            enabled: false,
                            visible: false,
                            date: new Date().toJSON().slice(0, 10)
                        },
                        contractStartDatePicker: {
                            enabled: false,
                            visible: false,
                            date: new Date().toJSON().slice(0, 10)
                        },
                        contractEndDatePicker: {
                            enabled: false,
                            visible: false,
                            date: new Date().toJSON().slice(0, 10)
                        },
                        projectStartDatePicker: {
                            enabled: false,
                            visible: false,
                            date: new Date().toJSON().slice(0, 10)
                        },
                        projectEndDatePicker: {
                            enabled: false,
                            visible: false,
                            date: new Date().toJSON().slice(0, 10)
                        },
                    },
                    detailsOfProject: {
                        visible: false,
                        dates:{
                            contractStartDate: null,
                            contractEndDate: null,
                            projectStartDate: null,
                            projectEndDate: null,
                            onBoardingDate: null,
                            offBoardingDate: null,
                        },
                        datesNames:{
                            contractStartDate: null,
                            contractEndDate: null,
                            projectStartDate: null,
                            projectEndDate: null,
                            onBoardingDate: null,
                            offBoardingDate: null,
                        }
                    }
				},

				recordHoldTimeout: false,
                recordHoldItem: null,
                
                notification: {
                    visible: false,
                    timeout: 2000,
                    message: "",
                }
			}
		},
		methods: {

            getAssignmentTypeText(assignmentType){
                return this.assignmentTypes[assignmentType];
            },
            getSourceProjectFromProjectId(projectId, ignoreHistorical = true){
                for(var i = 0; i < this.sourceData.length; i++){
                    if(this.sourceData[i].projectId == projectId){
                        if(!ignoreHistorical || this.sourceData[i].recordStatus != "NONE"){
                            return this.sourceData[i];
                        }
                    }
                }
                return null;
            },
            getSourceProjectFromAssignmentId(assignmentId){
                for(var i = 0; i < this.sourceData.length; i++){
                    if(this.sourceData[i].assignmentId == assignmentId){
                        return this.sourceData[i];
                    }
                }
            },
            getSourceProjectFromBoardingId(boardingId){
                for(var i = 0; i < this.sourceData.length; i++){
                    if(this.sourceData[i].boardingId == boardingId){
                        return this.sourceData[i];
                    }
                }
            },
            getProject(projectId) {
				for (var i = 0; i < this.projectsDictionary.lenght; i++) {
					if (this.projectsDictionary[i].id == projectId)
						return this.projectsDictionary[i];
				}
				return null;
            },
			getDisplayDates(onBoardingDate, offBoardingDate, projectStartDate, projectEndDate) {
                 // Boarding dates section
                var result = "";
				if (onBoardingDate != null) {
					result += onBoardingDate.toJSON().slice(0, 10);
				}
				else {
					result += this.lview.noDate;
				}
				result += " -> ";
				if (offBoardingDate != null) {
					result += offBoardingDate.toJSON().slice(0, 10);
				}
				else {
					result += this.lview.noDate;
                }
                // Project Assignment dates section
                // result += "<br>" + this.lview.project + ": ";
				// if (projectStartDate != null) {
				// 	result += projectStartDate.toJSON().slice(0, 10);
				// }
				// else {
				// 	result += this.lview.noDate;
				// }
				// result += " -> ";
				// if (projectEndDate != null) {
				// 	result += projectEndDate.toJSON().slice(0, 10);
				// }
				// else {
				// 	result += this.lview.noDate;
                // }
				return result;
			},

            getDisplayProjectDetails(details){
                let result=[]
                if(details && this.offBoardingTypes){
                    for(let item of details){
                        let type = this.offBoardingTypes.find(i => i.id==item)
                        if(type){
                            result.push(type.name)
                        }
                    }
                }
                return result
            },

			onItemClick(item) {
                if(this.editMode){
                    var index;
                    if ((index = this.selected.indexOf(item.assignmentId)) != -1 && item.markable) {
                        this.selected.splice(index, 1);
                        
                        if(this.selected.length == 0) this.closeEditMode();
                    }
                }
                else{
                    if(item.recordStatus != "NONE" && item.markable){
                        this.selected.splice(0, this.selected.length);
                        this.selected.push(item.assignmentId);

                        this.openEditBoardingDialog();
                    }
                    else{ //historical record
                        this.fillDetailsOfProject(item);
                        this.dialogs.detailsOfProject.visible = true;
                    }
                }
            },

            onAvatarClick(item){
                var index;
                if ( (index = this.selected.indexOf(item.assignmentId)) != -1){
                    this.selected.splice(index, 1);
                    if(this.selected.length == 0) this.closeEditMode();
                } else {
                    if (this.selected >= 1 || !item.markable) return;
                    this.selected.push(item.assignmentId);
                    this.$store.commit('updateMarkedRecordFlag', {isMarked: true});
                    this.editMode = true;

                    var project = this.getSourceProjectFromAssignmentId(item.assignmentId);

                    if(project != null){
                        var actions = this.actionsForStatus[project.recordStatus];

                        for(var key in actions){
                            this.toolbarIcons[key] = actions[key];
                        }
                    }
                }
            },

            fillDetailsOfProject(item){
                var project = this.getSourceProjectFromAssignmentId(item.assignmentId);
                this.dialogs.detailsOfProject.dates.contractStartDate = (project.contractStartDate == null)
                    ? null
                    : project.contractStartDate.toJSON().slice(0,10);
                this.dialogs.detailsOfProject.dates.contractEndDate = (project.contractEndDate == null)
                    ? null
                    : project.contractEndDate.toJSON().slice(0,10);
                this.dialogs.detailsOfProject.dates.projectStartDate = (project.projectStartDate == null)
                    ? null
                    : project.projectStartDate.toJSON().slice(0,10);
                this.dialogs.detailsOfProject.dates.projectEndDate = (project.projectEndDate == null)
                    ? null
                    : project.projectEndDate.toJSON().slice(0,10);
                this.dialogs.detailsOfProject.dates.onBoardingDate = (project.onBoardingDate == null)
                    ? null
                    : project.onBoardingDate.toJSON().slice(0,10);
                this.dialogs.detailsOfProject.dates.offBoardingDate = (project.offBoardingDate == null)
                    ? null
                    : project.offBoardingDate.toJSON().slice(0,10);
            },

			onItemHoldStart(item) {
				if (!item.markable || this.selected.length >= 1 || item.assignmentStatus == 5) return;

				if (this.recordHoldTimeout != false) {
					clearTimeout(this.recordHoldTimeout);
				}
				this.recordHoldItem = item;
				this.recordHoldTimeout = setTimeout(this.onItemHoldTimeout, 550);
			},
			onItemHoldTimeout() {
				if (!this.selected.includes(this.recordHoldItem.assignmentId)) {
                    this.selected.push(this.recordHoldItem.assignmentId);

                    this.$store.commit('updateMarkedRecordFlag', {isMarked: true});
                    this.editMode = true;
                    
                    var project = this.getSourceProjectFromAssignmentId(this.recordHoldItem.assignmentId);

                    if(project != null){
                        var actions = this.actionsForStatus[project.recordStatus];

                        for(var key in actions){
                            this.toolbarIcons[key] = actions[key];
                        }
                    }
				}
				else
                    this.selected.splice(this.selected.indexOf(this.recordHoldItem.assignmentId), 1);
                    
                    if(this.selected.length == 0) this.closeEditMode();
			},
			onItemHoldStop(item) {
				if (this.recordHoldTimeout != false) {
					clearTimeout(this.recordHoldTimeout);
				}
			},

			closeEditMode() {
                this.editMode = false;
                this.$store.commit('updateMarkedRecordFlag', {isMarked: false});
				this.selected.splice(0, this.selected.length);
            },
            
            showNotification(message){
                this.notification.message = message;
                this.notification.visible = true;
            },

            //
            // Edit dialogs
            //

            // Add project
			addNewProject: async function (projectId, projectAssignmentType, projectAssignmentStartDate, onBoardingDate) {
				this.$emit('set-state', 'loading_overlayer', this.lview.saving);
                var response, data = null;
                var that = this;
                var listofProjectsId = []
                if (projectId != -1) {
                    listofProjectsId = [projectId];  
                }

                try {
					response = await axios({
						method: "POST",
						url: localStorage.getItem("current_env") + "/api/v2/boardings/worker/" + this.$route.params.worker_id,
						headers: {
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + localStorage.getItem('jwt')
						},
						data: {
                            boarding: {
                                on_boarding_date: onBoardingDate,
                                boarding_status: 1,
                            },
                            project_assignment: {
                                assignment_type: projectAssignmentType,
                                start_date: projectAssignmentStartDate,
                                project_id: listofProjectsId
                            }
						},
                    })
                    // TODO: mimo poprawności zapisu nadal nie jest to w pełni działająca wersja - procedura wchodzi w ogólny 'catch'
                    //.catch(function (error) {
                        // TODO dodać obsługe ValidationError z API
					//    that.$emit('set-state', 'default');
                    //    that.$emit('display-error', that.lview.errorOccuredTitle, error.response.data.error, 'Error status: '+error.response.status );
                    //    return;                        
                    //});
                    data = response.data;
				} catch (e) {
                    this.$emit('set-state', 'default');
                    this.dialogs.newProject.visible = false;

                    if (e.isAxiosError) {
                        if (e.response.data.boarding != null && e.response.data.boarding != undefined) {
                            if (e.response.data.boarding.off_boarding_date != null && e.response.data.boarding.off_boarding_date != undefined) {
                                this.$emit('display-error', this.lerrors.boarding.off_boarding_date.header, this.lerrors.boarding.off_boarding_date.string, e);
                                return;
                            }

                            if (e.response.data.boarding.other_boarding != null && e.response.data.boarding.other_boarding != undefined) {
                                this.$emit('display-error', this.lerrors.boarding.other_boarding.header, this.lerrors.boarding.other_boarding.string, e);
                                return;
                            }                            
                        }
                        if (e.response.data.project_assignment != null && e.response.data.project_assignment != undefined) {
                            if (e.response.data.project_assignment.finish_date != null && e.response.data.project_assignment.finish_date != undefined) {
                                this.$emit('display-error', this.lerrors.project_assignment.finish_date.header, this.lerrors.project_assignment.finish_date.string, e);
                                return;
                            }

                            if (e.response.data.project_assignment.contract_end_date != null && e.response.data.project_assignment.contract_end_date != undefined) {
                                this.$emit('display-error', this.lerrors.project_assignment.contract_end_date.header, this.lerrors.project_assignment.contract_end_date.string, e);
                                return;
                            }

                            if (e.response.data.project_assignment.project_id != null && e.response.data.project_assignment.project_id != undefined) {
                                this.$emit('display-error', this.lerrors.project_assignment.project_id.header, this.lerrors.project_assignment.project_id.string, e);
                                return;
                            }
                        }
                        /*
                            OFF_BOARDING_DATE_LESS
                            OTHER_BOARDING_EXIST
                            FINISH_DATE_LESS
                            CONTRACT_END_DATE_LESS
                        */                        
                    }
                    else {
					    this.$emit('display-error', that.lview.errorOccuredTitle, that.lview.errorOccured, e);
                    }
                }

                if (data != null) {
                    
                    this.sourceData.push({
                        // boarding
                        boardingId: data.boardings[0].id,
                        recordStatus: "ONBOARDING",
                        boardingStatus: data.boardings[0].boarding_status,
                        onBoardingDate: (data.boardings[0].on_boarding_date != null) ? new Date(data.boardings[0].on_boarding_date) : null,
                        offBoardingDate: (data.boardings[0].off_boarding_date != null) ? new Date(data.boardings[0].off_boarding_date) : null,

                        // projectAssignment
                        assignmentId: data.boardings[0].project_assignment.id,
                        projectId: (data.boardings[0].project_assignment.project != null) ? data.boardings[0].project_assignment.project.id : -1,
                        projectName: (data.boardings[0].project_assignment.project != null) ? data.boardings[0].project_assignment.project.name : this.lview.unassigned,
                        assignmentType: data.boardings[0].project_assignment.assignment_type,
                        assignmentStatus: data.boardings[0].project_assignment.assignment_status,
                        projectStartDate: (data.boardings[0].project_assignment.start_date != null) ? new Date(data.boardings[0].project_assignment.start_date) : null,
                        projectEndDate: (data.boardings[0].project_assignment.finish_date != null) ? new Date(data.boardings[0].project_assignment.finish_date) : null,
                        contractStartDate: (data.boardings[0].project_assignment.contract_start_date != null) ? new Date(data.boardings[0].project_assignment.contract_start_date) : null,
                        contractEndDate: (data.boardings[0].project_assignment.contract_end_date != null) ? new Date(data.boardings[0].project_assignment.contract_end_date) : null
                    });

					this.$emit('set-state', 'default');

					this.dialogs.newProject.visible = false;

					this.updateList();
                    this.showNotification(this.lview.projectAdded);
                }
            },
            
            openNewProjectDialog() {
				this.dialogs.newProject.datePickerAssignmentStart.date = new Date().toJSON().slice(0, 10).replace('/-/g', '/');
				this.dialogs.newProject.datePickerOnBoardingStart.date = new Date().toJSON().slice(0, 10).replace('/-/g', '/');
				this.dialogs.newProject.assignmentType = 0;

				var usedProjects = [];
				for (var i = 0; i < this.sourceData.length; i++) {
                    if(this.sourceData[i].recordStatus != "NONE"){
                        usedProjects.push(this.sourceData[i].projectId);
                    }
				}

				var selectList = this.dialogs.newProject.projectIdsItems;
				selectList.splice(0, selectList.length);

				// Picking projects from projects dictionary, that are not already assigned to this worker
				for (var i = 0; i < this.projectsDictionary.length; i++) {
					if (!usedProjects.includes(this.projectsDictionary[i].id)) {
						selectList.push(this.projectsDictionary[i]);
					}
				}

				if (selectList.length > 0) {
					this.dialogs.newProject.projectId = selectList[0].id;
					this.dialogs.newProject.visible = true;
				}
				else {
					this.$emit("display-error", this.lview.cannotAddProjectTitle, this.lview.cannotAddProjectMessage);
				}
			},

			confirmNewProjectDialog() {
				this.addNewProject(
					this.dialogs.newProject.projectId,
					this.dialogs.newProject.assignmentType,
					this.dialogs.newProject.datePickerAssignmentStart.date,
					this.dialogs.newProject.datePickerOnBoardingStart.date
				);
            },
            
            // Confirm boarding
            confirmConfirmBoardingDialog: async function(){
                var boardings = [];
                var projectAssignmentIds = [];
                var boarding;
                
                for(var i = 0; i < this.selected.length; i++){
                    boarding = this.getSourceProjectFromAssignmentId(this.selected[i]);
                    if(boarding != null){
                        boardings.push(boarding);
                        projectAssignmentIds.push(this.selected[i]);
                    }
                }

                this.$emit("set-state", "loading_overlayer", this.lview.sendingRequest);

                var dataPackages = [];
                for(var i = 0; i < projectAssignmentIds.length; i++){
                    dataPackages.push({
                        boarding: boardings[i],
                        promise: axios({
                            method: "PATCH",
                            url: localStorage.getItem("current_env") + "/api/v2/boardings/" + projectAssignmentIds[i],//boardings[i].boardingId,
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                            },
                            data: {
                                boarding: {
                                    on_boarding_date: null,
                                    off_boarding_date: null,
                                    boarding_status: 3 // Hired
                                }
                            }
                        })
                    });
                }

                var response;
                for(var i = 0; i < dataPackages.length; i++){
                    try{
                        response = await dataPackages[i].promise;
                        dataPackages[i].boarding.boardingId = response.data.boarding.id;
                        dataPackages[i].boarding.boardingStatus = response.data.boarding.boarding_status;
                        dataPackages[i].boarding.recordStatus = "HIRED";
                        dataPackages[i].boarding.assignmentStatus = response.data.project_assignment.assignment_status; // Hired
                        dataPackages[i].boarding.offBoardingDate = (response.data.boarding.off_boarding_date == null) ? null : new Date(response.data.boarding.off_boarding_date);
                        dataPackages[i].boarding.onBoardingDate = (response.data.boarding.on_boarding_date == null) ? null : new Date(response.data.boarding.on_boarding_date);
                    } catch(e){
                        if (e.isAxiosError) {
                            if (e.response.data.boarding != null && e.response.data.boarding != undefined) {
                                if (e.response.data.boarding.off_boarding_date != null && e.response.data.boarding.off_boarding_date != undefined) {
                                    this.$emit('display-error', this.lerrors.boarding.off_boarding_date.header, this.lerrors.boarding.off_boarding_date.string, e);
                                    return;
                                }

                                if (e.response.data.boarding.other_boarding != null && e.response.data.boarding.other_boarding != undefined) {
                                    this.$emit('display-error', this.lerrors.boarding.other_boarding.header, this.lerrors.boarding.other_boarding.string, e);
                                    return;
                                }

                                if (e.response.data.boarding.non_field_errors != null && e.response.data.boarding.non_field_errors != undefined) {
                                    this.$emit('display-error', this.lerrors.boarding.non_field_errors.header, this.lerrors.boarding.non_field_errors.string, e);
                                    return;                               
                                }

                                if (e.response.data.boarding.boarding_status != null && e.response.data.boarding.boarding_status != undefined) {
                                    this.$emit('display-error', this.lerrors.boarding.boarding_status.header, this.lerrors.boarding.boarding_status.string, e);
                                    return;                               
                                }   
                            }                         
                            if (e.response.data.project_assignment != null && e.response.data.project_assignment != undefined) {
                                if (e.response.data.project_assignment.finish_date != null && e.response.data.project_assignment.finish_date != undefined) {
                                    this.$emit('display-error', this.lerrors.project_assignment.finish_date.header, this.lerrors.project_assignment.finish_date.string, e);
                                    return;
                                }

                                if (e.response.data.project_assignment.contract_end_date != null && e.response.data.project_assignment.contract_end_date != undefined) {
                                    this.$emit('display-error', this.lerrors.project_assignment.contract_end_date.header, this.lerrors.project_assignment.contract_end_date.string, e);
                                    return;
                                }
                            }
                        }

                        this.$emit("display-error", this.lview.requestError, this.lview.requestErrorMessage, e);
                    }
                }
                
                this.$emit('set-state', "default");
                this.updateList();
                this.closeEditMode();
                this.dialogs.confirmBoarding.visible = false;

                this.showNotification(this.lview.projectUpdated);
            },

            // Remove boarding
            confirmRemoveBoardingDialog: async function(){
                var boardings = [];
                var projectAssignmentsIds = [];
                var boarding;
                
                for(var i = 0; i < this.selected.length; i++){
                    boarding = this.getSourceProjectFromAssignmentId(this.selected[i]);
                    if(boarding != null){
                        boardings.push(boarding);
                        projectAssignmentsIds.push(this.selected[i]);
                    }
                }

                this.$emit("set-state", "loading_overlayer", this.lview.sendingRequest);

                var dataPackages = [];
                for(var i = 0; i < projectAssignmentsIds.length; i++){
                    dataPackages.push({
                        boarding: boardings[i],
                        promise: axios({
                            method: "PATCH",
                            url: localStorage.getItem("current_env") + "/api/v2/boardings/" + projectAssignmentsIds[i],
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                            },
                            data: {
                                boarding: {
                                    archived: 1
                                }
                            }
                        })
                    });
                }

                var response;
                var index;
                for(var i = 0; i < dataPackages.length; i++){
                    try{
                        response = await dataPackages[i].promise;
                        dataPackages[i].boarding.boardingId = response.data.boarding.id;
                        dataPackages[i].boarding.boardingStatus = response.data.boarding.boarding_status; // Terminated
                        dataPackages[i].boarding.recordStatus = "NONE";
                        dataPackages[i].boarding.assignmentStatus = response.data.project_assignment.assignment_status; // Historical
                    
                    } catch(e){
                        if (e.isAxiosError) {
                            if (e.response.data.boarding != null && e.response.data.boarding != undefined) {
                                if (e.response.data.boarding.non_field_errors != null && e.response.data.boarding.non_field_errors != undefined) {
                                    this.$emit('display-error', this.lerrors.boarding.non_field_errors.header, this.lerrors.boarding.non_field_errors.string, e);
                                    return;                               
                                }

                                if (e.response.data.boarding.boarding_status != null && e.response.data.boarding.boarding_status != undefined) {
                                    this.$emit('display-error', this.lerrors.boarding.boarding_status.header, this.lerrors.boarding.boarding_status.string, e);
                                    return;                               
                                }
                            }
                        }
                        this.$emit("display-error", this.lview.requestError, this.lview.requestErrorMessage, e);
                    }
                }

                this.$emit('set-state', "default");
                this.updateList();
                this.closeEditMode();
                this.dialogs.removeBoarding.visible = false;
                this.showNotification(this.lview.projectRemoved);
            },

            // Off boarding
            openOffBoardingDialog(){
                if(this.selected.length > 0){
                    var selected = this.getSourceProjectFromAssignmentId(this.selected[0]);
                    this.dialogs.offBoarding.datePicker.date = (selected.offBoardingDate == null)
                        ? new Date().toJSON().slice(0, 10)
                        : selected.offBoardingDate.toJSON().slice(0, 10);

                    this.$nextTick(() => this.dialogs.offBoarding.visible = true);
                }
            },
            confirmOffBoardingDialog: async function(){
                var boardings = [];
                var projectAssignmentIds = [];
                var boarding;
                
                for(var i = 0; i < this.selected.length; i++){
                    boarding = this.getSourceProjectFromAssignmentId(this.selected[i]);
                    if(boarding != null){
                        boardings.push(boarding);
                        projectAssignmentIds.push(this.selected[i]);
                    }
                }

                this.$emit("set-state", "loading_overlayer", this.lview.sendingRequest);

                var dataPackages = [];
                for(var i = 0; i < projectAssignmentIds.length; i++){
                    dataPackages.push({
                        boarding: boardings[i],
                        promise: axios({
                            method: "PATCH",
                            url: localStorage.getItem("current_env") + "/api/v2/boardings/" + projectAssignmentIds[i],
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                            },
                            data: {
                                boarding: {
                                    on_boarding_date: null,
                                    off_boarding_date: this.dialogs.offBoarding.datePicker.date,
                                    boarding_status: 7 // OFF-BOARDING
                                }
                            }
                        })
                    });
                }

                var response;
                for(var i = 0; i < dataPackages.length; i++){
                    try{
                        response = await dataPackages[i].promise;
                        dataPackages[i].boarding.boardingId = response.data.boarding.id;
                        dataPackages[i].boarding.boardingStatus = response.data.boarding.boarding_status;
                        dataPackages[i].boarding.recordStatus = "OFFBOARDING";
                        dataPackages[i].boarding.assignmentStatus = response.data.project_assignment.assignment_status;
                        dataPackages[i].boarding.offBoardingDate = (response.data.boarding.off_boarding_date == null) ? null : new Date(response.data.boarding.off_boarding_date);
                        dataPackages[i].boarding.onBoardingDate = (response.data.boarding.on_boarding_date == null) ? null : new Date(response.data.boarding.on_boarding_date);
                    } catch(e){
                        if (e.isAxiosError) {
                            if (e.response.data.boarding != null && e.response.data.boarding != undefined) {
                                if (e.response.data.boarding.non_field_errors != null && e.response.data.boarding.non_field_errors != undefined) {
                                    this.$emit('display-error', this.lerrors.boarding.non_field_errors.header, this.lerrors.boarding.non_field_errors.string, e);
                                    return;                               
                                }

                                if (e.response.data.boarding.boarding_status != null && e.response.data.boarding.boarding_status != undefined) {
                                    this.$emit('display-error', this.lerrors.boarding.boarding_status.header, this.lerrors.boarding.boarding_status.string, e);
                                    return;                               
                                }
                            }
                        }

                        this.$emit("display-error", this.lview.requestError, this.lview.requestErrorMessage, e);
                    }
                }

                this.$emit('set-state', "default");
                this.updateList();
                this.closeEditMode();
                this.dialogs.offBoarding.visible = false;
                this.showNotification(this.lview.projectUpdated);
            },

            // Terminate boarding
            openTerminateBoardingDialog(){
                
                if(this.selected.length > 0){
                    var selected = this.getSourceProjectFromAssignmentId(this.selected[0]);

                    var iterator = [
                        {
                            datePicker: this.dialogs.terminateBoarding.offBoardingDatePicker,
                            value: selected.offBoardingDate
                        },
                        {
                            datePicker: this.dialogs.terminateBoarding.contractEndDatePicker,
                            value: selected.contractEndDate
                        },
                        {
                            datePicker: this.dialogs.terminateBoarding.projectEndDatePicker,
                            value: selected.projectEndDate
                        }
                    ];

                    for(var i = 0; i < iterator.length; i++){
                        if(iterator[i].value == null) 
                            iterator[i].datePicker.date = new Date().toJSON().slice(0, 10);
                        else
                            iterator[i].datePicker.date = iterator[i].value.toJSON().slice(0, 10);
                    }

                    this.$nextTick(() => this.dialogs.terminateBoarding.visible = true);
                }
            },
            confirmTerminateBoardingDialog: async function(){
                var boardings = [];
                var projectAssignmentsIds = [];
                var boarding;
                
                for(var i = 0; i < this.selected.length; i++){
                    boarding = this.getSourceProjectFromAssignmentId(this.selected[i]);
                    if(boarding != null){
                        boardings.push(boarding);
                        projectAssignmentsIds.push(this.selected[i]);
                    }
                }

                this.$emit("set-state", "loading_overlayer", this.lview.sendingRequest);

                var dataPackages = [];
                for(var i = 0; i < projectAssignmentsIds.length; i++){
                    dataPackages.push({
                        boarding: boardings[i],
                        promise: axios({
                            method: "PATCH",
                            url: localStorage.getItem("current_env") + "/api/v2/boardings/" + projectAssignmentsIds[i],
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                            },
                            data: {
                                boarding: {
                                    archived: 1,
                                    off_boading_date: this.dialogs.terminateBoarding.offBoardingDatePicker.date
                                },
                                project_assignment: {
                                    contract_end_date: this.dialogs.terminateBoarding.contractEndDatePicker.date,
                                    finish_date: this.dialogs.terminateBoarding.projectEndDatePicker.date,
                                }
                            }
                        })
                    });
                }

                var response;
                for(var i = 0; i < dataPackages.length; i++){
                    try{
                        response = await dataPackages[i].promise;
                        dataPackages[i].boarding.boardingId = response.data.boarding.id;
                        dataPackages[i].boarding.boardingStatus = response.data.boarding.boarding_status; // Terminated
                        dataPackages[i].boarding.recordStatus = "NONE";
                        dataPackages[i].boarding.assignmentStatus = response.data.project_assignment.assignment_status; // Historical
                        dataPackages[i].boarding.projectEndDate = response.data.project_assignment.finish_date != null
                            ? new Date(response.data.project_assignment.finish_date)
                            : null;
                        dataPackages[i].boarding.contractEndDate = response.data.project_assignment.contract_end_date != null
                            ? new Date(response.data.project_assignment.contract_end_date)
                            : null;
                        dataPackages[i].boarding.offBoardingDate = response.data.boarding.off_boarding_date != null
                            ? new Date(response.data.boarding.off_boarding_date)
                            : null;
                        //console.log(response);
                    } catch(e){
                        this.$emit("set-state", 'default');
                        if (e.isAxiosError) {
                            // boarding errors
                            if (e.response.data.boarding != null && e.response.data.boarding != undefined) {
                                if (e.response.data.boarding.off_boarding_date != null && e.response.data.boarding.off_boarding_date != undefined) {
                                    this.$emit('display-error', this.lerrors.boarding.off_boarding_date.header, this.lerrors.boarding.off_boarding_date.string, e);
                                    return;
                                }

                                if (e.response.data.boarding.non_field_errors != null && e.response.data.boarding.non_field_errors != undefined) {
                                    this.$emit('display-error', this.lerrors.boarding.non_field_errors.header, this.lerrors.boarding.non_field_errors.string, e);
                                    return;                               
                                }

                                if (e.response.data.boarding.boarding_status != null && e.response.data.boarding.boarding_status != undefined) {
                                    this.$emit('display-error', this.lerrors.boarding.boarding_status.header, this.lerrors.boarding.boarding_status.string, e);
                                    return;                               
                                }
                            }
                            
                            // project assignment errors
                            if (e.response.data.project_assignment != null && e.response.data.project_assignment != undefined) {
                                if (e.response.data.project_assignment.finish_date != null && e.response.data.project_assignment.finish_date != undefined) {
                                    this.$emit('display-error', this.lerrors.project_assignment.finish_date.header, this.lerrors.project_assignment.finish_date.string, e);
                                    return;
                                }

                                if (e.response.data.project_assignment.contract_end_date != null && e.response.data.project_assignment.contract_end_date != undefined) {
                                    this.$emit('display-error', this.lerrors.project_assignment.contract_end_date.header, this.lerrors.project_assignment.contract_end_date.string, e);
                                    return;
                                }     
                            }            
                        }
                        this.$emit("display-error", this.lview.requestError, this.lview.requestErrorMessage, e);
                    }
                }

                this.$emit('set-state', "default");
                this.updateList();
                this.closeEditMode();
                this.dialogs.terminateBoarding.visible = false;
                this.showNotification(this.lview.projectRemoved);
            },

            // Edit boarding
            openEditBoardingDialog(){
                if(this.selected.length > 0){
                    var selected = this.getSourceProjectFromAssignmentId(this.selected[0]);
                    this.dialogs.editBoarding.changeProjectSelect.enabled = false;
                    this.dialogs.editBoarding.changeProjectSelect.options = [];
                    this.dialogs.editBoarding.changeProjectSelect.picked = null;
                    this.dialogs.editBoarding.changeWorkTypeSelect.enabled = false;
                    this.dialogs.editBoarding.changeWorkTypeSelect.picked = null;
                    this.dialogs.editBoarding.editComment.enabled=false;
                    this.dialogs.editBoarding.editComment.text=selected.comment;

                    var usedProjects = [];
                    for (var i = 0; i < this.sourceData.length; i++) {
                        if(this.sourceData[i].recordStatus != "NONE"){
                            usedProjects.push(this.sourceData[i].projectId);
                        }
                    }

                    var selectList = this.dialogs.editBoarding.changeProjectSelect.options;
                    selectList.splice(0, selectList.length);

                    // Picking projects from projects dictionary, that are not already assigned to this worker
                    for (var i = 0; i < this.projectsDictionary.length; i++) {
                        if (!usedProjects.includes(this.projectsDictionary[i].id)) {
                            selectList.push(this.projectsDictionary[i]);
                        }
                    }

                    var iterator = [
                        {
                            datePicker: this.dialogs.editBoarding.onBoardingDatePicker,
                            value: selected.onBoardingDate
                        },
                        {
                            datePicker: this.dialogs.editBoarding.offBoardingDatePicker,
                            value: selected.offBoardingDate
                        },
                        {
                            datePicker: this.dialogs.editBoarding.contractStartDatePicker,
                            value: selected.contractStartDate
                        },
                        {
                            datePicker: this.dialogs.editBoarding.contractEndDatePicker,
                            value: selected.contractEndDate
                        },
                        {
                            datePicker: this.dialogs.editBoarding.projectStartDatePicker,
                            value: selected.projectStartDate
                        },
                        {
                            datePicker: this.dialogs.editBoarding.projectEndDatePicker,
                            value: selected.projectEndDate
                        }
                    ];

                    for(var i = 0; i < iterator.length; i++){
                        iterator[i].datePicker.enabled = false;
                        if(iterator[i].value == null) 
                            iterator[i].datePicker.date = new Date().toJSON().slice(0, 10);
                        else
                            iterator[i].datePicker.date = iterator[i].value.toJSON().slice(0, 10);
                    }

                    this.$nextTick(() => this.dialogs.editBoarding.visible = true);
                }
            },
            isEmpty(obj){
                for(var key in obj){
                    if(obj.hasOwnProperty(key))
                        return false;
                }
                return true;
            },
            confirmEditBoardingDialog: async function(){
                
                var newProjectId = null;

                if(this.dialogs.editBoarding.changeProjectSelect.enabled){
                    newProjectId = this.dialogs.editBoarding.changeProjectSelect.picked;
                }

                var datesAssignment = [
                    {
                        requestName: 'contract_start_date',
                        datePicker: this.dialogs.editBoarding.contractStartDatePicker
                    },
                    {
                        requestName: 'contract_end_date',
                        datePicker: this.dialogs.editBoarding.contractEndDatePicker
                    },
                    {
                        requestName: 'start_date',
                        datePicker: this.dialogs.editBoarding.projectStartDatePicker
                    },
                    {
                        requestName: 'finish_date',
                        datePicker: this.dialogs.editBoarding.projectEndDatePicker
                    }
                ];
                var datesBoarding = [
                    {
                        requestName: 'on_boarding_date',
                        datePicker: this.dialogs.editBoarding.onBoardingDatePicker
                    },
                    {
                        requestName: 'off_boarding_date',
                        datePicker: this.dialogs.editBoarding.offBoardingDatePicker
                    }
                ];
                
                //
                // Preparing project assignment request
                //
                var requestDataAssignment = {};
                var sendUpdateRequest = false;

                // if(this.dialogs.editBoarding.editComment.text != null)
                requestDataAssignment.description=this.dialogs.editBoarding.editComment.text;
                sendUpdateRequest = true;
                if(this.dialogs.editBoarding.changeWorkTypeSelect.picked != null){
                    // new work type
                    requestDataAssignment.assignment_type = this.dialogs.editBoarding.changeWorkTypeSelect.picked;

                    sendUpdateRequest = true;
                }

                if(newProjectId != null){
                    requestDataAssignment.project_id = newProjectId;

                    sendUpdateRequest = true;
                }

                var datePicker;
                for(var i = 0; i < datesAssignment.length; i++){
                    // changed dates
                    datePicker = datesAssignment[i].datePicker;
                    if(datePicker.enabled){
                        requestDataAssignment[datesAssignment[i].requestName] = datePicker.date;

                        sendUpdateRequest = true;
                    }
                }

                //
                // Preparing boarding request
                //
                var requestDataBoarding = {};

                for(var i = 0; i < datesBoarding.length; i++){
                    // changed dates
                    datePicker = datesBoarding[i].datePicker;
                    if(datePicker.enabled){
                        requestDataBoarding[datesBoarding[i].requestName] = datePicker.date;

                        sendUpdateRequest = true;
                    }
                }

                var boardings = [];
                var projectAssignmentIds = [];
                var boarding;

                for(var i = 0; i < this.selected.length; i++){
                    boarding = this.getSourceProjectFromAssignmentId(this.selected[i]);
                    if(boarding != null){
                        boardings.push(boarding);
                        projectAssignmentIds.push(this.selected[i]);
                    }
                }

                this.$emit("set-state", "loading_overlayer", this.lview.sendingRequest);

                //
                // Assignment requests
                //
                var dataPackagesAssignment = [];

                var successes = 0;
                if(sendUpdateRequest){
                    for(var i = 0; i < projectAssignmentIds.length; i++){
                        dataPackagesAssignment.push({
                            boarding: boardings[i],
                            promise: axios({
                                method: "PATCH",
                                url: localStorage.getItem("current_env") + "/api/v2/boardings/" + projectAssignmentIds[i],
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                                },
                                data: {
                                    boarding: requestDataBoarding,
                                    project_assignment: requestDataAssignment
                                }
                            })
                        });
                    }

                    let that = this;
                    let projectId = null, projectName = "", project = null;
                    var date;
                    for(var i = 0; i < projectAssignmentIds.length; i++){
                        try {
                            let response = await dataPackagesAssignment[i].promise;

                            // project assignment data processing
                            project = response.data.project_assignment.project;
                            if(project == null){
                                projectId = -1;
                                projectName = this.lview.unassigned;
                            }
                            else {
                                projectId = project.id;
                                projectName = project.name;
                                dataPackagesAssignment[i].boarding.assignmentType = response.data.project_assignment.assignment_type;
                            }

                            dataPackagesAssignment[i].boarding.projectId = projectId;
                            dataPackagesAssignment[i].boarding.projectName = projectName;

                            dataPackagesAssignment[i].boarding.comment=response.data.project_assignment.description

                            date = response.data.project_assignment.start_date;
                            dataPackagesAssignment[i].boarding.projectStartDate = (date == null) ? null : new Date(date);
                            
                            date = response.data.project_assignment.finish_date;
                            dataPackagesAssignment[i].boarding.projectEndDate = (date == null) ? null : new Date(date);

                            date = response.data.project_assignment.contract_start_date;
                            dataPackagesAssignment[i].boarding.contractStartDate = (date == null) ? null : new Date(date);

                            date = response.data.project_assignment.contract_end_date;
                            dataPackagesAssignment[i].boarding.contractEndDate = (date == null) ? null : new Date(date);


                            // boarding data processing
                            dataPackagesAssignment[i].boarding.boardingId = response.data.boarding.id;

                            date = response.data.boarding.on_boarding_date;
                            dataPackagesAssignment[i].boarding.onBoardingDate = (date == null) ? null : new Date(date);

                            date = response.data.boarding.off_boarding_date;
                            dataPackagesAssignment[i].boarding.offBoardingDate = (date == null) ? null : new Date(date);

                            successes++;
                        } catch(e) {
                            that.$emit('set-state', 'default');
                            if (e.isAxiosError) {
                                if (e.response.data.boarding != null && e.response.data.boarding != undefined) {
                                    if (e.response.data.boarding.off_boarding_date != null && e.response.data.boarding.off_boarding_date != undefined) {
                                        this.$emit('display-error', this.lerrors.boarding.off_boarding_date.header, this.lerrors.boarding.off_boarding_date.string, e);
                                        return;
                                    }

                                    if (e.response.data.boarding.other_boarding != null && e.response.data.boarding.other_boarding != undefined) {
                                        this.$emit('display-error', this.lerrors.boarding.other_boarding.header, this.lerrors.boarding.other_boarding.string, e);
                                        return;
                                    }

                                    if (e.response.data.boarding.boarding_status != null && e.response.data.boarding.boarding_status != undefined) {
                                        this.$emit('display-error', this.lerrors.boarding.boarding_status.header, this.lerrors.boarding.boarding_status.string, e);
                                        return;                               
                                    }

                                    if (e.response.data.boarding.non_field_errors != null && e.response.data.boarding.non_field_errors != undefined) {
                                        this.$emit('display-error', this.lerrors.boarding.non_field_errors.header, this.lerrors.boarding.non_field_errors.string, e);
                                        return;                               
                                    }
                                }

                                if (e.response.data.project_assignment != null && e.response.data.project_assignment != undefined) {
                                    if (e.response.data.project_assignment.finish_date != null && e.response.data.project_assignment.finish_date != undefined) {
                                        this.$emit('display-error', this.lerrors.project_assignment.finish_date.header, this.lerrors.project_assignment.finish_date.string, e);
                                        return;
                                    }

                                    if (e.response.data.project_assignment.contract_end_date != null && e.response.data.project_assignment.contract_end_date != undefined) {
                                        this.$emit('display-error', this.lerrors.project_assignment.contract_end_date.header, this.lerrors.project_assignment.contract_end_date.string, e);
                                        return;
                                    }
                                }

                            }
                            else {
                                that.$emit('display-error', that.lview.errorOccuredTitle, that.lview.errorOccured, e);
                            }
                        }
                    }
                }

                //
                // Boarding requests
                //
                /*var dataPackagesBoarding = [];

                if(sendBoardingRequest){
                    for(var i = 0; i < boardings.length; i++){
                        dataPackagesBoarding.push({
                            boarding: boardings[i],
                            promise: axios({
                                method: "PATCH",
                                url: localStorage.getItem("current_env") + "/api/boardings/" + boardings[i].boardingId,
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                                },
                                data: requestDataBoarding
                            })
                        });
                    }
                }

                //
                // Awaiting responses
                //

                var response;
                var date;
                var project;
                var projectId;
                var projectName;
                var successes = 0;

                // ProjectAssigments responses
                for(var i = 0; i < dataPackagesAssignment.length; i++){
                    try{
                        response = await dataPackagesAssignment[i].promise;
                        project = response.data.project;
                        if(project == null){
                            projectId = -1;
                            projectName = this.lview.unassigned;
                        }
                        else {
                            projectId = project.id;
                            projectName = project.name;
                            dataPackagesAssignment[i].boarding.assignmentType = response.data.assignment_type;
                        }

                        dataPackagesAssignment[i].boarding.projectId = projectId;
                        dataPackagesAssignment[i].boarding.projectName = projectName;

                        date = response.data.start_date;
                        dataPackagesAssignment[i].boarding.projectStartDate = (date == null) ? null : new Date(date);
                        
                        date = response.data.finish_date;
                        dataPackagesAssignment[i].boarding.projectEndDate = (date == null) ? null : new Date(date);

                        date = response.data.contract_start_date;
                        dataPackagesAssignment[i].boarding.contractStartDate = (date == null) ? null : new Date(date);

                        date = response.data.contract_end_date;
                        dataPackagesAssignment[i].boarding.contractEndDate = (date == null) ? null : new Date(date);

                        successes++;

                        /* TO REMOVE
                            date = response.data.on_boarding_date;
                            dataPackages[i].project.onBoardingDate = (date == null) ? null : new Date(date);

                            date = response.data.off_boarding_date;
                            dataPackages[i].project.offBoardingDate = (date == null) ? null : new Date(date);
                        */
                    /*} catch(error){
                        this.$emit("display-error", this.lview.requestError, this.lview.requestErrorMessage, error);
                    }
                }

                // Boarding responses
                for(var i = 0; i < dataPackagesBoarding.length; i++){
                    try{
                        response = await dataPackagesBoarding[i].promise;
                        
                        dataPackagesBoarding[i].boarding.boardingId = response.data.id;

                        date = response.data.on_boarding_date;
                        dataPackagesBoarding[i].boarding.onBoardingDate = (date == null) ? null : new Date(date);

                        date = response.data.off_boarding_date;
                        dataPackagesBoarding[i].boarding.offBoardingDate = (date == null) ? null : new Date(date);

                        successes++;
                    } catch(error) {
                        this.$emit("display-error", this.lview.requestError, this.lview.requestErrorMessage, error);
                    }
                }*/

                this.$emit('set-state', "default");

                if(successes > 0){
                    this.updateList();
                    this.closeEditMode();
                    this.dialogs.editBoarding.visible = false;
                    this.showNotification(this.lview.projectUpdated);
                }
            },


            //
            // REQUESTS, ADAPTERS AND UPDATING DISPLAY LIST
            //
			
			updateList() {
                this.closeEditMode();
				/*console.log(this.selected);
				this.projectsList.splice(0,this.projectsList.length);
				var project;
				if(this.selected.length == 0){
					for(var i=0; i<this.myProjects.length; i++){
						project = this.myProjects[i];
						this.projectsList.push(project);
					}
				}
				else{
					for(var i=0; i<this.myProjects.length; i++){
						project = this.myProjects[i];
						console.log(">>>> " + JSON.stringify(this.selected));
						if( this.selected.includes(project.state.name[0])){
							this.projectsList.push(project);
						}
					}
				}*/

				this.listData.splice(0, this.listData.length);
				var record;
                var avatarSign;
                var sortSign;

				for (var i = 0; i < this.sourceData.length; i++) {
                    record = this.sourceData[i];
                    switch (record.assignmentStatus) {
						case 0:
                            avatarSign = 'P';
                            sortSign = '1';
							break;
						case 2:
                            avatarSign = "A";
                            sortSign = "2";
							break;
						//case 5:
                        //    avatarSign = "H";
                        //    sortSign = "3";
						//	break;
						default:
                            avatarSign = "H";
                            sortSign = "3";
                            break;
                    }

					if (this.filters.indexOf(avatarSign) != -1) {
						this.listData.push({
                            projectId: (record.assignmentStatus == 5) ? null : record.projectId,
                            sortSign: sortSign,
							projectName: record.projectName,
							recordStatus: record.recordStatus,
                            assignmentType: record.assignmentType,
                            assignmentTypeText: this.getAssignmentTypeText(record.assignmentType),
							assignmentStatus: record.assignmentStatus,
                            assignmentId: record.assignmentId,
                            startDate: (record.projectStartDate != null) ? record.projectStartDate.toJSON().slice(0, 10) : null,
							endDate: (record.projectEndDate != null) ? record.projectEndDate.toJSON().slice(0, 10) : null,
							displayDates: this.getDisplayDates(record.onBoardingDate, record.offBoardingDate, record.projectStartDate, record.projectEndDate),
							displayProjectDetails: this.getDisplayProjectDetails(record.details),
                            markable: (sortSign == "1" || sortSign == "2"),
                            avatarSign: avatarSign,
                            sortValue: record.projectEndDate
						});
					}
				}

				this.listData.sort((a, b) => {
                    var sorterA;
                    var sorterB;
                    if(a.sortValue == null){
                        if(b.sortValue == null){
                            sorterA = a.projectName + " " + a.sortSign;
                            sorterB = b.projectName + " " + b.sortSign;
                        }
                        else
                            return -1;
                    }
                    else if(b.sortValue == null){
                        if(a.sortValue == null){
                            sorterA = a.projectName + " " + a.sortSign;
                            sorterB = b.projectName + " " + b.sortSign;
                        }
                        else
                            return 1;
                    }
                    else if(a.sortValue == b.sortValue){
                        sorterA = a.endDate + " " + a.projectName + " " + a.sortSign;
                        sorterB = b.endDate + " " + b.projectName + " " + b.sortSign;
                    }
                    else{
                        sorterA = a.sortValue;
                        sorterB = b.sortValue;
                    }

					if (sorterA < sorterB) return 1;
					else if (sorterA == sorterB) return 0;
					else return -1;
				})
			},

            adaptOffBoardingTypes(json) {
				var result = [];

                for (var i = 0; i < json.length; i++) {
                    let type = json[i];
                    result.push({
                        id: type.id,
                        name: type.choice_name
                    });
                }

				return result;
			},

			adaptProjects(projectsData) {
				var result = [];

                var boarding;
                var projectAssignment;
                var projectAssignmentId;
                var recordStatus;
                for (var i = 0; i < projectsData.boardings.length; i++) {
                    boarding = projectsData.boardings[i];
                    projectAssignmentId = boarding.project_assignment.id;
                    projectAssignment = boarding.project_assignment;
                    
                    recordStatus = (boarding.boarding_status.toString() in this.recordStatuses) ? this.recordStatuses[boarding.boarding_status] : "NONE";

                    if (projectAssignment != null) {
                        result.push({
                            boardingId: boarding.id,
                            assignmentId: projectAssignment.id,
                            projectId: (projectAssignment.project == null) ? -1 : projectAssignment.project.id,
                            recordStatus: recordStatus,
                            projectName: (projectAssignment.project == null) ? this.lview.unassigned : projectAssignment.project.name,
                            assignmentType: projectAssignment.assignment_type,
                            assignmentStatus: projectAssignment.assignment_status,
                            boardingStatus: boarding.boarding_status,
                            projectStartDate: (projectAssignment.start_date == null) ? null : new Date(projectAssignment.start_date),
                            projectEndDate: (projectAssignment.finish_date == null) ? null : new Date(projectAssignment.finish_date),
                            contractStartDate: (projectAssignment.contract_start_date == null) ? null : new Date(projectAssignment.contract_start_date),
                            contractEndDate: (projectAssignment.contract_end_date == null) ? null : new Date(projectAssignment.contract_end_date),
                            onBoardingDate: (boarding.on_boarding_date == null) ? null : new Date(boarding.on_boarding_date),
                            offBoardingDate: (boarding.off_boarding_date == null) ? null : new Date(boarding.off_boarding_date),
                            details: projectAssignment.details,
                            comment: projectAssignment.description,
                        });
                    }
                }
                // Historical projects
                /*
                    if(projectsData.project_assignments != null){
                        for(var i = 0; i < projectsData.project_assignments.length; i++){
                            projectAssignment = projectsData.project_assignments[i];
                            boarding = projectsData.boardings[i];
                            if(projectAssignment.assignment_status == 5){
                                result.push({
                                    projectId: (projectAssignment.project == null) ? -1 : projectAssignment.project.id,
                                    recordStatus: "NONE",
                                    assignmentId: projectAssignment.id,
                                    projectName: (projectAssignment.project == null) ? this.lview.unassigned : projectAssignment.project.name,
                                    assignmentType: projectAssignment.assignment_type,
                                    assignmentStatus: projectAssignment.assignment_status,
                                    boardingStatus: null,
                                    projectStartDate: (projectAssignment.start_date == null) ? null : new Date(projectAssignment.start_date),
                                    projectEndDate: (projectAssignment.finish_date == null) ? null : new Date(projectAssignment.finish_date),
                                    contractStartDate: (projectAssignment.contract_start_date == null) ? null : new Date(projectAssignment.contract_start_date),
                                    contractEndDate: (projectAssignment.contract_end_date == null) ? null : new Date(projectAssignment.contract_end_date),
                                    onBoardingDate: (boarding.on_boarding_date == null) ? null : new Date(boarding.on_boarding_date),
                                    offBoardingDate: (boarding.off_boarding_date == null) ? null : new Date(boarding.off_boarding_date)
                                });
                            }
                        }
                    }
                */

				return result;
			},

			adaptProjectsDictionary(json) {
				var result = [];
				var project;

				//if (json.project_assignments != null) {
                for (var i = 0; i < json.projects.length; i++) {
                    project = json.projects[i];
                    //if (assignment.project != null && assignment.assignment_status != 5) {
                    result.push({
                        id: project.id,
                        name: project.name
                    });
                    //}
                }
                //}

				return result;
			},

			handleData(json) {
                this.offBoardingTypes=this.adaptOffBoardingTypes(json.offBoardingTypes)
				var projects = this.adaptProjects(json.userProjects);
                this.sourceData = projects;

				this.projectsDictionary.splice(1, this.projectsDictionary.length - 1);
				this.projectsDictionary = this.projectsDictionary.concat(this.adaptProjectsDictionary(json.projectsDictionary));

				this.updateList();
			},

            getOffBoardingTypes: async function () {
				var response = await axios({
					method: "GET",
					url: localStorage.getItem('current_env') + "/api/v2/boardings/boarding-details-types/",
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                    },
				});
				return response.data;
			},

			getUserProjects: async function (workerId) {
				var response = await axios({
					method: "GET",
					url: localStorage.getItem('current_env') + "/api/v2/boardings/worker-recent/" + workerId,
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                    },
                    params: {}
				});
				return response.data;
			},

			getProjectsDictionary: async function () {
				var response;

				response = await axios({
					method: "GET",
					url: localStorage.getItem("current_env") + "/api/v2/boardings/projects",
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + localStorage.getItem('jwt')
					}
				});

				return response.data;
			},

			getPageData: async function () {
				var workerId = this.$route.params.worker_id;
				//var managerId = JSON.parse(localStorage.getItem("workers"))[0].id;

                var offBoardingTypesPromise = this.getOffBoardingTypes();
				var userProjectsPromise = this.getUserProjects(workerId);
				var projectsDictionaryPromise = this.getProjectsDictionary();

				try {
					return {
                        offBoardingTypes: await offBoardingTypesPromise,
						userProjects: await userProjectsPromise,
						projectsDictionary: await projectsDictionaryPromise
					};
				} catch (error) {
                    console.log(error)
					//throw error;
                    // if(error.response.status == 403){
                    //     this.$emit("set-state", "ERROR", this.lmessages.forbidden);
                    // }
                    // else{
                    //     this.$emit("set-state", 'error', this.lmessages.errorOccured);
					//     this.$emit('display-error', this.lview.cannotLoadPageTitle, this.lview.cannotLoadPageMessage, error);
                    // }

					return {};
				}
            },
            
            //
            // EVENT HANDLERS
            //
            onEnablableDatePickerClick(datePicker){
                if(!datePicker.enabled){
                    datePicker.enabled = true;
                    datePicker.visible = true;
                }
            },

            /*
                onNotificationAppear(){
                    if(this.notification.timeout != false){
                        clearTimeout(this.notification.timeout);
                    }

                    this.notification.timeout = setTimeout(() => {
                        this.notification.visible = false;
                    }, 1500);
                }
            */
		}
	}
</script>

<style scoped>
    .Projects{
        margin-bottom: 100px;
    }

	.boarding-add-new {
		position: fixed;
		left: calc(50% - 36px + 128px);
		bottom: 28px;
		z-index: 4;
	}
    @media only screen and (max-width: 800px){
		.boarding-add-new {
			left: calc(50% - 36px);
		}
	}

	.projects-list {
		background-color: rgba(0,0,0,0);
		padding-top: 5px;
		padding-bottom: 10px;
	}

	.project-record.selected {
		background-color: rgba(0,0,0,0.2);
	}

	.edit-toolbar {
        margin-top: -56px;
        z-index: 11 !important;
		position: fixed;
	}

	.edit-toolbar[edit-mode=true] {
		display: block;
	}

    .record-avatar-initials {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		background-color: #CFCFCF;
		border-radius: 50%;
		font-weight: bold;
	}

    .project-details {
        text-align: center;
        align-self: center;
        justify-items: center;
    }
</style>