<template>
	<v-layout style="margin-top: 10px;">
		<!-- <v-btn
			v-model="fab"
			:top="top"
			:bottom="bottom"
			:right="right"
			:left="left"
			:transparent = false
			color="primary"
			fab
			style="position: fixed; bottom: 16px; right: 16px;"
			@click="openAddUser"
		>
			<v-icon>
				mdi-plus 
			</v-icon>
		</v-btn> -->

		<v-data-table
			:headers="dataTablesHeadersRendered"
			:items="dataTablesItems"
			:options.sync="options"
			hide-default-footer
			:loading="dataTableLoading"
		>
			<template v-slot:top>
				<v-flex xs12 style="padding-top: 10px;">

					<!-- users label -->
					<!-- <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10" style="display: flex; padding-top: 0px; padding-bottom: 0px;">
						<div 
							class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-1" 
							style = "margin-top: auto; margin-bottom: auto; background-color:lightgreen; font-weight:bold; font-size: 13px; text-align:center; padding: 0; border-radius: 5px; justify-content: center;  align-items: center;"
						> 
							{{ usersNumber }} 
						</div>

						<div 
							class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3" 
							style = "font-weight:bold;  font-size: 13px; justify-content: center;  align-items: center;"
						> 
							{{ lviews.users }} 
						</div>
					</div> -->

					<div>
						<!-- search field -->
						<v-text-field
							v-model="search"
							:label="lviews.search"
							style="height: 66px;"
							clearable
							append-icon="search"
							class="user-list-search-field"
						></v-text-field>

						<div style="display: inline-block; height: 66px; padding-top: 0px; padding-bottom: 0px; padding-left: 0px;" class="user-list-status-select-wrapper">
							<!-- status select -->
							<v-select
								v-model="selectedStatus"
								:items="statuses"
								:label="lviews.status"
								class="user-list-status-select-field"
								@change="updatePageData"
							></v-select>

							<!-- roles select -->
							<v-select
								v-model="roleSelected"
								:items="roles"
								:label="lviews.role"
								item-value="id"
								item-text="name"
								class="user-list-status-select-field"
								@change="updatePageData"
							></v-select>

							<!-- data sharing select -->
							<v-select
								v-model="dataSharingRoleSelected"
								:items="dataSharingRoles"
								:label="lviews.dataAccess"
								item-value="id"
								item-text="name"
								class="user-list-status-select-field"
								@change="updatePageData"
							></v-select>

							<!-- modify columns dialog trigger button -->
							<v-btn
								style="margin-top: 10px;"
								color="primary"
								@click="openModifyDataTableColumnsDialog"
								small
								fab
							> 
								<v-icon> mdi-settings </v-icon> 
							</v-btn>
						</div>
					</div>
				</v-flex>
			</template>

			<template v-slot:[`item.worker`]="{ item }">
				<v-icon v-if="item.worker == null" color="error"> mdi-close </v-icon>
				<v-icon v-else color="primary"> mdi-check </v-icon>
			</template>

			<template v-slot:[`item.options`]="{ item }">
				<v-menu v-if="item.options[0].length > 0" offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-icon
							color="primary"
							dark
							v-bind="attrs"
							v-on="on"
						>
							mdi-dots-vertical
						</v-icon>
					</template>

					<v-list style="min-width: 250px;">
						<div
							v-for="(group, groupIndex) in item.options"
							:key="groupIndex"
						>
							<v-list-item v-for="(groupItem, groupItemKey) in group" :key="groupItemKey" class="worker-document-menu-option" :disabled="groupItem.disabled">
								<v-flex :style="groupItem.style" @click="() => { groupItem.action(item); }">
									<v-icon :style="groupItem.style"> {{ groupItem.icon }} </v-icon>
									{{ groupItem.title }}
								</v-flex>
							</v-list-item>
							<v-divider v-if="groupIndex < item.options.length - 1" style="border-bottom: 1px solid lightgray;"></v-divider>
						</div>
					</v-list>
				</v-menu>
			</template>

			<template v-slot:[`item.status`]="{ item }">
				<v-chip
					:color="getColor(item.status, item.blocked, item)"
					dark
				>
					{{ item.statusText }}
				</v-chip>
			</template>

			<template v-slot:[`item.emailStatus`]="{ item }">               
				<v-tooltip v-if="item.emailError != null" left>
					<template v-slot:activator="{ on, attrs }">
						<v-chip
							:color="getEmailColor(item.emailStatus)"
							dark
							v-bind="attrs"
							v-on="on"
						>
							{{ item.emailStatusText }}
						</v-chip>
					</template>
					<span> {{item.emailError}} </span>
				</v-tooltip>

				<v-chip v-else
					:color="getEmailColor(item.emailStatus)"
					dark
				>
					{{ item.emailStatusText }}
				</v-chip>
			</template>

			<template v-slot:footer>
				<div class="display: inline-block;">
					<div class="users-summary-label" style="margin-top: 15px;">
						<div style="display: inline-block;"> {{ activeUsersNumber }} {{ lviews.active }}, {{ inactiveUsersNumber }} {{ lviews.inactive }} </div>
					</div>

					<worker-document-data-table-footer
						:lviews="lviews"
						:itemsPerPageOptions="itemsPerPageOptions"

						:pageNumber="options.page"
						:itemsPerPageNumber="options.itemsPerPage"
						@update-page-number="updatePageNumber"
						@update-page-size="updatePageSize"

						:totalItemsNumber="serverSideTotalItemsNumber"
					></worker-document-data-table-footer>
				</div>
			</template>
		</v-data-table>

		<v-dialog
			v-model="modifyDataTablesColumnsDialog"
			width="350"
			persistent
		>
			<v-card>
				<v-card-title style="font-size: 18px;"> {{lviews.chooseColumns}} </v-card-title>
				<v-list>
					<v-list-item v-for="(option, index) in dataTableHeadersSelectItems" :key="index">
						<v-checkbox
							v-model="dataTableHeadersSelected"
							:label="option.text"
							:value="option.value"
							style="padding:0; margin:0;"
						></v-checkbox>
					</v-list-item>
				</v-list>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text color="primary" class="align-self-center" style="margin-bottom: 15px;" @click="() => { modifyDataTablesColumnsDialog = false; }"> {{lviews.cancel}} </v-btn>
					<v-btn text color="primary" class="align-self-center" style="margin-bottom: 15px;" @click="() => { updateDataTablesColumns(); modifyDataTablesColumnsDialog = false; }" :disabled="dataTableHeadersSelected.length == 1"> {{lviews.save}} </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-snackbar
			v-model="snackbar.visible"
			:color="snackbar.color"
		>
			{{ snackbar.message }}

			<template v-slot:action="{ attrs }">
				<v-btn
					color="white"
					text
					v-bind="attrs"
					@click="snackbar.visible = false"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>

		<user-dialog
			ref="userDialog"
			:userId="userDialogUserId"
			:userData="userDialogUserData"
			:roles="userRolesData"
			:dataAccess="dataAccessData"
			@refresh-data="updatePageData"
			@show-overlayer="showOverlayer"
			@hide-overlayer="hideOverlayer"
		></user-dialog>

		<!-- add new user button -->
		<v-btn
			color="primary"
			fab
			v-if="canUserAddNewUsers"
			@click="createNewUser"
			class="add-new-users-button"
		>
			<v-icon>
				mdi-plus 
			</v-icon>
		</v-btn>

		<!-- user set new value for 'is_active' confirmation dialog -->
		<v-dialog 
			persistent 
			v-model="userActiveStatusChangeDialog.visible"
			max-width="320"
		>
			<v-card>
				<v-card-title class="headline text-h5">{{ userActiveStatusChangeDialog.title }}</v-card-title>

				<v-card-text style="text-align:justify">{{ userActiveStatusChangeDialog.content }}</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>

					<v-btn color="grey darken-1"
						text
						@click="() => { userActiveStatusChangeDialog.visible = false; }">
						{{ lviews.cancel }}
					</v-btn>

					<v-btn color="green darken-1"
						text
						@click="changeUserActiveStatus">
						{{ lviews.yes }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- delete user dialog -->
		<v-dialog
			v-model="deleteUserDialog.visible"
			width="350px"
			persistent
		>
			<v-card >
				<v-card-title>
					<span> {{ lviews.deleteUserTitle }} </span>
				</v-card-title>

				<v-card-text>
					<span> {{ lviews.deleteUserContent }} </span>
					<strong> {{ deleteUserDialog.userName }}</strong> ?
				</v-card-text>
				
				<v-progress-linear
					v-if="deleteUserDialog.loading"
					indeterminate
					color="primary"
				></v-progress-linear>
				
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="deleteUserCancel" text>
						{{ lviews.cancel }}
					</v-btn>
					<v-btn @click="deleteUserSave" color="red" text>
						<strong> {{ lviews.delete }} </strong>
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-layout>
</template>

<script>
import axios from 'axios';
import fob_config from '../fob_config.js';
export default {
	name: 'UserList',
	data() {
		return{
			fab: false,
			top: false,
			right: true,
			bottom: true,
			left: false,
			dialog: false,
			dialogDelete: false,
			deleteUserDialog: {
				visible: false,
				id: null,
				userName: null,
				loading: false,
			},
			options: {},
			search: '',
			dataTablesHeaders:[
				{ id: 0, text: '', align: 'start', sortable: true, value: 'name', visibleByDefault: true, },
				{ id: 1, text: '', sortable: true, value: 'birthDate', visibleByDefault: true, },
				{ id: 2, text: '', sortable: false, value: 'pesel', visibleByDefault: false, },
				{ id: 3, text: '', sortable: false, value: 'worker', visibleByDefault: false, },
				{ id: 4, text: '', sortable: false, value: 'role', visibleByDefault: true, },
				{ id: 5, text: '', sortable: false, value: 'dataAccess', visibleByDefault: false, },

				// Version with data access
				// { id: 3, text: '{L Dostęp do danych }', sortable: true, value: 'dataAccess'},
				// { id: 4, text: '{L Status }', sortable: true, value: 'status'},
				// { id: 5, text: '{L Ostatnio zalogowany }', sortable: true, value: 'lastLogin'},
				// { id: 6, text: '', value: 'options', sortable: false, class:"data-tables-header-field" }
				{ id: 6, text: '', sortable: true, value: 'status', visibleByDefault: true, },
				{ id: 7, text: '', sortable: true, value: 'lastLogin', visibleByDefault: true, },
				{ id: 8, text: '', sortable: true, value: 'email', visibleByDefault: true, },
				{ id: 9, text: '', sortable: true, value: 'emailStatus', visibleByDefault: true, },
				{ id: 10, text: '', value: 'options', sortable: false, class:"data-tables-header-field", visibleByDefault: true }
			],
			dataTablesItems:[
				/*{
					name: 'Kowalski Jan',
					birthDate: '17.10.1982',
					role: 'Administrator',
					rolesIds [{Number}, ...],
					dataAccess: 'Wszystkie rekordy',
					status: 'Aktywny',
					lastLogin: '01.07.2021',
					options:[
						[
							{ 
								icon: {String}, 
								title: "{String}", 
								action: {Function} 
							}
						],
					]
				}*/
			],
			dataTablesItemsSourceData: [
				// User Profile serialized data
			],

			dataTableHeadersSelectItems: [
				/**
				 * {
				 *      value: {Integer},
				 *      text: {String}
				 * }
				 */
				{
					value: 0,
					text: "" // Nazwisko i imię
				},
				{
					value: 1,
					text: "" // Data urodzenia
				},
				{
					value: 2,
					text: "" // Pesel
				},
				{
					value: 3,
					text: "", // Pracownik
				},
				{
					value: 4,
					text: "" // Rola
				},
				{
					value: 5,
					text: "" // Dostęp do danych
				},
				{
					value: 6,
					text: "" // Status
				},
				{
					value: 7,
					text: "" // Ostatnio zalogowany
				},
				{
					value: 8,
					text: "", // Email
				},
				{
					value: 9,
					text: "", // Email status
				}
			],
			modifyDataTablesColumnsDialog: false,
			dataTablesHeadersRendered: [],
			dataTableHeadersSelected: [],
			fab: false,
			statuses: [
				{
					text: "",
					value: -1
				},
				{
					text: "",
					value: 1
				},
				{
					text: "",
					value: 0
				},
				{
					text: "",
					value: 2
				},
			],
			selectedStatus: null,

			roles: [],
			roleSelected: null,

			dataSharingRoles: [],
			dataSharingRoleSelected: null,

			screenWidth:null,

			options: {},
			itemsPerPageOptions: [5, 10, 15, 30, 50, 100, 500],
			serverSideTotalItemsNumber: 0,

			preventUpdate: false,
			searchTimeout: null,

			// active/inactive status
			activeUsersNumber: 0,
			inactiveUsersNumber: 0,

			administratorsNumber: 0,
			usersNumber: 0,

			snackbar: {
				visible: false,
				color: "primary",
				message: ""
			},

			userDialogUserId: null,
			userDialogUserData: {},

			userRolesData: [],
			dataAccessData: [],
			
			// user active status dialog data
			userActiveStatusChangeDialog: {
				visible: false,
				title: "",
				content: "",
				user: null,
			},

			dataTableLoading: false,
		};
	},
	beforeMount() {
		this.screenWidth = window.innerWidth;
		// Version with data access
		// this.dataTablesHeaders[0].text = this.lviews.firstAndLastName;
		// this.dataTablesHeaders[1].text = this.lviews.birthDate;
		// this.dataTablesHeaders[2].text = this.lviews.role;
		// this.dataTablesHeaders[3].text = this.lviews.dataAccess;
		// this.dataTablesHeaders[4].text = this.lviews.status;
		// this.dataTablesHeaders[5].text = this.lviews.lastLogin;
		// this.dataTableHeadersSelectItems[0].text = this.lviews.firstAndLastName;
		// this.dataTableHeadersSelectItems[1].text = this.lviews.birthDate;
		// this.dataTableHeadersSelectItems[2].text = this.lviews.role;
		// this.dataTableHeadersSelectItems[3].text = this.lviews.dataAccess;
		// this.dataTableHeadersSelectItems[4].text = this.lviews.status;
		// this.dataTableHeadersSelectItems[5].text = this.lviews.lastLogin;

		this.dataTablesHeaders[0].text = this.lviews.firstAndLastName;
		this.dataTablesHeaders[1].text = this.lviews.birthDate;
		this.dataTablesHeaders[2].text = this.lviews.pesel;
		this.dataTablesHeaders[3].text = this.lviews.worker;
		this.dataTablesHeaders[4].text = this.lviews.role;
		this.dataTablesHeaders[5].text = this.lviews.dataAccess;
		this.dataTablesHeaders[6].text = this.lviews.status;
		this.dataTablesHeaders[7].text = this.lviews.lastLogin;
		this.dataTablesHeaders[8].text = this.lviews.email;
		this.dataTablesHeaders[9].text = this.lviews.emailStatus;

		this.dataTableHeadersSelectItems[0].text = this.lviews.firstAndLastName;
		this.dataTableHeadersSelectItems[1].text = this.lviews.birthDate;
		this.dataTableHeadersSelectItems[2].text = this.lviews.pesel;
		this.dataTableHeadersSelectItems[3].text = this.lviews.worker;
		this.dataTableHeadersSelectItems[4].text = this.lviews.role;
		this.dataTableHeadersSelectItems[5].text = this.lviews.dataAccess;
		this.dataTableHeadersSelectItems[6].text = this.lviews.status;
		this.dataTableHeadersSelectItems[7].text = this.lviews.lastLogin;
		this.dataTableHeadersSelectItems[8].text = this.lviews.email;
		this.dataTableHeadersSelectItems[9].text = this.lviews.emailStatus;
	},
	mounted: async function() {
		this.$emit('set-title', this.lviews.userListTitle);
		this.$emit("set-display", "back_window");
		let userPermissions = localStorage.getItem("user_permissions");
		if ( !userPermissions.includes("fob.admin_view") ) {
			this.$emit('set-state', 'ERROR', this.lviews.youDoNotHavePriviledgesToThisView);
			return;
		}

		this.statuses[0].text = this.lviews.all;
		this.statuses[1].text = this.lviews.active;
		this.statuses[2].text = this.lviews.inactive;
		this.statuses[3].text = this.lviews.blocked;

		this.preventUpdate = true;

		this.dataTablesHeadersRendered = [];
		this.dataTableHeadersSelected = [];
		for(var i = 0; i < this.dataTablesHeaders.length; i++){
			if (this.dataTablesHeaders[i].visibleByDefault) {
				this.dataTablesHeadersRendered.push(this.dataTablesHeaders[i]);
				this.dataTableHeadersSelected.push(i);
			}
		}
		window.addEventListener('resize', ()=>{
			this.screenWidth = window.innerWidth;
		});

	},
	computed: {
		lviews: {
			get: function() {
				return this.$t('views.userListPanel');
			}
		},
		canUserAddNewUsers: {
			get: function() {
				let userPermissions = localStorage.getItem("user_permissions");
				if (!userPermissions.includes("fob.admin_add")) {
					return false;
				}
				return true;
			}
		}
	},
	watch: {
		search: async function() {
			if (!this.preventUpdate) {
				if (this.searchTimeout != null) {
					clearTimeout(this.searchTimeout);
				}
				var that = this;
				this.searchTimeout = setTimeout(async function(){
					await that.updatePageData();
				}, 500);
			}
			else {
				this.preventUpdate = false;
			}
		},
		options: {
			handler: async function() {
				if (!this.dataTableLoading) {
					await this.updatePageData();
				}
			},
			deep: true
		}
	},
	methods: {
		deleteUserCancel () {
			this.deleteUserDialog.visible = false;
			this.deleteUserDialog.id = null;
			this.deleteUserDialog.userName = null;
		},
		async deleteUserSave () {
			if (this.deleteUserDialog.id != null && this.deleteUserDialog.id != undefined) {
				this.deleteUserDialog.loading = true;

				try {
					let response = await axios({
						url: fob_config.api_url + "/api/v2/admin/user/" + this.deleteUserDialog.id,
						method: "DELETE",
						headers: {
							'Authorization': 'Bearer ' + localStorage.getItem('jwt')
						}
					});
					
					this.snackbar.color = "success";
					this.snackbar.message = this.lviews.deleteUserSuccess;
					this.snackbar.visible = true;

					// update list
					if (!this.dataTableLoading) {
						await this.updatePageData();
					}

				} catch (error) {
					this.$emit("set-state", "default");
					if (error.isAxiosError) {
						switch(error.response.status) {
							case 404:
								this.snackbar.color = "error";
								this.snackbar.message = this.lviews.couldNotFindUserToDelete;
								this.snackbar.visible = true;
								break;
						}
					}
				}

				this.deleteUserDialog.visible = false;
				this.deleteUserDialog.id = null;
				this.deleteUserDialog.userName = null;
				this.deleteUserDialog.loading = false;
			}
		},
		/**
		 * Opens dedicated to create new / edit user dialog.
		 */
		createNewUser: function() {
			this.userDialogUserId = null;
			this.userDialogUserData = {};
			this.$nextTick(() => { this.$refs.userDialog.openDialog(); });
		},

		/**
		 * Opens dedicated to user edit dialog.
		 * @param user User data object to edit. Its structure is presented below
		 */
		editUserRow: function(user) {
			let originalUserData = this.dataTablesItemsSourceData.find(x => { return x.application_user.id == user.userId; });
			this.userDialogUserId = user.userId;
			this.userDialogUserData = { ...user, ...originalUserData };
			this.$nextTick(() => { this.$refs.userDialog.openDialog(); });
		},

		/**
		 * Sends API request to server (in order to send activation account mail to user)
		 * @param user - adapted user data. Its structure is presented below
		 * 
		 * {
		 *      username: {String},
		 *      name: {String},
		 *      birthDate: {String},
		 *      role: {String},
		 *      dataAccess: {String},
		 *      status: {Boolean},
		 *      statusText: {String},
		 *      lastLogin: {String},
		 *      options: {Array of objects}
		 * }
		 */
		sendActivationMail: async function(user) {
			this.$emit('set-state', "LOADING_OVERLAYER", this.lviews.sendingEmail);
			let response = null;
			
			try {
				response = await axios({
					url: fob_config.api_url + "/api/v2/admin/user-activation-mail/" + user.userId,
					method: "POST",
					headers: {
						'Authorization': 'Bearer ' + localStorage.getItem('jwt')
					}
				});
			} catch (error) {
				this.$emit("set-state", "default");
				if (error.isAxiosError) {
					switch(error.response.status) {
						case 400:
							this.snackbar.color = "error";
							this.snackbar.message = this.lviews.invalidUserIdentifier;
							this.snackbar.visible = true;
							break;
						case 423:
							this.snackbar.color = "error";
							this.snackbar.message = this.lviews.couldNotSendMailServerBusy;
							this.snackbar.visible = true;
							break;
						case 500:
							this.snackbar.color = "error";
							this.snackbar.message = this.lviews.couldNotSendMail;
							this.snackbar.visible = true;
							break;
					}
				}
			}

			if (response != null) {
				this.$emit("set-state", 'default');
				this.snackbar.color = "success";
				this.snackbar.message = this.lviews.mailSent;
				this.snackbar.visible = true;
			}
		},

		/**
		 * Sends request with new user active status.
		 */
		changeUserActiveStatus: async function() {
			let requestData = {
				auth_user: {
					is_active: this.userActiveStatusChangeDialog.user.blocked ? 1 : 0
				}
			};

			let response = null;
			if (this.userActiveStatusChangeDialog.user.blocked) {
				this.$emit("set-state",  "LOADING_OVERLAYER", this.lviews.unblockingAccountInProgress);
			}
			else {
				this.$emit("set-state",  "LOADING_OVERLAYER", this.lviews.blockingAccountInProgress);
			}

			try {
				response = await axios({
					url: fob_config.api_url + "/api/v2/admin/user/" + this.userActiveStatusChangeDialog.user.userId,
					method: "PATCH",
					headers: {
						'Authorization': 'Bearer ' + localStorage.getItem('jwt'),
						'Content-Type': 'application/json'
					},
					data: requestData
				});
			} catch (error) {
				response = null;
				this.$emit('set-state', 'default');

				switch(error.response.status) {
					case 400:
						this.$emit('display-error', this.lviews.errorOccurred, this.lviews.invalidRequestData);
						break;
					case 401:
						this.$router.push("/login?next=/user-list");
						break;
					case 403:
						this.$router.push("/login?next=/user-list");
						break;
					case 404:
						if (requestData.auth_user.user_active) {
							this.$emit('display-error', this.lviews.errorOccurred, this.lviews.couldNotFindUserToUnblock);
						}
						else {
							this.$emit('display-error', this.lviews.errorOccurred, this.lviews.couldNotFindUserToBlock);
						}
						break;
					case 429:
						this.$emit('display-error', this.lviews.waitAMinute, this.lviews.tooManyRequestsSent);
						break;
					default:
						this.$emit('display-error', this.lviews.errorOccurred, this.lviews.errorOccurredThatsAllWeKnow);
						break;
				}
			}
			this.$emit('set-state', 'default');

			if (response != null) {
				// handle response
				let index = this.dataTablesItems.findIndex(x => { return x.userId == this.userActiveStatusChangeDialog.user.userId; });

				let statusText = "";
				if (!response.data.application_user.is_active) {
					statusText = this.lviews.blocked;
				}
				else {
					statusText = response.data.application_user.account_activated ? this.lviews.activeStatus : this.lviews.inactiveStatus;
				}

				this.dataTablesItems[index].status = response.data.application_user.account_activated;
				this.dataTablesItems[index].blocked = !response.data.application_user.is_active;
				this.dataTablesItems[index].statusText = statusText;

				if (this.dataTablesItems[index].blocked) {
					this.dataTablesItems[index].options[0][2].title = this.lviews.unblock;
				}
				else {
					this.dataTablesItems[index].options[0][2].title = this.lviews.block; 
				}
				this.userActiveStatusChangeDialog.visible = false;
			}
		},

		/**
		 * Opens modify user block status dialog
		 */
		openModifyBlockedUserStatusDialog: async function(user) {
			if (user.blocked) {
				this.userActiveStatusChangeDialog.title = this.lviews.unblockUser;
				this.userActiveStatusChangeDialog.content = this.lviews.areYouSureYouWantToUnblockUser.replace("{fullname}", user.name).replace("{username}", user.username);
			}
			else {
				this.userActiveStatusChangeDialog.title = this.lviews.blockUser;
				this.userActiveStatusChangeDialog.content = this.lviews.areYouSureYouWantToBlockUser.replace("{fullname}", user.name).replace("{username}", user.username);
			}

			this.userActiveStatusChangeDialog.user = user;
			this.userActiveStatusChangeDialog.visible = true;
		},

		/** 
		 * Determine user permissions and assign options.
		 * @param isUserActivated - {Boolean} is this user account activated.
		 * @param userBlocked {Boolean} Information whether specific user is blocked or not.
		 * */ 
		checkUserPermissions: function(isUserActivated, userBlocked, userDeletable) {
			let optionsToAssign = [];
			let tmpOptions = [];
			let usersPermissions = localStorage.getItem("user_permissions");
			if (usersPermissions.includes("fob.admin_change")) {
				tmpOptions.push(
					{ icon: "mdi-border-color", title: this.lviews.editData, action: this.editUserRow, disabled: false },
					{ icon: "mdi-email-outline", title: this.lviews.sendActivationMail, action: this.sendActivationMail, disabled: isUserActivated},
					// { icon: "mdi-lock", title: "{L Zresetuj hasło }", action: "" , disabled: false},
				);
				// More options
				if (userBlocked){
					tmpOptions.push(
						{ icon: "mdi-replay", title: this.lviews.unblock, action: this.openModifyBlockedUserStatusDialog, disabled: false},
					)
				}
				else{
					tmpOptions.push(
						{ icon: "mdi-replay", title: this.lviews.block, action: this.openModifyBlockedUserStatusDialog, disabled: false},
					);
				}    
			}
			if (usersPermissions.includes('fob.admin_delete')) {
				tmpOptions.push(
					{ icon: "mdi-delete", title: this.lviews.delete, action: this.openDeleteUserDialog, disabled: (userDeletable) ? false : true},
				);                
			}
			optionsToAssign.push(
				tmpOptions
			)
			return optionsToAssign;
		},
		// fetches users data from server
		updatePageData: async function() {
			let userListData = await this.fetchUserListData();
			if (userListData) {
				this.adaptUserListData(userListData);
				this.dataTableLoading = false;
			}
		},

		openDeleteUserDialog (user) {
			this.deleteUserDialog.visible = true;
			this.deleteUserDialog.id = user.userId;
			this.deleteUserDialog.userName = user.name;
		},

		/**
		 * Updates page number
		 * @param newPageNumber - {Number} new page number
		 */
		updatePageNumber: async function(newPageNumber) {
			this.options.page = newPageNumber;
			if (!this.dataTableLoading) {
				await this.updatePageData();
			}
		},

		/**
		 * Updates items per page number
		 * @param newPageSize - {Number} new items per page number
		 */
		updatePageSize: async function(newPageSize) {
			this.options.itemsPerPage = newPageSize;
			if (!this.dataTableLoading) {
				await this.updatePageData();
			}
		},

		openModifyDataTableColumnsDialog: function() {
			this.dataTableHeadersSelected = [];
			for(var i = 0; i < this.dataTablesHeadersRendered.length; i++) {
				this.dataTableHeadersSelected.push(this.dataTablesHeadersRendered[i].id);
			}

			this.modifyDataTablesColumnsDialog = true;
		},
		updateDataTablesColumns: function(){
			this.dataTablesHeadersRendered = [];
			this.dataTableHeadersSelected.sort((a, b) => { return a < b ? -1 : 1; });
			for(var i = 0; i < this.dataTableHeadersSelected.length; i++){
				this.dataTablesHeadersRendered.push(
					this.dataTablesHeaders[ this.dataTableHeadersSelected[i] ]
				);
			}
		},

		/**
		 * Chooses color for user's status.
		 * @param status {Boolean} Boolean flag whether user account is activated or not.
		 * @param blocked {Boolean} Boolean flag whether user account is blocked (is_active flag set to 0) or not.
		 * @returns String with color to render.
		 */
		getColor (status, blocked) {
			if (blocked) {
				return 'grey';
			}
			if (status) return 'success';
			else return 'red';
		},

		getEmailColor(status) {
			// 0 Unsent
			// 1 Sent
			// 2 Error
			// default 0
			if (status == 2) {
				return 'red';
			} else if (status == 1) {
				return 'success';
			} else {
				return 'grey';
			}
		},

		fetchUserListData: async function() {
			this.dataTableLoading = true;
			let apiLink = fob_config.api_url + "/api/v2/admin/user/?page=" + this.options.page + "&per_page=" + this.options.itemsPerPage;
			
			// add search value to query params
			if (this.search != null && this.search != "") {
				apiLink += ("&search=" + this.search);
			}

			// add selected status to query params
			if (this.selectedStatus != null) {
				apiLink += ("&status=" + this.selectedStatus.toString());
			}

			// handling sorting datatable columns
			if (this.options.sortBy != null && this.options.sortBy.length > 0) {
				switch(this.options.sortBy[0]) {
					case "birthDate":
						apiLink += "&sort_by=1";
						break;
					case "status":
						apiLink += "&sort_by=6";
						break;
					case "lastLogin":
						apiLink += "&sort_by=7";
						break;
					case "email":
						apiLink += "&sort_by=8";
						break;
					case "emailStatus":
						apiLink += "&sort_by=9";
						break;
				}

				if (this.options.sortDesc[0]) {
					apiLink += "&sort_desc=1";
				}
				else {
					apiLink += "&sort_desc=0";
				}
			}

			// handling role select
			if (this.roleSelected != null && this.roleSelected != -1) {
				apiLink += ("&role=" + this.roleSelected.toString());
			}

			// handling data sharing role select
			if (this.dataSharingRoleSelected != null && this.dataSharingRoleSelected != -1) {
				apiLink += ("&ds_role=" + this.dataSharingRoleSelected.toString());
			}

			let responsePromise = axios({
				method: "GET",
				url: apiLink,
				headers: {
					'Authorization': 'Bearer ' + localStorage.getItem('jwt')
				}
			});
			let response = null;
			try {
				response = await responsePromise;
			} catch (error) {
				this.dataTableLoading = false;
				// handle errors
				switch(error.response.status) {
					case 401:
						this.$router.push("/login?next=/user-list");
						break;
					case 403:
						this.$emit('set-state', 'ERROR', this.lviews.youDoNotHavePriviledgesToThisView);
						break;
					case 429:
						this.$emit("display-error", this.lviews.waitAMinute, this.lviews.tooManyRequestsSent);
						break;
					default:
						this.$emit("set-state", 'ERROR', this.lviews.errorOccurredThatsAllWeKnow);
						break;
				}
				return null;
			}
			return response.data;
		},

		adaptUserListData: async function(userListData) {
			this.activeUsersNumber = userListData.active_users_number;
			this.inactiveUsersNumber = userListData.inactive_users_number;

			this.usersNumber = userListData.users_overall;
			// this.administratorsNumber = userListData.administrators_overall;
			this.dataTablesItems = [];

			this.serverSideTotalItemsNumber = userListData.total;

			let rolesData = await this.fetchUserRoles();

			if (rolesData != null) {
				// assigning user roles select items
				this.roles = [];
				this.roles.push({
					id: -1,
					name: this.lviews.all
				});

				// adding filtering for simple roles (module groups)
				for (let i = 0; i < rolesData.roles.length; i++) {
					this.roles.push({
						id: rolesData.roles[i].id,
						name: rolesData.roles[i].name
					});
				}

				this.dataSharingRoles = [];
				this.dataSharingRoles.push({
					id: -1,
					name: this.lviews.all
				});
				// assigning filtering items for data sharing roles
				for (let i = 0; i < rolesData.data_sharing_roles.length; i++) {
					this.dataSharingRoles.push({
						id: rolesData.data_sharing_roles[i].id,
						name: rolesData.data_sharing_roles[i].name
					});
				}
				this.dataAccessData = rolesData.data_sharing_roles;
				this.userRolesData = rolesData.roles;
			}
			else { // could not fetch roles data => error will be displayed ()
				return;
			}

			this.dataTablesItemsSourceData = [];
			userListData.items.user_profile.forEach(item => {
				this.dataTablesItemsSourceData.push(item);

				let optionsToAssign = this.checkUserPermissions(item.application_user.account_activated, !item.application_user.is_active, item.application_user.deletable);
				let newRoles = [], newRolesIds = [];
				let userDataSharingRoles = [], userDataSharingRolesIds = [];
				for(var i = 0; i < item.application_user.groups.length; i++){
					// getting user roles 
					for(var j = 0; j < rolesData.roles.length; j++){
						if (item.application_user.groups[i] == rolesData.roles[j].id) {
							newRoles.push(rolesData.roles[j].name);
							newRolesIds.push(rolesData.roles[j].id);
						}
					}

					// getting user data sharing roles
					for (let j = 0; j < rolesData.data_sharing_roles.length; j++) {
						if (item.application_user.groups[i] == rolesData.data_sharing_roles[j].id) {
							userDataSharingRoles.push(rolesData.data_sharing_roles[j].name);
							userDataSharingRolesIds.push(rolesData.data_sharing_roles[j].id);
						}
					}
				}
				if (newRoles.length > 0) {
					newRoles = newRoles.join(", ");
				} else {
					newRoles = this.lviews.lackOfData;
				}

				if (userDataSharingRoles.length > 0) {
					userDataSharingRoles = userDataSharingRoles.join(", ");
				} else {
					userDataSharingRoles = this.lviews.lackOfData;
				}

				var active = '';
				if (item.application_user.account_activated){
					active = true;
				}
				else {
					active = false;
				}
				let statusText = "";
				if (!item.application_user.is_active) {
					statusText = this.lviews.blocked;
				}
				else {
					statusText = active ? this.lviews.activeStatus : this.lviews.inactiveStatus
				}

				let emailStatusText;
				let emailStatus = item.application_user.sent_email;

				if (emailStatus == 2) {
					emailStatusText = this.lviews.emailStatusError;    
				} else if (emailStatus == 1) {
					emailStatusText = this.lviews.emailStatusSent;
				} else {
					emailStatusText = this.lviews.emailStatusNotSent;    
				}

				// get error email message
				let emailStatusErrorText = item.application_user.email_error;

				if (emailStatusErrorText != null && emailStatus == 2) {
					if (emailStatusErrorText.toLowerCase().trim() == 'smtp server refused connection') {
						emailStatusErrorText = this.lviews.emailStatusErrorConnection;    
					} else if (emailStatusErrorText.toLowerCase().trim() == 'smtp server refused message') {
						emailStatusErrorText = this.lviews.emailStatusErrorMessage;    
					} else if (emailStatusErrorText.toLowerCase().trim() == 'smtp server refused recipients') {
						emailStatusErrorText = this.lviews.emailStatusErrorRecipients;     
					} else if (emailStatusErrorText.toLowerCase().trim() == 'smtp server refused sender') {
						emailStatusErrorText = this.lviews.emailStatusErrorSender;      
					} else if (emailStatusErrorText.toLowerCase().trim() == 'smtp server returned invalid response') {
						emailStatusErrorText = this.lviews.emailStatusErrorInvlidResponse;   
					} else if (emailStatusErrorText.toLowerCase().trim() == 'smtp server closed connection') {
						emailStatusErrorText = this.lviews.emailStatusErrorCloseConn;     
					} else if (emailStatusErrorText.toLowerCase().trim() == 'smtp server returned an unrecognized exception') {
						emailStatusErrorText = this.lviews.emailStatusErrorException;   
					} else {
						emailStatusErrorText = this.lviews.emailStatusErrorDefault;
					}
				} else {
					emailStatusErrorText = null;    
				}

				this.dataTablesItems.push(
					{
						userId: item.application_user.id,
						username: item.application_user.username,
						name: item.last_name + " " + item.first_name,
						birthDate: item.birth_date,
						role: newRoles,
						rolesIds: newRolesIds,
						dataAccess: userDataSharingRoles,
						dataAccessIds: userDataSharingRolesIds,
						status: active,
						statusText: statusText,
						lastLogin: item.application_user.last_login ? item.application_user.last_login : '-',
						emailStatus: emailStatus,
						emailStatusText: emailStatusText,
						emailError: emailStatusErrorText,
						deletable: item.application_user.deletable,
						blocked: !item.application_user.is_active,
						options: optionsToAssign,
						pesel: item.pesel ? item.pesel : this.lviews.lackOfData,
						worker: item.worker,
						email: item.personal_email,
					}
				);
			});
		},

		fetchUserRoles: async function(){
			let apiLink = fob_config.api_url + "/api/v2/admin/user-roles";
			let responsePromise = axios({
				method: "GET",
				url: apiLink,
				headers: {
					'Authorization': 'Bearer ' + localStorage.getItem('jwt')
				}
			});
			let response = null;
			try {
				response = await responsePromise;
			} catch (error) {
				this.$emit('set-state', 'ERROR', this.lviews.youDoNotHavePriviledgesToThisView);
				// handle errors
				// #TODO Handle 403 error (Forbiden)
				return null;
			}
			return response.data;
		},

		openAddUser(){
			this.$router.push('/add-user');
		},

		// prop methods for user-dialog

		/**
		 * Shows loading overlayer when sending request
		 */
		showOverlayer: function(message) {
			this.$emit("set-state", "LOADING_OVERLAYER", message);
		},

		/**
		 * Hides loading overlayer after receiving response
		 */
		hideOverlayer: function() {
			this.$emit("set-state", "DEFAULT");
		}
	}
}
</script>
<style>
	.worker-document-menu-option {
		background-color: white;
	}
	.worker-document-menu-option:hover {
		background-color: #e4e4e4;
	}

	.user-list-search-field {
		width: 260px;
		display: inline-block !important;
		padding-left: 10px;
		padding-right: 10px;
	}
	.user-list-status-select-wrapper {
		width: 700px;
	}
	.user-list-status-select-field {
		width: 200px;
		display: inline-block !important;
		padding-left: 10px;
		padding-right: 10px;
	}

	@media only screen and (max-width: 530px) {
		.user-list-search-field {
			width: 100%;
		}
		.user-list-status-select-wrapper {
			width: 100%;
		}
		.user-list-status-select-field {
			width: calc(100% - 50px);
		}
	}
	.users-summary-label {
		font-size: 14px;
		color: gray;
		padding-left: 14px;
		padding-bottom: 15px;
		width: 100%;
	}
</style>
<style scoped>
	.document-name-cut-when-too-long {
		max-width: 170px;
		overflow: hidden; 
		white-space: nowrap; 
		text-overflow: ellipsis;
	}
	.data-tables-header-field {
	background-color: rgb(83,241,107)!important;
	color: rgb(62, 51, 51) !important;
	}
	.add-new-users-button {
		position: fixed !important;
		bottom: 25px !important;
		right: 25px !important;
	}
</style>