<!--
SharedMasters 2019 - Fast OnBoarding Application

__copyright__ = "Copyright (C) 2019, Shared Masters sp. z o.o."
__license__ = "Fast OnBoarding can not be copied and/or distributed without the express permission of Shared Masters sp. z o.o."
__version__ = "0.0.1-pre-beta"
__author__ = "Jan Przychodniak, .."
__maintainer__ = "Jan Przychodniak"
__email__ = "jan@sharedmasters.com"
__status__ = "DEV"


USAGE
-------
<resign-dialog
  ref='yourReference'

  ...
></resign-dialog>
$refs.youReference.openDialog();

props:
  - workers {Array}, determines workers to modify in the database
  {
	id {Number},
	workerId {Number},
  	records {Array}
    {
      boardingId: {Number},
      projectId: {Number}
    }
  }
  - date {String}, date to display by default
  - assignedProjects {Array}, common projects of workers
  - markedProjects {Array}, marked projects of workers to change

events:
  - request-sending-start
  - request-sending-end
  - request-sending-success
  - error

errors:
  structure: {
	errorCodeShort,
	errorCodeLong,
	title,
	message,
	details
  }

  errorsId: RSGND

-->

<template>
	<v-dialog persistent v-model="visible" max-width="290">
		<v-card dense>
			<div class="ResignDialog">
				<v-card-title style="text-align:center; padding-bottom:0">
					<span class="headline">{{ lview.title }}</span>
				</v-card-title>

				<v-card-text>
					<v-card elevation="1" align-center class="projects-table">
						<v-card-title class="projects-table-title" style="position:relative">
							{{ lview.chooseProjects }}
						</v-card-title>

						<v-list
							style="width:100%; max-height: 110px; overflow-y: auto; 	overflow-x: hidden"
							dense
						>
						<v-list-item style="padding:0 5px" v-for="project in tableProjects" :key="project.id">
							<v-flex xs12>
								<v-checkbox
									v-model="checkboxes[project.id.toString()]"
									color="rgba(4, 202, 90, 1)"
									:label="project.name"
									style="margin-top: 0; margin-bottom: 0;"
								></v-checkbox>
							</v-flex>
						</v-list-item>
						</v-list>
					</v-card>

					<v-layout align-center style="margin-top:10px;">
						<v-dialog ref="offBoardingDateDialog"
								v-model="datePickerOffBoarding.visible"
								:return-value.sync="datePickerOffBoarding.date"
								persistent
								width="290px">
							<template v-slot:activator="{ on }">
								<div 
									style="width:100%"
									@click="
										if(!datePickerOffBoardingEnabled){
											datePickerOffBoardingEnabled = true;
											datePickerOffBoarding.visible = true;
										}"
								>
									<v-text-field hide-details
												v-model="datePickerOffBoarding.date"
												:label="lview.offBoardingDate"
												readonly
												v-on="on"
												append-icon="event"
												style="width:100%"
												:disabled="!datePickerOffBoardingEnabled">
									</v-text-field>
								</div>
							</template>

							<v-date-picker color="rgba(4, 202, 90, 1)" v-model="datePickerOffBoarding.date" scrollable>
								<v-spacer></v-spacer>
								<v-btn text
									color="rgba(4, 202, 90, 1)"
									@click="datePickerOffBoarding.visible = false">
									{{ lbuttons.cancel }}
								</v-btn>
								<v-btn text
									color="rgba(4, 202, 90, 1)"
									@click="$refs.offBoardingDateDialog.save(datePickerOffBoarding.date); datePickerOffBoarding.visible = false">
									{{ lbuttons.confirm }}
								</v-btn>
							</v-date-picker>
						</v-dialog>
					</v-layout>

					<v-layout align-center style="margin-top:10px;">
						<v-dialog ref="contractEndDateDialog"
								v-model="datePickerContractEndDate.visible"
								:return-value.sync="datePickerContractEndDate.date"
								persistent
								width="290px">
							<template v-slot:activator="{ on }">
								<div
									style="width:100%"
								 	@click="
										if(!datePickerContractEndDateEnabled){
											datePickerContractEndDateEnabled = true;
											datePickerContractEndDate.visible = true;
										}"
								>
									<v-text-field hide-details
												v-model="datePickerContractEndDate.date"
												:label="lview.contractEndDate"
												readonly
												v-on="on"
												style="width:100%"
												append-icon="event"
												:disabled="!datePickerContractEndDateEnabled">
									</v-text-field>
								</div>
							</template>

							<v-date-picker color="rgba(4, 202, 90, 1)" v-model="datePickerContractEndDate.date" scrollable>
								<v-spacer></v-spacer>
								<v-btn text
									color="rgba(4, 202, 90, 1)"
									@click="datePickerContractEndDate.visible = false">
									{{ lbuttons.cancel }}
								</v-btn>
								<v-btn text
									color="rgba(4, 202, 90, 1)"
									@click="$refs.contractEndDateDialog.save(datePickerContractEndDate.date); datePickerContractEndDate.visible = false">
									{{ lbuttons.confirm }}
								</v-btn>
							</v-date-picker>
						</v-dialog>
					</v-layout>

					<v-layout align-center style="margin-top:10px;">
						<v-dialog ref="projectEndDateDialog"
								v-model="datePickerProjectEndDate.visible"
								:return-value.sync="datePickerProjectEndDate.date"
								persistent
								width="290px">
							<template v-slot:activator="{ on }">
								<div 
									style="width:100%"
									@click="
										if(!datePickerProjectEndDateEnabled){
											datePickerProjectEndDateEnabled = true;
											datePickerProjectEndDate.visible = true;
										}"
								>
									<v-text-field hide-details
												v-model="datePickerProjectEndDate.date"
												:label="lview.projectEndDate"
												readonly
												v-on="on"
												append-icon="event"
												:disabled="!datePickerProjectEndDateEnabled">
									</v-text-field>
								</div>
							</template>

							<v-date-picker color="rgba(4, 202, 90, 1)" v-model="datePickerProjectEndDate.date" scrollable>
								<v-spacer></v-spacer>
								<v-btn text
									color="rgba(4, 202, 90, 1)"
									@click="datePickerProjectEndDate.visible = false">
									{{ lbuttons.cancel }}
								</v-btn>
								<v-btn text
									color="rgba(4, 202, 90, 1)"
									@click="$refs.projectEndDateDialog.save(datePickerProjectEndDate.date); datePickerProjectEndDate.visible = false">
									{{ lbuttons.confirm }}
								</v-btn>
							</v-date-picker>
						</v-dialog>
					</v-layout>

					<div v-if="workers.length == 1">
						<!-- Ratings -->
						<nullable-rating
							style="margin-top: 10px;"
							v-model="rating"
						></nullable-rating>

						<v-textarea
							v-model="ratingComment"
							:auto-grow="true"
							:rows="1"
							:clearable="true"
							:label="lview.ratingComment"
						></v-textarea>
					</div>

					<!--
						<v-layout align-center>
							
							<v-icon>event</v-icon>
						</v-layout>

						<v-layout align-center>
							<v-checkbox v-model="datePickerEnabled"
										hide-details
										color="rgba(4, 202, 90, 1)">
							</v-checkbox>

							<v-dialog ref="dialogResign"
									v-model="datePicker.visible"
									:return-value.sync="datePicker.date"
									persistent
									width="290px">
								<template v-slot:activator="{ on }">
									<div @click="
										if(!datePickerEnabled){
											datePickerEnabled = true;
											datePicker.visible = true;
										}"
									>
										<v-text-field hide-details
													v-model="datePicker.date"
													:label="lview.resignDate"
													readonly
													v-on="on"
													:disabled="!datePickerEnabled">
										</v-text-field>
									</div>
								</template>

								<v-date-picker v-model="datePicker.date" scrollable>
									<v-spacer></v-spacer>
									<v-btn text
										color="rgba(4, 202, 90, 1)"
										@click="datePicker.visible = false">
										{{ lbuttons.cancel }}
									</v-btn>
									<v-btn text
										color="rgba(4, 202, 90, 1)"
										@click="$refs.dialogResign.save(datePicker.date); datePicker.visible = false">
										{{ lbuttons.confirm }}
									</v-btn>
								</v-date-picker>
							</v-dialog>
							<v-icon>event</v-icon>
						</v-layout>
					-->
					<!-- TEMPORARY REMOVAL OF NOT YET COMPLETE FUNCTIONALITY - CODE STABILIZATION
						<v-select v-model="pickedResignReason"
								:items="selectForResignReasons"
								:label="lview.reason"
								style="margin-top: 10px;"></v-select>
					--> 
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="rgba(4, 202, 90, 1)" text @click="closeDialog()">{{ lbuttons.cancel }}</v-btn>
					<v-btn color="rgba(4, 202, 90, 1)" text @click="onAcceptDialog()">{{ lbuttons.confirm }}</v-btn>
				</v-card-actions>
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
		import axios from 'axios'

		export default {
			name: 'resignDialog',
			mounted: function () {

			},
			data() {
				return {
					visible: false,
					tableProjects: [],
					checkboxes: {},

					selectForResignReasons: this.$t("views.commons.resignDialog.selectForResignReasons"),
					pickedResignReason: null,

					selectedProjects: [],
					sharedProjects: [],

					datePickerOffBoardingEnabled: true,
					datePickerOffBoarding: {
						date: new Date().toJSON().slice(0, 10).replace('/-/g', '/'), //set default date as today's in format : YY-MM-DD
						visible: false,
					},

					datePickerContractEndDateEnabled: true,
					datePickerContractEndDate: {
						date: new Date().toJSON().slice(0, 10).replace('/-/g', '/'), //set default date as today's in format : YY-MM-DD
						visible: false,
					},

					datePickerProjectEndDateEnabled: true,
					datePickerProjectEndDate: {
						date: new Date().toJSON().slice(0, 10).replace('/-/g', '/'), //set default date as today's in format : YY-MM-DD
						visible: false,
					},

					rating: null,
					ratingComment: null
				}
			},
			computed: {
				lview: { get: function () { return this.$t("views.commons.resignDialog"); } },
				lbuttons: { get: function () { return this.$t("commons.buttons"); } },
				lmessages: { get: function () { return this.$t("commons.messages"); } },
				lerrors: { get: function() { return this.$t('errors'); } },
			},
			props: {
				workers: {
					type: Array,
					required: true
				},

				defaultDate: {
					type: String,
					default: new Date()
						.toJSON()
						.slice(0, 10)
						.replace("/-/g", "/")
				},

				date: {
					type: String,
					default: null
				},

				assignedProjects:{
					type: Array,
					required: true
				},

				markedProjectsIds:{
					type: Array,
					default: () => []
				}
			},
			methods: {
				openDialog() {
					if (this.date == null){
						this.datePickerOffBoarding.date = this.defaultDate;
						this.datePickerContractEndDate.date = this.defaultDate;
						this.datePickerContractEndDate.date = this.defaultDate;
					}
					else {
						this.datePickerOffBoarding.date = this.date;
						this.datePickerContractEndDate.date = this.date;
						this.datePickerProjectEndDate.date = this.date;
					}

					this.setupCheckboxes();
					this.tableProjects.splice(0, this.tableProjects.length);
					for (var i = 0; i < this.assignedProjects.length; i++) {
						this.tableProjects.push({
						id: this.assignedProjects[i].id,
						name: this.assignedProjects[i].name,
						sortName:
							this.assignedProjects[i].id == -1
							? "#"
							: this.assignedProjects[i].name
						});

						this.tableProjects.sort((a, b) => {
						if (a.sortName < b.sortName) return -1;
						else if (a.sortName == b.sortName) return 0;
						else return 1;
						});
					}

					this.visible = true;

					this.sharedProjects = this.assignedProjects;
					this.selectedProjects = this.markedProjects;

					var that = this;
					setTimeout(function() {
						if(that.workers.length == 1){
							//console.log(that.workers[0]);
							that.rating = that.workers[0].rating;
							that.ratingComment = that.workers[0].ratingComment;
						}
					}, 1);
				},

				closeDialog() {
					this.visible = false;
				},

				onAcceptDialog: async function () {
					var errorOccured = false;
					var workers = this.workers;

					if (workers.length == 0) {
						return;
					}

					this.$emit('request-sending-start');

					var errorOccured = false;
					var projectIds = [];
					var worker;

					for (var key in this.checkboxes) {
						if (this.checkboxes[key]) {
							projectIds.push(parseInt(key));
						}
					}

					// Get boardings to update
					var boardings = this.filterBoardings(projectIds);

					var packages = [];
					var userProfilePackage = null; // Rating update is valid only for a single wokrer update
					var errors = [];
					var modified = [];
					var modifiedUserProfile = null;

					var projectAssignmentsIdsList = [];
					for(var i = 0; i < boardings.length; i++) {
						projectAssignmentsIdsList.push(boardings[i].boarding.projectAssignmentId);
					}

					var response = null;
					try {
						response = await axios({
							method: "PATCH",
							url: localStorage.getItem("current_env") + "/api/v2/boardings/projects-assignment/",
							headers: {
								"Content-Type": "application/json",
								Authorization: "Bearer " + localStorage.getItem("jwt")
							},
							data: {
								projects_assignment_id: projectAssignmentsIdsList,
								boarding: {
									off_boarding_date: this.datePickerOffBoarding.date,
									archived: 1
								},
								project_assignment: {
									contract_end_date: this.datePickerContractEndDate.date,
									finish_date: this.datePickerProjectEndDate.date,
								}
							}
						});
					} catch (error) {
						errorOccured = true;
						this.$emit('request-sending-end');
						if (error.isAxiosError) {
							if (error.response.data != null && error.response.data != undefined) {
								if (error.response.data.boarding != null && error.response.data.boarding != undefined) {
									if (error.response.data.boarding.off_boarding_date != null && error.response.data.boarding.off_boarding_date != undefined) {
										this.$emit('error', {
											title: this.lerrors.boarding.off_boarding_date.header,
											message: this.lerrors.boarding.off_boarding_date.string,
											errorCodeShort: this.lerrors.boarding.off_boarding_date.code,
											errorCodeLong: this.lerrors.boarding.off_boarding_date.code,
											details: String(error)
										});
									}
								}
								if (error.response.data.project_assignment != null && error.response.data.project_assignment != undefined) {
									if (error.response.data.project_assignment.contract_end_date != null && error.response.data.project_assignment.contract_end_date != undefined) {
										this.$emit('error', {
											title: this.lerrors.project_assignment.contract_end_date.header,
											message: this.lerrors.project_assignment.contract_end_date.string,
											errorCodeShort: this.lerrors.project_assignment.contract_end_date.code,
											errorCodeLong: this.lerrors.project_assignment.contract_end_date.code,
											details: String(error)
										});
									}

									if (error.response.data.project_assignment.finish_date != null && error.response.data.project_assignment.finish_date != undefined) {
										this.$emit('error', {
											title: this.lerrors.project_assignment.finish_date.header,
											message: this.lerrors.project_assignment.finish_date.string,
											errorCodeShort: this.lerrors.project_assignment.finish_date.code,
											errorCodeLong: this.lerrors.project_assignment.finish_date.code,
											details: String(error)
										});
									}
								}

								if (error.response.data.projects_assignment_id != null && error.response.data.projects_assignment_id != undefined) {
									this.$emit('error', {
										title: this.lerrors.projects_assignment_id.header,
										message: this.lerrors.projects_assignment_id.string,
										errorCodeShort: this.lerrors.projects_assignment_id.code,
										errorCodeLong: this.lerrors.projects_assignment_id.code,
										details: String(error)
									});									
								}
							}
							if (error.response.status == 403) {
								this.$emit('error', {
									title: this.lmessages.forbiddenOperation,
									message: this.lmessages.youDoNotHavePriviledgesToDoThatOperation,
									errorCodeShort: "CNFBRD#5",
									errorCodeLong: "REQUEST_FAILED",
									details: String(error)
								});
							}
						}
						else {
							errors.push(error);

							this.$emit('error', {
								title: this.lview.errorOccured,
								message: this.lview.errorMessage + response.data.boardings[i].id,
								errorCodeShort: this.lview.errorCodeShort,
								errorCodeLong: this.lview.errorCodeLong,
								details: String(error)
							});
						}
						return;
					}

					for(var i = 0; i < boardings.length; i++) {
						modified.push({
							worker: boardings[i].worker,
							boardingId: boardings[i].boarding.boardingId//response.data.boardings[i].id
						});
					}

					/*for(var i = 0; i < boardings.length; i++){
						packages.push({
							worker: boardings[i].worker,
							boarding: boardings[i].boarding,
							promise: axios({
								method: "PATCH",
								url: localStorage.getItem("current_env") + "/api/v2/boardings/" + boardings[i].boarding.projectAssignmentId,
								headers: {
									"Content-Type": "application/json",
									Authorization: "Bearer " + localStorage.getItem("jwt")
								},
								data: {
									boarding: {
										off_boarding_date: this.datePickerOffBoarding.date
									},
									project_assignment: {
										contract_end_date: this.datePickerContractEndDate.date,
										finish_date: this.datePickerProjectEndDate.date,
									}
								}
							})
						});
					}*/

					var rating = this.rating;
					var ratingComment = this.ratingComment;
					if(this.workers.length == 1) {
						userProfilePackage = {
							worker: this.workers[0],
							promise: axios({
								method: "PATCH",
								url: localStorage.getItem("current_env") + "/api/v2/boardings/worker/" + this.workers[0].workerId,  // user_profile.id
								headers: {
									"Content-Type": "application/json",
									Authorization: "Bearer " + localStorage.getItem("jwt")
								},
								data: {
									user_profile: {
										rating: rating != null ? Number.parseInt(rating * 2) : null,
										rating_comment: ratingComment == "" ? null : ratingComment
									}
								}
							})
						};
					}

					if(this.workers.length == 1){
						try{
							await userProfilePackage.promise;

							modifiedUserProfile = {
								worker: userProfilePackage.worker,
								rating: rating,
								ratingComment: ratingComment
							};
						} catch (error) {
							errors.push(error);
							errorOccured = true;
							this.$emit('error', {
								title: this.lview.errorOccured,
								message: "Error updating user profile",
								errorCodeShort: this.lview.errorCodeShort,
								errorCodelong: this.lview.errorCodeLong,
								details: String(error)
							});
						}
					}

					this.$emit('request-sending-end');

					if (!errorOccured) {
						this.closeDialog();
						this.$emit('request-sending-success', modified, modifiedUserProfile);
					}
				},

				/**
				 * Filters boarding for provided project ids
				 * 
				 * @param {Array} projectIds Array of integers
				 * @returns {Array} Array of boardings for provided project ids
				 */
				filterBoardings: function(projectIds) {
					var result = [];

					var boarding;
					for(var i = 0; i < this.workers.length; i++){
						for(var j = 0; j < this.workers[i].boardings.length; j++){
							boarding = this.workers[i].boardings[j];

							if(projectIds.indexOf(boarding.projectId) != -1){
								result.push({
									worker: this.workers[i],
									boarding: boarding
								});
							}
						}
					}

					return result;
				},

				setupCheckboxes() {
					this.checkboxes = {};

					for (var i = 0; i < this.assignedProjects.length; i++) {
						this.checkboxes[this.assignedProjects[i].id] = false;
					}


					//console.log(JSON.stringify(this.assignedProjects));
					for (var i = 0; i < this.markedProjectsIds.length; i++) {
						this.checkboxes[this.markedProjectsIds[i]] = true;
					}
				}
			},
		}
</script>

<style>
div.OnBoardingDialog .projects-table .v-subheader {
  padding: 0;
}

div.OnBoardingDialog .projects-table .v-list__tile {
  padding: 0;
}
</style>

<style scoped>
.projects-table {
  padding: 0;
}

.projects-table-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 5px;
}
</style>