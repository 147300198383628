<template>
	<v-layout class="import-documents-page-container">
		<!-- Attaching files view ; state: BEFORE_UPLOAD -->
		<v-layout v-if="pageState == pageStates.BEFORE_UPLOAD" class="d-flex flex-column">
			<!-- main page content -->
			<v-layout class="align-self-center align-content-center pt-5 attach-files-container d-flex flex-column" v-cloak @drop.prevent="dragFile" @dragover.prevent>
				<div class="attach-files-input-header"> {{ pageMessages.BEFORE_UPLOAD.attachmentsPanelHeader }} </div>

				<!-- attaching files input : "LOAD" button -->
				<div class="attach-files-input mt-10 d-flex flex-column justify-content-center" style="width: 100%;">
					<!-- options for processing files in zip -->
					<div class="d-flex justify-content-center align-self-center mt-5" style="flex-wrap: wrap;">
						<!-- omit files headers while processing option -->
						<v-checkbox
							@change="omitHeadersCheckboxUpdated"
							v-model="attachmentOptions.omitHeaders.value"
							:label="attachmentOptions.omitHeaders.label"
							class="attachments-process-option"
							style="padding-top: 17px;"
						></v-checkbox>
						<!-- override existing files option -->
						<v-checkbox
							@change="overrideExistingFilesCheckboxUpdated"
							v-model="attachmentOptions.overrideExistingFiles.value"
							:label="attachmentOptions.overrideExistingFiles.label"
							class="attachments-process-option"
							style="padding-top: 17px;"
						></v-checkbox>
						<!-- upload files with document type option -->
						<v-checkbox
							@change="uploadWithDocumentTypeCheckboxUpdated"
							v-model="attachmentOptions.uploadWithDocumentType.value"
							:label="attachmentOptions.uploadWithDocumentType.label"
							class="attachments-process-option"
							style="padding-top: 17px;"
						></v-checkbox>
						<!-- default type select -->
						<v-select
							v-model="defaultDocumentType"
							class="attachments-process-option-select"
							:items="documentTypesSelect"
							:label="lview.defaultDocumentType"
						></v-select>
					</div>

					<!-- proper load files -->
					<v-layout class="d-flex flex-column">
						<v-icon class="mt-10" size="70">mdi-file-upload</v-icon>

						<!-- hidden input for files -->
						<input type="file" accept=".zip,.rar,.7zip" ref="uploadFilesPackagesInput" @change="updateDataTable" style="display: none;">

						<v-btn small color="primary mt-5 align-self-center" style="width: 200px;" @click="openInput"> {{ pageMessages.BEFORE_UPLOAD.loadFiles }} </v-btn>
						<span class="mt-5"> {{ pageMessages.BEFORE_UPLOAD.filesSizeInfo }} </span>
					</v-layout>
				</div>
			</v-layout>

			<!-- information for user -->
			<div class="pt-5 pb-5">
				<strong style="color: red;"> {{ pageMessages.BEFORE_UPLOAD.userInfoPrefix }} </strong>
				{{ pageMessages.BEFORE_UPLOAD.userInfoWithoutLinkPart }} <span @click="showImportTemplateDialog = true" style="border-bottom: 1px solid blue; color: blue; cursor: pointer;">{{ pageMessages.BEFORE_UPLOAD.userInfoLink }}</span>
				<!-- <v-btn text flat to="/">{{ pageMessages.BEFORE_UPLOAD.downloadTemplate }}</v-btn> -->
			</div>
		</v-layout>

		<!-- Template tip dialog -->
		<v-dialog
			v-model="showImportTemplateDialog"
			persistent
			width="700"
			scrollable
		>
			<v-card>
				<v-card-title> 
					<span> {{ pageMessages.BEFORE_UPLOAD.importTemplateStructure }} </span> 
					<v-spacer></v-spacer>
					<a href="/static/img/worker_documents/import.zip" download style="margin-left: 10px;"> <v-icon>mdi-download</v-icon> </a>
					<v-btn @click="showImportTemplateDialog = false" text>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>

				<v-card-text style="height: auto;">
					<div style="width: 100%; height: auto;">
						<span style="font-size: 18px; border-bottom: 2px solid black;"> {{ lview.beforeUpload.excelStructure }} </span>
						<img v-bind:src="'/static/img/worker_documents/excel_sample.png'" style="width: 100%; height: auto;">
						<p> {{ lview.beforeUpload.fileNameDescription }} </p>
					</div>

					<div style="width: 100%; height: auto; margin-top: 10px;">
						<span style="font-size: 18px; border-bottom: 2px solid black;"> {{ lview.beforeUpload.sampleZipContent }} </span>
						<img v-bind:src="'/static/img/worker_documents/zip_sample.png'" style="width: 100%; height: auto;">
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>

		<v-layout v-if="pageState == pageStates.ZIP_VALIDATED" class="d-flex flex-column">
			<div class="zip-validated-page d-flex flex-column">
				<!-- after zip validation page : Header -->
				<div class="zip-validated-page-header">
					<div class="zip-validated-page-header-part col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">{{ pageMessages.ZIP_VALIDATED.uploadedZip }} <strong>{{ uploadedZipName }}</strong></div>
					<div class="zip-validated-page-header-part col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
						<div style="float: left; padding-right: 5px;">{{ pageMessages.ZIP_VALIDATED.documentsToAdd.label }}</div>
						<div style="color: green; float: left; padding-right: 5px;">{{ pageMessages.ZIP_VALIDATED.documentsToAdd.number }}</div>
						<!-- <div style="color: green; float: left;">{{ pageMessages.ZIP_VALIDATED.documentsToAdd.suffix }}</div> -->
					</div>
				</div>

				<!-- after zip validation page : Filters -->
				<div class="zip-validated-page-header">
					<div class="zip-validated-page-header-part col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-6"> {{ pageMessages.ZIP_VALIDATED.filtersHeader }} </div>
					<div class="zip-validated-page-header-part col-xl-10 col-lg-9 col-md-8 col-sm-7 col-xs-6">
						<!-- omit files headers while processing option -->
						<div style="color: green; float: left; padding-right: 5px;">
							<v-checkbox
								v-model="attachmentOptions.omitHeaders.value"
								:label="attachmentOptions.omitHeaders.label"
								class="attachments-process-option"
								disabled
								style="padding-top: 17px;"
							></v-checkbox>
						</div>
						<div style="color: green; float: left; padding-right: 5px;">
							<!-- override existing files option -->
							<v-checkbox
								v-model="attachmentOptions.overrideExistingFiles.value"
								:label="attachmentOptions.overrideExistingFiles.label"
								class="attachments-process-option"
								disabled
								style="padding-top: 17px;"
							></v-checkbox>
						</div>
						<div style="color: green; float: left; padding-right: 5px;">
							<!-- upload files with document type option -->
							<v-checkbox
								v-model="attachmentOptions.uploadWithDocumentType.value"
								:label="attachmentOptions.uploadWithDocumentType.label"
								class="attachments-process-option"
								disabled
								style="padding-top: 17px;"
							></v-checkbox>
						</div>
						<div style="color: gray; float: left; padding-right: 5px;">
							<!-- default type select -->
							<v-select
								v-model="defaultDocumentType"
								class="attachments-process-option-select"
								:items="documentTypesSelect"
								:label="lview.defaultDocumentType"
								disabled
							></v-select>
						</div>
					</div>
				</div>

				<!-- after zip validation page : Table view -->
				<v-layout class="imported-documents-table d-flex flex-column">
					<!-- Imported documents table view : sorters 
					<div class="imported-documents-table-sorters"> -->
						<!-- filter icon -->
						<!-- <v-flex xs1 style="height: 100%;">
							<v-icon style="color: green; height: 100%; margin-left: 5px; padding-top: auto; padding-bottom: auto;" @click="filterButtonClickHandler">mdi-filter-variant</v-icon>
						</v-flex>
					</div>-->
					
					<v-data-table
						dense
						:headers="validatedDocumentsDataHeaders"
						:items="renderedDocumentsData"
						item-key="name"
						class="elevation-1"
						:item-class="getDataTableRowClass"
						:search="filters.searchValue"
						:custom-filter="filterItems"
					>
						<template v-slot:top>
							<v-flex xs12 style="display: flex;">
								<v-text-field
									v-model="filters.searchValue"
									:label="pageMessages.ZIP_VALIDATED.search"
									class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-10"
								></v-text-field>
								<v-select
									:items="documentStatesSelect"
									:label="pageMessages.ZIP_VALIDATED.status"
									v-model="filters.statusOptions"
									@change="filterDocumentsByStatus"
									multiple
									class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-10"
								></v-select>
							</v-flex>
						</template>

						<template v-slot:[`item.id`]="{ item }">
							<div v-if="item.status == documentStates.ERROR" style="color: red;"> {{ item.id }} </div>
							<div v-else> {{ item.id }} </div>
						</template>

						<template v-slot:[`item.inputFilename`]="{ item }">
							<div v-if="item.status != documentStates.ERROR" style="word-wrap: break-word; max-width: 200px;" :class="item.deleted?'row-deleted':'row-to-import'"> {{ item.inputFilename }} </div>
							<div v-else style="word-wrap: break-word; max-width: 200px; color: red;"> {{ item.inputFilename }} </div>
						</template>

						<template v-slot:[`item.outputFilename`]="{ item }">
							<div v-if="item.status != documentStates.ERROR" style="word-wrap: break-word; max-width: 200px;" :class="item.deleted?'row-deleted':'row-to-import'"> {{ item.outputFilename }} </div>
							<div v-else style="word-wrap: break-word; max-width: 200px; color: red;"> {{ item.outputFilename }} </div>
						</template>

						<template v-slot:[`item.workerName`]="{ item }">
							<div v-if="item.status != documentStates.ERROR" :class="item.deleted?'row-deleted':'row-to-import'"> {{ item.workerName }} </div>
							<div v-else style="word-wrap: break-word; max-width: 200px; color: red;"> {{ item.workerName }} </div>
						</template>

						<template v-slot:[`item.documentType`]="{ item }">
							<div v-if="item.documentType != null && item.status != documentStates.ERROR" :class="item.deleted?'row-deleted':'row-to-import'"> {{ item.documentType.value }} </div>
							<div v-else-if="item.documentType != null" style="word-wrap: break-word; max-width: 200px; color: red;"> {{ item.documentType.value }} </div>
						</template>

						<template v-slot:[`item.status`]="{ item }">
							<v-icon v-if="item.status == documentStates.OK" style="color: green;">mdi-checkbox-marked-circle</v-icon>
							<v-icon v-else-if="item.status == documentStates.WARNING" style="color: #FFA500;">mdi-alert-circle</v-icon>
							<v-icon v-else-if="item.status == documentStates.ERROR" style="color: red;">mdi-alert-circle</v-icon>
						</template>

						<template v-slot:[`item.comment`]="{ item }">
							<div v-if="item.status == documentStates.ERROR" style="color: red"> {{ item.comment }} </div>
							<div v-else :class="item.deleted?'row-deleted':'row-to-import'"> {{ item.comment }} </div>
						</template>

						<template v-slot:[`item.action`]="{item}">
							<v-icon v-if="item.deleted && item.status != documentStates.ERROR" style="color: green;" @click="() => { updateImportConcreteDocument(item.id); }"> mdi-backup-restore </v-icon>
							<v-icon v-else-if="!item.deleted && item.status != documentStates.ERROR" style="color: green;" @click="() => { updateImportConcreteDocument(item.id); }"> mdi-delete </v-icon>
						</template>
					</v-data-table>
				</v-layout>

				<!-- import first 100 row error -->
				<div v-if="showRowErrorImport">
					<div class="zip-validated-page-header-part col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
						<div class="zip-validated-page-header-part col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" style="margin-top: 0px; margin-bottom: 0px; color:red;"> {{lview.rowError}} </div>
					</div>
				</div>

				<!-- import summary header -->
				<div class="zip-validated-page-header-part col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
					<div class="zip-validated-page-header-part col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" style="margin-top: 0px; margin-bottom: 0px; text-decoration: underline;"> {{ lview.importSummaryHeaders }} </div>
				</div>

				<!-- import summary -->
				<div class="zip-validated-page-header-part col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
					<div style="float: left; padding-right: 5px; color: green;" class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-6">
						{{ lview.importDocumentsNumber }}: {{ importSummary.importDocumentsNumber }}
					</div>
					<div style="float: left; padding-right: 5px;" class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-6">
						{{ lview.excludedFilesNumber }}: {{ importSummary.excludedFilesNumber }}
					</div>
					<div style="float: left; padding-right: 5px; color: red;" class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-6">
						{{ lview.errorDocumentsNumber }}: {{ importSummary.errorDocumentsNumber }}
					</div>
					<div style="float: left; padding-right: 5px; color: #ff4c00;" class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-6">
						{{ lview.warningDocumentsNumber }}: {{ importSummary.warningDocumentsNumber }}
					</div>
				</div>

				<!-- Bottom buttons -->
				<!-- Case when no error was detected while zip validation -->
				<div class="zip-validated-page-footer" style="float: left;" v-if="!errorInDataOccurred">
					<v-btn class="mr-3" @click="cancel"> {{ pageMessages.ZIP_VALIDATED.cancelButtonLabel }} </v-btn>
					<v-btn :disabled="importSummary.importDocumentsNumber == 0" class="ml-3" color="primary" @click="uploadDocuments"> {{ pageMessages.ZIP_VALIDATED.addDocumentsButtonLabel }} </v-btn>
				</div>
				<!-- Case when any issue was found -->
				<div class="zip-validated-page-footer" v-else>
					<v-flex xs12>
						<div>
							<v-btn class="mr-3" @click="cancel"> {{ pageMessages.ZIP_VALIDATED.cancelButtonLabel }} </v-btn>
							<v-btn :disabled="importSummary.importDocumentsNumber == 0" class="ml-3" color="primary" @click="uploadDocuments"> {{ pageMessages.ZIP_VALIDATED.addDocumentsButtonLabel }} </v-btn>
						</div>
						<!-- <div style="width: 100%; text-align: center; color: red; float: left; margin-top: 15px;">{{ pageMessages.ZIP_VALIDATED.uploadWithoutErrorsMessage }}</div> -->
					</v-flex>
				</div>
			</div>
		</v-layout>

		<v-layout v-if="pageState == pageStates.FILES_UPLOADING" class="d-flex flex-column">
			<v-container style="height: 550px;">
				<v-row
					class="fill-height"
					align-content="center"
					justify="center"
				>
					<v-col>
						<v-progress-linear
							:value="uploadingProgressBar.uploadProgress"
							color="primary"
							height="25"
							:indeterminate="uploadingProgressBar.indeterminate"
						>
							<template v-if="!uploadingProgressBar.indeterminate" v-slot:default="{ value }">
								<strong style="color:#FFFFFF">{{ Math.ceil(value) }}%</strong>
							</template>
						</v-progress-linear>
					</v-col>
					<v-col
						class="text-subtitle-1 text-center"
						cols="12"
					>
						{{ uploadingProgressBar.message }}
					</v-col>
				</v-row>
			</v-container>
		</v-layout>

		<v-layout v-if="pageState == pageStates.VALIDATION_ERROR" class="d-flex flex-column">
			<!-- main page content -->
			<v-layout class="align-self-center align-content-center pt-2 attach-files-container d-flex flex-column">
				<!-- attaching files input : "LOAD" button -->
				<div class="attach-files-input mt-10 d-flex flex-column justify-content-center" style="width: 100%;">
					<!-- options for processing files in zip -->
					<div class="d-flex justify-content-center align-self-center mt-5" style="font-size: 20px; font-weight: 500; color: red;">
						{{ pageZipErrorMessageHeader }}
					</div>

					<!-- proper load files -->
					<v-layout class="d-flex flex-column">
						<v-icon class="mt-10 align-self-center" size="80" style="color: red; background-color: white; border: 2px solid red; border-radius: 50%; width: 85px;">mdi-exclamation</v-icon>

						<div class="d-flex justify-content-center align-self-center mt-5" style="font-size: 25px;">
							{{ pageZipErrorMessage }}
						</div>
						<span class="mt-5"> {{ pageMessages.VALIDATION.correctFiles }} </span>

						<v-btn small color="grey lighten-2 mt-5 align-self-center" style="width: 200px;" @click="cancel"> {{ pageMessages.VALIDATION.goBack }} </v-btn>
					</v-layout>
				</div>
			</v-layout>

			<!-- information for user -->
			<div class="pt-5">
				<strong style="color: red;"> {{ pageMessages.BEFORE_UPLOAD.userInfoPrefix }} </strong>
				{{ pageMessages.BEFORE_UPLOAD.userInfoWithoutLinkPart }} <span @click="showImportTemplateDialog = true" style="border-bottom: 1px solid blue; color: blue; cursor: pointer;">{{ pageMessages.BEFORE_UPLOAD.userInfoLink }}</span>
				<!-- <v-btn text flat to="/">{{ pageMessages.BEFORE_UPLOAD.downloadTemplate }}</v-btn> -->
			</div>
		</v-layout>

		<!-- after upload view : no errors -->
		<v-layout v-if="pageState == pageStates.FILES_UPLOADED_CORRECTLY" class="d-flex flex-column">
			<v-layout class="align-self-center align-content-center pt-10 attach-files-container d-flex flex-column">
				<v-icon style="margin-top: 70px;" size="80" color="primary">mdi-checkbox-marked-circle</v-icon>
				<span class="align-self-center"> {{ pageMessages.FILES_UPLOADED.globalMessage }} </span>
				<v-btn style="margin: 25px auto; height: 20px;" class="back-button-files-uploaded" color="primary" v-if="summaryUuid != null" @click="downloadSummaryExcelFile"> {{ lview.downloadImportSummary }} </v-btn>
				<v-btn style="margin: 25px auto; height: 20px;" class="back-button-files-uploaded" color="primary" @click="cancel"> {{ lview.validatedZip.goBack }} </v-btn>
			</v-layout>
		</v-layout>

		<!-- after upload view : some errors while uploading -->
		<v-layout v-if="pageState == pageStates.FILES_UPLOADED_WITH_ERRORS" class="d-flex flex-column">
			<div class="zip-validated-page d-flex flex-column">
				<!-- after zip validation page : Table view -->
				<v-layout class="imported-documents-table d-flex flex-column" style="margin-top:50px; padding: 0px;">                    
					<v-data-table
						dense
						:headers="unsavedFilesFromUploadHeaders"
						:items="unsavedFilesFromUpload"
						item-key="name"
						class="elevation-1"
					>
						<template v-slot:top>
							<v-flex xs12 style="margin-left: 15px; margin-top: 10px; margin-bottom: 10px; color: red;">
								{{ lview.filesUploadedButSomeErrorsOccurred }}
							</v-flex>
						</template>
					</v-data-table>
					<v-btn style="margin: 25px auto; height: 20px;" class="back-button-files-uploaded" color="primary" v-if="summaryUuid != null" @click="downloadSummaryExcelFile"> {{ lview.downloadImportSummary }} </v-btn>
					<v-btn style="margin: 25px auto; height: 20px;" class="back-button-files-uploaded" color="primary" @click="cancel"> {{ lview.validatedZip.goBack }} </v-btn>
				</v-layout>
			</div>
		</v-layout>

		<!-- after upload view : invalid file uploaded - not zip file -->
		<v-layout v-if="pageState == pageStates.INVALID_INPUT_FILE" class="d-flex flex-column">
			<v-layout class="align-self-center align-content-center pt-10 attach-files-container d-flex flex-column">
				<v-icon style="margin-top: 70px; width: 84px; margin-left: auto; margin-right: auto; border-radius: 50%; border: 2px solid red;" size="80" color="red">mdi-exclamation</v-icon>
				<span class="align-self-center"> {{ pageMessages.INVALID_INPUT_FILE.globalMessage }} </span>
				<v-btn style="margin: 25px auto; height: 20px;" class="back-button-files-uploaded" color="primary" @click="cancel"> {{ lview.validatedZip.goBack }} </v-btn>
			</v-layout>
		</v-layout>
	</v-layout>
</template>

<script>
import JSZip from "jszip";
import axios from 'axios';
import fob_config from '../fob_config.js';

export default {
	data: function() {
		return {
			/**
			 * Whole page states:
			 * BEFORE_UPLOAD  - before any action is being processed; this state is created for uploading zip package
			 * ZIP_VALIDATION - this state means that page sent request with attached zip package to server and is waiting for
			 * ZIP_VALIDATED  - this state means that page received response from server after validation
			 */
			pageStates: {
				BEFORE_UPLOAD: 0,
				ZIP_VALIDATED: 1,
				VALIDATION_ERROR: 2,
				FILES_UPLOADED_CORRECTLY: 3,
				FILES_UPLOADED_WITH_ERRORS: 4,
				INVALID_INPUT_FILE: 5,
				FILES_UPLOADING: 6
			},

			// attachment options for files in zip -> omit headers, override existing files etc.
			attachmentOptions: {
				omitHeaders: {
					value: false,
					label: ""
				},
				overrideExistingFiles: {
					value: false,
					label: ""
				},
				uploadWithDocumentType: {
					value: false,
					label: ""
				},
			},

			// enums for document states after validation
			documentStates: {
				OK: 0,
				WARNING: 1,
				ERROR: 2
			},
			documentStatesSelect: [
				{
					text: "",
					value: 0,
				},
				{
					text: "",
					value: 1,
				},
				{
					text: "",
					value: 2
				}
			],

			// all messages that are rendered on page are kept in one place
			pageMessages: {
				BEFORE_UPLOAD: {
					userInfoPrefix: "",
					userInfo: "",
					attachmentsPanelHeader: "",
					loadFiles: "",
					filesSizeInfo: "",
				},
				VALIDATION: {
					overlayerMessage: "",
					excelLackMessage: "",
					tooLargeZipLoadedMessage: "",
					invalidZipStructure: "",
					correctFiles: "",
					goBack: "",
				},
				ZIP_VALIDATED: {
					uploadedZip: "",
					documentsToAdd: {
						label: "",
						number: 1,
						suffix: "{L dokument }"
					},
					selectFilesStatuses: {
						label: "",
						items: []
					},
					filtersHeader: "",
					uploadWithoutErrorsMessage: "",
					goBack: "",
					search: "",
					status: "",
				},
				FILES_UPLOADED: {
					globalMessage: "",
				},
				INVALID_INPUT_FILE: {
					globalMessage: "",
				},
			},

			// current page state -> it's initiated in "mounted" function
			pageState: null,
			pageZipErrorMessageHeader: null,
			pageZipErrorMessage: null,

			// uploaded ZIP data
			uploadedZipName: "",
			uploadedZipContent: null,
			uploadedZipContentSize: null,
			uploadedZip: null,

			uploadingProgressBar: {
				uploadProgress: 0,
				indeterminate: true,
				message: ""
			},

			// validated file information after zip validation
			shownPage: 1,
			validatedDocumentsDataHeaders:[
				{
					text: 'Nr',
					align: 'start',
					sortable: true,
					value: 'id',
				},
				{ text: '', value: 'inputFilename' },
				{ text: '', value: 'workerName' },
				{ text: '', value: 'outputFilename' },
				{ text: '', value: 'documentType' },
				{ text: '', value: 'status', sortable: false },
				{ text: '', value: 'comment', sortable: false },
				{ text: '', value: 'action', sortable: false },
			],
			validatedDocumentsData: [
				/**
				 * {
				 *      id: {Number},
				 *      inputFilename: {String},
				 *      workerName: {String} or {null},
				 *      workerId: {Number} or {null},
				 *      outputFilename: {String},
				 *      status: {Number},
				 *      comment: {String},
				 *      action: "",
				 *      deleted: {Boolean},
				 *      visible: {Boolean},
				 *      documentType: {Object}
				 * }
				 */
			],
			renderedDocumentsData: [
				/**
				 * {
				 *      id: {Number},
				 *      inputFilename: {String},
				 *      workerName: {String} or {null},
				 *      workerId: {Number} or {null},
				 *      outputFilename: {String},
				 *      status: {Number},
				 *      comment: {String},
				 *      action: "",
				 *      deleted: {Boolean},
				 *      visible: {Boolean},
				 *      documentType: {String}
				 * }
				 */
			],

			errorInDataOccurred: false,

			filters: {
				searchValue: "",
				statusOptions: []
			},

			documentTypesList: [
				/**
				 * {
				 *      id: {Number},
				 *      value: {String}
				 * }
				 */
			],

			documentTypesSelect: [
				/**
				 * {
				 *      value: {Number},
				 *      text: {String}
				 * }
				 */
			],

			workersData: [
				/**
				 * {
				 *      pesel: {String},
				 *      fullname: {String} or null,
				 *      workerId: {Number} or null,
				 * }
				 */
			],

			unsavedFilesFromUpload: [],
			unsavedFilesFromUploadHeaders: [
				{ text: '', align: 'start', sortable: false, value: 'name' },
				{ text: '', sortable: false, value: 'error' }
			],

			configExcelFile: null,
			showImportTemplateDialog: false,
			showRowErrorImport: false,

			importSummary: {
				importDocumentsNumber: null,
				excludedFilesNumber: null,
				errorDocumentsNumber: null,
				warningDocumentsNumber: null
			},

			defaultDocumentType: null,
			summaryUuid: null,
		}
	},
	methods: {
		dragFile(file){
			let userPermissions = localStorage.getItem("user_permissions");
			if (!userPermissions.includes("fob.worker_docs_add")) {
				
				return;
			}
			else{
				let droppedFiles = file.dataTransfer.files;
				if(!droppedFiles) return;
				let fileList = [];
				for (var i=0; i<droppedFiles.length; i++){
					fileList.push(droppedFiles[i]);
				}
				let extension = fileList[0].name.split('.').pop()
				if(extension == "zip" || extension == "rar" || extension == "7zip"){
					this.updateDataTable(fileList[0])
					
				}
				else{
					console.log("ERROR")
					// TODO: Catch and show incorrect file extension error
				}
			}

		},
		downloadSummaryExcelFile: async function() {
			if (this.summaryUuid == null || this.summaryUuid == undefined) {
				this.$emit('set-state', 'default');
				this.$emit('display-error', this.lmessages.errorOccurredTitle, this.lview.couldNotDownloadSummaryFile);
				return;
			}
			this.$emit('set-state', "LOADING_OVERLAYER");
			var xhr = new XMLHttpRequest();
			var that = this;
			xhr.onload = function() {
				that.$emit('set-state', 'default');
				var reader = new FileReader();
				reader.onloadend = function() {
					const linkSource = reader.result;
					const downloadLink = document.createElement('a');

					document.body.appendChild(downloadLink);
					downloadLink.href = linkSource;
					downloadLink.target = '_self';
					downloadLink.download = "import_" + that.summaryUuid + ".xls";
					downloadLink.click();
				}

				if (xhr.status < 400) {
					console.log(xhr.response);
					reader.readAsDataURL(xhr.response);
				}
				else {
					that.$emit('display-error', that.lmessages.errorOccurredTitle, that.lview.couldNotDownloadSummaryFile);   
				}
			};
			xhr.onerror = function(){
				that.$emit('set-state', 'default');
				that.$emit('display-error', that.lmessages.errorOccurredTitle, that.lview.couldNotDownloadSummaryFile);
			};
			xhr.open('GET', fob_config.api_url + "/api/v2/worker-documents/import-summary/" + this.summaryUuid, true);
			xhr.responseType = 'blob';
			xhr.setRequestHeader("Authorization", "Bearer " + localStorage.getItem('jwt'));
			xhr.send();
		},

		omitHeadersCheckboxUpdated(newValue){
			if (newValue)
				localStorage.setItem("omitHeadersCheckbox", 1);
			else
				localStorage.setItem("omitHeadersCheckbox", 0);
		},
		overrideExistingFilesCheckboxUpdated(newValue){
			if (newValue)
				localStorage.setItem("overrideExistingFilesCheckbox", 1);
			else
				localStorage.setItem("overrideExistingFilesCheckbox", 0);
		},
		uploadWithDocumentTypeCheckboxUpdated(newValue){
			if (newValue)
				localStorage.setItem("uploadWithDocumentTypeCheckbox", 1);
			else
				localStorage.setItem("uploadWithDocumentTypeCheckbox", 0);
		},
		// Opens file input so that user can choose zip to upload
		openInput: function(){
			this.$refs.uploadFilesPackagesInput.click();
		},

		assignRenderedDocumentsData: function(){
			this.renderedDocumentsData = [];
			for(var i = 0; i < this.validatedDocumentsData.length; i++){
				this.renderedDocumentsData.push(this.validatedDocumentsData[i]);
			}
		},

		filterDocumentsByStatus: function(){
			if (this.filters.statusOptions.length == 0){
				this.assignRenderedDocumentsData();
				return;
			}

			this.renderedDocumentsData = [];
			for(var i = 0; i < this.validatedDocumentsData.length; i++){
				let shouldBeRendered = (this.filters.statusOptions.find(
					element => { return element == this.validatedDocumentsData[i].status; }
				) != undefined);
				if (shouldBeRendered){
					this.renderedDocumentsData.push(this.validatedDocumentsData[i]);
				}
			}
		},

		/**
		 * Checks how many *.xls(x) files are located in uploaded zip
		 * @param zip - uploaded zip file (blob from input type="file")
		 * @returns excelFilesNumber - number of *.xls(x) files
		 */
		getExcelFilesNumber: function(zip){
			let excelFilesNumber = 0;
			if (zip == null || zip == undefined) {
				return 0;
			}
			zip.forEach((relativePath, zipEntry) => {
				let matches = (zipEntry.name.search(".xls") != -1);
				if (matches){
					excelFilesNumber++;
				}
			});
			return excelFilesNumber;
		},

		assignSpecialCharacters: function(bytes) {
			let str = "";
			for (let i = 0; i < bytes.length; i++) {
				switch( bytes[i] ) {
					case 164:
						str += 'Ą';
						break;
					case 143:
						str += 'Ć';
						break;
					case 168:
						str += 'Ę';
						break;
					case 157:
						str += 'Ł';
						break;
					case 227:
						str += 'Ń';
						break;
					case 224:
						str += 'Ó';
						break;
					case 151:
						str += 'Ś';
						break;
					case 141:
						str += 'Ź';
						break;
					case 189:
						str += 'Ż';
						break;
					case 165:
						str += 'ą';
						break;
					case 134:
						str += 'ć';
						break;
					case 169:
						str += 'ę';
						break;
					case 136:
						str += 'ł';
						break;
					case 228:
						str += 'ń';
						break;
					case 162:
						str += 'ó';
						break;
					case 152:
						str += 'ś';
						break;
					case 171:
						str += 'ź';
						break;
					case 190:
						str += 'ż';
						break;
					case 132: // german special characters below
						str += 'ä';
						break;
					case 142:
						str += 'Ä';
						break;
					case 148:
						str += 'ö';
						break;
					case 153:
						str += 'Ö';
						break;
					case 129:
						str += 'ü';
						break;
					case 154:
						str += 'Ü';
						break;
					case 225:
						str += 'ß';
						break;
					default:
						str += String.fromCharCode(bytes[i]);
						break;
				}
			}
			return str;
		},

		// reads loaded zip file
		readZipContent: async function(zippedFile){   
			var zipContent = null;
			var that = this;
			try{
				zipContent = await JSZip.loadAsync(zippedFile, {
					decodeFileName: function (bytes) {
						let filename = that.assignSpecialCharacters(bytes);
						return filename;
					}
				});
			}catch(error){
				console.log(error);
			}
			return zipContent;
		},

		/**
		 * Extracts *.xls(x) file from given zip content
		 * @param zipContent - zip content returned by method "readZipContent"
		 * @returns excelFile - extracted excel file 
		 */
		getExcelFile: async function(zipContent){
			var excelFile;
			zipContent.forEach((relativePath, zipEntry) => {
				let matches = (zipEntry.name.search(".xls") != -1);
				if (matches){
					excelFile = zipEntry;
				}
			});

			let binaryExcel = await excelFile.async("blob");
			let excelWorkbook = new ExcelFile();
			await excelWorkbook.loadWorkbook(binaryExcel);
			return excelWorkbook;
		},

		/**
		 * Finds position of document in excel
		 * @param excelFile - *.xls(x) file necessary to validation (returned by method "getExcelFile")
		 * @param filename - name of file to be validated
		 */
		getFileIndexInExcel: function(excelFile, filename){
			for(var row = 1; row <= excelFile.numberOfRows; row++){
				let cellValue = excelFile.sheet["A" + String(row)].v;
				if(cellValue == filename){
					return row;
				}
			}
			return -1;
		},

		/**
		 * Validates document type according to downloaded document types list.
		 * @param type - {String} type we want to validate
		 * @returns {Object} (with structure presented below) if given type is correct, otherwise returns undefined
		 * 
		 * {
		 *      id : {String},  // identifier for concrete document type
		 *      value: {String} // value for concrete document type 
		 * }
		 */
		validateDocumentType: function(type){
			if (type == undefined) {
				return undefined;
			}

			let uppercaseType = type.toLocaleUpperCase();
			for(var i = 0; i < this.documentTypesList.length; i++){
				if (uppercaseType == this.documentTypesList[i].id || uppercaseType == this.documentTypesList[i].value.toLocaleUpperCase())
					return this.documentTypesList[i];
			}
			return undefined;
		},

		/**
		 * Gets worker fullname
		 * @param pesel - {String} pesel (from given excel) of worker, whose fullname we want know 
		 * @returns {Object} when worker is found in workersData by his/her pesel number
		 * @returns {undefined} when worker is not found by his/her pesel number
		 */
		getDocumentWorker: function(pesel) {
			for(var i = 0; i < this.workersData.length; i++){
				if (pesel == this.workersData[i].pesel){
					return this.workersData[i];
				}
			}
			return undefined;
		},

		/**
		 * Validates single entry of uploaded zip
		 * @param relativePath - entry path in zip
		 * @param zipEntry - entry to validate
		 * @param excelFile - file necessary to validation received from "getExcelFile" method
		 * @param rowId - number of row with which we want to validate given entry
		 * @returns Object with structure presented below
		 * {
		 *      inputFilename: {String},
		 *      worker: {String},
		 *      outputFilename: {String},
		 *      status: {one value from enum "pageStates"},
		 *      comment: {String}
		 * }
		 */
		validateEntry: async function(zipEntry, excelFile, rowId){
			let entryNameSplit = zipEntry.name.split('/');
			let entryName = entryNameSplit[entryNameSplit.length - 1];
			let patternFileExtension = /\.([0-9a-z]+)(?:[\?#]|$)/i;
			let extensionTypes = ['.TXT', '.DOC', '.DOCX', '.DOCM', '.ODT', '.PDF', '.CSV', '.XLS', '.XLSX', '.XLSM', '.ODS', '.PPS', '.PPT', '.PPSX', '.PPTX', '.POTX', '.ODP', '.JPEG', '.JPG', '.PNG', '.BMP', '.ICO', '.SVG', '.WEBP', '.GIF', '.PSD', '.HEIC', '.NEF', '.CRW', '.AI', '.ID', '.ZIP', '.RAR', '.RAR5', '.7Z'];

			let index = rowId;//this.getFileIndexInExcel(excelFile, entryName);
			// document found in excel
			if (index != -1) {
				var finalEntryValidData = {
					documentType: undefined,
					entryInputFilename: "",
					entryOutputFilename: "",
					peselNumber: "",
					workerId: null,
					workerFullname: "",

					warnings: [],
					errors: []
				}


				if(zipEntry._data instanceof Promise){
					if (zipEntry._data.PromiseResult != "fulfilled") {
						await zipEntry._data;
					}
					// If still remains a promise, the file is empty (promise result is empty)
					if(zipEntry._data instanceof Promise){
						finalEntryValidData.errors.push(this.lview.validation.fileEmpty);
					}
				}

				if (this.attachmentOptions.uploadWithDocumentType.value) {
					var docType;
					try {
						docType = excelFile.sheet["D" + index.toString()].v;
					} catch(error) {
						docType = undefined;
					}
					let docTypeObj = this.validateDocumentType(docType);
					if (docTypeObj != undefined) {
						finalEntryValidData.documentType = docTypeObj;
					} else {
						let defaultDocumentTypeFound = this.documentTypesSelect.find(x => { return x.value == this.defaultDocumentType; });

						finalEntryValidData.documentType = {
							id: defaultDocumentTypeFound.value,
							value: defaultDocumentTypeFound.text
						}
						let message = this.lview.validation.invalidDocTypeWarningMessage.replace("{typeInExcel}", docType==undefined?"''":docType);
						message = message.replace("{defaultType}", defaultDocumentTypeFound.text);
						finalEntryValidData.warnings.push(message);
					}
				}
				else {
					let defaultDocumentTypeFound = this.documentTypesSelect.find(x => { return x.value == this.defaultDocumentType; });

					finalEntryValidData.documentType = {
						id: defaultDocumentTypeFound.value,
						value: defaultDocumentTypeFound.text
					}
				}

				try {
					finalEntryValidData.entryInputFilename = excelFile.sheet["A" + index.toString()].v;

					// check extension
					let fileInputExtension = (finalEntryValidData.entryInputFilename).match(patternFileExtension);
					
					if (fileInputExtension == null) {
						finalEntryValidData.entryInputFilename += '.pdf';
					} else {
						if (!extensionTypes.includes(fileInputExtension[0].toUpperCase())) {
							finalEntryValidData.entryInputFilename += '.pdf';
						}
					}

				} catch(error) {
					finalEntryValidData.entryInputFilename = "";
					finalEntryValidData.errors.push(this.lview.validation.errorOccurredWhileReadingInputFilename);
				}

				try {
					let entryOutputFilename = excelFile.sheet["C" + index.toString()]

					if (entryOutputFilename == undefined || entryOutputFilename == null) {
						finalEntryValidData.entryOutputFilename = finalEntryValidData.entryInputFilename;
					} else {
						finalEntryValidData.entryOutputFilename = entryOutputFilename.v;

						// check if outfile name don't have a extension add extension from input file
						let fileOutputExtension = (finalEntryValidData.entryOutputFilename).match(patternFileExtension);
						let fileInputExtension = (finalEntryValidData.entryInputFilename).match(patternFileExtension);

						if (finalEntryValidData.entryInputFilename != "") {
							if (fileOutputExtension == null) {
								finalEntryValidData.entryOutputFilename += fileInputExtension[0];
							} 
							else {
								if (!extensionTypes.includes(fileOutputExtension[0].toUpperCase())) {
									finalEntryValidData.entryOutputFilename += fileInputExtension[0];
								} 
							}
						} 
					}           
				} catch (error) {
					finalEntryValidData.entryOutputFilename = "";
					finalEntryValidData.errors.push(this.lview.validation.errorOccurredWhileReadingOutputFilename);
				}

				try {
					finalEntryValidData.peselNumber = String(excelFile.sheet["B" + index.toString()].v);
				} catch (error) {
					finalEntryValidData.peselNumber = undefined;
				}
				let workerObj = this.getDocumentWorker(finalEntryValidData.peselNumber);
				if (workerObj != undefined) {
					if (workerObj.workerId == null){
						finalEntryValidData.workerId = null;
						finalEntryValidData.workerFullname = "";
						finalEntryValidData.errors.push(this.lview.validation.unknownUserIncorrectPesel);
					}
					else {
						if (workerObj.workerDocumentsNames.includes(finalEntryValidData.entryOutputFilename)) {
							if (this.attachmentOptions.overrideExistingFiles.value) {
								finalEntryValidData.warnings.push(this.lview.validation.fileWillBeOverwritten);
							}
							else {
								finalEntryValidData.warnings.push(this.lview.validation.duplicateFilename);
							}
						}

						finalEntryValidData.workerId = workerObj.workerId;
						finalEntryValidData.workerFullname = workerObj.fullname;
					}
				} else {
					finalEntryValidData.workerId = null;
					finalEntryValidData.workerFullname = "";
					finalEntryValidData.errors.push(this.lview.validation.unknownUserIncorrectPesel);
				}

				if (finalEntryValidData.errors.length > 0) {
					let errorsMessage = finalEntryValidData.errors.join("|");
					return {
						inputFilename: finalEntryValidData.entryInputFilename,
						worker: finalEntryValidData.workerFullname,
						workerId: finalEntryValidData.workerId,
						outputFilename: finalEntryValidData.entryOutputFilename,
						status: this.documentStates.ERROR,
						comment: errorsMessage,
						documentType: finalEntryValidData.documentType,
					}
				}
				if (finalEntryValidData.warnings.length > 0) {
					return {
						inputFilename: finalEntryValidData.entryInputFilename,
						worker: finalEntryValidData.workerFullname,
						workerId: finalEntryValidData.workerId,
						outputFilename: finalEntryValidData.entryOutputFilename,
						status: this.documentStates.WARNING,
						comment: finalEntryValidData.warnings.join(" | "),
						documentType: finalEntryValidData.documentType,
					}
				}
				return {
					inputFilename: finalEntryValidData.entryInputFilename,
					worker: finalEntryValidData.workerFullname,
					workerId: finalEntryValidData.workerId,
					outputFilename: finalEntryValidData.entryOutputFilename,
					status: this.documentStates.OK,
					comment: "OK",
					documentType: finalEntryValidData.documentType,
				}
			}
			else { // document not found in excel -> ERROR
				return {
					inputFilename: entryName,
					worker: "",
					outputFilename: "",
					status: this.documentStates.ERROR,
					comment: this.lview.validation.fileIsNotPresentInExcel,
					documentType: {
						id: undefined,
						value: ""
					},
				}
			}
		},

		getUsersInfoRequestData: function(excelFile){
			var cellValue, pesel_values = [];
			for (var i = 1; i <= excelFile.numberOfRows; i++){
				cellValue = undefined;
				try {
					cellValue = excelFile.sheet["B" + String(i)].v;
				} catch(error) {
					cellValue = undefined;
				}
				if (cellValue != undefined){
					if (!pesel_values.includes( String(cellValue) ) ){
						cellValue = String(cellValue);
						if (cellValue.length < 11){
							for(var k = 0; k < cellValue.length - 11; k++){
								cellValue += '0';
							}
						}
						else if(cellValue.length > 11){
							cellValue = cellValue.substring(0,11);
						}
						pesel_values.push( String(cellValue) );
					}
				}
			}
			return { 
				pesels: pesel_values,
			}
		},

		assignWorkersDataAndDocumentTypes: function(response){
			// assign downloaded document types data
			this.documentTypesList = [];
			let documentTypesObjKeys = Object.keys(response.data.document_types);
			for (var i = 0; i < documentTypesObjKeys.length; i++) {
				this.documentTypesList.push({
					id: response.data.document_types[ documentTypesObjKeys[i] ],
					value: documentTypesObjKeys[i]
				});
			}

			let workerDataObjKeys = Object.keys(response.data.worker_data);
			// assign uploaded workers data
			this.workersData = [];
			for (var i = 0; i < workerDataObjKeys.length; i++) {
				if (response.data.worker_data[ workerDataObjKeys[i] ] == null){
					this.workersData.push({
						pesel: String(workerDataObjKeys[i]),
						fullname: null,
						workerId: null,
						workerDocumentsNames: null
					});
				} 
				else {
					this.workersData.push({
						pesel: String(workerDataObjKeys[i]),
						fullname: response.data.worker_data[ workerDataObjKeys[i] ].name,
						workerId: response.data.worker_data[ workerDataObjKeys[i] ].worker_id,
						workerDocumentsNames: response.data.worker_data[ workerDataObjKeys[i] ].documents
					});
				}
			}
		},

		getAdaptedValidatedData: async function(excelFile, zipContent){
			let adaptedFilesData = [];
			let patternFileExtension = /\.([0-9a-z]+)(?:[\?#]|$)/i;
			let breakFor = true;
			this.showRowErrorImport = false;
			let extensionTypes = ['.TXT', '.DOC', '.DOCX', '.DOCM', '.ODT', '.PDF', '.CSV', '.XLS', '.XLSX', '.XLSM', '.ODS', '.PPS', '.PPT', '.PPSX', '.PPTX', '.POTX', '.ODP', '.JPEG', '.JPG', '.PNG', '.BMP', '.ICO', '.SVG', '.WEBP', '.GIF', '.PSD', '.HEIC', '.NEF', '.CRW', '.AI', '.ID', '.ZIP', '.RAR', '.RAR5', '.7Z'];

			var rowId = 1;
			if (this.attachmentOptions.omitHeaders.value) {
				rowId++;
			}
			while( rowId <= excelFile.numberOfRows ) {
				var inputFilename;
				try {
					inputFilename = excelFile.sheet["A" + rowId.toString()].v;

					//Get the file Extension 
					let fileExtension = (inputFilename).match(patternFileExtension);
					
					if (fileExtension == null) {
						inputFilename += '.pdf'    
					} else {
						if (!extensionTypes.includes(fileExtension[0].toUpperCase())) {
							inputFilename += '.pdf'    
						}
					}
				} catch (error) {
					adaptedFilesData.push({
						id: rowId,
						inputFilename: "",
						workerName: "",
						workerId: null,
						outputFilename: "",
						status: this.documentStates.ERROR,
						comment: this.lview.inputFilenameNotProvided,
						action: "",
						deleted: false,
						visible: true,
						documentType: null,
					});
				}

				let foundZipEntry = null;
				zipContent.forEach((relativePath, zipEntry) => {
					var entryNameSplit = zipEntry.name.split("/");
					var entryName = entryNameSplit[entryNameSplit.length - 1];
					if (entryName == inputFilename) {
						foundZipEntry = zipEntry;
					}
				});

				if (foundZipEntry == null) {
					adaptedFilesData.push({
						id: rowId,
						inputFilename: inputFilename,
						workerName: "",
						workerId: null,
						outputFilename: "",
						status: this.documentStates.ERROR,
						comment: this.lview.fileNotFoundInZip,
						action: "",
						deleted: false,
						visible: true,
						documentType: null,
					});
				}
				else {
					// file is found in excel
					let validatedEntryData = await this.validateEntry(foundZipEntry, excelFile, rowId);                 
					// if row > 1 and row <= 100 then check return status
					if (rowId > 0 && rowId <= 100) {
						if (validatedEntryData.status == this.documentStates.OK || validatedEntryData.status == this.documentStates.WARNING) {
							breakFor = false;
						}                       
					}

					adaptedFilesData.push(
						{
							id: rowId,
							inputFilename: validatedEntryData.inputFilename,
							workerName: validatedEntryData.worker,
							workerId: validatedEntryData.workerId,
							outputFilename: validatedEntryData.outputFilename,
							status: validatedEntryData.status,
							comment: validatedEntryData.comment,
							action: "",
							deleted: false,
							visible: true,
							documentType: validatedEntryData.documentType,
						}
					);
				}

				if (rowId == 100 && breakFor == true) {
					this.showRowErrorImport = true;
					return adaptedFilesData;
				}

				rowId++;
			}
			return adaptedFilesData;
		},

		/**
		 * Validates uploaded zip content
		 * @param zipContent - zip content returned by method "readZipContent"
		 * @returns Object with structure presented below
		 * {
		 *      contentValid: {Boolean},
		 *      validData: {Array of objects} if contentValid is true otherwise validData = null,
		 *      nextPageState: {one value from enum "pageStates"},
		 *      errorMessage: {String} if contentValid is false, otherwise it's null
		 * }
		 */
		validateFilesInZip: async function(zipContent){
			let excelFilesNumber = this.getExcelFilesNumber(zipContent);
			
			if (excelFilesNumber == 1){
				const excelFile = await this.getExcelFile(zipContent);

				this.configExcelFile = excelFile;
				let requestData = this.getUsersInfoRequestData(excelFile);
				var response;
				try {
					response = await axios({
						url: localStorage.getItem("current_env") + "/api/v2/worker-documents/upload-verification",
						method: "POST",
						headers: {
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + localStorage.getItem('jwt')
						},
						data: requestData
					});
				} catch (error){
					this.$emit('set-state', "DEFAULT");
					if (error.message == 'Network Error') {
						this.$emit('set-state', "ERROR", this.lview.networkError);
					} else {
						switch(error.response.status){
							case 403:
								this.$emit('set-state', "ERROR", this.lview.lackOfPermission);
								break;
							case 400:
								this.$emit('set-state', "ERROR", this.lview.couldNotProcessAllSentFiles);
								break;
							default:
								this.$emit('set-state', "ERROR");
								break;
						}
					}
					 
					return {
						contentValid: false,
						errorMessage: error.toString(),
					};
				}

				this.assignWorkersDataAndDocumentTypes(response);
				let adaptedFilesData = await this.getAdaptedValidatedData(excelFile, zipContent);

				return {
					contentValid: true,
					validData: adaptedFilesData,
					nextPageState: this.pageStates.ZIP_VALIDATED,
					errorMessage: null
				}
			}
			else if (excelFilesNumber == 0){
				return {
					contentValid: false,
					validData: null,
					nextPageState: this.pageStates.VALIDATION_ERROR,
					errorMessage: this.pageMessages.VALIDATION.excelLackMessage
				}
			}
			else if (excelFilesNumber > 1){
				let excel = await this.getConcreteExcelFileFromZip("import.xlsx", zipContent);
				if (excel == undefined) {
					return {
						contentValid: false,
						validData: null,
						nextPageState: this.pageStates.VALIDATION_ERROR,
						errorMessage: this.pageMessages.VALIDATION.invalidZipStructure
					}
				}

				this.configExcelFile = excel;
				var response;
				try {
					let requestData = this.getUsersInfoRequestData(excel);
					response = await axios({
						url: localStorage.getItem("current_env") + "/api/v2/worker-documents/upload-verification",
						method: "POST",
						headers: {
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + localStorage.getItem('jwt')
						},
						data: requestData
					});
				} catch (error) {
					if (error.message == 'Network Error') {
						this.$emit('set-state', "ERROR", this.lview.networkError);
					} else {
						switch(error.response.status){
							case 403:
								this.$emit('set-state', "ERROR", this.lview.lackOfPermission);
								break;
							case 400:
								this.$emit('set-state', "ERROR", this.lview.couldNotProcessAllSentFiles);
								break;
							default:
								this.$emit('set-state', "ERROR");
								break;
						}
					}
					
					return {
						contentValid: false,
						errorMessage: error.toString(),
					};
				}

				this.assignWorkersDataAndDocumentTypes(response);
				if (excel){
					let adaptedFilesData = await this.getAdaptedValidatedData(excel, zipContent);
					return {
						contentValid: true,
						validData: adaptedFilesData,
						nextPageState: this.pageStates.ZIP_VALIDATED,
						errorMessage: null
					}
				}
				else {
					return {
						contentValid: false,
						validData: null,
						nextPageState: this.pageStates.VALIDATION_ERROR,
						errorMessage: this.pageMessages.VALIDATION.invalidZipStructure
					}
				}
			}
		},

		/**
		 * Gets *.xlsx file with concrete name from given zip content
		 * @param filename - name of excel we want to extract
		 * @param zipContent - content of the zip, that was received from readZipContent
		 * @returns {ExcelFile} if concrete excel is present in given zip content, otherwise returns undefined
		 */
		getConcreteExcelFileFromZip: async function(filename, zipContent){
			var concreteEntry = undefined;
			
			var keys = Object.keys(zipContent.files);

			for(var keyId = 0; keyId < keys.length; keyId++) {
				let fileJSZipObj = zipContent.files[ keys[keyId] ];
				let entryNameSplit = keys[keyId].split('/');
				let entryName = entryNameSplit[entryNameSplit.length - 1];

				let fileBlob = await fileJSZipObj.async("blob");

				let fileMimeType = await this.determineFileMimeType(fileBlob);
				if (fileMimeType == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" 
					&& entryName == filename) {
					concreteEntry = zipContent.files[ keys[keyId] ];
				}
			}
			
			/*zipContent.forEach((relativePath, zipEntry) => {
				let isExcelFile = (zipEntry.name.search(".xls") != -1);
				if (isExcelFile) {
					let entryNameSplit = zipEntry.name.split("/");
					let entryName = entryNameSplit[entryNameSplit.length - 1];
					if (entryName == filename){
						concreteEntry = zipEntry;
					}
				}
			});*/

			if (concreteEntry){
				let binaryExcel = await concreteEntry.async("blob");
				let excelWorkbook = new ExcelFile();
				await excelWorkbook.loadWorkbook(binaryExcel);
				return excelWorkbook;
			}
			return undefined;
		},

		/**
		 * Updates datatable content when zip is uploaded
		 * @param input - "change" event of <input type="file"/>
		 */
		updateDataTable: async function(input){
			this.$emit("set-state", "LOADING_OVERLAYER", this.pageMessages.VALIDATION.overlayerMessage);
			if(input.target != undefined){
				var zippedFile = input.target.files[0];
			}
			else{
				var zippedFile = input;
			}

			if (zippedFile.type != "application/x-zip-compressed") {
				this.$emit('set-state', "DEFAULT");

				this.pageState = this.pageStates.INVALID_INPUT_FILE;
				return;
			}

			if (this.defaultDocumentType == null) {
				this.$emit('set-state', "DEFAULT");
				return;
			}

			let zipSizeInMB = zippedFile.size / 1048576;

			if (zipSizeInMB > 50){
				this.pageZipErrorMessage = this.pageMessages.VALIDATION.tooLargeZipLoadedMessage;
				this.pageState = this.pageStates.VALIDATION_ERROR;
				this.$emit("set-state", "DEFAULT");
				return;
			}

			this.uploadedZipName = zippedFile.name;
			this.uploadedZip = zippedFile;
			const uploadedZipContentPromise = this.readZipContent(zippedFile);
			const uploadedZipContent = await uploadedZipContentPromise;
			this.uploadedZipContent = uploadedZipContent;

			const validationResults = await this.validateFilesInZip(uploadedZipContent);
			if (validationResults.contentValid){
				this.validatedDocumentsData = validationResults.validData;
				this.assignRenderedDocumentsData();

				this.importSummary.importDocumentsNumber = 0;
				this.importSummary.excludedFilesNumber = 0;
				this.importSummary.errorDocumentsNumber = 0;
				this.importSummary.warningDocumentsNumber = 0;

				for (let idx = 0; idx < this.validatedDocumentsData.length; idx++) {
					if (this.validatedDocumentsData[idx].status == this.documentStates.ERROR) {
						this.importSummary.errorDocumentsNumber++;
					}
					else if (this.validatedDocumentsData[idx].status == this.documentStates.WARNING) {
						this.importSummary.warningDocumentsNumber++;
						this.importSummary.importDocumentsNumber++;
					}
					else if (this.validatedDocumentsData[idx].status == this.documentStates.OK) {
						this.importSummary.importDocumentsNumber++;
					}
				}

				this.uploadedZipContentSize = this.importSummary.importDocumentsNumber; //this.validatedDocumentsData.length;

				this.pageMessages.ZIP_VALIDATED.documentsToAdd.number = this.uploadedZipContentSize;
				if (this.uploadedZipContentSize > 1 || this.uploadedZipContentSize == 0){
					this.pageMessages.ZIP_VALIDATED.documentsToAdd.suffix = this.lview.validatedZip.documentsToAdd.suffixPlural;
				}
				else{
					this.pageMessages.ZIP_VALIDATED.documentsToAdd.suffix = this.lview.validatedZip.documentsToAdd.suffixSingular;
				}

				var errorsNumber = this.validatedDocumentsData.filter(function(item){
					return item.status == 2;
				}).length;

				if (errorsNumber){
					this.errorInDataOccurred = true;
				} else {
					this.errorInDataOccurred = false;
				}
				this.$emit("set-state", "DEFAULT");
			}
			else {
				this.pageZipErrorMessage = validationResults.errorMessage;
				this.$emit("set-state", "DEFAULT");
			}
			this.pageState = validationResults.nextPageState;           
		},

		/*
		 *  Removes document from imported documents list
		 *  @param id - imported document id
		 */
		updateImportConcreteDocument: function(id){
			if (this.validatedDocumentsData[id - 1].deleted){
				this.pageMessages.ZIP_VALIDATED.documentsToAdd.number++;
				this.importSummary.importDocumentsNumber++;
				this.importSummary.excludedFilesNumber--;
			}
			else{
				this.importSummary.importDocumentsNumber--;
				this.importSummary.excludedFilesNumber++;
				this.pageMessages.ZIP_VALIDATED.documentsToAdd.number--;
			}
			this.validatedDocumentsData[id - 1].deleted = !this.validatedDocumentsData[id - 1].deleted;
			//this.assignRenderedDocumentsData();
		},

		// custom-filter method for datatables component
		filterItems: function(value, search, item){
			return  value != null &&
					search != null &&
					item != null && 
					item.documentType != null &&
					typeof value === 'string' &&
					(
						item.inputFilename.toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) != -1 ||
						item.outputFilename.toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) != -1 ||
						item.workerName.toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) != -1 ||
						item.documentType.value.toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) != -1
					);
		},

		// Decides what class should be assigned for concrete datatable's item
		getDataTableRowClass: function(dataTableItem){
			if (dataTableItem.status == this.documentStates.ERROR)
				return "row-deleted-background";
			return "";
		},

		// clears validated documents data and goes back to BEFORE_UPLOAD view state
		cancel: function(){
			/*this.attachmentOptions.omitHeaders.value = false;
			this.attachmentOptions.uploadWithDocumentType.value = true;
			this.attachmentOptions.overrideExistingFiles.value = false;*/

			this.validatedDocumentsData = [];
			this.renderedDocumentsData = [];
			this.pageState = this.pageStates.BEFORE_UPLOAD;
		},

		determineFileMimeType: async function(fileBlob) {
			return await new Promise((resolve) => {
				let fileReader = new FileReader();

				fileReader.onloadend = function (evt) {
					if (evt.target.readyState === FileReader.DONE) {
						var uint = new Uint8Array(evt.target.result);
						let bytes = [];

						uint.forEach((byte) => {
							bytes.push(byte.toString(16))
						})

						const signature_hex = bytes.join('').toUpperCase();
						let mimeType = "";
						switch (signature_hex) {
							case '89504E47':
								mimeType = 'image/png';
								break;
							case '47494638':
								mimeType = 'image/gif';
								break;
							case '25504446':
								mimeType = 'application/pdf';
								break;
							case 'FFD8FFDB':
							case 'FFD8FFE0':
							case 'FFD8FFE1':
								mimeType = 'image/jpeg';
								break;
							case '504B0304':
								mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
								//mimeType = 'application/zip';
								break;
							case '504B34':
								mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
								break;
							default:
								mimeType = "";
								break;
						}

						resolve(mimeType);
					}
				}

				fileReader.readAsArrayBuffer(fileBlob.slice(0, 4));
			});
		},

		/** 
		 * Sends validated documents (only ones with no error) to server 
		 */
		uploadDocuments: async function(){
			//this.$emit("set-state", "LOADING_OVERLAYER", this.lview.uploadingFiles);
			this.uploadingProgressBar.uploadProgress = 0;
			this.uploadingProgressBar.indeterminate = true;
			this.uploadingProgressBar.message = this.lview.uploadBarPreparingRequest;
			this.pageState = this.pageStates.FILES_UPLOADING;

			let requestData = new FormData();

			let uploadedZipContentKeys = Object.keys(this.uploadedZipContent.files);
			
			let filesToSendNumber = 0;
			let docTypes = [], workerIds = [];
			for(var i = 0; i < this.validatedDocumentsData.length; i++) {
				if (this.validatedDocumentsData[i].deleted || this.validatedDocumentsData[i].status == this.documentStates.ERROR) {
					continue;
				}

				let fileObjKey = uploadedZipContentKeys.find(x => {
					let entryNameSplit = x.split('/');
					let entryName = entryNameSplit[entryNameSplit.length - 1];
					return this.validatedDocumentsData[i].inputFilename == entryName;
				});
				let fileObj = this.uploadedZipContent.files[ fileObjKey ];

				if (fileObj == undefined) {
					continue;
				}

				let fileBlob = await fileObj.async("blob");
				let fileMimeType = await this.determineFileMimeType(fileBlob);

				let entryFile = new File([fileBlob], this.validatedDocumentsData[i].outputFilename, { type: fileMimeType });
				
				requestData.append("attachments_list", entryFile);
				docTypes.push(this.validatedDocumentsData[i].documentType.id);
				workerIds.push(this.validatedDocumentsData[i].workerId);

				filesToSendNumber++;
			}

			if (filesToSendNumber == 0){
				this.$emit("set-state", "DEFAULT");
				return;
			}

			requestData.append("workers_ids", workerIds);
			requestData.append("override_existing_files", this.attachmentOptions.overrideExistingFiles.value);
			requestData.append("upload_with_document_type", this.attachmentOptions.uploadWithDocumentType.value);
			
			if (this.attachmentOptions.uploadWithDocumentType.value){
				requestData.append("documents_types", docTypes);
			}
			requestData.append("default_document_type", this.defaultDocumentType);

			this.uploadingProgressBar.uploadProgress = 0;
			this.uploadingProgressBar.indeterminate = false;
			this.uploadingProgressBar.message = this.lview.uploadBarUploading;
			let responsePromise = axios({
				url: localStorage.getItem("current_env") + "/api/v2/worker-documents/upload",
				method: "POST",
				headers: {
					'Content-Type': 'multipart/form-data',
					'Authorization': 'Bearer ' + localStorage.getItem('jwt')
				},
				data: requestData,
				onUploadProgress: (progressEvent) => {
					if (progressEvent.lengthComputable) {
					   console.log(progressEvent.loaded + ' ' + progressEvent.total);
					   if (progressEvent.loaded == progressEvent.total){
							this.uploadingProgressBar.uploadProgress = 0;
							this.uploadingProgressBar.indeterminate = true;
							this.uploadingProgressBar.message = this.lview.uploadBarProcessing;
					   }
					   else{
						   let progress = (progressEvent.loaded * 100) / progressEvent.total;
						   this.uploadingProgressBar.uploadProgress = progress;
					   }
					}
				}
			});
			try {
				let response = await responsePromise;
				this.uploadingProgressBar.uploadProgress = 100;
				this.uploadingProgressBar.indeterminate = false;
				this.uploadingProgressBar.message =  this.lview.uploadBarComplete;
				this.$emit("set-state", "DEFAULT");
				if (response.status == 201) {
					if (response.data.unsaved_files != undefined){
						// render datatables with information whether file was uploaded or not
						// assign datatables
						this.pageState = this.pageStates.FILES_UPLOADED_WITH_ERRORS;
						this.unsavedFilesFromUploadHeaders[0].text = this.lview.filename;
						this.unsavedFilesFromUploadHeaders[1].text = this.lview.errorContent;
						this.unsavedFilesFromUpload = [];

						for(var i = 0; i < response.data.unsaved_files.length; i++) {
							this.unsavedFilesFromUpload.push(
								{
									name: response.data.unsaved_files[i].filename,
									error: response.data.unsaved_files[i].error_message
								}
							)
						}
					}
					else {
						this.pageMessages.FILES_UPLOADED.globalMessage = this.lview.filesUploadedCorrectly.replace("{0}", this.pageMessages.ZIP_VALIDATED.documentsToAdd.number.toString());
						//this.pageMessages.FILES_UPLOADED.globalMessage = this.lview.filesUploadedCorrectly;
						this.pageState = this.pageStates.FILES_UPLOADED_CORRECTLY;
					}
					this.summaryUuid = response.data.summary_uuid;
				}
			} catch(error) {
				this.$emit('set-state', "DEFAULT");
				if (error.message == 'Network Error') {
					this.$emit('set-state', "ERROR", this.lview.networkError);
				} else {
					switch(error.response.status){
						case 403:
							this.$emit('set-state', "ERROR", this.lview.lackOfPermission);
							break;
						case 400:
							this.$emit('set-state', "ERROR", this.lview.couldNotProcessAllSentFiles);
							break;
						default:
							this.$emit('set-state', "ERROR");
							break;
					}
				}
				return;
			}
		},

		// assigns messages for ZIP_VALIDATED view state
		assignZipValidatedLangData: function(){
			this.pageMessages.ZIP_VALIDATED.uploadedZip = this.lview.validatedZip.uploadedZip;
			this.pageMessages.ZIP_VALIDATED.documentsToAdd.label = this.lview.validatedZip.documentsToAdd.label;
			this.pageMessages.ZIP_VALIDATED.filtersHeader = this.lview.validatedZip.filtersHeader;
			this.pageMessages.ZIP_VALIDATED.selectFilesStatuses.label = this.lview.validatedZip.selectFilesStatuses.label;
			this.pageMessages.ZIP_VALIDATED.selectFilesStatuses.items = this.lview.validatedZip.selectFilesStatuses.items;
		
			this.validatedDocumentsDataHeaders[0].text = this.lview.validatedZip.tableHeaders.id;
			this.validatedDocumentsDataHeaders[1].text = this.lview.validatedZip.tableHeaders.inputFilename;
			this.validatedDocumentsDataHeaders[2].text = this.lview.validatedZip.tableHeaders.workerName;
			this.validatedDocumentsDataHeaders[3].text = this.lview.validatedZip.tableHeaders.outputFilename;
			this.validatedDocumentsDataHeaders[4].text = this.lview.validatedZip.tableHeaders.documentType;
			this.validatedDocumentsDataHeaders[5].text = this.lview.validatedZip.tableHeaders.status;
			this.validatedDocumentsDataHeaders[6].text = this.lview.validatedZip.tableHeaders.comment;
			this.validatedDocumentsDataHeaders[7].text = this.lview.validatedZip.tableHeaders.action;
		
			this.pageMessages.ZIP_VALIDATED.cancelButtonLabel = this.lview.validatedZip.cancelHeader;
			this.pageMessages.ZIP_VALIDATED.addDocumentsButtonLabel = this.lview.validatedZip.addDocumentsButtonLabel;
			this.pageMessages.ZIP_VALIDATED.uploadWithoutErrorsMessage = this.lview.validatedZip.uploadWithoutErrorsMessage;
			this.pageMessages.ZIP_VALIDATED.goBack = this.lview.validatedZip.goBack;
			this.pageMessages.ZIP_VALIDATED.search = this.lview.validatedZip.search;

			this.pageMessages.ZIP_VALIDATED.status = this.lview.validatedZip.selectFilesStatuses.label;
			this.documentStatesSelect[0].text = this.lview.validatedZip.selectFilesStatuses.ok;
			this.documentStatesSelect[1].text = this.lview.validatedZip.selectFilesStatuses.warning;
			this.documentStatesSelect[2].text = this.lview.validatedZip.selectFilesStatuses.error;
		},

		// assigns messages for VALIDATION and VALIDATION_ERROR views states
		assignZipValidationLangData: function(){
			this.pageZipErrorMessageHeader = this.lview.validation.pageZipErrorMessageHeader;
			this.pageMessages.VALIDATION.overlayerMessage = this.lview.validation.overlayerMessage;
			this.pageMessages.VALIDATION.excelLackMessage = this.lview.validation.excelLackMessage;
			this.pageMessages.VALIDATION.tooLargeZipLoadedMessage = this.lview.validation.tooLargeZipLoadedMessage;
			this.pageMessages.VALIDATION.invalidZipStructure = this.lview.validation.invalidZipStructure;
			this.pageMessages.VALIDATION.correctFiles = this.lview.validation.correctFiles;
			this.pageMessages.VALIDATION.goBack = this.lview.validation.goBack;
		},

		adaptDocumentTypesSelectData: function(docTypesResponse) {
			this.documentTypesSelect = [];
			for(let i = 0; i < docTypesResponse.document_types.length; i++) {
				this.documentTypesSelect.push({
					value: docTypesResponse.document_types[i].id,
					text: docTypesResponse.document_types[i].name
				});
			}
		}
	},
	computed: {
		lview: {
			get: function(){
				return this.$t('views.importDocuments');
			}
		},
		lmessages: {
			get: function() {
				return this.$t('commons.messages');
			}
		}
	},
	mounted: async function(){
		this.$emit('set-title', this.lview.title);
		this.pageState = this.pageStates.BEFORE_UPLOAD;

		this.$emit('set-display', "BACK_WINDOW"); // Was it really that hard to add this line?

		this.pageMessages.BEFORE_UPLOAD = this.lview.beforeUpload;
		this.assignZipValidatedLangData();
		this.assignZipValidationLangData();
		this.pageMessages.INVALID_INPUT_FILE.globalMessage = this.lview.invalidInputFileGlobalMessage;

		this.attachmentOptions.omitHeaders.label = this.lview.omitHeadersOption;
		this.attachmentOptions.overrideExistingFiles.label = this.lview.overrideExistingFiles;
		this.attachmentOptions.uploadWithDocumentType.label = this.lview.uploadWithDocumentTypeOption;

		let response = null;
		try {
			response = await axios({
				"url": fob_config.api_url + "/api/v2/worker-documents/document-types",
				"method": "GET",
				"headers": {
					"Authorization": "Bearer " + localStorage.getItem("jwt")
				}
			});
		} catch (error) {
			this.documentTypesList = [];
		}

		if (response != null) {
			this.adaptDocumentTypesSelectData(response.data);
			if (this.documentTypesSelect.length > 0) {
				this.defaultDocumentType = this.documentTypesSelect[0].value;
			}
		}

		// checkbox
		if (localStorage.getItem("omitHeadersCheckbox") == null || localStorage.getItem("omitHeadersCheckbox") == 0) {
			this.attachmentOptions.omitHeaders.value = false;
		}
		else {
			this.attachmentOptions.omitHeaders.value = true;
		}
 

		if (localStorage.getItem("uploadWithDocumentTypeCheckbox") == null || localStorage.getItem("uploadWithDocumentTypeCheckbox") == 0) {
			this.attachmentOptions.uploadWithDocumentType.value = false;
		}
		else {
			this.attachmentOptions.uploadWithDocumentType.value = true;
		}
		
		if (localStorage.getItem("overrideExistingFilesCheckbox") == null || localStorage.getItem("overrideExistingFilesCheckbox") == 0) {
			this.attachmentOptions.overrideExistingFiles.value = false;
		}
		else {
			this.attachmentOptions.overrideExistingFiles.value = true;
		} 
	},
}
</script>

<style lang="css">
.import-documents-page-container{
	height: 100%;
	width: auto;
	padding-top: 35px;
	padding-left: 10px;
	padding-right: 10px;
}

.attach-files-container{
	width: 100% !important;
	text-align: center;
}

.attach-files-input{
	background-color: #F5F5F6 !important;
	max-height: 550px;
	padding-bottom: 20px;
	border-radius: 10px;
	border: dashed 2px silver;
}

.attach-files-input-header{
	font-size: 20px;
	font-weight: 450;
}

.attachments-process-option{
	font-size: 10px !important;
	margin-right: 10px;
	margin-top: 0px !important;
	margin-left: 15px;
}

.attachments-process-option-select{
	font-size: 15px !important;
	margin-right: 10px;
	margin-top: 0px !important;
	margin-left: 15px;
	max-width: 200px !important;
}

.zip-validated-page{
	background-color: #F5F5F6 !important;
	width: 100%;
	border-radius: 10px;
	border: dashed 2px silver;
	margin-bottom: 15px;
}

.zip-validated-page-header{
	font-size: 16px;
	font-weight: 450;
	padding-top: 7px;
	padding-bottom: 2px !important;
}

.zip-validated-page-header-part{
	width: 50%;
	float: left;
	padding-bottom: 0px !important;
}

.imported-documents-table{
	width: auto;
	margin-left: 15px;
	margin-right: 15px; 
	margin-bottom: 15px;
	background-color: white;
}

.imported-documents-table-sorters{
	background-color: white;
	border-bottom: lightgray 2px solid;
	height: 35px;
	width: 100% !important;
}

.zip-validated-header{
	float: left;

}

.zip-validated-page-footer{
	font-size: 16px;
	font-weight: 450;
	padding-top: 15px;
	padding-bottom: 30px !important;
	text-align: center;
}

.row-deleted{
	text-decoration: line-through;
}

.row-to-import{
	text-decoration: none;
}

.row-deleted-background{
	/*background-color: gray;*/
}

.row-deleted-background:hover{
	/*background-color: gray;*/
}

.back-button-files-uploaded {
	height: 30px !important;
}
</style>