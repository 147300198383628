export default {
    app: {
        drawer: {
            emailUnassinged: "E-Mail nicht zugewiesen",
            manager: "Leiter",
            myProfile: "Mein Profil",
            about: "Über Fast OnBoarding",
            logout: "Ausloggen",
            profile: "Profil",
            documents: "Mitarbeiterdokumente",
            workersDocuments: "Mitarbeiterdokumente",
            settings: "Einstellungen",
            employees: "Mitarbeiter",
            boardings: "Boarding",
            myDocuments: "Meine Unterlagen",
            administration: "Verwaltung",

            officeData: "Unternehmensdaten",
            settlements: "Siedlungen",
            dictionaries: "Wörterbücher",
            permissions: "Berechtigungen",
            users: "Benutzer",
            unknown: "Unbekannt",
            permissions: "Berechtigungen",
            addresses: "Addressen",
        },
        bottomNav: {
            boarding: "Boarding",
            employees: "Mitarbeiter",
            profile: "Profil",
            documents: "Unterlagen"
        },
        pageNotFoundDetails: "Gewünschte Seite existiert nicht oder wurde gelöscht. Die Anwendung wurde an die voreingestellte Hauptseite umgeleitet."
    },
    views: {
        commons: {
            alertWithDetails: {
                details: "Details"
            },
            errorDialog: {
                copyError: "Den Fehler kopieren",
                copied: "Wurde kopiert",
                failedToCopy: "Fehler beim Kopieren",
                problemWithLoadingPage: "Es gab ein Problem beim Laden der Seite. Versuchen Sie noch einmal."
            },
            confirmBoardingDialog: {
                title: "Beschäftigungsbestätigung",
                chooseProjects: "Projekte wählen",
                startDate: "Anfangsdatum",
                checkbox1: "Unterschriebener Vertrag",
                checkbox2: "Vollständiger Fragebogen",
                checkbox3: "Ärztlicher Befund und Rechnung",
                checkbox4: "Führungszeugnis",

                uploading: "Senden",
                tooManyWorkersForThisAction: "Zu viele Mitarbeiter wurden für diese Aufgabe ausgewählt.",
            },
            resignDialog: {
                title: "Rücktritt des Mitarbeiters",
                pickProjects: "Projekte wählen",
                resignDate: "Datum der Rücktritts",
                offBoardingDate: "Offboardingsdatum",
                contractEndDate: "Datum der Kontraktsauflösung",
                projectEndDate: "Abschlussdatum des Projekts",
                chooseProjects: "Projekte wählen",
                reason: "Grund",
                selectForResignReasons: [
                    "Nichtaufnahme der Arbeit",
                    "Porzucenie pracy",
                    "Gütliche Entlassung",
                    "Entlassung aus disziplinarischen Gründen",
                    "Beendeter Vertrag",
                    "Mit Auszeihnung beendeter Vertrag "
                ],
                ratingComment: "Bewertungskommentar",
                errorOccured: "Ein Fehler ist aufgetreten",
                errorMessage: "Beim Verarbeiten der Daten für das Boarding mit der ID ist ein Fehler aufgetreten:",
                errorCodeShort: "RSGND#5",
                errorCodeLong: "REQUEST_FAILED"
            },
            removeBoardingDialog: {
                title: "Boardings löschen",
                chooseProjects: "Projekte wählen"
            },
            changeBoardingPlansDialog: {
                title: "Planänderung",
                chooseProject: "Projekte wählen",
                chooseWorker: "Mitarbeiter wählen",
                changeManager: "Den Leiter wechseln",
                changeDate: "Datumsänderung",
                reason: "Grund",
                errorOccured: "Ein Fehler ist aufgetreten",
                errorMessage: "Beim Verarbeiten der Daten für den Mitarbeiter mit der ID ist ein Fehler aufgetreten: ",
                errorCodeShort: "CHNGBRD#1",
                errorCodeLong: "REQUEST_FAILED"
            },
            changeEmployeePlansDialog: {
                title: "Planänderung",
                changeLocation: "Standortwechsel",
                changeProject: "Neues Projekt",
                changeManager: "Den Leiter wechseln",
                reason: "Grund"
            },
            sendEmployeeMessageDialog: {
                title: "Kurze Nachricht",
                cancel: "Abbrechen",
                send: "Senden"
            },
            offBoardingDialog: {
                title: "Rücktritt des Mitarbeiters",
                pickProjects: "Projekte wählen",
                offBoardingDate: "Offboardingsdatum",
                reason: "Grund",
                ratingComment: "Bewertungskommentar",
                errorNoProjectsAssignedTitle: "Keine Projekte zugewiesen",
                errorNoProjectsAssignedMessage: "Für dieses Bearbeitungsfenster wurden keine Projekte ausgewählt.",
                errorOccured: "Ein Fehler ist aufgetreten",
                errorMessage: "Beim Verarbeiten der Daten für den Mitarbeiter mit der ID ist ein Fehler aufgetreten: ",
                errorCodeLong: "REQUEST_FAILED"
            },
            attachPicturesDialog: {
                defaultTitle: "Bilder beifügen",
                attachFromCamera: "Ein Foto machen",
                attachFromFiles: "Datei beifügen",
                noFilesAttached: "Keine Dateien beigefügt",
                tooManyFilesTitle: "Zu viele Dateien",
                tooManyFilesText: "Sie versuchen {numberOfFiles} beizufügen. Maximale Anzahl von Dateien beträgt {maxNumberOfFiles}.",
                totalFilesSizeTooBigTitle: "Dateigrößenbeschränkung wurde überschritten",
                totalFilesSizeTooBigText: "Die Dateien, die Sie beifügen wollen, sind zu groß. Die maximal zulässige Größe beträgt {maxAttachmentsSize}.",
                fileTooBigTitle: "Dateigrößenbeschränkung wurde überschritten.",
                fileTooBigText: "Die folgenden Dateien überschreiten das Größenlimit ({sizeLimit})",
            },
            postalAddressEditDialog: {
                defaultHeadline: "Bearbeitung der Adresse",
                dictionaryLocation: "Beschreibung",
                streetName: "Straße",
                streetNumber: "Nummer",
                propertyNumber: "Wohnung",
                zipCode: "Postleitzahl",
                city: "Stadt",
                country: "Staat",

                invalidLocation: "Falscher Standort",
                missingLocationErrorTitle: "Unbekannter Standort",
                missingLocationError: "Der ausgewählte Standort wurde im Wörterbuch nicht gefunden.",
            },
            extendOffboardingDialog: {
                defaultHeadline: "Off-Boarding verlängern",
                chooseProjects: "Projekte wählen",
                pickNewOffBoardingDate: "Neues Off-Boarding-Datum wählen"
            },
            nullableRating: {
                rating: "Bewertung",
                none: "Keiner"
            }
        },
        login: {
            username: "Benutzer",
            password: "Kennwort",
            login: "Einloggen",
            forgotPassword: "Passwort vergessen?",
            invalidLoginOrPassword: "Ungültiger Benutzername oder Kennwort.",
            accountNotActivated: "Konto wurde nicht aktiviert",
            loginMethodBlockedByAdministrator: "Die Anmeldemethode wurde vom Administrator deaktiviert",
            invalidAccountConfig: "Kontokonfiguration ungültig, wenden Sie sich an den Administrator.",
            accountDisabled: "Das Konto wurde vorübergehend deaktiviert. Wenden Sie sich an den Administrator.",
            multipleAccountsAssignedToEmail: "Anmeldung über E-Mail-Adresse nicht möglich. Diese E-Mail-Adresse ist mehreren Konten zugeordnet",
            unassignedUserProfile: "Benutzerprofil nicht zugewiesen. Kontaktieren Sie den Administrator",
            unassignedWorker: "Falsch konfiguriertes Konto. Kontaktieren Sie den Administrator",
            couldNotLoginNoPermissions: "Sie haben keine Berechtigungen. Bitte kontaktieren Sie den Administrator",
            error429message: "Zu viele Anfragen mit einer nicht autorisierten Verbindung. Bitte warten Sie einen Moment, bevor Sie es erneut versuchen.",

            unknownError: "Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es später noch einmal.",
            missingInput: "Benutzernamen und Kennwort eingeben",
            infoRegulations: "Vorschriften",
            infoPrivacy: "Datenschutzerklärung",
            infoHelp: "Hilfe",

            selectedLanguage: "Deutsch",
            language: "Sprache",
            languageSelect: [
                "Polnisch",
                "Englisch",
                "Deutsch"
            ],
            languageDictionary: [{
                    language: "Polnisch",
                    key: "pl"
                },
                {
                    language: "Englisch",
                    key: "en"
                },
                {
                    language: "Deutsch",
                    key: "de"
                }
            ]
        },
        passwordReset: {
            yourEmail: "E-Mail-Adresse",
            birthDate: "Geburtsdatum",
            resetPassword: "Kennwort zurücksetzen",
            cancelBack: "Abbrechen / Rückkehr",
            okBack: "OK / Rückkehr",
            needHelp: "Sie benötigen Hilfe?",
            linkSent: "Wir haben Ihnen eine E-Mail mit einem Link zum Zurücksetzen Ihres Kennwort verschickt, prüfen Sie Ihre Mailbox.",
            couldNotResetPassword: "Falsche E-Mail oder falsches Geburtsdatum",
            sendMailError: "Mail-Fehler senden. Versuchen Sie es in ein paar Minuten erneut.",
            emailNotProvided: "E-Mail wurde nicht angegeben.",
            birthDateNotProvided: "Geburtsdatum wurde nicht angegeben.",
            incorrectEmailStructure: "Falsche E-Mail-Struktur.",
        },
        accountActivation: {
            title: "Aktivierung des Benutzerkontos",
            politicsAgreement1: "Ich erkläre, dass ich die geltenden Datenschutzbestimmungen gelesen habe und mit ihnen einverstanden bin.",
            politicsAgreement2: "Ich erkläre, dass ich die Nutzungsbedingungen gelesen habe und mit ihnen einverstanden bin.",
            markAgreementsToContinue: "Kreuzen Sie die Kästchen an um die App zu verwenden.",
            activatingAccount: "Kontoaktivierung...",
            activationFailed: "Die Aktivierung ist fehlgeschlagen.",
            repeatPassword: "Passwort wiederholen",
            password: "Passwort",
            activationLinkOutdated: "Ihr Link ist veraltet, Sie können einen neuen per E-Mail erhalten.",
            birthDate: "Geburtsdatum",
            label1: "1. Passwort für Ihr Konto festlegen",
            descriptionLabel1: "Um Ihr Passwort festzulegen, geben Sie Ihr Geburtsdatum ein und geben Sie das Passwort zweimal ein. Das Passwort muss mindestens 8 Zeichen enthalten (davon mindestens 1 Großbuchstabe, 1 Ziffer oder Sonderzeichen).",
            label2: "2. Nutzungsbedingungen akzeptieren ",
            birthDateRequired: "Geburtsdatum ist erforderlich",
            invalidPasswordStructure: "Das Passwort muss mindestens 8 Zeichen enthalten (davon mindestens 1 Großbuchstabe, 1 Ziffer oder Sonderzeichen).",
            incorrectlyRepeatedPassword: "Passwort falsch wiederholt",
            incorrectBirthDate: "Falsches Geburtsdatum. Du hast noch %d mögliche Versuche",
            errorTitle: "Ein Fehler ist aufgetreten",
            error500message: "Ein interner Serverfehler ist aufgetreten. Bitte wenden Sie sich an den Administrator.",
            error404message: "Kein zu aktivierendes Benutzerkonto gefunden - ungültiger Aktivierungslink.",
            error403message1: "Konto konnte nicht aktiviert werden - bitte versuchen Sie es später noch einmal.",
            error403message2: "Das bereits aktivierte Konto konnte nicht aktiviert werden.",
            error400message1: "Konto konnte nicht aktiviert werden - falsche Daten.",
            error400message2: "Versuche zur Kontoaktivierung überschritten, bitte versuchen Sie es später erneut.",
            error400accountActivate: "Konto ist bereits aktiv",
            error429message: "Zu viele Anfragen mit einer nicht autorisierten Verbindung. Bitte warten Sie einen Moment, bevor Sie es erneut versuchen.",
            resendActivationLink: "Neuen Aktivierungslink generieren",
            couldNotSendNewEmail: "Neuer Aktivierungslink konnte nicht gesendet werden.",
            newActivationLinkSent: "Neuer Aktivierungslink gesendet, überprüfen Sie Ihr Postfach.",
            birthDateNotProvided: "Geburtsdatum wurde nicht angegeben.",
            tryAgainInAnHour: "Ihr Konto wurde für eine Stunde gesperrt. Versuchen Sie es später noch einmal.",
            gotoLoginPage: "Zur Login-Seite gehen"
        },
        about: {
            pageTitle: "Über Fast OnBoarding",
            header: "IHR IT-MOTOR FÜR DAS GESCHÄFT",
            version: "Version",
            privacyPolicy: "Datenschutz",
            learnMore: "Erfahren Sie mehr",
            contact: "Kontaktieren Sie uns / Fordern Sie Hilfe an"
        },
        qr: {
            pageTitle: "Mein QR-Code",
        },
        employeesPM: {
            pageTitle: "Mitarbeiter",
            myEmployees: "Meine Mitarbeiter",
            unassigned: "Nicht zugewiesen",
            otherManagers: "Andere Leiter",
            search: "Aussuchen",
            nothingFound: "Keine Suchergebnisse gefunden",

            today: "Heute",
            yesterday: "Gestern",
            tomorrow: "Morgen",

            editMenu: {
                edit: "Bearbeiten"
            },

            sortBy: "Sortieren nach",
            sortByDate: "Datum",
            sortByProject: "Projekt",
            sortByManager: "Leiter",

            warningDifferentOffBoardingDates: "Datensätze haben unterschiedliche Off-Boarding-Daten.",

            alertWorkerNotValidForEditing: "Einer der ausgewählten Mitarbeiter kann nicht bearbeitet werden.",
            alertWorkerNotValidForEditingDetails: "Mindestens einer der ausgewählten Mitarbeiter kann nicht bearbeitet werden. Diese Warnung wurde für den folgenden Mitarbeiter angezeigt:<br /><br /><b>{worker}</b><br /><br />Grund: Dieser Mitarbeiter hat mehr zugewiesene Boardings für dasselbe Projekt.<br /><br />Sie können Boardings dieses Mitarbeiters immer noch mit Hilfe des Bildschirms manipulieren. \"Projekty\". Sie können dieses Problem auch dort lösen.",

            errorLocalDataInconsistencyTitle: "Unvereinbarkeit von Hierarchiedaten (kein Leiter)",
            errorLocalDataInconsistencyMessage: "Lokal gespeicherte Daten sind falsch. Versuchen Sie sich erneut einzuloggen.",

            errorInvalidWorkerTitle: "Falscher Mitarbeiter",
            errorInvalidWorkerMessage: "Der ausgewählte Mitarbeiter ist falsch oder seine Daten wurden beschädigt.",
            errorNoProjectsToDisplayTitle: "Keine Projekte zum Anzeigen",
            errorNoProjectsToDisplay: "Ausgewählt wurden Mitarbeiter, die keine gemeinsamen Projekte haben. Sie können nur dann mehrere Mitarbeiter gleichzeitig manipulieren, wenn sie mindestens ein Projekt teilen.",
            specialSigns: [{
                    spec: 'Ä',
                    norm: 'A'
                },
                {
                    spec: 'Ö',
                    norm: 'O'
                },
                {
                    spec: 'ẞ',
                    norm: 'SS'
                },
                {
                    spec: 'Ü',
                    norm: 'U'
                }
            ],

            youDoNotHavePriviledgesToThisView: "Sie können nicht auf diese Ansicht zugreifen",
        },
        onBoardingPM: {
            pageTitle: "Boarding",
            nothingFound: "Keine Suchergebnisse gefunden.",

            today: "Heute",
            yesterday: "Gestern",
            tomorrow: "Morgen",
            nodate: "Kein Datum",

            projectUnassigned: "Nich zugewiesen",

            filter: "Filter",

            sortBy: "Sortieren nach",
            sortByDate: "Datum",
            sortByProject: "Projekt",
            sortByManager: "Leiter",

            filterAll: "Alles",

            tillToday: "Bis heute",
            thisWeek: "Diese Woche",
            later: "Später",

            unassigned: "Nicht zugewiesen",
            noProject: "Nicht zugewiesen",
            myEmployees: "Meine Mitarbeiter",

            newBoarding: {
                title: "Neues Boarding",
                firstName: "Vorname",
                lastName: "Name",
                email: "E-Mail",
                pesel: "IdNr.",
                boardingDate: "Datum des Boardings",
                project: "Projekt",
                noPesel: "Keine IdNr."
            },

            editMenu: {
                edit: "Bearbeiten"
            },

            alertWorkerNotValidForEditing: "Einer der ausgewählten Mitarbeiter kann nicht bearbeitet werden",
            alertWorkerNotValidForEditingDetails: "Mindestens einer der ausgewählten Mitarbeiter kann nicht bearbeitet werden. Diese Warnung wurde für den folgenden Mitarbeiter angezeigt:<br /><br /><b>{worker}</b><br /><br />Grund: Dieser Mitarbeiter hat mehr zugewisene Boardings für dasselbe Projekt.<br /><br />Sie können Boardings dieses Mitarbeiters immer noch mit Hilfe des Bildschirms manipulieren. \"Projekty\". Sie können dieses Problem auch dort lösen.",

            errorEmailAlreadyExistsTitle: "E-Mail bereits zugewiesen",
            errorEmailAlreadyExists: "Ein Mitarbeiter mit dieser E-Mail-Adresse ist bereits in der Datenbank gespeichert",
            errorPeselAlreadyExistsTitle: "IdNr. bereits zugewiesen",
            errorPeselAlreadyExists: "Ein Mitarbeiter mit dieser E-Mail-Adresse ist bereits in der Datenbank gespeichert",
            wantToAddBoardingForExistingWorker: "Wollen Sie das Boarding für diesen Mitarbeiter hinzufügen?",
            errorProjectAlreadyAssigned: "Mindestens eines der ausgewählten Projekte ist diesem Mitarbeiter bereits zugewiesen.",
            errorNoProjectsToDisplayTitle: "Keine Projekte zum Anzeigen",
            errorNoProjectsToDisplay: "Ausgewählt wurden Mitarbeiter, die keine gemeinsamen Projekte haben. Sie können nur dann mehrere Mitarbeiter gleichzeitig manipulieren, wenn sie mindestens ein Projekt teilen.",
            errorNetworkOccured: "Ein Fehler ist aufgetreten",
            errorMessage: "Netzwerkfehler. Bitte versuche es erneut.",
            errorCodeShort: "ADNBRD",
            errorCodeLong: "REQUEST_FAILED",

            youDoNotHavePriviledgesToThisView: "Sie können nicht auf diese Ansicht zugreifen",
        },
        userProfile: {
            myProfile: "Mein Profil",
            workerProfile: "Profil",
            inSince: "Beim Fast OnBoarding seit {since}",
            unassigned: "Nicht zugewiesen",
            permanentContract: "Auf unbestimmte Zeit",
            unassignedProject: "Nicht zugewiesen",
            sinceDateToDate: "vom {since} bis zum {to}",
            sinceDate: "seit {since}",

            editRating: "Bewertung bearbeiten",
            ratingComment: "Bewertungskommentar",

            contact: "Kontakt",
            privatePhone: "Private Telefonnummer",
            businessPhone: "Dienstliche Telefonnummer",
            chooseCountry: "Wähle ein Land",
            privateMail: "Private E-Mail-Adresse",
            businessMail: "Dienstliche E-Mail-Adresse",

            personalDetails: "Personaldaten",
            birthDate: "Geburtsdatum",
            personalAddress: "Wohnsitz",
            pesel: "IdNr.",
            drivingLicense: "Führerschein",
            contactAddressInformation: "Informationen zur Kontaktadresse",
            additionalInformation: "Zusätzliche Informationen",
            otherData: "Sonstige Daten",
            noData: "Keine",

            contractDetails: "Vertragsdaten",
            beginDate: "Anfangsdatum",
            endDate: "Enddatum",

            stayDetails: "Aufenthaltsdaten",
            stayAddress: "Aufenthaltsadresse",

            contractEndDateQuestionTitle: "Vertragsverlängerung",
            contractEndDateQuestionContent: "Bestätigen Sie die Vertragsverlängerung des Mitarbeiters vom {oldDate} bis zum {newDate}",

            age: "Alter",
            edit: "Bearbeitung",
            close: "Beenden",
            seeMoreProjects: "Weitere Projekte ansehen",
            projectsList: "Liste der Projekte",

            location: {
                streetName: "Straße",
                streetNumber: "Straßennummer",
                propertyNumber: "Wohnungsnummer",
                zipCode: "Postleitzahl",
                city: "Stadt",
                country: "Staat"
            },

            lackOfContactPostalAddress: "Keine - wählen Sie eine Wohnung",

            countriesSelect: [{
                    key: "AL",
                    text: "Albanien"
                },
                {
                    key: "AD",
                    text: "Andorra"
                },
                {
                    key: "AT",
                    text: "Österreich"
                },
                {
                    key: "BE",
                    text: "Belgien"
                },
                {
                    key: "BY",
                    text: "Belaruss"
                },
                {
                    key: "BA",
                    text: "Bosnien und Herzegowina"
                },
                {
                    key: "BG",
                    text: "Bulgarien"
                },
                {
                    key: "HR",
                    text: "Kroatien"
                },
                {
                    key: "ME",
                    text: "Montenegro"
                },
                {
                    key: "CZ",
                    text: "Tschechien"
                },
                {
                    key: "DK",
                    text: "Dänemark"
                },
                {
                    key: "EE",
                    text: "Estland"
                },
                {
                    key: "FI",
                    text: "Finnland"
                },
                {
                    key: "FR",
                    text: "Frankreich"
                },
                {
                    key: "GR",
                    text: "Griechenland"
                },
                {
                    key: "ES",
                    text: "Spanien"
                },
                {
                    key: "NL",
                    text: "Die Niederlande"
                },
                {
                    key: "IE",
                    text: "Irland"
                },
                {
                    key: "IS",
                    text: "Island"
                },
                {
                    key: "KZ",
                    text: "Kasachstan"
                },
                {
                    key: "XK",
                    text: "Kosovo"
                },
                {
                    key: "LI",
                    text: "Liechtenstein"
                },
                {
                    key: "LT",
                    text: "Litauen"
                },
                {
                    key: "LV",
                    text: "Lettland"
                },
                {
                    key: "MK",
                    text: "Mazedonien"
                },
                {
                    key: "MT",
                    text: "Malta"
                },
                {
                    key: "MD",
                    text: "Moldau"
                },
                {
                    key: "MC",
                    text: "Monaco"
                },
                {
                    key: "DE",
                    text: "Deutschland"
                },
                {
                    key: "NO",
                    text: "Norwegen"
                },
                {
                    key: "PL",
                    text: "Polen"
                },
                {
                    key: "PT",
                    text: "Portugal"
                },
                {
                    key: "RU",
                    text: "Russland"
                },
                {
                    key: "RO",
                    text: "Rumänien"
                },
                {
                    key: "SM",
                    text: "San Marino"
                },
                {
                    key: "XS",
                    text: "Serbien"
                },
                {
                    key: "SK",
                    text: "Slowakei"
                },
                {
                    key: "SI",
                    text: "Slowenien"
                },
                {
                    key: "CH",
                    text: "Schweiz"
                },
                {
                    key: "SE",
                    text: "Schweden"
                },
                {
                    key: "TR",
                    text: "Türkei"
                },
                {
                    key: "UA",
                    text: "Ukraine"
                },
                {
                    key: "HU",
                    text: "Ungarn"
                },
                {
                    key: "GB",
                    text: "Großbritannien"
                },
                {
                    key: "IT",
                    text: "Italien"
                },
            ],

            youDoNotHavePriviledgesToThisView: "Sie können nicht auf diese Ansicht zugreifen",
        },
        documents: {
            title: "Unterlagen",
            myDocumentsTitle: "Meine unterlagen",
            correctUploadInfo: "Fotos sind beigefügt",
            errorUploadInfo: "Beim Versuch, das Bild anzuhängen, ist ein Fehler aufgetreten",
            attachPicturesInfo: "Fotos beifügen",
            noDocuments: "Keine dokumente gefunden",
            noMatchingDocumentsFound: "Keine passenden dokumente gefunden",
            couldNotDownloadDocument: "Dokument konnte nicht heruntergeladen werden",
            search: "Suche",
            groupBy: "Gruppiere nach",
            sortBy: "Sortiere nach",
            name: "Name",
            date: "Datum",
            download: "Herunterladen",
            fromMostRecent: "Von den neuesten",
            fromLeastRecent: "Von den letzten",
            alphabeticalAscending: "Alphabetisch A-Z",
            alphabeticalDescending: "Alphabetisch A-Z",
            dontGroup: "Nicht gruppieren",
            byType: "Typ",
            byDate: "Datum",
            lastWeek: "Letzte Woche",
            lastMonth: "Im vergangenen Monat",
            lastYear: "Vergangenes Jahr",
            elder: "Älter",
            downloadingDocuments: "Dokumente herunterladen",
            areYouSureYouWantDownloadAllDocuments: "Sind Sie sicher, dass Sie alle Dokumente herunterladen möchten",
            cancel: "Stornieren",

            workersDocuments: "Mitarbeiterdokumente",
            workerFullName: "Vollständiger Name des Arbeitnehmers",
            workerDateBirth: "Geburtsdatum des Arbeitnehmers",
            documentCreatedAt: "Hergestellt in",
            documentTypeShort: "Typ",
            documentTypeLong: "Art des Dokuments",
            documentType: "Typ",
            documentName: "Dokumentname",
            allTypesSelected: "Alle",
            chooseDataTableColumns: "Tabellenspalten auswählen",
            modify: "Ändern",
            preview: "Vorschau",

            save: "Speichern",
            changeDocumentName: "Dokumentnamen ändern",
            changeDocumentType: "Dokumentart ändern",
            updateDocumentFile: "Vorhandenes dokument ersetzen",

            update: "Existierendes ersetzen",
            changeType: "Typ ändern",
            chooseNewFile: "Wählen Sie eine neue Datei",
            lackOfPermission: "Du darfst nicht hier sein",
            incorrectRequest: "Falsche Anfragedaten",

            recordNotModified: "Dokument nicht aktualisiert",
            recordUpdatedSuccessfully: "Dokument aktualisiert",
            lackOfPermissionToUpdateDocument: "Sie dürfen kein Dokument aktualisieren",

            couldNotAddNewDocument: "Das angegebene dokument konnte nicht angehängt werden",
            documentAddedSuccessfully: "Dokument erfolgreich angehängt",
            addNewDocument: "Neues Dokument anhängen",
            chooseWorker: "Arbeiter auswählen",
            startWritingToFind: "Schreib etwas zum Suchen",
            overrideExistingFiles: "Vorhandene Dateien überschreiben",
            uploadingInProgress: "Hochladen läuft...",
            sorryWeCouldntConnectToServer: "Entschuldigung, wir konnten keine Verbindung zum Server herstellen",
            couldNotDownloadDocument: "Dokument konnte nicht heruntergeladen werden",
            importDocuments: "Dokumente importieren",
            rowsPerPage: "Objekte pro Seite",
            of: "von",

            delete: "Löschen",
            deleteDocument: "Löschen",
            areYouSureYouWantToDeleteThisDocument: "Möchten Sie dieses Dokument wirklich löschen?",
            assignedTo: "Zugewiesen an",
            documentName: "Dokumentname",
            birthDatePrefix: "geb. am",

            unassigned: "Nicht zugewiesen",
            duplicateFilenameTitle: "Eine Datei mit diesem Namen existiert bereits",
            proposedFilename: "Vorgeschlagener Name ",
            ok: "OK",
        },
        projects: {
            title: "Projekte",
            states: [{
                    name: "Aktiv",
                    stateKey: "A"
                },
                {
                    name: "Geplant",
                    stateKey: "P",
                },
                {
                    name: "Historisch",
                    stateKey: "H",
                }
            ],

            selectItems: [
                "Aktiv",
                "Historisch",
                "Geplant",
            ],

            addProject: "Projekt hinzufügen",
            comment: "Kommentar",
            project: "Projekt",
            type: "Art",
            assignmentStartDate: "Einstellungsdatum",
            onBoardingDate: "Onboardingsdatum",
            cancel: "Abbrechen",
            confirm: "Bestätigen",
            confirmEmployment: "Beschäftigung bestätigen",
            assuranceEmployment: "Sind Sie sicher, dass Sie die Beschäftigung dieses Mitarbeiters für dieses Projekt bestätigen wollen?",
            no: "Nein",
            yes: "Ja",
            removeBoarding: "Boarding entfernen",
            assuranceRemovement: "Sind Sie sicher, dass Sie das Boarding für diesen Mitarbeiter entfernen wollen? Er wird verschwinden, als er nie existiert hätte",
            employeeResignation: "Rücktritt des Mitarbeiters",
            offBoardingDate: "Offboardingsdatum",
            terminateEmployment: "Den Mitarbeiter entlassen",
            changeProject: "Das Projekt ändern",
            contractEndDate: "Datum des Vertragsauflösung",
            projectEndDate: "Datum des Abschlusses des Projekts",
            editBoarding: "Boarding bearbeiten",
            contractStartDate: "Datum der Vertragsunterzeichnung",
            projectStartDate: "Anfangsdatum des Projekts",
            unassigned: "Nicht zugewiesen",
            active: "Aktiv",
            historical: "Historisch",
            planned: "Geplant",
            fulltime: "Vollzeistelle",
            parttime: "Teilzeitstelle",
            timebased: "Zeitbasiert",
            fixedprice: "Normierte Vergütung",
            saving: "Speichern",
            errorOccuredTitle: "Fehler",
            errorOccured: "Ein Fehler ist aufgetreten. Das ist alles, was wir wissen.",
            cannotAddProjectTitle: "Das Projekt kann nicht hinzugefügt werden",
            cannotAddProjectMessage: "Diesem Mitarbeiter können keine Projekte zugeordnet werden",
            sendingRequest: "Ich schicke eine Anfrage",
            requestError: "Felher bei der Anfrage",
            requestErrorMessage: "Ein Fehler ist bei der Ausführung der Anfrage aufgetreten",
            noDate: "Kein Datum",
            cannotLoadPageTitle: "Fehler beim Laden der Seite",
            cannotLoadPageMessage: "Ein Fehler ist beim Laden der Seite aufgetreten",
            noInformation: "Keine Informationen",

            projectAdded: "Projekt hinzugefügt",
            projectUpdated: "Projekt aktualisiert",
            projectRemoved: "Projekt entfernt",
            changeWorkType: "Art der Arbeit bearbeiten",
            details: "Details zum Projekt",
            close: "Schließen",
        },
        settings: {
            title: "Einstellungen",
            settings: {
                preferences: {
                    title: "Vorlieben",
                    description: "Sprache ändern, Benachrichtigungen verwalten"
                },
                changePassword: {
                    title: "Sicherheit",
                    description: "Passwort ändern"
                }
            }
        },
        changePassword: {
            title: "Ändere das Passwort",
            headerPrefix: "Passwort für",
            pageContent: "Um das Passwort zu ändern, müssen Sie das alte Passwort und dann zweimal das neue Passwort eingeben. Das neue Passwort muss mindestens 8 Zeichen enthalten (davon mindestens 1 Großbuchstabe und 1 Zahl oder ein Sonderzeichen)",
            cancel: "Abbrechen",
            saveChanges: "Änderungen speichern",
            oldPassword: "Altes Passwort",
            newPassword: "Neues Passwort",
            repeatPassword: "Passwort wiederholen",
            updatedSuccessfully: "Erfolgreich aktualisiert",
            couldNotUpdate: "Das Passwort kann nicht aktualisiert werden",
            updatingInProgress: "Passwort-Update läuft...",
            oldPasswordNotProvided: "Das aktuelle Passwort wurde nicht eingegeben",
            invalidPasswordStructure: "Passwort muss mindestens 8 Zeichen enthalten (davon mindestens 1 Großbuchstabe und 1 Zahl oder ein Sonderzeichen)",
            incorrectlyRepeatedPassword: "Nicht übereinstimmende Passwörter",
            incorrectPassword: "Falsches Passwort",
            newPasswordNotProvided: "Neues Passwort wurde nicht eingegeben",
            newPasswordCannotBeTheSameAsOldOne: "Das neue Passwort kann nicht mit dem alten Passwort identisch sein",
        },
        pdfViewer: {
            couldNotDownloadDocument: "Das Dokument kann nicht download werden",
            couldNotDisplayDocument: "Dokument kann nicht angezeigt werden",
        },
        settingPassword: {
            title: "Passworteinstellungen",
            headerPrefix: "Konto Passwort",
            pageContent: "Um das Passwort zu ändern, müssen Sie das alte Passwort und dann zweimal das neue Passwort eingeben. Das neue Passwort muss mindestens 8 Zeichen enthalten (davon mindestens 1 Großbuchstabe und 1 Zahl oder ein Sonderzeichen)",
            saveChanges: "Änderungen speichern",
            newPassword: "Neues Passwort",
            repeatPassword: "Passwort wiederholen",
            updatedSuccessfully: "Erfolgreich aktualisiert",
            couldNotUpdate: "Das Passwort kann nicht aktualisiert werden",
            updatingInProgress: "Passwort-Update läuft...",
            passwordNotProvided: "Das Passwort wurde nicht eingegeben",
            birthDateNotProvided: "Geburtsdatum wurde nicht eingegeben",
            invalidPasswordStructure: "Passwort muss mindestens 8 Zeichen enthalten (davon mindestens 1 Großbuchstabe und 1 Zahl oder ein Sonderzeichen)",
            incorrectlyRepeatedPassword: "Nicht übereinstimmende Passwörter",
            incorrectPassword: "Falsches Passwort",
            globalErrorMessage: "Entschuldigung, es ist ein Fehler aufgetreten",
            birthDate: "Geburtsdatum",
            linkOutdated: "Der Link zum Zurücksetzen ist abgelaufen",
            incorrectBirthDate: "Geburtsdatum falsch. Du hast noch %d Versuche",
            incorrectBirthDateOneLeft: "Geburtsdatum falsch. Du hast noch %d Versuche",
            tryAgainChangePasswordLaterTitle: "Warten Sie",
            tryAgainChangePasswordLaterMessage: "Die maximale Anzahl von Versuchen zum Zurücksetzen des Passworts ist aufgebraucht - Versuchen Sie es später noch einmal",
        },
        importDocuments: {
            title: "Import von Dokumenten",
            networkError: "Netzwerkfehler. Überprüfen Sie Ihre Verbindung.",
            beforeUpload: {
                userInfoPrefix: "Vorsicht!",
                userInfoWithoutLinkPart: "Die Datei sollte gemäß",
                userInfoLink: "Schablone",
                attachmentsPanelHeader: "Laden Sie die ZIP-Datei mit den Dokumenten der Mitarbeiter hoch",
                loadFiles: "Belastung",
                filesSizeInfo: "Die maximale Dateigröße beträgt 50 MB",
                importTemplateStructure: "Vorlagenstruktur",
                excelStructure: "Excel-Dateistruktur",
                sampleZipContent: "Inhalt einer Beispiel-ZIP-Datei",
                fileNameDescription: "Wenn der technische Name der Datei nicht die Erweiterung hat, wird die Erweiterung .pdf automatisch hinzugefügt. Wenn der Zieldateiname leer ist, nimmt das Dokument automatisch denselben Namen wie die Eingabedatei an. Wenn der Zielname der Datei die Erweiterung nicht enthält, wird er automatisch mit dem gleichen hinzugefügt, wie das Eingabedokument hat.",
            },
            download: "Herunterladen",
            omitHeadersOption: "Schlagzeilen überspringen",
            overrideExistingFiles: "Bestehende Dateien überschreiben",
            uploadWithDocumentTypeOption: "Importieren von Dokumenten einschließlich ihres Typs",
            otherDocumentType: "Sonstiges",
            validatedZip: {
                uploadedZip: "Hochgeladene Zip :",
                documentsToAdd: {
                    label: "Dokumente zum Hinzufügen :",
                    suffixSingular: "Dokument",
                    suffixPlural: "Unterlagen"
                },
                selectFilesStatuses: {
                    label: "Dateistatus",
                    ok: "OK",
                    warning: "Mit Warnung",
                    error: "Mit Fehler"
                },
                filtersHeader: "Zusatzparameter",
                tableHeaders: {
                    id: "Nr",
                    inputFilename: "Eingabedatei",
                    workerName: "Arbeiter",
                    outputFilename: "Ausgabedatei",
                    status: "Status",
                    comment: "Kommentar",
                    action: "Aktion",
                    documentType: "Unterlagentyp",
                },
                cancelHeader: "Abbrechen",
                addDocumentsButtonLabel: "Dokumente hinzufügen",
                uploadWithoutErrorsMessage: "Achtung! Fehlerhafte Dokumente werden nicht hinzugefügt. }",
                goBack: "Komm zurück",
                search: "Suche"
            },
            validation: {
                overlayerMessage: "Dateivalidierung läuft",
                excelLackMessage: "Konnte * .xlsx-Datei nicht finden in versendet .zip",
                tooLargeZipLoadedMessage: "ZIP-Datei zu groß, maximale Größe beträgt 50 MB",
                invalidZipStructure: "Die ZIP-Datei kann nicht entschlüsselt werden (falsche Struktur) - fehlende 'import.xlsx'-Datei",
                correctFiles: "Korrigieren Sie die Postleitzahl und laden Sie neu",
                goBack: "Komm zurück",
                pageZipErrorMessageHeader: "Fehler beim Laden der ZIP",
                fileIsNotPresentInExcel: "Es gibt keine Informationen zu dieser Datei im angegebenen Excel",
                invalidDocTypeWarningMessage: "Ungültiger Dokumenttyp: {typeInExcel} - Standardwert '{defaultType}' wurde zugewiesen.",
                unknownUserIncorrectPesel: "Unbekannter Arbeiter - möglicherweise falsche PESEL-Nummer angegeben",
                errorOccurredWhileReadingInputFilename: "Beim Versuch, den Namen der Eingabedatei zu lesen, ist ein Fehler aufgetreten - wahrscheinlich nicht angegeben",
                errorOccurredWhileReadingOutputFilename: "Beim Versuch, den Namen der Ausgabedatei zu lesen, ist ein Fehler aufgetreten - wahrscheinlich nicht angegeben",
                duplicateFilename: "Arbeiterdokument dupliziert",
                fileEmpty: "Datei ist leer",
                fileWillBeOverwritten: "Arbeiterdokument wird überschrieben",
            },
            importSummaryHeaders: "Zusammenfassung importieren",
            importDocumentsNumber: "Importierte Dateien",
            excludedFilesNumber: "Vom Import ausgeschlossen",
            errorDocumentsNumber: "Fehlerhafte Dateien",
            warningDocumentsNumber: "Warnungen",

            invalidInputFileGlobalMessage: "Die geladene Datei ist kein *.zip-Paket",
            uploadingFiles: "Die Dateien werden gesendet",
            lackOfPermission: "Sie sind nicht berechtigt, auf diese Ansicht zuzugreifen",
            couldNotProcessAllSentFiles: "Alle gesendeten Dateien konnten nicht verarbeitet werden",
            filename: "Dateinamen",
            errorContent: "Inhalt des Fehlers",
            filesUploadedCorrectly: "{0} Dateien erfolgreich hinzugefügt",
            filesUploadedButSomeErrorsOccurred: "Verarbeitete Dateien - einige hatten Fehler beim Speichern",
            inputFilenameNotProvided: "Informationen zur Eingabedatei fehlen in der ZIP-Datei",
            fileNotFoundInZip: "Eingabedatei nicht in angehängter ZIP-Datei gefunden",

            rowError: "Die ersten 100 Zeilen sind falsch. Überprüfen und korrigieren Sie die Excel-Datei und versuchen Sie es erneut.",
            defaultDocumentType: "Standarddokumenttyp",

            uploadBarPreparingRequest: "Anfrage vorbereiten...",
            uploadBarUploading: "Hochladen von Dateien...",
            uploadBarProcessing: "Verarbeitung von Dateien...",
            uploadBarComplete: "Import abgeschlossen",
            couldNotDownloadSummaryFile: "Die Importzusammenfassungsdatei kann nicht heruntergeladen werden",
            downloadImportSummary: "Laden Sie die Importzusammenfassung herunter",
        },
        adminPanel: {
            adminPanelTitle: "Administrationsbereich",
            addUsersNotification: "Benutzer zum System hinzufügen!",
            addUsers: "Hinzufügen hier",
            activeUsers: "AKTIVE BENUTZER",
            administrators: "Administratoren",
            usersPanel: "Benutzer",
            activeWorkers: "ARBEITER",
            workers: "Arbeiter",
            activeDocuments: "UNTERLAGEN",
            documentsSize: "Grösse",
            officeData: "Kompanie Daten",
            officeDataDescription: "Aktualisieren Sie ihre Kompanie Daten",
            settlement: "Abrechnungen",
            settlementDescription: "Überprüfen Sie ihre Abrechnungen und verwalten Sie ihre Lizenzen",
            dictionaries: "Wörterbücher",
            dictionariesDescription: "Definieren Sie ihre eigenen Typen und Status ",
            permissions: "Berechtigungen",
            permissionsDescription: "Zugriff auf Module verwalten",
            users: "Benutzer",
            usersDescription: "Benutzer hinzufügen oder verwalten",
            youDoNotHavePriviledgesToThisView: "Sie können nicht auf diese Ansicht zugreifen",
            addresses: "Addressen",
            addressesDescription: "Definieren Sie Ihre eigenen Adressen",
        },
        addUserPanel: {
            addUserTitle: "Benutzer hinzufügen",
            basicDataSection: "GRUNDINFORMATION",
            surname: "Name",
            name: "Vorname",
            email: "E-mail",
            phonenumber: "Telefonnummer",
            personalDataSection: "PERSÖNLICHE DATEN",
            birthDate: "Geburtsdatum",
            pesel: "PESEL",
            securitySection: "SICHERHEIT",
            role: "Funktion",
            loginSection: "BENUTZERNAME",
            login: "Eindeutiger Benutzername",
            cancel: "Abbrechen",
            submit: "Speichern",
            administrator: "Administrator",
            hrManager: "HR Manager",
            projectManager: "Projektmanager",
            manager: "Manager",
            sendEmail: "Senden Sie eine E-Mail, um das Konto zu aktivieren",
            dataAccess: "Datenzugriff",
            allRecords: "Alle Datensätze",
            myRecords: "Meine Datensätze",
            myProjectRecords: "Alle Datensätze in meinen Projekten",
            lastNameRequired: "Nachname ist erforderlich",
            lastNameCharakters: "Nachname muss weniger als 50 Zeichen lang sein",
            firstNameRequired: "Vorname ist erforderlich",
            firstNameCharakters: "Vorname muss weniger als 30 Zeichen lang sein",
            emailRequired: "Email ist erforderlich",
            emailValid: "Falsch Email",
            roleRequired: "Rolle ist erforderlich",
            dataAccessRequired: "Datenzugriff ist erforderlich",
            loginRequired: "Login ist erforderlich",
            errorInvalidUserFirstName: "Falsch Vorname",
            errorInvalidUserLastName: "Falsch Nachname",
            errorInvalidUserBirthDate: "Falsch Geburtsdatum",
            errorInvalidUserEmail: "Falsch Email",
            errorInvalidUserPhonenumber: "Falsch Telefonnummer",
            errorInvalidUserPesel: "Falsch Pesel",
            errorInvalidUserRole: "Rolle ist erforderlich",
            errorInvalidUserDataAccess: "Datenzugriff ist erforderlich",
            errorInvalidUserLogin: "Falsch Benutzername",
            adding: "Einen Benutzer hinzufügen",
            errorAddUser: "Es ist ein Fehler aufgetreten. Kontaktiere den Serveradministrator.",
            addUserSuccess: "Benutzer hinzugefügt!",
            errorInvalidFields: "Einige Felder sind falsch",

            youDoNotHavePriviledgesToThisView: "Sie können nicht auf diese Ansicht zugreifen",
        },
        configurationPanel: {
            configurationTitle: "Wörterbücher",
            documentsSection: "Unterlagen",
            addDocumentType: "Unterlagentyp hinzufügen",
            add: "Hinzufügen",
            documentTypes: "Unterlagentypen erstellt",
            name: "Name",
            action: "Aktion",
            deactivate: "Deaktivieren",
            activate: "Aktivieren",
            save: "Speichern",
            boardingSection: "Boarding",
            addBoardingStatus: "Einen Boardingstatus hinzufügen",
            boardingStatuses: "Boardingstatus erstellt",
            editDocumentType: "Unterlagentyp bearbeiten",
            cancel: "Abbrechen",
            editBoardingStatus: "Boardingstatus bearbeiten",
            understand: "Ich verstehe",
            error: "Fehler",
            errorTypeText: "Ungültiger Unterlagentypname",
            errorDocumentExists: "Einen Unterlagentyp mit dem angegebenen Namen existiert",
            errorBoardingExists: "Einen Boardingstatus mit dem angegebenen Namen existiert",
            errorBoardingName: "Ungültiger Boardingstatusname",

            documentTypesUpdatingMessage: "Aktualisierung der Unterlagentypen im Gange",
            documentTypesUpdatedSuccessfully: "Unterlagentypen erfolgreich aktualisiert",
            documentTypesUpdateFailed: "Unterlagentypen konnten nicht aktualisiert werden",
            youDoNotHavePriviledgesToThisView: "Sie können nicht auf diese Ansicht zugreifen",
        },
        userListPanel: {
            userListTitle: "Benutzer",
            search: "Suche",
            active: "Aktiv",
            administrators: "Administratoren",
            users: "Benutzer",
            status: "Status",
            role: "Rolle",
            chooseColumns: "Wählen Sie die anzuzeigenden Spalten aus",
            cancel: "Abbrechen",
            save: "Speichern",
            delete: "Löschen",
            firstAndLastName: "Name",
            birthDate: "Geburtsdatum",
            dataAccess: "Datenzugriff",
            lastLogin: "Zuletzt eingeloggt",
            emailStatus: "Email status",
            emailStatusNotSent: "Nicht gesendet",
            emailStatusSent: "Gesendet",
            emailStatusError: "Email-Fehler beim Senden",
            emailStatusErrorConnection: "Verbindung vom SMTP-Server abgelehnt",
            emailStatusErrorMessage: "Vom SMTP-Server abgelehnte Nachricht",
            emailStatusErrorRecipients: "SMTP-Server hat Empfänger abgelehnt",
            emailStatusErrorSender: "SMTP-Server hat den Absender abgelehnt",
            emailStatusErrorInvlidResponse: "Der SMTP-Server hat eine ungültige Antwort zurückgegeben",
            emailStatusErrorCloseConn: "Verbindung zum SMTP-Server geschlossen",
            emailStatusErrorException: "SMTP-Server hat eine nicht erkannte Ausnahme zurückgegeben",
            emailStatusErrorDefault: "Unbekannter Fehler",

            rowsPerPage: "Objekte pro Seite",
            of: "von",

            all: "Alle",
            inactive: "Inaktiv",
            sendingEmail: "Eine E-Mail senden ...",
            invalidUserIdentifier: "Ungültige Benutzer-Id",
            couldNotSendMailServerBusy: "E-Mail kann wegen Serverlast nicht gesendet werden",
            couldNotSendMail: "Die E-Mail konnte nicht gesendet werden",
            mailSent: "Mail zur Warteschlange hinzugefügt. Es sollte innerhalb weniger Minuten gesendet werden.",
            sendActivationMail: "Senden Sie eine Aktivierungs-E-Mail",

            administrator: "Administrator",
            HRManager: "HR Manager",
            projectManager: "Projektmanager",
            worker: "Arbeiter",
            lackOfData: "Ausschuss",
            activeStatus: "Aktiv",
            inactiveStatus: "Inaktiv",
            youDoNotHavePriviledgesToThisView: "Sie können nicht auf diese Ansicht zugreifen",
            editData: "Bearbeiten Sie die Daten",

            unblockUser: "Entsperren Sie den Benutzer",
            areYouSureYouWantToUnblockUser: "Möchten Sie den Nutzer {fullname} (Login '{username}') wirklich entsperren?",
            blockUser: "Benutzer blockieren",
            areYouSureYouWantToBlockUser: "Möchten Sie den Benutzer {fullname} (Login '{username}') wirklich blockieren?",
            unblock: "Freischalten",
            block: "Blockiere es",
            yes: "Ja",
            unblockingAccountInProgress: "Entsperren eines Benutzers...",
            blockingAccountInProgress: "Sperren eines Benutzers...",

            errorOccurred: "Es ist ein Fehler aufgetreten",
            invalidRequestData: "Ungültige Anfragedaten",
            couldNotFindUserToUnblock: "Der Benutzer, den Sie entsperren möchten, konnte nicht gefunden werden",
            couldNotFindUserToBlock: "Der Benutzer, den Sie blockieren möchten, kann nicht gefunden werden",
            waitAMinute: "Warten Sie eine Weile",
            tooManyRequestsSent: "Sie haben in kurzer Zeit zu viele Anfragen gesendet - warten Sie eine Weile und versuchen Sie es später erneut",
            errorOccurredThatsAllWeKnow: "Ein Fehler ist aufgetreten - mehr wissen wir",

            blocked: "Verstopft",
            blockedStatus: "Verstopft",

            deleteUserContent: "Bist du sicher, das du das löschen möchtest:",
            deleteUserTitle: "Benutzer löschen",
            couldNotFindUserToDelete: "Der Benutzer, den Sie löschen wollten, konnte nicht gefunden werden",
            deleteUserSuccess: "Benutzer erfolgreich gelöscht",
            users: "Benutzer",

            worker: "Arbeiter",
            pesel: "Pesel",
            email: "Email",
        },
        preferences: {
            title: "Einstellungen",
            language: "Sprache",
            notifications: "Benachrichtigungen",
            notificationsAppCheckbox: "In-App-Benachrichtigungen senden",
            notificationsEmailCheckbox: "Benachrichtigungen per E-Mail senden",
            notificationsMessage: "Sie über neue Dokumente zu benachrichtigen:",
            notificationsHour: "Zeit",
            notificationsWeekday: "Tag",
            buttonCancel: "Abbrechen",
            buttonSave: "Änderungen speichern",

            loading: "Herunterladen...",
            pickTime: "Geben Sie die Uhrzeit ein",
            cancel: "Abbrechen",
            confirm: "Bestätigen Sie",
            asap: "So schnell wie möglich",
            hourly: "Jede Stunde",
            daily: "Alltäglich",
            weekly: "Jede Woche",

            weekday: {
                monday: "Montag",
                tuesday: "Dienstag",
                wednesday: "Mittwoch",
                thursday: "Donnerstag",
                friday: "Freitag",
                saturday: "Samstag",
                sunday: "Sonntag"
            },
            savingChanges: "Speichert Änderungen...",
            updatedCorrectly: "Änderungen gespeichert!",
            couldNotUpdate: "Die bereitgestellten Änderungen konnten nicht gespeichert werden",
            couldNotFetchSettingsData: "Einstellungsdaten konnten nicht abgerufen werden",
            turnOnNotifications: "Benachrichtigungen für Gerät aktivieren",
        },
        dsConfig: {
            dsConfigTitle: "Datenzugriffskonfiguration",
            noPermissions: "Keine Erlaubnis",
            pickedNumber: "{0} markiert",
            confirm: "Bestätigen Sie",
            cancel: "Abbrechen",
            save: "Speichern",
            access: "Betreten",
            create: "Schaffung",
            edit: "Auflage",
            delete: "Entfernung",
            accept: "Annehmen",
            roleName: "Der Name der Rolle",
            lackOfPermissions: "Keine Erlaubnis",
            expand: "Expandieren",
        },
        mdlConfig: {
            mdlConfigTitle: "Rolle Konfiguration",
            module: "Module",
            read: "Lesung",
            add: "Zusatz",
            edit: "Auflage",
            delete: "Entfernung",
            cancel: "Abbrechen",
            save: "Speichern",
            youDoNotHavePriviledgesToThisView: "Sie können nicht auf diese Ansicht zugreifen",
            roleName: "Rollenname",
            changesUpdated: "Änderungen aktualisiert!",
            noSuchRecord: "Datensatz mit angegebener ID existiert nicht",
            checkAll: "Wählen Alle",
            access: "Zugriff",
            creating: "Erstellen",
            pageNotFound: "Seite nicht gefunden",
            unrecognizedError: "Unerwarteter Fehler ist aufgetreten",
            loading: "Wird geladen",
            errorLoading: "Beim Laden der Seite ist ein Fehler aufgetreten",
            noPermissions: "Keine Erlaubnis",
            numberPicked: "{0} ausgewählt",
            accept: "Annehmen",
            incorrectRequest: "Falsche Anfragedaten",
        },
        permissions: {
            title: "Berechtigungen",
            mdlRole: "Module greifen auf Rollen zu",
            dsRole: "Datenzugriffsrollen",
            addGroup: "Eine Gruppe hinzufügen",
            deleteGroup: "Gruppe löschen",
            deleteGroupContent: "Möchten Sie die Gruppe wirklich löschen?",
            groupName: "Gruppenname",
            cancelButton: "Abbrechen",
            deleteButton: "Löschen",
            duplicateGroup: "Gruppe duplizieren",
            duplicateGroupContent: "Möchten Sie wirklich eine neue Gruppe basierend auf erstellen:",
            createButton: "Schaffen",
            headerName: "Name",
            headerUsers: "Benutzer",
            headerActions: "Aktionen",
            youDoNotHavePriviledgesToThisView: "Sie können nicht auf diese Ansicht zugreifen",
            invalidRoleName: "Dieser Rollenname ist bereits vergeben",
            roleEmpty: "Der Rollenname darf nicht leer sein",
            actionEdit: "Bearbeiten",
            actionDuplicate: "Duplizieren",
            actionDelete: "Löschen",
            showMore: "Zeig mehr",
            showLess: "Zeig weniger"
        },
        userDialog: {
            lastName: "Nachname",
            firstName: "Name",
            email: "Email",
            phoneNumber: "Telefonnummer",
            birthDate: "Geburtsdatum",
            pesel: "PESEL",
            role: "Rolle",
            dataAccess: "Zugriff auf die Daten",
            login: "Anmeldung",
            loginTaken: "Diese Anmeldung ist bereits vergeben",

            headers: {
                security: "Sicherheit",
                login: "Anmeldung",
                personalData: "Persönliche Daten",
                basicData: "Grundinformation",
            },

            editDialogTitle: "Einen Benutzer bearbeiten",
            addDialogTitle: "Neuer Benutzer",

            validators: {
                lastName: {
                    lastNameRequired: "Nachname ist erforderlich",
                    lastNameTooLong: "Der Nachname ist zu lang",
                    invalidLastNameStructure: "Ungültige Namensstruktur",
                },
                firstName: {
                    firstNameRequired: "Vorname ist erforderlich",
                    firstNameTooLong: "Der Name ist zu lang",
                    invalidFirstNameStructure: "Ungültige Namensstruktur",
                },
                email: {
                    emailRequired: "E-Mail ist erforderlich",
                    invalidEmailStructure: "Ungültige E-Mail-Struktur",
                },
                role: {
                    roleRequired: "Rolle ist erforderlich",
                },
                dataAccess: {
                    dataAccessRequired: "Informationen zum erforderlichen Datenzugriff",
                },
                login: {
                    loginFieldValueRequired: "Benutzeranmeldung ist erforderlich",
                },
                birthDate: {
                    birthDateRequired: "Geburtsdatum ist erforderlich",
                },
                phoneNumber: {
                    invalidPhoneNumberStructure: "Ungültige Telefonnummernstruktur",
                    areaCode: "Keine Vorwahl eingegeben",
                },
            },

            noWorker: "Kein Mitarbeiter",
            newWorker: "Neu Mitarbeiter",
            existingWorker: "Bestehender Mitarbeiter",
            worker: "Angestellter",
            sendActivationMail: "Senden Sie eine Aktivierungs-E-Mail",
            sendingUserCreateRequest: "Ich erstelle einen Benutzer...",
            sendingUserUpdateRequest: "Aktualisieren des Benutzers...",
            sendingUserRemoveRequest: "Ich entlasse einen Mitarbeiter...",

            errors: {
                error400: {
                    worker: {
                        INVALID_WORKER_CONFIG: "Ungültige Anfrage für einen Mitarbeiter",
                        INVALID_WORKER_CONFIG_NO_ID: "Mitarbeiter-ID wurde nicht angegeben",
                        INVALID_WORKER_ID_DOES_NOT_EXIST: "Ein Mitarbeiter mit der angegebenen ID wurde nicht gefunden",
                        INVALID_WORKER_TAKEN_OPERATION: "Das Benutzerprofil konnte keinem bestehenden Mitarbeiter zugeordnet werden - es ist derzeit einem anderen Mitarbeiter zugeordnet",
                    },
                    worker_option: {
                        INVALID_WORKER_CONFIG: "Ungültige Anfrage für einen Mitarbeiter",
                        INVALID_REMOVE_WORKER_OPERATION: "Es war nicht möglich den Mitarbeiter vom Benutzer zu trennen",
                        INVALID_WORKER_EXIST_OPERATION: "Es war nicht möglich einen neuen Mitarbeiter für den Benutzer anzulegen, da bereits einer vorhanden ist",
                        INVALID_WORKER_ASSIGNED_OPERATION: "Ein bestehender Mitarbeiter konnte nicht verbunden werden, da er bereits mit einem anderen Benutzer verbunden ist",
                    },
                },
                error401: {
                    message: "Ihre Sitzung ist abgelaufen - bitte melden Sie sich an und versuchen Sie es erneut",
                },
                error403: {
                    message: "Sie verfügen nicht über ausreichende Berechtigungen, um die Änderungen vorzunehmen",
                },
                error404: {
                    message: "Der Empfänger der Abfrage wurde nicht gefunden",
                },
                error429: {
                    message: "Zu viele Versuche in kurzer Zeit - versuche es später noch einmal",
                },
                anyError: {
                    message: "Ein Fehler ist aufgetreten - mehr wissen wir",
                }
            },

            yesNoDialogTitle: "Einen Mitarbeiter löschen",
            yesNoDialogContent: "Möchten Sie den Mitarbeiter für den Benutzer {username} wirklich löschen?",

            delete: "Löschen",
            otherUserFunctionalitiesMessage: "Zusätzliche Mitarbeiterfunktionalitäten: Mitarbeiterportal, Organisationsstruktur, Dokumente, Boardings",
            assignWorkerMessage: "Es können nur Mitarbeiter verknüpft werden, die nicht mit dem Benutzerkonto verknüpft sind - ein Mitarbeiter kann nur einen Benutzer haben",
            cancel: "Abbrechen",
            save: "Speichern",

            pickWorker: "Arbeiter auswählen*",
            requiredField: "Dieses Feld wird benötigt.",
            errorsInForm: "Sie haben Fehler in Ihrem Formular",
            invalidLastName: "Ungültiger Wert des Felds 'Nachname'.",
            invalidFirstName: "Ungültiger Wert des Felds 'Vorname'.",
            invalidEmail: "Ungültiger Wert des Felds 'E-Mail'.",
            invalidPhoneNumber: "Ungültiger Wert des Felds 'Telefonnummer'.",
            invalidBirthDate: "Ungültiger Wert des Felds 'Geburtsdatum'.",
            peselAssignedToOtherWorker: "Vorausgesetzt, der PESEL-Wert ist bereits einem anderen Arbeiter zugewiesen.",
            invalidPesel: "Ungültiger Wert des Felds 'PESEL'.",
            unrecognizedErrorInBasicDataSection: "Im Abschnitt mit den Basisdaten ist ein nicht erkannter Fehler aufgetreten",
            requestedUserNotFound: "Angeforderter Benutzer wurde nicht gefunden",
            youSentTooManyRequests: "Sie haben zu viele Anfragen an den Server gesendet. Bitte warten Sie eine Minute, bevor Sie erneut versuchen, das Formular hochzuladen.",
            unrecognizedErrorOcurredWhileSendingForm: "Beim Versuch, das Formular zu senden, ist ein unbekannter Fehler aufgetreten. Bitte versuche es erneut. Wenn dieser Fehler weiterhin auftritt, wenden Sie sich an den Administrator.",
            assigningWorkerEnabledPermissionsMessage: "Durch das Zuweisen eines Mitarbeiters wird das Hinzufügen von Dokumenten und Boardings für diesen Benutzer aktiviert.",

            cannotDeleteWorkerUsedInApp: "Sie können einen Mitarbeiter nicht entfernen, da ihm Dokumente oder Boardings zugewiesen sind",
        
            no: "Nicht",
            yes: "Ja",
            closeUserDialogConfirmationContent: "Möchten Sie dieses Fenster wirklich schließen? Die darin eingegebenen Daten gehen verloren",
            wait: "Warte ab",
        },
        company: {
            title: "Angaben zum Unternehmen",
            name: "Name des Unternehmens",
            street: "Straße",
            streetNumber: "Nr budynku",
            propertyNumber: "Nr lokalu",
            zipCode: "Kod pocztowy",
            city: "Stadt",
            country: "Staat",
            pickCountry: "Wähle ein Staat",
            region: "Woiwodschaft",
            nip: "NIP",
            email: "E-mail",
            emailHelpText: "E-Mail für den Versand der Rechnung",
            phoneNumber: "Rufnummer",
            logoHelpText: "Bevorzugte Größe 250x100 Pixel (jpg oder png Format), nicht mehr als 50 KB",
            logo: "Logo",
            address: "Adresse des Unternehmens",
            saveButton: "Speichern Sie",
            cancelButton: "Abbrechen",
            errors: {
                name: {
                    required: "Feld ist erforderlich",
                },
                email: {
                    required: "Feld ist erforderlich",
                    wrong: "Es wurde eine ungültige E-Mail eingegeben",
                },
                phoneNumber: {
                    wrong: "Falsche Rufnummer angegeben",
                    areaCode: "Keine Vorwahl eingegeben",
                },
                nip: {
                    wrong: "In der Datenbank gibt es bereits ein Unternehmen, das diese NIP-Nummer hat.",
                    tooShort: "Die NIP muss 10 Zeichen enthalten.",
                }
            },
            loading: "Herunterladen...",
            loadingSave: "Aufnahme...",
            couldNotFetchData: "Daten können nicht heruntergeladen werden",
            youDoNotHavePriviledgesToThisView: "Sie können nicht auf diese Ansicht zugreifen",
        },
        removeAddressDialog: {
            cancel: "Abbrechen",
            delete: "Löschen",
            removingAddress: "Löschen einer Adresse ...",
            badRequest: "Abfragefehler",
            cannotRemoveAddressInUse: "Diese Adresse kann nicht gelöscht werden, da sie derzeit verwendet wird",
            notFound: "Nicht gefunden",
            cannotRemoveAddressDoesNotExists: "Diese Adresse kann nicht gelöscht werden, da sie nicht existiert",
            tooManyRequests: "Zu viele Anfragen",
            youSentTooManyRequestsMessage: "Sie haben in kurzer Zeit zu viele Anfragen gesendet - warten Sie eine Weile und versuchen Sie es später erneut",
            anErrorOccurredTitle: "Es ist ein Fehler aufgetreten",
            anErrorOccurredMessage: "Ein Fehler ist aufgetreten - mehr wissen wir",
            deleteAddress: "Adresse löschen",
            areYouSureYouWantRemoveAddress: "Möchten Sie diese Adresse wirklich löschen?",
        },
        addressListPanel: {
            title: "Adressen",
            streetName: "Straßenname",
            streetNumber: "Nummer",
            propertyNumber: "Wohnungsnummer",
            zipCode: "Postleitzahl",
            city: "Stadt",
            region: "Woiwodschaft",
            country: "Land",
            description: "Beschreibung",
            projects: "Projekte",

            edit: "Projekt bearbeiten",
            remove: "Projekt löschen",

            search: "Suche",
            project: "Entwurf",

            allProjectsSelected: "Alle",
            chooseDataTableColumns: "Tabellenspalten auswählen",
            modify: "Ändern",

            badRequest: "Abfragefehler",
            badRequestMessage: "Ein falsch ausgefülltes Formular wurde gesendet",
            notFound: "Nicht gefunden",
            notFoundErrorMessage: "Die Anfrage konnte nicht gesendet werden - ungültige URL",
            errorOccurred: "Es ist ein Fehler aufgetreten",
            errorOccurredMessage: "Beim Senden der Anfrage ist ein Fehler aufgetreten - mehr wissen wir nicht",
            tooManyRequests: "Warte ab",
            tooManyRequestsMessage: "Sie haben zu viele Anfragen gesendet - warten Sie einen Moment und versuchen Sie es später erneut",
            choose: "Wählen",
            assignToProject: "Dem Projekt zuordnen",
            allProjectsSelected: "Alle",
            forbiddenOperation: "Verbotener Betrieb",
            youDoNotHavePrivilegesToDoThatOperation: "Sie sind nicht berechtigt, diesen Vorgang auszuführen",

            // custom datatable footer translations
            rowsPerPage: "Objekte pro Seite",
            of: "mit",
        },
        addressDialog: {
            newAddress: "Neue Adresse",
            editAddress: "Adresse bearbeiten",

            streetName: "Straßenname",
            streetNumber: "Hausnummer",
            propertyNumber: "Wohnungsnummer",
            zipCode: "Postleitzahl",
            city: "Stadt",
            region: "Woiwodschaft",
            country: "Land",
            description: "Beschreibung",

            cannotSendEmptyForm: "Sie können kein leeres Formular senden",
            creatingNewAddress: "Ich erstelle eine neue Adresse...",
            updatingAddress: "Aktualisieren der Adresse ...",

            cancel: "Abbrechen",
            save: "Speichern",
            badRequest: "Abfragefehler",
            badRequestMessage: "Ein falsch ausgefülltes Formular wurde gesendet",
            notFound: "Nicht gefunden",
            notFoundErrorMessage: "Die Anfrage konnte nicht gesendet werden - ungültige URL",
            errorOccurred: "Es ist ein Fehler aufgetreten",
            errorOccurredMessage: "Beim Senden der Anfrage ist ein Fehler aufgetreten - mehr wissen wir nicht",
            tooManyRequests: "Warte ab",
            tooManyRequestsMessage: "Sie haben zu viele Anfragen gesendet - warten Sie einen Moment und versuchen Sie es später erneut",
            choose: "Wählen",
            assignToProject: "Dem Projekt zuordnen",
            allProjectsSelected: "Alle",
            forbiddenOperation: "Verbotener Betrieb",
            youDoNotHavePrivilegesToDoThatOperation: "Sie sind nicht berechtigt, diesen Vorgang auszuführen",
        },
    },
    commons: {
        buttons: {
            ok: "OK",
            confirm: "Bestätigen",
            "continue": "Weiterführen",
            cancel: "Abbrechen",
            understood: "Ich verstehe",
            upload: "Senden",
            retry: "Versuchen Sie erneut",
            close: "Schließen",
        },
        messages: {
            yes: "Ja",
            no: "Nein",
            cancel: "Abbrechen",
            logging: "Einloggen..",
            loggingFailed: "Das Einloggen ist fehlgeschlagen",
            loading: "Herunterladen...",
            savingChanges: "Speicherung von Änderungen...",
            processing: "Verarbeitung...",
            errorOccuredTitle: "Fehler",
            errorOccured: "Ein Fehler ist aufgetreten. Das ist alles, was wir wissen.",
            edit: "Bearbeiten",
            showDetails: "Details anzeigen",
            noDetails: "Keine Details",
            details: "Details",
            unassigned: "Nicht zugewisen",
            pageNotFound: "Fehler 404, Seite nicht gefunden",
            networkErrorTitle: "Netzwerkfehler",
            networkError: "Ein Netzwerkfehler ist aufgetreten. Überprüfen Sie Ihre Internetverbindung, bevor Sie es erneut versuchen.",
            tryAgainLater: "Versuchen Sie es später noch einmal.",
            requestFailedTitle: "Abfragefehler",
            requestBoardingFailed: "Abfrage fehlgeschlagen für ID: {id}",
            requestAttachmentFailed: "Abfrage fehlgeschlagen für Anhang: {name}",
            goBackTitle: "Nicht gespeicherte",
            goBackReloadRequest: "Sind Sie sicher, Änderungen zu verwerfen und diese Seite zu verlassen?",
            resettingPassword: "Das Zurücksetzen des Passworts im Gange...",
            forbidden: "Zugriff abgelehnt",
            internalServerError: "Serverfehler",
            tryAgainInAMinute: "Bitte versuchen Sie es in einer Minute erneut",
            projectsNotChosen: "Es wurden keine Projekte ausgewählt",
            unknownPage: "Unbekannte seite",

            documentNotExist: "Dokument existiert nicht",
            forbiddenOperation: "Verbotener Betrieb",
            youDoNotHavePriviledgesToDoThatOperation: "Sie haben keine Berechtigungen für diese Operation",
            badRequest: "Falsche angegebene Abfrage",
            noWorkerAssigned: "Keine Ergebnisse gefunden - keine Mitarbeiter",
        },
        selectors: {
            countries: [{
                    key: "AL",
                    text: "Albanien"
                },
                {
                    key: "AD",
                    text: "Andorra"
                },
                {
                    key: "AT",
                    text: "Österreich"
                },
                {
                    key: "BE",
                    text: "Belgien"
                },
                {
                    key: "BY",
                    text: "Belaruss"
                },
                {
                    key: "BA",
                    text: "Bosnien und Herzegowina"
                },
                {
                    key: "BG",
                    text: "Bulgarien"
                },
                {
                    key: "HR",
                    text: "Kroatien"
                },
                {
                    key: "ME",
                    text: "Montenegro"
                },
                {
                    key: "CZ",
                    text: "Tschechien"
                },
                {
                    key: "DK",
                    text: "Dänemark"
                },
                {
                    key: "EE",
                    text: "Estland"
                },
                {
                    key: "FI",
                    text: "Finnland"
                },
                {
                    key: "FR",
                    text: "Frankreich"
                },
                {
                    key: "GR",
                    text: "Griechenland"
                },
                {
                    key: "ES",
                    text: "Spanien"
                },
                {
                    key: "NL",
                    text: "Die Niederlande"
                },
                {
                    key: "IE",
                    text: "Irland"
                },
                {
                    key: "IS",
                    text: "Island"
                },
                {
                    key: "KZ",
                    text: "Kasachstan"
                },
                {
                    key: "XK",
                    text: "Kosovo"
                },
                {
                    key: "LI",
                    text: "Liechtenstein"
                },
                {
                    key: "LT",
                    text: "Litauen"
                },
                {
                    key: "LV",
                    text: "Lettland"
                },
                {
                    key: "MK",
                    text: "Mazedonien"
                },
                {
                    key: "MT",
                    text: "Malta"
                },
                {
                    key: "MD",
                    text: "Moldau"
                },
                {
                    key: "MC",
                    text: "Monaco"
                },
                {
                    key: "DE",
                    text: "Deutschland"
                },
                {
                    key: "NO",
                    text: "Norwegen"
                },
                {
                    key: "PL",
                    text: "Polen"
                },
                {
                    key: "PT",
                    text: "Portugal"
                },
                {
                    key: "RU",
                    text: "Russland"
                },
                {
                    key: "RO",
                    text: "Rumänien"
                },
                {
                    key: "SM",
                    text: "San Marino"
                },
                {
                    key: "XS",
                    text: "Serbien"
                },
                {
                    key: "SK",
                    text: "Slowakei"
                },
                {
                    key: "SI",
                    text: "Slowenien"
                },
                {
                    key: "CH",
                    text: "Schweiz"
                },
                {
                    key: "SE",
                    text: "Schweden"
                },
                {
                    key: "TR",
                    text: "Türkei"
                },
                {
                    key: "UA",
                    text: "Ukraine"
                },
                {
                    key: "HU",
                    text: "Ungarn"
                },
                {
                    key: "GB",
                    text: "Großbritannien"
                },
                {
                    key: "IT",
                    text: "Italien"
                },
            ],
        }
    },
    errors: {
        projects_assignment_id: {
            code: "empty",
            string: "Liste darf nicht leer sein.",
            header: "Fehler"
        },
        boarding: {
            off_boarding_date: {
                code: "OFF_BOARDING_DATE_LESS",
                string: "Die Daten außerhalb des Boardings sind geringer als die Daten beim Boarding.",
                header: "Fehler"
            },
            other_boarding: {
                code: "OTHER_BOARDING_EXIST",
                string: "Die Arbeitskraft nimmt zum eingegebenen Datum bereits am Projekt teil.",
                header: "Fehler"
            },
            non_field_errors: {
                code: "BOARDING_CURRENT_STATUS_INVALID",
                string: "Der angegebene Boarding-Status wird nicht unterstützt.",
                header: "Fehler"
            },
            boarding_status: {
                code: "BOARDING_NEW_STATUS_INVALID",
                string: "Es ist nicht zulässig, das Boarding in den angegebenen Status zu ändern.",
                header: "Fehler"
            }
        },
        project_assignment: {
            finish_date: {
                code: "FINISH_DATE_LESS",
                string: "Das Enddatum des Projekts ist kleiner als das Startdatum.",
                header: "Fehler"
            },
            contract_end_date: {
                code: "CONTRACT_END_DATE_LESS",
                string: "Das Vertragsendedatum ist kleiner als das Startdatum.",
                header: "Fehler"
            },
            project_id: {
                code: "empty",
                string: "Es wurde kein Design ausgewählt.",
                header: "Fehler"
            }
        },
        user_profile: {
            pesel_unique: {
                code: "unique",
                string: "Der angegebene Pesel ist bereits in der Datenbank vorhanden.",
                header: "Fehler"
            },
            contract_end_date: {
                code: "CONTRACT_END_DATE_LESS",
                string: "Das Vertragsendedatum ist kleiner als das Startdatum.",
                header: "Fehler"
            },
            non_field_errors: {
                code: "NON_FIELD_ERROR",
                string: "Aktualisierung nicht möglich. Es wurden keine neuen Daten eingegeben.",
                header: "Fehler"
            },
            personal_email: {
                code: "invalid",
                string: "Ungültige private E-Mail-Adresse angegeben.",
                header: "Fehler"
            },
            secondary_email: {
                code: "invalid",
                string: "Falsche Firmen-E-Mail-Adresse angegeben.",
                header: "Fehler"
            },
            personal_mobile_number: {
                code: "invalid",
                string: "Ungültige Telefonnummer eingegeben.",
                header: "Fehler"
            }


        },
        worker: {
            parent_id: [{
                    code: "INCORRECT_PARENT_ID",
                    string: "Die ausgewählte Person verfügt nicht über die entsprechenden Rollen als Manager.",
                    header: "Fehler"
                },
                {
                    code: "THE_SAME_PARENT_ID",
                    string: "Kann nicht zugewiesen werden. Dieselbe Person wurde ausgewählt.",
                    header: "Fehler"
                },
                {
                    code: "INVALID_PARENT_ID_TO_WORKER",
                    string: "Ein Arbeitnehmer darf einem ihm gleichgestellten Arbeitnehmer nicht untergeordnet sein.",
                    header: "Fehler"
                }

            ]
        },
        preferences: {
            non_field_errors: {
                code: "NON_FIELD_ERROR",
                string: "Es wurden keine Daten bereitgestellt.",
                header: "Fehler"
            },
            new_docs_notifications_time: [{
                    code: "NEW_DOCS_NOTIFICATIONS_TIME_TOO_BIG",
                    string: "Falsches Datum eingegeben, versuchen Sie es später erneut.",
                    header: "Fehler"
                },
                {
                    code: "NEW_DOCS_NOTIFICATIONS_TIME_NULL",
                    string: "Der Tag oder die Uhrzeit wurde nicht eingegeben.",
                    header: "Fehler"
                },
            ],
            new_docs_notification_phone_time: [
                {
                    code: "NEW_DOCS_NOTIFICATIONS_PHONE_TIME_TOO_BIG",
                    string: "Falsches Datum eingegeben, versuchen Sie es später erneut.",
                    header: "Fehler"
                },
                {
                    code: "NEW_DOCS_NOTIFICATIONS_PHONE_TIME_NULL",
                    string: "Der Tag oder die Uhrzeit wurde nicht eingegeben.",
                    header: "Fehler"
                }
            ],
            default_error: {
                string: "Es ist ein Fehler aufgetreten. Das ist alles was wir wissen.",
                header: "Fehler",
            }
        },
        permissions: {
            name: {
                string: "Gruppe mit diesem Namen existiert bereits.",
                header: "Fehler",
            },
            default_error: {
                string: "Es ist ein Fehler aufgetreten. Das ist alles was wir wissen.",
                header: "Fehler",
            },
            youDoNotHavePriviledgesToDoThatOperation: {
                string: "Sie haben keine Berechtigungen für diese Operation",
                header: "Fehler",
            },
        },
        company: {
            email: {
                string: "Es wurde eine ungültige E-Mail eingegeben.",
                header: "Fehler"
            },
            nip: {
                string: "In der Datenbank gibt es bereits ein Unternehmen, das diese NIP-Nummer hat.",
                header: "Fehler"
            },
            fob_billing: {
                string: "In der Datenbank gibt es bereits ein Unternehmen, das zu berücksichtigen ist.",
                header: "Fehler"
            },
            default_error: {
                string: "Es ist ein Fehler aufgetreten. Das ist alles was wir wissen.",
                header: "Fehler",
            },
            upload_file: [{
                    code: "FILE_TOO_LARGE",
                    string: "Die Datei wiegt zu viel.",
                    header: "Fehler"
                },
                {
                    code: "FILE_NOT_PICTURE",
                    string: "Die Datei muss ein Foto mit der Erweiterung .png oder .jpg sein.",
                    header: "Fehler",
                }
            ],
            contact_number1: {
                string: "Falsche Rufnummer angegeben",
                code: "Fehler"
            },
        }
    }
}