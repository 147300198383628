export default {
    app: {
        drawer: {
            emailUnassinged: "E-Mail nieprzypisany",
            manager: "Kierownik",
            myProfile: "Mój profil",
            about: "O Fast OnBoarding",
            logout: "Wyloguj",
            profile: "Profil",
            documents: "Dokumenty",
            workersDocuments: "Dokumenty pracowników",
            settings: "Ustawienia",
            employees: "Pracownicy",
            boardings: "Boarding",
            myDocuments: "Moje dokumenty",
            administration: "Administracja",

            officeData: "Dane firmy",
            settlements: "Rozliczenia",
            dictionaries: "Słowniki",
            permissions: "Uprawnienia",
            users: "Użytkownicy",
            unknown: "Nieznany",
            permissions: "Uprawnienia",
            addresses: "Adresy",
        },
        bottomNav: {
            boarding: "Boarding",
            employees: "Pracownicy",
            profile: "Profil",
            documents: "Dokumenty"
        },
        pageNotFoundDetails: "Wybrana strona nie istnieje lub została usunięta. Aplikacja została przekierowana do domyślnej strony głównej."
    },
    views: {
        commons: {
            alertWithDetails: {
                details: "Szczegóły"
            },
            errorDialog: {
                copyError: "Kopiuj błąd",
                copied: "Skopiowano",
                failedToCopy: "Błąd kopiowania",
                problemWithLoadingPage: "Wystąpił problem z ładowaniem strony. Spróbuj ponownie."
            },
            confirmBoardingDialog: {
                title: "Potwierdzenie zatrudnienia",
                chooseProjects: "Wybierz projekty",
                startDate: "Data rozpoczęcia",
                checkbox1: "Podpisana umowa",
                checkbox2: "Kompletny kwestionariusz",
                checkbox3: "Orzeczenie lekarskie i faktura",
                checkbox4: "Zaświadczenie o niekaralności",

                uploading: "Przesyłanie",
                tooManyWorkersForThisAction: "Wybrano zbyt wielu pracowników, aby wykonać tą czynność.",
            },
            resignDialog: {
                title: "Rezygnacja pracownika",
                pickProjects: "Wybierz projekty",
                resignDate: "Rezygnacja dnia",
                offBoardingDate: "Data wyjazdu",
                contractEndDate: "Data rozwiązania kontraktu",
                projectEndDate: "Data zakończenia projektu",
                chooseProjects: "Wybór projektów",
                reason: "Powód",
                selectForResignReasons: [
                    "Niepodjęcie pracy",
                    "Porzucenie pracy",
                    "Zwolnienie polubowne",
                    "Zwolnienie dyscyplinarne",
                    "Umowa zakończona",
                    "Umowa zakończona z wyróżnieniem"
                ],
                ratingComment: "Komentarz do oceny",
                errorOccured: "Wystąpił błąd",
                errorMessage: "Wystąpiło niepowodzenie przy próbie zapytania dla boardingu o id: ",
                errorCodeShort: "RSGND#5",
                errorCodeLong: "REQUEST_FAILED"
            },
            removeBoardingDialog: {
                title: "Usuń boardingi",
                chooseProjects: "Wybierz projekty"
            },
            changeBoardingPlansDialog: {
                title: "Zmiana planów",
                chooseProject: "Wybór projektów",
                chooseWorker: "Wybór pracownika",
                changeManager: "Zmień kierownika",
                changeDate: "Zmiana daty",
                reason: "Powód",
                errorOccured: "Wystąpił błąd",
                errorMessage: "Wystąpiło niepowodzenie przy próbie zapytania dla pracownika o id: ",
                errorCodeShort: "CHNGBRD#1",
                errorCodeLong: "REQUEST_FAILED"
            },
            changeEmployeePlansDialog: {
                title: "Zmiana planów",
                changeLocation: "Zmiana lokalizacji",
                projectChangeDate: "Data rozpoczęcia nowego projektu",
                projectToChange: "Projekt do zmiany",
                changeProject: "Nowy projekt",
                changeManager: "Zmiana kierownika",
                reason: "Powód"
            },
            sendEmployeeMessageDialog: {
                title: "Krótka wiadomość",
                cancel: "Anuluj",
                send: "Wyślij"
            },
            employeesFiltersDialog: {
                title: "Filtry",
                clear: "Wyczyść",
                search: "Wyszukaj",
                resignReason: "Powód rezygnacji",
                details: "Szczegóły boardingu"
            },
            offBoardingDialog: {
                title: "Rezygnacja pracownika",
                pickProjects: "Wybierz projekty",
                offBoardingDate: "Data wyjazdu",
                contractEndDate: "Data zakończenia umowy",
                project: "Projekt",
                date: "Data",
                rating: "Ocena",
                details: "Szczegóły",
                reason: "Powód",
                resignReason: "Powód rezygnacji",
                comment: "Komentarz",
                ratingComment: "Komentarz do oceny",
                errorNoProjectsAssignedTitle: "Brak przypisanych projektów",
                errorNoProjectsAssignedMessage: "Nie wybrano projektów dla tego okna edycyjnego",
                errorOccured: "Wystąpił błąd",
                errorMessage: "Wystąpiło niepowodzenie przy próbie zapytania dla boardingu o id: ",
                errorCodeLong: "REQUEST_FAILED"
            },
            attachPicturesDialog: {
                defaultTitle: "Załącz obrazy",
                attachFromCamera: "Zrób zdjęcie",
                attachFromFiles: "Załącz plik",
                noFilesAttached: "Brak załączonych plików",
                tooManyFilesTitle: "Zbyt wiele plików",
                tooManyFilesText: "Próbujesz załączyć {numberOfFiles} plików. Maksymalna liczba plików to {maxNumberOfFiles}.",
                totalFilesSizeTooBigTitle: "Przekroczono limit wielkości plików",
                totalFilesSizeTooBigText: "Pliki, które próbujesz załączyć są zbyt duże. Maksymalna dozwolona wielkość wynosi {maxAttachmentsSize}.",
                fileTooBigTitle: "Przekroczono limit wielkości pliku",
                fileTooBigText: "Następujące pliki przekraczają limit wielkości ({sizeLimit})",
            },
            postalAddressEditDialog: {
                defaultHeadline: "Edycja adresu",
                dictionaryLocation: "Opis",
                streetName: "Ulica",
                streetNumber: "Numer",
                propertyNumber: "Mieszkanie",
                zipCode: "Kod pocztowy",
                city: "Miasto",
                country: "Państwo",

                invalidLocation: "Nieprawidłowa lokalizacja",
                missingLocationErrorTitle: "Nieznana lokalizacja",
                missingLocationError: "Nie odnaleziono wybranej lokalizacji w słowniku",
            },
            extendOffboardingDialog: {
                defaultHeadline: "Przedłuż Off-Boarding",
                chooseProjects: "Wybierz projekty",
                pickNewOffBoardingDate: "Wybierz nową datę off-boardingu"
            },
            nullableRating: {
                rating: "Ocena",
                none: "Brak"
            }
        },
        login: {
            username: "Użytkownik",
            password: "Hasło",
            login: "Zaloguj się",
            forgotPassword: "Zapomniałeś hasła?",
            invalidLoginOrPassword: "Użytkownik lub Hasło niepoprawne",
            accountNotActivated: "Konto nie zostało aktywowane",
            loginMethodBlockedByAdministrator: "Wybrana metoda logowania została zablokowana przez administratora",
            invalidAccountConfig: "Nieprawidłowa konfiguracja konta, skontaktuj się z administratorem.",
            accountDisabled: "Konto zostało tymczasowo zablokowane, skontaktuj się z administratorem.",
            multipleAccountsAssignedToEmail: "Brak możliwości zalogowania się za pomocą adresu E-Mail. Ten adres E-Mail jest przypisany do więcej niż jednego konta",
            unassignedUserProfile: "Nieprzypisany profil użytkownika. Skontaktuj się z administratorem",
            unassignedWorker: "Błędnie skonfigurowane konto. Skontaktuj się z administratorem",
            couldNotLoginNoPermissions: "Nie posiadasz żadnych uprawnień. Skontaktuj się z administratorem",
            error429message: "Zbyt wiele zapytań dla niezautoryzowanego połączenia. Odczekaj chwilę przed następną próbą.",

            unknownError: "Wystąpił nieznany błąd. Prosimy spróbować ponownie później.",
            missingInput: "Wpisz użytkownika i hasło",
            infoRegulations: "Regulamin",
            infoPrivacy: "Polityka prywatności",
            infoHelp: "Pomoc",

            selectedLanguage: "Polski",
            language: "Język",
            languageSelect: [
                "Polski",
                "Angielski",
                "Niemiecki"
            ],
            languageDictionary: [{
                    language: "Polski",
                    key: "pl"
                },
                {
                    language: "Angielski",
                    key: "en"
                },
                {
                    language: "Niemiecki",
                    key: "de"
                }
            ]
        },
        passwordReset: {
            yourEmail: "Twój E-Mail",
            birthDate: "Data urodzenia",
            resetPassword: "Resetuj hasło",
            cancelBack: "Anuluj / Powrót",
            okBack: "OK / Powrót",
            needHelp: "Potrzebujesz pomocy?",
            linkSent: "Wysłaliśmy maila z linkiem do resetowania hasła, sprawdź swoją skrzynkę pocztową.",
            couldNotResetPassword: "Niepoprawny email lub data urodzenia",
            sendMailError: "Błąd wysyłki maila. Spróbuj ponownie za jakiś czas.",
            emailNotProvided: "Nie podano adresu email",
            birthDateNotProvided: "Nie podano daty urodzenia",
            incorrectEmailStructure: "Niepoprawna struktura email",
        },
        accountActivation: {
            title: "Aktywacja konta użytkownika",
            politicsAgreement1: "Oświadczam iż zapoznałem się i zgadzam się z obowiązującą Polityką Prywatności",
            politicsAgreement2: "Oświadczam iż zapoznałem się i zgadzam się z Warunkami Świadczenia Usługi",
            markAgreementsToContinue: "Zaznacz zgody, by móc korzystać z aplikacji",
            activatingAccount: "Aktywowanie konta...",
            activationFailed: "Aktywacja nie powiodła się",
            repeatPassword: "Powtórz hasło",
            password: "Hasło",
            activationLinkOutdated: "Przepraszamy Twój link stracił ważność. Wygeneruj nowy link i przejdź do poczty, aby go użyć",
            birthDate: "Data urodzenia",
            label1: "1. Ustaw hasło do konta",
            descriptionLabel1: "Aby ustawić hasło wprowadź datę urodzenia, a następnie wprowadź dwukrotnie hasło. Hasło musi składać się z minimum 8 znaków i zawierać przynajmniej jedną dużą literę oraz jedną cyfrę lub znak specjalny.",
            label2: "2. Potwierdź zgody",
            birthDateRequired: "Podanie daty urodzenia jest wymagane",
            invalidPasswordStructure: "Hasło musi zawierać min. 8 znaków, przynajmniej jedną dużą literę oraz jedną cyfrę lub znak specjalny",
            incorrectlyRepeatedPassword: "Niezgodne hasła",
            incorrectBirthDate: "Niepoprawna data urodzenia. Pozostały %d próby",
            errorTitle: "Wystąpił błąd",
            error500message: "Wystąpił wewnętrzny błąd serwera. Skonsultuj się z administratorem.",
            error404message: "Nie znaleziono konta pracownika do aktywacji - niepoprawny link aktywacyjny",
            error403message1: "Nie można było aktywować konta - spróbuj ponownie później",
            error403message2: "Nie można aktywować konta, które już jest aktywne",
            error400message1: "Nie można było aktywować konta - nieprawidłowe dane",
            error400message2: "Przekroczono liczbę dozwolonych prób aktywacji konta - spróbuj ponownie później",
            error400accountActivate: "Konto jest już aktywne",
            error429message: "Zbyt wiele zapytań dla niezautoryzowanego połączenia. Odczekaj chwilę przed następną próbą.",
            resendActivationLink: "Wygeneruj nowy link aktywacyjny",
            couldNotSendNewEmail: "Nie można było wysłać nowego linku aktywacyjnego",
            newActivationLinkSent: "Wysłano nowy link aktywacyjny, sprawdź swoją skrzynkę pocztową",
            birthDateNotProvided: "Nie podano daty urodzenia",
            tryAgainInAnHour: "Twoje konto zostało zablokowane na godzinę. Spróbuj ponownie później",
            gotoLoginPage: "Przejdź do strony logowania"
        },
        about: {
            pageTitle: "O Fast OnBoarding",
            header: "TWÓJ SILNIK IT DLA BIZNESU",
            version: "Wersja",
            privacyPolicy: "Polityka prywatności",
            learnMore: "Dowiedz się więcej",
            contact: "Kontakt / Zgłoszenie pomocy"
        },
        qr: {
            pageTitle: "Mój kod QR",
        },
        employeesPM: {
            pageTitle: "Pracownicy",
            myEmployees: "Moi pracownicy",
            unassigned: "Nieprzypisani",
            otherManagers: "Inni kierownicy",
            search: "Wyszukaj",
            nothingFound: "Nie znaleziono wyników",

            today: "Dzisiaj",
            yesterday: "Wczoraj",
            tomorrow: "Jutro",

            editMenu: {
                edit: "Edytuj"
            },

            sortBy: "Sortuj po",
            sortByDate: "Dacie",
            sortByProject: "Projekcie",
            sortByManager: "Kierowniku",

            warningDifferentOffBoardingDates: "Rekordy mają różne daty off-boardingu",

            alertWorkerNotValidForEditing: "Jeden z wybranych pracowników nie może być edytowany",
            alertWorkerNotValidForEditingDetails: "Przynajmniej jeden z wybranych pracowników nie może być edytowany. To ostrzeżenie zostało wyświetlone dla następującego pracownika:<br /><br /><b>{worker}</b><br /><br />Powód: Ten pracownik ma wiele boardingów przypisanych dla tego samego projektu.<br /><br />Wciąż możesz manipulować boardingami tego pracownika przy użyciu ekranu \"Projekty\". Możesz tam też rozwiązać ten problem.",

            errorLocalDataInconsistencyTitle: "Niezgodność danych hierarchii (brak kierownika)",
            errorLocalDataInconsistencyMessage: "Lokalnie przechowywane dane są nieprawidłowe. Spróbuj ponownie się zalogować.",

            errorInvalidWorkerTitle: "Nieprawidłowy pracownik",
            errorInvalidWorkerMessage: "Wybrany pracownik jest niepoprawny lub jego dane uległy uszkodzeniu",
            errorNoProjectsToDisplayTitle: "Brak projektów do wyświetlenia",
            errorNoProjectsToDisplay: "Wybrano pracowników, którzy nie posiadają wspólnych projektów. Możesz jednocześnie manipulować wieloma pracownikami tylko w sytuacji, kiedy dzielą oni przynajmniej jeden projekt.",
            specialSigns: [{
                    spec: 'Ą',
                    norm: 'A'
                },
                {
                    spec: 'Ć',
                    norm: 'C'
                },
                {
                    spec: 'Ę',
                    norm: 'E'
                },
                {
                    spec: 'Ł',
                    norm: 'L'
                },
                {
                    spec: 'Ń',
                    norm: 'N'
                },
                {
                    spec: 'Ó',
                    norm: 'O'
                },
                {
                    spec: 'Ś',
                    norm: 'S'
                },
                {
                    spec: 'Ź',
                    norm: 'Z'
                },
                {
                    spec: 'Ż',
                    norm: 'Z'
                },
            ],

            youDoNotHavePriviledgesToThisView: "Nie masz dostępu do tego widoku",
        },
        onBoardingPM: {
            pageTitle: "Boarding",
            nothingFound: "Nie znaleziono wyników",

            today: "Dzisiaj",
            yesterday: "Wczoraj",
            tomorrow: "Jutro",
            nodate: "Brak daty",

            projectUnassigned: "Nieprzypisany",

            filter: "Filtruj",

            sortBy: "Sortuj po",
            sortByDate: "Dacie",
            sortByProject: "Projekcie",
            sortByManager: "Kierowniku",

            filterAll: "Wszystko",

            tillToday: "Do dzisiaj",
            thisWeek: "Ten tydzień",
            later: "Później",

            unassigned: "Nieprzypisani",
            noProject: "Nieprzypisany",
            myEmployees: "Moi pracownicy",

            newBoarding: {
                title: "Nowy Boarding",
                firstName: "Imię",
                lastName: "Nazwisko",
                email: "E-Mail",
                pesel: "Pesel",
                boardingDate: "Data boardingu",
                project: "Projekt",
                noPesel: "Brak peselu"
            },

            editMenu: {
                edit: "Edytuj"
            },

            alertWorkerNotValidForEditing: "Jeden z wybranych pracowników nie może być edytowany",
            alertWorkerNotValidForEditingDetails: "Przynajmniej jeden z wybranych pracowników nie może być edytowany. To ostrzeżenie zostało wyświetlone dla następującego pracownika:<br /><br /><b>{worker}</b><br /><br />Powód: Ten pracownik ma wiele boardingów przypisanych dla tego samego projektu.<br /><br />Wciąż możesz manipulować boardingami tego pracownika przy użyciu ekranu \"Projekty\". Możesz tam też rozwiązać ten problem.",

            errorEmailAlreadyExistsTitle: "Email już przypisany",
            errorEmailAlreadyExists: "Pracownik z tym adresem email już istnieje w bazie danych",
            errorPeselAlreadyExistsTitle: "Pesel już przypisany",
            errorPeselAlreadyExists: "Pracownik z tym numerem pesel już istnieje w bazie danych",
            wantToAddBoardingForExistingWorker: "Czy chcesz dodać boarding dla tego pracownika?",
            errorProjectAlreadyAssigned: "Przynajmniej jeden z wybranych projektów jest już przypisany do tego pracownika",
            errorNoProjectsToDisplayTitle: "Brak projektów do wyświetlenia",
            errorNoProjectsToDisplay: "Wybrano pracowników, którzy nie posiadają wspólnych projektów. Możesz jednocześnie manipulować wieloma pracownikami tylko w sytuacji, kiedy dzielą oni przynajmniej jeden projekt.",
            errorNetworkOccured: "Błąd sieci",
            errorMessage: "Błąd sieci",
            errorCodeShort: "ADNBRD",
            errorCodeLong: "REQUEST_FAILED",

            youDoNotHavePriviledgesToThisView: "Nie masz dostępu do tego widoku",
        },
        userProfile: {
            myProfile: "Mój profil",
            workerProfile: "Profil",
            inSince: "w Fast OnBoarding od {since}",
            unassigned: "Nieprzypisane",
            permanentContract: "Na czas nieokreślony",
            unassignedProject: "Nieprzypisany",
            sinceDateToDate: "od {since} do {to}",
            sinceDate: "od {since}",

            editRating: "Edytuj ocenę",
            ratingComment: "Komentarz do oceny",

            contact: "Kontakt",
            privatePhone: "Telefon prywatny",
            businessPhone: "Telefon firmowy",
            chooseCountry: "Wybierz kraj",
            privateMail: "Mail prywatny",
            businessMail: "Mail firmowy",

            personalDetails: "Dane osobowe",
            birthDate: "Data urodzenia",
            personalAddress: "Adres zamieszkania",
            pesel: "Pesel",
            drivingLicense: "Prawo jazdy",
            contactAddressInformation: "Informacje adresu kontaktowego",
            additionalInformation: "Informacje dodatkowe",
            otherData: "Inne dane",
            noData: "Brak",

            contractDetails: "Dane umowy",
            beginDate: "Data rozpoczęcia",
            endDate: "Data zakończenia",

            stayDetails: "Dane pobytu",
            stayAddress: "Adres pobytu",

            contractEndDateQuestionTitle: "Przedłużenie kontraktu",
            contractEndDateQuestionContent: "Potwierdź przedłużenie umowy pracownika z {oldDate} do {newDate}",

            age: "lat",
            edit: "Edycja",
            close: "Zakończ",
            seeMoreProjects: "Zobacz więcej projektów",
            projectsList: "Lista projektów",

            location: {
                streetName: "Ulica",
                streetNumber: "Numer",
                propertyNumber: "Mieszkanie",
                zipCode: "Kod pocztowy",
                city: "Miasto",
                country: "Kraj"
            },

            lackOfContactPostalAddress: "Brak - Wybierz mieszkanie",

            countriesSelect: [{
                    key: "AL",
                    text: "Albania"
                },
                {
                    key: "AD",
                    text: "Andora"
                },
                {
                    key: "AT",
                    text: "Austria"
                },
                {
                    key: "BE",
                    text: "Belgia"
                },
                {
                    key: "BY",
                    text: "Białoruś"
                },
                {
                    key: "BA",
                    text: "Bośnia and Hercegowina"
                },
                {
                    key: "BG",
                    text: "Bułgaria"
                },
                {
                    key: "HR",
                    text: "Chorwacja"
                },
                {
                    key: "ME",
                    text: "Czarnogóra"
                },
                {
                    key: "CZ",
                    text: "Czechy"
                },
                {
                    key: "DK",
                    text: "Dania"
                },
                {
                    key: "EE",
                    text: "Estonia"
                },
                {
                    key: "FI",
                    text: "Finlandia"
                },
                {
                    key: "FR",
                    text: "Francja"
                },
                {
                    key: "GR",
                    text: "Grecja"
                },
                {
                    key: "ES",
                    text: "Hiszpania"
                },
                {
                    key: "NL",
                    text: "Holandia"
                },
                {
                    key: "IE",
                    text: "Irlandia"
                },
                {
                    key: "IS",
                    text: "Islandia"
                },
                {
                    key: "KZ",
                    text: "Kazachstan"
                },
                {
                    key: "XK",
                    text: "Kosowo"
                },
                {
                    key: "LI",
                    text: "Liechtenstein"
                },
                {
                    key: "LT",
                    text: "Litwa"
                },
                {
                    key: "LV",
                    text: "Łotwa"
                },
                {
                    key: "MK",
                    text: "Macedonia"
                },
                {
                    key: "MT",
                    text: "Malta"
                },
                {
                    key: "MD",
                    text: "Mołdawia"
                },
                {
                    key: "MC",
                    text: "Monaco"
                },
                {
                    key: "DE",
                    text: "Niemcy"
                },
                {
                    key: "NO",
                    text: "Norwegia"
                },
                {
                    key: "PL",
                    text: "Polska"
                },
                {
                    key: "PT",
                    text: "Portugalia"
                },
                {
                    key: "RU",
                    text: "Rosja"
                },
                {
                    key: "RO",
                    text: "Rumunia"
                },
                {
                    key: "SM",
                    text: "San Marino"
                },
                {
                    key: "XS",
                    text: "Serbia"
                },
                {
                    key: "SK",
                    text: "Słowacja"
                },
                {
                    key: "SI",
                    text: "Słowenia"
                },
                {
                    key: "CH",
                    text: "Szwajcaria"
                },
                {
                    key: "SE",
                    text: "Szwecja"
                },
                {
                    key: "TR",
                    text: "Turcja"
                },
                {
                    key: "UA",
                    text: "Ukraina"
                },
                {
                    key: "HU",
                    text: "Węgry"
                },
                {
                    key: "GB",
                    text: "Wielka Brytania"
                },
                {
                    key: "IT",
                    text: "Włochy"
                },
            ],

            youDoNotHavePriviledgesToThisView: "Nie masz dostępu do tego widoku",
        },
        documents: {
            title: "Dokumenty",
            myDocumentsTitle: "Moje dokumenty",
            correctUploadInfo: "Załączono zdjęcia",
            errorUploadInfo: "Wystąpił błąd przy próbie załączenia zdjęcia",
            attachPicturesInfo: "Załącz zdjęcia",
            noDocuments: "Brak dokumentów",
            noMatchingDocumentsFound: "Brak wyników wyszukiwania",
            couldNotDownloadDocument: "Próba pobierania nie powiodła się",
            search: "Wyszukaj",
            groupBy: "Grupuj po",
            sortBy: "Sortuj po",
            name: "Nazwa",
            date: "Data",
            download: "Pobierz",
            fromMostRecent: "Od najnowszych",
            fromLeastRecent: "Od najstarszych",
            alphabeticalAscending: "Alfabetycznie A-Z",
            alphabeticalDescending: "Alfabetycznie Z-A",
            dontGroup: "Brak",
            byType: "Po typie",
            byDate: "Po dacie",
            lastWeek: "Ostatni tydzień",
            lastMonth: "Ostatni miesiąc",
            lastYear: "Ostatni rok",
            elder: "Starsze",
            downloadingDocuments: "Pobieranie dokumentów",
            areYouSureYouWantDownloadAllDocuments: "Czy na pewno chcesz pobrać wszystkie dokumenty",
            cancel: "Anuluj",

            workersDocuments: "Dokumenty pracowników",
            workerFullName: "Nazwisko i imię",
            workerDateBirth: "Data urodzenia",
            documentCreatedAt: "Stworzono",
            documentTypeShort: "Typ",
            documentTypeLong: "Typ dokumentu",
            documentName: "Nazwa dokumentu",
            allTypesSelected: "Wszystkie",
            chooseDataTableColumns: "Wybierz kolumny tabeli",
            modify: "Zmodyfikuj",
            preview: "Podgląd",

            save: "Zapisz",
            changeDocumentName: "Zmień nazwę",
            changeDocumentType: "Zmień typ dokumentu",
            updateDocumentFile: "Aktualizuj dokument",

            update: "Aktualizuj",
            changeType: "Zmień typ",
            chooseNewFile: "Wybierz nowy plik",
            lackOfPermission: "Nie masz uprawnień dostępu do tego widoku",
            incorrectRequest: "Nieprawidłowe dane żądania",

            recordNotModified: "Nie zaktualizowano dokumentu",
            recordUpdatedSuccessfully: "Zaktualizowano dokument",
            lackOfPermissionToUpdateDocument: "Nie masz uprawnień do aktualizowania dokumentu",

            couldNotAddNewDocument: "Nie można było dodać nowego dokumentu",
            documentAddedSuccessfully: "Pomyślnie dodano dokument",
            addNewDocument: "Dodaj nowy dokument",
            chooseWorker: "Wybierz pracownika",
            startWritingToFind: "Zacznij pisać aby wyszukać",
            overrideExistingFiles: "Nadpisz istniejące pliki",
            uploadingInProgress: "Trwa dodanie dokumentu...",
            sorryWeCouldntConnectToServer: "Przepraszamy, nie można było połączyć się z serwerem",
            couldNotDisplayDocument: "Nie można było wyświetlić dokumentu",
            importDocuments: "Importuj dokumenty",
            rowsPerPage: "Elementów na stronę",
            of: "z",

            delete: "Usuń",
            deleteDocument: "Usuń dokument",
            areYouSureYouWantToDeleteThisDocument: "Czy na pewno chcesz usunąć wybrany dokument?",
            assignedTo: "Przypisany do",
            documentName: "Nazwa dokumentu",
            birthDatePrefix: "ur.",

            unassigned: "Nieprzypisane",
            duplicateFilenameTitle: "Plik o takiej nazwie już istnieje",
            proposedFilename: "Proponowana nazwa",
            ok: "OK",
        },
        projects: {
            title: "Projekty",
            states: [{
                    name: "Aktywny",
                    stateKey: "A"
                },
                {
                    name: "Planowany",
                    stateKey: "P",
                },
                {
                    name: "Historyczny",
                    stateKey: "H",
                }
            ],

            selectItems: [
                "Aktywny",
                "Historyczny",
                "Planowany",
            ],

            addProject: "Dodaj projekt",
            comment: "Komentarz",
            project: "Projekt",
            type: "Typ",
            assignmentStartDate: "Data zatrudnienia",
            onBoardingDate: "Data przyjazdu",
            cancel: "Anuluj",
            confirm: "Zatwierdź",
            confirmEmployment: "Potwierdź zatrudnienie",
            assuranceEmployment: "Czy na pewno chcesz potwierdzić zatrudnienie tego pracownika na ten projekt?",
            no: "Nie",
            yes: "Tak",
            removeBoarding: "Usuń boarding",
            assuranceRemovement: "Czy na pewno chcesz usunąć boarding dla tego pracownika? Zniknie on jakby nigdy nie istniał",
            employeeResignation: "Rezygnacja pracownika",
            offBoardingDate: "Data wyjazdu",
            terminateEmployment: "Zwolnij pracownika",
            changeProject: "Zmień projekt",
            contractEndDate: "Data rozwiązania kontraktu",
            projectEndDate: "Data sfinalizowania projektu",
            editBoarding: "Edytuj boarding",
            contractStartDate: "Data podpisania kontraktu",
            projectStartDate: "Data rozpoczęcia projektu",
            unassigned: "Nieprzypisany",
            hired: "Zatrudniony",
            historical: "Historyczny",
            planned: "Planowany",
            fulltime: "Pełny etat",
            parttime: "Część etatu",
            timebased: "Oparta na czasie",
            fixedprice: "Unormowana płaca",
            saving: "Zapisuję",
            errorOccuredTitle: "Błąd",
            errorOccured: "Wystąpił błąd. Tylko tyle wiemy.",
            cannotAddProjectTitle: "Nie można dodać projektu",
            cannotAddProjectMessage: "Nie można przypisać żadnych projektów do tego pracownika",
            sendingRequest: "Wysyłam zapytanie",
            requestError: "Błąd zapytanie",
            requestErrorMessage: "Wystąpił błąd w trakcie wykonywania zapytania",
            noDate: "Brak daty",
            cannotLoadPageTitle: "Błąd ładowania strony",
            cannotLoadPageMessage: "Wystąpił błąd w trakcie ładowania strony",
            noInformation: "Brak informacji",

            projectAdded: "Projekt dodany",
            projectUpdated: "Projekt zaktualizowany",
            projectRemoved: "Projekt usunięty",
            changeWorkType: "Zmień typ pracy",
            details: "Szczegóły projektu",
            close: "Zamknij",
        },
        settings: {
            title: "Ustawienia",
            settings: {
                preferences: {
                    title: "Preferencje",
                    description: "Zmień język, zarządzaj powiadomieniami"
                },
                changePassword: {
                    title: "Bezpieczeństwo",
                    description: "Zmień hasło"
                }
            }
        },
        changePassword: {
            title: "Zmiana hasła",
            headerPrefix: "Hasło do konta",
            pageContent: "Aby ustawić nowe hasło wprowadź stare hasło, a następnie wprowadź dwukrotnie nowe hasło. Hasło musi składać się z conajmniej 8 znaków i zawierać przynajmniej jedną dużą literę oraz jedną cyfrę lub znak specjalny.",
            cancel: "Anuluj",
            saveChanges: "Zapisz zmiany",
            oldPassword: "Stare hasło",
            newPassword: "Nowe hasło",
            repeatPassword: "Powtórz hasło",
            updatedSuccessfully: "Zaktualizowano pomyślnie",
            couldNotUpdate: "Nie można było zaktualizować hasła",
            updatingInProgress: "Trwa aktualizacja hasła...",
            oldPasswordNotProvided: "Nie podano aktualnego hasła",
            invalidPasswordStructure: "Hasło musi zawierać min. 8 znaków, przynajmniej jedną dużą literę oraz jedną cyfrę lub znak specjalny",
            incorrectlyRepeatedPassword: "Niezgodne hasła",
            incorrectPassword: "Niepoprawne hasło",
            newPasswordNotProvided: "Nie podano nowego hasła",
            newPasswordCannotBeTheSameAsOldOne: "Nowe hasło nie może być takie samo jak stare",
        },
        pdfViewer: {
            couldNotDisplayDocument: "Nie można było wyświetlić dokumentu",
            couldNotDownloadDocument: "Próba pobierania nie powiodła się"
        },
        settingPassword: {
            title: "Ustawienie hasła",
            headerPrefix: "Hasło do konta",
            pageContent: "Aby ustawić hasło wprowadź datę urodzenia, a następnie wprowadź dwukrotnie nowe hasło. Hasło musi składać się z conajmniej 8 znaków i zawierać przynajmniej jedną dużą literę oraz jedną cyfrę lub znak specjalny.",
            saveChanges: "Zapisz zmiany",
            newPassword: "Nowe hasło",
            repeatPassword: "Powtórz hasło",
            updatedSuccessfully: "Zaktualizowano pomyślnie",
            couldNotUpdate: "Nie można było zaktualizować hasła",
            updatingInProgress: "Trwa ustawianie hasła...",
            passwordNotProvided: "Nie podano hasła",
            birthDateNotProvided: "Nie podano daty urodzenia",
            invalidPasswordStructure: "Hasło musi zawierać min. 8 znaków, przynajmniej jedną dużą literę oraz jedną cyfrę lub znak specjalny",
            incorrectlyRepeatedPassword: "Niezgodne hasła",
            incorrectPassword: "Niepoprawne hasło",
            globalErrorMessage: "Wystąpił błąd, przepraszamy",
            birthDate: "Data urodzenia",
            linkOutdated: "Link resetujący uległ przedawnieniu",
            incorrectBirthDate: "Niepoprawna data urodzenia. Pozostały %d próby",
            incorrectBirthDateOneLeft: "Niepoprawna data urodzenia. Pozostała %d próba",
            tryAgainChangePasswordLaterTitle: "Momencik",
            tryAgainChangePasswordLaterMessage: "Wykorzystano maksymalną liczbę prób resetowania hasła - spróbuj ponownie później.",
        },
        importDocuments: {
            title: "Import dokumentów",
            networkError: "Błąd sieci. Sprawdź swoje połączenie.",
            beforeUpload: {
                userInfoPrefix: "Uwaga!",
                userInfoWithoutLinkPart: "Plik powinien być przygotowany zgodnie z ",
                userInfoLink: "szablonem",
                attachmentsPanelHeader: "Wczytaj plik ZIP z dokumentami pracowników",
                loadFiles: "Wczytaj",
                filesSizeInfo: "Maksymalna wielkość pliku 50MB",
                importTemplateStructure: "Struktura szablonu",
                excelStructure: "Struktura Excel",
                sampleZipContent: "Przykładowa zawartość paczki zip",
                fileNameDescription: "Jeżeli nazwa techniczna pliku nie będzie posiadała rozszerzenia automatycznie zostanie dodane rozszerzenie .pfd. Jeżeli nazwa docelowa pliku będzie pusta to dokument automatycznie przyjmnie taka sama nazwę jak plik wejściowy. Jeżeli nazwa docelowa pliku nie będzie zawierać rozszerzenia automatycznie zostanie dopisane takie jakie posiada dokument wejściowy.",
            },
            download: "Pobierz",
            omitHeadersOption: "Pomijaj nagłówki",
            overrideExistingFiles: "Nadpisuj istniejące pliki",
            uploadWithDocumentTypeOption: "Importuj dokumenty z ich typem",
            otherDocumentType: "Inny",
            validatedZip: {
                uploadedZip: "Pobrano zip : ",
                documentsToAdd: {
                    label: "Dokumentów do dodania : ", //"Do dodania",
                    suffixSingular: "dokument",
                    suffixPlural: "dokumenty"
                },
                selectFilesStatuses: {
                    label: "Status pliku",
                    ok: "OK",
                    warning: "Z ostrzeżeniem",
                    error: "Z błędem"
                },
                filtersHeader: "Parametry dodawania",
                tableHeaders: {
                    id: "Nr",
                    inputFilename: "Plik wejściowy",
                    workerName: "Pracownik",
                    outputFilename: "Plik wyjściowy",
                    status: "Status",
                    comment: "Uwagi",
                    action: "Akcja",
                    documentType: "Typ dokumentu",
                },
                cancelHeader: "Anuluj",
                addDocumentsButtonLabel: "Dodaj dokumenty",
                uploadWithoutErrorsMessage: "Uwaga! Dokumenty z błędami nie zostaną dodane.",
                goBack: "Powrót",
                search: "Wyszukaj",
            },
            validation: {
                overlayerMessage: "Trwa sprawdzanie plików",
                excelLackMessage: "W załadowanym ZIP nie znaleziono pliku excel",
                tooLargeZipLoadedMessage: "Za duży plik ZIP, maksymalna wielkość to 50 MB",
                invalidZipStructure: "Niemożliwe rozkodowanie pliku ZIP (błędna struktura) - brak pliku 'import.xlsx'",
                correctFiles: "Popraw ZIP i wczytaj ponownie",
                goBack: "Powrót",
                pageZipErrorMessageHeader: "Błąd podczas wczytywania",
                fileIsNotPresentInExcel: "Brak informacji o tym pliku w podanym excelu",
                invalidDocTypeWarningMessage: "Nieprawidłowy typ dokumentu : {typeInExcel} - przypisano domyślny '{defaultType}'.",
                unknownUserIncorrectPesel: "Nieznany pracownik - prawdopodobnie podano niepoprawny PESEL",
                errorOccurredWhileReadingInputFilename: "Wystąpił błąd przy próbie odczytu nazwy pliku wejściowego - prawdopodobnie nie została ona podana",
                errorOccurredWhileReadingOutputFilename: "Wystąpił błąd przy próbie odczytu nazwy pliku wyjściowego - prawdopodobnie nie została ona podana",
                duplicateFilename: "Duplikat dokumentu pracownika",
                fileEmpty: "Plik jest pusty",
                fileWillBeOverwritten: "Dokument pracownika zostanie nadpisany nowym",
            },
            uploadingFiles: "Trwa wysyłanie plików",
            lackOfPermission: "Nie masz uprawnień dostępu do tego widoku",
            couldNotProcessAllSentFiles: "Nie można było przeprocesować wszystkich wysłanych plików",
            filename: "Nazwa pliku",
            errorContent: "Treść błędu",
            filesUploadedCorrectly: "Pomyślnie dodano {0} plików",
            filesUploadedButSomeErrorsOccurred: "Przetworzono pliki - w niektórych wystąpiły błędy podczas próby zapisu",
            inputFilenameNotProvided: "Brakuje informacji o pliku wejściowym z pliku .zip",
            fileNotFoundInZip: "Nie znaleziono pliku wejściowego w załączonym zip",

            importSummaryHeaders: "Podsumowanie importu",
            importDocumentsNumber: "Importowanych plików",
            excludedFilesNumber: "Wykluczonych z importu",
            errorDocumentsNumber: "Błędnych plików",
            warningDocumentsNumber: "Ostrzeżeń",

            invalidInputFileGlobalMessage: "Wczytany plik nie jest paczką *.zip",

            rowError: "Pierwsze 100 rekordów jest błędne. Sprawdź i popraw strukture pliku excel, a następnie spróbuj ponownie.",
            defaultDocumentType: "Domyślny typ dokumentu",

            uploadBarPreparingRequest: "Przygotowywanie zapytania...",
            uploadBarUploading: "Wysyłanie plików...",
            uploadBarProcessing: "Przetwarzanie plików...",
            uploadBarComplete: "Import zakończony",
            couldNotDownloadSummaryFile: "Nie można było pobrać pliku podsumowania importu",
            downloadImportSummary: "Pobierz podsumowanie importu",
        },
        adminPanel: {
            adminPanelTitle: "Panel administracyjny",
            addUsersNotification: "Dodaj użytkowników do systemu!",
            addUsers: "Dodaj użytkowników",
            activeUsers: "AKTYWNI UŻYTKOWNICY",
            administrators: "Administratorów",
            usersPanel: "Użytkowników",
            activeWorkers: "PRACOWNICY",
            workers: "Pracowników",
            activeDocuments: "DOKUMENTY",
            documentsSize: "Wielkość",
            officeData: "Dane firmy",
            officeDataDescription: "Zaktualizuj informacje o swojej firmie",
            settlement: "Rozliczenia",
            settlementDescription: "Sprawdź rozliczenia i zarządzaj licencjami",
            dictionaries: "Słowniki",
            dictionariesDescription: "Definiuj własne typy i statusy",
            permissions: "Uprawnienia",
            permissionsDescription: "Zarządzaj dostępami do modułów",
            users: "Użytkownicy",
            usersDescription: "Dodaj lub zarządzaj użytkownikami",
            youDoNotHavePriviledgesToThisView: "Nie masz dostępu do tego widoku",
            addresses: "Adresy",
            addressesDescription: "Definiuj swoje własne adresy",
        },
        addUserPanel: {
            addUserTitle: "Dodaj użytkownika",
            basicDataSection: "DANE PODSTAWOWE",
            surname: "Nazwisko",
            name: "Imię",
            email: "E-mail",
            phonenumber: "Telefon",
            personalDataSection: "DANE OSOBOWE",
            birthDate: "Data urodzenia",
            pesel: "PESEL",
            securitySection: "BEZPIECZEŃSTWO",
            role: "Rola",
            loginSection: "LOGIN",
            login: "Unikalny login",
            cancel: "Anuluj",
            submit: "Zapisz",
            administrator: "Administrator",
            hrManager: "Menadżer HR",
            projectManager: "Menadżer Projektu",
            manager: "Kierownik",
            sendEmail: "Wyślij Email do aktywacji konta",
            dataAccess: "Dostęp do danych",
            allRecords: "Wszystkie rekordy",
            myRecords: "Własne rekordy",
            myProjectRecords: "Rekordy pracowników w moich projektach",
            lastNameRequired: "Nazwisko jest wymagane",
            lastNameCharakters: "Nazwisko musi mieć mniej niż 50 znaków",
            firstNameRequired: "Imię jest wymagane",
            firstNameCharakters: "Imię musi mieć mniej niż 30 znaków",
            emailRequired: "Email jest wymagany",
            emailValid: "Niepoprawny email",
            roleRequired: "Rola jest wymagana",
            dataAccessRequired: "Dostęp do danych jest wymagany",
            loginRequired: "Login jest wymagany",
            errorInvalidUserFirstName: "Niepoprawne imię",
            errorInvalidUserLastName: "Niepoprawne nazwisko",
            errorInvalidUserBirthDate: "Niepoprawna data urodzenia",
            errorInvalidUserEmail: "Niepoprawny email",
            errorInvalidUserPhonenumber: "Niepoprawny numer telefonu",
            errorInvalidUserPesel: "Niepoprawny pesel",
            errorInvalidUserRole: "Rola jest wymagana",
            errorInvalidUserDataAccess: "Dostęp do danych jest wymagany",
            errorInvalidUserLogin: "Niepoprawny login",
            adding: "Dodawanie",
            errorAddUser: "Wystąpił błąd podczas dodawania użytkownika. Skontaktuj się z administratorem serwera",
            addUserSuccess: "Pomyślnie dodano użytkownika!",
            errorInvalidFields: "Proszę poprawnie uzupełnić wszystkie wymagane pola formularza",

            youDoNotHavePriviledgesToThisView: "Nie masz dostępu do tego widoku",
        },
        configurationPanel: {
            configurationTitle: "Słowniki",
            documentsSection: "Dokumenty",
            addDocumentType: "Dodaj typ dokumentu",
            add: "Dodaj",
            documentTypes: "Utworzone typy dokumentów",
            name: "Nazwa",
            action: "Akcja",
            deactivate: "Deaktywuj",
            activate: "Aktywuj",
            save: "Zapisz",
            boardingSection: "Boarding",
            addBoardingStatus: "Dodaj status boardingu",
            boardingStatuses: "Utworzone statusy boardingów",
            editDocumentType: "Edytuj typ dokumentu",
            cancel: "Anuluj",
            editBoardingStatus: "Edytuj status boardingu",
            understand: "Rozumiem",
            error: "Błąd",
            errorTypeText: "Niepoprawna nazwa typu dokumentu",
            errorDocumentExists: "Istnieje typ dokumentu o podanej nazwie",
            errorBoardingExists: "Nie można dodać podanego statusu boarding, ponieważ już on istnieje.",
            errorBoardingName: "Niepoprawna nazwa statusu boardingu",

            documentTypesUpdatingMessage: "Aktualizowanie typów dokumentów w toku...",
            documentTypesUpdatedSuccessfully: "Typy dokumentów zaktualizowane pomyślnie!",
            documentTypesUpdateFailed: "Nie można było zaktualizować typów dokumentów",
            cannotSaveWithoutChanges: "Nie wprowadzono zmian",
            youDoNotHavePriviledgesToThisView: "Nie masz dostępu do tego widoku",
        },
        userListPanel: {
            userListTitle: "Użytkownicy",
            search: "Wyszukaj",
            active: "Aktywni",
            administrators: "Administratorów",
            users: "Użytkowników",
            status: "Status",
            role: "Rola",
            chooseColumns: "Wybierz kolumny do wyświetlenia",
            cancel: "Anuluj",
            save: "Zapisz",
            delete: "Usuń",
            firstAndLastName: "Nazwisko i imię",
            birthDate: "Data urodzenia",
            role: "Rola",
            dataAccess: "Dostęp do danych",
            status: "Status",
            lastLogin: "Ostatnio zalogowany",
            emailStatus: "Email status",
            emailStatusNotSent: "Niewysłany",
            emailStatusSent: "Wysłany",
            emailStatusError: "Błąd wysyłki emaila",
            emailStatusErrorConnection: "SMTP Serwer odrzucił połączenie",
            emailStatusErrorMessage: "SMTP Serwer odrzucił wiadomość",
            emailStatusErrorRecipients: "SMTP Serwer odrzucił odbiorcę",
            emailStatusErrorSender: "SMTP Serwer odrzucił wysyłającego",
            emailStatusErrorInvlidResponse: "SMTP Serwer zwrócił nieprawidłową odpowiedź",
            emailStatusErrorCloseConn: "SMTP Serwer zamknął połączenie",
            emailStatusErrorException: "SMTP Serwer zwrócił nieznany wyjtek",
            emailStatusErrorDefault: "Nieznany błąd",

            rowsPerPage: "Elementów na stronę",
            of: "z",

            all: "Wszystkie",
            inactive: "Nieaktywni",
            sendingEmail: "Wysyłanie maila...",
            invalidUserIdentifier: "Niepoprawny identyfikator użytkownika",
            couldNotSendMailServerBusy: "Nie można było wysłać maila przez obciążenie serwera",
            couldNotSendMail: "Nie można było wysłać maila",
            mailSent: "Mail dodany do kolejki. Powinien zostać wysłany w przeciągu kilku minut.",
            sendActivationMail: "Wyślij mail aktywacyjny",

            administrator: "Administrator",
            HRManager: "HR Manager",
            projectManager: "Project manager",
            worker: "Pracownik",
            lackOfData: "Brak",
            activeStatus: "Aktywny",
            inactiveStatus: "Nieaktywny",
            youDoNotHavePriviledgesToThisView: "Nie masz dostępu do tego widoku",
            editData: "Edytuj dane",

            unblockUser: "Odblokuj użytkownika",
            areYouSureYouWantToUnblockUser: "Czy na pewno chcesz odblokować użytkownika {fullname} (login '{username}')?",
            blockUser: "Zablokuj użytkownika",
            areYouSureYouWantToBlockUser: "Czy na pewno chcesz zablokować użytkownika {fullname} (login '{username}')?",
            unblock: "Odblokuj",
            block: "Zablokuj",
            yes: "Tak",
            unblockingAccountInProgress: "Odblokowywanie użytkownika...",
            blockingAccountInProgress: "Blokowanie użytkownika...",

            errorOccurred: "Wystąpił błąd",
            invalidRequestData: "Nieprawidłowe dane żądania",
            couldNotFindUserToUnblock: "Nie można było znaleźć użytkownika, którego chcesz odblokować",
            couldNotFindUserToBlock: "Nie można było znaleźć użytkownika, którego chcesz zablokować",
            waitAMinute: "Poczekaj chwilę",
            tooManyRequestsSent: "Wysłałeś zbyt dużo żądań w krótkim czasie - poczekaj chwilę i spróbuj ponownie później",
            errorOccurredThatsAllWeKnow: "Wystąpił błąd - tylko tyle wiemy",

            blocked: "Zablokowany",
            blockedStatus: "Zablokowani",

            deleteUserContent: "Czy na pewno chcesz usunąć użytkownika:",
            deleteUserTitle: "Usuń użytkownika",
            couldNotFindUserToDelete: "Nie można było znaleźć użytkownika, którego chcesz usunąć",
            deleteUserSuccess: "Poprawnie usunięto użytkownika",
            users: "Użytkownicy",

            worker: "Pracownik",
            pesel: "Pesel",
            email: "Email",
        },
        preferences: {
            title: "Preferencje",
            language: "Język",
            notifications: "Powiadomienia",
            notificationsAppCheckbox: "Wysyłaj powiadomienia w aplikacji",
            notificationsEmailCheckbox: "Wysyłaj powiadomienia na e-mail",
            notificationsMessage: "Powiadamiaj o nowych dokumentach:",
            notificationsHour: "Godzina",
            notificationsWeekday: "Dzień",
            buttonCancel: "Anuluj",
            buttonSave: "Zapisz zmiany",

            loading: "Ładowanie...",
            pickTime: "Podaj czas",
            cancel: "Anuluj",
            confirm: "Zatwierdź",
            asap: "Jak najszybciej",
            hourly: "Co godzinę",
            daily: "Co dzień",
            weekly: "Co tydzień",

            weekday: {
                monday: "Poniedziałek",
                tuesday: "Wtorek",
                wednesday: "Środa",
                thursday: "Czwartek",
                friday: "Piątek",
                saturday: "Sobota",
                sunday: "Niedziela"
            },
            savingChanges: "Zapisuje zmiany...",
            updatedCorrectly: "Zapisano zmiany pomyślnie!",
            couldNotUpdate: "Nie można było zapisać zmian",
            couldNotFetchSettingsData: "Nie można było pobrać ustawień",
            turnOnNotifications: "Włącz powiadomienia na urządzenie",
        },
        dsConfig: {
            dsConfigTitle: "Konfiguracja dostępu do danych",
            noPermissions: "Brak uprawnień",
            pickedNumber: "Zaznaczono {0}",
            confirm: "Zatwierdź",
            cancel: "Anuluj",
            save: "Zapisz",
            access: "Dostęp",
            create: "Tworzenie",
            edit: "Edycja",
            delete: "Usuwanie",
            accept: "Zaakceptuj",
            roleName: "Nazwa roli",
            lackOfPermissions: "Brak uprawnień",
            expand: "Rozwiń",
        },
        mdlConfig: {
            mdlConfigTitle: "Konfiguracja Roli",
            module: "Moduły",
            read: "Odczyt",
            add: "Dodawanie",
            edit: "Edycja",
            delete: "Usuwanie",
            cancel: "Anuluj",
            save: "Zapisz",
            youDoNotHavePriviledgesToThisView: "Nie masz dostępu do tego widoku",
            roleName: "Nazwa roli",
            changesUpdated: "Zaktualizowano zmiany!",
            noSuchRecord: "Rekord o podanym ID nie istnieje",
            checkAll: "Zaznacz wszystko",
            access: "Dostęp",
            creating: "Tworzenie",
            pageNotFound: "Nie znaleziono podanej strony",
            unrecognizedError: "Wystąpił nieznany błąd",
            loading: "Wczytywanie",
            errorLoading: "Podczas wczytywania strony wystąpił błąd",
            noPermissions: "Brak uprawnień",
            numberPicked: "Zaznaczono {0}",
            accept: "Zatwierdź",
            invalidRoleName: "Ta nazwa użytkownika jest już zajęta",
            roleEmpty: "Nazwa roli nie może być pusta",
            incorrectRequest: "Nieprawidłowe dane żądania",
        },
        permissions: {
            title: "Uprawnienia",
            mdlRole: "Role dostępu do modułów",
            dsRole: "Role dostępu do danych",
            addGroup: "Dodaj grupę",
            deleteGroup: "Usuń grupę",
            deleteGroupContent: "Na pewno chcesz usunać grupe?",
            groupName: "Nazwa grupy",
            cancelButton: "Anuluj",
            deleteButton: "Usuń",
            duplicateGroup: "Zduplikuj grupę",
            duplicateGroupContent: "Na pewno chcesz stworzyć nowa grupe na podstawie:",
            createButton: "Stwórz",
            headerName: "Nazwa",
            headerUsers: "Użytkownicy",
            headerActions: "Akcje",
            youDoNotHavePriviledgesToThisView: "Nie masz dostępu do tego widoku",
            actionEdit: "Edytuj",
            actionDuplicate: "Duplikuj",
            actionDelete: "Usuń",
            showMore: "Pokaż więcej",
            showLess: "Pokaż mniej"
        },
        userDialog: {
            lastName: "Nazwisko",
            firstName: "Imię",
            email: "Email",
            phoneNumber: "Numer telefonu",
            birthDate: "Data urodzenia",
            pesel: "PESEL",
            role: "Rola",
            dataAccess: "Dostęp do danych",
            login: "Login",
            loginTaken: "Ten login jest już zajęty",

            headers: {
                security: "Bezpieczeństwo",
                login: "Login",
                personalData: "Dane osobowe",
                basicData: "Dane podstawowe",
            },

            editDialogTitle: "Edycja użytkownika",
            addDialogTitle: "Nowy użytkownik",

            validators: {
                lastName: {
                    lastNameRequired: "Nazwisko jest wymagane",
                    lastNameTooLong: "Nazwisko jest zbyt długie",
                    invalidLastNameStructure: "Niepoprawna struktura nazwiska",
                },
                firstName: {
                    firstNameRequired: "Imię jest wymagane",
                    firstNameTooLong: "Imię jest zbyt długie",
                    invalidFirstNameStructure: "Niepoprawna struktura imienia",
                },
                email: {
                    emailRequired: "Email jest wymagany",
                    invalidEmailStructure: "Niepoprawna struktura email",
                },
                role: {
                    roleRequired: "Rola jest wymagana",
                },
                dataAccess: {
                    dataAccessRequired: "Informacja o dostępie do danych wymagana",
                },
                login: {
                    loginFieldValueRequired: "Login użytkownika jest wymagany",
                },
                birthDate: {
                    birthDateRequired: "Data urodzenia jest wymagana",
                },
                phoneNumber: {
                    invalidPhoneNumberStructure: "Nieprawidłowa struktura numeru telefonu",
                    areaCode: "Nie wpisano numeru kierunkowego",
                },
            },

            noWorker: "Brak pracownika",
            newWorker: "Nowy pracownik",
            existingWorker: "Istniejący pracownik",
            worker: "Pracownik",
            sendActivationMail: "Wyślij mail aktywacyjny",
            sendingUserCreateRequest: "Tworzę użytkownika...",
            sendingUserUpdateRequest: "Aktualizuję użytkownika...",
            sendingUserRemoveRequest: "Usuwam pracownika...",

            errors: {
                error400: {
                    worker: {
                        INVALID_WORKER_CONFIG: "Niepoprawne żądanie dot. pracownika",
                        INVALID_WORKER_CONFIG_NO_ID: "Nie podano ID pracownika",
                        INVALID_WORKER_ID_DOES_NOT_EXIST: "Nie znaleziono pracownika o podanym ID",
                        INVALID_WORKER_TAKEN_OPERATION: "Nie można było powiązać profilu użytkownika z istniejącym pracownikiem - jest on obecnie powiązany z innym",
                    },
                    worker_option: {
                        INVALID_WORKER_CONFIG: "Niepoprawne żądanie dot. pracownika",
                        INVALID_REMOVE_WORKER_OPERATION: "Nie można było odpiąć pracownika od użytkownika",
                        INVALID_WORKER_EXIST_OPERATION: "Nie można było utworzyć nowego pracownika dla użytkownika, ponieważ już takowy istnieje",
                        INVALID_WORKER_ASSIGNED_OPERATION: "Nie można było podpiąć istniejącego pracownika, ponieważ jest już on podpięty pod innego użytkownika",
                    },
                },
                error401: {
                    message: "Twoja sesja wygasła - zaloguj się i spróbuj ponownie",
                },
                error403: {
                    message: "Nie masz wystarczających uprawnień by wprowadzić modyfikacje",
                },
                error404: {
                    message: "Nie znaleziono odbiorcy zapytania",
                },
                error429: {
                    message: "Zbyt dużo prób w krótkim czasie - spróbuj ponownie później",
                },
                anyError: {
                    message: "Wystąpił błąd - tylko tyle wiemy",
                }
            },

            yesNoDialogTitle: "Usuń pracownika",
            yesNoDialogContent: "Czy na pewno chcesz usunąć pracownika dla użytkownika {username}?",

            delete: "Usuń",
            otherUserFunctionalitiesMessage: "Dodatkowe funkcjonalności Pracownika: portal pracownika, struktura organizacyjna, dokumenty, boardings",
            assignWorkerMessage: "Powiązać można tylko pracowników, którzy nie są związani z kontem użytkownika - jeden pracownik może mieć tylko jednego użytkownika",
            cancel: "Anuluj",
            save: "Zapisz",

            pickWorker: "Wybierz pracownika*",
            requiredField: "To pole jest wymagane.",
            errorsInForm: "W formularzu są błędy.",
            invalidLastName: "Niepoprawna wartość pola 'nazwisko'.",
            invalidFirstName: "Niepoprawna wartość pola 'imię'.",
            invalidEmail: "Niepoprawna wartość pola 'email'.",
            invalidPhoneNumber: "Niepoprawna wartość pola 'numer telefonu'.",
            invalidBirthDate: "Niepoprawna wartość pola 'data urodzenia'.",
            peselAssignedToOtherWorker: "Wpisany PESEL jest już przypisany do innego pracownika.",
            invalidPesel: "Niepoprawna wartość pola 'PESEL'.",
            unrecognizedErrorInBasicDataSection: "Wystąpił nieznany błąd w sekcji 'dane podstawowe'.",
            requestedUserNotFound: "Użytkownik którego chcesz edytować nie został znaleziony.",
            youSentTooManyRequests: "Wysłałeś zbyt dużo żądań do serwera. Poczekaj chwilę przed zatwierdzeniem formularza.",
            unrecognizedErrorOcurredWhileSendingForm: "Wystąpił nieznany błąd podczas wysyłania formularza - spróbuj ponownie. Jeśli błąd będzie się powtarzać, skontaktuj się z administratorem.",
            assigningWorkerEnabledPermissionsMessage: "Dodanie pracownika umożliwi dodawanie dokumentów oraz boardingów dla tego użytkownika",

            errorOccurred: "Wystąpił błąd",
            invalidRequestData: "Nieprawidłowe dane żądania",
            couldNotFindUserToUnblock: "Nie znaleziono użytkownika, którego chcesz odblokować",
            couldNotFindUserToBlock: "Nie znaleziono użytkownika, którego chcesz zablokować",
            waitAMinute: "Poczekaj chwilę",
            tooManyRequestsSent: "Wysłałeś zbyt dużo żądań w krótkim czasie - poczekaj chwilę i spróbuj ponownie później",
            errorOccurredThatsAllWeKnow: "Wystąpił błąd - tylko tyle wiemy",

            cannotDeleteWorkerUsedInApp: "Nie można usunąc pracownika ponieważ są do niego przypisane dokumenty lub boardingi",
        
            no: "Nie",
            yes: "Tak",
            closeUserDialogConfirmationContent: "Czy na pewno chcesz zamknąć to okno? Wprowadzone w nim dane zostaną utracone",
            wait: "Zaczekaj",
        },
        company: {
            title: "Dane firmy",
            name: "Nazwa firmy",
            street: "Ulica",
            streetNumber: "Nr budynku",
            propertyNumber: "Nr lokalu",
            zipCode: "Kod pocztowy",
            city: "Miejscowość",
            country: "Państwo",
            pickCountry: "Wybierz państwo",
            region: "Województwo",
            nip: "NIP",
            email: "E-mail",
            emailHelpText: "E-mail do wysyłki faktury",
            phoneNumber: "Telefon",
            logoHelpText: "Preferowany wymiar 250x100 pixeli (format jpg lub png), nie większe niż 50 KB",
            logo: "Logo",
            address: "Adres firmy",
            saveButton: "Zapisz",
            cancelButton: "Anuluj",
            errors: {
                name: {
                    required: "Pole jest wymagane",
                },
                email: {
                    required: "Pole jest wymagane",
                    wrong: "Podano niepoprawny email",
                },
                phoneNumber: {
                    wrong: "Podano niepoprawny numer telefonu",
                    areaCode: "Nie wpisano numeru kierunkowego",
                },
                nip: {
                    wrong: "Istnieje już firma w bazie danych, która posiada ten numer NIP",
                    tooShort: "NIP musi zawierać 10 znaków.",
                }
            },
            loading: "Wczytywanie...",
            loadingSave: "Zapisywanie...",
            couldNotFetchData: "Nie można pobrać danych",
            youDoNotHavePriviledgesToThisView: "Nie masz dostępu do tego widoku",
        },
        removeAddressDialog: {
            cancel: "Anuluj",
            delete: "Usuń",
            removingAddress: "Usuwanie adresu...",
            badRequest: "Błąd zapytania",
            cannotRemoveAddressInUse: "Nie można usunąć tego adresu, ponieważ jest on obecnie w użyciu",
            notFound: "Nie znaleziono",
            cannotRemoveAddressDoesNotExists: "Nie można usunąć tego adresu, ponieważ on nie istnieje",
            tooManyRequests: "Zbyt dużo zapytań",
            youSentTooManyRequestsMessage: "Wysłałeś zbyt dużo żądań w krótkim czasie - poczekaj chwilę i spróbuj ponownie później",
            anErrorOccurredTitle: "Wystąpił błąd",
            anErrorOccurredMessage: "Wystąpił błąd - tylko tyle wiemy",
            deleteAddress: "Usuń adres",
            areYouSureYouWantRemoveAddress: "Czy na pewno chcesz usunąć ten adres?",
        },
        addressListPanel: {
            title: "Adresy",
            streetName: "Nazwa ulicy",
            streetNumber: "Numer",
            propertyNumber: "Numer mieszkania",
            zipCode: "Kod pocztowy",
            city: "Miasto",
            region: "Województwo",
            country: "Kraj",
            description: "Opis",
            projects: "Projekty",

            edit: "Edytuj adres",
            remove: "Usuń adres",

            search: "Szukaj",
            project: "Projekt",

            allProjectsSelected: "Wszystkie",
            chooseDataTableColumns: "Wybierz kolumny tabeli",
            modify: "Modyfikuj",

            badRequest: "Błąd zapytania",
            badRequestMessage: "Przesłano nieprawidłowo wypełniony formularz",
            notFound: "Nie znaleziono",
            notFoundErrorMessage: "Nie można było wysłać żądania - nieprawidłowy adres url",
            errorOccurred: "Wystąpił błąd",
            errorOccurredMessage: "Wystąpił błąd w trakcie wysyłania żądania - tylko tyle wiemy",
            tooManyRequests: "Zaczekaj",
            tooManyRequestsMessage: "Wysłałeś zbyt dużo żądań - poczekaj chwilę i spróbuj ponownie później",
            choose: "Wybierz",
            assignToProject: "Przypisz do projektu",
            allProjectsSelected: "Wszystkie",
            forbiddenOperation: "Zabroniono wykonania operacji",
            youDoNotHavePrivilegesToDoThatOperation: "Nie masz uprawnień by wykonać tą operację",

            // custom datatable footer translations
            rowsPerPage: "Elementów na stronę",
            of: "z",
        },
        addressDialog: {
            newAddress: "Nowy adres",
            editAddress: "Edycja adresu",

            streetName: "Nazwa ulicy",
            streetNumber: "Numer ulicy",
            propertyNumber: "Numer mieszkania",
            zipCode: "Kod pocztowy",
            city: "Miasto",
            region: "Województwo",
            country: "Kraj",
            description: "Opis",

            cannotSendEmptyForm: "Nie można wysłać pustego formularza",
            creatingNewAddress: "Tworzę nowy adres...",
            updatingAddress: "Aktualizuję adres...",

            cancel: "Anuluj",
            save: "Zapisz",
            badRequest: "Błąd zapytania",
            badRequestMessage: "Przesłano nieprawidłowo wypełniony formularz",
            notFound: "Nie znaleziono",
            notFoundErrorMessage: "Nie można było wysłać żądania - nieprawidłowy adres url",
            errorOccurred: "Wystąpił błąd",
            errorOccurredMessage: "Wystąpił błąd w trakcie wysyłania żądania - tylko tyle wiemy",
            tooManyRequests: "Zaczekaj",
            tooManyRequestsMessage: "Wysłałeś zbyt dużo żądań - poczekaj chwilę i spróbuj ponownie później",
            choose: "Wybierz",
            assignToProject: "Przypisz do projektu",
            allProjectsSelected: "Wszystkie",
            forbiddenOperation: "Zabroniono wykonania operacji",
            youDoNotHavePrivilegesToDoThatOperation: "Nie masz uprawnień by wykonać tą operację",
        },
    },
    commons: {
        buttons: {
            ok: "OK",
            confirm: "Zatwierdź",
            "continue": "Kontynuuj",
            cancel: "Anuluj",
            understood: "Rozumiem",
            upload: "Prześlij",
            retry: "Spróbuj ponownie",
            close: "Zamknij",
        },
        messages: {
            yes: "Tak",
            no: "Nie",
            cancel: "Anuluj",
            logging: "Logowanie...",
            loggingFailed: "Logowanie nie powiodło się",
            loading: "Wczytywanie...",
            savingChanges: "Zapisywanie zmian...",
            processing: "Przetwarzanie...",
            errorOccuredTitle: "Błąd",
            errorOccured: "Wystąpił błąd. Tylko tyle wiemy.",
            edit: "Edytuj",
            showDetails: "Pokaż szczegóły",
            noDetails: "Brak szczegółów",
            details: "Szczegóły",
            unassigned: "Nie przypisano",
            pageNotFound: "Błąd 404, nie znaleziono strony",
            networkErrorTitle: "Błąd sieci",
            networkError: "Błąd sieci",
            tryAgainLater: "Spróbuj ponownie później",
            requestFailedTitle: "Błąd zapytania",
            requestBoardingFailed: "Zapytanie nie powiodło się dla id: {id}",
            requestAttachmentFailed: "Zapytanie nie powiodło się dla załącznika: {name}",
            goBackTitle: "Niezapisane zmiany",
            goBackReloadRequest: "Czy jesteś pewien by anulować wprowadzone zmiany i opuścić stronę?",
            resettingPassword: "Resetowanie hasła...",
            forbidden: "Dostęp zabroniony",
            internalServerError: "Błąd serwera",
            tryAgainInAMinute: "Spróbuj ponownie za minutę",
            projectsNotChosen: "Nie wybrano projektów",
            unknownPage: "Nieznana strona",
            documentNotExist: "Poszukiwany dokument nie istnieje",
            forbiddenOperation: "Zabroniono wykonania operacji",
            youDoNotHavePriviledgesToDoThatOperation: "Nie masz uprawnień by wykonać tą operację",
            badRequest: "Niepoprawne dane zapytanie",
            noWorkerAssigned: "Nie znaleziono wyników - brak funkcji Pracownika",
        },
        selectors: {
            countries: [{
                    key: "AL",
                    text: "Albania"
                },
                {
                    key: "AD",
                    text: "Andora"
                },
                {
                    key: "AT",
                    text: "Austria"
                },
                {
                    key: "BE",
                    text: "Belgia"
                },
                {
                    key: "BY",
                    text: "Białoruś"
                },
                {
                    key: "BA",
                    text: "Bośnia and Hercegowina"
                },
                {
                    key: "BG",
                    text: "Bułgaria"
                },
                {
                    key: "HR",
                    text: "Chorwacja"
                },
                {
                    key: "ME",
                    text: "Czarnogóra"
                },
                {
                    key: "CZ",
                    text: "Czechy"
                },
                {
                    key: "DK",
                    text: "Dania"
                },
                {
                    key: "EE",
                    text: "Estonia"
                },
                {
                    key: "FI",
                    text: "Finlandia"
                },
                {
                    key: "FR",
                    text: "Francja"
                },
                {
                    key: "GR",
                    text: "Grecja"
                },
                {
                    key: "ES",
                    text: "Hiszpania"
                },
                {
                    key: "NL",
                    text: "Holandia"
                },
                {
                    key: "IE",
                    text: "Irlandia"
                },
                {
                    key: "IS",
                    text: "Islandia"
                },
                {
                    key: "KZ",
                    text: "Kazachstan"
                },
                {
                    key: "XK",
                    text: "Kosowo"
                },
                {
                    key: "LI",
                    text: "Liechtenstein"
                },
                {
                    key: "LT",
                    text: "Litwa"
                },
                {
                    key: "LV",
                    text: "Łotwa"
                },
                {
                    key: "MK",
                    text: "Macedonia"
                },
                {
                    key: "MT",
                    text: "Malta"
                },
                {
                    key: "MD",
                    text: "Mołdawia"
                },
                {
                    key: "MC",
                    text: "Monaco"
                },
                {
                    key: "DE",
                    text: "Niemcy"
                },
                {
                    key: "NO",
                    text: "Norwegia"
                },
                {
                    key: "PL",
                    text: "Polska"
                },
                {
                    key: "PT",
                    text: "Portugalia"
                },
                {
                    key: "RU",
                    text: "Rosja"
                },
                {
                    key: "RO",
                    text: "Rumunia"
                },
                {
                    key: "SM",
                    text: "San Marino"
                },
                {
                    key: "XS",
                    text: "Serbia"
                },
                {
                    key: "SK",
                    text: "Słowacja"
                },
                {
                    key: "SI",
                    text: "Słowenia"
                },
                {
                    key: "CH",
                    text: "Szwajcaria"
                },
                {
                    key: "SE",
                    text: "Szwecja"
                },
                {
                    key: "TR",
                    text: "Turcja"
                },
                {
                    key: "UA",
                    text: "Ukraina"
                },
                {
                    key: "HU",
                    text: "Węgry"
                },
                {
                    key: "GB",
                    text: "Wielka Brytania"
                },
                {
                    key: "IT",
                    text: "Włochy"
                },
            ],
        }
    },
    errors: {
        projects_assignment_id: {
            code: "empty",
            string: "Lista nie może być pusta.",
            header: "Błąd"
        },
        boarding: {
            off_boarding_date: {
                code: "OFF_BOARDING_DATE_LESS",
                string: "Data off boarding jest mniejsza niż data on boarding.",
                header: "Błąd"
            },
            other_boarding: {
                code: "OTHER_BOARDING_EXIST",
                string: "Worker uczestniczy już w projecie w wprowadzonym terminie.",
                header: "Błąd"
            },
            non_field_errors: {
                code: "BOARDING_CURRENT_STATUS_INVALID",
                string: "Podany statusu boardingu nie jest obsługiwany.",
                header: "Błąd"
            },
            boarding_status: {
                code: "BOARDING_NEW_STATUS_INVALID",
                string: "Zmiana boardingu na podany status nie jest dozwolona.",
                header: "Błąd"
            }
        },
        project_assignment: {
            finish_date: {
                code: "FINISH_DATE_LESS",
                string: "Data zakończenia projektu jest mniejsza niż jego data start.",
                header: "Błąd"
            },
            contract_end_date: {
                code: "CONTRACT_END_DATE_LESS",
                string: "Data zakończenia kontraktu jest mniejsza niż jego data rozpoczęcia.",
                header: "Błąd"
            },
            project_id: {
                code: "empty",
                string: "Nie wybrano żadnego projektu.",
                header: "Błąd"
            }
        },
        user_profile: {
            pesel_unique: {
                code: "unique",
                string: "Podany pesel już istnieje w bazie danych.",
                header: "Błąd"
            },
            contract_end_date: {
                code: "CONTRACT_END_DATE_LESS",
                string: "Data zakończenia kontraktu jest mniejsza niż jego data rozpoczęcia.",
                header: "Błąd"
            },
            non_field_errors: {
                code: "NON_FIELD_ERROR",
                string: "Nie można zaktualizować. Nie wprowadzono żadnych nowych danych.",
                header: "Błąd"
            },
            personal_email: {
                code: "invalid",
                string: "Podano niepoprawny prywatny adres email.",
                header: "Błąd"
            },
            secondary_email: {
                code: "invalid",
                string: "Podano niepoprawny firmowy adres email.",
                header: "Błąd"
            },
            personal_mobile_number: {
                code: "invalid",
                string: "Podano niepoprawny numer telefonu.",
                header: "Błąd"
            }


        },
        worker: {
            parent_id: [{
                    code: "INCORRECT_PARENT_ID",
                    string: "Wybrana osoba nie posiada odpowiednich roli, aby przypisać ją jako kierownika.",
                    header: "Błąd"
                },
                {
                    code: "THE_SAME_PARENT_ID",
                    string: "Nie można przypisać. Wybrano tą samą osobe.",
                    header: "Błąd"
                },
                {
                    code: "INVALID_PARENT_ID_TO_WORKER",
                    string: "Pracownik nie może podlegać innemu pracownikowi równorzędnemu sobie.",
                    header: "Błąd"
                }

            ],
            project_assignment: {
                code: "PROJECT_ASSIGNMENT_EXIST",
                string: "Pracownik posiada już przypisanie do wybranego projektu.",
                header: "Błąd"
            }
        },
        preferences: {
            non_field_errors: {
                code: "NON_FIELD_ERROR",
                string: "Nie podano żadnych danych.",
                header: "Błąd"
            },
            new_docs_notifications_time: [{
                    code: "NEW_DOCS_NOTIFICATIONS_TIME_TOO_BIG",
                    string: "Wprowadzono niepoprawna datę, spróbuj ponownie poźniej.",
                    header: "Błąd"
                },
                {
                    code: "NEW_DOCS_NOTIFICATIONS_TIME_NULL",
                    string: "Nie wprowadzono dnia lub godziny.",
                    header: "Błąd"
                },
            ],
            new_docs_notification_phone_time: [
                {
                    code: "NEW_DOCS_NOTIFICATIONS_PHONE_TIME_TOO_BIG",
                    string: "Wprowadzono niepoprawną datę, spróbuj ponownie później.",
                    header: "Błąd"
                },
                {
                    code: "NEW_DOCS_NOTIFICATIONS_PHONE_TIME_NULL",
                    string: "Nie wprowadzono dnia lub godziny.",
                    header: "Błąd"
                }
            ],
            default_error: {
                string: "Wystąpił błąd. Tylko tyle wiemy.",
                header: "Błąd",
            }
        },
        permissions: {
            name: {
                string: "Grupa o takiej nazwie już istnieje.",
                header: "Błąd",
            },
            default_error: {
                string: "Wystąpił błąd. Tylko tyle wiemy.",
                header: "Błąd",
            },
            youDoNotHavePriviledgesToDoThatOperation: {
                string: "Nie masz uprawnień by wykonać tą operację",
                header: "Błąd"
            },
        },
        company: {
            email: {
                string: "Podano niepoprawny email.",
                header: "Błąd"
            },
            nip: {
                string: "Istnieje już firma w bazie danych, która posiada ten numer NIP.",
                header: "Błąd"
            },
            fob_billing: {
                string: "Istnieje już firma do rozliczenia w bazie danych.",
                header: "Błąd"
            },
            default_error: {
                string: "Wystąpił błąd. Tylko tyle wiemy.",
                header: "Błąd",
            },
            upload_file: [{
                    code: "FILE_TOO_LARGE",
                    string: "Plik waży za dużo.",
                    header: "Błąd"
                },
                {
                    code: "FILE_NOT_PICTURE",
                    string: "Plik musi być zdjęciem z rozszerzeniem .png lub .jpg.",
                    header: "Błąd",
                }
            ],
            contact_number1: {
                string: "Podano niepoprawny numer telefonu",
                code: "Błąd"
            },

        }
    }
}